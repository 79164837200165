﻿import * as React from "react";
import { CONST } from "../../settings";
import {
    assert,
    DigitalPlanningBoardLibraryEvent,
    DigitalPlanningBoardSelectionEvent,
    DigitalPlanningBoardTradesEvent,
    DigitalPlanningBoardViewDetails,
} from "lcmd2framework";
import { gfu } from "../../../model/GlobalHelper";
import { CanvasCommonProps, CanvasCommonState, CanvasViewKey, CanvasViewPort, CanvasViewProps } from "./Canvas";
import { MainWorkerMessage } from "../../MainWorkerPipe";
import { CanvasSizeHelper } from "./Shared/CanvasSizeHelper";

type CanvasSidebarHostProps = CanvasCommonProps &
    CanvasViewProps & {
        divRefs: { AD: HTMLDivElement };
        sidebar: {
            selected: CanvasViewKey;
        };
        wb: any;
        hidden: boolean;
        children: React.ReactNode;
    };

type CanvasSidebarHostState = CanvasCommonState & {
    _trades: DigitalPlanningBoardTradesEvent;
    selectedTrade: DigitalPlanningBoardTradesEvent;
    _libs: DigitalPlanningBoardLibraryEvent;
    width: number;
    open: boolean;
    sel: DigitalPlanningBoardSelectionEvent;
    ppTS: number;
    wbTS: number;
    wbID: string;
    initialCanvasLoaded: boolean;
};

export class CanvasSidebarHost extends React.Component<CanvasSidebarHostProps, CanvasSidebarHostState> {
    state: CanvasSidebarHostState = {
        scale: 1.0,
        const: this.props.worker.canvas.viewConst,
        rows: 0,
        cols: 0,
        l_min: 0,
        l_max: 0,
        _trades: {},
        selectedTrade: null,
        _libs: {},
        width: CONST.legacyCanvasSidebarWidth,
        open: false,
        sel: {
            pid: [],
            isWhiteboard: false,
        },
        ppTS: -1,
        wbTS: -1,
        wbID: null,
        initialCanvasLoaded: false,
    };
    private onClick = function (this: CanvasSidebarHost) {
        this.setState({
            open: !this.state.open,
        });
    }.bind(this);
    private _selectCBS: ((ev: DigitalPlanningBoardSelectionEvent) => void)[] = [];
    private _tradesCBS: ((ev: DigitalPlanningBoardTradesEvent) => void)[] = [];
    private _libsCBS: ((ev: DigitalPlanningBoardLibraryEvent) => void)[] = [];
    private _lastSignal = {
        select: null,
        trades: null,
        libs: null,
    };
    private _signalCBS = function (this: CanvasSidebarHost) {
        if (this._lastSignal.select !== this.state.sel) {
            this._lastSignal.select = this.state.sel;
            const n_cbs = this._selectCBS.length;
            for (let i_cb = 0; i_cb < n_cbs; i_cb++) {
                this._selectCBS[i_cb](this.state.sel);
            }
        }
        if (this._lastSignal.trades !== this.state._trades) {
            this._lastSignal.trades = this.state._trades;
            const n_cbs = this._tradesCBS.length;
            for (let i_cb = 0; i_cb < n_cbs; i_cb++) {
                this._tradesCBS[i_cb](this.state._trades);
            }
        }
        if (this._lastSignal.libs !== this.state._libs) {
            this._lastSignal.libs = this.state._libs;
            const n_cbs = this._libsCBS.length;
            for (let i_cb = 0; i_cb < n_cbs; i_cb++) {
                this._libsCBS[i_cb](this.state._libs);
            }
        }
    }.bind(this);
    onWorkerMsg = function (this: CanvasSidebarHost, msg: MainWorkerMessage) {
        //console.log(msg[0]);
        switch (msg[0]) {
            case "canvas":
                {
                    const canvas = msg[1];
                    this.setState(this._updateView.bind(null, canvas, null, null), this._signalCBS);
                }
                break;
            case "wb":
                {
                    const wb = msg[1];
                    this.setState((state, _props) => {
                        const wbID = gfu(wb?.sync?.storageName, state.wbID, null);
                        const wbTS = gfu(wb?.sync?.commited, state.wbTS, -1);
                        const wb_stripes =
                            Array.isArray(wb.stripes) && wb.stripes.length > 0 && wb.stripes[0].t > 0 ? wb.stripes : []; // do not signal "root" stripe
                        if (state._libs[wb.id] !== wb_stripes) {
                            const ret = {
                                ...state,
                                _libs: {
                                    /*...state._libs,*/ // we do not merge libs, we replace them
                                    [wb.id]: wb_stripes,
                                },
                                wbID: wbID,
                                wbTS: wbTS,
                            };
                            return ret;
                        } else if (wbID === state.wbID && wbTS !== state.wbTS) {
                            // quick TS change...
                            return {
                                ...state,
                                wbTS: wbTS,
                            };
                        } else if (wbID !== state.wbID) {
                            return {
                                ...state,
                                wbID: wbID,
                                wbTS: wbTS,
                            };
                        } else {
                            return null;
                        }
                    }, this._signalCBS);
                }
                break;
            case "view":
                {
                    const view = msg[1];
                    this.setState(this._updateView.bind(null, null, view, null));
                }
                break;
            case "tab":
                {
                    const id = msg[1].id;
                    const open = null !== id;
                    if (open !== this.state.open) {
                        this.setState({
                            open: open,
                        });
                    }
                }
                break;
            case "task": // no break
            case "taskex":
                {
                    const opt = msg[1];
                    const event: DigitalPlanningBoardSelectionEvent = {
                        pid: opt.selected || [],
                        isWhiteboard: opt.isWhiteboard,
                    };

                    this.setState(this._updateView.bind(null, null, null, event), this._signalCBS);
                }
                break;
            case "toggle":
                {
                    switch (msg[1]) {
                        case "forceShiftKey":
                            {
                                this.forceUpdate();
                            }
                            break;
                    }
                }
                break;
        }
    }.bind(this);
    private sidebarCtx = function (this: CanvasSidebarHost) {
        return {
            useSelectionEffect: (cb: (ev: DigitalPlanningBoardSelectionEvent) => void, deps: React.DependencyList) => {
                const _cb = React.useCallback(cb, deps);
                React.useEffect(() => {
                    assert(this._lastSignal.select === this.state.sel);
                    _cb(this.state.sel);
                    return this._registerCBHelper(this._selectCBS, cb);
                }, [_cb]);
            },
            useTradesEffect: (cb: (ev: DigitalPlanningBoardTradesEvent) => void, deps: React.DependencyList) => {
                const _cb = React.useCallback(cb, deps);
                React.useEffect(() => {
                    assert(this._lastSignal.trades === this.state._trades);
                    _cb(this.state._trades);
                    return this._registerCBHelper(this._tradesCBS, cb);
                }, [_cb]);
            },
            useLibrariesEffect: (cb: (ev: DigitalPlanningBoardLibraryEvent) => void, deps: React.DependencyList) => {
                const _cb = React.useCallback(cb, deps);
                React.useEffect(() => {
                    assert(this._lastSignal.libs === this.state._libs);
                    _cb(this.state._libs);
                    return this._registerCBHelper(this._libsCBS, cb);
                }, [_cb]);
            },
            scrollToProcess: (pid: number) => {
                this.props.worker.gotoTask(pid, this.props.view, this.props.divRefs);
            },
            setTradeData: (tradeData: DigitalPlanningBoardTradesEvent) => this.setState({ selectedTrade: tradeData }),
        };
    }.call(this);
    private _view: DigitalPlanningBoardViewDetails = null;

    componentDidMount(this: CanvasSidebarHost) {
        // this._signalCBS()
        this.props.worker.registerHandler(this.onWorkerMsg);
    }

    componentWillUnmount(this: CanvasSidebarHost) {
        this.props.worker.unregisterHandler(this.onWorkerMsg);
    }

    render(this: CanvasSidebarHost) {
        const state = this.state;
        const C = state.const;
        const view = this.props.view;
        const scale = view.scale;
        const selected: CanvasViewKey = this.props.sidebar.selected;
        if (
            null == this._view ||
            this._view.key !== selected ||
            this._view.projectId !== this.props.projectId ||
            this._view.libraries !== this.props.wb
        ) {
            // cache instance
            this._view = {
                projectId: this.props.projectId,
                libraries: this.props.wb,
                key: selected,
            };
        }
        if ("function" === typeof this.props.worker.config?.Sidebar) {
            return this.props.hidden || !this.state.initialCanvasLoaded ? null : (
                <this.props.worker.config.Sidebar
                    ppTS={state.ppTS}
                    wbID={state.wbID}
                    wbTS={state.wbTS}
                    view={this._view}
                    sidebarCtx={this.sidebarCtx}
                    top={
                        "project" === this._view.key
                            ? CanvasSizeHelper.getHeaderHeight(C)
                            : CONST.titleHeight + CONST.subtitleHeight
                    }
                    hidden={this.props.hidden}
                    marquee={this.props.view.forceShiftKey}
                    selectedTrade={state.selectedTrade}
                />
            );
        } else {
            return (
                <div
                    style={{
                        display: this.props.hidden ? "none" : undefined,
                        position: "fixed",
                        right: state.open ? 0 : -state.width,
                        width: state.width,
                        top: CanvasSizeHelper.getHeaderHeight(C),
                        bottom: 0,
                        border: "1px solid darkgray",
                        zIndex: 10,
                        transition: "right 100ms linear",
                        backgroundColor: "white",
                    }}
                >
                    {this.props.children}
                </div>
            );
        }
    }

    private _updateView(
        this: null,
        canvas: any,
        view: CanvasViewPort | null,
        sel: DigitalPlanningBoardSelectionEvent,
        state: CanvasSidebarHostState,
        _props: {
            view: CanvasViewPort;
            projectId: string;
        },
    ) {
        view = view || _props.view;
        const scale = view.scale || state.scale || 1;
        const C = canvas?.viewConst || state.const;
        const cols = gfu(canvas?.cols, state.cols, 0);
        const rows = gfu(canvas?.rows, state.rows, 0);
        const l_min = gfu(canvas?.l_min, state.l_min, 0);
        const l_max = gfu(canvas?.l_max, state.l_max, 0);
        const _sel: DigitalPlanningBoardSelectionEvent = sel || state.sel || null;
        const state_canvas_trades = state._trades[_props.projectId];
        const canvas_trades = canvas?.trades || state._trades[_props.projectId];
        const ts = gfu(canvas?.sync?.commited, state.ppTS, -1);
        if (
            scale !== state.scale ||
            cols !== state.cols ||
            rows !== state.rows ||
            l_min !== state.l_min ||
            l_max !== state.l_max ||
            C !== state.const ||
            _sel !== state.sel ||
            canvas_trades !== state_canvas_trades ||
            ts !== state.ppTS
        ) {
            let _trades = state._trades;
            if (canvas_trades !== state_canvas_trades) {
                _trades = {
                    ..._trades,
                    [_props.projectId]: canvas_trades,
                };
            }
            return {
                scale: scale,
                const: C,
                cols: cols,
                rows: rows,
                l_min: l_min,
                l_max: l_max,
                sel: _sel,
                _trades: _trades,
                ppTS: ts,
                initialCanvasLoaded: true,
            };
        } else {
            return null;
        }
    }

    private _registerCBHelper(cbs: any[], cb: any) {
        cbs.push(cb);
        return () => {
            const i = cbs.indexOf(cb);
            if (i >= 0) {
                cbs.splice(i, 1);
            }
        };
    }
}
