import { SERVICES } from "./services";
import { FrameworkError, FrameworkHttpError, generateId } from "./GlobalHelper";
import type { DataOperation } from "./DataModel";

import { SubId } from "@/legacy/SubTypes";
import { WebAppTelemetryFactory } from "@/app/services/WebAppTelemetry.service";
import { SeverityLevel } from "@microsoft/applicationinsights-web";

export interface Role {
    role: string;
    trade?: number;
    status?: string;
}

export interface UsersWithRole {
    [sub: SubId]: Role;
}

export interface ProjectDetails {
    shared: UsersWithRole;
    ts: number;
    log: number[];
    name: string;
}

export interface ProjectInformation {
    project_token: string;
    sub: string;
    role: Role;
    pid: string;
    project: ProjectDetails;
}

export function uploadArrayBuffer(
    buffer: ArrayBuffer,
    cb: (err: Error, done: { resource_token: string }) => void,
    status?: (status: { ofs: number; length: number }) => void,
    resource_meta?: any,
) {
    const uploadBlock = function (this) {
        const ofs = this.ofs;
        const len = Math.min(this.buffer.byteLength - ofs, SERVICES.UPLOAD_SERVICE_MAX);
        if (status) {
            status({
                ofs: this.ofs,
                length: this.buffer.byteLength,
            });
        }
        if (len > 0) {
            const req = new XMLHttpRequest();
            req.responseType = "json";
            req.open("POST", SERVICES.UPLOAD_SERVICE);
            req.setRequestHeader("content-type", "application/octet-stream");
            if (this.auth_token) {
                req.setRequestHeader("Authorization", ["token", this.auth_token].join(" "));
            }
            req.onreadystatechange = () => {
                if (req.readyState === XMLHttpRequest.DONE) {
                    if (201 === req.status) {
                        if (0 === this.ofs) {
                            if (req.response?.auth_token) {
                                this.auth_token = req.response?.auth_token || null;
                            }
                        }
                        this.blocks.push(req.response?.blockId);
                        this.ofs += len;
                        uploadBlock(); // upload next block
                    } else {
                        // error
                        cb(new FrameworkHttpError(req.status), null);
                    }
                }
            };
            const content = new Uint8Array(this.buffer, ofs, len);
            req.send(content);
        } else {
            if (
                this.buffer.byteLength === ofs &&
                this.auth_token &&
                Math.ceil(this.buffer.byteLength / SERVICES.UPLOAD_SERVICE_MAX) === this.blocks.length
            ) {
                const req = new XMLHttpRequest();
                req.responseType = "json";
                req.open("POST", SERVICES.UPLOAD_SERVICE);
                req.setRequestHeader("content-type", "application/json");
                req.setRequestHeader("Authorization", ["token", this.auth_token].join(" "));
                req.onreadystatechange = () => {
                    if (req.readyState === XMLHttpRequest.DONE) {
                        if (201 === req.status && req.response?.resource_token) {
                            cb(null, {
                                resource_token: req.response.resource_token,
                            });
                        } else {
                            cb(new FrameworkHttpError(req.status), null);
                        }
                    }
                };
                req.send(
                    JSON.stringify({
                        blocks: this.blocks,
                        resource_meta: resource_meta,
                    }),
                );
            } else {
                cb(new FrameworkError("error.fatal"), null);
            }
        }
    }.bind({
        buffer: buffer,
        ofs: 0,
        blocks: [],
        auth_token: null,
    });
    uploadBlock();
}

export function uploadFileBlocks(
    file: File,
    cb: (err: Error, done: { resource_token: string }) => void,
    status?: (status: { ofs: number; length: number }) => void,
) {
    const reader = new FileReader();
    reader.onload = (e) => {
        const buffer = e.target.result as ArrayBuffer;
        uploadArrayBuffer(buffer, cb, status);
    };
    reader.readAsArrayBuffer(file);
}

export function uploadString(
    content: string,
    cb: (err: Error, done: { resource_token: string }) => void,
    status?: (status: { ofs: number; length: number }) => void,
) {
    const enc = new TextEncoder();
    const buffer = enc.encode(content).buffer;
    uploadArrayBuffer(buffer, cb, status);
}

export function uploadAttachment(
    buffer: ArrayBuffer,
    name: string,
    auth_token: string,
    cb: (err: Error, done: { token: string }) => void,
    status?: (status: { ofs: number; length: number }) => void,
) {
    const req = new XMLHttpRequest();
    req.responseType = "json";
    req.open("POST", SERVICES.ATTACHMENT_SERVICE);
    req.setRequestHeader("content-type", "application/octet-stream");
    if (auth_token) {
        req.setRequestHeader("Authorization", ["token", auth_token].join(" "));
        req.setRequestHeader("Attachment-Name", Buffer.from(name).toString("base64"));
    }
    req.onreadystatechange = () => {
        if (req.readyState === XMLHttpRequest.DONE) {
            if (201 === req.status) {
                cb(null, req.response);
            } else {
                // error
                cb(new FrameworkHttpError(req.status), null);
            }
        }
    };
    const content = new Uint8Array(buffer);
    req.send(content);
}

function liveReq(url: string, data: any, onResponse: (status: number, data: any) => void, token?: string) {
    liveFetch(url, data, token)
        .then((result) => {
            onResponse(result.status, result.data);
        })
        .catch(() => {
            onResponse(500, {});
        });
}

export async function liveFetch(url: string, data: any, token: string) {
    const response = await fetch(url, {
        method: "POST",
        headers: {
            "content-type": "application/json",
            authorization: token ? `token ${token}` : undefined,
        },
        body: typeof data === "string" ? data : JSON.stringify(data),
    });

    let result;
    try {
        result = await response.json();
    } catch {
        result = await response.text();
    }

    return {
        status: response.status,
        ok: response.ok,
        data: result,
    };
}

export function authCreate(
    email: string,
    cb: (error: Error, result: any) => void,
    token?: string,
    auth_warmup?: { req: string },
    recaptcha?: string,
) {
    liveReq(
        SERVICES.AUTH_SERVICE,
        {
            endpoint: "auth",
            op: "create",
            build: SERVICES.BUILD,
            req: auth_warmup?.req,
            email: email,
            recaptcha: recaptcha || undefined,
        },
        (status, response) => {
            if (201 === status && response) {
                cb(null, response);
            } else {
                cb(new FrameworkHttpError(status), null);
            }
        },
        token,
    );
}

export function authLogin(
    email: string,
    password: string | { accessToken: string } | null,
    cb: (error: Error, result: any) => void,
    token: string,
    auth_warmup: { req: string },
    doNotCreate: boolean,
    license: string,
    recaptcha: string | undefined,
) {
    liveReq(
        SERVICES.AUTH_SERVICE,
        {
            endpoint: "auth",
            op: "login",
            auth_token: token || undefined,
            build: SERVICES.BUILD,
            req: auth_warmup?.req,
            email: email,
            password: password || undefined,
            license: license || undefined,
            recaptcha: recaptcha || undefined,
        },
        (status, response) => {
            if (200 === status && response) {
                cb(null, response);
            } else if (null === password && true !== doNotCreate && 409 === status && response) {
                // does not exist
                authCreate(
                    email,
                    (error, result) => {
                        if (error) {
                            cb(error, null);
                        } else {
                            authLogin(email, null, cb, response.recaptcha, auth_warmup, true, license, undefined);
                        }
                    },
                    token,
                    auth_warmup,
                );
            } else {
                cb(new FrameworkHttpError(status), null);
            }
        },
        token,
    );
}

export function authVerify(
    pin: string,
    pin_secret: string,
    password: string | null,
    cb: (error: Error, result: any) => void,
    license?: string,
    email?: string,
    meta?: any,
) {
    liveReq(
        SERVICES.AUTH_SERVICE,
        {
            endpoint: "auth",
            op: "verify",
            build: SERVICES.BUILD,
            pin: pin,
            pin_secret: pin_secret,
            license: license,
            email: email || undefined,
            auth_token: email ? pin_secret || undefined : undefined,
            password: password || undefined,
            meta: meta || undefined,
        },
        (status, response) => {
            if (200 === status && response) {
                cb(null, response);
            } else {
                cb(new FrameworkHttpError(status), null);
            }
        },
        pin_secret,
    );
}

export function createProject(
    auth_token: string,
    cb: (error: Error, result: any) => void,
    file?: { id: string } & any,
    commit?: any,
) {
    if ((file?.name || "").endsWith(".itwm.json")) {
        file.type = "application/vnd.lcmd.import.itwm";
    }

    liveReq(
        SERVICES.AUTH_SERVICE,
        {
            endpoint: "auth",
            op: "create_project",
            build: SERVICES.BUILD,
            file: file || undefined,
            commit: commit || undefined,
            auth_token: auth_token,
        },
        (status, response) => {
            if (200 === status && response) {
                cb(null, response);
            } else {
                cb(new FrameworkHttpError(status), null);
            }
        },
        auth_token,
    );
}

export function addFileToProject(
    auth_token: string,
    pid: string,
    cb: (error: Error, result: any) => void,
    file?: { id: string } & any,
    commit?: any,
) {
    liveReq(
        SERVICES.AUTH_SERVICE,
        {
            endpoint: "auth",
            op: "add_file",
            build: SERVICES.BUILD,
            file: file || undefined,
            commit: commit || undefined,
            pid: pid,
            auth_token: auth_token,
        },
        (status, response) => {
            if (200 === status && response) {
                cb(null, response);
            } else {
                cb(new FrameworkHttpError(status), null);
            }
        },
        auth_token,
    );
}

export function getProject(auth_token: string, pid: string, cb: (error: Error, result: any) => void) {
    liveReq(
        SERVICES.AUTH_SERVICE,
        {
            endpoint: "auth",
            op: "project",
            build: SERVICES.BUILD,
            pid: pid,
            auth_token: auth_token,
        },
        (status, response) => {
            if (200 === status && response) {
                cb(null, response);
            } else {
                cb(new FrameworkHttpError(status), null);
            }
        },
        auth_token,
    );
}

export function setProjectProps(
    auth_token: string,
    pid: string,
    props: { name?: string },
    cb: (error: FrameworkHttpError, result: any) => void,
) {
    liveReq(
        SERVICES.AUTH_SERVICE,
        {
            endpoint: "auth",
            op: "set_project",
            build: SERVICES.BUILD,
            pid: pid,
            auth_token: auth_token,
            name: props?.name,
        },
        (status, response) => {
            if (200 === status && response) {
                cb(null, response);
            } else {
                cb(new FrameworkHttpError(status), null);
            }
        },
        auth_token,
    );
}

export function getSub(
    auth_token: string,
    sub: string,
    cb: (error: FrameworkError, result: any) => void,
    opt?: { sync_lic?: boolean },
) {
    liveReq(
        SERVICES.AUTH_SERVICE,
        {
            ...(opt || {}),
            endpoint: "auth",
            op: "sub",
            sub: sub,
            auth_token: auth_token,
        },
        (status, response) => {
            if (200 === status && response) {
                cb(null, response);
            } else {
                console.error(sub);
                cb(new FrameworkHttpError(status), null);
            }
        },
        auth_token,
    );
}

export function updateMeta(auth_token: string, meta: any, cb: (error: FrameworkError, result: any) => void) {
    liveReq(
        SERVICES.AUTH_SERVICE,
        {
            endpoint: "auth",
            op: "updateMeta",
            meta: meta,
            auth_token: auth_token,
        },
        (status, response) => {
            if (200 === status && response) {
                cb(null, response);
            } else {
                cb(new FrameworkHttpError(status), null);
            }
        },
        auth_token,
    );
}

export function getLic(auth_token: string, licid: string, cb: (error: FrameworkError, result: any) => void) {
    liveReq(
        SERVICES.AUTH_SERVICE,
        {
            endpoint: "auth",
            op: "lic",
            lic: licid,
            auth_token: auth_token,
        },
        (status, response) => {
            if (200 === status && response) {
                cb(null, response);
            } else {
                cb(new FrameworkHttpError(status), null);
            }
        },
        auth_token,
    );
}

export function attachLic(
    auth_token: string,
    params: { email: string[]; role: string },
    cb: (error: FrameworkError, result: any) => void,
) {
    liveReq(
        SERVICES.AUTH_SERVICE,
        {
            endpoint: "auth",
            op: "attach_lic",
            auth_token: auth_token,
            ...params,
        },
        (status, response) => {
            if (200 === status && response) {
                cb(null, response);
            } else {
                cb(new FrameworkHttpError(status), null);
            }
        },
        auth_token,
    );
}

export function getLog(auth_token: string, pid: string, ts: number, cb: (error: Error, result: any) => void) {
    liveReq(
        SERVICES.AUTH_SERVICE,
        {
            endpoint: "auth",
            op: "log",
            pid: pid,
            ts: ts,
            auth_token: auth_token,
        },
        (status, response) => {
            if (200 === status && response) {
                cb(null, response);
            } else {
                cb(new FrameworkHttpError(status), null);
            }
        },
        auth_token,
    );
}

export function notifySub(auth_token: string, notification: string, cb: (error: Error, result: any) => void) {
    liveReq(
        SERVICES.AUTH_SERVICE,
        {
            endpoint: "auth",
            op: "notify",
            build: SERVICES.BUILD,
            notify: notification,
            auth_token: auth_token,
        },
        (status, response) => {
            if (201 === status && response) {
                cb(null, response);
            } else {
                cb(new FrameworkHttpError(status), null);
            }
        },
        auth_token,
    );
}

export function notifyError(error: string, stack?: string) {
    const err = new Error(error || "General from worker!No error message provided");
    err.stack = stack;
    WebAppTelemetryFactory.trackException({ id: generateId(), exception: err, severityLevel: SeverityLevel.Error });
}

function converterWarmup(auth_token: string, cb: (error: Error, result: any) => void) {
    const req = new XMLHttpRequest();
    req.responseType = "json";
    req.open("POST", SERVICES.PARSE_SERVICE);
    req.setRequestHeader("content-type", "application/json");
    if (false && auth_token) {
        // no yet...
        req.setRequestHeader("Authorization", ["token", auth_token].join(" "));
    }
    req.onreadystatechange = () => {
        if (req.readyState === XMLHttpRequest.DONE) {
            if (200 === req.status && req.response) {
                cb(null, req.response);
            } else {
                cb(new FrameworkHttpError(req.status), null);
            }
        }
    };
    req.send(
        JSON.stringify({
            op: "warmup",
            build: SERVICES.BUILD,
            auth_token: auth_token || undefined,
        }),
    );
}

export function authWarmup(
    auth_token: string,
    promo: string | undefined,
    cb: (error: Error, result: any) => void,
    warmup_converter: boolean,
) {
    liveReq(
        SERVICES.AUTH_SERVICE,
        {
            endpoint: "auth",
            op: "warmup",
            build: SERVICES.BUILD,
            auth_token: auth_token || undefined,
            major: SERVICES.MAJOR,
            promo: promo,
        },
        (status, response) => {
            if (200 === status && response) {
                cb(null, response);
            } else if (403 === status && response?.details) {
                cb(new FrameworkError("fw.warmup403", response?.details), null);
            } else {
                cb(new FrameworkHttpError(status), null);
            }
        },
        auth_token,
    );
    if (warmup_converter) {
        converterWarmup(auth_token, (error, result) => {
            /*ignore*/ console.info("Converter warmed up.");
        });
    }
}

export function addCollaborator(
    auth_token: string,
    cb: (error: Error, result: any) => void,
    pid: string,
    sub: string,
    email: string,
    role: string,
) {
    liveReq(
        SERVICES.AUTH_SERVICE,
        {
            endpoint: "auth",
            op: "add_collaborator",
            auth_token: auth_token || undefined,
            pid: pid,
            sub: sub,
            email: email,
            role: role,
        },
        (status, response) => {
            if (200 === status && response) {
                cb(null, response);
            } else {
                cb(new FrameworkHttpError(status), null);
            }
        },
        auth_token,
    );
}

export function registerSub(auth_token: string, cb: (error: Error, result: any) => void, email: string) {
    liveReq(
        SERVICES.AUTH_SERVICE,
        {
            endpoint: "auth",
            op: "register_sub",
            auth_token: auth_token || undefined,
            email: email,
        },
        (status, response) => {
            if (200 === status && response) {
                cb(null, response);
            } else {
                cb(new FrameworkHttpError(status), null);
            }
        },
        auth_token,
    );
}

export function updateDB(token: string, subs: { [sub: string]: 0 | 1 }, cb: (error: Error, result: any) => void) {
    liveReq(
        SERVICES.STORAGE_SERVICE,
        {
            endpoint: "storage",
            op: "update_db",
            token: token || undefined,
            subs: subs,
        },
        (status, response) => {
            if (200 === status && response) {
                cb(null, response);
            } else {
                cb(new FrameworkHttpError(status), null);
            }
        },
        token,
    );
}

export function unlink_project(
    auth_token: string,
    cb: (error: Error, result: any) => void,
    pid: string,
    sub: string | string[],
) {
    liveReq(
        SERVICES.AUTH_SERVICE,
        {
            endpoint: "auth",
            op: "unlink_project",
            auth_token: auth_token || undefined,
            pid: pid,
            sub: sub,
        },
        (status, response) => {
            if (200 === status && response) {
                cb(null, response);
            } else {
                cb(new FrameworkHttpError(status), null);
            }
        },
        auth_token,
    );
}

export function set_master_sandbox(auth_token: string, cb: (error: Error, result: any) => void) {
    liveReq(
        SERVICES.AUTH_SERVICE,
        {
            endpoint: "auth",
            op: "master_sandbox",
            build: SERVICES.BUILD,
            auth_token: auth_token || undefined,
        },
        (status, response) => {
            if (200 === status && response) {
                cb(null, response);
            } else {
                cb(new FrameworkHttpError(status), null);
            }
        },
        auth_token,
    );
}

export function fetch_ts(auth_token: string, sids: string[], cb: (error: Error, result: any) => void) {
    if (Array.isArray(sids) && sids.length > 0) {
        liveReq(
            SERVICES.STORAGE_SERVICE,
            {
                endpoint: "storage",
                op: "fetch_ts",
                build: SERVICES.BUILD,
                token: auth_token || undefined,
                sids,
            },
            (status, response) => {
                if (200 === status && response) {
                    cb(null, response);
                } else {
                    cb(new FrameworkHttpError(status), null);
                }
            },
            auth_token,
        );
    } else {
        cb(null, {});
    }
}

export function create_master(
    ops: DataOperation[],
    cb: (error: Error, result: any) => void,
    opt?: { resource_token?: string; clone?: any },
) {
    liveReq(
        SERVICES.STORAGE_SERVICE,
        {
            endpoint: "storage",
            op: "create_storage",
            ops,
            token: opt?.resource_token,
            clone: opt?.clone,
        },
        (status, response) => {
            if (200 === status) {
                cb(null, response);
            } else {
                cb(new FrameworkHttpError(status), null);
            }
        },
        opt?.resource_token,
    );
}

export function create_sandbox(
    master_token: string,
    cb: (error: Error, result: any) => void,
    sandboxName: string,
    auth: "subs" | "pid" | "*",
    startOfs?: number,
    opt?: {
        reopen?: string;
        db?: boolean;
        overrideTS?: number;
    },
) {
    liveReq(
        SERVICES.STORAGE_SERVICE,
        {
            endpoint: "storage",
            op: "create_branch",
            token: master_token,
            startOfs: startOfs || 0,
            name: sandboxName,
            auth: auth,
            reopen: opt?.reopen,
            db: opt?.db,
            overrideTS: opt?.overrideTS,
        },
        (status, response) => {
            if (200 === status && response) {
                cb(null, response);
            } else {
                cb(new FrameworkHttpError(status), null);
            }
        },
        master_token,
    );
}

export function fetch_master(master_token: string, cb: (error: Error, result: any) => void) {
    liveReq(
        SERVICES.STORAGE_SERVICE,
        {
            endpoint: "storage",
            op: "fetch_master",
            token: master_token,
        },
        (status, response) => {
            if (200 === status && response) {
                cb(null, response);
            } else {
                cb(new FrameworkHttpError(status), null);
            }
        },
        master_token,
    );
}

export function fetch_ops(
    token: string,
    cb: (error: Error, result: any) => void,
    startOfs: number,
    endOfs?: number,
    sid?: string,
    chunked?: boolean | string,
) {
    liveReq(
        SERVICES.STORAGE_SERVICE,
        {
            endpoint: "storage",
            op: "fetch_storage",
            build: SERVICES.BUILD,
            token: token,
            startOfs: startOfs,
            endOfs: endOfs,
            sid: sid || undefined,
            chunked: chunked,
        },
        (status, response) => {
            if (200 === status && response) {
                cb(null, response);
            } else if (403 === status && "number" === typeof response?.invalidPID && response.invalidPID >= 0) {
                const err = new FrameworkHttpError(status);
                err.details = response;
                cb(err, null);
            } else {
                cb(new FrameworkHttpError(status), null);
            }
        },
        token,
    );
}

export function fetch_xslx(
    token: string,
    cb: (error: FrameworkHttpError, result: any) => void,
    data: Buffer,
    isCompressed: boolean,
) {
    const req = new XMLHttpRequest();
    req.responseType = "json";
    req.open("POST", SERVICES.PDF_SERVICE);
    req.setRequestHeader("content-type", "application/octet-stream");
    if (isCompressed) {
        // compress
        req.setRequestHeader("content-encoding", "deflate");
        /*
        if ('undefined'!==typeof(self)) {
            pako.deflate(data)
        } else {
            console.log("NODE JS!!!");
            data=require('zlib').deflateSync(data);
        }
        */
    }
    if (token) {
        req.setRequestHeader("Authorization", ["token", token].join(" "));
    }
    req.onreadystatechange = () => {
        if (req.readyState === XMLHttpRequest.DONE) {
            if (200 === req.status && req.response) {
                cb(null, req.response);
            } else {
                cb(new FrameworkHttpError(req.status), null);
            }
        }
    };
    req.responseType = "arraybuffer";
    req.send(data);
}

export function procore_fetch_companies(token: string, cb: (error: Error, result: any) => void) {
    const req = new XMLHttpRequest();
    req.responseType = "json";
    req.open("GET", SERVICES.PROCORE_SERVICE + "/vapid/companies");
    //req.setRequestHeader("content-type", "application/json");
    if (token) {
        req.setRequestHeader("Authorization", ["Bearer", token].join(" "));
    }
    req.onreadystatechange = () => {
        if (req.readyState === XMLHttpRequest.DONE) {
            if (200 === req.status && req.response) {
                cb(null, req.response);
            } else {
                cb(new FrameworkHttpError(req.status), null);
            }
        }
    };
    req.send();
}

export function procore_fetch_projects(token: string, company_id: number, cb: (error: Error, result: any) => void) {
    const req = new XMLHttpRequest();
    req.responseType = "json";
    req.open(
        "GET",
        SERVICES.PROCORE_SERVICE +
            "/vapid/projects?" +
            [
                ["company_id", encodeURIComponent(company_id)].join("="),
                ["serializer_view", encodeURIComponent("compact")].join("="),
            ].join("&"),
    );
    //req.setRequestHeader("content-type", "application/json");
    if (token) {
        req.setRequestHeader("Authorization", ["Bearer", token].join(" "));
    }
    req.onreadystatechange = () => {
        if (req.readyState === XMLHttpRequest.DONE) {
            if (200 === req.status && req.response) {
                cb(null, req.response);
            } else {
                cb(new FrameworkHttpError(req.status), null);
            }
        }
    };
    req.send();
}

export function procore_fetch_tasks(
    token: string,
    project_id: number,
    company_id: number,
    cb: (error: Error, result: any) => void,
) {
    const req = new XMLHttpRequest();
    req.responseType = "json";
    req.open(
        "GET",
        SERVICES.PROCORE_SERVICE +
            "/rest/v1.0/tasks?" +
            [["project_id", encodeURIComponent(project_id)].join("=")].join("&"),
    );
    //req.setRequestHeader("content-type", "application/json");
    if (token) {
        req.setRequestHeader("Authorization", ["Bearer", token].join(" "));
    }
    if (company_id) {
        const value = company_id.toString();
        req.setRequestHeader("Procore-Company-Id", value);
    }
    req.onreadystatechange = () => {
        if (req.readyState === XMLHttpRequest.DONE) {
            if (200 === req.status && req.response) {
                cb(null, req.response);
            } else {
                cb(new FrameworkHttpError(req.status), null);
            }
        }
    };
    req.send();
}

export function procore_fetch_schedule(
    token: string,
    project_id: number,
    company_id: number,
    cb: (error: Error, result: ArrayBuffer, contentType) => void,
) {
    const req = new XMLHttpRequest();
    req.responseType = "arraybuffer";
    req.open(
        "GET",
        SERVICES.PROCORE_SERVICE +
            "/rest/v1.0/schedule_integration/download?" +
            [["project_id", encodeURIComponent(project_id)].join("=")].join("&"),
    );
    //req.setRequestHeader("content-type", "application/json");
    if (token) {
        req.setRequestHeader("Authorization", ["Bearer", token].join(" "));
    }
    if (company_id) {
        const value = company_id.toString();
        req.setRequestHeader("Procore-Company-Id", value);
    }
    req.onreadystatechange = () => {
        if (req.readyState === XMLHttpRequest.DONE) {
            if (200 === req.status && req.response) {
                const contentType = req.getResponseHeader("content-type");
                cb(null, req.response, contentType);
            } else {
                cb(new FrameworkHttpError(req.status), null, null);
            }
        }
    };
    req.send();
}

export function procore_put_schedule(
    token: string,
    project_id: number,
    company_id: number,
    buffer: ArrayBuffer,
    cb: (error: Error, result: any) => void,
) {
    const formData = new FormData();
    formData.append(
        "schedule_integration[file]",
        new Blob([buffer], {
            type: "application/xml",
        }),
        "lcmdigital_export.xml",
    );
    const req = new XMLHttpRequest();
    req.responseType = "json";
    const _url = SERVICES.PROCORE_SERVICE + "/rest/v1.0/schedule_integration?";
    const url = _url + [["project_id", encodeURIComponent(project_id)].join("=")].join("&");
    req.open("PUT", url);
    //req.setRequestHeader("content-type", "multipart/form-data"); DO NOT SET. WILL BE SET IMPLICITLY
    if (token) {
        req.setRequestHeader("Authorization", ["Bearer", token].join(" "));
    }
    if (company_id) {
        const value = company_id.toString();
        req.setRequestHeader("Procore-Company-Id", value);
    }
    req.onreadystatechange = () => {
        if (req.readyState === XMLHttpRequest.DONE) {
            if (200 === req.status && req.response) {
                cb(null, req.response);
            } else {
                cb(new FrameworkHttpError(req.status), null);
            }
        }
    };
    req.send(formData);
}

export function getAttachmentUrl(a: { blobId: string; contentType?: string }) {
    const url =
        SERVICES?.ATTACHMENT_SERVICE && a?.blobId
            ? SERVICES.ATTACHMENT_SERVICE +
              "?blobId=" +
              a.blobId +
              (a.contentType ? "&contentType=" + encodeURIComponent(a.contentType) : "")
            : undefined;
    return url;
}
