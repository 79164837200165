export interface IChange {
    _: number;
    value: any;
    key: string;
    day: number;
    date: Date;
    userId: string;
    cardId: string[];
    type: "add" | "edit" | "delete";
    prop: any;
    fail?: number;
    hasAction?: string[];
    conflict?: string[];
}

export type HistoryTarget = "area" | "process" | "card" | "trade" | "comment" | "hive" | "actionItem" | "stability";

export interface HistoryMeta {
    action: HistoryActionName;
    events: HistoryChangeEvent[];
    epochDay: number;
    target: HistoryTarget;
    hasAction?: any[];
}

export interface Paginator<T> {
    data: T;
    meta: {
        next: number;
        current: number;
    };
}

export enum HistoryActionName {
    STRIPEY_MOVE = "stripey",
    AREA_CREATED = "areaCreated",
    AREA_DELETED = "areaDeleted",
    AREA_MULTI_DELETED = "areaMultiDeleted",
    AREA_MOVED = "areaMoved",
    AREA_RENAMED = "areaRenamed",
    MULTI_DELETED = "multiDeleted",
    MULTI_START_EDIT = "multiStartEdit",
    MULTI_NAME_EDIT = "multiNameEdit",
    PROCESS_CREATED = "processCreated",
    PROCESS_DELETED = "processDeleted",
    PROCESS_START_AND_DAYS_EDIT = "processStartAndDaysEdit",
    PROCESS_STARTDATE_EDIT = "processStartDateEdit",
    PROCESS_DAYS_EDIT = "processDaysEdit",
    MULTI_START_AND_DAYS_EDIT = "multiStartAndDaysEdit",
    MULTI_STARTDATE_EDIT = "multiStartDateEdit",
    MULTI_DAYS_EDIT = "multiDaysEdit",
    PROCESS_NAME_EDIT = "processNameEdit",
    PROCESS_TRADE_EDIT = "processTradeEdit",
    PROCESS_WORKFORCE_EDIT = "processWorkForce",
    MULTI_PROCESS_WORKFORCE_EDIT = "processMultiWorkForce",
    PROCESS_COMMENT_CREATED = "processCommentCreated",
    PROCESS_COMMENT_EDIT = "processCommentEdit",
    PROCESS_COMMENT_DELETED = "processCommentDeleted",
    MULTI_PROCESS_CREATED = "multiProcessCreated",
    TRADE_CREATED = "tradeCreated",
    TRADE_NAME_EDIT = "tradeNameEdit",
    TRADE_COLOR_EDIT = "tradeColorEdit",
    CARD_STATUS_EDIT = "cardStatusEdit",
    CARD_DELETED = "cardDeleted",
    CARD_ADDED = "cardAdded",
    ACTION_ITEM_CREATED = "actionItemCreated",
    ACTION_ITEM_DELETED = "actionItemDeleted",
    ACTION_ITEM_EDIT = "actionItemEdit",
    STABILITY_STATUS_EDIT = "stabilityStatusEdit",
    PROCESS_CUT = "processCut",
    PROCESS_PASTE = "processPaste",
    MULTI_PROCESS_PASTE = "multiProcessPaste",
    MULTI_PROCESS_CUT = "multiProcessCut",
    MULTI_TRADE_EDIT = "multiTradeEdit",
    /* TODO: Implement actions
     CONFLICT_RESOLVED = "conflictResolved",
     PROCESS_(STATUS)_EDIT = "process(Status)Edit",
     */
}
// copied from core, should be imported from core package
export interface HistoryChangeEvent {
    _: number;
    group: boolean | undefined;
    rejected: boolean | undefined;
    target: HistoryTarget;
    id: number;
    // prop: string | {
    //     target: "card";
    //     aid: number;
    //     cid: number | null;
    //     name: string;
    // } | {
    //     target: "trade";
    //     trId: number;
    // } | {
    //     target: "rel";
    //     relId: number;
    // } | {
    //     target: "comment";
    //     commentId: number;
    // } | {
    //     target: "ai";
    //     ai: number;
    //     name: string | null;
    // } | string[];
    prop: string;
    value: any;
    unit?: number;
    date?: number;
    userId?: string;
    sub?: any;
    // user?: IUser;
    // prevValue?: any;
    // prevUnit?: any;
    // prevStart: Date;
    // prevEnd: Date;
    // start: Date;
    // end: Date;
    created: boolean;
    processName: string;
    tradeName: string;
    actionItemId?: string;
    card?: { aid: number; cid: number; prop: string };
}
