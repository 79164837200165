import * as React from "react";
import { mergeStyleSets } from "@fluentui/react";
import {
    CanvasHostBase,
    CanvasHostBasePointerEvent,
    CanvasHostBaseProps,
    CanvasHostHitResult,
    CanvasHostRenderer,
    CanvasHostRendererCtx,
    CanvasHostRendererDragInfo,
    CanvasHostRendererDropInfo,
    CanvasHostRendererSidebarItem,
    CanvasHostRenderNode,
    CanvasHostStripeRenderNode,
    CanvasStripeData,
    CanvasTaskData,
    EpochMStoEpochDays,
    ICanvasHostRenderer,
    intl,
    needsWhiteColor,
} from "lcmd2framework";
import { MainWorkerMessageHandler } from "@/legacy/MainWorkerPipe";
import { LCMDContextDependencyType } from "@/app/LCMDContextTypes";
import { useCanvasTooltipStore } from "@/app/store/tooltipStore";
import { useCanvasStore } from "@/app/store/canvasStore";
import { useUserJourneyStore } from "@/app/store/userJourneyStore";
import { useConflictStore } from "@/app/store/conflictsStore";
import { tradeImagesMap } from "@/components/Sidebar/TradeImages";
import { generateCanvasCalendarIntl } from "@/legacy/GlobalHelperFluentUI";
import { weekStart } from "@/utils/DateUtils";
import { hasTouchSupport } from "@/utils/device";
import { SidebarMenuItem } from "./Sidebar/interface";
import { ViewTypes } from "@/components/view/constants";
import { WebAppTelemetryFactory } from "@/app/services/WebAppTelemetry.service";
import { toast } from "sonner";

const MIN_WIDTH = 10;
const MIN_TASK_WIDTH = 4;
const MIN_HEIGHT = 10;

const MIN_LINE_SCALE = 0.25;
const GRID_WIDTH = 2;
const GRID_COLOR = "#f1f3f3";

const DETAILS_FONT = "400 6pt Roboto";
const CANVAS_BUBBLE_FONT = "400 10pt Roboto";
const CANVAS_CONFLICT_FONT = "600 11px Roboto";

const classNames = mergeStyleSets({
    overlayHack: {
        //transition: "left 0 ease",
        position: "absolute",
        left: 0,
        top: 0,
        width: 1,
        height: 0,
        backgroundColor: "red",
    },
    overlayHackBubble: {
        position: "absolute",
        left: 0,
        top: 0,
        width: "auto",
        height: "auto",
        font: "400 120x Roboto",
        textAlign: "left",
        backgroundColor: "red",
        color: "white",
        whiteSpace: "pre",
    },
});

type CanvasProjectHostProps = CanvasHostBaseProps & {
    onTaktzoneClicked: (taktzone: number[]) => void;
};

type Rect = {
    x: number;
    y: number;
    right: number;
    bottom: number;
    w: number;
    h: number;
};

export class CanvasProjectHost extends CanvasHostBase<CanvasProjectHostProps> implements ICanvasHostRenderer {
    private hoverTimeoutIndex = null;
    private hoveredPid = null;
    private intl = null;

    private weekDaysShort;

    constructor(props: CanvasProjectHostProps) {
        super(props, {
            zOrderStripes: false,
            xGroupTasks: false,
            eventHitTest: false,
        });
        this._renderer.setRenderer(this);
        this.intl = generateCanvasCalendarIntl();
        const shortdays = this.intl.shortDays2;

        this.weekDaysShort = [
            shortdays?.sun,
            shortdays?.mon,
            shortdays?.tue,
            shortdays?.wed,
            shortdays?.thu,
            shortdays?.fri,
            shortdays?.sat,
        ];
        // this._overlayHack=this.renderOverlayHack.bind(this);
    }

    private _getSidebarAreaInViewY(
        rc: CanvasHostRendererCtx,
        y: number,
        sidebarArea: CanvasHostRendererSidebarItem,
        padding: number,
        offset: number = 0,
    ): number {
        const sidebarAreaY = (sidebarArea.top - rc.view.y1) * rc.view.scale - rc.view.insetTop - padding + offset;

        if (sidebarAreaY <= -(sidebarArea.height * rc.view.scale)) {
            return 0;
        } else if (sidebarAreaY <= 0) {
            return rc.view.insetTop + padding;
        }

        return y;
    }

    private _renderRoundRectWithText(
        ctx: CanvasRenderingContext2D,
        text: string,
        textColor: string,
        x: number,
        y: number,
        paddingX: number,
        paddingY: number,
        radii: number,
        rectColor: string,
        rotate = false,
    ) {
        // TODO: add logic for emulating lineHeight
        const { width: w, height: h, textHeight } = this._measureLabelDimensions(ctx, text, paddingX, paddingY);

        if (rotate) {
            ctx.save();
            ctx.translate(x + paddingX, y + h / 2 + paddingY);
            ctx.rotate(Math.PI / 2);
            ctx.translate(-x - paddingX, -y - h / 2 + paddingY / 4);
        }

        ctx.beginPath();
        ctx.fillStyle = rectColor;
        ctx.roundRect(x, y, w, h, radii);
        ctx.fill();

        ctx.fillStyle = textColor;
        ctx.fillText(text, x + paddingX, y + paddingY + textHeight);

        if (rotate) {
            ctx.restore();
        }

        return { width: w, height: h };
    }

    private _measureLabelDimensions(ctx: CanvasRenderingContext2D, text: string, paddingX: number, paddingY: number) {
        const { width: textWidth, actualBoundingBoxAscent: textHeight } = ctx.measureText(text);
        const width = textWidth + paddingX + paddingX;
        const height = textHeight + paddingY + paddingY;

        return { width, height, textWidth, textHeight };
    }

    private _drawArrowIcon(
        ctx: CanvasRenderingContext2D,
        iconX: number,
        iconY: number,
        iconSize: number,
        rotationDegrees: number = 0,
    ): void {
        ctx.save();

        if (rotationDegrees !== 0) {
            const rotationRadians = (rotationDegrees * Math.PI) / 180;
            const centerX = iconX + iconSize / 2;
            const centerY = iconY + iconSize / 2;

            ctx.translate(centerX, centerY);
            ctx.rotate(rotationRadians);
            ctx.translate(-centerX, -centerY);
        }

        ctx.strokeStyle = "#9CA3AF";
        ctx.lineWidth = 1.5;
        ctx.lineCap = "round";
        ctx.lineJoin = "round";

        ctx.beginPath();
        const points = [
            { xRatio: 0.375, yRatio: 0.75 },
            { xRatio: 0.625, yRatio: 0.5 },
            { xRatio: 0.375, yRatio: 0.25 },
        ];
        ctx.moveTo(iconX + points[0].xRatio * iconSize, iconY + points[0].yRatio * iconSize);

        for (let i = 1; i < points.length; i++) {
            ctx.lineTo(iconX + points[i].xRatio * iconSize, iconY + points[i].yRatio * iconSize);
        }
        ctx.stroke();

        ctx.restore();
    }

    private _renderCollapseIcon(
        ctx: CanvasRenderingContext2D,
        x: number,
        y: number,
        p: number,
        iconSize: number,
        isRotated: boolean,
    ): void {
        const iconX = x + (isRotated ? p * 2 : p); // if item is rotated, we need to move icon to the right to center it
        const iconY = y + p;

        this._drawArrowIcon(ctx, iconX, iconY, iconSize, 90);
    }

    private _renderExpandIcon(ctx: CanvasRenderingContext2D, x: number, y: number, p: number, iconSize: number): void {
        const iconX = x + p;
        const iconY = y + p;
        this._drawArrowIcon(ctx, iconX, iconY, iconSize, 0);
    }

    private _renderSidebar(rc: CanvasHostRendererCtx, ctx: CanvasRenderingContext2D): void {
        const showBaseline = useUserJourneyStore.getState().userView === ViewTypes.BASELINE;
        const scale = rc.view.scale;
        const info = rc.sidebarL;
        const n_info = info.length;
        let l_max = 0;
        const S_MIN_HEIGHT = MIN_HEIGHT / scale;
        while (l_max < n_info && (!info[l_max] || info[l_max].m >= S_MIN_HEIGHT)) l_max++;
        const max_l = l_max * rc.A.sidebarColWidth;
        const view_h = rc.view.y2 - rc.view.y1;
        ctx.strokeStyle = GRID_COLOR;
        ctx.lineWidth = GRID_WIDTH;
        const sidebar = rc.sidebar;
        const n_sidebar = sidebar.length;
        const width = (rc.l_max - rc.l_min) * rc.A.sidebarColWidth + rc.A.sidebarColExtra + rc.C.sidebarColImage;
        const height = (rc.stripes.length > 0 ? rc.stripes[rc.stripes.length - 1].e : 0) * rc.C.rowPx;
        ctx.fillStyle = rc.C.gpa.canvasColor;
        ctx.fillRect(0, 0, width, rc.view.y2 - rc.view.y1);
        for (let i_sidebar = 0; i_sidebar < n_sidebar; i_sidebar++) {
            const item = sidebar[i_sidebar];
            const l = item.left;
            if (l <= max_l) {
                const t = (item.top - rc.view.y1) * scale;
                const leaf = l >= max_l;
                const w = leaf ? width - l : item.width;
                const h = item.height * scale;
                const nv = t + h < 0 || t > view_h;

                if (nv) {
                    continue;
                }

                ctx.fillStyle = item.color;
                ctx.fillRect(l, t, w, h);
                ctx.fillStyle = rc.C.gpa.task.label.black;
                const p = 2;
                const mt = 3;
                const expandCollapseIconSize = 14; // in px
                const expandCollapseIconTotalWidth = expandCollapseIconSize + p * 2;

                const baselineLabelWidth = showBaseline
                    ? this._measureLabelDimensions(ctx, intl.get("CanvasProjectHost.baseline"), 8, 5).width
                    : 0;

                const currentLabelWidth = showBaseline
                    ? this._measureLabelDimensions(ctx, intl.get("CanvasProjectHost.current"), 8, 5).width
                    : 0;

                const delayDaysLabel = item.stripe?.delayDays ? "+" + item.stripe?.delayDays + "d" : "";
                const delayDaysLabelWidth = showBaseline
                    ? this._measureLabelDimensions(ctx, delayDaysLabel, 8, 5).width
                    : 0;

                const maxLabelWidth = Math.max(baselineLabelWidth, currentLabelWidth);
                // const viewY = rc.view.y1 * rc.view.scale - rc.C.colHeaderHeight;
                // const viewY = (rc.view.y1 + rc.C.colHeaderHeight + rc.view.insetTop) * rc.view.scale;
                const renderSidebarColImage = l + w === width && !item.stripe.collapsed;
                const sidebarColImage = renderSidebarColImage ? rc.C.sidebarColImage : 0;
                const image_s = sidebarColImage > 0 ? Math.min(sidebarColImage, sidebarColImage * rc.view.scale) : 0;
                const { totalW, totalH, finalY } = CanvasHostRenderer.renderSegments(
                    ctx,
                    item.segs,
                    l + p + (!item.rotated ? expandCollapseIconTotalWidth : 0),
                    t + (item.rotated ? expandCollapseIconTotalWidth : mt + p),
                    w - image_s - (!item.rotated ? expandCollapseIconTotalWidth + maxLabelWidth + p * 5 : 0),
                    h - p - p,
                    1,
                    !leaf && item.rotated,
                    true,
                    0,
                    0,
                    false,
                    {
                        insetTop: !item.rotated
                            ? rc.view.insetTop + p + mt
                            : rc.view.insetTop + expandCollapseIconTotalWidth + p,
                    },
                );

                if (showBaseline && !item.rotated) {
                    ctx.font = "600 10px Inter";
                    this._renderRoundRectWithText(
                        ctx,
                        intl.get("CanvasProjectHost.baseline"),
                        "rgb(79, 58, 177)",
                        Math.min(
                            l + w - (image_s || -p) - maxLabelWidth - p * 2.5,
                            l + p * 3 + totalW + expandCollapseIconTotalWidth,
                        ),
                        item.segs ? finalY - totalH + p / 2 : this._getSidebarAreaInViewY(rc, t + mt, item, mt),
                        8,
                        5,
                        4,
                        "rgb(237, 233, 254)",
                    );
                } else if (showBaseline && item.rotated) {
                    ctx.font = "600 10px Inter";
                    ctx.save();
                    this._renderRoundRectWithText(
                        ctx,
                        intl.get("CanvasProjectHost.current"),
                        "black",
                        l + p * 3,
                        item.segs
                            ? this._getSidebarAreaInViewY(
                                  rc,
                                  t + totalW + p + p + expandCollapseIconTotalWidth,
                                  item,
                                  totalW + expandCollapseIconTotalWidth + p,
                                  totalW + expandCollapseIconTotalWidth + p,
                              )
                            : this._getSidebarAreaInViewY(rc, t + totalW + p, item, p),
                        8,
                        5,
                        4,
                        "rgb(226, 232, 240)",
                        true,
                    );

                    if (item.stripe?.delayDays > 0) {
                        const { width: w, height: h, textHeight } = this._measureLabelDimensions(ctx, "Current", 8, 5);
                        this._renderRoundRectWithText(
                            ctx,
                            delayDaysLabel,
                            "#DC2626",
                            l + p * 3,
                            item.segs
                                ? this._getSidebarAreaInViewY(
                                      rc,
                                      t + totalW + delayDaysLabelWidth + 20 + p + p + expandCollapseIconTotalWidth,
                                      item,
                                      totalW + delayDaysLabelWidth + expandCollapseIconTotalWidth + 20 + p,
                                      totalW + delayDaysLabelWidth + expandCollapseIconTotalWidth + 20 + p,
                                  )
                                : this._getSidebarAreaInViewY(rc, t + totalW + delayDaysLabelWidth + 20 + p, item, p),
                            8,
                            5,
                            4,
                            "#FEE2E2",
                            true,
                        );
                    }

                    ctx.restore();
                }

                if (showBaseline && !item.rotated) {
                    // this is for situation when baseline and current tags are on same positions
                    // so user don't see tag below
                    ctx.fillStyle = item.color;
                    ctx.fillRect(l + p, t + p + (h - p - p) / 2, w - p, h / 2 - p - p);
                    // ===

                    ctx.fillStyle = "black";
                    const { finalY: finalBaselineY } = CanvasHostRenderer.renderSegments(
                        ctx,
                        item.segs,
                        l + p + expandCollapseIconTotalWidth,
                        t + p + (h - p - p) / 2 + mt,
                        w - image_s - (!item.rotated ? expandCollapseIconTotalWidth + maxLabelWidth + p * 3 : 0),
                        h - p - p,
                        1,
                        !leaf && item.rotated,
                        true,
                        0,
                        0,
                        false,
                        {
                            insetTop: rc.view.insetTop + p + mt,
                        },
                    );

                    ctx.font = "600 10px Inter";

                    const labelPos = Math.min(
                        l + w - (image_s || -p) - maxLabelWidth - p / 2,
                        l + p * 3 + totalW + expandCollapseIconTotalWidth,
                    );

                    this._renderRoundRectWithText(
                        ctx,
                        intl.get("CanvasProjectHost.current"),
                        "black",
                        labelPos,
                        item.segs
                            ? finalBaselineY - totalH + p / 2
                            : this._getSidebarAreaInViewY(
                                  rc,
                                  t + p + (h - p - p) / 2,
                                  item,
                                  mt,
                                  p + (h - p - p) / 2 + mt,
                              ),
                        8,
                        5,
                        4,
                        "rgb(226, 232, 240)",
                    );

                    if (item.stripe?.delayDays > 0) {
                        this._renderRoundRectWithText(
                            ctx,
                            delayDaysLabel,
                            "#DC2626",
                            labelPos + maxLabelWidth,
                            item.segs
                                ? finalBaselineY - totalH + p / 2
                                : this._getSidebarAreaInViewY(
                                      rc,
                                      t + p + (h - p - p) / 2,
                                      item,
                                      mt,
                                      p + (h - p - p) / 2 + mt,
                                  ),
                            5,
                            5,
                            4,
                            "#FEE2E2",
                        );
                    }
                }

                // Render expand/collapse icon
                const iconY = this._getSidebarAreaInViewY(rc, t + p, item, p) - p;
                if (item.rotated) {
                    this._renderCollapseIcon(ctx, l + p / 2, iconY - p, p, expandCollapseIconSize, true);
                } else {
                    if (item.stripe.collapsed) {
                        this._renderExpandIcon(
                            ctx,
                            l + p + (!item.segs ? p / 2 : 0),
                            iconY + mt,
                            p,
                            expandCollapseIconSize,
                        );
                    } else {
                        this._renderCollapseIcon(
                            ctx,
                            l + p + (!item.segs ? p / 2 : 0),
                            iconY + mt,
                            p,
                            expandCollapseIconSize,
                            false,
                        );
                    }
                }

                if (renderSidebarColImage) {
                    if (item.stripe._gpa) {
                        ctx.save();
                        const t_x1 = l + p;
                        const t_y1 = t + p;
                        const t_w = w - sidebarColImage - p - p;
                        const t_h = h - p - p;
                        const i_x1 = l + w - (image_s ? image_s + p : 0) - p / 2;
                        const i_y1 = t + p;
                        const i_w = image_s - p - p;
                        const i_h = image_s - p - p;

                        const r_x1 = t_x1 + totalW + p;
                        const r_y1 = t;
                        const r_x2 = i_x1;
                        const r_h = h;

                        const b_x1 = t_x1;
                        const b_y1 = t_y1 + totalH;
                        const b_x2 = i_x1;
                        const b_y2 = t + h - p;

                        const name = item.stripe._gpa.name || "";
                        ctx.font = CANVAS_BUBBLE_FONT;
                        const m = ctx.measureText(name);
                        const _p = 4;
                        let y1;
                        let x2;
                        if (m.width + _p + _p <= r_x2 - r_x1) {
                            x2 = r_x2;
                            y1 = r_y1;
                        } else {
                            x2 = b_x2;
                            y1 = b_y1;
                        }
                        const x1 = x2 - (m.width + _p + _p);
                        const y2 = y1 + m.actualBoundingBoxAscent + m.actualBoundingBoxDescent + _p + _p;

                        ctx.fillStyle = "#EEDFF7";
                        ctx.fillRect(x1, y1, x2 - x1, y2 - y1);
                        ctx.fillStyle = "#520980";
                        ctx.fillText(name, x1 + _p, y2 - m.actualBoundingBoxDescent - _p);
                        ctx.restore();
                    }
                    if (sidebarColImage) {
                        ctx.save();
                        ctx.strokeStyle = item.stripe._image ? "red" : "gray";
                        const image = item.stripe._image
                            ? this.getImage(item.stripe._image, sidebarColImage, sidebarColImage)
                            : null;
                        if (image) {
                            const __w = image.width as number;
                            const __h = image.height as number;
                            const s = Math.min(image_s / __w, image_s / __h);
                            const _w = Math.floor(image.width as number) * s;
                            const _h = Math.floor(image.height as number) * s;
                            ctx.drawImage(image, l + w - _w - p * 2, t + p, _w, _h);
                        } else {
                            if ("number" === typeof item.stripe._gpa?.color) {
                                ctx.fillStyle = "#" + item.stripe._gpa.color.toString(16).padStart(6, "0");
                                ctx.fillRect(l + w - image_s - p, t + p, image_s - p - p, image_s - p - p);
                            }
                            ctx.strokeRect(l + w - image_s - p, t + p, image_s - p - p, image_s - p - p);
                        }
                        ctx.restore();
                    }
                }
                ctx.strokeRect(l, t, w, h);
            }
        }

        ctx.strokeRect(0, (0 - rc.view.y1) * scale, width, height * scale);

        ctx.fillStyle = "#FFFFFF";
        ctx.fillRect(0, rc.A.titleHeight + rc.A.subtitleHeight, width, rc.C.colHeaderHeight);
        ctx.strokeRect(0, rc.A.titleHeight + rc.A.subtitleHeight, width, rc.C.colHeaderHeight);

        // HACK remove text that is outside of sidebar
        ctx.fillStyle = rc.C.gpa.canvasColor;
        ctx.fillRect(0, (height - rc.view.y1) * rc.view.scale, width, (rc.view.y2 - rc.view.y1) / rc.view.scale);
    }

    private _renderHeader(rc: CanvasHostRendererCtx, ctx: CanvasRenderingContext2D): void {
        const scale = rc.view.scale;
        const y = rc.A.titleHeight + rc.A.subtitleHeight;
        ctx.fillStyle = rc.C.gpa.canvasColor;
        ctx.fillRect(0, y, rc.view.x2 - rc.view.x1, rc.C.colHeaderHeight);
        const width = rc.grid.view.cols * rc.C.colPx;
        const h = rc.view.y2 - rc.view.y1;
        const header = rc.header;
        const a = header.weeks;
        const n_a = Array.isArray(a) ? a.length : 0;
        const b = header.month;
        const n_b = Array.isArray(b) ? b.length : 0;

        ctx.strokeStyle = GRID_COLOR;
        ctx.lineWidth = GRID_WIDTH;
        ctx.save();

        ctx.scale(scale, 1);
        ctx.translate(-rc.view.x1, 0);
        const S_MIN_WIDTH = MIN_WIDTH / scale;
        const S_MIN_HEIGHT = MIN_HEIGHT / scale;

        const NV_X1 = rc.view.x1;
        const NV_X2 = NV_X1 + (rc.view.x2 - rc.view.x1) / scale;
        for (let i_b = 0; i_b < n_b && b[i_b].left <= width; i_b++) {
            const x1 = b[i_b].left;
            const x2 = Math.min(i_b + 1 < n_b ? b[i_b + 1].left : width, width);
            const l = x1;
            const t = y;
            const w = x2 - x1;
            const h = rc.C.colHeaderHeight1;
            const nv = l + w < NV_X1 || l > NV_X2;
            if (w > S_MIN_WIDTH && h > MIN_HEIGHT && !nv) {
                const p = 2;
                CanvasHostRenderer.renderSegments(
                    ctx,
                    [0, 0, b[i_b].text],
                    l + p,
                    t + p,
                    w - p - p,
                    h - p - p,
                    1,
                    false,
                    false,
                    0,
                    0,
                    false,
                );
                ctx.strokeRect(l, t, w, h);
            }
        }

        ctx.fillStyle = "#FFFFFF";
        for (let i_a = 0; i_a < n_a && a[i_a].left <= width; i_a++) {
            const x1 = a[i_a].left;
            const x2 = Math.min(i_a + 1 < n_a ? a[i_a + 1].left : width, width);
            const l = x1;
            const t = y + rc.C.colHeaderHeight1;
            const w = x2 - x1;
            const h = rc.C.colHeaderHeight2;
            const nv = l + w < NV_X1 || l > NV_X2;
            if (false !== a[i_a].wd && w > S_MIN_WIDTH && h > MIN_HEIGHT && !nv) {
                ctx.fillRect(l, t, w, h);
                ctx.strokeRect(l, t, w, h);
            }
        }
        ctx.strokeRect(0, y, width, rc.C.colHeaderHeight);
        ctx.restore();
        ctx.fillStyle = "black";
        const view_w = rc.view.x2 - rc.view.x1;
        for (let i_b = 0; i_b < n_b && b[i_b].left <= width; i_b++) {
            const x1 = b[i_b].left;
            const x2 = Math.min(i_b + 1 < n_b ? b[i_b + 1].left : width, width);
            const l = (x1 - rc.view.x1) * scale;
            const t = y;
            const w = (x2 - x1) * scale;
            const h = rc.C.colHeaderHeight1;
            const m = b[i_b].m;
            const nv = l + w < 0 || l > view_w;
            if (w > MIN_WIDTH && h > MIN_HEIGHT && !nv) {
                const p = 2;
                if (m) {
                    CanvasHostRenderer.renderSegments(
                        ctx,
                        [0, 0, [b[i_b].text, b[i_b].m, b[i_b].y].join(" ")],
                        l + p,
                        t + p,
                        w - p - p,
                        h - p - p,
                        1,
                        false,
                        true,
                        0,
                        0,
                        false,
                    );
                } else {
                    CanvasHostRenderer.renderSegments(
                        ctx,
                        [0, 0, b[i_b].text],
                        l + p,
                        t + p,
                        w - p - p,
                        h - p - p,
                        1,
                        false,
                        true,
                        0,
                        0,
                        false,
                    );
                }
            }
        }
        for (let i_a = 0; i_a < n_a && a[i_a].left <= width; i_a++) {
            const d = this.weekDaysShort[a[i_a].d];
            const x1 = a[i_a].left;
            const x2 = Math.min(i_a + 1 < n_a ? a[i_a + 1].left : width, width);
            const l = (x1 - rc.view.x1) * scale;
            const t = y + rc.C.colHeaderHeight1;
            const w = (x2 - x1) * scale;
            const h = rc.C.colHeaderHeight2 / (d ? 2 : 1);
            const nv = l + w < 0 || l > view_w;
            if (w > MIN_WIDTH && h > MIN_HEIGHT && !nv) {
                const p = 2;
                if (d) {
                    CanvasHostRenderer.renderSegments(
                        ctx,
                        [0, 0, d],
                        l + p,
                        t + p,
                        w - p - p,
                        h - p - p,
                        1,
                        false,
                        true,
                        0,
                        0,
                        false,
                    );
                    CanvasHostRenderer.renderSegments(
                        ctx,
                        [0, 0, a[i_a].text],
                        l + p,
                        t + h + p,
                        w - p - p,
                        h - p - p,
                        1,
                        false,
                        true,
                        0,
                        0,
                        false,
                    );
                } else {
                    CanvasHostRenderer.renderSegments(
                        ctx,
                        [0, 0, a[i_a].text],
                        l + p,
                        t + p,
                        w - p - p,
                        h - p - p,
                        1,
                        false,
                        true,
                        0,
                        0,
                        false,
                    );
                }
            }
        }
    }

    public renderOverlay(rc: CanvasHostRendererCtx, ctx: CanvasRenderingContext2D) {
        if (rc.header) {
            this._renderHeader(rc, ctx);
        }

        if (rc.sidebar) {
            this._renderSidebar(rc, ctx);
        }

        this._restoreSessionSplitView();
    }

    public renderHell(rc: CanvasHostRendererCtx, ctx: CanvasRenderingContext2D) {}

    public renderHeaven(rc: CanvasHostRendererCtx, ctx: CanvasRenderingContext2D, isVisible: boolean) {
        const h = (rc.stripes.length > 0 ? rc.stripes[rc.stripes.length - 1].e : 0) * rc.C.rowPx;
        if (!isVisible) {
            return;
        }
        this.renderStripeGrid(rc, ctx, h);
    }

    private renderStripeGrid(rc: CanvasHostRendererCtx, ctx: CanvasRenderingContext2D, h: number) {
        const w = rc.grid.view.cols * rc.C.colPx;
        const S_MIN_HEIGHT = MIN_HEIGHT / rc.view.scale;
        if (h >= S_MIN_HEIGHT) {
            const header = rc.header;
            const a = header.weeks;
            const n_a = Array.isArray(a) ? a.length : 0;
            ctx.fillStyle = "#f5f5f5";
            ctx.strokeStyle = GRID_COLOR;
            ctx.lineWidth = GRID_WIDTH;
            let isNonWorkDay = false;
            for (let i_a = 0; i_a < n_a && a[i_a].left <= w; i_a++) {
                ctx.beginPath();

                if (false === a[i_a].wd && a[i_a].left + a[i_a].width < w) {
                    ctx.fillRect(a[i_a].left, 0, a[i_a].width, h);

                    if (isNonWorkDay) {
                        ctx.strokeStyle = "white";
                        ctx.moveTo(a[i_a].left, 0);
                        ctx.lineTo(a[i_a].left, h);
                        ctx.stroke();
                        ctx.strokeStyle = GRID_COLOR;
                    } else {
                        ctx.moveTo(a[i_a].left, 0);
                        ctx.lineTo(a[i_a].left, h);
                        ctx.stroke();
                        isNonWorkDay = true;
                    }
                    continue;
                }

                // @todo: not correct for countries where the week starts at Sunday
                if (a[i_a].d === weekStart) {
                    ctx.lineWidth = GRID_WIDTH * 3;
                    ctx.moveTo(a[i_a].left, 0);
                    ctx.lineTo(a[i_a].left, h);
                    ctx.stroke();
                    ctx.lineWidth = GRID_WIDTH;
                } else {
                    ctx.moveTo(a[i_a].left, 0);
                    ctx.lineTo(a[i_a].left, h);
                    ctx.stroke();
                }

                isNonWorkDay = false;
            }
        }

        for (let i = 0; i < Math.floor(h / rc.C.rowPx); i++) {
            ctx.moveTo(rc.header.weeks[0].left, i * rc.C.rowPx);
            ctx.lineTo(w, i * rc.C.rowPx);
        }
        ctx.stroke();
    }

    private renderTodayLine(rc: CanvasHostRendererCtx, ctx: CanvasRenderingContext2D, h) {
        if (undefined !== rc.today) {
            const x = rc.today;
            ctx.strokeStyle = "black";
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(x * rc.C.colPx + 1, 0);
            ctx.lineTo(x * rc.C.colPx + 1, h);
            ctx.stroke();
        }
    }

    public renderEnd(rc: CanvasHostRendererCtx, ctx: CanvasRenderingContext2D) {
        const h = (rc.stripes.length > 0 ? rc.stripes[rc.stripes.length - 1].e : 0) * rc.C.rowPx;
        this.renderTodayLine(rc, ctx, h);
    }

    public renderStripeHell(
        rc: CanvasHostRendererCtx,
        ctx: CanvasRenderingContext2D,
        stripe: CanvasStripeData & CanvasHostStripeRenderNode,
        i_stripe: number,
    ) {
        const showBaseline = useUserJourneyStore.getState().userView === ViewTypes.BASELINE;

        if (!showBaseline) {
            if (0 === i_stripe % 2) {
                ctx.fillStyle = "#ffffff";
                ctx.fillRect(stripe.__x, stripe.__y, stripe.__w, stripe.__h);
            }

            return;
        }

        if (0 === (i_stripe + 1) % 4) {
            ctx.fillStyle = "rgb(249, 250, 251)";
            ctx.fillRect(stripe.__x, stripe.__y, stripe.__w, stripe.__h);
        } else if (0 === (i_stripe + 2) % 4) {
            ctx.fillStyle = "rgb(249, 250, 251)";
            ctx.fillRect(stripe.__x, stripe.__y, stripe.__w, stripe.__h);
        } else {
            ctx.fillStyle = "#ffffff";
            ctx.fillRect(stripe.__x, stripe.__y, stripe.__w, stripe.__h);
        }
    }

    public renderStripeBegin(
        rc: CanvasHostRendererCtx,
        ctx: CanvasRenderingContext2D,
        stripe: CanvasStripeData & CanvasHostStripeRenderNode,
        i_stripe: number,
    ) {
        const S_MIN_HEIGHT = MIN_HEIGHT;
        if (stripe.__h >= S_MIN_HEIGHT) {
            ctx.strokeStyle = GRID_COLOR;
            ctx.lineWidth = GRID_WIDTH;
            ctx.beginPath();
            if (0 === stripe.y) {
                ctx.moveTo(stripe.__x, stripe.__y);
                ctx.lineTo(stripe.__w, stripe.__y);
            }
            ctx.moveTo(stripe.__x, stripe.__y + stripe.__h);
            ctx.lineTo(stripe.__w, stripe.__y + stripe.__h);
            ctx.stroke();
        }
    }

    public renderStripeEnd(
        rc: CanvasHostRendererCtx,
        ctx: CanvasRenderingContext2D,
        stripe: CanvasStripeData & CanvasHostStripeRenderNode,
    ) {}

    zoom(
        event: { deltaY: number; clientX: number; clientY: number; currentTarget: HTMLCanvasElement },
        pinchZoom?: boolean,
    ) {
        const { width, height } = this.state.reactState;
        const delta = event.deltaY < 0 ? 1 : -1;
        const step = pinchZoom ? 0.05 : Math.abs(event.deltaY) > 50 ? 0.2 : 0.05;
        const s = this.state.reactState.scale;
        const __s = s + delta * (s - s * step) * step;
        const _s = Math.max(
            Math.min(__s, 10),
            (height > width ? (window.innerHeight - 146) / height : (window.innerWidth - 471) / width) / 3,
        );

        useCanvasTooltipStore.getState().setInvisible();

        if (_s > 0) {
            const { currentTarget } = event;
            this.setState(
                {
                    ...this.state,
                    reactState: {
                        ...this.state.reactState,
                        scale: _s,
                        left: this.state.reactState.left - (event.clientX * (_s - s)) / s / _s,
                        top: this.state.reactState.top - (event.clientY * (_s - s)) / s / _s,
                    },
                },
                () => {
                    this._invalidateCanvasWithEvent({ ...event, currentTarget });
                },
            );
        }
    }

    private shrinkText(ctx: CanvasRenderingContext2D, text: string, width: number) {
        let low = 0;
        let high = text.length;

        while (low <= high) {
            const mid = Math.floor((low + high) / 2);
            const textWidth = ctx.measureText(text.slice(0, mid).trim()).width;

            if (Math.round(textWidth) === width) {
                return mid;
            } else if (textWidth > width) {
                high = mid - 1;
            } else {
                low = mid + 1;
            }
        }

        return high;
    }

    private _renderDepOffset(ctx: CanvasRenderingContext2D, conflictNumber: string, x, y): void {
        ctx.font = CANVAS_CONFLICT_FONT;

        const p = 7;
        const r = 15;
        const borderWidth = 2;
        const finalX = x + p + r + borderWidth;
        const finalY = y + p + r + borderWidth;
        const finalConflictNumber = conflictNumber.length > 2 ? "99+" : conflictNumber;
        const textWidth = ctx.measureText(finalConflictNumber).width;

        ctx.save();
        ctx.fillStyle = "#DC2626";
        ctx.lineWidth = borderWidth;
        ctx.strokeStyle = "white";

        ctx.beginPath();
        ctx.arc(finalX, finalY, r, 0, Math.PI * 2);
        ctx.fill();
        ctx.beginPath();
        ctx.arc(finalX, finalY, r + 1, 0, Math.PI * 2);
        ctx.stroke();
        ctx.fillStyle = "white";
        ctx.fillText(finalConflictNumber, finalX - textWidth / 2, finalY + 4);
        ctx.restore();
    }

    renderCollapsedStripe(rc: CanvasHostRendererCtx, ctx: CanvasRenderingContext2D, stripe: CanvasStripeData): void {
        if (stripe._x2 - stripe._x1 <= 0) {
            return;
        }

        ctx.save();
        const p = rc.C.label === "week" ? 4 : 2; // "week = daily view" and "month = weekly view" :(
        const x = stripe._x1 + p;
        const y = stripe.y + p;
        const w = stripe._x2 - stripe._x1 - p - p;
        const h = rc.C.rowPx - p - p;
        const showBaseline = useUserJourneyStore.getState().userView === ViewTypes.BASELINE;
        const isBaseline = showBaseline && stripe.baseline;
        const isRoundedCorner = rc.view.scale >= this._renderer.roundedCornersThreshold;
        const isTextHidden = rc.view.scale < this._renderer.textVisibilityThreshold;
        const hasDelayDays = stripe?.delayDays > 0;

        if (isBaseline) {
            ctx.fillStyle = "rgb(175, 175, 175)";
        } else {
            ctx.fillStyle = "rgb(100, 116, 139)";
        }

        if (isRoundedCorner) {
            ctx.beginPath();
            ctx.roundRect(x, y, w, h, 8);
            ctx.fill();
        } else {
            ctx.fillRect(x, y, w, h);
        }

        if (!isTextHidden) {
            ctx.font = "600 14px Inter";
            ctx.fillStyle = w ? rc.C.gpa.task.label.white : rc.C.gpa.task.label.black;
            const text = isBaseline ? `Baseline: ${stripe.label}` : stripe.label;
            ctx.fillText(text, this._getInViewTaskTextX(rc, ctx, text, x, w, 44, 44), y + h / 2 + 6);
        }

        if (showBaseline && !isBaseline && hasDelayDays) {
            this._renderDelayDaysInfo(ctx, x + 23, y + 24, 15, stripe.delayDays);
        }

        ctx.restore();
    }

    private _renderTaskStatusBar(
        ctx: CanvasRenderingContext2D,
        task: CanvasTaskData & CanvasHostStripeRenderNode,
        x: number,
        y: number,
        w: number,
        h: number,
        isDarkBg: boolean,
    ): void {
        const { p } = task;
        const lineWidth = 4;
        const paddingX = 12;
        const paddingY = 4;
        const lineY = y + h - paddingY - lineWidth / 2;
        const startX = x + paddingX;
        const lineLength = w - paddingX - paddingX;

        ctx.lineCap = "round";
        ctx.lineWidth = lineWidth;

        ctx.strokeStyle = isDarkBg ? "rgba(255, 255, 255, 0.16)" : "rgba(9, 9, 11, 0.16)";
        ctx.beginPath();
        ctx.moveTo(startX, lineY);
        ctx.lineTo(startX + lineLength, lineY);
        ctx.stroke();

        if (p > 0) {
            ctx.strokeStyle = isDarkBg ? "rgba(255, 255, 255, 0.65)" : "rgba(9, 9, 11, 0.65)";
            ctx.beginPath();
            ctx.moveTo(startX, lineY);
            ctx.lineTo(startX + lineLength * (p / 100), lineY);
            ctx.stroke();
        }
    }

    private _getInViewTaskTextX(
        rc: CanvasHostRendererCtx,
        ctx: CanvasRenderingContext2D,
        text: string,
        x: number,
        w: number,
        paddingLeft: number = 0,
        paddingRight: number = 0,
    ): number {
        const sidebarWidth =
            ((rc.l_max - rc.l_min) * rc.A.sidebarColWidth + rc.A.sidebarColExtra + rc.C.sidebarColImage) /
            rc.view.scale;
        const textWidth = ctx.measureText(text).width;

        if (textWidth + paddingLeft + paddingRight >= w) {
            return x + paddingLeft;
        }

        return Math.min(Math.max(x, rc.view.x1 + sidebarWidth) + paddingLeft, x + w - textWidth - paddingRight);
    }

    private _createRectFromTask(task: CanvasTaskData & CanvasHostStripeRenderNode): Rect {
        return {
            x: task.__x,
            y: task.__y,
            right: task.__x + task.__w,
            bottom: task.__y + task.__h,
            w: task.__w,
            h: task.__h,
        };
    }

    private _resizeRect(rect: Rect, value: number): Rect {
        rect.x -= value;
        rect.right += value;
        rect.w += value + value;

        rect.y -= value;
        rect.bottom += value;
        rect.h += value + value;

        return rect;
    }

    // rect1 must be above rect2
    private _renderTwoJoinedRects(ctx: CanvasRenderingContext2D, rect1: Rect, rect2: Rect): void {
        ctx.beginPath();
        ctx.moveTo(rect1.x, rect1.y);
        ctx.lineTo(rect1.right, rect1.y);

        if (rect1.right > rect2.right) {
            ctx.lineTo(rect1.right, rect1.bottom);
            ctx.lineTo(rect2.right, rect2.bottom);
        } else if (rect1.right === rect2.right) {
            ctx.lineTo(rect2.right, rect2.bottom);
        } else {
            ctx.lineTo(rect2.right, rect2.y);
            ctx.lineTo(rect2.right, rect2.bottom);
        }

        ctx.lineTo(rect2.x, rect2.bottom);

        if (rect2.x > rect1.x) {
            ctx.lineTo(rect1.x, rect1.bottom);
            ctx.lineTo(rect1.x, rect1.y);
        } else if (rect2.x === rect1.x) {
            ctx.lineTo(rect1.x, rect1.y);
        } else {
            ctx.lineTo(rect2.x, rect2.y);
            ctx.lineTo(rect1.x, rect1.y);
        }

        ctx.stroke();
        ctx.fill();
    }

    private _calculateAngle(x1: number, y1: number, x2: number, y2: number) {
        return Math.atan2(y2 - y1, x2 - x1);
    }

    // rect1 must be above rect2
    private _renderTwoJoinedRoundedRects(ctx: CanvasRenderingContext2D, rect1: Rect, rect2: Rect, radii: number): void {
        const rect1NotRounded = this._resizeRect({ ...rect1 }, -radii);
        const rect2NotRounded = this._resizeRect({ ...rect2 }, -radii);

        ctx.beginPath();
        ctx.moveTo(rect1NotRounded.x, rect1.y);

        if (rect1.right > rect2.right) {
            const angle = this._calculateAngle(rect1.right, rect1.y, rect2.right, rect2.y);

            ctx.lineTo(rect1NotRounded.right, rect1.y);
            ctx.arcTo(rect1.right, rect1.y, rect1.right, rect1NotRounded.y, radii);
            ctx.lineTo(rect1.right, rect1NotRounded.bottom);
            ctx.arcTo(rect1.right, rect1.bottom, rect2.right, rect2.bottom, radii);
            ctx.lineTo(rect2.right + radii * -Math.cos(angle), rect2.bottom - radii * Math.sin(angle));
            ctx.arcTo(rect2.right, rect2.bottom, rect2NotRounded.right, rect2.bottom, radii);
        } else if (rect1.right === rect2.right) {
            ctx.lineTo(rect1NotRounded.right, rect1.y);
            ctx.arcTo(rect1.right, rect1.y, rect1.right, rect1NotRounded.y, radii);
            ctx.lineTo(rect2.right, rect2NotRounded.bottom);
            ctx.arcTo(rect2.right, rect2.bottom, rect2NotRounded.right, rect2.bottom, radii);
        } else {
            const angle = this._calculateAngle(rect1.right, rect1.y, rect2.right, rect2.y);

            ctx.lineTo(rect1NotRounded.right, rect1.y);
            ctx.arcTo(rect1.right, rect1.y, rect2.right, rect2.y, radii);
            ctx.lineTo(rect2.right + radii * -Math.cos(angle), rect2.y - radii * Math.sin(angle));
            ctx.arcTo(rect2.right, rect2.y, rect2.right, rect2NotRounded.y, radii);
            ctx.lineTo(rect2.right, rect2NotRounded.bottom);
            ctx.arcTo(rect2.right, rect2.bottom, rect2NotRounded.right, rect2.bottom, radii);
        }

        ctx.lineTo(rect2NotRounded.x, rect2.bottom);

        if (rect2.x > rect1.x) {
            const angle = this._calculateAngle(rect1.x, rect1.y, rect2.x, rect2.y);

            ctx.lineTo(rect2NotRounded.x, rect2.bottom);
            ctx.arcTo(rect2.x, rect2.bottom, rect1.x, rect1.bottom, radii);
            ctx.lineTo(rect1.x - radii * -Math.cos(angle), rect1.bottom + radii * Math.sin(angle));
            ctx.arcTo(rect1.x, rect1.bottom, rect1.x, rect1NotRounded.bottom, radii);
            ctx.lineTo(rect1.x, rect1NotRounded.y);
            ctx.arcTo(rect1.x, rect1.y, rect1NotRounded.x, rect1.y, radii);
        } else if (rect2.x === rect1.x) {
            ctx.lineTo(rect2NotRounded.x, rect2.bottom);
            ctx.arcTo(rect2.x, rect2.bottom, rect2.x, rect2NotRounded.bottom, radii);
            ctx.lineTo(rect1.x, rect1NotRounded.y);
            ctx.arcTo(rect1.x, rect1.y, rect1NotRounded.x, rect1.y, radii);
        } else {
            const angle = this._calculateAngle(rect1.x, rect1.y, rect2.x, rect2.y);

            ctx.lineTo(rect2NotRounded.x, rect2.bottom);
            ctx.arcTo(rect2.x, rect2.bottom, rect2.x, rect2NotRounded.bottom, radii);
            ctx.lineTo(rect2.x, rect2NotRounded.y);
            ctx.arcTo(rect2.x, rect2.y, rect1.x, rect1.y, radii);
            ctx.lineTo(rect1.x - radii * -Math.cos(angle), rect1.y + radii * Math.sin(angle));
            ctx.arcTo(rect1.x, rect1.y, rect1NotRounded.x, rect1.y, radii);
        }

        ctx.closePath();
        ctx.stroke();
        ctx.fill();
    }

    renderSelectedBaselineHighlight(
        rc: CanvasHostRendererCtx,
        ctx: CanvasRenderingContext2D,
        task: CanvasTaskData & CanvasHostStripeRenderNode,
        baselineTask: CanvasTaskData & CanvasHostStripeRenderNode,
    ): void {
        const isRoundedCorner = rc.view.scale >= this._renderer.roundedCornersThreshold;
        const taskRect = this._resizeRect(this._createRectFromTask(task), -2);
        const baselineTaskRect = this._resizeRect(this._createRectFromTask(baselineTask), -2);

        ctx.strokeStyle = "black";
        ctx.fillStyle = "rgba(79, 58, 177, 0.16)";
        ctx.lineWidth = 2;
        ctx.setLineDash([5, 5]);

        if (isRoundedCorner) {
            this._renderTwoJoinedRoundedRects(ctx, baselineTaskRect, taskRect, 8);
        } else {
            this._renderTwoJoinedRects(ctx, baselineTaskRect, taskRect);
        }

        ctx.setLineDash([]);
    }

    public renderTask(
        rc: CanvasHostRendererCtx,
        ctx: CanvasRenderingContext2D,
        task: CanvasTaskData & CanvasHostStripeRenderNode,
        drawOutline?: boolean,
        tradeName?: string,
        isBaseline?: boolean,
        baselineColor?: string,
    ) {
        const selWidth = this._renderer?.hit?.hitTask?.right - this._renderer?.hit?.hitTask?.left;
        const dif = selWidth * this._renderer.getSelStripeSx() - selWidth;
        const newSx = (task.right - task.left + dif) / (task.right - task.left);
        const isSelected = this._renderer.isTaskSelected(task);
        // if 1 then the process is not selected and should stay the same in width. Otherwise will be a factor for width scaling
        const scaleWidthFactor = isSelected && !isBaseline ? newSx : 1;
        const transformXValue = isSelected && !isBaseline ? this._renderer.getBBox()?.tx : 0;
        const S_MIN_WIDTH = MIN_TASK_WIDTH;
        const dx = isSelected && !isBaseline ? rc.selStripeDx : 0;
        const dy = isSelected && !isBaseline ? rc.selStripeDy : 0;
        const p = rc.C.label === "week" ? 4 : 2; // "week = daily view" and "month = weekly view" :(
        let l = task.__x + dx + p;
        const t = task.__y + dy + p;
        let w = task.__w - p - p;
        const h = task.__h - p - p;
        const { showConflictMode, dependencyChain, showDependencies } = useCanvasStore.getState();
        const showBaseline = useUserJourneyStore.getState().userView === ViewTypes.BASELINE;
        const isRoundedCorner = rc.view.scale >= this._renderer.roundedCornersThreshold;
        const isTextHidden = rc.view.scale < this._renderer.textVisibilityThreshold;
        const handle = this._pointer.drag?.handle;
        const isMilestone = task.right === task.left;

        const nv = l + w < rc.view.x1 || l > rc.view.x2 || t + h < rc.view.y1 || t > rc.view.y2;
        if (nv) {
            // abort render if process not visible
            return;
        }
        if (handle === 6 && !isMilestone) {
            l += transformXValue;
            w -= transformXValue;
        }
        if (handle === 5 && !isMilestone) {
            // l /= scaleWidthFactor;
            w = task.__w * scaleWidthFactor - p - p;
        }
        if (w >= S_MIN_WIDTH) {
            const isGray = true === rc.M.sel && 1 !== task.m && task.v === "conflict";
            const color = !isGray ? (task.v && undefined !== task.c ? task.c : task.color) : 0xc1c5c7;
            let hex = color.toString(16).padStart(6, "0");
            hex = baselineColor ?? hex.substring(0, 6);
            const bigint = parseInt(hex, 16);
            const _w = needsWhiteColor(bigint);
            const r = (bigint >> 16) & 255;
            const g = (bigint >> 8) & 255;
            const b = bigint & 255;
            const opacityModifier = this._renderer.getSel().length == 0 || isSelected ? 1 : 0.35;
            const fillColor = `rgba(${r}, ${g}, ${b}, ${1 * opacityModifier})`;

            ctx.fillStyle = fillColor;
            if (isGray) {
                ctx.save();
            }
            if (showConflictMode && dependencyChain.indexOf(task.id) === -1) {
                ctx.globalAlpha = 0.3;
            }

            if (task.isVirtual) {
                ctx.globalAlpha = 0.6;
            }

            if (isMilestone) {
                if (isRoundedCorner) {
                    ctx.beginPath();
                    ctx.moveTo(l + 8, t + h / 2 - 8);
                    ctx.lineTo(l + w / 2 - 8, t + 8);
                    ctx.arcTo(l + w / 2, t, l + w / 2 + 8, t + 8, 8);
                    ctx.lineTo(l + w - 8, t + h / 2 - 8);
                    ctx.arcTo(l + w, t + h / 2, l + w - 8, t + h / 2 + 8, 8);
                    ctx.lineTo(l + w / 2 + 8, t + h - 8);
                    ctx.arcTo(l + w / 2, t + h, l + w / 2 - 8, t + h - 8, 8);
                    ctx.lineTo(l + 8, t + h / 2 + 8);
                    ctx.arcTo(l, t + h / 2, l + 8, t + h / 2 - 8, 8);
                    ctx.closePath();
                    ctx.fill();

                    // draw read frame when process has a conflict
                    if (task.dep >= 0) {
                        ctx.lineWidth = 2;
                        ctx.beginPath();
                        ctx.strokeStyle = "#D13501";
                        ctx.moveTo(l + 4, t + h / 2 - 8);
                        ctx.lineTo(l + w / 2 - 8, t + 4);
                        ctx.arcTo(l + w / 2, t - 4, l + w / 2 + 8, t + 4, 10);
                        ctx.lineTo(l + w - 4, t + h / 2 - 8);
                        ctx.arcTo(l + w + 4, t + h / 2, l + w - 8, t + h / 2 + 12, 10);
                        ctx.lineTo(l + w / 2 + 12, t + h - 8);
                        ctx.arcTo(l + w / 2, t + h + 4, l + w / 2 - 12, t + h - 8, 10);
                        ctx.lineTo(l + 4, t + h / 2 + 8);
                        ctx.arcTo(l - 4, t + h / 2, l + 8, t + h / 2 - 12, 10);
                        ctx.closePath();
                        ctx.stroke();
                    }
                } else {
                    ctx.beginPath();
                    ctx.moveTo(l, t + h / 2);
                    ctx.lineTo(l + w / 2, t);
                    ctx.lineTo(l + w, t + h / 2);
                    ctx.lineTo(l + w / 2, t + h);
                    ctx.closePath();
                    ctx.fill();
                }

                // render selection for task milestone
                if (isSelected && !showBaseline) {
                    ctx.beginPath();
                    ctx.strokeStyle = "#000000";
                    ctx.moveTo(l + 8, t + h / 2 - 8);
                    ctx.lineTo(l + w / 2 - 8, t + 8);
                    ctx.arcTo(l + w / 2, t, l + w / 2 + 8, t + 8, 8);
                    ctx.lineTo(l + w - 8, t + h / 2 - 8);
                    ctx.arcTo(l + w, t + h / 2, l + w - 8, t + h / 2 + 8, 8);
                    ctx.lineTo(l + w / 2 + 8, t + h - 8);
                    ctx.arcTo(l + w / 2, t + h, l + w / 2 - 8, t + h - 8, 8);
                    ctx.lineTo(l + 8, t + h / 2 + 8);
                    ctx.arcTo(l, t + h / 2, l + 8, t + h / 2 - 8, 8);
                    ctx.closePath();
                    ctx.stroke();
                }

                if (!isTextHidden) {
                    ctx.fillStyle = _w ? rc.C.gpa.task.label.white : rc.C.gpa.task.label.black;
                    CanvasHostRenderer.renderSegments(
                        ctx,
                        task.segs,
                        l,
                        t,
                        w,
                        h,
                        1,
                        false,
                        false,
                        1,
                        1,
                        isMilestone && (_w ? rc.C.gpa.task.label.black : rc.C.gpa.task.label.white),
                        null,
                        task.name,
                        this.state.fs > 0 ? -this.state.fs : this.state.fs, // make sure this number is always negative, because milestones need always text calculation
                    );
                }
            } else {
                if (isRoundedCorner) {
                    ctx.beginPath();
                    ctx.roundRect(l, t, w, h, 8);
                    ctx.fill();
                } else {
                    ctx.fillRect(l, t, w, h);
                }

                if (task.dep >= 0) {
                    ctx.lineWidth = 2;
                    ctx.strokeStyle = "#D13501";

                    ctx.beginPath();
                    ctx.roundRect(l - 2, t - 2, w + 4, h + 4, 10);
                    ctx.stroke();
                }

                // render selection for task
                if (isSelected && !showBaseline) {
                    ctx.beginPath();
                    ctx.roundRect(l + 1, t + 1, w - 2, h - 2, 7);
                    ctx.strokeStyle = "#000000";
                    ctx.stroke();
                }
                // console.log("BBOX: ", this._renderer.getBBox()?.canvas);

                if (this.state.const.showStatusBar && !isTextHidden) {
                    this._renderTaskStatusBar(ctx, task, l, t, w, h, _w);
                }

                // render text old UI with text adaption
                ctx.fillStyle = _w ? rc.C.gpa.task.label.white : rc.C.gpa.task.label.black;
                if (this.state.fs < 0 && w >= 48 && !isTextHidden) {
                    if (!handle || !isSelected) {
                        CanvasHostRenderer.renderSegments(
                            ctx,
                            task.segs,
                            l,
                            t,
                            w,
                            h,
                            1,
                            false,
                            false,
                            1,
                            1,
                            isMilestone && (_w ? rc.C.gpa.task.label.black : rc.C.gpa.task.label.white),
                            null,
                            task.name,
                            this.state.fs,
                        );

                        // render status, conflict, stabillity indicator in the views
                        if ((!isMilestone && task.v && task.l) || (showConflictMode && typeof task.dep === "number")) {
                            const p = 2;
                            const text = task.l ?? "+" + Math.abs(task.dep).toString();
                            ctx.save();
                            ctx.font = DETAILS_FONT;
                            const m = ctx.measureText(text);
                            ctx.fillText(text, l + w - m.width - p, t + h - m.actualBoundingBoxDescent - p);
                            ctx.restore();
                        }
                    }
                } else {
                    // check if the process is to short, so no rendering of icon or text
                    if (w >= 48) {
                        if (!isTextHidden) {
                            ctx.save();
                            ctx.font = "600 14px Inter";
                            const taskName = task.name.slice(0, this.shrinkText(ctx, task.name, w - 44)).trim();
                            ctx.fillText(taskName, this._getInViewTaskTextX(rc, ctx, taskName, l, w, 44, 44), t + 35);

                            ctx.fillStyle = _w ? rc.C.gpa.task.trade.light : rc.C.gpa.task.trade.dark;
                            ctx.font = "10px Inter";
                            const taskTrade = (task?.trade || "")
                                .slice(0, this.shrinkText(ctx, task.trade || "", w - 44))
                                .trim();
                            ctx.fillText(taskTrade, this._getInViewTaskTextX(rc, ctx, taskTrade, l, w, 44, 44), t + 19);
                            ctx.restore();
                        }

                        if (rc.view.scale >= this._renderer.iconVisibilityThreshold) {
                            this.renderIconAndStatusInfo(
                                ctx,
                                task.tradeIcon,
                                l,
                                t,
                                task.l,
                                // calculated baselineDelayDays between current and baseline should be passed here (showBaseline ? task.baseLineDelayDays : 0),
                                task.delayDays,
                                isBaseline,
                            );
                            if (task.atm && !(showConflictMode && typeof task.dep === "number")) {
                                this.renderAttachment(ctx, l, t);
                            }
                            if (showConflictMode && typeof task.dep === "number") {
                                this._renderDepOffset(ctx, Math.abs(task.dep).toString(), l, t);
                            } else {
                                //disable rendering red dot for the moment
                                // this.renderRedDot(rc, ctx, task);

                                if (task.hasComments) {
                                    this.newNotificationDot(rc, ctx, task);
                                }
                            }
                        }
                    }
                }
            }

            if (showDependencies && this._renderer.hit.hitTask?.id === task.id) {
                ctx.fillStyle = "rgba(0, 0, 0, 0.33)";
                if (this._renderer.hit.hitTaskLeft === true) {
                    // is left
                    ctx.fillRect(l, t, w / 4, h);
                    this.drawPlusSing(ctx, l + w * 0.125, t + h / 2);
                } else if (this._renderer.hit.hitTaskLeft === false) {
                    // is right
                    ctx.fillRect(l + w * 0.75, t, w / 4, h);
                    this.drawPlusSing(ctx, l + w * 0.875, t + h / 2);
                }
            }

            if (isGray) {
                ctx.restore();
            }
            // else if (true === rc.M.sel) {
            // disable dotted lines
            // if (-1 === task.__z) {
            //     // not selected...
            //     const p = 1;
            //     const l = task.__x + dx + p;
            //     const t = task.__y + dy + p;
            //     const w = task.__w - p - p;
            //     const h = task.__h - p - p;
            //     ctx.save();
            //     ctx.strokeStyle = "#000000";
            //     ctx.lineWidth = 1;
            //     ctx.setLineDash([3, 4]);
            //     ctx.strokeRect(l, t, w, h);
            //     ctx.restore();
            // }
            // }
            //set alpha back to normal, so in any case alpha returns back
            ctx.globalAlpha = 1.0;
        }
    }

    private _renderDelayDaysInfo(
        ctx: CanvasRenderingContext2D,
        x: number,
        y: number,
        w: number,
        delayDays: number,
    ): void {
        // Draw red circle
        ctx.save();
        ctx.beginPath();
        ctx.arc(x, y, w, 0, Math.PI * 2);
        ctx.fillStyle = "rgba(220, 38, 38, 1)";
        ctx.fill();
        ctx.lineWidth = 2;
        ctx.strokeStyle = "white";
        ctx.stroke();

        // write delay days
        ctx.font = "600 9px Inter";
        ctx.fillStyle = "#ffffff";
        const text = delayDays > 99 ? "99+" : delayDays.toString();
        const { width: textWidth, actualBoundingBoxAscent: textHeight } = ctx.measureText(text);
        ctx.fillText(text, x - textWidth / 2, y + textHeight / 2);
        ctx.restore();
    }

    private renderIconAndStatusInfo(
        ctx: CanvasRenderingContext2D,
        icon: string,
        l: number,
        t: number,
        label?: string,
        delayDays?: number,
        isBaseline?: boolean,
    ): void {
        const svgIcon = tradeImagesMap.get(icon || this._renderer.C.tradeDefaultIcon);
        if (!svgIcon && !label) {
            console.error("Could not render iconIndex:", { icon: this._renderer.C.tradeDefaultIcon });
        }

        if (!this.svgImageCache.has(svgIcon.title) && !label) {
            const img = new Image();
            img.src = svgIcon.img;
            this.svgImageCache.set(svgIcon.title, img);
        }

        ctx.beginPath();
        ctx.arc(l + 23, t + 24, 16, 0, Math.PI * 2);
        ctx.fillStyle = "rgba(0, 0, 0, 0.4)";
        ctx.fill();
        // ctx.save();
        // ctx.strokeStyle = "rgba(0, 0, 0, 0.4)";
        // ctx.lineWidth = 2;
        // ctx.beginPath();
        // ctx.arc(l + 24, t + 24, 13, 0, Math.PI * 2);
        // ctx.stroke();
        // ctx.restore();
        if (!label) {
            ctx.drawImage(this.svgImageCache.get(svgIcon?.title), l + 15.51, t + 16, 16, 16);
        } else {
            ctx.save();
            ctx.fillStyle = "#ffffff";
            ctx.font = "8px Inter";
            ctx.textAlign = "center";
            ctx.fillText(label, l + 23, t + 27);
            ctx.restore();
        }

        if (!isBaseline && delayDays > 0) {
            this._renderDelayDaysInfo(ctx, l + 23, t + 24, 15, delayDays);
        }
    }

    private renderAttachment(ctx: CanvasRenderingContext2D, l: number, t: number): void {
        const svgIcon = tradeImagesMap.get("attachment");
        if (!svgIcon) {
            console.error("Could not render iconIndex:", { icon: this._renderer.C.tradeDefaultIcon });
        }

        if (!this.svgImageCache.has(svgIcon.title)) {
            const img = new Image();
            img.src = svgIcon.img;
            this.svgImageCache.set(svgIcon.title, img);
        }

        ctx.beginPath();
        ctx.arc(l + 33, t + 33, 6, 0, Math.PI * 2);
        ctx.fillStyle = "black";
        ctx.fill();
        ctx.drawImage(this.svgImageCache.get(svgIcon?.title), l + 29, t + 29, 8, 8);
    }

    private renderRedDot(
        rc: CanvasHostRendererCtx,
        ctx: CanvasRenderingContext2D,
        task: CanvasTaskData & CanvasHostStripeRenderNode,
    ) {
        const dx = -1 === task.__z ? 0 : rc.selStripeDx;
        const dy = -1 === task.__z ? 0 : rc.selStripeDy;
        const p = 2;
        const l = task.__x + dx + p;
        const t = task.__y + dy + p;

        ctx.beginPath();
        ctx.arc(l + 35.75, t + 35.75, 3.5, 0, Math.PI * 2);
        ctx.save();
        ctx.fillStyle = "#D13501";
        ctx.lineWidth = 1.5;
        ctx.strokeStyle = "white";
        ctx.fill();
        ctx.stroke();
        ctx.restore();
    }

    private newNotificationDot(
        rc: CanvasHostRendererCtx,
        ctx: CanvasRenderingContext2D,
        task: CanvasTaskData & CanvasHostStripeRenderNode,
    ) {
        const dx = -1 === task.__z ? 0 : rc.selStripeDx;
        const dy = -1 === task.__z ? 0 : rc.selStripeDy;
        const p = 2;
        const l = task.__x + dx + p;
        const t = task.__y + dy + p;
        ctx.beginPath();
        ctx.arc(l + 15, t + 15, 3.5, 0, Math.PI * 2);
        ctx.save();
        ctx.fillStyle = "#4F3AB1";
        ctx.lineWidth = 1.5;
        ctx.strokeStyle = "white";
        ctx.fill();
        ctx.stroke();
        ctx.restore();
    }

    private renderDepOffset(
        rc: CanvasHostRendererCtx,
        ctx: CanvasRenderingContext2D,
        task: CanvasTaskData & CanvasHostStripeRenderNode,
    ) {
        const dx = -1 === task.__z ? 0 : rc.selStripeDx;
        const dy = -1 === task.__z ? 0 : rc.selStripeDy;
        const p = 2;
        const l = task.__x + dx + p;
        const t = task.__y + dy + p;

        ctx.beginPath();
        ctx.fillStyle = "#ffffff";
        ctx.fillText(Math.abs(task.dep).toString(), l + 35.75, t + 35.75);
    }

    public selChanged(ev?: PointerEvent) {
        const sel = this._renderer.getSel();
        const tids = sel.reduce((ret, sel) => {
            if (CanvasHostBase.TASK_TARGET === sel.target) {
                ret.push(sel.id);
            }
            return ret;
        }, []);
        const canvasState = useCanvasStore.getState();
        useCanvasTooltipStore.getState().setInvisible();
        canvasState.setSelectedProcessIds(tids);

        this.props.worker.postMessage([
            "meta",
            "sel",
            {
                ts: ++this._metaTS,
                mode: this.props.mode,
                details: this.props.viewConst?.details || null,
                tids,
                cb: this.props.worker.registerCallback((data) => {
                    if (
                        sel[0]?.dep >= 0 ||
                        (typeof sel.at(0)?.dep === "undefined" && data?.numberOfConflictingProcesses)
                    ) {
                        useConflictStore.getState().actions.setConflictCount(data?.numberOfConflictingProcesses);
                    } else {
                        useConflictStore.getState().actions.setConflictCount(0);
                    }

                    // some cases ev is not defined, there it needs to be ignored
                    if (
                        ev?.clientX >= 0 &&
                        this._renderer.hit.hitTask &&
                        hasTouchSupport() &&
                        !this._renderer.hit.hitBaselineTask
                    ) {
                        const { setVisible } = useCanvasTooltipStore.getState();
                        const scale = this.state.reactState.scale;
                        setVisible(
                            ev.clientX,
                            (this.state.reactState.top + this._renderer.hit.hitTask.__y) * scale,
                            this._renderer.hit.hitTask,
                        );
                    }

                    if (canvasState.showConflictMode === false) {
                        canvasState.setDependencyChain(data?.chain || []);
                    }
                }),
            },
        ]);
    }

    protected override onDragStart(rc: CanvasHostRendererCtx, hit: CanvasHostHitResult) {
        clearTimeout(this.hoverTimeoutIndex);
        useCanvasTooltipStore.getState().setInvisible();
        const sel = this._renderer.getSel();
        this.props.worker.postMessage([
            "async",
            "drag",
            {
                ts: ++this._asyncTS,
                mode: this.props.mode,
                tids: sel.reduce((ret, sel) => {
                    if (CanvasHostBase.TASK_TARGET === sel.target) {
                        ret.push(sel.id);
                    }
                    return ret;
                }, []),
            },
        ]);
    }

    protected override onDragEnd(rc: CanvasHostRendererCtx, dragInfo: CanvasHostRendererDragInfo | null) {
        const sel = this._renderer.getSel();
        let d_sel;
        if (
            "number" === typeof dragInfo?.task?.id &&
            (d_sel = sel.findIndex((task) => task.id === dragInfo.task.id)) >= 0
        ) {
            const task = dragInfo.task;
            const tx = dragInfo?.tx || 0;
            const e0 = task.stripe > 0 ? rc.stripes[task.stripe - 1].e : 0;
            const e1 = rc.stripes[task.stripe].e;
            const y = Math.max(Math.min(Math.round(task.top / rc.C.rowPx) - e0, e1 - e0), 0);
            const x = Math.round((task.left + tx) / rc.C.colPx);
            const sx = dragInfo?.sx || 1;
            this.props.worker.postMessage([
                "move",
                {
                    taskId: task.id,
                    x: x,
                    sx: sx,
                    y: y,
                    multisel: sel.filter((t) => t.id !== task.id).map((t) => t.id),
                },
            ]);
            const canvasState = useCanvasStore.getState();
            this.props.worker.postMessage([
                "meta",
                "sel",
                {
                    ts: ++this._metaTS,
                    mode: this.props.mode,
                    details: this.props.viewConst?.details || null,
                    tids: [task.id],
                    cb: this.props.worker.registerCallback((data) => {
                        if (!canvasState.showConflictMode) {
                            canvasState.setDependencyChain(data?.chain || []);
                            useConflictStore
                                .getState()
                                .actions.setConflictCount(data?.numberOfConflictingProcesses ?? 0);
                        }
                    }),
                },
            ]);
        }
    }

    protected onDropEnd(
        rc: CanvasHostRendererCtx,
        pointer: CanvasHostBasePointerEvent,
        dropInfo: CanvasHostRendererDropInfo | null,
        draggedRef: HTMLElement,
    ) {
        if (rc.hit.hitStripe && dropInfo.trade) {
            const dropDays = draggedRef?.dataset?.dropDays;
            const days = "string" === typeof dropDays ? Number.parseInt(dropDays, 10) : 0;
            WebAppTelemetryFactory.trackEvent("trade-dropped-trades-menu");
            this.props.worker.postMessage([
                "add",
                {
                    trade: dropInfo.trade.id,
                    stripe: -1, //@TODO
                    stripet: rc.hit.hitStripe.t,
                    stripei: rc.hit.hitStripe.i,
                    stripej: rc.hit.hitStripe.j,
                    x1: rc.hit.hitStripeGridX,
                    x2: rc.hit.hitStripeGridX + days,
                    y: rc.hit.hitStripeGridY,
                    text: dropInfo.trade.name,
                },
            ]);
        } else if (rc.hit.hitStripe && dropInfo.lib) {
            WebAppTelemetryFactory.trackEvent("trade-dropped-library-menu");
            this.props.worker.postMessage([
                "wb",
                "pastepp",
                {
                    lib: dropInfo.lib.id,
                    stripet: rc.hit.hitStripe.t,
                    stripei: rc.hit.hitStripe.i,
                    stripej: rc.hit.hitStripe.j,
                    x1: rc.hit.hitStripeGridX,
                    y: rc.hit.hitStripeGridY,
                },
            ]);
        } else if (rc.hit.hitSidebar && dropInfo.lib) {
            WebAppTelemetryFactory.trackEvent("trade-dropped-library-area-attachment");
            this.props.worker.postMessage([
                "wb",
                "setPPGpa",
                {
                    lib: dropInfo.lib.id,
                    stripet: rc.hit.hitSidebar.stripe.t,
                    stripei: rc.hit.hitSidebar.stripe.i,
                    stripej: rc.hit.hitSidebar.stripe.j,
                },
            ]);
        }
    }

    protected override onTranslate(dx: number, dy: number) {
        if (useCanvasTooltipStore.getState().visible) {
            useCanvasTooltipStore.getState().setInvisible();
        }
    }

    public override onClick(mouse: { x: number; y: number }) {
        useCanvasTooltipStore.getState().setInvisible();
        if (this._renderer.hit.hitSidebarImage && this.props.meta?.session?.sandbox) {
            this.props.worker.dispatchMessage([
                "toggle",
                "dialog.taktzone.details",
                {
                    projectId: this.props.meta.session.sandbox,
                    tid: this._renderer.hit.hitSidebar.stripe.t,
                },
            ]);
        } else {
            const taktzones = this._renderer?.hit?.hitSidebar?.stripe?._p;
            if (!taktzones) {
                //Taktzones can be null or undefined
                return;
            }

            const taktzonesExceptRoot = taktzones.filter((taktzone) => taktzone > 0);
            if (taktzonesExceptRoot.length > 0) {
                const { stripe, j } = this._renderer.hit.hitSidebar;
                const isTemporaryStripe = stripe._p[j] < 0;
                if (stripe.collapsed == false) {
                    WebAppTelemetryFactory.trackEvent("individual-area-collapsed")
                } else {
                    WebAppTelemetryFactory.trackEvent("individual-area-expanded")
                }
                this.props.worker.postMessage([
                    "area",
                    "toggleCollapse",
                    {
                        id: isTemporaryStripe ? stripe._ : stripe._p[j],
                        isTemporaryStripe,
                    },
                ]);
                // for now this is commented to remove sidebar popup
                // this.props.worker.postMessage(["collapse", stripe._]);
                // this.props.onTaktzoneClicked(taktzonesExceptRoot);
            }
        }

        //@ts-ignore
        const taktzones = this._renderer?.hit?.hitSidebar?.stripe?._p;
        if (taktzones === undefined) {
            return;
        }

        const taktzonesExceptRoot = taktzones.filter((taktzone) => taktzone !== 0);
        if (taktzonesExceptRoot.length > 0) {
            if (window.top != window.self) {
                console.log("taktzones", taktzonesExceptRoot);
                const messageObj = {
                    action: "taktzoneSelected",
                    //@ts-ignore
                    taktzones: taktzonesExceptRoot,
                };
                parent.postMessage(messageObj, "*");
            }
        }
    }

    public onAddDependency(srcPid: number, tgtPid: number, dependencyType: LCMDContextDependencyType) {
        const canvasState = useCanvasStore.getState();
        if (canvasState.showConflictMode === true && canvasState.dependencyChain.indexOf(srcPid) === -1) {
            console.warn(
                "adding dependency aborted. In conflict mode only active chain is allowed to create dependencies",
            );
            return;
        }
        // src and dst are wrong way around!!!!!
        const props: { src: number; dst: number; type: number; lag: [number, number]; whiteboard?: boolean } = {
            dst: srcPid,
            src: tgtPid,
            lag: [0, 3],
            type: dependencyType,
        };
        this.props.worker.postMessage(["dependency", "add", props]);

        // todo: separate sel logic from depChain calcualtion in Worker, because here is only the chain calculation required! could lead to side effects
        if (canvasState.dependencyChain.length > 0 && canvasState.showConflictMode === true) {
            this.props.worker.postMessage([
                "meta",
                "sel",
                {
                    ts: ++this._metaTS,
                    mode: this.props.mode,
                    details: this.props.viewConst?.details || null,
                    tids: [srcPid],
                    cb: this.props.worker.registerCallback((data) => {
                        canvasState.setDependencyChain(data?.chain || []);
                        useConflictStore.getState().actions.setConflictCount(data?.numberOfConflictingProcesses);
                    }),
                },
            ]);
        }
    }

    protected override onTextEdited(target: number, id: number, value: string) {
        if (CanvasHostBase.TASK_TARGET === target) {
            this.props.worker.postMessage([
                "rename",
                {
                    taskId: id,
                    text: value,
                },
            ]);
            WebAppTelemetryFactory.trackEvent("trade-dropped-process-renamed");
        }
    }

    protected override onCopyCut(cmd: "copy" | "cut") {
        const sel = this._renderer.getSel();
        const selectedProcessData = [];
        if (sel.length > 0) {
            const ids = sel.map((t) => t.id);
            //get in-depth data of each copied task
            sel.forEach((item) => {
                this.props.worker.postMessage([
                    "details",
                    {
                        taskId: item.id,
                        cb: this.props.worker.registerCallback((data) => {
                            selectedProcessData.push(data);
                        }),
                    },
                ]);
            });
            // Send the message to the worker to perform the copy/cut operation
            this.props.worker.postMessage([cmd, ids]);
            // process data recieved from worker
            const clipboardContentHandler: MainWorkerMessageHandler = (msg) => {
                if (msg[0] === "clipboardContent") {
                    const clipboardContent = msg[1];
                    this.writeToClipboard(JSON.stringify({ clipboardContent, selectedProcessData, cmd }));
                    this.props.worker.unregisterHandler(clipboardContentHandler);
                }
            };
            //register the handler to process the clipboard content once it's received
            this.props.worker.registerHandler(clipboardContentHandler);
        }
    }

    protected async writeToClipboard(clipboardContent: string) {
        //Clipboard API copy function
        try {
            await navigator.clipboard.writeText(clipboardContent);
            console.log("Content copied to clipboard successfully!");
        } catch (err) {
            console.error("Failed to copy content to clipboard:", err);
        }
    }

    protected override async onPaste(ev: CanvasHostBasePointerEvent) {
        this._renderer.hitTest(ev);
        if (this._renderer.hit.hitStripe) {
            const pointerGridX = Math.max(0, Math.floor(this._renderer.hit.hitStripeLeft / this._renderer.C.colPx));
            const pointerGridY = Math.max(0, Math.floor(this._renderer.hit.hitStripeTop / this._renderer.C.rowPx));
            const p = {
                pid: this._renderer.hit.hitStripe.t,
                i_pid: this._renderer.hit.hitStripe.i,
            };

            try {
                let clipboardContent;
                let copiedTrades;
                let sourceProjectId;
                let copiedTaskIds;
                let selectedProcessData;
                const clipboardPermission = await navigator.permissions.query({
                    name: "clipboard-read" as PermissionName,
                });
                if (clipboardPermission.state == "denied") {
                    toast(intl.get("ClipboardToast.title"), {
                        description: intl.getHTML("ClipboardToast.description"),
                        // link: "https://lcmdigital.zendesk.com/hc/de/articles/12420163695132"
                    });
                    return;
                }

                const clipboardData = await navigator.clipboard.readText();
                let data;

                try {
                    data = JSON.parse(clipboardData);
                    clipboardContent = data.clipboardContent;
                    selectedProcessData = data.selectedProcessData;
                    copiedTrades = data.selectedProcessData
                        .flatMap((item) => (item.trades ? item.trades.value : []))
                        .filter((obj, index, self) => index === self.findIndex((t) => t.name === obj.name));
                    sourceProjectId = data.selectedProcessData[0].projectId;
                    copiedTaskIds = data.selectedProcessData
                        .map((item) => ({
                            id: item.pid.value,
                            name: item.name.value,
                            startDate: item.start.value,
                            predecessors: item.predecessors,
                            successors: item.successors,
                        }))
                        .sort((a, b) => {
                            if (a.startDate === b.startDate) {
                                return a.id - b.id;
                            }
                            return a.startDate - b.startDate;
                        });
                } catch (error) {
                    console.error("Failed to JSON parse clipboard content:", error);
                    return;
                }
                this.props.worker.postMessage([
                    "pastev2",
                    {
                        x: pointerGridX,
                        y: pointerGridY,
                        p: p,

                        clipboardContent: clipboardContent,
                        copiedTrades: copiedTrades,
                        sourceProjectId: sourceProjectId,
                        copiedTaskIds: copiedTaskIds,
                        action: data.cmd,
                    },
                ]);

                //TODO: Make this unnecessary
                const clipboardContentHandler: MainWorkerMessageHandler = (msg) => {
                    if (msg[0] === "clipboardContent") {
                        const clipboardContent = msg[1];
                        //When copy => copy; when cut, keep copy in Clipboard => copy
                        this.writeToClipboard(JSON.stringify({ clipboardContent, selectedProcessData, cmd: "copy" }));
                        this.props.worker.unregisterHandler(clipboardContentHandler);
                    }
                };

                //register the handler to process the clipboard content once it's received
                this.props.worker.registerHandler(clipboardContentHandler);
            } catch (error) {
                console.error("Failed to internally paste clipboard content:", error);
            }
        }
    }

    protected override onDeleteKey() {
        const sel = this._renderer.getSel();
        if (sel.length > 0) {
            this.props.worker.postMessage([
                "deleteTasks",
                {
                    tasks: sel.map((t) => t.id),
                },
            ]);
            return;
        }

        const depSelection = this._renderer.getDependencySel();
        if (depSelection[0] >= 1 && depSelection[1] >= 1) {
            this.props.worker.postMessage([
                "details",
                {
                    dep: {
                        srcPid: depSelection[1], // targetTaskId,
                        dstPid: depSelection[0], // sourceTaskId,
                        state: null,
                    },
                },
            ]);
            return;
        }
    }

    public override onHoverProcess(
        task: CanvasTaskData & CanvasHostRenderNode,
        points: { mouse: { x: number; y: number }; task: { x: number; y: number } },
        scale: number,
        dynamicFontSize: boolean,
    ) {
        if (this._renderer.hit.hitBaselineTask) {
            return;
        }

        if (this.hoveredPid !== task.id) {
            //clear timeout if previous exists
            clearTimeout(this.hoverTimeoutIndex);

            this.hoverTimeoutIndex = setTimeout(() => {
                this.hoveredPid = task.id;
                const { setVisible } = useCanvasTooltipStore.getState();
                setVisible(points.mouse.x, points.task.y, task);
            }, 700);

            return;
        }

        const { setVisible } = useCanvasTooltipStore.getState();
        setVisible(points.mouse.x, points.task.y, task);
    }

    public override onLeaveProcess() {
        clearTimeout(this.hoverTimeoutIndex);
        this.hoverTimeoutIndex = null;
        this.hoveredPid = null;

        useCanvasTooltipStore.getState().setInvisible();
    }

    private renderOverlayHack(this: CanvasHostBase, props: { const: any; reactState: any; overlayHack: any }) {
        const CONST = this.getCONST();
        const headerHeight = CONST.titleHeight + CONST.subtitleHeight;
        let x = undefined;
        let d = null;
        if (props.overlayHack.hover) {
            x = props.overlayHack.hover.x;
            d = props.overlayHack.hover.d;
        }
        return (
            <div
                className={classNames.overlayHack}
                style={{
                    visibility: undefined === x ? "hidden" : null,
                    left: (props.reactState.left + (x || 0)) * props.reactState.scale,
                    top: headerHeight,
                    height: props.const.colHeaderHeight,
                }}
            >
                {d ? <div className={classNames.overlayHackBubble}>{d}</div> : null}
            </div>
        );
    }
}
