import React, { useState } from "react";
import * as SliderPrimitive from "@radix-ui/react-slider";

import { cn } from "@/lib/utils";
import { Flag, Play } from "lucide-react";

interface ProcessTooltipSlider extends SliderPrimitive.SliderProps {
    className?: string;
    trackClassName?: string;
    thumbClassName?: string;
    label?: string;
    rangeColor?: string;
    startLabel?: string;
    endLabel?: string;
    valueLabel?: string;
    valueLabelClass?: string;
}

const ProcessTooltipSlider = React.forwardRef<
    React.ElementRef<typeof SliderPrimitive.Root>,
    React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root> & ProcessTooltipSlider
>(
    (
        {
            className,
            trackClassName,
            thumbClassName,
            label,
            rangeColor,
            startLabel,
            endLabel,
            valueLabel,
            valueLabelClass,
            ...props
        },
        ref,
    ) => {
        const [hovered, setHovered] = useState(false);

        const isMinValue = props.value[0] === 0;
        const isMaxValue = props.value[0] === props.max;
        const getMinMaxLabelColor = (value: boolean) => (value ? "text-white" : "text-gray-400");

        return (
            <div className="relative w-full">
                <div>{label && <p className="p-2 pt-0 text-sm font-semibold text-[#2C3032]">{label}</p>}</div>
                <SliderPrimitive.Root
                    ref={ref}
                    className={cn("relative flex w-full touch-none select-none items-center", className)}
                    {...props}
                >
                    <SliderPrimitive.Track
                        className={cn(
                            "relative h-2 w-full grow overflow-hidden rounded-full bg-secondary",
                            trackClassName,
                        )}
                    >
                        <SliderPrimitive.Range
                            className="absolute h-full"
                            style={{ backgroundColor: isMaxValue ? "hsla(131,41%,46%,1)" : rangeColor || "#4B5563" }}
                        />
                    </SliderPrimitive.Track>
                    <SliderPrimitive.Thumb
                        onPointerEnter={() => setHovered(true)}
                        onPointerLeave={() => setHovered(false)}
                        className={cn(
                            "block h-5 w-5 rounded-full border-2 border-primary bg-background ring-offset-gray-600 transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
                            thumbClassName,
                        )}
                    >
                        {hovered && valueLabel && !isMinValue && !isMaxValue && (
                            <div
                                className={cn(
                                    "absolute -left-5 top-full mt-[3px] text-[10px] text-white",
                                    valueLabelClass,
                                )}
                            >
                                {valueLabel}
                            </div>
                        )}
                    </SliderPrimitive.Thumb>
                </SliderPrimitive.Root>
                <div className="mt-3 flex w-full justify-between pb-1">
                    {startLabel && (
                        <div className={`font-inter flex items-center leading-3 ${getMinMaxLabelColor(isMinValue)}`}>
                            <Play size={14} />
                            <span className="ml-1 text-[10px]">{startLabel}</span>
                        </div>
                    )}
                    {endLabel && (
                        <div className={`font-inter flex items-center leading-3 ${getMinMaxLabelColor(isMaxValue)}`}>
                            <Flag size={14} />
                            <span className="ml-1 text-[10px]">{endLabel}</span>
                        </div>
                    )}
                </div>
            </div>
        );
    },
);

ProcessTooltipSlider.displayName = SliderPrimitive.Root.displayName;

export { ProcessTooltipSlider };
