import { useMemo } from "react";
import { intl } from "@/legacy/GlobalHelperReact";
import { LCMDContextTaskDetailsResult } from "@/app/LCMDContextTypes";
import { formatDate } from "../ProcessTooltip/Wrapper";
import { calculateDayDifference } from "@/utils/ProcessUtils";
import { ProcessTooltipBaselineDate } from "./ProcessTooltipBaselineDate";

type ProcessTooltipBaselineInfoProps = {
    processDetails: LCMDContextTaskDetailsResult;
    baselineDetails: LCMDContextTaskDetailsResult;
};

enum ComparisonStatus {
    DELAYED = "Delayed",
    AHEAD_OF_SCHEDULE = "Ahead of schedule",
    IN_PLAN = "In plan",
    NOT_AVAILABLE = "Not available",
}

export function ProcessTooltipBaselineInfo({ processDetails, baselineDetails }: ProcessTooltipBaselineInfoProps) {
    const dayDiff = useMemo(() => {
        return calculateDayDifference({
            endDate1: processDetails.end.value,
            endDate2: baselineDetails.end.value,
            startDate1: processDetails.start.value,
            startDate2: baselineDetails.start.value,
        });
    }, [processDetails, baselineDetails]);

    const status = useMemo<ComparisonStatus>(() => {
        if (dayDiff === 0) {
            return ComparisonStatus.IN_PLAN;
        }
        if (dayDiff > 0) {
            return ComparisonStatus.DELAYED;
        }
        if (dayDiff < 0) {
            return ComparisonStatus.AHEAD_OF_SCHEDULE;
        }
        return ComparisonStatus.NOT_AVAILABLE;
    }, [dayDiff]);

    const statusBarColor = useMemo(() => {
        switch (status) {
            case ComparisonStatus.IN_PLAN:
                return "bg-[#A1A1AA]";
            case ComparisonStatus.DELAYED:
                return "bg-red-600";
            case ComparisonStatus.AHEAD_OF_SCHEDULE:
                return "bg-green-500";
            default:
                return "";
        }
    }, [status]);

    if (status === ComparisonStatus.NOT_AVAILABLE) {
        return null;
    }

    const label = intl.get(
        "charts.baselineComparisonStatus.labels." +
            (status === ComparisonStatus.IN_PLAN
                ? "inPlan"
                : status === ComparisonStatus.DELAYED
                  ? "delayedDays"
                  : "earlyDays"),
        { num: dayDiff },
    );

    return (
        <div className="mt-[2px] text-xs text-white">
            <div className="flex flex-row justify-between">
                <ProcessTooltipBaselineDate
                    value={formatDate(baselineDetails.start.date)}
                    isDifferentFromCurrent={processDetails.start?.value !== baselineDetails.start?.value}
                />
                <ProcessTooltipBaselineDate
                    value={formatDate(baselineDetails.end.date)}
                    isDifferentFromCurrent={processDetails.end?.value !== baselineDetails.end?.value}
                />
            </div>
            <div className="mb-2 mt-1 flex flex-row items-center justify-between">
                <div className={`mt-2 h-[8px] grow rounded-full ${statusBarColor}`} />
                <span className="w-fit-content ml-1 mt-1 font-semibold">{label}</span>
            </div>
        </div>
    );
}
