import * as React from "react";
import { useNavigate } from "react-router-dom";
import { ModalAssetHeader } from "../../ModalAsset";
import { ChoiceGroup, DefaultButton, IChoiceGroupOption, PrimaryButton } from "@fluentui/react";
import { ModalAssetFooter } from "../../ModalAsset/ModalAssetFooter";
import { Icons } from "../../../RegisteredIcons";
import { useEffect, useState } from "react";
import { ItemType } from "../../../ToDo/interface";
import { intl } from "@/legacy/GlobalHelperReact";
import { RouteNames } from "../filter.interface";
import { useToDoModalFilterStore } from "../../../../app/store/toDoModalFilterStore";
import { styles } from "../filterModalStyles";
import { WebAppTelemetryFactory } from "@/app/services/WebAppTelemetry.service";

export function ToDoTypeFilter() {
    const navigate = useNavigate();
    const { filterData, setFilterData } = useToDoModalFilterStore();
    const [defaultSelected, setDefaultSelected] = useState(null);
    const [selectedMode, setSelectedMode] = useState(null);

    const options: IChoiceGroupOption[] = [
        {
            key: ItemType.ACTION_ITEM,
            text: intl.get("ModalToDoFilter.ToDoTypeFilter.typeAction"),
            iconProps: { iconName: Icons.Lcmd_Warning },
            imageSize: { width: 145, height: 96 },
        },
        {
            key: ItemType.STABILITY_CRITERIA,
            text: intl.get("ModalToDoFilter.ToDoTypeFilter.typeStability"),
            iconProps: { iconName: Icons.Lcmd_Scales },
            imageSize: { width: 145, height: 96 },
        },
    ];

    useEffect(() => {
        filterData?.typeFilterData?.forEach((type) => {
            const option = options.find((t) => t.key === type.key);
            setDefaultSelected(option.key);
        });
    }, [filterData]);

    function headerTitle() {
        return (
            <>
                {intl.get("ModalToDoFilter.ToDoTypeFilter.filter")} / {intl.get("ModalToDoFilter.ToDoTypeFilter.type")}
            </>
        );
    }

    function footerLeftContent() {
        return (
            <DefaultButton
                styles={{ root: { border: "none" } }}
                text={intl.get("ModalToDoFilter.ToDoTypeFilter.clearFilters")}
                iconProps={{ iconName: "Delete" }}
                onClick={() => {
                    setFilterData(null);
                }}
            />
        );
    }

    function footerRightContent() {
        return (
            <>
                <PrimaryButton
                    text={intl.get("ModalToDoFilter.ToDoTypeFilter.done")}
                    onClick={() => {
                        setFilterData({ ...filterData, typeFilterData: selectedMode });
                        navigate(RouteNames.HOME);
                        if (selectedMode != null) {
                            WebAppTelemetryFactory.trackEvent('todo-type-filter-applied',{
                                type: defaultSelected
                            });
                        }
                    }}
                />
            </>
        );
    }

    return (
        <>
            <ModalAssetHeader
                onBackButtonChange={() => {
                    navigate(RouteNames.HOME);
                }}
                title={headerTitle()}
            />

            <div className={styles.body + " " + styles.bodyPadding}>
                <ChoiceGroup
                    options={options}
                    onChange={(event, option) => {
                        setDefaultSelected(option.key);
                        setSelectedMode([{ name: option.text, key: option.key }]);
                    }}
                    selectedKey={defaultSelected}
                />
            </div>

            <ModalAssetFooter leftContent={footerLeftContent()} rightContent={footerRightContent()} />
        </>
    );
}
