import React, { useState } from "react";
import { LcmFacepile } from "@/components/common/LcmFacepile";
import { Checkbox } from "@/components/ui/checkbox";
import { intl } from "@/legacy/GlobalHelperReact";
import { createUserName } from "@/components/Utils";
import { UserWithKey } from "./ReasonCodesRolesModal";
import { useLCMD } from "@/app/LCMContext";
import { TradesEllipses } from "@/components/common/TradesEllipses";

type DetailsUserType = {
    user: UserWithKey;
    selectedUsers?: string[];
    setSelectedUsers?: (selectedUsers: string[]) => void;
};

export function DetailsUser({ user, selectedUsers, setSelectedUsers }: DetailsUserType) {
    const LCMD = useLCMD();
    const isChecked = selectedUsers.includes(user.key);
    const [userTrades, setUserTrades] = useState(null);

    LCMD.useTradesEffect(
        (err, data) => {
            const tradesData = data[Object.keys(data)[0]];

            LCMD.getProjectDetails((error, projectData) => {
                const rbacs = projectData?.rbac || {};
                const rbacsByUser = rbacs[user?.key];

                if (rbacsByUser) {
                    const filteredData = tradesData.filter((trade) =>
                        rbacsByUser.filter.contraints.includes(trade.name),
                    );
                    setUserTrades(filteredData);
                } else {
                    setUserTrades([]);
                }
            });
        },
        [user],
    );

    return (
        <div className="flex h-10 items-center justify-between">
            <div className="my-2 flex items-center gap-3">
                <Checkbox
                    checked={isChecked}
                    className="h-5 w-5"
                    onCheckedChange={() =>
                        !isChecked
                            ? setSelectedUsers([...selectedUsers, user.key])
                            : setSelectedUsers(selectedUsers?.filter((value: string) => value !== user.key))
                    }
                />
                {user && <LcmFacepile persona={[user]} circleSize={30} />}
                <div>
                    <p className="text-sm font-normal leading-4 text-zinc-950">
                        {createUserName({ email: user.email, meta: user.meta })}
                    </p>
                    <p className="text-[10px] font-normal leading-3 text-gray-600">{user?.email}</p>
                </div>
            </div>

            <div className="flex items-center gap-2">
                {/* <TradesEllipses trades={userTrades} /> */}
                <div className="w-16 overflow-hidden text-ellipsis text-right text-xs font-normal">
                    {intl.get(`users.share.add.role.${user.role}`)}
                </div>
            </div>

        </div>
    );
}
