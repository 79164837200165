import { Locale } from "date-fns";
import { de, enUS, fr, es } from "date-fns/locale";

const DEFAULT_LOCALE = enUS;

const languageLocales = {
    de: de,
    deDE: de,
    en: enUS,
    enUS: enUS,
    fr: fr,
    frFR: fr,
    es: es,
    esES: es,
};

export function getCurrentLanguage(): string {
    // TODO: should add and use this method from lcmd2ssf
    return (globalThis?.navigator as any)?.userLanguage || globalThis?.navigator?.language;
}

export function getCurrentLocale(): Locale {
    const langCode = getCurrentLanguage()?.replace("-", "");
    return languageLocales[langCode] || DEFAULT_LOCALE;
}
