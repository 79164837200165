import { useState } from "react";
import { useLCMD } from "@/app/LCMContext";
import { LCMDContextCardStatus as DailyBoardAPICardStatus, intl } from "lcmd2framework";
import { Gauge } from "../Gauge/Gauge";
import { ProcessTooltipSlider } from "../ProcessTooltipSlider/ProcessTooltipSlider";
import { formatDate } from "./Wrapper";
import { ProcessTooltipType } from "./ProcessTooltip";
import { ProcessStatuses } from "@/model/GlobalHelper";
import { getMilestoneStatus } from "@/utils/StatusUtils";
import { today } from "@/legacy/settings";
import { assert } from "@/model/GlobalHelper";
import { convertMillisecondsToDays } from "@/utils/DateUtils";

// TODO: refactor me so that I use e.g. startDate, endDate, ... instead of processDetails
export function MilestoneTooltip({ processDetails, canvasTaskId }: ProcessTooltipType) {
    assert(processDetails !== undefined, "processDetails needs to be defined for MilestoneTooltip");
    assert(processDetails.isMilestone.value, "process needs to be a Milestone in MilestoneTooltip");

    const LCMD = useLCMD();
    const milestones = processDetails.milestoneState.value;
    const endDateInDays = convertMillisecondsToDays(processDetails.end.value);
    const { p, s: statusForCards } = getMilestoneStatus(milestones, today, endDateInDays);

    //Dates für UI
    const startDate = formatDate(processDetails.start.date);
    const endDate = formatDate(processDetails.end.date);

    const [progress, setProgress] = useState<number>(p);

    function handleSliderChange(num: number) {
        {
            LCMD.setProcessDetails(
                canvasTaskId,
                {
                    cards: {
                        s: num === 0 ? DailyBoardAPICardStatus.OPEN : DailyBoardAPICardStatus.DONE,
                    },
                },
                false,
            );
        }

        setProgress(num * 100);
    }

    return (
        <>
            <div className="mb-2 flex content-center gap-x-2">
                <Gauge value={progress} size="small" showValue={true} />
                <div className="flex flex-col justify-center">
                    <p className="font-inter text-xs leading-3 text-gray-400">{processDetails.trades?.value[0].name}</p>
                    <p className="font-inter text-base font-semibold leading-5 tracking-tighter text-gray-50">
                        {processDetails.name.value} (
                        {intl.get(`charts.statusTracking.labels.${ProcessStatuses[statusForCards]}`)})
                    </p>
                </div>
            </div>
            <ProcessTooltipSlider
                value={[progress]}
                valueLabel={startDate}
                valueLabelClass="bg-[#030712]"
                max={1}
                step={1}
                startLabel={startDate}
                endLabel={endDate}
                onValueChange={(vals) => {
                    setProgress(vals[0]);
                }}
                onValueCommit={(vals) => handleSliderChange(vals[0])}
            />
        </>
    );
}
