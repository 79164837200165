import { Input } from "@/components/ui/input";
import { Search, X } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useState, ChangeEvent } from "react";
import { cn } from "@/lib/utils";
import { intl } from "lcmd2framework";

export type SearchBar = {
    onSearch?: (value: string) => void;
    onChange?: (value: string) => void;
    value?: string;
    placeholder?: string;
    className?: string;
    onKeyUpChange?: (event) => void;
    autoFocus?: boolean;
    searchBarClassName?: string;
    inputClassName?: string;
};

export function SearchBar({
    value,
    onChange: handleChange,
    placeholder,
    className,
    searchBarClassName,
    inputClassName,
    onKeyUpChange,
    autoFocus,
}: SearchBar) {
    if (!handleChange) {
        throw new Error("onChange must be defined");
    }
    if (handleChange && typeof handleChange !== "function") {
        throw new Error(`onChange must be type of function. ${typeof handleChange} given.`);
    }

    const [textValue, setValue] = useState(value || "");

    function handleValueChange(evt: ChangeEvent<HTMLInputElement>) {
        setValue(evt.target.value);

        if (!onKeyUpChange && handleChange) {
            if (evt.target.value.length == 0) {
                resetText();
            } else {
                handleChange(evt.target.value.toString());
            }
        }
    }

    function resetText() {
        setValue("");
        handleChange("");
    }

    function handleKeyUp(e) {
        onKeyUpChange && onKeyUpChange(e);

        if (e.keyCode == 13) {
            handleChange(e.target.value.toString());
        }
        if (e.key === "Escape") {
            resetText();
        }
    }

    return (
        <div className={cn("relative flex w-80 items-center", className)}>
            <Search
                className={cn(
                    "absolute left-3 top-1/2 my-auto h-6 w-6 -translate-y-1/2 transform text-gray-500",
                    searchBarClassName,
                )}
            />
            <Input
                type="text"
                placeholder={placeholder || intl.get("CommonUse.Search")}
                className={cn("pl-10 pr-4", inputClassName)}
                onChange={handleValueChange}
                value={textValue}
                onKeyUp={handleKeyUp}
                autoFocus={autoFocus}
            />
            {value && value.length && (
                <Button
                    size={"icon"}
                    variant={"secondary"}
                    className={"absolute right-1 top-1/2 my-auto h-6 w-6 -translate-y-1/2 transform"}
                    onClick={resetText}
                >
                    <X size={20} />
                </Button>
            )}
        </div>
    );
}
