import { useCallback } from "react";
import { Card, CardContent } from "@/components/ui/card";
import { LucideIcon } from "lucide-react";
import { LCMDTooltip } from "@/components/common/Tooltip/LCMDTooltip";
import { useActiveBaseline } from "@/app/store/baselineStore";
import { ViewTypes } from "@/components/view/constants";
import { intl } from "@/legacy/GlobalHelperReact";

type ItemProps = {
    type: string;
    iconName: LucideIcon;
    label: string;
    selected: boolean;
    onSelect: (type: string) => void;
    userflowId?: string;
    colSpan: string;
};

function Item({ type, iconName: Icon, label, selected, onSelect, userflowId, colSpan }: ItemProps) {
    const activeBaseline = useActiveBaseline();
    const noActiveBaseline = !activeBaseline;
    const isDisabled = type === ViewTypes.BASELINE && noActiveBaseline;

    const onClick = useCallback(() => !isDisabled && onSelect(type), [type]);
    const selectionStyle = selected ? "bg-zinc-900 text-white" : "border-gray-200";

    const cardContent = (
        <CardContent className="flex h-[40px] items-center justify-center gap-2 self-auto px-3">
            <Icon size={16} />
            <div className="text-sm">{label}</div>
        </CardContent>
    );

    const cardProps = {
        className: `hover:cursor-pointer ${selectionStyle} ${colSpan}`,
        onClick,
        "data-userflow-id": userflowId,
    };

    if (isDisabled) {
        return (
            <LCMDTooltip text={intl.get("canvas.cmd.view.types.baseline.noBaselineTooltip")} asChild side="bottom">
                <Card {...cardProps} className={`opacity-50 ${selectionStyle} ${colSpan}`}>
                    {cardContent}
                </Card>
            </LCMDTooltip>
        );
    }

    return <Card {...cardProps}>{cardContent}</Card>;
}

export { Item };
