import * as React from "react";
import { intl } from "@/legacy/GlobalHelperReact";

import { format } from "date-fns";

import { decimalToRgb } from "../../utils/color/decimal-to-rgb";
import { mergeStyleSets, Stack, Text } from "@fluentui/react";
import { MilestoneI } from "../types";
import { CSSProperties, Fragment } from "react";
import { getMilstoneStateColor } from "../Utils";
import { getCurrentLocale } from "@/components/utils/date/locale";

export type BodyProps = {
    milestoneDate: number;
    milestone: MilestoneI;
};

const classNames = mergeStyleSets({
    milestoneColor: {
        width: 10,
        height: 10,
        borderRadius: 2,
    },
    milestoneDate: {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
    },
});

const tokensProps = { childrenGap: 5 };

function Body(props: BodyProps) {
    const { milestone, milestoneDate } = props;
    const spanStyle: CSSProperties = {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "10px",
        lineHeight: "12px",
    };

    const dayDifference = milestone.b - milestone.x;

    return (
        <Fragment>
            <Stack horizontal verticalAlign="center" tokens={tokensProps}>
                <Stack.Item shrink={0}>
                    <div className={classNames.milestoneColor} style={{ backgroundColor: decimalToRgb(milestone.c) }} />
                </Stack.Item>
                <Text className={classNames.milestoneDate}>
                    {format(milestoneDate, intl.get("charts.milestones.overview.dateFormat"), {
                        locale: getCurrentLocale(),
                    })}
                </Text>
            </Stack>
            {milestone.b >= 0 && (
                <Stack horizontal>
                    <Text style={{ ...spanStyle, color: getMilstoneStateColor(milestone), fontWeight: 600 }}>
                        {intl.get("charts.milestones.labels.days", { num: dayDifference })}
                    </Text>
                    <Text style={spanStyle}>&nbsp;{intl.get("charts.milestones.labels.difference")}</Text>
                </Stack>
            )}
        </Fragment>
    );
}

export { Body };
