import { ConfirmDialog } from "@/components/common/confirmDialog/confirmDialog";
import { intl } from "lcmd2framework";
import { AlertDialog } from "@/components/common/AlertDialog/AlertDialogV2";

export function DeleteConfirmModal({
    onOk,
    onCancel,
    filterName,
}: {
    onOk: () => void;
    onCancel: () => void;
    filterName: string;
}) {
    return (
        <AlertDialog
            open={true}
            onOpenChange={onCancel}
            title={intl.get("filter.manage.delete.title")}
            subText={intl.get("filter.manage.delete.subText", { name: filterName })}
            onOk={onOk}
            okButtonText={intl.get("filter.manage.cmd.delete.text")}
            cancelButtonText={intl.get("fw.cancel")}
        />
    );
}
