import { EGlobeState, GlobeField } from "@/components/common/globeField";
import { Input } from "@/components/ui/input";

type InputWithGlobeFieldProps = {
    value: string;
    onInputValueChange: (value: string) => void;
    placeholder?: string;
    initialGlobeState?: EGlobeState;
    onGlobalStateChange(globeState: EGlobeState): void;
};

export function InputWithGlobeField({
    value,
    onInputValueChange,
    placeholder = "",
    initialGlobeState,
    onGlobalStateChange,
}: InputWithGlobeFieldProps) {
    return (
        <Input
            value={value}
            placeholder={placeholder}
            suffix={
                <GlobeField
                    initialGlobeState={initialGlobeState !== undefined ? initialGlobeState : undefined}
                    onGlobalStateClick={onGlobalStateChange}
                />
            }
            suffixClassName="p-0"
            onChange={(e) => onInputValueChange(e.target.value)}
        ></Input>
    );
}
