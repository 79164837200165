import { Persona } from "@/legacy/SubTypes";
import { SubCache } from "@/legacy/SubCache";
import { getUserDisplayName } from "@/utils/GeneralUtils";

export async function fetchUserData(text: string, authToken: string): Promise<Persona[]> {
    const regex = /\$\{"([0-9a-fA-F-]{36})"\}/g;
    const matches = text.matchAll(regex);
    const userIds = [...matches].map((match) => match[1]);

    return new Promise((resolve) => {
        SubCache.getPersonas(authToken, userIds, (result: Persona[]) => resolve(result));
    });
}

export async function replaceUserIdsWithNames(
    text: string,
    authToken: string,
    withOutUserId: boolean = false,
): Promise<string> {
    const users = await fetchUserData(text, authToken);
    const transformedText = replaceUserIdsWithNamesSync(text, users);
    return withOutUserId ? transformedText.replace(/\$\{"([^"}]+)"\}/g, "") : transformedText;
}

export function replaceUserIdsWithNamesSync(text: string, users: Persona[]): string {
    if (!text && text.length < 1) {
        return "";
    }

    return text.replace(/<br\s*\/?>(g)/, "\n").replace(/\$\{"([^"}]+)"\}/g, (match, p1) => {
        const person = users.find((p) => p.data.sub === p1);
        return person ? `${match} @{"${getUserDisplayName(person.data)}"}` : match;
    });
}
