import { SearchBar } from "../SearchBar/SearchBar";
import { ArrowDown, ArrowUp, X } from "lucide-react";
import { IconButton } from "../IconButton/IconButton";
import { Separator } from "@/components/ui/separator";

export type ExtendedSearchBox = {
    searchText?: string;
    onClose: () => void;
    onNext: () => void;
    onPrevious: () => void;
    onSearch: (value) => void;
    currentResult: number;
    allResults: number;
};

export function ExtendedSearchBox(props: ExtendedSearchBox) {
    function handleEnter(event) {
        if (event.currentTarget.value == "") {
            return;
        }

        if (event.key === "Enter") {
            if (event.shiftKey) {
                props.onPrevious();
                return;
            }

            if (event.currentTarget.value != props.searchText) {
                props.onSearch(event.currentTarget.value);
            } else {
                props.onNext();
            }
            return;
        }
    }

    return (
        <div className="flex h-14 w-[500px] justify-between rounded-lg bg-white p-2 shadow">
            <div className="flex items-center gap-4">
                <SearchBar autoFocus onChange={() => {}} onKeyUpChange={handleEnter} value={props.searchText} />
                {props.searchText != "" && (
                    <div className="text-sm">
                        {props.currentResult}/{props.allResults}
                    </div>
                )}
            </div>
            <div className="flex items-center gap-1">
                <IconButton icon={ArrowUp} onClick={props.onPrevious} className="rounded" />
                <IconButton icon={ArrowDown} onClick={props.onNext} className="rounded" />
                <Separator orientation="vertical" />
                <IconButton icon={X} onClick={props.onClose} className="rounded" />
            </div>
        </div>
    );
}
