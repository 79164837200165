import { ICommandBarItemProps, IContextualMenuItem, IMessageBarStyles } from "@fluentui/react";
import { init } from "../main";
import { FrameworkHttpError } from "@/model/GlobalHelper";
import { SERVICES } from "@/model/services";
export { isCanary, isReleaseCandidate } from "../../model/services";
export * from "./Dialog";
export * from "./Chart";
export * from "../../model/DataModelTypes";
import { LCMDChangesPanelChanges } from "./ParticleContext";
import { ICustomizedNavLinkGroup } from "@/components/interface";
import { CollaborationFacepileProps } from "@/app/components/CollaborationFacepile";
import { OnErrorProps, setOnErrorHandler } from "../ScreenOfDeath";
import { ReasonDisturbanceProps } from "@/components/common/ErrorNotifications/ReasonDisturbance/ReasonDisturbance";
import { ILegendProps } from "@/app/components/Legend/Legend";
import { WebAppTelemetryFactory } from "@/app/services/WebAppTelemetry.service";
export * from "./ParticleContext";
export {
    needsWhiteColor,
    EpochMSToLocaleDateStr,
    EpochDaystoEpochMS,
    EpochMStoEpochDays,
    UNIT_TYPES,
    HelperDateToUTCEpochMS,
    HelperEpochMSToLocalDate,
    HelperDateTo24TimeStr,
    DateToMonday,
    assert,
    FrameworkHttpError,
    FrameworkError,
    TableExportHelper,
    FrameworkUpdateNeededError,
    jsonToError,
    LCMDContextCardStatus,
} from "../../model/GlobalHelper";
export { errorToMessage, intl, localizeIfNeeded } from "../GlobalHelperReact";
export { LocalizedDatePicker, generateMoreColumn, NonProgressBar, roundRectPath } from "../GlobalHelperFluentUI";
export { DailyBoardReactAPI, DailyBoardFilterDialog } from "../DailyBoardReactAPI";
export { LCM } from "./DailyBoardAPI";
export { ChoiceWithCommit, DropdownWithCommit, TextFieldWithCommit, NonProgressPopup } from "../GlobalHelperFluentUI";
export { CARD_STATUS_TEXT } from "../GlobalHelperReact";
export { StackedDialog } from "../components/StackedDialog/StackedDialogV1";
export { createProject, addFileToProject, uploadFileBlocks } from "../../model/ApiHelper";
export { getServices } from "../../model/services";
export * from "../components/Canvas/Shared/CanvasHostBase";
export * from "../components/Canvas/Shared/CanvasHostRenderer";
export * from "../../model/DataModel";

export type FilterDialogOptions = {
    modeDailyBoard?: boolean;
};

export type CardDialogOptions = {
    card: {
        id: string;
    };
};

export type CanvasChartCallbacks = {
    onRenderCanvas?: any;
    onPointerDown?: any;
    onPointerUp?: any;
    onPointerMove?: any;
    onPointerEnter?: any;
    onPointerLeave?: any;
    onPointerCancel?: any;
    onClick?: any;
    Component?: (props: any) => JSX.Element;
    Overlay?: (props: any) => JSX.Element;
};

export type DigitalPlanningBoardSidebarContext = {
    useSelectionEffect: (cb: (ev: DigitalPlanningBoardSelectionEvent) => void, deps: React.DependencyList) => void;
    useTradesEffect: (cb: (ev: DigitalPlanningBoardTradesEvent) => void, deps: React.DependencyList) => void;
    useLibrariesEffect: (cb: (ev: DigitalPlanningBoardLibraryEvent) => void, deps: React.DependencyList) => void;
    scrollToProcess: (pid: number) => void;
    setTradeData: (tradeData: DigitalPlanningBoardTradeDetails) => void;
};

export type DigitalPlanningBoardSelectionEvent = {
    pid: number[];
    isWhiteboard?: boolean;
};

export type DigitalPlanningBoardTradeDetails = {
    projectId: string;
    color: number;
    id: number;
    label: string;
    name: string;
    trade: string;
    icon?: string;
    subs?: { [key: string]: string };
};

export type DigitalPlanningBoardLibraryDetails = {
    /** label */
    label: string;
    /** libary id */
    t: number;
};

export type DigitalPlanningBoardTradesEvent = {
    [projectId: string]: DigitalPlanningBoardTradeDetails[];
};

export type DigitalPlanningBoardLibraryEvent = {
    [projectId: string]: DigitalPlanningBoardLibraryDetails[];
};

export type DigitalPlanningBoardViewKey =
    | "project"
    | "dashboard"
    | "workshop"
    | "dailyboard"
    | "todo"
    | "settings"
    | "processview"
    | "taktzone"
    | "folder"
    | "login"
    | "tp";

export type DigitalPlanningBoardMetaData = {
    name: string;
    ts: number;
    role: string;
    error?: string;
};

export type DigitalPlanningBoardViewDetails = {
    key: DigitalPlanningBoardViewKey;
    projectId: string;
    libraries: {
        active: string | null;
        items: {
            [id: string]: {
                id: string;
                name: string;
            };
        };
    };
};

export type ShareDialogProps = {
    session?: any; // WorkerSession,
    projectSandbox: any; // string|WorkerSession,
    meta: any; // CanvasMeta,
    onClose: () => void;
    projectId?: string;
};

export type FilesOverlayProps = {
    hasCloseButton: boolean;
    projects: FilesOverlayProps_Project[];
    onClose: () => void;
    onUploadDone: (details: any) => void;
    onProjectEntryClick: (item: any) => any;
    loadingProjects?: boolean;
};

export type FilesOverlayProps_Project = {
    pid: string;
    pid_ts?: string;
    project?: {
        project?: {
            name: string;
        };
    };
};

export type DigitalPlanningBoardConfig<LCMDContextType> = {
    getParticleContext?: () => {
        instance: LCMDContextType;
        ctx: React.Context<LCMDContextType>;
    };
    DAILYBOARD_URL?: (opt: { isReleaseCandidate: boolean; isCanary: boolean }) => string;
    onError?: (props: OnErrorProps) => JSX.Element;
    onRejectedOps?: (LCMD: LCMDContextType, rejectedOps: { projectId: string; reason: string; i_op: number }[]) => void;
    onKeyDown?: (LCMD: LCMDContextType, event: KeyboardEvent) => boolean;
    locales?: { [locale: string]: any };
    dashboard?: (props: { filter; grid; error }) => JSX.Element;
    filterDialog?: (props: { isOpen: { manage?: boolean } | null; onClose: () => void }) => JSX.Element;
    filterButton?: (props: { checked: boolean }) => JSX.Element;
    previewButton?: (props: { checked: boolean }) => JSX.Element;
    headerMetaComponent?: (props: { metaData: DigitalPlanningBoardMetaData }) => JSX.Element;
    resourceChart?: CanvasChartCallbacks;
    milestonesChart?: CanvasChartCallbacks;
    projects?: {
        cmd: ((ctx: LCMDContextType) => ICommandBarItemProps[])[];
    };
    dialogs?: { [dialog_id: string]: (params?: any) => Promise<any> };
    exports?: {
        cmd: ((ctx: LCMDContextType) => IContextualMenuItem[])[];
    };
    whiteboard?: any;
    Todo?: (props: {}) => JSX.Element;
    Settings?: (props: { navigationOptions?: ICustomizedNavLinkGroup[] | undefined; subView?: string }) => JSX.Element;
    Sidebar?: (props: {
        ppTS: number;
        wbID: string;
        wbTS: number;
        sidebarCtx: DigitalPlanningBoardSidebarContext;
        view: DigitalPlanningBoardViewDetails;
        top: number;
        hidden: boolean;
        marquee: boolean /* marquee mode */;
        selectedTrade: DigitalPlanningBoardTradesEvent;
    }) => JSX.Element;
    ChangesPanel?: (props: { onDismiss: () => void; changes: LCMDChangesPanelChanges | null }) => JSX.Element;
    Legend?: (props: ILegendProps) => JSX.Element;
    // props:{card:any, onClose:()=>void, onChangeTrade?
    CardDialog?: (props: { card: any; onClose: () => void; onChangeTrade?; mode: DigitalPlanningBoardViewKey; }) => JSX.Element;
    ReasonDisturbance?: (props: ReasonDisturbanceProps) => JSX.Element;
    ShareDialog?: (props: ShareDialogProps) => JSX.Element;
    CollaborationFacepile?: (props: CollaborationFacepileProps) => JSX.Element;

    TradesSelectionDialog?: (props: {
        isOpen: { tid: number; trades: number[] } | null;
        onClose: () => void;
        options?: FilterDialogOptions;
    }) => JSX.Element;
    ProcessViewDialog?: (props: { readonly: boolean; isOpen: boolean; onClose: () => void }) => JSX.Element;
    ConnectionErrorAlertBox?: (props: {
        defaultStyle: IMessageBarStyles;
        onReconnect: () => void;
        connectionState: {
            rt?: any;
            error?: any;
        };
    }) => JSX.Element;
    CanvasProjectHost?: any;
    CanvasWhiteboardHost?: any;
};

export function initFramework<LCMDContextType>(
    config: {
        _canary?: boolean | "dev";
    } & DigitalPlanningBoardConfig<LCMDContextType>,
) {
    setOnErrorHandler(config?.onError || null);
    if ("complete" === document.readyState) {
        init(config);
    } else {
        window.addEventListener("load", init.bind(null, config));
    }
    const observer = new PerformanceObserver((list) => {
        list.getEntries().forEach((entry) => {
            // @ts-ignore
            if ("reload" === entry?.type) {
                WebAppTelemetryFactory.trackEvent("website-reloaded");
                observer.disconnect();
            }
        });
    });
    observer.observe({ type: "navigation", buffered: true });
}

export type CheckForUpdatesResult = {
    updateAvailable: boolean;
    currentBuild: string;
    availableBuild: string;
};

export function checkForUpdates(): Promise<CheckForUpdatesResult> {
    return new Promise((resolve: (ret: CheckForUpdatesResult) => void, reject: (e: FrameworkHttpError) => void) => {
        const xhr = new XMLHttpRequest();
        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4) {
                if (200 === xhr.status) {
                    const s = xhr.responseText;
                    const reg = /bundle_(?<hash>[^.]*)\.js/;
                    const m = s.match(reg);
                    const hash = m?.groups?.hash;
                    const res: CheckForUpdatesResult = {
                        updateAvailable: hash !== SERVICES?.BUILD?.BUNDLE_HASH,
                        currentBuild: SERVICES?.BUILD?.BUNDLE_HASH || null,
                        availableBuild: hash || null,
                    };
                    resolve(res);
                } else {
                    reject(new FrameworkHttpError(xhr.status));
                }
            }
        };
        xhr.open("GET", "/?now=" + Date.now());
        xhr.responseType = "text";
        xhr.send();
    });
}
export { StackedDialogView } from "../components/StackedDialogView/StackedDialogView";
