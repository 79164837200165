import { ComplexProp, Core, DocumentTransaction, TradesGetter } from "@/core/lcmd2core";

export const processDefaultColor = 0x94A3B8;

export class TradeService {

    /**
     * Konstruktor für den TradeService.
     * Initialisiert das Core-Modul zur Interaktion mit Handelsdaten.
     * @param core - Die Core-Instanz, die für Handelsoperationen verwendet wird.
     */
    constructor(private core: Core) {
    }

    /**
     * Berechnet die numerische Farbe aus einem Hex-String.
     * @param hexString - Der Hexadezimal-String der Farbe (z. B. "#FF5733").
     * @returns Die numerische Darstellung der Farbe oder null, wenn der Eingabewert ungültig ist.
     */
    public static calculateColor(hexString: string): number | null {
        if (!hexString) {
            return null;
        }
        if (hexString[0]==="#") {
            hexString = hexString.substring(1);
        }
        return parseInt(hexString, 16);
    }

    /**
     * Erstellt einen neuen Handel mit den angegebenen Eigenschaften.
     * @param name - Der Name des Handels.
     * @param colorHex - Die Farbe des Handels als Hex-String.
     * @param iconTitle - Der Titel des Icons für den Handel (optional).
     * @param extensions - Zusätzliche Eigenschaften für den Handel (optional).
     * @returns Ein Promise, das die ID des neu erstellten Handels zurückgibt.
     */
    public async create({ name, colorHex, iconTitle, extensions }: {
        name: string,
        colorHex: string,
        iconTitle?: string,
        extensions?: Record<string, string>
    }): Promise<number> {
        if (name.length < 1) return null;
        if (colorHex.length < 1) return null;

        const documentTransaction = new DocumentTransaction(this.core);
        const tradeId = documentTransaction.createTrade(name);
        documentTransaction.setTradeProp(tradeId, "color", TradeService.calculateColor(colorHex));
        documentTransaction.setTradeProp(tradeId, "icon", iconTitle);
        for (const [key, value] of Object.entries(extensions)) {
            documentTransaction.setTradeProp(tradeId, "lcmx."+key, value);
        }
        documentTransaction.commit();
        await this.core.sync();
        return tradeId;
    }

    /**
     * Aktualisiert einen vorhandenen Handel mit neuen Eigenschaften.
     * @param id - Die ID des Handels, der aktualisiert werden soll.
     * @param name - Der neue Name des Handels.
     * @param colorHex - Die neue Farbe des Handels als Hex-String.
     * @param iconTitle - Der neue Titel des Icons für den Handel (optional).
     * @param extensions - Zusätzliche Eigenschaften für den Handel (optional).
     * @returns Ein Promise, das die ID des aktualisierten Handels zurückgibt.
     */
    public async update(id: number, { name, colorHex, iconTitle, extensions }: {
        name: string,
        colorHex: string,
        iconTitle?: string,
        extensions?: Record<string, string>
    }): Promise<number> {
        if (!id) return null;
        if (name.length < 1) return null;
        if (colorHex.length < 1) return null;

        const documentTransaction = new DocumentTransaction(this.core);
        documentTransaction.setTradeProp(id, "name", name);
        documentTransaction.setTradeProp(id, "color", TradeService.calculateColor(colorHex));
        documentTransaction.setTradeProp(id, "icon", iconTitle);
        for (const [key, value] of Object.entries(extensions)) {
            documentTransaction.setTradeProp(id, "lcmx."+key, value);
        }
        documentTransaction.commit();
        await this.core.sync();
        return id;
    }

    /**
     * Ruft die Eigenschaften eines Gewerk anhand seiner ID ab.
     * @param id - Die ID des Gewerk, dessen Eigenschaften abgerufen werden sollen.
     * @returns Ein Objekt mit den Eigenschaften des Gewerk, wie Name, Farbe und Erweiterungen.
     */
    public get(id: number) {
        if (!id) return null;
        const tradesGetter = new TradesGetter(this.core, id);
        const _name = tradesGetter.value<string>("name", "");

        const _trade = tradesGetter.value<string>("trade", "");
        let label: string;
        if ((_trade || "").length > 0 && _trade!==_name) {
            label = _trade + " (" + _name + ")";
        } else {
            label = _name;
        }
        const subs = {};
        const extensions = {};
        {
            const it = tradesGetter.getAllProps();
            const p = new ComplexProp();
            for (it.first(p); it.next(p);) {
                const sub = p.isSUB();
                const extension = p.isLCMX();
                const value = tradesGetter.value<any>(p);
                if (sub && value) {
                    subs[sub] = value;
                }
                if (extension && value) {
                    extensions[extension] = value;
                }
            }
        }
        return {
            projectId: this.core.getSID(),
            label: label,
            name: _name,
            trade: _trade,
            color: tradesGetter.value<number>("color", processDefaultColor),
            icon: tradesGetter.icon,
            id: id,
            subs: subs,
            extensions: extensions
        };
    }

    /**
     * Ruft alle verfügbaren Gewerksdaten ab, optional einschließlich gelöschter Einträge.
     * @param includeDeleted - Gibt an, ob gelöschte Gewerksdaten einbezogen werden sollen.
     * @returns Ein Array mit Objekten, die alle Gewerksdaten enthalten.
     */
    public getTrades(includeDeleted: boolean = false): any[] {
        const trGt = new TradesGetter(this.core);
        const tradeIds = this.core.getAllTradeIds();
        return tradeIds.reduce((trades, tradeId) => {
            trGt.reset(tradeId);
            const _name = trGt.value<string>("name", "");
            if (null!==_name || includeDeleted) {
                // not deleted
                const _trade = trGt.value<string>("trade", "");
                let label: string;
                if ((_trade || "").length > 0 && _trade!==_name) {
                    label = _trade + " (" + _name + ")";
                } else {
                    label = _name;
                }
                const subs = {};
                const extensions = {};
                {
                    const it = trGt.getAllProps();
                    const p = new ComplexProp();
                    for (it.first(p); it.next(p);) {
                        const sub = p.isSUB();
                        const extension = p.isLCMX();
                        const value = trGt.value<any>(p);
                        if (sub && value) {
                            subs[sub] = value;
                        }
                        if (extension && value) {
                            extensions[extension] = value;
                        }
                    }
                }
                trades.push({
                    projectId: this.core.getSID(),
                    label: label,
                    name: _name,
                    trade: _trade,
                    color: trGt.value<number>("color", processDefaultColor),
                    icon: trGt.icon,
                    id: tradeId,
                    subs: subs,
                    extensions: extensions
                });
            }
            return trades;
        }, []);
    }

    /**
     * Ruft die Eigenschaften eines Gewerks anhand seiner ID ab.
     * @param id - Die ID des Gewerks, dessen Eigenschaften abgerufen werden sollen.
     * @returns Ein Iterator über alle Eigenschaften des Handels.
     */
    public getTradeProperties(id: number) {
        const tradesGetter = new TradesGetter(this.core, id);
        return tradesGetter.getAllProps();
    }
}
