import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";

type EmptyViewProps = {
    size?: "xl" | "l";
    imgSrc?: string;
    header?: string;
    text?: string;
    buttonText?: string;
    onButtonClicked?: () => void;
};

export function EmptyView({ size = "xl", imgSrc, header, text, buttonText, onButtonClicked }: EmptyViewProps) {
    let imageSize: string, headerFontSize: string, dimensions: string;
    switch (size) {
        case "xl": {
            imageSize = "h-[200px] w-[200px]";
            headerFontSize = "text-3xl";
            dimensions = "h-screen w-screen";
            break;
        }
        case "l": {
            imageSize = "h-[160px] w-[160px]";
            headerFontSize = "text-xl";
            dimensions = "w-full h-full";
            break;
        }
        default: {
            imageSize = "h-[200px] w-[200px]";
            headerFontSize = "text-3xl";
            dimensions = "h-screen w-screen";
        }
    }
    return (
        <div className={cn(dimensions, "flex flex-col items-center justify-center gap-3 text-center")}>
            {imgSrc && <img alt="Empty View Image" className={imageSize} src={imgSrc} />}
            {header && (
                <h2 className={cn(headerFontSize, "text-center font-semibold leading-9 tracking-tight")}>{header}</h2>
            )}
            {text && <p className="text-s text-center font-medium leading-5 text-gray-500">{text}</p>}
            {buttonText && onButtonClicked && (
                <Button onClick={() => onButtonClicked()} className="text-left text-sm font-medium leading-6">
                    {buttonText}
                </Button>
            )}
        </div>
    );
}
