import * as React from "react";
import { intl } from "@/legacy/GlobalHelperReact";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import { LcmdModal2 } from "@/components/common/LcmModal2";
import { useEffect, useState } from "react";
import { Counter } from "@/components/common/counter/CounterV2";
import { TradesList } from "../../Filter/dialogs/FilterDialog";
import { WebAppTelemetryFactory } from "@/app/services/WebAppTelemetry.service";

const minValue = 0;
type Props = {
    stabilityCriteriaToEdit?: any;
    onCancel: () => void;
    onAccept: (stabilityCriteria: any) => void;
    showDialog: boolean;
    allTrades: any;
};
export const SettingsModal = ({ stabilityCriteriaToEdit, allTrades, onCancel, onAccept, showDialog }: Props) => {
    const initialSelectedTrades = {};
    stabilityCriteriaToEdit?.trades.forEach((t) => {
        initialSelectedTrades[t.id] = t;
    });
    const [leadTime, setLeadTime] = useState(
        stabilityCriteriaToEdit?.leadTimeValue ? stabilityCriteriaToEdit.leadTimeValue : 0,
    );
    const [currentStabilityCriteria, setCurrentStabilityCriteria] = useState(stabilityCriteriaToEdit || {});
    const [currentStabilityCriteriaName, setCurrentStabilityCriteriaName] = useState(stabilityCriteriaToEdit?.name);
    const [selectedTrades, setSelectedTrades] = useState(initialSelectedTrades || {});
    const [isAllChecked, setAllChecked] = useState(false);
    const stabilityTitle = !stabilityCriteriaToEdit
        ? intl.get("StabilityCriteria.SettingsModal.NewHeadlineText")
        : intl.get("StabilityCriteria.SettingsModal.EditHeadlineText");

    useEffect(() => {
        if (Object.keys(selectedTrades).length == allTrades.length) {
            setAllChecked(true);
        } else {
            setAllChecked(false);
        }
    }, [selectedTrades]);

    const cleanUpModal = () => {
        setSelectedTrades([]);
        setLeadTime(0);
        setCurrentStabilityCriteriaName("");
        setCurrentStabilityCriteria({});
    };

    const changeLeadTimeValue = (value: number) => {
        if (value < minValue) {
            value = minValue;
        }
        setLeadTime(value);
    };

    const saveStabilityCriteria = () => {
        const stabilityCriteria: any = {
            name: currentStabilityCriteriaName,
            leadTimeValue: leadTime,
            trades: Object.values(selectedTrades),
        };
        if (stabilityCriteriaToEdit?.key) {
            stabilityCriteria.key = stabilityCriteriaToEdit.key;
        }
        WebAppTelemetryFactory.trackEvent("settings-stability-criteria-added");
        setCurrentStabilityCriteria(stabilityCriteria);
        onAccept(stabilityCriteria);
        cleanUpModal();
    };

    const buttons = [
        <Button
            key="cancel"
            variant="secondary"
            onClick={() => {
                onCancel();
                cleanUpModal();
            }}
        >
            {intl.get("StabilityCriteria.SettingsModal.CancelButtonText")}
        </Button>,
        <Button onClick={saveStabilityCriteria} key="save" disabled={!currentStabilityCriteriaName}>
            {intl.get("StabilityCriteria.SettingsModal.ApplyButtonText")}
        </Button>,
    ];
    return (
        <div>
            <LcmdModal2
                header={{ title: stabilityTitle }}
                className="w-[600px]"
                buttons={buttons}
                open={showDialog}
                onOpenChange={() => {
                    onCancel();
                }}
            >
                <div className="flex h-full flex-col gap-6 px-8 py-0">
                    <div>
                        <Label className="font-semibold">
                            {intl.get("StabilityCriteria.SettingsModal.NameTextFieldLabel")}
                        </Label>
                        <Input
                            value={currentStabilityCriteriaName}
                            onChange={(ev) => {
                                setCurrentStabilityCriteriaName(ev.currentTarget.value);
                            }}
                        />
                    </div>
                    <div>
                        <span className="min-h-[800px] min-w-[600px]">
                            {intl.get("StabilityCriteria.SettingsModal.LeadTimeTextFieldLabel")}
                        </span>
                        <div className="flex flex-row items-center gap-2">
                            <div>
                                <Counter
                                    min={0}
                                    value={leadTime}
                                    onChange={(newValue: number) => {
                                        changeLeadTimeValue(newValue);
                                    }}
                                />
                            </div>
                            <span>{intl.get("StabilityCriteria.SettingsModal.WorkingDaysText")}</span>
                        </div>
                    </div>
                    <span>{intl.get("StabilityCriteria.SettingsModal.AppliesToTextFieldLabel")}</span>
                    <div className="relative max-h-60 overflow-y-auto">
                        <TradesList
                            trades={allTrades}
                            selected={selectedTrades}
                            setSelected={setSelectedTrades}
                            search={null}
                            multiselect={true}
                        />
                    </div>
                </div>
            </LcmdModal2>
        </div>
    );
};
