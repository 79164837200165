import { useLCMD } from "../../app/LCMContext";
import { useEffect, useState } from "react";
import { HistoryActionName, HistoryMeta } from "../../app/interface";
import { LCMDHookResult } from "../../app/types/LCMDHookResult";
import { processDefaultColor } from "@/core/services/Trade.service";

export function useTradeColorOfProcesses(changeEvent: HistoryMeta): LCMDHookResult<Map<number, number>> {
    const LCMD = useLCMD();
    const [hookResult, setHookResult] = useState<LCMDHookResult<Map<number, number>>>({
        isLoading: true,
        isError: false,
        error: undefined,
        data: undefined,
    });

    if (!Array.isArray(changeEvent.events)) {
        console.warn("changeEvent.events is no array");
        setHookResult({
            isError: true,
            isLoading: false,
            error: new Error("changeEvent.events is no array"),
            data: undefined,
        });
        return hookResult;
    }

    useEffect(() => {
        if (changeEvent && changeEvent.target === "process") {
            const processDetailPromises = [];
            changeEvent.events.forEach((event) => {
                if (typeof event.id !== "number") {
                    console.warn(`process event id of ${event.processName} is not a number`);
                    return;
                }
                const getProcessDetailsPromise = new Promise((resolve, reject) => {
                    LCMD.getProcessDetails(event.id, (error, data) => {
                        if (error) {
                            reject(error);
                        } else {
                            resolve({ processId: event.id, tradeColor: data?.trades?.value[0]?.color || processDefaultColor });
                        }
                    });
                });
                processDetailPromises.push(getProcessDetailsPromise);
            });

            Promise.allSettled(processDetailPromises).then((results) => {
                const tradeColorOfProcess = new Map();
                results.forEach((result) => {
                    if (result.status !== "fulfilled") {
                        return;
                    }

                    tradeColorOfProcess.set(result.value.processId, result.value.tradeColor);
                });
                setHookResult({ ...hookResult, data: tradeColorOfProcess, isLoading: false });
            });
        }
    }, [changeEvent, LCMD]);

    return hookResult;
}

export function useOldValuesOfProcesses(changeEvent: HistoryMeta): number[] {
    const LCMD = useLCMD();
    const [oldValues, setOldValues] = useState([]);

    if (!Array.isArray(changeEvent.events)) {
        console.warn("changeEvent.events is no array");
        return [];
    }

    useEffect(() => {
        if (changeEvent && changeEvent.target === "process") {
            const arrPromises = [];

            changeEvent.events.forEach((change) => {
                if (typeof change.id !== "number") {
                    console.warn(`process event id of ${change.processName} is not a number`);
                    return;
                }
                if (typeof change.prop === "string" && change.prop !== "p") {
                    const promise = LCMD.getOldHistoryValue(change.id, change.prop, change._);
                    arrPromises.push(promise);
                }
            });
            Promise.allSettled(arrPromises).then((results) => {
                setOldValues(results.map((result) => (result.status === "fulfilled" ? result.value : null)));
            });
        }
    }, [LCMD, changeEvent]);

    return oldValues;
}

export function useOldValuesWithIDOfProcesses(changeEvent: HistoryMeta): Map<number, number> {
    const LCMD = useLCMD();
    const [oldValues, setOldValues] = useState(new Map());

    if (!Array.isArray(changeEvent.events)) {
        console.warn("changeEvent.events is no array");
        return new Map();
    }

    useEffect(() => {
        if (changeEvent && changeEvent.target === "process") {
            const arrPromises = [];
            const eventTempMap = new Map();
            const aIDs = [];

            changeEvent.events.forEach((event) => {
                const eventTempData = eventTempMap.get(event.id);
                if (eventTempData) {
                    eventTempData.push(event);
                    eventTempMap.set(event.id, eventTempData);
                } else {
                    eventTempMap.set(event.id, [event]);
                }
            });

            if (
                changeEvent.action === HistoryActionName.MULTI_DAYS_EDIT ||
                changeEvent.action === HistoryActionName.MULTI_START_EDIT
            ) {
                Array.from(eventTempMap.keys()).forEach((id) => {
                    const eventTempData = eventTempMap.get(id);
                    const promise = LCMD.getOldHistoryValue(
                        eventTempData[0].id,
                        eventTempData[0].prop,
                        eventTempData[0]._,
                    );
                    arrPromises.push(promise);
                    aIDs.push(id);
                });
            }

            if (changeEvent.action === HistoryActionName.MULTI_START_AND_DAYS_EDIT) {
                Array.from(eventTempMap.keys()).forEach((id) => {
                    const eventTempData = eventTempMap.get(id);
                    const promise = LCMD.getOldHistoryValue(
                        eventTempData[0].id,
                        eventTempData[0].prop,
                        eventTempData[0]._,
                    );
                    const promise2 = LCMD.getOldHistoryValue(
                        eventTempData[1].id,
                        eventTempData[1].prop,
                        eventTempData[1]._,
                    );
                    arrPromises.push(promise);
                    arrPromises.push(promise2);
                    aIDs.push(id);
                    aIDs.push(id);
                });
            }

            Promise.allSettled(arrPromises).then((results) => {
                const tempMap = new Map();
                for (let i = 0; i < aIDs.length; i++) {
                    if (tempMap.has(aIDs[i])) {
                        const tempArrayData = tempMap.get(aIDs[i]);
                        // @ts-ignore
                        tempArrayData.push(results[i].status === "fulfilled" ? results[i].value : null);
                        tempMap.set(aIDs[i], tempArrayData);
                    } else {
                        // @ts-ignore
                        tempMap.set(aIDs[i], results[i].status === "fulfilled" ? [results[i].value] : null);
                    }
                }

                setOldValues(tempMap);
            });
        }
    }, [LCMD, changeEvent]);

    return oldValues;
}
