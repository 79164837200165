import { useState, useEffect, useCallback } from "react";
import { intl } from "lcmd2framework";
import { LcmdModal2 } from "@/components/common/LcmModal2";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { StartEndDateControl } from "../utils/start-end-date-control";
import { useLCMD } from "@/app/LCMContext";
import { LCMDContextProjectDetails } from "@/app/LCMDContextTypes";
import { DataSecurityLink } from "./DataSecurityLink";
import { isValidHttpUrl } from "../Utils";
import { WebAppTelemetryFactory } from "@/app/services/WebAppTelemetry.service";

function extractValues(data) {
    const startDate = data?.startDate ? new Date(data.startDate) : undefined;
    const endDate = data?.endDate ? new Date(data.endDate) : undefined;
    return {
        name: data?.meta?.name || "",
        startDate,
        endDate,
    };
}

export function ProjectPropertiesDialog(props) {
    const LCMD = useLCMD();
    const { data: dataSecurityLink, setDataSecurityLink } = LCMD.useDataSecurityLink();
    const [data, setData] = useState(null as LCMDContextProjectDetails);
    const [name, setName] = useState(null as string);
    const [inputState, setInputState] = useState<string>(dataSecurityLink.link);
    const [startDate, setStartDate] = useState<Date>(undefined);
    const [endDate, setEndDate] = useState<Date>(undefined);

    useEffect(() => {
        LCMD.getProjectDetails((error, data) => {
            if (error) {
                console.warn(error);
                setData(null);
                setName(null);
                setStartDate(undefined);
                setEndDate(undefined);
                setInputState(null);
            } else {
                const initialValues = extractValues(data);
                setName(initialValues.name);
                setStartDate(initialValues.startDate);
                setEndDate(initialValues.endDate);
                setInputState(dataSecurityLink.link);
                setData(data);
            }
        });
    }, [setData, setName, setStartDate, setEndDate, setInputState, dataSecurityLink]);

    const onDateChange = useCallback(
        (start, end) => {
            setStartDate(start || undefined);
            setEndDate(end || undefined);
            if (start && end && start.getTime() < end.getTime()) {
                console.log("TODO");
            }
        },
        [setStartDate, setEndDate],
    );
    const initialValues = extractValues(data);
    const onCancel = () => {
        LCMD.showDialog("dialog.project.properties", false);
    };
    const values = {
        name: name || "",
        link: inputState || "",
        startDate: startDate ? startDate : undefined,
        endDate: endDate ? endDate : undefined,
    };
    const nameValidAndChanged =
        "string" === typeof values.name && values.name.length > 0 && initialValues.name !== (name || "");
    const dateValidAndChanged =
        values.startDate &&
        values.endDate &&
        values.startDate.getTime() < values.endDate.getTime() &&
        (initialValues.startDate !== values.startDate || initialValues.endDate !== values.endDate);

    const linkValidAndChanged = !values.link
        ? true
        : isValidHttpUrl(values.link) && dataSecurityLink.link !== values.link;
    const onOK =
        nameValidAndChanged || dateValidAndChanged || linkValidAndChanged
            ? () => {
                  if (nameValidAndChanged) {
                      WebAppTelemetryFactory.trackEvent("processplan-renamed", {
                          place: "dropdown",
                      });
                  }
                  //name && startDate && endDate && startDate.getTime()<endDate.getTime()
                  if (linkValidAndChanged) setDataSecurityLink(values.link);
                  LCMD.setProjectDetails(
                      {
                          name: nameValidAndChanged ? values.name : undefined,
                          startDate: dateValidAndChanged ? values.startDate : undefined,
                          endDate: dateValidAndChanged ? values.endDate : undefined,
                      },
                      (error, data) => {
                          if (error) {
                              console.warn(error);
                              setData(null);
                              setName(null);
                              setInputState(null);
                          } else {
                              LCMD.showDialog("dialog.project.properties", false);
                          }
                      },
                  );
              }
            : undefined;
    return (
        <LcmdModal2
            open={true}
            className="min-w-[800px]"
            onOpenChange={() => onCancel()}
            header={{ title: intl.get("overview.cmd.props.text") }}
            buttons={[
                <Button key="cancel" variant="secondary" onClick={() => onCancel()}>
                    {intl.get("fw.cancel")}
                </Button>,
                <Button key="ok" onClick={() => onOK()} disabled={!name}>
                    {intl.get("fw.ok")}
                </Button>,
            ]}
        >
            <div className="flex shrink grow flex-col">
                <Label className="my-2 font-semibold">{intl.get("fw.projectName")}</Label>
                <Input
                    value={name || ""}
                    disabled={null === name}
                    onChange={(ev) => {
                        setName(ev.currentTarget.value);
                    }}
                />
            </div>
            <StartEndDateControl defaultStart={startDate} defaultEnd={endDate} onChange={onDateChange} utc={false} />
            <DataSecurityLink onChange={(value) => setInputState(value)} value={inputState} />
        </LcmdModal2>
    );
}
