import { FormEvent, useEffect, useState } from "react";
import { DataTable } from "@/components/ui/data-table";
import { ColumnDef } from "@tanstack/react-table";
import {
    ActiveWeekdaySelector,
    ActiveWeekdays,
} from "../../common/active-weekday-selector/ActiveWeekdaySelector.component";
import { SearchBar } from "../../common/SearchBar/SearchBar";
import { Button } from "@/components/ui/button";
import { CalendarExceptionDialog } from "./CalendarException.dialog";
import { CalendarExceptionDeleteDialog } from "./CalenderExceptionDelete.dialog";
import { intl } from "@/legacy/GlobalHelperReact";
import { CoreExtensions } from "@/extensions/core.extensions";
import { useLCMD } from "@/app/LCMContext";
import { useT3RolesHook } from "../../hooks/useT3Roles.hook";
import { FileButtonPrimary } from "../../common/FileButton/FileButtonPrimary";
import parse from "csv-parse/lib/browser";
import { getCurrentLanguage } from "../../utils/date/locale";
import { CalendarException, CalendarExceptionData } from "./calendarSettings.interfaces";
import { useCalendarStore } from "@/app/store/saveCalendarSettings";
import { Pencil, Plus, Trash2 } from "lucide-react";
import { MoreButtonDropdown, MenuMoreProps } from "@/components/MoreButtonDropdown/MoreButtonDropdown";
import { DEFAULT_WORKING_WEEK } from "@/lib/constants";
import { WebAppTelemetryFactory } from "@/app/services/WebAppTelemetry.service";

type LCMDExtensionVersion = 1.0;

type LCMDExtension = {
    lcmdx: LCMDExtensionVersion;
    id: string;
};

type WeekStartDayExtensionFields = {
    calendar: {
        weekStartDay: number;
    };
};

type CalendarWorkingDaysExtensionFields = {
    calendar: {
        wd?: [boolean, boolean, boolean, boolean, boolean, boolean, boolean];
    };
};

type CalendarExceptionExtensionFields = {
    calendar: {
        exceptions?: CalendarException[];
    };
};

export type WeekStartDayExtension = LCMDExtension & WeekStartDayExtensionFields;
export type CalendarSettingsExtension = LCMDExtension &
    CalendarWorkingDaysExtensionFields &
    CalendarExceptionExtensionFields;

export const CalendarSettingsView = (props) => {
    const weekstartOptions: { key: number; text: string }[] = [
        { key: 1, text: intl.get("calendar.strings.days.mon") },
        { key: 2, text: intl.get("calendar.strings.days.tue") },
        { key: 3, text: intl.get("calendar.strings.days.wed") },
        { key: 4, text: intl.get("calendar.strings.days.thu") },
        { key: 5, text: intl.get("calendar.strings.days.fri") },
        { key: 6, text: intl.get("calendar.strings.days.sat") },
        { key: 0, text: intl.get("calendar.strings.days.sun") },
    ];

    const [calendarExceptions, setCalendarExceptions] = useState<CalendarException[]>([
        {
            name: "Christmas",
            value: ["2021-12-24", "2021-12-26"],
            repeat: "yearly",
        },
        {
            name: "New Years Eve",
            value: "2021-12-31",
            repeat: "yearly",
        },
    ]);
    const LCMD = useLCMD();
    const [searchValue, setSearchValue] = useState("");
    const initalWeekStartKey = weekstartOptions.find((weekstartOption) => weekstartOption.key === 0);
    const [initialCalendarSettings, setInitalCalendarSettings] = useState<{
        firstDayInWeek: { key: number; text: string };
        activeWeekDays: ActiveWeekdays;
        calendarExceptions: CalendarException[];
    }>({
        firstDayInWeek: initalWeekStartKey,
        activeWeekDays: DEFAULT_WORKING_WEEK,
        calendarExceptions: calendarExceptions,
    });
    const [firstDayInWeek, setFirstDayInWeek] = useState(initialCalendarSettings.firstDayInWeek);
    const [activeWeekDays, setActiveWeekDays] = useState<ActiveWeekdays>(initialCalendarSettings.activeWeekDays);
    const [showAddDialog, setShowAddDialog] = useState(false);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [calendarExceptionToEdit, setCalendarExceptionToEdit] = useState<CalendarException>(null);
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [invalidCalendarSettings, setInvalidCalendarSettings] = useState(false);
    const isAllowed = useT3RolesHook();
    const calendarSettingsStore = useCalendarStore();

    useEffect(() => {
        calendarSettingsStore.setCalendarSettings({
            firstDayInWeek: firstDayInWeek.key,
            activeWeekDays,
            calendarExceptions,
        });
    }, [activeWeekDays, firstDayInWeek, calendarExceptions]);

    const calendarColumns: ColumnDef<CalendarException, unknown>[] = [
        {
            accessorKey: "name",
            size: 400,
            header: intl.get("CalendarExceptionMainScreen.Columns.Name"),
        },
        {
            id: "actions",
            meta: {
                cellProps: {
                    style: {
                        display: "flex",
                        justifyContent: "flex-end",
                    },
                },
            },
            cell: ({ row }) => {
                const menuProps: MenuMoreProps[] = [
                    {
                        key: "edit",
                        text: intl.get("CalendarExceptionMainScreen.Columns.Actions.Edit"),
                        icon: <Pencil size={16} color="#666666" />,
                        onClick: () => {
                            setCalendarExceptionToEdit(row.original);
                            setShowEditDialog(true);
                        },
                    },
                    {
                        key: "remove",
                        text: intl.get("CalendarExceptionMainScreen.Columns.Actions.Delete"),
                        icon: <Trash2 size={16} color="#666666" />,
                        onClick: () => {
                            setCalendarExceptionToEdit(row.original);
                            setShowDeleteModal(true);
                        },
                    },
                ];
                return <MoreButtonDropdown menuItems={menuProps} />;
            },
        },
        {
            accessorKey: "value",
            size: 150,
            header: intl.get("CalendarExceptionMainScreen.Columns.StartDate"),
            cell: ({ row }) => {
                const value = row.getValue("value");
                const date = typeof value === "string" ? new Date(value) : new Date(value[0]);
                const timezoneUnawareDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1_000);

                return <span>{formattedDate(timezoneUnawareDate)}</span>;
            },
        },
        {
            accessorKey: "value2",
            size: 150,
            header: intl.get("CalendarExceptionMainScreen.Columns.EndDate"),
            cell: ({ row }) => {
                const value = row.getValue("value");
                const date = typeof value === "string" ? new Date(value) : new Date(value[1]);
                const timezoneUnawareDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1_000);

                return <span>{formattedDate(timezoneUnawareDate)}</span>;
            },
        },
        {
            accessorKey: "repeat",
            size: 150,
            header: intl.get("CalendarExceptionMainScreen.Columns.Repeat"),
        },
    ];

    const formattedDate = (date: Date) =>
        date.toLocaleDateString(getCurrentLanguage(), {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        });

    useEffect(() => {
        LCMD.getProjectExtension(CoreExtensions.CALENDAR_SETTINGS, (error, data: WeekStartDayExtension) => {
            if (!(data?.calendar?.weekStartDay >= 0)) {
                return;
            }

            setInitalCalendarSettings({
                ...initialCalendarSettings,
                firstDayInWeek: weekstartOptions.find(
                    (weekstartOption) => weekstartOption.key === data.calendar.weekStartDay,
                ),
            });
            setFirstDayInWeek(
                weekstartOptions.find((weekstartOption) => weekstartOption.key === data.calendar.weekStartDay),
            );
        });

        loadDefaultCalenderSettings();
    }, []);

    useEffect(() => {
        setInvalidCalendarSettings(activeWeekDays.every((activeWeekday) => !activeWeekday));
    }, [activeWeekDays]);

    const filterCalendarExceptions = (value?: string) => {
        setSearchValue(value);
    };

    const weekdayActiveStatusChanged = (newActiveWeekdays) => {
        WebAppTelemetryFactory.trackEvent("settings-working-days-changed");
        setUnsavedChanges(true);
        setActiveWeekDays(newActiveWeekdays);
    };

    const handleCreateNewCalendarException = (calendarExceptionData: CalendarExceptionData) => {
        WebAppTelemetryFactory.trackEvent("settings-new-calendar-exception-added");
        setShowAddDialog(false);

        const newException: CalendarException =
            transformCalendarExceptionDataToCalendarException(calendarExceptionData);

        const newCalendarExceptions: CalendarException[] = [...calendarExceptions, newException];

        setCalendarExceptions(newCalendarExceptions);
        setUnsavedChanges(true);
    };

    const handleEditCalendarException = (calendarExceptionData: CalendarExceptionData) => {
        setShowEditDialog(false);
        const editedExceptionData: CalendarException =
            transformCalendarExceptionDataToCalendarException(calendarExceptionData);

        Object.keys(calendarExceptionToEdit).forEach((propertyName) => {
            calendarExceptionToEdit[propertyName] = editedExceptionData[propertyName];
        });

        setCalendarExceptions([...calendarExceptions]);
        setUnsavedChanges(true);
    };

    function addCalendarException() {
        setShowAddDialog(true);
    }

    /**
     *  @todo: check if this method can be replaced with the new hook
     *  @see useCalendarSettings
     */
    function loadDefaultCalenderSettings() {
        LCMD.getProjectExtension(CoreExtensions.CALENDAR_DEFAULT_SETTINGS, (error, data: CalendarSettingsExtension) => {
            setInitalCalendarSettings({
                ...initialCalendarSettings,
                activeWeekDays: data?.calendar?.wd || DEFAULT_WORKING_WEEK,
                calendarExceptions: data?.calendar?.exceptions || [],
            });
            setActiveWeekDays(data?.calendar?.wd || DEFAULT_WORKING_WEEK);
            setCalendarExceptions(data?.calendar.exceptions || []);
            setUnsavedChanges(false);
        });
    }

    function transformGermanDate(germanDateString: string): { month: number; day: number; year: number } {
        const germanArray = germanDateString.split(".");
        return { day: +germanArray[0], month: +germanArray[1], year: +germanArray[2] };
    }

    function importCalendar(event: FormEvent<HTMLButtonElement>) {
        WebAppTelemetryFactory.trackEvent("settings-calendar-imported");
        const files = (event.target as HTMLInputElement).files;

        if (files[0].type !== "text/csv") {
            console.warn("File is not csv");
            alert("File is not csv");
            return;
        }
        files
            .item(0)
            .text()
            .then((data) => {
                parse(data, { delimiter: ";" }, function (err, records: []) {
                    const exceptions: CalendarException[] = [];
                    for (let i = 1; i < records.length; i++) {
                        const record = records[i];
                        if (record[0] && record[1] && record[2] && record[3]) {
                            const sDate = transformGermanDate(record[1]);
                            const eDate = transformGermanDate(record[2]);
                            const calData: CalendarExceptionData = {
                                name: record[0],
                                startDate: new Date(Date.UTC(sDate.year, sDate.month - 1, sDate.day)),
                                endDate: new Date(Date.UTC(eDate.year, eDate.month - 1, eDate.day)),
                                repeat: record[3] as any,
                            };

                            exceptions.push(transformCalendarExceptionDataToCalendarException(calData));
                        } else {
                            console.warn(`CSV record ${i} is not valid`, record);
                        }
                    }

                    if (exceptions.length > 0) {
                        setCalendarExceptions(exceptions);
                        setUnsavedChanges(true);
                    }
                });
            });
    }

    function DateToFormattedString(d: Date) {
        const yyyy = d.getUTCFullYear().toString();
        const mm = (d.getUTCMonth() + 1).toString(); // getMonth() is zero-based
        const dd = d.getUTCDate().toString();

        return yyyy + "-" + (mm[1] ? mm : "0" + mm[0]) + "-" + (dd[1] ? dd : "0" + dd[0]);
    }

    function transformCalendarExceptionDataToCalendarException({
        name,
        startDate,
        endDate,
        repeat,
    }: CalendarExceptionData): CalendarException {
        return {
            name,
            value:
                startDate.toLocaleDateString() === endDate.toLocaleDateString()
                    ? DateToFormattedString(startDate)
                    : [DateToFormattedString(startDate), DateToFormattedString(endDate)],
            repeat,
        };
    }

    function handleCloseAddModal() {
        setShowAddDialog(false);
        setCalendarExceptionToEdit(null);
    }

    function handleCloseEditModal() {
        setShowEditDialog(false);
        setCalendarExceptionToEdit(null);
    }

    return (
        <div className="px-5 py-0">
            <div className="border-b border-solid border-r-gray-200 px-0 py-2.5">
                <div className="flex flex-col gap-4">
                    <div>
                        <p className="text-base font-semibold">
                            {intl.get("CalendarExceptionMainScreen.WorkingDaysHeaderText")}
                        </p>
                        <p className="text-sm text-gray-600">
                            {intl.get("CalendarExceptionMainScreen.WorkingDaysDescriptionText")}
                        </p>
                    </div>
                    <ActiveWeekdaySelector
                        activeWeekdays={activeWeekDays as ActiveWeekdays}
                        onActiveWeekdaysChange={weekdayActiveStatusChanged}
                        disabled={!isAllowed}
                    />
                </div>
            </div>
            <div className="px-0 py-2.5">
                <div className="flex justify-between">
                    <p className="text-base font-semibold">
                        {intl.get("CalendarExceptionMainScreen.ExceptionsHeaderText")}
                    </p>
                    <div className="flex gap-4">
                        <FileButtonPrimary
                            accept={".csv"}
                            disabled={!isAllowed}
                            text={intl.get("CalendarExceptionMainScreen.Import")}
                            onChange={importCalendar}
                        />
                        <Button disabled={!isAllowed} onClick={addCalendarException}>
                            <Plus size="16" className="mr-2" />
                            {intl.get("CalendarExceptionMainScreen.NewButtonText")}
                        </Button>
                    </div>
                </div>
                <SearchBar
                    value={searchValue}
                    onChange={filterCalendarExceptions}
                    placeholder={intl.get("CalendarExceptionMainScreen.SearchTextFieldText")}
                    className="mb-1 w-60"
                />
                <DataTable
                    columns={calendarColumns}
                    data={
                        !searchValue
                            ? [...calendarExceptions].sort((a, b) => (a.value > b.value ? 1 : -1))
                            : [...calendarExceptions]
                                  .sort((a, b) => (a.value > b.value ? 1 : -1))
                                  .filter(
                                      (calendarException) =>
                                          calendarException.name.toLowerCase().indexOf(searchValue?.toLowerCase()) >= 0,
                                  )
                    }
                />
            </div>
            {showAddDialog && (
                <CalendarExceptionDialog onDismiss={handleCloseAddModal} onAccept={handleCreateNewCalendarException} />
            )}
            {showEditDialog && (
                <CalendarExceptionDialog
                    onDismiss={handleCloseEditModal}
                    onAccept={handleEditCalendarException}
                    calendarException={calendarExceptionToEdit}
                />
            )}
            {showDeleteModal && (
                <CalendarExceptionDeleteDialog
                    onDismiss={() => {
                        setShowDeleteModal(false);
                    }}
                    onAccept={() => {
                        const exceptionsAfterDelete = calendarExceptions.filter(
                            (calendarException, index) => calendarException !== calendarExceptionToEdit,
                        );
                        setCalendarExceptions(exceptionsAfterDelete);
                        setUnsavedChanges(true);
                        setCalendarExceptionToEdit(null);
                        setShowDeleteModal(false);
                    }}
                    exceptionTitle={calendarExceptionToEdit.name}
                />
            )}
        </div>
    );
};
