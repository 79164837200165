import * as React from "react";

import {
    Stack,
    ICommandBarItemProps,
    MessageBar,
    MessageBarType,
    MessageBarButton,
    mergeStyleSets,
    Button,
} from "@fluentui/react";

import { Layout } from "./layout";
import { Row } from "./layout/row";

import { FilterBar } from "./filter-bar";

import { ActualPlanningBaseline } from "./widgets/actual-planning-baseline";
import { ProgressPlanning } from "./widgets/progress-planning";
import { StatusTracking } from "./widgets/status-tracking";
import { ScheduleStability } from "./widgets/schedule-stability";
import { classNames as headerButtonClassNames } from "./buttons/common";
import { BaselinesDialog } from "../baselines-dialog-v2";
import { useLCMD } from "../../app/LCMContext";
import { intl } from "@/legacy/GlobalHelperReact";
import { DailyNews } from "../common/dailyNews";
import { AttachedReasonCodesWidget } from "./widgets/attached-reason-codes/AttachedReasonCodesWidget";
import { usePowerBiDashboard } from "../hooks/usePowerBiDashboard.hook";
import { PowerBIEmbed } from "powerbi-client-react";
import { useCurrentBaseline } from "@/components/hooks/useBaseline.hook";
import { useEffect } from "react";
import { FilterButton } from "@/components/common/FilterButton/FilterButton";
import { EmptyView } from "@/components/common/EmptyView";
import noDashboard from "@/assets/img/emptyState/noDashboard.png";
import { useAllProcessIds } from "@/components/hooks/useAllProcessIds";

const _timescaleOptions = [
    { key: "none", text: "Everything" },
    { key: "lastMonth", text: "Last 30 days" },
    { key: "lastWeek", text: "Last 7 days" },
    { key: "today", text: "Today" },
    { key: "nextMonth", text: "Next 30 days" },
    { key: "nextWeek", text: "Next 7 days" },
    { key: "custom", text: "..." /*, isSelected: true*/ },
];
const specifiedTrades = [
    { name: "Bodenleger", color: "#00B050" },
    { name: "Elektro", color: "#00B0F0" },
    { name: "Wallpaper", color: "#4800f0" },
];
const specifiedTaktZones = [
    { name: "Building A", count: 7 },
    { name: "Building B", count: 3 },
    { name: "Building C", count: 3 },
    { name: "Building E", count: 189 },
];

const messages = {
    timescaleLabel: "Timescale",
    taktZonesLabel: "Takt zones",
    tradesLabel: "Trades",
    specifiedAllLabel: "All",
};

const additionActionsProps: ICommandBarItemProps[] = [
    {
        key: "add",
        text: "Add",
        iconProps: { iconName: "Add", className: headerButtonClassNames.iconColor },
        onClick: () => console.log("Click on add button"),
    },
    {
        key: "Open in new window",
        text: "Open in new window",
        iconProps: {
            iconName: "OpenInNewWindow",
            className: headerButtonClassNames.iconColor,
        },
        onClick: () => console.warn("TODO: should Implement"),
    },
];

const onTimescaleChange = (...args) => console.log(...args);
const onDownloadClick = (...args) => console.log(...args);

const tokensProps = { maxWidth: "100%" };

export type DashboardCtx = {
    onOutdatedChartData: (boolean) => void;
};

export type DashboardCtxPropI = {
    dashboardCtx: DashboardCtx;
};

function Dashboard({ error, filter, grid }) {
    const LCMD = useLCMD();
    const { data: allProcesses, isLoading: isAllProcessIdsLoading, isError: isAllProcessError } = useAllProcessIds();
    let dashboardStyles;
    const [showBaseline, setShowBaseline] = React.useState(false);
    const [isOutdated, setIsOutdated] = React.useState(false);
    const { isLoading: isDashboardConfigLoading, isError, data: dashboardConfig } = usePowerBiDashboard();
    const { data: currentBaseline } = useCurrentBaseline();

    const timescaleOptions = React.useMemo(() => {
        if (Array.isArray(grid?.grid) && grid.grid.length > 0) {
            const grid0 = new Date(grid.grid[0].d0 * 86400 * 1000);
            const grid1 = new Date(grid.grid[grid.grid.length - 1].d1 * 86400 * 1000);
            const ret = _timescaleOptions.map((_item) => {
                const item: any = { ..._item };
                if ("custom" === item.key) {
                    item.text = JSON.stringify([grid0.toISOString(), grid1.toUTCString()]);
                    item.isSelected = true;
                }
                return item;
            });
            return ret;
        } else {
            return null;
        }
    }, [grid]);
    const dashboardCtx: DashboardCtx = React.useMemo(
        () => ({
            onOutdatedChartData: setIsOutdated,
        }),
        [setIsOutdated],
    );

    useEffect(() => {
        if (currentBaseline && currentBaseline.activebaseline) {
            LCMD.setBaselineId(currentBaseline.activebaseline);
        }
    }, [currentBaseline]);

    const actions = React.useMemo(
        () => (
            <MessageBarButton
                onClick={() => {
                    LCMD.worker.postMessage([
                        "stats",
                        "update",
                        {
                            cb: LCMD.worker.registerCallback(() => {
                                setIsOutdated(false);
                            }),
                        },
                    ]);
                }}
            >
                {intl.get("dashboard.updateButton")}
            </MessageBarButton>
        ),
        [LCMD, setIsOutdated],
    );

    if (!isDashboardConfigLoading && !isError && dashboardConfig.isPowerBIEnabled) {
        dashboardStyles = mergeStyleSets({
            reportClass: {
                height: "95vh",
            },
        });
    }

    if (!error && !isError && !isAllProcessError && (isDashboardConfigLoading || isAllProcessIdsLoading)) {
        return (
            <div
                style={{
                    display: "flex",
                    width: "100vw",
                    height: "100vh",
                    justifyContent: "center",
                    flexDirection: "column",
                    textAlign: "center",
                    backgroundColor: "white",
                }}
            >
                <div>
                    <div>
                        <img style={{ marginLeft: "auto", marginRight: "auto" }} src="/img/lcmd_logo.svg" />
                    </div>
                    <div style={{ width: "662.9999999999999px", marginLeft: "auto", marginRight: "auto" }}>
                        <span>Loading...</span>
                    </div>
                </div>
            </div>
        );
    }
    const isDashboardEmpty = allProcesses && allProcesses.length === 0;

    return error || isAllProcessError ? null : (
        <>
            {isDashboardEmpty ? (
                <EmptyView
                    imgSrc={noDashboard}
                    header={intl.get("dashboard.noDataYet")}
                    text={intl.getHTML("dashboard.noDashboardDescription")}
                    buttonText={intl.get("dashboard.buttonText")}
                    onButtonClicked={() => LCMD.worker.dispatchMessage(["framework", "toggle", "project"])}
                />
            ) : (
                <>
                    <DailyNews />
                    <Stack
                        tokens={tokensProps}
                        styles={{
                            root: {
                                width: "100%",
                            },
                        }}
                    >
                        {dashboardConfig.isPowerBIEnabled ? (
                            <div>
                                <PowerBIEmbed
                                    embedConfig={dashboardConfig.reportConfig}
                                    cssClassName={dashboardStyles.reportClass}
                                />
                            </div>
                        ) : (
                            <>
                                <FilterBar
                                    specifiedAllLabel={messages.specifiedAllLabel}
                                    timescaleLabel={messages.timescaleLabel}
                                    timescaleOptions={timescaleOptions}
                                    tradesLabel={messages.tradesLabel}
                                    specifiedTrades={specifiedTrades}
                                    taktZonesLabel={messages.taktZonesLabel}
                                    specifiedTaktZones={specifiedTaktZones}
                                    onChangeTimescale={onTimescaleChange}
                                    onDownloadClick={onDownloadClick}
                                    additionActions={additionActionsProps}
                                    filter={
                                        <FilterButton checked={filter} webtrackingPrefix="dashboard"></FilterButton>
                                    }
                                />
                                {isOutdated ? (
                                    <MessageBar
                                        messageBarType={MessageBarType.warning}
                                        isMultiline={false}
                                        actions={actions}
                                    >
                                        {intl.get("dashboard.newChangesText")}
                                    </MessageBar>
                                ) : null}
                                <Layout>
                                    <Row>
                                        <ActualPlanningBaseline
                                            dashboardCtx={dashboardCtx}
                                            onShowBaseline={setShowBaseline}
                                        />
                                        <ProgressPlanning dashboardCtx={dashboardCtx} />
                                    </Row>
                                    <Row>
                                        <ScheduleStability dashboardCtx={dashboardCtx} />
                                        <StatusTracking dashboardCtx={dashboardCtx} />
                                        <AttachedReasonCodesWidget dashBoardCtx={dashboardCtx} />
                                    </Row>
                                    {/*
        <Row>
          <CapacityCurve />
        </Row>
        <Row>
          <Milestones />
        </Row>
        <Row>
          <ExecutionByArea />
        </Row>
        <Row>
          <ExecutionByTrade />
        </Row>
        */}
                                </Layout>
                            </>
                        )}
                    </Stack>
                    {showBaseline ? <BaselinesDialog onShow={setShowBaseline} /> : null}
                </>
            )}
        </>
    );
}
export { Dashboard };
