import { useState } from "react";
import { LCMDHookResult } from "../../app/types/LCMDHookResult";
import { useLCMD } from "../../app/LCMContext";
import { useSetActiveBaseline } from "@/app/store/baselineStore";
import { LCMDContextBaselineResponse } from "@/app/LCMDContextTypes";

export function useCurrentBaseline() {
    const LCMD = useLCMD();
    const [updateRef, setUpdateRef] = useState(Symbol());
    const [hookState, setHookState] = useState<
        LCMDHookResult<{
            activebaseline: number;
            name: string;
            date: number;
        }>
    >({
        isLoading: true,
        isError: false,
        error: undefined,
        data: undefined,
    });
    const setActiveBaseline = useSetActiveBaseline();

    const setBaseline = (activebaseline: number) => {
        if (!activebaseline) {
            throw new Error("Please provide baseline to store in Settings");
        }

        const newSettings = {
            ["global"]: {
                activebaseline: activebaseline,
            },
        };
        LCMD.setSettingsProps(newSettings, () => {
            LCMD.setBaselineId(activebaseline);
            setUpdateRef(Symbol());
        });
    };

    const parseBaselineName = (nameListRawData: string, activeBaselineId: number) => {
        if (!nameListRawData || !activeBaselineId) return "";
        return JSON.parse(nameListRawData).find((baseline: any) => baseline.id === activeBaselineId)?.name;
    };

    LCMD.useSettingsEffect(
        ["activebaseline", "baseline"],
        ({ global }: { global: { activebaseline: number; baseline: string } }) => {
            LCMD.getBaselineIds((error: Error, resp: LCMDContextBaselineResponse) => {
                if (error) {
                    console.log(error);
                    setHookState({
                        isLoading: false,
                        isError: true,
                        error: error,
                        data: undefined,
                    });
                } else {
                    const baselineItem = resp.data.find(
                        (baseline: { i: number; d: number; u: string }) => baseline.i === global?.activebaseline,
                    );
                    const id = global?.activebaseline;
                    const name = parseBaselineName(global?.baseline, global?.activebaseline);
                    const date = baselineItem?.d;

                    // Set active baseline in store if id exists
                    if (id) {
                        setActiveBaseline({
                            id: id,
                            name: name,
                            date: date,
                        });
                    }

                    // Set active baseline in hook state
                    setHookState({
                        ...hookState,
                        isLoading: false,
                        data: {
                            activebaseline: global?.activebaseline,
                            name: name,
                            date: date,
                        },
                    });
                }
            });
        },
        [updateRef],
    );

    return { ...hookState, setBaseline } as const;
}
