import { LcmdModal2, ModalSizeTypes } from "@/components/common/LcmModal2";
import { Alert } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import { intl } from "@/legacy/GlobalHelperReact";
import { XCircle, LoaderCircle } from "lucide-react";
import { ReactNode } from "react";
import { FileUploadPopupState } from "../../types/initialState";
import { cn } from "@/lib/utils";

type FileUploadWrapperProps = {
    children: ReactNode;
    view: FileUploadPopupState["view"];
    viewOptions?: {
        files?: FileList | File[];
        clone?: any;
    };
    onDone: (data: any) => void;
    onNext: () => void | Promise<void>;
    onHide: () => void;
};

export const FileUploadWrapper = ({ children, view, viewOptions, onDone, onNext, onHide }: FileUploadWrapperProps) => {
    const onDismiss = view?.message ? onNext : onHide;
    const disabledNext = !view?.message && (!view || view?.busy || "start" === view?.name);
    const nextButtonText = () => {
        let textId = "legacyProjectDialog.button.next";
        if (view?.name === "details") {
            if (view?.canSkip === false) textId = "legacyProjectDialog.button.create";
            else if (view?.status?.clone) textId = "legacyProjectDialog.button.clone";
            else textId = "legacyProjectDialog.button.upload";
        }
        if (view?.name === "primavera") {
            textId = "legacyProjectDialog.button.upload";
        }
        return intl.get(textId);
    };
    const size = viewOptions?.clone ? ModalSizeTypes.s : ModalSizeTypes.m;

    return (
        <LcmdModal2
            size={size}
            open={!!onDone}
            onOpenChange={onDismiss}
            className="pb-1"
            header={{ title: view?.title || "" }}
        >
            {view?.error && (
                <div className="relative mb-2 flex items-center">
                    <XCircle color="#ef4444" className="absolute inset-y-0 left-3 my-auto h-4 w-4 text-gray-500" />
                    <Alert variant="destructive" className="py-1 pl-8 pr-4 text-sm">
                        {view.error}
                    </Alert>
                </div>
            )}
            {view?.message ? <div>{view.message}</div> : view ? children : null}
            <div className="flex flex-none items-end justify-end gap-2 pt-0">
                <Button
                    variant="outline"
                    disabled={!view?.message && (!view || view.busy)}
                    onClick={view?.message ? onNext : onHide}
                >
                    {intl.get("legacyProjectDialog.cancel")}
                </Button>
                <Button
                    className={"start" === view?.name && "hidden"}
                    disabled={disabledNext}
                    onClick={onNext}
                    data-userpilot-id="fileUploadPopup-step1ContinueButton"
                >
                    <LoaderCircle className={cn(disabledNext ? "mr-3 animate-spin" : "hidden")} size={16} />
                    {nextButtonText()}
                </Button>
            </div>
        </LcmdModal2>
    );
};
