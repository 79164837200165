import { DigitalPlanningBoardSelectionEvent, intl } from "lcmd2framework";
import { Comment } from "./Comment";
import { MentionsComponent } from "@/components/common/MentionsComponent";
import { useContext, useLayoutEffect, useState } from "react";
import { SidebarContext } from "@/app/components/SidebarHost";
import { useLCMD } from "@/app/LCMContext";
import { Notification, NotificationTarget } from "@/core/Notification/NotificationService";
import { useProcessComments } from "@/app/hooks/useProcessComments";
import { Skeleton } from "@/components/ui/skeleton";
export interface ICommentHandlers {
    deleteComment: (commentId: string) => Promise<void>;
    editComment: (commentId: string, newValue: string) => Promise<void>;
}

export function CommentsView() {
    const LCMD = useLCMD();
    const propsContext = useContext(SidebarContext);

    const [commentDisabled, setCommentDisabled] = useState(true);
    const [currentUserId, setCurrentUserId] = useState(null);
    const [selectedProcesses, setSelectedProcesses] = useState<DigitalPlanningBoardSelectionEvent>(null);

    const {
        isLoading,
        data: comments,
        deleteComment,
        createComment,
        editComment,
        error,
        isError,
    } = useProcessComments(selectedProcesses?.pid);

    async function handleSendComment(event: any, newValue: string) {
        if (newValue && Array.isArray(selectedProcesses?.pid) && 1 === selectedProcesses.pid.length) {
            setCommentDisabled(true);
            const notification = await createNotification(newValue);
            await createComment({
                notificationWrapperData: notification,
                processId: selectedProcesses.pid[0],
                text: newValue,
            });
        }
    }

    async function handleDeleteComment(commentId: string) {
        if (Array.isArray(selectedProcesses?.pid) && 1 === selectedProcesses.pid.length) {
            await deleteComment({
                processId: selectedProcesses.pid[0],
                commentId,
            });
        }
    }

    async function handleEditComment(commentId: string, newValue: string) {
        if (Array.isArray(selectedProcesses?.pid) && 1 === selectedProcesses.pid.length) {
            const notification = await createNotification(newValue);

            await editComment({
                notificationWrapperData: notification,
                processId: selectedProcesses.pid[0],
                text: newValue,
                commentId,
            });
        }
    }

    const authUserIdPromise: Promise<string> = new Promise((resolve, reject) => {
        LCMD.getAuthUser((error, user) => {
            if (error) {
                reject(error);
            } else {
                resolve(user.sub);
            }
        });
    });

    const commentHandlers: ICommentHandlers = {
        deleteComment: handleDeleteComment,
        editComment: handleEditComment,
    };

    propsContext.sidebarCtx.useSelectionEffect(
        (ev: DigitalPlanningBoardSelectionEvent) => {
            const currentValues = selectedProcesses ? [...selectedProcesses.pid] : [];
            const newValues = [...ev.pid];
            if (currentValues.sort().toString() !== newValues.sort().toString()) {
                setSelectedProcesses(ev);
            }
            setCommentDisabled(ev?.pid?.length !== 1);
        },
        [setSelectedProcesses, setCommentDisabled, commentDisabled, selectedProcesses],
    );

    async function createNotification(
        comment: string,
    ): Promise<{ fromUserId: string; users: string[]; notification: Notification }> {
        const getProcessNamePromise: Promise<string> = new Promise((resolve, reject) => {
            LCMD.getProcessDetails(selectedProcesses.pid[0], (error, data) => {
                if (error) {
                    reject(error);
                } else {
                    resolve(data?.name?.value);
                }
            });
        });

        const getProjectPromise = (): Promise<{ name: string; id: string }> => {
            return new Promise((resolve, reject) => {
                LCMD.getProjectDetails((error, data) => {
                    if (error) {
                        reject(error);
                    } else {
                        resolve({
                            name: data.meta?.name,
                            id: data.pid,
                        });
                    }
                });
            });
        };

        const fromUserId = await authUserIdPromise;
        const users = extractIdsFromCommment(comment);
        const projectData = await getProjectPromise();
        const projectId = projectData.id;

        const notification: Notification = {
            type: "info",
            target: [NotificationTarget.Email],
            meta: {
                processid: selectedProcesses.pid[0].toString(),
                processname: await getProcessNamePromise,
                projectname: projectData.name,
                confirmationUrl: projectId,
            },
        };
        return {
            fromUserId,
            users,
            notification,
        };
    }

    function extractIdsFromCommment(text: string) {
        const regex = /\$\{"([^"]+)"\}/g;
        const ids = [];
        let match;

        while ((match = regex.exec(text)) !== null) {
            ids.push(match[1]);
        }

        return ids;
    }

    useLayoutEffect(() => {
        authUserIdPromise
            .then((userId) => {
                setCurrentUserId(userId);
            })
            .catch((error) => {
                console.error("Error retrieving user ID:", error);
            });
    }, []);

    return (
        <div className="h-full overflow-y-hidden">
            <div
                className="flex flex-col"
                style={{
                    height: "calc(100% - 94px)",
                }}
            >
                <div className="border-b border-solid border-gray-300">
                    <div className="px-5 pb-2 pt-0">
                        <p className="text-lg font-semibold not-italic leading-6 tracking-tight">
                            {intl.get("CommentsView.ActionsLabelText")}
                        </p>
                    </div>
                </div>
                <div
                    className=" bg-slate-100 p-4"
                    style={{
                        height: "calc(100% - 33px)",
                    }}
                >
                    {!isLoading
                        ? !!comments?.size && (
                              <div className="flex h-auto max-h-full flex-col-reverse gap-4 overflow-y-auto rounded-2xl bg-white p-3">
                                  {Array.from(comments).map(([id, comment]) => (
                                      <Comment
                                          key={"comment-" + id}
                                          commentId={id}
                                          currentUserId={currentUserId}
                                          comment={comment}
                                          commentHandlers={commentHandlers}
                                      />
                                  ))}
                              </div>
                          )
                        : Array.from({ length: 3 }, (_, index) => (
                              <Skeleton key={"index-" + index} className={"h-24 w-full bg-slate-400"}></Skeleton>
                          ))}
                </div>
            </div>
            <div className="min-h-10 w-full rounded-b-lg border-t border-solid border-gray-300 bg-slate-100 px-4 py-3">
                <MentionsComponent
                    handleSendComment={handleSendComment}
                    mentionStyles={{ backgroundColor: "#ede9fe" }}
                    styles={{
                        "&multiLine": {
                            input: {
                                padding: 9,
                                overflow: "auto",
                                height: 70,
                            },
                            highlighter: {
                                padding: 9,
                                boxSizing: "border-box",
                                overflow: "hidden",
                                height: 70,
                            },
                        },
                        background: "white",
                    }}
                />
            </div>
        </div>
    );
}
