import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { intl } from "@/legacy/GlobalHelperReact";
import { cn } from "@/lib/utils";
import { forwardRef, useState } from "react";
import { FileUploadPopupState } from "../../types/initialState";
import { WeekPicker } from "@/components/common/WeekPicker/WeekPicker";
import { DEFAULT_WORKING_WEEK, workingWeekType } from "@/lib/constants";
import { DatePicker } from "@/components/DatePicker/DatePicker";

type DetailsViewProps = {
    data: FileUploadPopupState["data"];
    viewOptions?: {
        files?: FileList | File[];
        clone?: boolean;
    };
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => boolean;
    onDatesChange?: (start: Date, end: Date) => void;
    onWorkingWeekChange?: (week: boolean[]) => void;
};

export const DetailsView = forwardRef<HTMLFormElement, DetailsViewProps>(
    ({ data, onSubmit, onDatesChange, onWorkingWeekChange }, ref) => {
        const [workingWeek, setWorkingWeek] = useState<workingWeekType>(DEFAULT_WORKING_WEEK);
        const [fromDate, setFromDate] = useState(new Date());
        const [toDate, setToDate] = useState(new Date(new Date().setMonth(new Date().getMonth() + 1)));
        const handleOnDatesChange = (from, to) => {
            setFromDate(from);
            setToDate(to);
            onDatesChange(from, to);
        };
        const handleWorkingWeekChange = (days: workingWeekType) => {
            setWorkingWeek(days);
            onWorkingWeekChange(days);
        };

        return (
            <div className="w-full">
                <div className={cn("pb-2.5", !data?.upload_result?.file && "hidden")}>
                    {data?.clone ? (
                        <span>{intl.getHTML("legacyProjectDialog.details.successClone")}</span>
                    ) : (
                        <span>
                            {intl.getHTML("legacyProjectDialog.details.successUpload", {
                                file: data?.upload_result?.file?.fileName || "?",
                            })}
                        </span>
                    )}
                </div>
                <form ref={ref} onSubmit={onSubmit}>
                    <Label className="text-sm font-semibold" htmlFor="formProjectName">
                        {intl.get("legacyProjectDialog.details.formProjectName.label")}
                    </Label>
                    <Input
                        id="formProjectName"
                        placeholder={intl.get("legacyProjectDialog.details.formProjectName.placeholder")}
                        defaultValue={
                            data?.upload_result?.props?.projectTitle || data?.upload_result?.file?.fileName || ""
                        }
                        className="mb-8 mt-3"
                        data-userpilot-id="fileUploadPopup-projectName"
                    />
                    <div className={cn((data?.clone || data?.upload_result) && "hidden")}>
                        <Label className="text-sm font-semibold">
                            {intl.get("legacyProjectDialog.details.formStartEnd.label")}
                        </Label>
                        <DatePicker
                            className="mt-3"
                            date={{ from: fromDate, to: toDate }}
                            onChange={({ from, to }) => handleOnDatesChange(from, to)}
                        ></DatePicker>
                    </div>
                    <div className={cn("mt-8 rounded-2xl bg-slate-100 p-4", data?.clone && "hidden")}>
                        <Label className="text-2xl font-semibold">
                            {intl.get("legacyProjectDialog.details.formDefaultCalendar.label")}
                        </Label>
                        <p className="mb-6 text-sm text-slate-500">
                            {intl.get("legacyProjectDialog.details.formDefaultCalendar.numberOfWorkdays", {
                                days: workingWeek.filter((day) => day).length,
                            })}
                        </p>
                        <WeekPicker onChange={(days) => handleWorkingWeekChange(days)} value={[...workingWeek]} />
                    </div>
                </form>
            </div>
        );
    },
);

DetailsView.displayName = "DetailsView";
