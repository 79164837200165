import { intl } from "lcmd2framework";
import { useEffect, useMemo, useState } from "react";
import { DefaultReasonCodeList } from "./DefaultReasonCodeList";
import { ReasonCodeItem } from "./reason-codes";
import { LcmdModal2, ModalSizeTypes } from "@/components/common/LcmModal2";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { WebAppTelemetryFactory } from "@/app/services/WebAppTelemetry.service";

type Props = {
    reasonCodeToEdit?: any;
    onCancel: () => void;
    onClose: (itemsToSave: ReasonCodeItem[] | ReasonCodeItem) => void;
    showDialog: boolean;
};
export function ReasonCodesModal({ reasonCodeToEdit, onCancel, onClose, showDialog }: Props) {
    const [checkedEntries, setCheckedEntries] = useState([]);
    const [currentReasonCodeName, setCurrentReasonCodeName] = useState(
        reasonCodeToEdit?.name ? reasonCodeToEdit.name : "",
    );
    const [currentCase, setCurrentCase] = useState<"create" | "edit">(reasonCodeToEdit ? "edit" : "create");

    const isSaveButtonDisabled = useMemo(
        () => checkedEntries.length == 0 && currentReasonCodeName == "",
        [checkedEntries, currentReasonCodeName],
    );

    useEffect(() => {
        setCurrentCase(reasonCodeToEdit ? "edit" : "create");
        setCurrentReasonCodeName(reasonCodeToEdit?.name ? reasonCodeToEdit.name : "");
    }, [reasonCodeToEdit]);

    const cleanUpModal = () => {
        setCurrentReasonCodeName("");
        setCheckedEntries([]);
    };

    const saveReasonCodes = () => {
        let itemsToSave;

        switch (currentCase) {
            case "create": {
                WebAppTelemetryFactory.trackEvent("settings-variance-reason-added");
                itemsToSave = [...checkedEntries];
                if (currentReasonCodeName != "") {
                    itemsToSave.push({ name: currentReasonCodeName });
                }
                break;
            }
            case "edit": {
                itemsToSave = {};
                itemsToSave.name = currentReasonCodeName;
                if (reasonCodeToEdit?.id) {
                    itemsToSave.id = reasonCodeToEdit.id;
                }
                break;
            }
        }
        onClose(itemsToSave);
        cleanUpModal();
    };

    function onChangeDefaultSettingsList(selectedCode: string, isChecked) {
        const selectedEntries = [...checkedEntries];
        if (!isChecked) {
            selectedEntries.some((entry, index) => {
                if (entry.name == selectedCode) {
                    selectedEntries.splice(index, 1); // 2nd parameter means remove one item only
                    return true;
                }
            });
        } else {
            selectedEntries.push({ name: selectedCode });
        }
        setCheckedEntries(selectedEntries);
    }

    const buttons = [
        <Button
            key="cancel"
            variant="outline"
            onClick={() => {
                onCancel();
                cleanUpModal();
            }}
        >
            {intl.get("StabilityCriteria.SettingsModal.CancelButtonText")}
        </Button>,
        <Button key="saveReasonCodes" onClick={saveReasonCodes} disabled={isSaveButtonDisabled}>
            {intl.get("StabilityCriteria.SettingsModal.ApplyButtonText")}
        </Button>,
    ];

    return (
        <div>
            <LcmdModal2
                size={ModalSizeTypes.m}
                open={showDialog}
                onOpenChange={onCancel}
                className="max-h-max overflow-hidden"
                buttons={buttons}
                header={{
                    title:
                        currentCase == "create"
                            ? intl.get("ReasonCodesSettings.modal.addTitle")
                            : intl.get("ReasonCodesSettings.modal.editTitle"),
                    subtitle: intl.get("ReasonCodesSettings.modal.subtitle"),
                    height: 80,
                }}
            >
                <div className="relative flex h-full w-full flex-col gap-2">
                    <Label>{intl.get("ReasonCodesSettings.modal.nameLabel")}</Label>
                    <Input
                        value={currentReasonCodeName}
                        placeholder={intl.get("ReasonCodesSettings.modal.namePlaceholder")}
                        onChange={(e) => setCurrentReasonCodeName(e.target.value)}
                    />
                    {currentCase == "create" && <DefaultReasonCodeList onChange={onChangeDefaultSettingsList} />}
                </div>
            </LcmdModal2>
        </div>
    );
}
