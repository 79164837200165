import { useLCMD } from "@/app/LCMContext";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Separator } from "@fluentui/react";
import { intl } from "@/legacy/GlobalHelperReact";
import { LegacyStartEndDateControl, TextFieldWithCommit, UploadAttachmentControl } from "@/legacy/GlobalHelperFluentUI";
import { AttachmentThumbnailControl } from "./AttachmentThumbnailControl";
import { ExtensionFieldList } from "./ExtensionFieldList";
import { TradeField } from "./TradeField";
import { StatusButton } from "./StatusButtonV1";
import { StackedDialogView } from "../../legacy/components/StackedDialogView/StackedDialogView";
import { Button } from "@/components/ui/button";
import { WebAppTelemetryFactory } from "@/app/services/WebAppTelemetry.service";

const flexGrowStyle = {
    wrapper: {
        flexGrow: 1,
        flexShrink: 0,
    },
};

const workforceFieldStyles = { fieldGroup: { width: 100 } };

type CardDialogViewProps = {
    dialogCtx;
    onCancel;
    initialCard: { tid: number; aid: number; i: number; isWhiteboard: boolean };
    onChangeTrade;
    webtrackingPrefix?: string;
};

export function CardDialogView(props: CardDialogViewProps) {
    const LCMD = useLCMD();
    const [ts, setTS] = useState(-1);
    const [safeTradeId, setSafeTradeId] = useState(null);
    const [status, setStatus] = useState(null);
    const [data, setData] = useState({
        card: null,
        i_card: -1,
        td: null,
    });
    const [isWorkshopMode] = useState(LCMD.worker.nav?.view === "workshop");
    const [changedData, setChangedData] = useState({ n: null, m: null, wf: null });

    useEffect(() => {
        // todo: dirty hack
        try {
            if (props.initialCard.tid >= 0 && data.td) {
                LCMD.getSafeIDs({ trids: [data.td._trades[0].id] }, (error, safeIds) => {
                    setSafeTradeId(safeIds.trids[0] || null);
                });
            }
        } catch (e) {
            setSafeTradeId(undefined);
            console.error(e);
        }
    }, [LCMD, data, props.initialCard.tid]);

    useEffect(() => {
        if (data.card) {
            setChangedData({
                n: changedData.n !== null ? changedData.n : data.card.name,
                m: changedData.m !== null ? changedData.m : data.card.desc,
                wf:
                    changedData.wf !== null
                        ? changedData.wf
                        : "number" === typeof data.card.wf
                          ? data.card.wf.toString()
                          : "number" === typeof data.td?.wf
                            ? (data.td?.wf || 0).toString(10)
                            : "",
            });
        }
    }, [data.card]);

    const onChangeSubmit = () => {
        if (
            data?.card?.name !== changedData?.n ||
            data?.card?.desc !== changedData?.m ||
            (data?.card?.wf || 0).toString() !== changedData?.wf?.toString()
        ) {
            WebAppTelemetryFactory.trackEvent(`${props.webtrackingPrefix}-task-changes-updated`);
        }
        LCMD.updateSingleCard(props.initialCard, {
            n: changedData.n,
            m: changedData.m,
            wf: changedData.wf,
        });
        props.dialogCtx.close();
    };

    const initialProcess = useMemo(
        () => ({
            tid: props.initialCard.tid,
            aid: -1,
            i: 0,
        }),
        [props.initialCard.tid],
    );
    LCMD.useCardEffect(props, ts, data, status, setTS, setData, setStatus);
    const actions = useMemo(
        () => [
            <Button onClick={onChangeSubmit} key={0}>
                {intl.get("tasks.done")}
            </Button>,
        ],
        [onChangeSubmit],
    );
    const onCardNameChange = (value) => {
        setChangedData((prev) => ({ ...prev, n: value.value as string }));
    };

    const onCardDescChange = (value) => {
        setChangedData((prev) => ({ ...prev, m: value.value as string }));
    };

    const onProcessNameChange = useMemo(
        () => (value) => {
            LCMD.setProcess(props.initialCard.tid, [
                {
                    name: "name",
                    value: value.value,
                },
            ]);
        },
        [LCMD, props.initialCard.tid],
    );
    const onWorkforceChange = (_value) => {
        if (_value.value && _value.value.length > 0) {
            const value = Number.parseInt(_value.value, 10) || 0;
            if (value >= 0) {
                setChangedData((prev) => ({ ...prev, wf: value }));
            }
        } else {
            setChangedData((prev) => ({ ...prev, wf: null }));
        }
    };

    const title = data?.card
        ? intl.get("tasks.title.text", {
              i: data.i_card + 1,
              n: data.td._cards.length,
          })
        : intl.get("tasks.title.loading");
    const onTaskChange = useCallback(
        (field, value) => {
            LCMD.updateSingleCard(props.initialCard, {
                lcmx: {
                    key: field.key,
                    value: value,
                },
            });
        },
        [LCMD, props.initialCard],
    );
    const onProcessChange = useCallback(
        (field, value) => {
            LCMD.setProcess(data.td.id, [
                {
                    name: ["lcmx.", (field.key as string).toLocaleLowerCase()].join(""),
                    value: value,
                },
            ]);
        },
        [LCMD, data.td?.id],
    );

    return (
        <StackedDialogView title={title} actions={actions} onCancel={props.onCancel}>
            {data?.card && (
                <div className="flex shrink grow flex-row gap-x-4">
                    <div className="flex shrink grow flex-col">
                        <TextFieldWithCommit
                            id="taskTextField"
                            label={intl.get("tasks.task.label")}
                            value={changedData.n}
                            onChange={onCardNameChange}
                            ts={data.td._}
                        />
                        <br />
                        <TextFieldWithCommit
                            id="descriptionTextField"
                            label={intl.get("tasks.description.label")}
                            value={changedData.m}
                            onChange={onCardDescChange}
                            multiline
                            rows={3}
                            ts={data.td._}
                        />
                        <div>
                            <div className="py-1 text-sm font-semibold">{intl.get("tasks.attachments.label")}</div>
                            <AttachmentThumbnailControl
                                card={data.card}
                                atm={data.card.atm}
                                status={status}
                                setStatus={setStatus}
                            />
                            <UploadAttachmentControl
                                card={props.initialCard}
                                statusKey="attachments"
                                buttonId="fw.attachment.add"
                            />
                        </div>
                        <div>
                            <ExtensionFieldList
                                card={props.initialCard}
                                fields={data.td?.fields?.task}
                                _={data.td._}
                                values={data?.card?.lcmx}
                                onChange={onTaskChange}
                                atm={data.card.atm}
                                safeTradeId={safeTradeId}
                            />
                        </div>
                        <Separator />
                        <div className="text-lg font-semibold">{intl.get("tasks.process.title")}</div>
                        <div className="flex flex-row justify-between gap-x-5">
                            <TextFieldWithCommit
                                styles={flexGrowStyle}
                                label={intl.get("tasks.process.name.label")}
                                value={data.td.name}
                                onChange={onProcessNameChange}
                                ts={data.td._}
                                disabled={isWorkshopMode}
                            />
                            <TradeField
                                LCMD={LCMD}
                                data={data}
                                onChangeTrade={props.onChangeTrade}
                                disabled={isWorkshopMode}
                            />
                        </div>
                        {!isWorkshopMode && (
                            <LegacyStartEndDateControl
                                utc={true}
                                onChange={null}
                                defaultStart={
                                    new Date(data.td.start + new Date(data.td.start).getTimezoneOffset() * 60 * 1000)
                                }
                                defaultEnd={
                                    data.td.end
                                        ? new Date(data.td.end + new Date(data.td.end).getTimezoneOffset() * 60 * 1000)
                                        : null
                                }
                                duration={data.td.wd}
                                durationUnit={data.td.wd_unit}
                                ellapsedUnit={10}
                            />
                        )}
                        <ExtensionFieldList
                            card={initialProcess}
                            fields={data.td?.fields?.process}
                            _={data.td._}
                            values={data.td.lcmx}
                            onChange={onProcessChange}
                            safeTradeId={safeTradeId}
                        />
                    </div>
                    <Separator vertical />
                    <div className="flex shrink grow basis-[300px] flex-col">
                        {!isWorkshopMode && (
                            <>
                                <div>{intl.get("tasks.status.label")}</div>
                                <StatusButton data={data} LCMD={LCMD} />
                            </>
                        )}
                        <br />
                        <TextFieldWithCommit
                            label={intl.get("tasks.workforce.label")}
                            value={changedData.wf}
                            onChange={onWorkforceChange}
                            ts={data.td._}
                            styles={workforceFieldStyles}
                            type="number"
                            min={0}
                        />
                    </div>
                </div>
            )}
        </StackedDialogView>
    );
}
