import React, { Dispatch, FormEvent, SetStateAction, useCallback, useEffect, useState } from "react";
import { Checkbox } from "@fluentui/react";
import { intl, localizeIfNeeded, ShareDialogProps } from "lcmd2framework";
import { getCurrentLocale } from "./utils/date/locale";
import { useLCMD } from "../app/LCMContext";
import { LcmdModal2 } from "./common/LcmModal2";
import { Tabs, TabsList, TabsTrigger } from "./ui/tabs";
import { Button } from "./ui/button";
import { ArrowUpDown, Copy, Filter, Plus, Trash2, User, X } from "lucide-react";
import { SearchBar } from "./common/SearchBar/SearchBar";
import { ScrollArea } from "./ui/scroll-area";
import { DataTable } from "./ui/data-table";
import { MoreButtonDropdown } from "./MoreButtonDropdown/MoreButtonDropdown";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { PasswordInput } from "@/components/ui/password-input";
import { Textarea } from "./ui/textarea";
import { RadioGroup, RadioGroupItem } from "./ui/radio-group";
import { CanvasMeta } from "@/core/lcmd2core";
import { Persona } from "@/legacy/SubTypes";
import { useUpdateRole } from "./hooks/useUpdateRole.hook";
import { UserMap } from "./hooks/UserMapTypes";
import { useUserMap } from "./hooks/userMap.hook";
import { useShowTradeSelection } from "@/app/hooks/useShowTradeSelection";
import { toast } from "sonner";

type Role = "admin" | "user" | "readonly";
type SubRole = "editAll" | "editSpecific" | "updateApproval" | "updateCompleted";

function NormalUsersDialogView({
    emailsText,
    setEmailsText,
    role,
    setRole,
    selectedTrades,
    setSeletedTrades,
}: {
    emailsText: string;
    setEmailsText: (value: string) => void;
    role: Role;
    setRole: (value: Role) => void;
    selectedTrades: number[];
    setSeletedTrades: (value: number[]) => void;
}) {
    const {data: localSelectedTrades, showTradeSelection} = useShowTradeSelection();

    useEffect(() => {
        if (!localSelectedTrades) {
            return;
        }
        setSeletedTrades(localSelectedTrades);
    }, [localSelectedTrades]);
    return (
        <div className="flex shrink grow flex-col justify-stretch">
            <div>{intl.get("users.share.add.label")}</div>
            <Textarea
                rows={4}
                value={emailsText}
                onChange={(ev) => setEmailsText((ev.target as HTMLTextAreaElement).value)}
            />
            <div>{intl.get("users.share.add.note")}</div>
            <div>{intl.get("users.share.role")}</div>
            <RadioGroup required className="my-3" onValueChange={setRole} defaultValue={role}>
                <div className="flex items-center space-x-2">
                    <RadioGroupItem value="admin" id="admin" />
                    <Label htmlFor="admin">{intl.get("users.share.add.role.admin")}</Label>
                </div>
                <div className="flex-row items-center space-x-2">
                    <RadioGroupItem disabled value="user" id="user" />
                    <Label htmlFor="user">{intl.get("users.share.add.role.user")}</Label>
                    <div>
                        <div className="grid gap-2 my-3">
                            <div className="flex items-center space-x-2">
                                <RadioGroupItem value="user:editAll" id="editAll" />
                                <Label htmlFor="editAll">{intl.get("users.share.add.role.editAll")}</Label>
                            </div>
                            <div className="flex items-center justify-between space-x-2">
                                <div>
                                    <RadioGroupItem value="user:editSpecific" id="editSpecific" className="mr-2" />
                                    <Label htmlFor="readonly">{intl.get("users.share.add.role.editSpecific")}</Label>
                                </div>
                                <Filter onClick={() => showTradeSelection(selectedTrades)}/>
                            </div>
                            <div className="flex items-center justify-between space-x-2">
                                <div>
                                    <RadioGroupItem value="user:updateApproval" id="updateApproval" className="mr-2" />
                                    <Label htmlFor="updateApproval">{intl.get("users.share.add.role.updateApproval")}</Label>
                                </div>
                                <Filter onClick={() => showTradeSelection(selectedTrades)}/>
                            </div>
                            <div className="flex items-center justify-between space-x-2">
                                <div>
                                    <RadioGroupItem value="user:updateCompleted" id="udpateCompleted" className="mr-2" />
                                    <Label htmlFor="udpateCompleted">{intl.get("users.share.add.role.udpateCompleted")}</Label>
                                </div>
                                <Filter onClick={() => showTradeSelection(selectedTrades)}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex items-center space-x-2">
                    <RadioGroupItem value="readonly" id="readonly" />
                    <Label htmlFor="readonly">{intl.get("users.share.add.role.readonly")}</Label>
                </div>
            </RadioGroup>
        </div>
    );
}

function MobileUsersDialogView() {
    return (
        <div className="frow flex shrink flex-col justify-stretch">
            <div>{intl.get("users.share.add.label")}</div>
            <Textarea rows={4} />
            <div>{intl.get("users.share.add.note")}</div>
            <Label htmlFor="login" className="my-3 block font-semibold">
                {intl.get("users.share.add.tradeCheckbox.label")}
            </Label>
            <RadioGroup id="login" required defaultValue="login">
                <div className="flex flex-grow flex-row items-center gap-2">
                    <RadioGroupItem id="login" value="login" />
                    <Label htmlFor="login">{intl.get("users.share.add.loginLabel")}</Label>
                </div>
                <div className="flex flex-grow flex-row items-center justify-between gap-2">
                    <RadioGroupItem id="select" value="select" />
                    <div className="w-full">
                        <Button variant="outline">{intl.get("users.share.add.selectTrade")}</Button>
                    </div>
                </div>
            </RadioGroup>
            <Label htmlFor="license" className="my-3 block font-semibold">
                {intl.get("users.share.add.licenseCheckbox.label")}
            </Label>
            <RadioGroup id="license" required>
                <div className="flex items-center space-x-2">
                    <RadioGroupItem value="default" id="none" />
                    <Label htmlFor="none">{intl.get("users.share.add.licenseCheckbox.noneLicensOption")}</Label>
                </div>
                <div className="flex items-center space-x-2">
                    <RadioGroupItem value="comfortable" id="project" />
                    <Label htmlFor="project">{intl.get("users.share.add.licenseCheckbox.projectLicensOption")}</Label>
                </div>
                <div className="flex items-center space-x-2">
                    <RadioGroupItem value="compact" id="byol" />
                    <Label htmlFor="byol">{intl.get("users.share.add.licenseCheckbox.ownLicenseOption")}</Label>
                </div>
            </RadioGroup>
        </div>
    );
}

function UsersDialog({
    isOpen,
    onClose,
    onAdd,
    mobile,
}: {
    isOpen: boolean;
    onClose: () => void;
    onAdd: (result: any) => void;
    mobile: boolean;
}) {
    const [emailsText, setEmailsText] = useState("");
    const [role, setRole] = useState<Role>("readonly");
    const [selectedTrades, setSeletedTrades] = useState<number[]>([]);

    const _onAdd = useCallback(() => {
        const emails = emailsText
            .trim()
            .split(/[\s,;]+/)
            .filter((email) => email.length > 0);
        if (emails.length > 0) {
            onAdd({
                project: {
                    emails,
                    role,
                    selectedTrades,
                },
            });
            setEmailsText("");
        } else {
            onClose();
        }
    }, [onAdd, emailsText, role, selectedTrades, setEmailsText]);

    return (
        <LcmdModal2
            size="m"
            open={isOpen}
            titleTop
            header={{
                title: intl.get("users.share.add.title"),
            }}
            onOpenChange={() => {}}
            closable={false}
            buttons={[
                <Button key="cancel" variant="outline" onClick={onClose}>
                    {intl.get("fw.cancel")}
                </Button>,
                <Button key="ok" onClick={_onAdd}>
                    {intl.get("fw.ok")}
                </Button>,
            ]}
        >
            {mobile ? (
                <MobileUsersDialogView />
            ) : (
                <NormalUsersDialogView
                    emailsText={emailsText}
                    setEmailsText={setEmailsText}
                    role={role}
                    setRole={setRole}
                    selectedTrades={selectedTrades}
                    setSeletedTrades={setSeletedTrades}
                />
            )}
        </LcmdModal2>
    );
}

interface NormalUsersProps {
    data: Persona[];
    setReload: Dispatch<SetStateAction<number>>;
    meta: CanvasMeta;
    copyToClipboard: (text?: string) => void;
    projectId?: string;
}

function NormalUsers({ data, setReload, meta, copyToClipboard, projectId }: NormalUsersProps) {
    const LCMD = useLCMD();
    const userMap: UserMap = useUserMap();
    const [deleteUser, setDeleteUser] = useState(null);
    const [currentUser, setCurrentUser] = useState(null);
    const users = Object.entries(userMap).map((user) => Object.assign(user[1], { key: user[0] }));
    const { updateRole } = useUpdateRole();

    useEffect(() => {
        LCMD.getAuthUser((error, user) => {
            if (error) {
                console.error(error);
            } else {
                setCurrentUser(user);
            }
        });
    }, []);

    const handleRoleUpdate = async (role: string, row) => {
        if (row.original.data?.sub) {
            await updateRole(projectId, row.original.data.sub, role);
            setReload((reload) => reload + 1);
        }
    };

    const columns = [
        {
            accessorKey: "personaName",
            size: 200,
            header: ({ column }) => {
                return (
                    <Button variant="ghost" className="flex gap-2 p-0">
                        {intl.get("users.share.email")}
                        <ArrowUpDown size={16} onClick={() => column.toggleSorting(column.getIsSorted() === "asc")} />
                        <Copy size={16} className="cursor-pointer" onClick={() => copyToClipboard()} />
                    </Button>
                );
            },
            cell: ({ row }) => (
                <div className="relative flex max-w-max items-center gap-2">
                    <p>{row.getValue("personaName")}</p>
                    <Copy
                        size={15}
                        className="cursor-pointer"
                        onClick={() => copyToClipboard(row.getValue("personaName"))}
                    />
                </div>
            ),
        },
        {
            accessorKey: "data.role.role",
            header: ({ column }) => {
                return (
                    <Button
                        variant="ghost"
                        className="p-0"
                        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                    >
                        {intl.get("users.share.role")}
                        <ArrowUpDown className="ml-2 h-4 w-4" />
                    </Button>
                );
            },
            cell: ({ row }) => {
                const filter = row.original.data.rbac?.filter
                    ? (row.original.data.rbac?.filter.contraints || [])
                          .filter((constraint) => (constraint !== "?" ? constraint : null))
                          .join(", ")
                    : null;
                const role = localizeIfNeeded(row.original.data.role.role);
                const roleName = intl.get(`users.share.add.role.${role}`);

                return <div>{filter ? `${roleName}: ${filter}` : roleName}</div>;
            },
        },
        {
            id: "actions",
            meta: {
                cellProps: {
                    style: {
                        paddingRight: "8px",
                        display: "flex",
                        justifyContent: "flex-end",
                        height: "100%",
                    },
                },
            },
            cell: ({ row }) => {
                const currentUserAdmin = users.find(
                    (user) => user.email === currentUser?.email && user.role === "admin",
                );

                const menuProps = [
                    {
                        key: "delete",
                        text: intl.get("users.share.delete.text"),
                        icon: <Trash2 size={16} color="#666666" />,
                        disabled: !("admin" == meta?.role),
                        onClick: () => {
                            if (row.original.data?.sub) {
                                setDeleteUser(row.original.data);
                            }
                        },
                    },
                    {
                        key: "filter",
                        text: intl.get("users.share.filter.text"),
                        icon: <Filter size={16} color="#666666" />,
                        disabled: "admin" !== meta?.role,
                        onClick: () => {
                            if (row.original.data?.sub) {
                                const trades = row.original.data?.rbac?.filter?.trades || [];
                                const commandBarItemCtx = {
                                    extendedRights: (row.original.data?.rbac?.rules?.a_decl?.s?.values || {})[2],
                                };
                                LCMD.showDialog("fw.trade.select", {
                                    title: intl.get("filter.trades.share.title"),
                                    trades: trades,
                                    commandBarItems: [
                                        {
                                            key: "check",
                                            commandBarButtonAs: () => {
                                                return (
                                                    <Checkbox
                                                        label={intl.get("filter.trades.extendedRights")}
                                                        styles={{ root: { alignSelf: "center" } }}
                                                        defaultChecked={commandBarItemCtx.extendedRights}
                                                        onChange={(
                                                            ev?: FormEvent<HTMLElement | HTMLInputElement>,
                                                            isChecked?: boolean,
                                                        ) => {
                                                            commandBarItemCtx.extendedRights = isChecked;
                                                        }}
                                                    />
                                                );
                                            },
                                            onClick: () => {},
                                        },
                                    ],
                                    cb: (sel) => {
                                        LCMD.setCollaboratorState(row.original.data.sub, {
                                            trades: sel,
                                            extendedRights: commandBarItemCtx.extendedRights,
                                        });
                                        setReload((reload) => reload + 1);
                                    },
                                    multiselect: true,
                                });
                            }
                        },
                    },
                    {
                        key: "change-role",
                        text: intl.get("users.share.changeRole.text"),
                        icon: <User size={16} color="#666666" />,
                        disabled: "admin" !== meta?.role || row.original.data?.sub === currentUserAdmin?.key,
                        options: [
                            {
                                key: "admin",
                                text: intl.get("users.share.add.role.admin"),
                                onClick: () => handleRoleUpdate("admin", row),
                            },
                            {
                                key: "user",
                                text: intl.get("users.share.add.role.user"),
                                onClick: () => handleRoleUpdate("user", row),
                            },
                            {
                                key: "readonly",
                                text: intl.get("users.share.add.role.readonly"),
                                onClick: () => handleRoleUpdate("readonly", row),
                            },
                        ],
                    },
                ];

                return <MoreButtonDropdown menuItems={menuProps} />;
            },
        },
    ];

    return (
        <div className="flex flex-auto flex-col overflow-y-hidden">
            <ScrollArea className="relative h-full w-full">
                <DataTable columns={columns} data={data} />
            </ScrollArea>
            {!!deleteUser && (
                <LcmdModal2
                    size="s"
                    open={!!deleteUser}
                    titleTop
                    header={{
                        title: intl.get("users.share.delete.confirmTitle"),
                        subtitle: intl.get("users.share.delete.confirmText", { email: deleteUser.email }),
                    }}
                    onOpenChange={() => setDeleteUser(null)}
                    buttons={[
                        <Button key="delete" size="sm" variant="outline" onClick={() => setDeleteUser(null)}>
                            {intl.get("fw.cancel")}
                        </Button>,
                        <Button
                            key="delete"
                            size="sm"
                            variant="destructive"
                            onClick={() => {
                                if (deleteUser !== null) {
                                    LCMD.unlinkProject(null, deleteUser.sub, (error, result) => {
                                        setReload((reload) => reload + 1);
                                    });
                                }
                                setDeleteUser(null);
                            }}
                        >
                            {intl.get("fw.ok")}
                        </Button>,
                    ]}
                />
            )}
        </div>
    );
}

function ApiKeyView(props: {
    projectId: string;
    copyToClipboard: (text?: string) => void;
    worker: { auth: { auth_token: string } };
}) {
    return (
        <div className="my-4 flex w-full flex-col gap-4">
            <div className="flex w-[300px] w-full flex-col gap-2">
                <Label htmlFor="projectId">{intl.get("users.share.projectId.label")}</Label>
                <div className="flex items-center gap-2">
                    <Input id="projectId" readOnly value={props.projectId} className="w-[330px]" />
                    <Copy className="h-5 w-5 cursor-pointer" onClick={() => props.copyToClipboard(props.projectId)} />
                </div>
            </div>
            <div className="flex w-full flex-col gap-2">
                <Label htmlFor="password">{intl.get("users.share.authToken.label")}</Label>
                <div className="flex w-full items-center gap-2">
                    <PasswordInput
                        id="password"
                        readOnly
                        value={props?.worker?.auth?.auth_token}
                        containerClassName="w-full"
                    />
                    <Copy
                        className="h-5 w-5 cursor-pointer"
                        onClick={() => props.copyToClipboard(props?.worker?.auth?.auth_token)}
                    />
                </div>
            </div>
        </div>
    );
}

export function ShareDialog(props: ShareDialogProps) {
    const LCMD = useLCMD();
    const [isAddDialog, setIsAddDialog] = useState(null);
    const [view, setView] = useState(0);
    const [personas, setPersonas] = useState(null);
    const [reload, setReload] = useState(0);
    const [searchText, setSearchText] = useState<string>("");
    const [sortState, setSortState] = useState<{
        key: string[];
        isSortedDescending: boolean;
    }>({ key: ["email"], isSortedDescending: false });

    const copyToClipboard = (text?: string) => {
        const copyText = text || personas.map((el) => el?.data.email).join(", ");

        navigator.clipboard.writeText(copyText).then(
            () => {
                toast.success(intl.get("users.share.copy.success"));
            },
            (err) => {
                toast.error(intl.get("users.share.copy.failure"));
            },
        );
    };

    const onAdd = useCallback(
        (result) => {
            if (result.project && Array.isArray(result.project.emails) && result.project.emails.length > 0) {
                const [role, subRole]:[Role, SubRole] = result.project.role.split(":");
                LCMD.addCollaborator(
                    null,
                    null,
                    result.project.emails,
                    role || "readonly",
                    (error, collaboratorResult) => {
                        if (error) {
                            console.error(error);
                        }
                        collaboratorResult.forEach(({body:{addedSub}}) => {
                            LCMD.setCollaboratorState(addedSub, {
                                trades: result.project.selectedTrades,
                                extendedRights: subRole === "updateCompleted",
                                tradesAdmin: subRole === "editSpecific" ? true : undefined,
                            });
                        });
                        setReload((reload) => 1 + reload);
                    },
                );
            }
            setIsAddDialog(null);
        },
        [setReload, setIsAddDialog],
    );

    LCMD.useProjectCollaboratorsEffect(
        null,
        (error, facepilePersonas, project) => {
            if (error) {
                setPersonas(null);
            } else {
                facepilePersonas.forEach((persona) => {
                    const sub = persona?.data?.sub;
                    if (sub) {
                        persona.data = { ...persona.data, role: project.project.shared[sub], rbac: undefined };
                    }
                });

                if (searchText.length) {
                    facepilePersonas = facepilePersonas.filter((p) => {
                        return p.data.email.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
                    });
                }

                setPersonas(
                    facepilePersonas.slice(0).sort((a: any, b: any) => {
                        let aTemp, bTemp: string;
                        aTemp = a.data;
                        sortState.key.forEach((key) => {
                            aTemp = aTemp[key];
                        });
                        aTemp = aTemp.toString();

                        bTemp = b.data;
                        sortState.key.forEach((key) => {
                            bTemp = bTemp[key];
                        });
                        bTemp = bTemp.toString();

                        if (sortState.isSortedDescending) {
                            return bTemp.localeCompare(aTemp, getCurrentLocale().code, {
                                sensitivity: "base",
                                numeric: true,
                            });
                        } else {
                            return aTemp.localeCompare(bTemp, getCurrentLocale().code, {
                                sensitivity: "base",
                                numeric: true,
                            });
                        }
                    }),
                );

                LCMD.getProjectDetails((error, data) => {
                    const rbacs = data?.rbac || {};
                    setPersonas((_personas) => {
                        const tmppersonas = _personas.slice().map((persona) => {
                            return { ...persona, data: { ...persona.data, rbac: rbacs[persona.data.sub] || null } };
                        });
                        return tmppersonas;
                    });
                });
            }
        },
        [reload, sortState, searchText],
    );

    const list =
        0 === view ? (
            <NormalUsers
                key={view}
                copyToClipboard={copyToClipboard}
                data={personas}
                setReload={setReload}
                meta={props.meta}
                projectId={props?.projectId}
            />
        ) : 1 === view ? (
            <NormalUsers
                key={view}
                copyToClipboard={copyToClipboard}
                data={[]}
                setReload={setReload}
                meta={props.meta}
                projectId={props?.projectId}
            />
        ) : 2 === view ? (
            <ApiKeyView key={view} copyToClipboard={copyToClipboard} {...(props as any)} />
        ) : null;

    return (
        <>
            <LcmdModal2 open size="l" onOpenChange={() => {}} closable={false}>
                <div className="flex h-[400px] max-h-full max-w-full flex-col">
                    <div className="mb-2 flex items-center font-semibold">
                        <Tabs defaultValue="0" onValueChange={(item) => setView(Number.parseInt(item, 10))}>
                            <TabsList>
                                <TabsTrigger value="0">{intl.get("users.share.pivot")}</TabsTrigger>
                                <TabsTrigger value="2">{intl.get("users.api.pivot")}</TabsTrigger>
                            </TabsList>
                        </Tabs>
                        <div className="ml-auto">
                            {!("admin" !== props.meta?.role || 2 === view) && (
                                <Button
                                    variant="ghost"
                                    className="border-[#8a8886]"
                                    onClick={() => setIsAddDialog(props.meta)}
                                    disabled={"admin" !== props.meta?.role || 2 === view}
                                >
                                    <Plus size={20} className="mr-2" />
                                    {intl.get("users.add.icon")}
                                </Button>
                            )}
                            <Button variant="ghost" className="border-[#8a8886] p-2.5" onClick={props.onClose}>
                                <X size={20} />
                            </Button>
                        </div>
                    </div>

                    {[0, 1].includes(view) && (
                        <SearchBar
                            className="my-2 w-full"
                            value={searchText}
                            onChange={setSearchText}
                            placeholder={intl.get("users.share.search")}
                        />
                    )}
                    {Array.isArray(personas) ? list : null}
                </div>
            </LcmdModal2>
            <UsersDialog
                key={1 === view ? "user" : "mobile"}
                isOpen={isAddDialog}
                onClose={() => setIsAddDialog(null)}
                mobile={1 === view}
                onAdd={onAdd}
            />
        </>
    );
}
