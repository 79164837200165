import { Core, ProcessGetter } from "../lcmd2core";
import { replaceUserIdsWithNames } from "@/utils/MentionIdResolver";

export class CommentService {

    /**
     * Konstruktor für den CommentService.
     * Initialisiert das Core-Modul.
     * @param core - Die Core-Instanz, die für Kommentaroperationen verwendet wird.
     */
    constructor(private core: Core) {}

    /**
     * Ruft alle Kommentare für einen gegebenen Prozess ab.
     * Die Benutzer-IDs in den Kommentaren werden durch Namen ersetzt.
     * @param processId - Die ID des Prozesses, dessen Kommentare abgerufen werden sollen.
     * @returns Eine Liste von Kommentaren mit durch Namen ersetzten Benutzer-IDs.
     */
    public async get(processId: number) {
        const processGetter = new ProcessGetter(
            this.core,
            processId,
        );

        const comments = processGetter.getComments();
        for await (const comment of comments.values()) {
            comment.text = await replaceUserIdsWithNames(comment.text, this.core.auth_token);
        }

        return comments;
    }

    /**
     * Erstellt einen neuen Kommentar für einen bestimmten Prozess.
     * Entfernt formatierte Benutzererwähnungen vor dem Speichern.
     * @param processId - Die ID des Prozesses, für den der Kommentar erstellt wird.
     * @param comment - Der Kommentartext, der gespeichert werden soll.
     */
    public create(processId: number, comment: string): void {
        this.core._model.createProcessComment(
            processId,
            comment.replace(/\@\{"([^"]+)"\}/g, ""),
        );
    }

    /**
     * Aktualisiert einen vorhandenen Kommentar in einem bestimmten Prozess.
     * Entfernt formatierte Benutzererwähnungen vor dem Speichern.
     * @param processId - Die ID des Prozesses, zu dem der Kommentar gehört.
     * @param commentId - Die ID des zu aktualisierenden Kommentars.
     * @param comment - Der aktualisierte Kommentartext.
     */
    public update(processId: number, commentId: number, comment: string): void {
        this.core._model.editProcessComment({
            processId,
            commentId,
            text: comment.replace(/\@\{"([^"]+)"\}/g, ""),
        });
    }

    /**
     * Löscht einen Kommentar aus einem bestimmten Prozess.
     * Wenn keine Kommentar-ID angegeben ist, werden alle Kommentare des Prozesses gelöscht.
     * @param processId - Die ID des Prozesses, aus dem der Kommentar gelöscht wird.
     * @param commentId - (Optional) Die ID des zu löschenden Kommentars. Falls nicht angegeben, werden alle Kommentare des Prozesses gelöscht.
     */
    public delete(processId: number, commentId?: number): void {
        this.core._model.deleteProcessComment({
            processId,
            commentId,
        });
    }
}
