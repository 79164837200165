﻿import { intl } from "@/legacy/GlobalHelperReact";
import { Circle } from "lucide-react";
import { useConflictMode } from "@/app/store/canvasStore";

type LegendProps = {
    mode: "canvas" | "wb";
    details: ViewTypes;
};

type ViewItems = {
    color: string;
    text: string;
}[];

enum ViewTypes {
    Conflict = "conflict",
    Status = "status",
    Constrains = "stability",
}

export function Legend({ details, mode }: LegendProps) {
    const showConflictMode = useConflictMode();

    const conflictView: ViewItems = [
        {
            color: "#107C10",
            text: "legend.noConflict",
        },
        {
            color: "#D83B01",
            text: "legend.causeOfConflict",
        },
        {
            color: "#FFC000",
            text: "legend.affected",
        },
    ];
    const statusView: ViewItems = [
        {
            color: "#107C10",
            text: "legend.status.done",
        },
        {
            color: "#D83B01",
            text: "legend.status.overdue",
        },
        {
            color: "#a1a1aa",
            text: "legend.status.open",
        },
        {
            color: "#e47828",
            text: "legend.status.inProgressLate",
        },
        {
            color: "#009bd4",
            text: "legend.status.inProgressTime",
        },
    ];
    const constrainsView: ViewItems = [
        {
            color: "#107C10",
            text: "legend.constrains.noOpen",
        },
        {
            color: "#D83B01",
            text: "legend.constrains.overdue",
        },
        {
            color: "#a1a1aa",
            text: "legend.constrains.open",
        },
    ];
    let legendData: ViewItems = [];
    if (mode === "canvas") {
        legendData = conflictView;
        switch (details) {
            case ViewTypes.Conflict:
                legendData = conflictView;
                break;
            case ViewTypes.Status:
                legendData = statusView;
                break;
            case ViewTypes.Constrains:
                legendData = constrainsView;
                break;
            default:
                legendData = [];
        }
    }

    return (
        !!legendData.length &&
        !showConflictMode && (
            <div className="pointer-events-none mb-2.5 flex select-none flex-row items-center justify-center rounded-lg border border-solid border-[rgba(0,0,0,0.1)] bg-white p-1 font-['Inter']">
                {legendData.map((item) => (
                    <>
                        <Circle fill={item.color} color={item.color} className="width mr-1.5 h-4 w-4 text-base" />
                        <div className="mr-3 text-xs font-normal leading-4">{intl.get(item.text)}</div>
                    </>
                ))}
            </div>
        )
    );
}
