import { useEffect, useState } from "react";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";
import { Input } from "@/components/ui/input";
import { intl } from "lcmd2framework";
import * as z from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Paths } from "../../types/routes";
import { useLoginStore } from "../../store/loginStore";
import { AuthClient } from "@/app/services/AuthClient.service";
import { showScreenOfDeath } from "@/legacy/ScreenOfDeath";
import { WebAppTelemetryFactory } from "@/app/services/WebAppTelemetry.service";
import { SSOButton } from "@/components/common/SSOButton/SSOButton";

type HomeViewProps = {
    onLogin: (sub: string, authToken: string, email: string) => void;
};

export function HomeView({ onLogin }: HomeViewProps) {
    const { setEmail, email } = useLoginStore();
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    //TODO: make hook
    async function handleLogin(email: string) {
        setEmail(email);
        // check always with trimmed and lowercase emails. In backend this should also be handled!!!
        const isRegistered = await AuthClient.isUserRegistered(email);
        const whiteList: string[] = await AuthClient.getWhiteList();

        if (isRegistered) {
            navigate(Paths.LOGIN);
            return;
        }

        WebAppTelemetryFactory.trackEvent("new-user-email-clicked");
        if (whiteList.includes(email.split("@")[1].toLowerCase())) {
            navigate(`${Paths.REGISTER_WITHOUT_EMAIL}/${email}`);
            return;
        }

        navigate(Paths.BOOK_APPOINTMENT);
    }

    const handleSubmit = async (values: z.infer<typeof formSchema>) => {
        setIsLoading(true);
        try {
            await handleLogin(values.email);
        } catch (error) {
            showScreenOfDeath(error);
        }
        setIsLoading(false);
    };

    const formSchema = z.object({
        email: z.string().email(intl.get("LcmdLogin.ForgotPassword.EmailInputErrorMessage")).trim().toLowerCase(),
    });
    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            email: email || "",
        },
    });

    return (
        <>
            <div className={"text-xl font-semibold leading-7 text-zinc-900"}>{intl.get("LcmdLogin.Home.Headline")}</div>
            <Form {...form}>
                <form onSubmit={form.handleSubmit(handleSubmit)}>
                    <div className={"mb-8 mt-4 space-y-1"}>
                        <FormField
                            control={form.control}
                            name="email"
                            disabled={isLoading}
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>{intl.get("LcmdLogin.Home.EmailLabel")}</FormLabel>
                                    <FormControl>
                                        <Input {...field} placeholder={intl.get("LcmdLogin.Home.EmailPlaceholder")} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                    <Button type="submit" disabled={isLoading} className={"mb-4 h-12 w-full"}>
                        {intl.get("LcmdLogin.Home.ContinueButton")}
                    </Button>

                    <div className={"mb-4 flex items-center justify-between align-middle"}>
                        <div className={"h-px w-[132px] bg-gray-300"}></div> {intl.get("LcmdLogin.Home.Or")}
                        <div className={"h-px w-[132px] bg-gray-300"}></div>
                    </div>
                    <SSOButton
                        onLoadingStateChanged={(isLoading) => setIsLoading(isLoading)}
                        handleLogin={(sub, authToken) => {
                            onLogin(sub, authToken, email);
                        }}
                    ></SSOButton>
                </form>
            </Form>
        </>
    );
}
