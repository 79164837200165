import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ModalAssetHeader } from "../../ModalAsset";
import { DefaultButton, PrimaryButton } from "@fluentui/react";
import { ModalAssetFooter } from "../../ModalAsset/ModalAssetFooter";
import { TaktzonesMain } from "./TaktzonesMain";
import { intl } from "@/legacy/GlobalHelperReact";
import { RouteNames } from "../filter.interface";
import { useToDoModalFilterStore } from "../../../../app/store/toDoModalFilterStore";
import { WebAppTelemetryFactory } from "@/app/services/WebAppTelemetry.service";

export function AreasFilter() {
    const navigate = useNavigate();
    const [selection, setSelection] = useState([]);
    const [initiallySelected, setInitiallySelected] = useState({});
    const { filterData, setFilterData, areas } = useToDoModalFilterStore();

    useEffect(() => {
        if (filterData?.areasFilterData) {
            const obj = Object.values(filterData.areasFilterData).reduce((accu, item) => {
                // @ts-ignore
                return { ...accu, [item.tid]: item };
            }, {});
            setInitiallySelected(obj);
        }
    }, [filterData]);

    const handleApplyClick = () => {
        const currentFilterData = filterData;
        const updatedFilterData = {
            ...currentFilterData,
            areasFilterData: selection,
        };
        setFilterData(updatedFilterData);
        navigate(RouteNames.HOME);
        console.log(selection.length)
        if (updatedFilterData.areasFilterData.length > 0) {
            WebAppTelemetryFactory.trackEvent('todo-areas-filter-applied');
        }
    };

    function headerTitle() {
        return (
            <>
                {intl.get("ModalToDoFilter.AreasFilter.filter")} / {intl.get("ModalToDoFilter.AreasFilter.areas")}
            </>
        );
    }

    function footerLeftContent() {
        return (
            <DefaultButton
                styles={{
                    root: { border: "none" },
                    rootDisabled: { backgroundColor: "transparent" },
                }}
                disabled={!selection.length}
                text={intl.get("ModalToDoFilter.AreasFilter.clearFilters")}
                iconProps={{ iconName: "Delete" }}
                onClick={() => {
                    setFilterData(null);
                }}
            />
        );
    }

    function footerRightContent() {
        return (
            <>
                {selection.length} {intl.get("ModalToDoFilter.AreasFilter.areaSelected")}
                <PrimaryButton
                    text={intl.get("ModalToDoFilter.AreasFilter.done")}
                    styles={{ root: { marginLeft: 10 } }}
                    onClick={handleApplyClick}
                />
            </>
        );
    }

    return (
        <>
            <ModalAssetHeader
                onBackButtonChange={() => {
                    navigate(RouteNames.HOME);
                }}
                title={headerTitle()}
            />

            <TaktzonesMain
                tz={areas}
                initiallySelected={initiallySelected}
                onSelected={(selected) => {
                    setSelection(Object.values(selected));
                }}
            />

            <ModalAssetFooter leftContent={footerLeftContent()} rightContent={footerRightContent()} />
        </>
    );
}
