import { Button } from "@/components/ui/button";
import microsoftLogo from "@/lcmd2loginV2/src/assets/MicrosoftLogo.svg";
import { intl } from "@/legacy/GlobalHelperReact";
import { BrowserAuthError } from "@azure/msal-browser";
import { showScreenOfDeath } from "@/legacy/ScreenOfDeath";
import { useMicrosoftSSO } from "@/lcmd2loginV2/src/hooks/useMicrosoftSSO";
import { useEffect } from "react";
import { InteractionStatus } from "@azure/msal-browser";
import { AuthClient } from "@/app/services/AuthClient.service";
import { toast } from "sonner";

export function SSOButton({
    onLoadingStateChanged,
    handleLogin,
}: {
    onLoadingStateChanged: (isLoading: boolean) => void;
    handleLogin: (sub: string, authToken: string) => void;
}) {
    const { result, inProgress, loginPopup } = useMicrosoftSSO();

    useEffect(() => {
        async function handleSsoLogin() {
            try {
                onLoadingStateChanged(true);
                const loginResult = await AuthClient.loginWithSSO(result.accessToken);
                handleLogin(loginResult.sub, loginResult.authToken);
            } catch (error) {
                showScreenOfDeath(error);
                onLoadingStateChanged(false);
            }
        }
        if (result) {
            handleSsoLogin();
        }
    }, [result]);

    useEffect(() => {
        onLoadingStateChanged(inProgress !== InteractionStatus.None);
    }, [inProgress]);
    function handleLoginWithSSO() {
        loginPopup().catch((err) => {
            if (!(err instanceof BrowserAuthError)) {
                // todo add toaster
                toast.error(err.errorMessage, { richColors: true });
            }
        });
    }
    return (
        <Button variant={"outline"} type={"button"} className={"h-12 w-full"} onClick={handleLoginWithSSO}>
            <img src={microsoftLogo} className={"mr-4"} />
            {intl.get("LcmdLogin.Home.SSOButton")}
        </Button>
    );
}
