import React from "react";
import { IconButton, IContextualMenuProps, mergeStyleSets } from "@fluentui/react";
import { Icons } from "@/components/RegisteredIcons";
import { FoldVertical, UnfoldVertical } from "lucide-react";
import { intl } from "@/legacy/GlobalHelperReact";

type CanvasMoreMenuDropdownProps = {
    onExpandAllAreas: () => void;
    onCollapseAllAreas: () => void;
};

const classNames = mergeStyleSets({
    moreMenuIconButton: {
        color: "black",
        fontSize: 16,
        marginLeft: "auto!important",
        marginRight: 4,
    },
    moreMenuContainer: {
        padding: 4,
        minWidth: "fit-content",
    },
    moreMenuCallout: {
        marginTop: "20px",
        borderRadius: 8,
    },
});

const moreMenuItemStyle: React.CSSProperties = {
    borderRadius: 8,
    height: 32,
    color: "black",
};

function createMenuItems(onExpandAllAreas: () => void, onCollapseAllAreas: () => void): IContextualMenuProps["items"] {
    return [
        {
            key: "more.expandAll",
            text: intl.get("canvas.moreMenu.expandAreas"),
            iconProps: { iconName: "UnfoldVertical" },
            style: moreMenuItemStyle,
            onRenderIcon: () => <UnfoldVertical size={16} className="mr-1" />,
            onClick: onExpandAllAreas,
        },
        {
            key: "more.collapseAll",
            text: intl.get("canvas.moreMenu.collapseAreas"),
            iconProps: { iconName: "FoldVertical" },
            style: moreMenuItemStyle,
            onRenderIcon: () => <FoldVertical size={16} className="mr-1" />,
            onClick: onCollapseAllAreas,
        },
    ];
}

export function CanvasMoreMenuDropdown({
    onExpandAllAreas,
    onCollapseAllAreas,
}: CanvasMoreMenuDropdownProps): JSX.Element {
    const menuProps = {
        items: createMenuItems(onExpandAllAreas, onCollapseAllAreas),
        className: classNames.moreMenuContainer,
        calloutProps: {
            className: classNames.moreMenuCallout,
            styles: { calloutMain: { borderRadius: "8px" } },
        },
    };

    return (
        <IconButton
            className={classNames.moreMenuIconButton}
            styles={{ menuIcon: { display: "none" } }}
            menuProps={menuProps}
            iconProps={{ iconName: Icons.Lcmd_More, title: intl.get("canvas.moreMenu.tooltip") }}
        />
    );
}
