import { LcmFacepile } from "@/components/common/LcmFacepile";
import { format } from "date-fns";
import { useUser } from "@/components/hooks/userMap.hook";
import { isValidUrl } from "@/components/Utils";
import { ICommentHandlers } from "./CommentsView";
import { MentionsComponent } from "@/components/common/MentionsComponent";
import { DropdownEditDelete } from "@/components/common/DropdownEditDelete/DropdownEditDelete";
import { CommentDeleteDialog } from "@/components/Sidebar/CommentsView/CommentDeleteDialog";
import { useState } from "react";
import { ProcessComment } from "@/app/hooks/useProcessComments";
import { getCurrentLanguage } from "@/components/utils/date/locale";
import { getLocaleDateAndTime } from "@/components/utils/date/dateAndTime";

type TCommentProps = {
    //comment: HistoryChangeEvent;
    commentHandlers: ICommentHandlers;
    currentUserId: string | null;
    comment: ProcessComment;
    commentId: string;
};

export function Comment({
    comment: { text, date, userId: commentUserId },
    commentHandlers,
    currentUserId,
    commentId,
}: TCommentProps) {
    const { deleteComment, editComment } = commentHandlers;
    const formattedDate: string = getLocaleDateAndTime(date, getCurrentLanguage()).toString();

    const author = useUser(commentUserId);

    const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [isOpedEditInput, setOpedEditInput] = useState(false);

    function toggleEditInputHandler() {
        setOpedEditInput((state) => !state);
    }

    function toggleDialogDelete() {
        setDeleteDialogOpen((state) => !state);
    }

    async function editCommentHandler(id: string, text: string) {
        if (commentUserId === currentUserId) {
            await editComment(commentId, text);
        }
    }

    const mentionsInputButtons = {
        cancel: toggleEditInputHandler,
        save: editCommentHandler,
    };

    const formatCommentWithMentionsAndLinks = (comment: string) => {
        const parts = comment.replace(/\$\{"([^"]+)"\}/g, "").split(/(@\{"[^}]+\"\})/g);

        return parts.map((part, index) => {
            const mentionMatch = part && part.match(/@\{"([^}]+)\"\}/);
            if (mentionMatch) {
                return (
                    <span
                        key={index}
                        className="text-nowrap rounded-full bg-[#ede9fe] px-1 py-0.5 text-xs font-medium leading-5 text-black"
                    >
                        @{mentionMatch[1]}
                    </span>
                );
            }

            return part.split("\n").map((line, i) => (
                <span key={i}>
                    {i > 0 && <br />}
                    {line.split(" ").map((word, index) => {
                        if (isValidUrl(word)) {
                            return (
                                <>
                                    {index > 0 && " "}
                                    <a className="underline text-indigo-700" key={index} href={word} target="_blank" rel="noreferrer">
                                        {word}
                                    </a>
                                </>
                            );
                        }
                        return index > 0 ? " " + word : word;
                    })}
                </span>
            ));
        });
    };

    return (
        <div className={"flex justify-between"}>
            <div className="flex gap-3">
                {author && <LcmFacepile persona={[author]} showTooltip={false} />}
                <div className="flex flex-col justify-center gap-1">
                    <div className="flex items-center gap-2">
                        <p className=" text-sm font-semibold leading-5 text-black">
                            {author?.meta.firstName} {author?.meta.lastName}
                        </p>
                        <p className="text-xs font-normal leading-5 text-slate-400">{formattedDate}</p>
                    </div>

                    {isOpedEditInput ? (
                        <MentionsComponent
                            isEditMode={true}
                            buttons={mentionsInputButtons}
                            commentText={text}
                            commentId={commentId}
                            className="mt-1 w-[250px] rounded-xl placeholder-black"
                            styles={{
                                "&multiLine": {
                                    input: {
                                        padding: 9,
                                        overflow: "hidden",
                                        border: "none",
                                    },
                                    highlighter: {
                                        padding: 9,
                                        boxSizing: "border-box",
                                        paddingBottom: 50,
                                    },
                                },
                                background: "#F4F4F5",
                            }}
                        />
                    ) : (
                        <p className="text-sm font-normal leading-5 text-black">
                            {formatCommentWithMentionsAndLinks(text)}
                        </p>
                    )}
                </div>
            </div>

            {commentUserId === currentUserId && (
                <DropdownEditDelete
                    onDeleteClicked={toggleDialogDelete}
                    onEditClicked={toggleEditInputHandler}
                ></DropdownEditDelete>
            )}
            {isDeleteDialogOpen && (
                <div className="absolute">
                    <CommentDeleteDialog
                        onCancel={toggleDialogDelete}
                        onOk={async () => {
                            if (currentUserId === commentUserId) {
                                await deleteComment(commentId);
                            }
                        }}
                    />
                </div>
            )}
        </div>
    );
}
