import { decimalToHex } from "@/components/utils/color/decimal-to-hex";
import { darkenColor, needsWhiteColor } from "@/model/GlobalHelper";
import { tradeImagesMap } from "@/components/Sidebar/TradeImages";
import { VCONST0 } from "@/model/DataModel";
import { cn } from "@/lib/utils";
import { IconButton } from "@/components/common/IconButton/IconButton";
import { X } from "lucide-react";
import * as React from "react";

export function TradeCard({
    id,
    color,
    icon,
    name,
    index = 0,
    onClick,
}: {
    id: number;
    color: number;
    icon: string;
    name: string;
    index: number;
    onClick: (element) => void;
}) {
    const bGColorHex = color !== undefined ? decimalToHex(color) : "white";
    const textColor = color !== undefined && needsWhiteColor(color) ? "text-white" : "text-black";
    const darkerBgColorHex = color !== undefined ? darkenColor(bGColorHex, 0.2) : "white";
    const svgIcon = tradeImagesMap.get(icon || VCONST0.tradeDefaultIcon);

    return (
        <div
            key={index}
            className="flex h-[30px] items-center gap-1 space-x-1 rounded-full p-1"
            style={{ backgroundColor: bGColorHex }}
        >
            <div
                className="flex items-center justify-center rounded-full"
                style={{
                    width: "22px",
                    height: "22px",
                    backgroundColor: darkerBgColorHex,
                }}
            >
                <img
                    src={svgIcon.img}
                    title={svgIcon.title}
                    className="pointer-events-none h-[14px] w-[14px] contrast-125"
                />
            </div>
            <span className={cn("text-xs font-medium leading-5", textColor)}>{name}</span>
            <IconButton
                key={"tradeBtn-" + index}
                icon={X}
                className="h-5 w-5 text-white contrast-125 hover:text-white"
                style={{ backgroundColor: darkerBgColorHex }}
                iconProps={{ size: 14 }}
                onClick={() => {
                    onClick(id);
                }}
            />
        </div>
    );
}
