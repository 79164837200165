import * as React from "react";
import { assert } from "../../../model/GlobalHelper";
import { fetch_master, getLog } from "../../../model/ApiHelper";
import { FrameworkHeader } from "../FrameworkHeader/FrameworkHeader";
//import { CollaboratorFormProps } from "./SharePopup";
import { Sub } from "../../Sub";
//import { SandboxPopup, SandboxPopupOptions } from "./SandboxPopup";
import { WorkerSession } from "../../../model/DataModel";
import { intl, localizeIfNeeded } from "../../GlobalHelperReact";
import * as fluentui from "@fluentui/react";
import { Spinner } from "@fluentui/react";
import { CanvasMeta } from "../Canvas/Canvas";
import DigitalPlanningBoard from "../DigitalPlanningBoard";
import { MainWorkerPipe } from "../../MainWorkerPipe";
import { FrameworkRightHelpAlertContract } from "../FrameworkHeader/FrameworkRightHelpAlertContract";
import { Button } from "@/components/ui/button";
import { useLCMD } from "@/app/LCMContext";
import video from "./cubic-rube-animated-v-17.webm";
import { useCallback } from "react";
import { openProject } from "@/components/FilesOverlay";
import { WebAppTelemetryFactory } from "@/app/services/WebAppTelemetry.service";

const labelStyle: React.CSSProperties = {
    fontSize: "0.8em",
    paddingTop: 10,
    color: "gray",
};

type FileData = {
    token: string;
    ts: number;
    name: string;
    color: string;
    start: string;
    end: string;
    shared: {
        [sub: string]: {
            role: string;
            trade?: number;
            status?: string;
        };
    };
    history: {
        id: string;
        ts: number;
        uploadedBy?: string;
        name?: string;
        description?: string;
        resource?: {
            id: string;
            token: string;
            filename?: string;
        };
        master_token?: string;
    }[];
};

type FileDataModel = {
    [projectId: string]: FileData;
};

type FilesProps = {
    worker: MainWorkerPipe;
    filesOverlay: boolean;
};

type FilesState = {
    uploadPopup: string | boolean;
    sharePopup: boolean;
    model: FileDataModel;
    view: string[];
    selected_project: string | null;
    pending: boolean | string;
    meta: CanvasMeta;
};

export class Files extends React.Component<FilesProps, FilesState> {
    state = {
        uploadPopup: false,
        sharePopup: false,
        model: null,
        view: null,
        selected_project: null,
        pending: false,
        meta: null,
    };

    private data: {
        commitedTS: number;
        commited: FileDataModel;
        pending: {
            apply: (model: FileDataModel) => void;
        }[];
    } = {
        commitedTS: 0,
        commited: {},
        pending: [],
    };

    private updateView() {
        const projects = this.data.commited;
        const view = Object.getOwnPropertyNames(projects);
        const model = view.reduce((ret, pid) => {
            const p = projects[pid];
            ret[pid] = p;
            if (p) {
                this.fetchMissingLog(pid);
            }
            return ret;
        }, {});
        this.setState(
            {
                model: model,
                view: view,
            },
            () => {},
        );
    }

    private setProject(pid: string, project: FileData) {
        assert(0 === this.data.pending.length); // apply pending ops... @TODO rollback
        Object.getOwnPropertyNames(project).forEach((key) => project[key] === undefined && delete project[key]); // remove undefines...
        const projects = this.data.commited;
        const instance: FileData = Object.assign(
            {},
            projects[pid] || {
                ts: 0,
                name: null,
                color: null,
                start: null,
                end: null,
                shared: {},
                history: [],
            },
            project,
        );
        projects[pid] = instance;
        this.updateView();
    }

    private _setState(name: string, value: any, cb?: () => void) {
        const state = {};
        state[name] = value;
        this.setState(state, cb);
    }

    private fetchMissingMaster(pid: string, lid: number) {
        const project = this.data.commited[pid];
        if (project) {
            const log = (project.history || [])[lid];
            if (log.master_token && !this.state[log.master_token]) {
                this._setState(log.master_token, new Date(), () => {
                    fetch_master(log.master_token, (error, result) => {
                        if (error) {
                            this._setState(log.master_token, error);
                        } else {
                            if (Array.isArray(result.sandbox)) {
                                const sbs = result.sandbox;
                                for (let i_sb = 0; i_sb < sbs.length; i_sb++) {
                                    const sb = sbs[i_sb];
                                    sb.db = sb.sid === result?.master?.db;
                                    sb.name = localizeIfNeeded(sb.name);
                                    /*
                                    if ((sb.name||"").startsWith("lcmd.")) {
                                        sb.name=intl.get(sb.name) || sb.name;
                                    }
                                    */
                                }
                                result.sandbox = sbs.filter((sb) => !(sb.ts < 0));
                            }
                            this._setState(log.master_token, result);
                        }
                    });
                });
            }
        }
    }

    private fetchMissingLog(pid: string) {
        const project = this.data.commited[pid];
        if (true && project) {
            project.history.forEach((id, i_id) => {
                let pending = null;
                if ("number" === typeof id) {
                    pending = {
                        id: id,
                        ts: id,
                    };
                } else {
                    //@TODO check ts...
                }
                if (pending) {
                    project.history[i_id] = pending;
                    getLog(project.token, pid, id as any, (error, result) => {
                        if (error) {
                            //@TODO
                        } else {
                            const _project = this.data.commited[pid];
                            const _history = _project?.history || [];
                            if (
                                i_id <= _history.length &&
                                pending === _project.history[i_id] &&
                                pending.id === result.id
                            ) {
                                const __history = _history.slice();
                                __history.splice(i_id, 1, {
                                    id: id as any,
                                    ts: id as any,
                                    uploadedBy: result?.file?.sub || undefined,
                                    name: result?.commit?.name || undefined,
                                    description: result?.commit?.message || undefined,
                                    resource: result?.file || undefined,
                                    master_token: result?.master || undefined,
                                });
                                this.setProject(pid, {
                                    token: undefined,
                                    ts: undefined,
                                    name: undefined,
                                    color: undefined,
                                    start: undefined,
                                    end: undefined,
                                    shared: undefined,
                                    history: __history,
                                });
                                this.fetchMissingMaster(pid, i_id);
                            } else {
                                console.warn("out of bound...");
                            }
                        }
                    });
                }
            });
        }
        return project;
    }

    componentDidMount(this: Files) {}

    componentDidUnMount(this: Files) {}

    onReload = function (this: Files) {
        //@TODO
    }.bind(this);

    render(this: Files) {
        return (
            <div className="FE">
                <FrameworkHeader
                    key={5}
                    activeView="folder"
                    filesOverlay={this.props.filesOverlay}
                    worker={this.props.worker}
                    right={[
                        <FrameworkRightHelpAlertContract key="frahc" {...this.props} synced={null} selected="folder" />,
                    ]}
                    metaData={this.state.meta}
                />
                <div style={{ backgroundColor: "#09090B" }}>
                    <div
                        className="relative mx-20 flex h-screen items-center justify-center"
                        style={{ width: "calc(100% - 420px)", left: "420px", gap: "30px" }}
                    >
                        <div className="flex flex-col gap-4" style={{ minWidth: "350px", objectFit: "contain" }}>
                            <p
                                style={{
                                    fontSize: "22px",
                                    fontWeight: 300,
                                    lineHeight: "25.69px",
                                    textAlign: "left",
                                    color: "#FFFFFF",
                                }}
                            >
                                {this.props.worker?.userMeta?.firstName}{" "}
                                <span
                                    style={{
                                        fontSize: "22px",
                                        fontWeight: 600,
                                        lineHeight: "25.69px",
                                        textAlign: "left",
                                        color: "#FFFFFF",
                                    }}
                                >
                                    {this.props.worker?.userMeta?.lastName}
                                </span>
                            </p>
                            <p
                                style={{
                                    fontSize: "45px",
                                    fontWeight: 600,
                                    lineHeight: "52.55px",
                                    textAlign: "left",
                                    color: "#FFFFFF",
                                }}
                            >
                                {intl.get("welcomeScreen.title")}
                            </p>
                            <NewProjectButton {...this.props} />
                        </div>
                        <div>
                            <video src={video} autoPlay muted style={{ height: "374px" }} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function NewProjectButton(props: FilesProps) {
    const LCMD = useLCMD();

    const onUploadDone = useCallback(
        (details) => {
            LCMD.showDialog("dialog.project.upload", null);
            props.worker.dispatchMessage(["framework", "reload"]);

            details &&
                details.project_token &&
                getLog(details.project_token, details.pid, 0, (error, log) => {
                    const session: WorkerSession = {
                        pid: details.pid,
                        pid_ts: 0,
                        project_token: details.project_token,
                        resource: log.file,
                        master_token: undefined,
                        sandbox: Date.now() as any,
                        role: { role: "admin" },
                    };
                    openProject(LCMD.worker, session, false);
                });
        },
        [LCMD],
    );
    return (
        <Button
            style={{ backgroundColor: "#60D4C5", color: "black", width: "170px" }}
            data-userpilot-id="filesOverlay-addProject"
            onClick={() => {
                WebAppTelemetryFactory.trackEvent("new-project-green-button-clicked");
                LCMD.showDialog("dialog.project.upload", {
                    view: "details",
                    onDone: onUploadDone,
                    auth_token: LCMD.worker.auth?.auth_token || null,
                    auth_warmup: LCMD.worker.warmup_result?.req ? (LCMD.worker.warmup_result as { req: string }) : null,
                });
            }}
        >
            {intl.get("legacyProjectDialog.details.title")}
        </Button>
    );
}
