import { WebAppTelemetryFactory } from "@/app/services/WebAppTelemetry.service";
import { DropdownMenuItemProps } from "@radix-ui/react-dropdown-menu";
import { Copy, ExternalLink, File, FlipVertical, Option, Settings, Share } from "lucide-react";

const iconClassName = "w-4 h-5 self-center mr-2";

interface MoreButtonOption extends DropdownMenuItemProps {
    text: string;
}

export type DropdownOptionsProps = {
    selected: string;
    moreButtonOptions: MoreButtonOption[];
    handleCloneProject: () => void;
    handleShowBaselineDialog: () => void;
    handleShowChanges: () => void;
    showSettings: () => void;
    shareProject: () => void;
    handleLeaveProject: () => void;
};

export const getDropdownOptions = (props: DropdownOptionsProps) => {
    const {
        selected,
        moreButtonOptions,
        handleCloneProject,
        handleShowBaselineDialog,
        handleShowChanges,
        showSettings,
        shareProject,
        handleLeaveProject,
    } = props;

    const userSettingButtonOptions = [
        {
            key: "baseline",
            icon: <FlipVertical className={iconClassName} />,
            onClick: handleShowBaselineDialog,
            separate: true,
            hotkey: undefined,
        },
        // {
        //     key: "history",
        //     icon: <AlignJustify className={iconClassName} />,
        //     onClick: handleShowChanges,
        //     separate: true,
        //     hotkey: "⌘R",
        // },
        {
            key: "export",
            icon: <File className={iconClassName} />,
            options: moreButtonOptions,
            separate: true,
            hotkey: undefined,
        },
        /* No functionality found
        {
            key: "import",
            icon: <Import className={iconClassName} />,
            separate: false,
        },
             */
        {
            key: "clone",
            icon: <Copy className={iconClassName} />,
            onClick: handleCloneProject,
            separate: false,
            hotkey: undefined,
        },
        {
            key: "props",
            icon: <Settings className={iconClassName} />,
            onClick: showSettings,
            separate: false,
            hotkey: undefined,
        },
        {
            key: "share",
            icon: <Share className={iconClassName} />,
            onClick: () => {
                WebAppTelemetryFactory.trackEvent("share-project", { where: "dropdown" });
                shareProject();
            },
            separate: false,
            hotkey: undefined,
        },
        {
            key: "unlink",
            icon: <ExternalLink className={iconClassName} />,
            onClick: handleLeaveProject,
            separate: false,
            hotkey: undefined,
        },
    ];

    return userSettingButtonOptions;
};
