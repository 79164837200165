import React, { FormEvent, Fragment, useRef } from "react";
import { Button } from "@/components/ui/button";
import { Plus } from "lucide-react";

type FileButtonProps = {
    accept?: string;
    disabled?: boolean;
    text?: string;
    onChange?: (event: FormEvent<HTMLButtonElement>) => void;
};

export function FileButtonPrimary({ accept, disabled, text, onChange }: FileButtonProps) {
    const inputRef = useRef<HTMLInputElement>();
    function clickInputFile() {
        inputRef.current.click();
    }

    return (
        <>
            <Button disabled={disabled} onClick={clickInputFile}>
                <Plus size={16} className="mr-2" />
                {text}
            </Button>
            <input
                accept={accept}
                ref={inputRef}
                type="file"
                id="file-selector"
                style={{ display: "none" }}
                onChange={onChange}
            />
        </>
    );
}
