import * as React from "react";
import { intl, FilesOverlayProps, FilesOverlayProps_Project, WorkerSession } from "lcmd2framework";
import { useLCMD } from "@/app/LCMContext";
import { useCallback, useState } from "react";
import { LastOpenedProjectsService } from "@/app/services/LastOpenedProjects.service";
import { ScrollArea } from "./ui/scroll-area";
import { SearchBar } from "./common/SearchBar/SearchBar";
import { Folder, FolderOpen, LogOut, PlusCircle, UploadCloud } from "lucide-react";
import { Button } from "./ui/button";
import { cn } from "@/lib/utils";
import { Separator } from "./ui/separator";
import { Skeleton } from "./ui/skeleton";
import { LCMDTooltip } from "./common/Tooltip/LCMDTooltip";
import DigitalPlanningBoard from "@/legacy/components/DigitalPlanningBoard";
import { MainWorkerPipe } from "@/legacy/MainWorkerPipe";
import { getLog } from "@/model/ApiHelper";
import { MoreButtonDropdown, MenuMoreProps } from "@/components/MoreButtonDropdown/MoreButtonDropdown";
import { WebAppTelemetryFactory } from "@/app/services/WebAppTelemetry.service";
import { AlertDialog } from "@/components/common/AlertDialog/AlertDialogV2";
import { EmptyView } from "@/components/common/EmptyView";
import noProjects from "@/assets/img/emptyState/noProjects.png";

type LCMDFilesOverlayProps = FilesOverlayProps & {
    onSearch: (searchString: string) => void;
    value: string;
};

export function openProject(worker: MainWorkerPipe, session: WorkerSession, dailyBoard: boolean) {
    worker.dispatchMessage(["framework", "files", false]);
    DigitalPlanningBoard.openProject(worker, session, dailyBoard);
}

function FilesOverlayHeader({
    onUploadDone: handleUploadDone,
    onSearch: handleSearch,
    projects,
    value,
}: Pick<LCMDFilesOverlayProps, "onUploadDone" | "onSearch" | "projects" | "value">) {
    const LCMD = useLCMD();

    const onUploadDone = useCallback(
        (details) => {
            LCMD.showDialog("dialog.project.upload", null);
            handleUploadDone(details);

            details &&
                details.project_token &&
                getLog(details.project_token, details.pid, 0, (error, log) => {
                    const session: WorkerSession = {
                        pid: details.pid,
                        pid_ts: 0,
                        project_token: details.project_token,
                        resource: log.file,
                        master_token: undefined,
                        sandbox: Date.now() as any,
                        role: { role: "admin" },
                    };
                    openProject(LCMD.worker, { ...session, sandbox_name: log.commit.name }, false);
                });
        },
        [LCMD, handleUploadDone],
    );

    return (
        <div className="border-box flex max-w-full items-center gap-2 p-4">
            <SearchBar
                onChange={handleSearch}
                placeholder={intl.get("projects.title")}
                value={value}
                className="w-full"
            />
            <div className="flex">
                <LCMDTooltip
                    key="uploadScheduleTooltip"
                    asChild
                    text={intl.get("projects.uploadSchedule")}
                    side="bottom"
                >
                    <Button
                        variant="ghost"
                        size="icon"
                        className="h-10 w-10"
                        data-userpilot-id="filesOverlay-uploadProject"
                        data-userflow-id="ProjectUpload"
                        onClick={() => {
                            WebAppTelemetryFactory.trackEvent("project-import-button-clicked");
                            LCMD.showDialog("dialog.project.upload", {
                                view: "upload",
                                projects: projects,
                                onDone: onUploadDone,
                                auth_token: LCMD.worker.auth?.auth_token || null,
                                auth_warmup: LCMD.worker.warmup_result?.req
                                    ? (LCMD.worker.warmup_result as { req: string })
                                    : null,
                            });
                        }}
                    >
                        <UploadCloud size="16" />
                    </Button>
                </LCMDTooltip>
                <LCMDTooltip key="addProject" asChild text={intl.get("projects.newProject")} side="bottom">
                    <Button
                        variant="ghost"
                        size="icon"
                        className="h-10 w-10"
                        data-userpilot-id="filesOverlay-addProject"
                        data-userflow-id="NewProject"
                        onClick={() => {
                            WebAppTelemetryFactory.trackEvent("new-project-plus-button-clicked");
                            LCMD.showDialog("dialog.project.upload", {
                                view: "details",
                                onDone: onUploadDone,
                                auth_token: LCMD.worker.auth?.auth_token || null,
                                auth_warmup: LCMD.worker.warmup_result?.req
                                    ? (LCMD.worker.warmup_result as { req: string })
                                    : null,
                            });
                        }}
                    >
                        <PlusCircle size="16" />
                    </Button>
                </LCMDTooltip>
            </div>
        </div>
    );
}

function ProjectEntry({
    onUploadDone: handleUploadDone,
    projects,
    onProjectEntryClick: handleProjectEntryClick,
    item,
}: Omit<FilesOverlayProps, "onClose" | "hasCloseButton"> & { item: FilesOverlayProps_Project }) {
    const LCMD = useLCMD();
    const [showUnlinkModal, setShowUnlinkModal] = useState(false);

    const openProject = () => "number" === typeof item?.pid_ts && handleProjectEntryClick(item);
    const moreMenuProps: MenuMoreProps[] = [
        {
            key: "more.open",
            text: intl.get("overview.cmd.options.openProject"),
            icon: <FolderOpen size={16} />,
            onClick: () => openProject(),
        },
        {
            key: "more.delete",
            text: intl.get("overview.cmd.options.unLinkProject"),
            icon: <LogOut size={16} />,
            onClick: () => setShowUnlinkModal(true),
        },
    ];

    return (
        <>
            <div
                key={item.pid}
                data-is-focusable={true}
                data-userpilot-id={projects.slice(-1)[0].pid === item.pid ? "filesOverlay-newAddedProject" : null}
                onClick={() => openProject()}
                className={cn(
                    "flex cursor-pointer items-center rounded-lg p-2",
                    item.pid === LCMD.worker.nav.project
                        ? "bg-zinc-800 text-white"
                        : "text-zinc-400 transition ease-in-out hover:bg-gray-100 hover:text-zinc-800",
                )}
            >
                {"number" === typeof item?.pid_ts ? (
                    <>
                        <div className="flex flex-1 gap-2">
                            <Folder size={20} />
                            <p
                                style={{ wordBreak: "break-word" }}
                                className={cn(
                                    "flex-1",
                                    item.pid === LCMD.worker.nav.project ? "text-white" : "text-zinc-800",
                                )}
                            >
                                {(item as FilesOverlayProps_Project).project?.project?.name ||
                                    intl.get("projects.unnamedProject")}
                            </p>
                        </div>
                        <div className="flex-2 self-start" onClick={(ev) => ev.stopPropagation()}>
                            <MoreButtonDropdown menuItems={moreMenuProps} />
                        </div>
                    </>
                ) : (
                    <>
                        <Skeleton className="h-5 w-5" />
                        <Skeleton className="h-4 w-[250px]" />
                    </>
                )}
            </div>
            {showUnlinkModal && (
                <AlertDialog
                    open={true}
                    onOpenChange={() => setShowUnlinkModal(false)}
                    title={intl.get("overview.cmd.unlink.alert.title")}
                    subText={intl.get("overview.cmd.unlink.alert.subText")}
                    okButtonText={intl.get("overview.cmd.unlink.alert.accept")}
                    cancelButtonText={intl.get("overview.cmd.unlink.alert.cancel")}
                    onOk={() => {
                        WebAppTelemetryFactory.trackEvent("project-unsubscribe-pp");
                        LCMD.unlinkProject(item.pid, LCMD.worker.auth?.auth_result.sub, (error, result) => {
                            if (result && LCMD.worker.nav.project === item.pid) {
                                location.reload();
                            } else {
                                handleUploadDone({});
                            }
                        });
                        setShowUnlinkModal(false);
                        WebAppTelemetryFactory.trackEvent("project-unsubscribed");
                    }}
                />
            )}
        </>
    );
}

export function FilesOverlay({
    onUploadDone: handleUploadDone,
    projects,
    onProjectEntryClick: handleOnProjectEntryClick,
    loadingProjects = false,
}: FilesOverlayProps) {
    const LCMD = useLCMD();

    const onUploadDone = useCallback(
        (details) => {
            LCMD.showDialog("dialog.project.upload", null);
            handleUploadDone(details);

            details &&
                details.project_token &&
                getLog(details.project_token, details.pid, 0, (error, log) => {
                    const session: WorkerSession = {
                        pid: details.pid,
                        pid_ts: 0,
                        project_token: details.project_token,
                        resource: log.file,
                        master_token: undefined,
                        sandbox: Date.now() as any,
                        role: { role: "admin" },
                    };
                    openProject(LCMD.worker, { ...session, sandbox_name: log.commit.name }, false);
                });
        },
        [LCMD, handleUploadDone],
    );

    const [searchString, setSearchString] = useState("");

    const sortedProjects = [...projects].sort((a, b) =>
        a.project?.project?.name?.toLowerCase().localeCompare(b.project?.project?.name?.toLowerCase()),
    );
    const lastOpenedProjects = sortedProjects.length > 3 ? get3LastOpenedProjects(sortedProjects) : [];

    const filterProjects = (projects) =>
        projects.filter((project) => {
            return (
                searchString == "" ||
                (project as FilesOverlayProps_Project).project?.project?.name
                    ?.toLowerCase()
                    .includes(searchString.toLowerCase())
            );
        });

    const filteredLastOpenedProjects = filterProjects(lastOpenedProjects);
    const filteredSortedProjects = filterProjects(sortedProjects);

    function get3LastOpenedProjects(sortedProjects) {
        const lastOpenedProjectIds = LastOpenedProjectsService.getLastOpenedProjectIds();
        const lastOpenedProjects = [];

        for (const projectId of lastOpenedProjectIds) {
            const project = sortedProjects.find((x) => x.pid === projectId);
            if (project) {
                lastOpenedProjects.push(project);
                sortedProjects.splice(sortedProjects.indexOf(project), 1);
            }

            if (lastOpenedProjects.length >= 3) {
                break;
            }
        }

        return lastOpenedProjects;
    }

    function handleSearch(searchString: string) {
        setSearchString(searchString);
    }

    const translationText = () => {
        return (
            <>
                {intl.get("projects.noProjects.text")}
                <br />
                <a
                    className="cursor-pointer text-indigo-700 underline"
                    onClick={() => {
                        WebAppTelemetryFactory.trackEvent("project-sidebar-clicked");
                        LCMD.showDialog("dialog.project.upload", {
                            view: "upload",
                            projects: projects,
                            onDone: onUploadDone,
                            auth_token: LCMD.worker.auth?.auth_token || null,
                            auth_warmup: LCMD.worker.warmup_result?.req
                                ? (LCMD.worker.warmup_result as { req: string })
                                : null,
                        });
                    }}
                >
                    {intl.get("projects.noProjects.link")}
                </a>
            </>
        ) as unknown as string;
    };

    return (
        <div className="overflow-hidden" data-userflow-id="projektliste" onClick={(ev) => ev.stopPropagation()}>
            <FilesOverlayHeader
                onSearch={handleSearch}
                value={searchString}
                onUploadDone={handleUploadDone}
                projects={projects}
            />
            <ScrollArea
                className="absolute inset-0 h-[calc(100vh-160px)] p-4"
                data-userpilot-id="filesOverlay-projectPanel"
            >
                {lastOpenedProjects.length > 0 && (
                    <div className="pb-4">
                        <div className="mb-4 text-left text-sm font-semibold leading-5 tracking-normal text-zinc-950">
                            {intl.get("projects.recentlySeen")}
                        </div>
                        <div className="flex flex-col gap-1 pb-3">
                            {filteredLastOpenedProjects?.map((project) => (
                                <ProjectEntry
                                    key={project.pid}
                                    onUploadDone={handleUploadDone}
                                    projects={projects}
                                    onProjectEntryClick={handleOnProjectEntryClick}
                                    item={project}
                                />
                            ))}
                        </div>
                        <Separator orientation="horizontal" className="my-4" />
                    </div>
                )}
                <div className="flex flex-col gap-1">
                    {filteredSortedProjects.length > 0 ? (
                        <>
                            <div className="mb-4 text-left text-sm font-semibold leading-5 tracking-normal text-zinc-950">
                                {intl.get("projects.all")}
                            </div>
                            {filteredSortedProjects?.map((project) => (
                                <ProjectEntry
                                    key={project.pid}
                                    onUploadDone={handleUploadDone}
                                    projects={projects}
                                    onProjectEntryClick={handleOnProjectEntryClick}
                                    item={project}
                                />
                            ))}
                        </>
                    ) : (
                        !loadingProjects && (
                            <EmptyView
                                size="l"
                                imgSrc={noProjects}
                                header={intl.get("projects.noProjectsHeader")}
                                text={translationText()}
                                buttonText={intl.get("projects.createProject")}
                                onButtonClicked={() => {
                                    WebAppTelemetryFactory.trackEvent("new-project-sidepanel-button-clicked");
                                    LCMD.showDialog("dialog.project.upload", {
                                        view: "details",
                                        onDone: onUploadDone,
                                        auth_token: LCMD.worker.auth?.auth_token || null,
                                        auth_warmup: LCMD.worker.warmup_result?.req
                                            ? (LCMD.worker.warmup_result as { req: string })
                                            : null,
                                    });
                                }}
                            />
                        )
                    )}
                </div>
            </ScrollArea>
        </div>
    );
}
