import { FlipVertical } from "lucide-react";

type ProcessTooltipBaselineDateProps = {
    value: string;
    isDifferentFromCurrent?: boolean;
};

export function ProcessTooltipBaselineDate({ value, isDifferentFromCurrent }: ProcessTooltipBaselineDateProps) {
    return (
        <div className={`flex items-center text-[#818cf8] ${isDifferentFromCurrent ? "line-through" : ""}`}>
            <FlipVertical size={14} />
            <span className="ml-1 text-[10px]">{value}</span>
        </div>
    );
}
