export function TooltipShimmer() {
    return (
        <div className="mx-auto min-h-[90px] max-w-xs  rounded-md p-2 shadow-lg">
            <div className="flex animate-pulse items-center space-x-3">
                {/* Shimmer circle */}
                <div className="relative h-10 w-10 overflow-hidden rounded-full bg-gray-700">
                    <div className="animate-shimmer absolute inset-0 bg-gradient-to-r from-transparent via-white/20 to-transparent" />
                </div>

                {/* Shimmer text lines */}
                <div className="flex-1 space-y-3">
                    <ShimmerLine />
                    <ShimmerLine />
                </div>
            </div>

            {/* Shimmer progress bar and dates */}
            <div className="mt-2 animate-pulse">
                <ShimmerLine />
                <div className="mt-2 flex justify-between">
                    <ShimmerLine width="w-1/4" />
                    <ShimmerLine width="w-1/4" />
                </div>
            </div>
        </div>
    );
}

function ShimmerLine({ width = "w-full" }: { width?: string }) {
    return (
        <div className={`relative h-2 overflow-hidden rounded-md bg-gray-700 ${width}`}>
            <div className="animate-shimmer absolute inset-0 bg-gradient-to-r from-transparent via-white/20 to-transparent" />
        </div>
    );
}
