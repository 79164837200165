import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { MessageDialog } from "../common/MessageDialog/MessageDialog";
import { intl } from "@/legacy/GlobalHelperReact";
import { UserAlreadyConfirmedError, AuthClient } from "@/app/services/AuthClient.service";
import { Paths } from "../../types/routes";
import { WebAppTelemetryFactory } from "@/app/services/WebAppTelemetry.service";

export type ConfirmEmailProps = {
    onLogin: (sub: string, authToken: string, email: string, registered: boolean) => void;
};

export function ConfirmEmail({ onLogin }: ConfirmEmailProps) {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");

    const [open] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [isSuccess, setIsSuccess] = useState(false);

    const [message, setMessage] = useState("");
    const [buttonText, setButtonText] = useState(intl.get("LcmdLogin.ConfirmEmail.ConfirmingButton"));

    const [sub, setSub] = useState("");
    const [authToken, setAuthToken] = useState("");

    useEffect(() => {
        async function confirm() {
            try {
                const result = await AuthClient.confirmUser(token);
                setSub(result.sub);
                setAuthToken(result.authToken);
                setIsSuccess(true);
                WebAppTelemetryFactory.trackEvent("open-application-clicked");
                setMessage(intl.get("LcmdLogin.ConfirmEmail.SuccessfullyConfirmed"));
                setButtonText(intl.get("LcmdLogin.ConfirmEmail.SuccessButtonText"));
            } catch (error) {
                setIsSuccess(false);
                setButtonText(intl.get("LcmdLogin.ConfirmEmail.ErrorButtonText"));

                if (error instanceof UserAlreadyConfirmedError) {
                    setMessage(intl.get("LcmdLogin.ConfirmEmail.AlreadyConfirmedError"));
                } else {
                    setMessage(intl.get("LcmdLogin.ConfirmEmail.GeneralError"));
                }
            } finally {
                setIsLoading(false);
            }
        }
        confirm();
    }, []);

    function onButtonClick() {
        if (isSuccess) {
            //todo: hack for getting the email, should be sent from backend!!!
            onLogin(sub, authToken, getEmailFromJWT(token), true);
        } else {
            navigate(Paths.HOME);
        }
    }

    function getEmailFromJWT(token: string): string{
        const base64 = token.split(".")[1];
        if(base64){
            const userObj = JSON.parse(atob(base64));
            if(userObj.email){
                 return userObj.email;
            }else{
                throw new Error("missing email")
            }
        }else{
            throw new Error("missing email")
        }
    }

    return (
        <MessageDialog
            open={open}
            title={intl.get("LcmdLogin.ConfirmEmail.Title")}
            message={message}
            buttonText={buttonText}
            buttonLoading={isLoading}
            onButtonClick={onButtonClick}
        ></MessageDialog>
    );
}
