export function calculateDayDifference({
    startDate1,
    endDate1,
    startDate2,
    endDate2,
}: {
    startDate1: number;
    endDate1: number;
    startDate2: number;
    endDate2: number;
}): number {
    if (startDate1 && endDate1 && startDate2 && endDate2) {
        const processStartDate = new Date(startDate1);
        const processEndDate = new Date(endDate1);
        const baselineStartDate = new Date(startDate2);
        const baselineEndDate = new Date(endDate2);

        const lengthDate1 = Math.ceil((processEndDate.getTime() - processStartDate.getTime()) / (1000 * 60 * 60 * 24));
        const lengthDate2 = Math.ceil(
            (baselineEndDate.getTime() - baselineStartDate.getTime()) / (1000 * 60 * 60 * 24),
        );

        const diffTime = processStartDate.getTime() - baselineStartDate.getTime();
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        return diffDays + (lengthDate1 - lengthDate2);
    }

    return undefined;
}
