import * as React from "react";
import { useNavigate } from "react-router-dom";
import { ModalAssetHeader } from "../../ModalAsset";
import {
    CheckboxVisibility,
    DefaultButton,
    DetailsList,
    DetailsRowCheck,
    IColumn,
    Persona,
    PrimaryButton,
    SearchBox,
    SelectionMode,
} from "@fluentui/react";
import { ModalAssetFooter } from "../../ModalAsset/ModalAssetFooter";
import { useEffect, useState } from "react";
import { useLCMD } from "../../../../app/LCMContext";
import { intl } from "@/legacy/GlobalHelperReact";
import { RouteNames } from "../filter.interface";
import { useToDoModalFilterStore } from "../../../../app/store/toDoModalFilterStore";
import { styles } from "../filterModalStyles";
import { User, usersFilter } from "../usersFilter";
import { WebAppTelemetryFactory } from "@/app/services/WebAppTelemetry.service";

export function RaisedByFilter() {
    const LCMD = useLCMD();
    const { filterData, setFilterData } = useToDoModalFilterStore();
    const [allUser, setAllUser] = useState<User[]>([]);
    const [users, setUsers] = useState<User[]>([]);
    const [selectedItems, setSelectedItems] = useState<Map<number, any>>(new Map());
    const navigate = useNavigate();

    useEffect(() => {
        const tempMap = new Map();
        filterData?.raisedByFilterData?.forEach((obj) => {
            tempMap.set(obj.id, obj);
        });
        setSelectedItems(tempMap);
    }, [filterData]);

    LCMD.useProjectCollaboratorsEffect(
        null,
        (error, data) => {
            if (error) {
                console.error(error);
            }
            const tmpUsers: User[] = [];
            data.forEach((d) => {
                tmpUsers.push({
                    id: d.data.sub,
                    email: d.personaName,
                    fullName: d.data?.meta?.lastName ? d.data?.meta?.firstName + " " + d.data?.meta?.lastName : null,
                    firstName: d.data?.meta?.firstName,
                    lastName: d.data?.meta?.lastName,
                });
            });
            setUsers(tmpUsers);
            setAllUser(tmpUsers);
        },
        [],
    );

    const columns = [
        {
            key: "name",
            name: intl.get("ModalToDoFilter.RaisedByFilter.columnName"),
            fieldName: "fullName",
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
        },
        {
            key: "email",
            name: intl.get("ModalToDoFilter.RaisedByFilter.columnEMail"),
            fieldName: "email",
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
        },
    ];

    function headerTitle() {
        return (
            <>
                {intl.get("ModalToDoFilter.RaisedByFilter.filter")} /{" "}
                {intl.get("ModalToDoFilter.RaisedByFilter.createdBy")}
            </>
        );
    }

    function renderItemColumn(
        item: { key: string; name: string; value: string },
        index: number | undefined,
        column: IColumn | undefined,
    ) {
        if (typeof column === "undefined") {
            return false;
        }
        const style = {
            fontSize: "14px",
            display: "flex",
        };
        const fieldContent = item[column.fieldName];
        switch (column.key) {
            case "name":
                return (
                    <div style={style}>
                        <Persona text={fieldContent} size={10} />
                    </div>
                );
            default:
                return <div style={style}>{fieldContent}</div>;
        }
    }

    function footerLeftContent() {
        return (
            <DefaultButton
                styles={{
                    root: { border: "none" },
                    rootDisabled: { backgroundColor: "transparent" },
                }}
                disabled={!selectedItems.size}
                text={intl.get("ModalToDoFilter.RaisedByFilter.clearFilters")}
                iconProps={{ iconName: "Delete" }}
                onClick={() => {
                    setUsers([...users]);
                    setSelectedItems(new Map());
                }}
            />
        );
    }

    function footerRightContent() {
        return (
            <>
                {selectedItems.size} {intl.get("ModalToDoFilter.RaisedByFilter.selected")}
                <PrimaryButton
                    text={intl.get("ModalToDoFilter.RaisedByFilter.done")}
                    styles={{ root: { marginLeft: 10 } }}
                    onClick={() => {
                        setFilterData({
                            ...filterData,
                            raisedByFilterData: Array.from(selectedItems, ([name, value]) => value),
                        });
                        navigate(RouteNames.HOME);
                        if (selectedItems.size > 0) {
                            WebAppTelemetryFactory.trackEvent('todo-raisedby-filter-applied');
                        }
                    }}
                />
            </>
        );
    }

    function onChangeText(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string) {
        setUsers(text ? usersFilter(text, allUser) : allUser);
    }

    return (
        <>
            <ModalAssetHeader
                onBackButtonChange={() => {
                    navigate(RouteNames.HOME);
                }}
                title={headerTitle()}
            />

            <div className={styles.body}>
                <div className={styles.searchField}>
                    <SearchBox
                        placeholder={intl.get("ModalToDoFilter.RaisedByFilter.search")}
                        onChange={onChangeText}
                    />
                </div>
                <DetailsList
                    items={users}
                    columns={columns}
                    onRenderItemColumn={renderItemColumn}
                    setKey="set"
                    compact={true}
                    checkboxVisibility={CheckboxVisibility.always}
                    selectionMode={SelectionMode.multiple}
                    selectionPreservedOnEmptyClick={true}
                    enterModalSelectionOnTouch={true}
                    onRenderRow={(propsRow, defaultRenderRow) => {
                        propsRow.onRenderDetailsCheckbox = (propsRowCheck, defaultRender1) => {
                            propsRowCheck.checked = selectedItems.get(propsRow.item.id);
                            return (
                                <DetailsRowCheck
                                    canSelect={true}
                                    isVisible={true}
                                    compact={true}
                                    selected={propsRowCheck.checked}
                                    onClick={() => {
                                        setUsers([...users]);
                                        if (selectedItems.get(propsRow.item.id)) {
                                            selectedItems.delete(propsRow.item.id);
                                        } else {
                                            selectedItems.set(propsRow.item.id, propsRow.item);
                                        }
                                        setSelectedItems(selectedItems);
                                    }}
                                />
                            );
                        };

                        return defaultRenderRow({ ...propsRow });
                    }}
                />
            </div>

            <ModalAssetFooter leftContent={footerLeftContent()} rightContent={footerRightContent()} />
        </>
    );
}
