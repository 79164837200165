import * as React from "react";
import { useEffect, useState } from "react";
import { Chip, HTMLElementEvent, ItemPriority, ItemState, ItemType, ToDoProps, ToDoView } from "./interface";
import { intl } from "lcmd2framework";
import { usePersonaChips } from "../hooks/usePersonaChips.hook";
import { useLCMD } from "@/app/LCMContext";
import { useRBACHook } from "../hooks/useRBAC.hook";
import { useT3RolesHook } from "../hooks/useT3Roles.hook";
import { getCurrentLanguage } from "../utils/date/locale";
import { UTCDatePickerV2 } from "@/legacy/GlobalHelperFluentUI";
import { LcmdModal2 } from "@/components/common/LcmModal2";
import { Button } from "@/components/ui/button";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import { Input } from "@/components/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { TagsMultiSelect } from "@/components/ui/tags-multi-select";
import { WebAppTelemetryFactory } from "@/app/services/WebAppTelemetry.service";
import { cva } from "class-variance-authority";
import { cn } from "@/lib/utils";

export type EditViewProps = {
    todos?: ToDoProps[];
    isOpen: boolean;
    onCloseEditView: () => void;
    createItemType?: ItemType;
};

export function ToDoEditView({ todos = [], isOpen, onCloseEditView, createItemType }: EditViewProps) {
    const [modelTodo, setModelTodo] = useState<ToDoView>({} as any);
    const [isValid, setValidity] = useState<boolean>(false);
    const [showOriginalDeadlineDate, setShowOriginalDeadlineDate] = useState<boolean>(false);
    const [showOriginalIdentifiedDate, setShowOriginalIdentifiedDate] = useState<boolean>(false);
    const [showOriginalNeededDate, setShowOriginalNeededDate] = useState<boolean>(false);
    const [showOriginalResolvedDate, setShowOriginalResolvedDate] = useState<boolean>(false);
    const [originalDeadlineDate, setOriginalDeadlineDate] = useState<string>("");
    const [originalIdentifiedDate, setOriginalIdentifiedDate] = useState<string>("");
    const [originalNeededDate, setOriginalNeededDate] = useState<string>("");
    const [originalResolvedDate, setOriginalResolvedDate] = useState<string>("");
    const hasMixedProcessIds =
        todos.length > 1 && !todos.every((todoItem) => todoItem.process.pid === todos[0].process.pid);
    const allChips = usePersonaChips();
    const LCMD = useLCMD();
    const [rights, setRights] = useState({ role: "", editFields: null });
    const rbacData = useRBACHook();
    const canEdit = useT3RolesHook();

    useEffect(() => {
        if (rbacData.isLoading) {
            return;
        }
        if (rbacData.isError) {
            //TODO: evtl. console.error(error)
            return;
        }
        const fields =
            rbacData.data.role == "user" && rbacData.data.rbac?.rules?.[createItemType]?.properties
                ? rbacData.data.rbac.rules[createItemType].properties
                : null;

        setRights({ role: rbacData.data.role, editFields: fields });
    }, [rbacData.data]);

    useEffect(() => {
        const mixedToDo: ToDoView = {
            issue: [],
            id: [],
            status: [],
            action: [],
            process: [],
            trade: [],
            type: [],
            area: [],
            priority: [],
            deadline: [],
            raisedBy: [],
            responsible: [],
            actionBy: [],
            identified: [],
            needed: [],
            resolved: []
        };
        todos.forEach((todo) => {
            mixedToDo.issue.push(todo.issue);
            mixedToDo.id.push(todo.id);
            mixedToDo.status.push(todo.status);
            mixedToDo.action.push(todo.action);
            mixedToDo.process.push(todo.process.name);
            mixedToDo.trade.push(todo.trade);
            mixedToDo.type.push(todo.type);
            mixedToDo.area.push(todo.process?.areaPath?.map((p) => p.name).join(" > "));
            mixedToDo.priority.push(todo.priority);
            mixedToDo.deadline.push(todo.deadline);
            mixedToDo.raisedBy.push([]);
            mixedToDo.responsible.push([]);
            mixedToDo.actionBy.push(todo.actionBy);
            mixedToDo.identified.push(todo.identified);
            mixedToDo.needed.push(todo.needed);
            mixedToDo.resolved.push(todo.resolved);
        });

        setModelTodo(mixedToDo);
    }, []);

    useEffect(() => {
        if (allChips?.length === 0) {
            return;
        }
        const raisedByChips: Array<Chip[]> = [];
        const responsibleChips: Array<Chip[]> = [];
        todos.forEach((todo: ToDoProps) => {
            const selectedRaisedByChips = todo.raisedBy
                .map((userId) => allChips.find((chip) => chip.key === userId))
                .filter(Boolean);
            raisedByChips.push(selectedRaisedByChips);

            const selectedResponsibleChips = todo.responsible
                .map((userId) => allChips.find((chip) => chip.key === userId))
                .filter(Boolean);
            responsibleChips.push(selectedResponsibleChips);
        });

        setModelTodo({ ...modelTodo, raisedBy: raisedByChips, responsible: responsibleChips });
    }, [allChips]);

    useEffect(() => {
        setValidity(Boolean(modelTodo.issue?.toString()?.length));
    }, [modelTodo]);

    function checkFieldDisabled(fieldName: string) {
        //TODO: define editFields for Action Items
        if (createItemType == ItemType.ACTION_ITEM) {
            return false;
        }

        if (canEdit) {
            return false;
        }

        if (rights.role == "readonly") {
            return true;
        }

        //case user (+ extended rights)
        return !(rights.editFields !== null && rights.editFields[fieldName]);
    }

    function onChangeTextField(e: HTMLElementEvent<any, any>, propertyName: string) {
        setModelTodo({ ...modelTodo, [propertyName]: modelTodo[propertyName].map((t) => e.target.value) });
    }

    function onPickerChange(items: Chip[], pickerName: "raisedBy" | "responsible") {
        const updatedFields = { ...modelTodo, [pickerName]: modelTodo[pickerName].map(() => items) };

        if (pickerName === "responsible") updatedFields.identified = modelTodo.status.map(() => +new Date());

        setModelTodo(updatedFields);
    }

    function handleDateSelected(
        date: Date,
        showOriginalFlag: boolean,
        setShowOriginalFlag: (val: boolean) => void,
        printField: any,
        setOriginalField: (val: string) => void,
        getDateValueFn: (field: string) => any,
        fieldKey: string,
    ) {
        setShowOriginalFlag(true);
        setModelTodo((prevModelTodo) => {
            if (!showOriginalFlag) {
                const printDate = print(printField);
                if (printDate !== "Mixed") {
                    const dateVal = getDateValueFn(fieldKey);
                    const dateOutput = dateVal
                        ? dateVal.toUTCFormatString(getCurrentLanguage(), {
                            day: "2-digit",
                            month: "long",
                            year: "numeric",
                        })
                        : "";
                    setOriginalField(dateOutput);
                } else {
                    // Case: Mixed Dates
                    setOriginalField(printDate);
                }
            }
            return { ...prevModelTodo, [fieldKey]: prevModelTodo[fieldKey].map(() => date.getTime()) };
        });
    }

    function onSelectedDeadlineDate(date: Date) {
        handleDateSelected(
            date,
            showOriginalDeadlineDate,
            setShowOriginalDeadlineDate,
            modelTodo.deadline,
            setOriginalDeadlineDate,
            getDateValueByField,
            "deadline",
        );
    }

    function onSelectedIdentifiedDate(date: Date) {
        handleDateSelected(
            date,
            showOriginalIdentifiedDate,
            setShowOriginalIdentifiedDate,
            modelTodo.identified,
            setOriginalIdentifiedDate,
            getDateValueByField,
            "identified",
        );
    }

    function onSelectedNeededDate(date: Date) {
        handleDateSelected(
            date,
            showOriginalNeededDate,
            setShowOriginalNeededDate,
            modelTodo.needed,
            setOriginalNeededDate,
            getDateValueByField,
            "needed",
        );
    }

    function onSelectedResolvedDate(date: Date) {
        handleDateSelected(
            date,
            showOriginalResolvedDate,
            setShowOriginalResolvedDate,
            modelTodo.resolved,
            setOriginalResolvedDate,
            getDateValueByField,
            "resolved",
        );
    }

    function priorityChanged(value: string) {
        setModelTodo({ ...modelTodo, priority: modelTodo.priority.map(() => +value) });
    }

    function stateSelected(value: string) {
        if (value === ItemState.DONE) {
            setModelTodo({
                ...modelTodo,
                status: modelTodo.status.map(() => ItemState.DONE),
                resolved: modelTodo.resolved.map(() => +new Date()),
            });
        } else {
            setModelTodo({ ...modelTodo, status: modelTodo.status.map(() => ItemState.OPEN) });
        }
    }

    function print<T>(v: T[]): T | string {
        if (!v) {
            return "";
        }

        if (v.length === 1 || sameValues(v)) {
            return v[0];
        }

        return "Mixed";
    }

    function printType<Type>(v: Type[]): string {
        const typeText = print(v).toString();
        let returnString = "";
        switch (typeText) {
            case "action": {
                returnString = intl.get("ToDoEditView.type.action");
                break;
            }
            case "stability": {
                returnString = intl.get("ToDoEditView.type.stability");
                break;
            }
            case "Mixed": {
                returnString = intl.get("ToDoEditView.mixed");
                break;
            }
        }

        return returnString;
    }

    function sameValues(arr: any[]): boolean {
        if (!arr) {
            return false;
        }
        return arr.every((val) => val === arr[0]);
    }

    function getDateValueByField(field: keyof typeof modelTodo): Date | undefined {
        const fieldValue = modelTodo[field];

        if (fieldValue?.length === 1 || sameValues(fieldValue)) {
            const value = fieldValue[0];

            if (typeof value === "string" || typeof value === "number") {
                return new Date(value);
            }
        }
    }

    function getPickerValue(pickerName: "raisedBy" | "responsible"): Chip[] {
        if (modelTodo[pickerName]?.length === 1) {
            return modelTodo[pickerName][0];
        }
        // todo: check if user the same and return value for Pickerfield
        if (modelTodo[pickerName]?.length > 1) {
            for (let i = 1; i < modelTodo[pickerName]?.length; i++) {
                if (modelTodo[pickerName][i] !== modelTodo[pickerName][i - 1]) {
                    return [];
                }
            }

            return modelTodo[pickerName] ? modelTodo[pickerName][0] : [];
        }
        return [];
    }

    function arrayEquals(a, b) {
        return (
            Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((val, index) => val === b[index])
        );
    }
    function save() {
        const properties: string[] = [
            "action",
            "issue",
            "trade",
            "type",
            "priority",
            "deadline",
            "raisedBy",
            "identified",
            "needed",
            "resolved",
            "responsible",
            "actionBy",
        ];
        let hasChanges = false;
        todos.forEach((t, i) => {
            //todo: @Dario: please only submit "obj" that are changed...
            const obj: unknown = {};
            properties.forEach((p) => {
                if (t[p] !== modelTodo[p][i] && !["responsible","raisedBy"].includes(p) ) {
                    hasChanges = true;
                }
                if (t[p] !== modelTodo[p][i] || (Boolean(t["id"]) === false && p === "deadline")) {
                    if (["raisedBy", "responsible"].includes(p)) {
                        if (arrayEquals(t[p], modelTodo[p][i])) {
                            return;
                        }
                        obj[p] = { value: modelTodo[p][i]?.map((c) => c.key) };
                    } else if (!["raisedBy", "responsible"].includes(p)) {
                        obj[p] = { value: modelTodo[p][i] };
                    }
                }
            });

            let status = null;
            if (t.status !== modelTodo.status[i]) {
                hasChanges = true;
                status = modelTodo.status[i];
            }
            if (t.stability) {
                // sameValues(modelTodo.status)) WHY THIS?
                LCMD.setOrCreateTodoItemState(
                    { pid: t.process.pid, stab: t.stability },
                    { value: modelTodo.status[i] },
                    obj,
                    (error, data) => {
                        if (error) {
                            console.error(error);
                        }
                    },
                );
            } else if (t.actionId) {
                LCMD.setOrCreateTodoItemState(
                    { pid: t.process.pid, id: t.actionId },
                    { value: modelTodo.status[i] },
                    obj,
                    (error, data) => {
                        if (error) {
                            console.error(error);
                        }
                    },
                );
            } else if (createItemType && todos.length === 1) {
                // only for actionItems at the moment
                LCMD.setOrCreateTodoItemState(
                    { pid: t.process.pid, id: "action" },
                    { value: t.status },
                    obj,
                    (error, data) => {
                        if (error) {
                            console.error(error);
                        }
                    },
                );
            }
            if (hasChanges) {
                if (createItemType == ItemType.STABILITY_CRITERIA) {
                    WebAppTelemetryFactory.trackEvent("stability-criteria-edit-updated");
                } else {
                    //todo: fix createItemType which returns false for action item
                    WebAppTelemetryFactory.trackEvent("action-item-edit-updated");
                }
            }
        });
        onCloseEditView();
    }

    const showOriginalDate = (key: "deadline" | "identified" | "needed" | "resolved") => {
        const dateMapping: Record<typeof key, { show: boolean, date: string | undefined }> = {
            deadline: { show: showOriginalDeadlineDate, date: originalDeadlineDate },
            identified: { show: showOriginalIdentifiedDate, date: originalIdentifiedDate },
            needed: { show: showOriginalNeededDate, date: originalNeededDate },
            resolved: { show: showOriginalResolvedDate, date: originalResolvedDate },
        };
        const { show, date } = dateMapping[key];
        const labelVariants = cva(
            "text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70",
        );
        return show && date ? (
            <div className={cn(labelVariants())}>
                {intl.get("ToDoEditView.original")}: {date}
            </div>
        ) : undefined;
    };

    const getViewTitle = (): string => {
        switch (createItemType) {
            case ItemType.STABILITY_CRITERIA:
                return modelTodo.id?.toString()
                    ? intl.get("ToDoEditView.headerTitleStabilityEdit")
                    : intl.get("ToDoEditView.headerTitleStabilityNew");
            case ItemType.ACTION_ITEM:
                return modelTodo.id?.toString()
                    ? intl.get("ToDoEditView.headerTitleActionEdit")
                    : intl.get("ToDoEditView.headerTitleActionNew");
            default:
                return modelTodo.id?.toString()
                    ? intl.get("ToDoEditView.headerTitleEdit")
                    : intl.get("ToDoEditView.headerTitleNew");
        }
    };

    const statusOptions = [
        {
            key: ItemState.DONE,
            text: intl.get("ToDoEditView.dropDownStatus.done"),
            data: { icon: "Memo" },
        },
        {
            key: ItemState.OPEN,
            text: intl.get("ToDoEditView.dropDownStatus.open"),
            data: { icon: "Print" },
        },
    ];

    const priorityOptions = [
        {
            key: ItemPriority.HIGH,
            text: intl.get("ToDoEditView.dropDownPriority.high"),
            data: { icon: "Memo" },
        },
        {
            key: ItemPriority.MEDIUM,
            text: intl.get("ToDoEditView.dropDownPriority.medium"),
            data: { icon: "Print" },
        },
        {
            key: ItemPriority.LOW,
            text: intl.get("ToDoEditView.dropDownPriority.low"),
            data: { icon: "Running" },
        },
    ];

    return (
        <LcmdModal2
            open={isOpen}
            size="l"
            onOpenChange={onCloseEditView}
            header={{
                title: getViewTitle(),
            }}
            buttons={[
                <Button key="cancel" size="sm" variant="outline" onClick={onCloseEditView}>
                    {intl.get("ToDoEditView.cancelButton.text")}
                </Button>,
                <Button key="save" size="sm" onClick={save} disabled={!isValid}>
                    {intl.get("ToDoEditView.applyButton.text")}
                </Button>,
            ]}
        >
            <ScrollArea style={{ maxHeight: "calc(100vh - 250px)" }}>
                <div className="flex">
                    <div style={{ borderRight: "1px solid #ccc", width: "60%" }}>
                        <div className="p-5">
                            <Label className="text-[#323130]" htmlFor="textFieldIssue">
                                {createItemType == ItemType.STABILITY_CRITERIA
                                    ? intl.get("ToDoEditView.textFieldIssue.labelStability")
                                    : intl.get("ToDoEditView.textFieldIssue.labelAction")}
                            </Label>
                            <Textarea
                                id="textFieldIssue"
                                rows={3}
                                placeholder={intl.get("ActionsView.TodoIssueNotSet")}
                                disabled={checkFieldDisabled(ItemType.STABILITY_CRITERIA)}
                                value={print(modelTodo.issue)}
                                onChange={(e) => onChangeTextField(e, "issue")}
                            />
                            <br />
                            <Label className="text-[#323130]" htmlFor="textFieldAction">
                                {intl.get("ToDoEditView.textFieldAction.label")}
                            </Label>
                            <Textarea
                                id="textFieldAction"
                                rows={3}
                                disabled={checkFieldDisabled("action")}
                                value={print(modelTodo.action)}
                                onChange={(e) => onChangeTextField(e, "action")}
                            />
                            <br />
                            <hr />
                            <br />
                            <Label>{intl.get("ToDoEditView.chipPickerRaisedby.labelText")}</Label>
                            <TagsMultiSelect
                                options={allChips}
                                onValueChange={(items) => onPickerChange(items, "raisedBy")}
                                values={getPickerValue("raisedBy")}
                                disabled={checkFieldDisabled("raisedBy")}
                                placeholder="Select options"
                            />
                            <br />
                            <Label>{intl.get("ToDoEditView.chipPickerResponsible.labelText")}</Label>
                            <TagsMultiSelect
                                options={allChips}
                                onValueChange={(items) => onPickerChange(items, "responsible")}
                                values={getPickerValue("responsible")}
                                disabled={checkFieldDisabled("responsible")}
                            />
                            <br />
                            <Label className="text-[#323130]" htmlFor="textFieldActionBy">
                                {intl.get("ToDoEditView.textFieldActionBy.label")}
                            </Label>
                            <Input
                                id="textFieldActionBy"
                                disabled={checkFieldDisabled("actionBy")}
                                value={print(modelTodo.actionBy)}
                                onChange={(e) => {
                                    onChangeTextField(e, "actionBy");
                                }}
                            />
                        </div>
                    </div>
                    <div className="w-5/12">
                        <div className="p-5">
                            <Label className="text-[#323130]" htmlFor="textFieldId">
                                {intl.get("ToDoEditView.textFieldId.label")}
                            </Label>
                            <Input id="textFieldId" disabled value={modelTodo.id?.toString()} />
                            <br />
                            <div>
                                {modelTodo.status && (
                                    <div className="pb-5">
                                        <Label>{intl.get("ToDoEditView.dropDownStatus.label")}</Label>
                                        <Select
                                            onValueChange={stateSelected}
                                            defaultValue={modelTodo.status?.toString()}
                                            value={print(modelTodo.status)}
                                        >
                                            <SelectTrigger>
                                                <SelectValue placeholder="Select a verified email to display" />
                                            </SelectTrigger>
                                            <SelectContent>
                                                {statusOptions.map((el) => (
                                                    <SelectItem key={el.key} value={el.key}>
                                                        {el.text}
                                                    </SelectItem>
                                                ))}
                                            </SelectContent>
                                        </Select>
                                    </div>
                                )}
                                <div className="pb-5">
                                    <Label>{intl.get("ToDoEditView.dropDownPriority.label")}</Label>
                                    <Select
                                        disabled={checkFieldDisabled("priority")}
                                        onValueChange={priorityChanged}
                                        value={print(modelTodo.priority).toString()}
                                    >
                                        <SelectTrigger>
                                            <SelectValue />
                                        </SelectTrigger>
                                        <SelectContent>
                                            {priorityOptions.map((el) => (
                                                <SelectItem key={el.key} value={el.key.toString()}>
                                                    {el.text}
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                </div>
                            </div>
                            <div className="relative pb-5">
                                <span className="absolute -top-[0px] right-0 text-zinc-400">
                                    {showOriginalDate("identified")}
                                </span>
                                <UTCDatePickerV2
                                    label={intl.get("ToDoEditView.identified.label")}
                                    disabled={checkFieldDisabled("identified")}
                                    value={getDateValueByField("identified")}
                                    onSelectDate={onSelectedIdentifiedDate}
                                />
                            </div>
                            <div className="relative pb-5">
                                <span className="absolute -top-[0px] right-0 text-zinc-400">
                                    {showOriginalDate("needed")}
                                </span>
                                <UTCDatePickerV2
                                    label={intl.get("ToDoEditView.needed.label")}
                                    disabled={checkFieldDisabled("needed")}
                                    value={getDateValueByField("needed")}
                                    onSelectDate={onSelectedNeededDate}
                                />
                            </div>
                            <div className="relative pb-5">
                                <span className="absolute -top-[0px] right-0 text-zinc-400">
                                    {showOriginalDate("deadline")}
                                </span>
                                <UTCDatePickerV2
                                    label={intl.get("ToDoEditView.promised.label")}
                                    disabled={checkFieldDisabled("deadline")}
                                    value={getDateValueByField("deadline")}
                                    onSelectDate={onSelectedDeadlineDate}
                                />
                            </div>
                            <div className="relative">
                                <span className="absolute -top-[0px] right-0 text-zinc-400">
                                    {showOriginalDate("resolved")}
                                </span>
                                <UTCDatePickerV2
                                    label={intl.get("ToDoEditView.resolved.label")}
                                    disabled={checkFieldDisabled("resolved")}
                                    value={getDateValueByField("resolved")}
                                    onSelectDate={onSelectedResolvedDate}
                                />
                            </div>
                            <div>
                                <br />
                                <strong>{intl.get("ToDoEditView.area.labelText")}</strong>
                                <p>{print(modelTodo.area)}</p>
                            </div>
                        </div>
                        <div className="border-t border-solid border-[#ccc] p-5">
                            <div>
                                <strong>{intl.get("ToDoEditView.type.text")}</strong>
                                <p>{printType(modelTodo.type)}</p>
                            </div>
                            <div>
                                <strong>{intl.get("ToDoEditView.process.text")}</strong>
                                <p>
                                    {!hasMixedProcessIds && `#${todos[0].process.pid} - `}
                                    {print(modelTodo.process)}
                                </p>
                            </div>
                            {/*<div>*/}
                            {/*    <strong>Area</strong>*/}
                            {/*    <p>{print(todo.area)}</p>*/}
                            {/*</div>*/}
                            <div>
                                <strong>{intl.get("ToDoEditView.trade.text")}</strong>
                                <p>{print(modelTodo.trade?.map((t) => t.map((tt) => tt.name).join(" ")))}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </ScrollArea>
        </LcmdModal2>
    );
}
