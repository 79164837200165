import { CanvasHostBaseReactState } from "../../../legacy/api/lcmd2framework";

export type ViewTypeValueI = (typeof ViewTypes)[keyof typeof ViewTypes];
export type ContentTypeValueI = (typeof ContentTypes)[keyof typeof ContentTypes];
export type SizeValueI = (typeof Sizes)[keyof typeof Sizes];
export type ViewNamesType = (typeof viewNames)[keyof typeof viewNames];

export const ViewTypes = {
    TRADE: "standard",
    STATUS: "status",
    STABILITY: "stability",
    CONFLICT: "conflict",
    BASELINE: "baseline",
} as const;

export const ContentTypes = {
    RESOURCE_CHART: "RESOURCE_CHART",
    MILESTONES: "MILESTONES",
} as const;

export const Sizes = {
    SMALL: "SMALL",
    MEDIUM: "MEDIUM",
    LARGE: "LARGE",
} as const;

export const viewNames = {
    0: "monthly",
    1: "weekly",
} as const;

export type UserSessionPosition = Pick<CanvasHostBaseReactState, "left" | "top" | "scale">;
