import { assert, FrameworkHttpError } from "./GlobalHelper";
import { DataModel } from "./DataModel";
import { SERVICES } from "./services";
import { createClient, Client } from "@liveblocks/client";

export type RTSessionsData = {
    [session: string]: RTSessionData;
};

export type RTSessionData = any & {};

export type Presence = {
    x: number;
    y: number;
};

async function authenticateLiveblocks(authToken: string, room: string) {
    return (
        await fetch(SERVICES.LIVEBLOCKS_AUTH_SERVICE, {
            method: "POST",
            headers: {
                Authorization: authToken,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ room }),
        })
    ).json();
}

export class RT {
    private projectId: string = null;
    private streamId: string = null;
    private warmupId: string = undefined;
    private sub: string = null;
    private sdkInst: string = null;
    private sandbox_token: string = null;
    private cb: (error: FrameworkHttpError, sts: number, sessions: string[], data: RTSessionsData) => void = null;
    private onMoved: (session: string, data: RTSessionsData) => void = null;
    public syncDate: number = 0;

    private data = {
        _sts: -1,
    };
    private _client: Client = null;
    private _room = null;
    private __reconnectCtx = {
        count: 0,
        timeout: 0,
        timer: null,
    };
    private async doConnect() {
        if (null === this._room) {
            const self = this;
            if (null === this._client) {
                this._client = createClient({
                    authEndpoint: async (room) => {
                        const res = await authenticateLiveblocks(this.sandbox_token, room);
                        return res;
                    },
                    polyfills: {
                        fetch,
                        WebSocket,
                    },
                });
            }
            this._room = this._client.enter(this.streamId, {
                initialPresence: {
                    sub: this.sub,
                },
            });
            this._room.subscribe("others", (others) => {
                const ts = Date.now();
                const n_others = others.length;
                const signal = false;
                let _sts = -1;
                for (let i_others = 0; i_others < n_others; i_others++) {
                    const c = others[i_others];
                    const _p = self.data[c.connectionId];
                    const p = { ...c.presence, ts: ts };
                    if ("number" === typeof p?.sts && p.sts >= _sts) {
                        _sts = p.sts;
                    }
                    if ("number" === typeof p.x && "number" === typeof p.y) {
                        if (p.x !== _p?.x || p.y !== _p?.y) {
                            self.onMoved(c.connectionId, p);
                        }
                    }
                    self.data[c.connectionId] = p;
                }
                if (self.data._sts !== _sts || signal) {
                    self.data._sts = _sts;
                    self.cb(null, _sts, null, self.data);
                }
            });
            this._room.subscribe("connection", (status) => {
                console.log("connection: " + JSON.stringify(status));
            });
        }
    }

    public reconnect() {
        this.disconnect();
        this.doConnect();
    }

    public disconnect() {
        if (null !== this.__reconnectCtx.timer) {
            clearTimeout(this.__reconnectCtx.timer);
            this.__reconnectCtx.timer = null;
        }
        assert(null === this.__reconnectCtx.timer);
        if (this._room) {
            this._client.leave(this._room.id);
            this._room = null;
        }
        assert(null === this._room);
    }

    connect(
        projectId: string,
        streamId: string,
        sub: string,
        sandbox_token: string,
        sdkInst: string,
        warmupId: string,
        cb: (error: FrameworkHttpError, sts: number, sessions: string[], data: RTSessionsData) => void,
        onMoved?: (session: string, data: RTSessionsData) => void,
    ) {
        if (
            null === this.projectId &&
            null === this.sub &&
            null === this.sandbox_token &&
            null === this.streamId &&
            null == this.cb
        ) {
            this.warmupId = warmupId;
            this.projectId = projectId;
            this.streamId = streamId;
            this.sub = sub;
            this.sandbox_token = sandbox_token;
            this.sdkInst = sdkInst;
            this.cb = cb;
            this.onMoved = onMoved;
            this.doConnect();
        }
    }

    pointerMoved(x: number, y: number, n: string) {
        if (this._room) {
            this._room.updatePresence({
                x,
                y,
                n,
            });
        }
    }

    static signalReload(model: DataModel, rt?: RT) {
        if (rt?.streamId) {
            // signal reload to other clients...
            assert(rt._room); //@TODO handle me
            const p = rt._room.getPresence();
            assert(rt.streamId === model.storageName && p.sub === rt.sub);
            const sts = model.commitTS();
            if ("number" !== typeof p.sts || p.sts < sts) {
                rt._room.updatePresence({
                    sts,
                });
            }
        }
    }

    static async syncStream(model: DataModel, master_token: string, cb: (e: Error | null) => void, rt?: RT) {
        if (0 === model.syncStreamPending) {
            model.syncStreamPending = 1;
            const syncRet = model.getSync(0, true);
            let ret = null;
            try {
                ret = await DataModel.pushStreamOps(model.storageName, syncRet.sync, undefined, master_token);
                //console.log("SYNC:"+JSON.stringify(Object.assign({}, ret, model._snap())));
                RT.signalReload(model, rt);
            } catch (e) {
                // rollback
                console.warn(e);
                if (rt) {
                    let now = Date.now();
                    while (now <= rt.syncDate) now++; // ensure its unique
                    rt.syncDate = now;
                }
                cb(e); // signal connection error
                ret = null;
            }
            if (ret) {
                //assert(syncRet.sync.ofs===ret.ofs);
                // rollback of local ops and apply changes from server
                const commitRet = model.commitSync(ret, syncRet.token);
                if (commitRet > 0) {
                    if (rt) {
                        let now = Date.now();
                        while (now <= rt.syncDate) now++; // ensure its unique
                        rt.syncDate = now;
                    }
                    cb(null); // signal success;
                } else if (0 === commitRet) {
                    // commit failed -> got new data, try to commit again...
                    model.syncStreamPending = 2; // commit again
                } else {
                    // out-of-bound-data
                    assert(-1 === commitRet);
                    model.syncStreamPending = 2; // try again
                }
            }
            if (2 === model.syncStreamPending) {
                // again...
                model.syncStreamPending = 0;
                RT.syncStream(model, master_token, cb, rt);
            } else {
                assert(1 === model.syncStreamPending);
                model.syncStreamPending = 0;
            }
        } else if (model.syncStreamPending >= 0) {
            model.syncStreamPending = 2;
        } else {
            assert(-1 === model.syncStreamPending); // sync disabled
            cb(null);
        }
    }
}
