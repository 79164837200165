﻿import { Dialog, DialogContent, DialogTitle } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import rocket from "../../../assets/rocket2.png";
import { Loader2 } from "lucide-react";

export type MessageDialogProps = {
    open: boolean;
    title: string;
    message: string;
    buttonText: string;
    onButtonClick: () => void;
    onClose?: () => void;
    buttonLoading?: boolean;
    buttonDisabled?: boolean;
};

export function MessageDialog({
    open,
    title,
    message,
    buttonText,
    onButtonClick,
    buttonLoading,
    buttonDisabled,
    onClose,
}: MessageDialogProps) {
    return (
        <Dialog
            open={open}
            onOpenChange={(isOpen) => {
                if (!isOpen) {
                    onClose && onClose();
                }
            }}
        >
            <DialogContent
                className="flex h-[558px] w-[900px] items-center justify-center"
                style={{ borderRadius: "16px" }}
            >
                <div className="bg-white">
                    <div className="inline-flex flex-col items-center gap-4">
                        <img width="238" src={rocket} />
                        <div className="inline-flex items-center gap-4">
                            <DialogTitle className={"text-center text-3xl font-semibold text-zinc-900"}>
                                {title}
                            </DialogTitle>
                        </div>
                        <div className="flex h-10 w-[350px] items-center justify-center whitespace-pre-line text-center text-xs font-medium leading-5 text-gray-500">
                            {message}
                        </div>
                        <Button
                            className={"px-4 py-2"}
                            type="submit"
                            size="lg"
                            onClick={onButtonClick}
                            disabled={buttonLoading || buttonDisabled}
                        >
                            {buttonLoading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
                            {buttonText}
                        </Button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}
