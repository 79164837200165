﻿import * as React from "react";
import { WorkerSession } from "@/model/DataModel";
import { LastOpenedProjectsService } from "@/app/services/LastOpenedProjects.service";
import { WebAppTelemetryFactory } from "@/app/services/WebAppTelemetry.service";
import { getServicesToken, SERVICES } from "@/model/services";
import { assert, FrameworkError, jsonToError } from "@/model/GlobalHelper";
import { DefaultButton, Dialog, DialogFooter, DialogType, IDialogContentProps, PrimaryButton } from "@fluentui/react";
import { errorToMessage, intl } from "../../GlobalHelperReact";
import { authWarmup } from "@/model/ApiHelper";
import { Canvas } from "../Canvas/Canvas";
import Files from "../Files";
import { FilesOverlay } from "@/components/ProjectList/FilesOverlay";
import { NonProgressBar } from "../../GlobalHelperFluentUI";
import { ParticleContextProvider } from "lcmd2framework";
import DialogLoader from "../DialogLoader";
import { initExtensions } from "../../main";
import { MainWorkerMessage, MainWorkerPipe } from "../../MainWorkerPipe";
import { Login } from "@/components/Login";
import userflow from "userflow.js";
import { getCurrentLocale } from "@/components/utils/date/locale";
import posthog from "posthog-js";
import { MessageDialog } from "@/lcmd2loginV2/src/components/common/MessageDialog/MessageDialog";
import { useLoginStore } from "@/lcmd2loginV2/src/store/loginStore";

type DigitalPlanningBoardProps = {
    nav: any;
    worker: MainWorkerPipe;
};

type DigitalPlanningBoardState = {
    error: string | null;
    authenticated: boolean | null;
    nav: any;
    timer0: number;
    timer: number;
    betaPopup: boolean;
    filesOveray: boolean;
    isLicense: boolean;
    dialog: {
        id: string;
        params: any;
    } | null;
    alert: {
        dialogContentProps: IDialogContentProps;
        onOK?: () => void;
        onCancel?: () => void;
    } | null;
    showNewUserModal: boolean;
};

export class DigitalPlanningBoard extends React.Component<DigitalPlanningBoardProps, DigitalPlanningBoardState> {
    state: Readonly<DigitalPlanningBoardState> = {
        error: null,
        authenticated: null,
        nav: null,
        timer0: 0,
        timer: 0,
        betaPopup: false,
        filesOveray: true,
        isLicense: false,
        dialog: null,
        alert: null,
        showNewUserModal: useLoginStore.getState().isNewUser,
    };

    private static HLP: HTMLDivElement = null;

    static calcFontSize(w: number, h: number, t: string, padding: number, _fs_max?: number, _fs_min?: number) {
        const fs_max = _fs_max || 14;
        const fs_min = _fs_min || 6;
        let HLP = DigitalPlanningBoard.HLP;
        if (null === HLP) {
            DigitalPlanningBoard.HLP = document.getElementById("HLP") as HTMLDivElement;
            HLP = DigitalPlanningBoard.HLP;
        }
        if (HLP) {
            //const padding=2+1;
            for (let fs = fs_max; fs > fs_min; fs -= 0.5) {
                //HLP.style.maxWidth=(w-2*padding)+"px";
                HLP.style.width = w - 2 * padding + "px";
                HLP.style.height = h - 2 * padding + "px";
                //HLP.style.width=w+"px";
                //HLP.style.margin=2+"px";
                HLP.style.fontSize = fs + "px";
                HLP.style.lineHeight = (1).toFixed(1);
                //HLP.style.overflowWrap="break-word";
                //HLP.style.wordBreak="break-word";
                HLP.innerText = t;
                const r = HLP.getBoundingClientRect();
                //HLP.style.visibility="visible";
                /*
                if (Math.ceil(r.height)+2*padding<=h) { // fit
                    return fs;
                }
                */
                if (HLP.scrollWidth <= HLP.clientWidth && HLP.scrollHeight <= HLP.clientHeight) {
                    return fs;
                }
            }
            return fs_min;
        } else {
            return undefined;
        }
    }

    static calcCardFontSize(w: number, h: number, t: string) {
        return DigitalPlanningBoard.calcFontSize(w - 3 - 0, h - 2 - 1, t, 0);
    }

    public static openProject(worker: MainWorkerPipe, session: WorkerSession, dailyBoard: boolean) {
        if (!dailyBoard || "development" === process.env.NODE_ENV) {
            worker.dispatchMessage([
                "framework",
                "nav",
                Object.assign({}, worker.nav, {
                    project: session.pid, // HACK
                    session: session, // HACK
                }),
            ]);
            worker.dispatchMessage(["framework", "toggle", dailyBoard ? "dailyboard" : "project"]);

            LastOpenedProjectsService.projectOpened(session.pid);

            // init app analytics
            if (session.pid && session?.resource?.sub) {
                // init project for app analytics
                if (WebAppTelemetryFactory.isInitialized() && WebAppTelemetryFactory.getProjectId()) {
                    WebAppTelemetryFactory.clearInstance();
                    const email =
                        worker.auth?.auth_result?.email || worker.auth?.details?.email || worker.auth?.params?.email;
                    const internal = WebAppTelemetryFactory.isInternalEmail(email);
                    const domain = WebAppTelemetryFactory.domainEmail(email);
                    WebAppTelemetryFactory.identify({
                        projectId: session.pid,
                        userId: session.resource.sub,
                        internal: internal,
                        domain: domain || "notDefined",
                    });
                    WebAppTelemetryFactory.setRole(session.role.role);
                } else {
                    // todo: add user role for first initialisation
                    if (WebAppTelemetryFactory.getProjectId() !== session.pid) {
                        WebAppTelemetryFactory.setProject(session.pid, session.role.role);
                    }
                }

                WebAppTelemetryFactory.trackEvent("open-project");
                //rename the userjourney store name in local storage so that it is different for each user and project
            } else {
                const url = window.location.href + "#tab&lcmdServiceToken=" + getServicesToken();
                const ctx = {
                    ref: window.open(url, "_blank"),
                    handler: null,
                };
                ctx.handler = function (ev) {
                    if (ev.origin === window.origin) {
                        const lcmd = ev.data?.lcmd;
                        if (Array.isArray(lcmd)) {
                            if ("tab" === lcmd[0] && "ready" === lcmd[1]) {
                                window.removeEventListener("message", ctx.handler);
                                ctx.ref.postMessage(
                                    {
                                        lcmd: [
                                            "tab",
                                            "open",
                                            Object.assign({}, worker.nav, {
                                                project: session.pid, // HACK
                                                session: session, // HACK
                                                dailyBoard: dailyBoard,
                                            }),
                                        ],
                                    },
                                    window.origin,
                                );
                                ctx.ref = null;
                                ctx.handler = null;
                            }
                        }
                    }
                }.bind(ctx);
                window.addEventListener("message", ctx.handler);
            }
        }
    }

    private addDocumentTitle = function (
        view: string,
        projectName: string = intl.get("projects.unnamedProject") || "",
    ) {
        let title: string | undefined;
        switch (view) {
            case "hello":
                title = "Login";
                break;
            case "files":
                title = "Home";
                break;
            default:
                title = projectName.length > 16 ? projectName.slice(0, 16) + "..." : projectName;
        }
        if (title) {
            document.title = title + " - lcmd";
        }
    };

    private onWorkerMsg = function (this: DigitalPlanningBoard, msg: MainWorkerMessage) {
        switch (msg[0]) {
            case "auth_token":
                {
                    const auth = msg[1];
                    if (auth?.auth_token) {
                        this.setState({
                            authenticated: true,
                        });
                        const sub = auth.sub || auth.auth_result.sub;
                        const email =
                            auth.email || auth.auth_result?.email || auth?.details?.email || auth?.params?.email;
                        if (sub) {
                            if (
                                WebAppTelemetryFactory.isInitialized() &&
                                WebAppTelemetryFactory.getSub() &&
                                WebAppTelemetryFactory.getSub() !== sub
                            ) {
                                WebAppTelemetryFactory.clearInstance();
                            }
                            WebAppTelemetryFactory.identify({
                                userId: sub,
                                internal: WebAppTelemetryFactory.isInternalEmail(email),
                                domain: WebAppTelemetryFactory.domainEmail(email) || "notDefined",
                            });
                        } else {
                            console.warn("Cannot init app analytics");
                        }
                    }
                }
                break;
            case "framework":
                switch (msg[1]) {
                    case "load":
                        {
                            const load = msg[2];
                            this.props.worker.postMessage([
                                "init",
                                {
                                    SERVICES: SERVICES,
                                    puid: load.puid,
                                    key: load.key,
                                    rev: load.rev,
                                },
                            ]);
                        }
                        break;
                    case "logout":
                        {
                            try {
                                sessionStorage.clear();
                                localStorage.removeItem("lcm.auth_token");
                                posthog.reset();
                            } catch (e) {
                                console.warn(e);
                            }
                            window.location.reload();
                        }
                        break;
                    case "toggle":
                        {
                            const toggle = msg[2];
                            let nav = this.props.worker.nav;
                            delete nav.subView;
                            switch (toggle) {
                                case "folder":
                                    {
                                        if (this.state.authenticated) {
                                            nav = Object.assign({}, this.props.worker.nav, {
                                                view: "files",
                                            });
                                        }
                                    }
                                    break;
                                case "processview":
                                    {
                                        if (this.props.worker.nav?.project && this.props.worker.nav?.session) {
                                            //@TODO
                                            nav = Object.assign({}, this.props.worker.nav, {
                                                view: "processview",
                                            });
                                        }
                                    }
                                    break;
                                case "project":
                                    {
                                        if (this.props.worker.nav?.project && this.props.worker.nav?.session) {
                                            //@TODO
                                            nav = Object.assign({}, this.props.worker.nav, {
                                                view: "pp",
                                            });
                                        }
                                    }
                                    break;
                                case "dashboard":
                                    {
                                        if (this.props.worker.nav?.project && this.props.worker.nav?.session) {
                                            //@TODO
                                            nav = Object.assign({}, this.props.worker.nav, {
                                                view: "dashboard",
                                            });
                                        }
                                    }
                                    break;
                                case "dailyboard":
                                    {
                                        if (this.props.worker.nav?.project && this.props.worker.nav?.session) {
                                            //@TODO
                                            nav = Object.assign({}, this.props.worker.nav, {
                                                view: "dailyboard",
                                            });
                                        }
                                    }
                                    break;
                                case "workshop":
                                    {
                                        if (this.props.worker.nav?.project && this.props.worker.nav?.session) {
                                            nav = Object.assign({}, this.props.worker.nav, {
                                                view: "workshop",
                                            });
                                        }
                                    }
                                    break;
                                case "taktzone":
                                    {
                                        if (this.props.worker.nav?.project && this.props.worker.nav?.session) {
                                            nav = Object.assign({}, this.props.worker.nav, {
                                                view: "taktzone",
                                            });
                                        }
                                    }
                                    break;
                                case "todo":
                                    {
                                        if (this.props.worker.nav?.project && this.props.worker.nav?.session) {
                                            nav = Object.assign({}, this.props.worker.nav, {
                                                view: "todo",
                                            });
                                        }
                                    }
                                    break;
                                case "settings":
                                    {
                                        if (this.props.worker.nav?.project && this.props.worker.nav?.session) {
                                            nav = Object.assign({}, this.props.worker.nav, {
                                                view: "settings",
                                                subView: msg[3],
                                            });
                                        }
                                    }
                                    break;
                            }
                            if (this.state.nav?.view !== nav?.view) {
                                this.props.worker.dispatchMessage(["framework", "nav", nav]);
                            }
                        }
                        break;
                    case "nav":
                        {
                            const nav = msg[2];
                            this.setState({
                                nav: nav,
                            });
                            this.addDocumentTitle(nav.view, nav.session?.sandbox_name);
                        }
                        break;
                    case "betaPopup":
                        {
                            this.setState({
                                betaPopup: true,
                            });
                        }
                        break;
                    case "files":
                        {
                            const visible = msg[2];
                            this.setState({
                                filesOveray: visible,
                            });
                        }
                        break;
                    case "lic":
                        {
                            this.setState({
                                isLicense: true,
                            });
                        }
                        break;
                }
                break;
            case "toggle":
                {
                    const dialog_id = msg[1] || "";
                    if (dialog_id.startsWith("dialog.")) {
                        this.setState({
                            dialog: msg[2]
                                ? {
                                      id: dialog_id,
                                      params: msg[2],
                                  }
                                : null,
                        });
                    } else if ("fw.alert" === dialog_id) {
                        this.setState({
                            alert: msg[2],
                        });
                    } else if ("fw.exception" === dialog_id) {
                        const e: FrameworkError = jsonToError(msg[2]) as FrameworkError;
                        this.setState({
                            alert: {
                                dialogContentProps: {
                                    type: DialogType.normal,
                                    title: intl.get(e.message),
                                },
                                onOK: () => {
                                    this.setState({ alert: null });
                                },
                            },
                        });
                    }
                }
                break;
        }
    }.bind(this);

    private onWindowMessage = function (this: DigitalPlanningBoard, ev: any) {
        if (window.origin === ev.origin) {
            const lcmd = ev.data?.lcmd;
            if (Array.isArray(lcmd)) {
                if ("tab" === lcmd[0] && "open" === lcmd[1]) {
                    const worker = this.props.worker;
                    const nav = lcmd[2];
                    document.title = nav.session?.sandbox_name || (nav.dailyBoard ? "Daily Board" : "Master");
                    worker.dispatchMessage(["framework", "nav", nav]);
                    worker.dispatchMessage(["framework", "toggle", nav.dailyBoard ? "dailyboard" : "project"]);
                }
            }
        }
    }.bind(this);

    private onVisibilityChangedOrOnline = function (this: DigitalPlanningBoard) {
        if (this.props.worker && !document.hidden) {
            this.props.worker.forceSync();
        }
    }.bind(this);

    componentDidMount(this: DigitalPlanningBoard) {
        if (useLoginStore.getState().isNewUser !== this.state.showNewUserModal) {
            this.setState({
                showNewUserModal: useLoginStore.getState().isNewUser,
            });
        }
        this.props.worker.registerHandler(this.onWorkerMsg);
        window.addEventListener("message", this.onWindowMessage);
        window.addEventListener("visibilitychange", this.onVisibilityChangedOrOnline);
        window.addEventListener("online", this.onVisibilityChangedOrOnline);

        const auth_token = localStorage.getItem("lcm.auth_token");
        const waitCtx = {
            self: this,
            onTimeout: null,
            onTimeoutHandle: null as any,
        };
        waitCtx.onTimeoutHandle = setInterval(
            function (ctx) {
                if (null !== ctx.onTimeout) {
                    ctx.onTimeout();
                    ctx.onTimeout = null;
                    if (null !== waitCtx.onTimeoutHandle) {
                        clearInterval(waitCtx.onTimeoutHandle);
                        waitCtx.onTimeoutHandle = null;
                    }
                } else {
                    ctx.self.setState({
                        timer: new Date().getTime(),
                    });
                }
            },
            1000,
            waitCtx,
        );
        const onWarmup = function (this: DigitalPlanningBoard, error: any, result: any) {
            if (error) {
                if ("fw.warmup403" === error?.name) {
                    this.setState({
                        error: errorToMessage(error),
                    });
                } else {
                    this.setState({
                        error: errorToMessage(error),
                    });
                }
            } else {
                this.props.worker.warmup_result = result;
                if ("string" === typeof result?.warmupId) {
                    this.props.worker.warmupId = result.warmupId;
                }
                if (result?.sub) {
                    this.props.worker.dispatchMessage([
                        "auth_token",
                        {
                            auth_token: auth_token,
                            auth_result: result,
                        },
                    ]);
                } else {
                    this.setState({
                        authenticated: false,
                    });
                }
            }
        };
        const now = new Date().getTime();
        this.setState(
            {
                timer0: now,
                timer: now,
            },
            () => {
                authWarmup(
                    auth_token,
                    this.props.nav?.promo,
                    (error, result) => {
                        // hack for userflow
                        if (result?.email && result?.sub && userflow && posthog.isFeatureEnabled("ff-userflow")) {
                            userflow.init(SERVICES.USERFLOW_TOKEN);
                            userflow.identify(result.sub, {
                                email: result.email,
                                locale_code: getCurrentLocale().code,
                            });
                        }

                        initExtensions(this.props.worker.config, this.props.nav, result, (e) => {
                            if (e) {
                                this.setState({
                                    error: "Error initializing locales.",
                                });
                            } else {
                                assert(null === waitCtx.onTimeout);
                                waitCtx.onTimeout = onWarmup.bind(this, error, result);
                            }
                        });
                    },
                    true,
                );
            },
        );

        let view = "hello";
        const project = this.props.nav?.project || undefined;
        const _revId = this.props.nav?.revId || undefined;
        let revId = undefined;
        if (_revId) {
            const rev = Number.parseInt(_revId, 16);
            if (rev > 0) {
                revId = rev;
            }
        }

        if (undefined !== this.props.nav?.tab && window.opener) {
            view = "tab";
            window.opener.postMessage({ lcmd: ["tab", "ready"] }, window.origin);
        } else if (auth_token || this.props.nav?.login) {
            view = "files";
        }

        const nav = {
            view: view,
            session: {}, // pass empty session
            login: this.props.nav?.login,
            license: this.props.nav?.license,
            project: project,
            revId: revId,
            processId: this.props.nav?.processid,
            initialSidebarView: this.props.nav?.initialsidebarview,
            resource_token: this.props.nav?.resource_token,
        };
        if (this.props.nav?.initialsidebarview === "com.lcmdigital.sidebar.comments") {
            WebAppTelemetryFactory.trackEvent("comments-opened-from-mail-notification");
        }
        this.props.worker.dispatchMessage(["framework", "nav", nav]);
    }

    hideUploadPopup = function (this: DigitalPlanningBoard, data?: any) {
        if (data?.auth_token) {
            this.props.worker.dispatchMessage(["auth_token", data]);
        }
        if (this.state.nav?.resource_token) {
            const nav = {
                ...this.state.nav,
                view: "files",
            };
            delete nav.resource_token;
            this.props.worker.dispatchMessage(["framework", "nav", nav]);
        }
    }.bind(this);

    componentWillUnmount(this: DigitalPlanningBoard) {
        window.removeEventListener("online", this.onVisibilityChangedOrOnline);
        window.removeEventListener("visibilitychange", this.onVisibilityChangedOrOnline);
        window.removeEventListener("message", this.onWindowMessage);
        this.props.worker.unregisterHandler(this.onWorkerMsg);
    }

    //private _renderBetaPopup=function(this:DigitalPlanningBoard, props:{}) {
    //    const licTrial=this.props.worker.isLicTrial();
    //    return licTrial?<FrameworkFeedbackPopup worker={this.props.worker} key="feedback" onDone={this.state.betaPopup?()=>{this.setState({betaPopup:false})}:null} notify={"beta"} title="Beta Test" text={"Thank you for your interest!\nWe will add you to our Beta Testing Group.\nOur team will contact you soon about the details."}/>:null;
    //}.bind(this);

    render(this: DigitalPlanningBoard) {
        /*
        const worker:MainWorkerPipe=this.props.worker;
        return <Canvas key="canvas" worker={worker} projectId={this.props.projectId} projectSandbox={this.props.projectSandbox}/>;
        */
        /**/
        const worker: MainWorkerPipe = this.props.worker;
        const nav = this.state.nav;
        let ret = null;
        if (nav?.resource_token && null !== this.state.authenticated) {
            assert(false); // no longer used
            //ret=<FileUploadPopup key="upload" view="details"  worker={this.props.worker} auth_token={this.props.worker.auth?.auth_token||null} auth_warmup={this.props.worker.warmup_result?.req?this.props.worker.warmup_result as {req:string} :null} onDone={this.hideUploadPopup} project={null} resource_token={nav.resource_token}/>
        } else if (this.state.authenticated) {
            let main = null;
            if (
                "pp" === nav?.view ||
                "processview" === nav?.view ||
                "dashboard" === nav?.view ||
                "workshop" === nav?.view /* // (1) workshop is part of the canvas... */ ||
                "dailyboard" === nav?.view /* // (2) dailyboard iframe is part of the canvas... */ ||
                "taktzone" === nav?.view ||
                "todo" === nav?.view ||
                "settings" === nav?.view
            ) {
                const token =
                    nav.session?.sandbox ||
                    nav.session?.master_token ||
                    nav.session?.resource?.token ||
                    JSON.stringify(nav.session);
                main = (
                    <Canvas
                        key={token}
                        worker={worker}
                        projectId={nav.project}
                        projectSandbox={nav.session}
                        /*readonly={nav.session.sandbox && !this.props.worker.isLicTrial()?0!==(nav.session?.sandbox_ts||0):true}*/
                        sidebar={{
                            selected: "pp" === nav.view ? "project" : nav.view,
                            subView: nav.subView,
                        }}
                        filesOverlay={this.state.filesOveray}
                    />
                );
            } else if ("files" === nav?.view) {
                main = <Files key={"empty"} worker={worker} filesOverlay={this.state.filesOveray} />;
            }
            ret = [
                main,
                useLoginStore.getState().isNewUser && (
                    <MessageDialog
                        key={"Welcome-Modal"}
                        open={useLoginStore.getState().isNewUser}
                        title={intl.get("LcmdLogin.ConfirmEmail.Welcome")}
                        message={intl.get("LcmdLogin.ConfirmEmail.SuccessTextInvite")}
                        buttonText={intl.get("LcmdLogin.ConfirmEmail.SuccessButtonText")}
                        onButtonClick={() => {
                            this.setState({ showNewUserModal: false });
                            useLoginStore.setState({ isNewUser: false });
                        }}
                        onClose={() => {
                            this.setState({ showNewUserModal: false });
                            useLoginStore.setState({ isNewUser: false });
                        }}
                    />
                ),
                <FilesOverlay key="fo" visible={this.state.filesOveray} hasCloseButton={"files" !== nav?.view} />,
                // @deprecated
                // <FrameworkUserDialog
                //     key="FUD"
                //     isOpen={this.state.isLicense}
                //     onClose={() => {
                //         this.setState({ isLicense: false });
                //     }}
                // />,
            ];
        } else {
            if (null !== this.state.authenticated && "hello" === nav?.view) {
                ret = <Login nav={this.props.nav} />;
            } else if (false === this.state.authenticated) {
                assert(false); //no longer used...
                //ret=this.props.worker.config?.auth?<this.props.worker.config.auth nav={this.props.nav}/>:<FileUploadPopup key="login" view="login"  worker={this.props.worker} auth_token={this.props.worker.auth?.auth_token||null} auth_warmup={this.props.worker.warmup_result?.req?this.props.worker.warmup_result as {req:string} :null} onDone={this.hideUploadPopup} project={null}/>
            } else {
                ret = (
                    <div
                        style={{
                            display: "flex",
                            width: "100vw",
                            height: "100vh",
                            justifyContent: "center",
                            flexDirection: "column",
                            textAlign: "center",
                        }}
                    >
                        <div>
                            <div>
                                <img
                                    style={{
                                        marginLeft: "auto",
                                        marginRight: "auto",
                                    }}
                                    src="/img/lcmd_logo.svg"
                                />
                            </div>
                            <div
                                style={{
                                    width: 662.9999999999999,
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                }}
                            >
                                {this.state.error ? (
                                    <span style={{ color: "red" }}>{this.state.error.toString()}</span>
                                ) : (
                                    <NonProgressBar {...this.state} />
                                )}
                            </div>
                        </div>
                    </div>
                );
            }
        }
        return (
            <ParticleContextProvider>
                {ret}
                <DialogLoader worker={worker} dialog={this.state.dialog} />
                {this.state.alert ? (
                    <Dialog
                        hidden={false}
                        onDismiss={this.state.alert.onCancel}
                        dialogContentProps={this.state.alert.dialogContentProps}
                    >
                        <DialogFooter>
                            {this.state.alert?.onCancel ? (
                                <DefaultButton onClick={this.state.alert.onCancel} text={intl.get("fw.cancel")} />
                            ) : null}
                            {this.state.alert?.onOK ? (
                                <PrimaryButton onClick={this.state.alert.onOK} text={intl.get("fw.ok")} />
                            ) : null}
                        </DialogFooter>
                    </Dialog>
                ) : null}
                {/*<div style={{position:"fixed", right:5, bottom:5, backgroundColor: "red", color:"white", zIndex: 100000, padding: 5}}>M4 Development Build - Not For Release</div>*/}
            </ParticleContextProvider>
        );
    }
}
