import { ProcessStatuses } from "@/model/GlobalHelper";
import { intl } from "@/legacy/GlobalHelperReact";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import * as React from "react";

export function StatusCard({
    status,
    onClick,
    isSelected,
}: {
    status: ProcessStatuses;
    onClick: (status: ProcessStatuses) => void;
    isSelected: boolean;
}) {
    const statusColorProperties = {
        [ProcessStatuses.open]: {
            backgroundColor: "bg-slate-400",
            text: intl.get("charts.statusTracking.labels.open"),
        },
        [ProcessStatuses.inProgress]: {
            backgroundColor: "bg-blue-500",
            text: intl.get("charts.statusTracking.labels.inProgress"),
        },
        [ProcessStatuses.done]: {
            backgroundColor: "bg-green-600",
            text: intl.get("charts.statusTracking.labels.done"),
        },
        [ProcessStatuses.late]: {
            backgroundColor: "bg-orange-500",
            text: intl.get("charts.statusTracking.labels.late"),
        },
        [ProcessStatuses.overdue]: {
            backgroundColor: "bg-red-600",
            text: intl.get("charts.statusTracking.labels.overdue"),
        },
    };

    const { backgroundColor, text } = statusColorProperties[status] || {};
    return (
        <Button
            key={"status-" + status}
            variant={"outline"}
            onClick={() => {
                onClick(status);
            }}
            className={cn(isSelected && "bg-slate-100")}
        >
            <div className={cn(backgroundColor, "mr-3 h-2 w-2 rounded-full")}></div>
            {text}
        </Button>
    );
}
