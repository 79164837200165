import * as React from "react";
import { useEffect, useState } from "react";
import { useLCMD } from "../../../app/LCMContext";
import { useLoadingStatus } from "../../hooks/use-loading-status";
import { useProcessPlanTimestamp } from "@/app/store/canvasStore";
import { useFilter } from "@/app/store/userJourneyStore";

// TODO: check types before merge to master
export type FilterItem = {
    _hid: string;
    _pos?: number;
    name: string;
    global?: boolean;
    owner: string;
};

export type SelectedFilterItem = {
    hid: string;
};

export type Data = {
    filterCollection?: FilterItem[];
    filter?: { trade: { [key: string]: unknown }; saveAs?: SelectedFilterItem };
    trades?: unknown[];
};

export function useFilterData() {
    const processPlanTimestamp = useProcessPlanTimestamp();
    const filterState = useFilter();

    const [data, setData] = useState<Data>({});
    const [refreshToken, setRefreshToken] = useState<symbol>();
    const { status, observe } = useLoadingStatus();
    function refresh() {
        setRefreshToken(Symbol());
    }

    const LCMD = useLCMD();

    useEffect(() => {
        return LCMD.loadFilterData((data) => {
            observe(Promise.resolve(data)).then(setData);
        });
    }, [refreshToken, processPlanTimestamp, filterState]);

    return { data, status, refresh };
}
