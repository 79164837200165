import { User } from "@/components/hooks/UserMapTypes";

export function downloadBuffer(buffer: ArrayBuffer, filename: string): void {
    // Convert buffer to a Blob
    const blob = new Blob([buffer], { type: "application/octet-stream" });

    // Create a URL for the Blob
    const url = window.URL.createObjectURL(blob);

    // Create an anchor element and set the href attribute to the Blob URL
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;

    // Append the anchor to the document body (required for Firefox)
    document.body.appendChild(a);

    // Programmatically click the anchor to trigger the download
    a.click();

    // Clean up: remove the anchor and revoke the Blob URL
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
}

export function getUserDisplayName(user: User): string {
    if (!user) {
        return null;
    }
    if (user.meta?.firstName && user.meta?.lastName) {
        return (user.meta.firstName + " " + user.meta.lastName).trim();
    } else {
        return user.email;
    }
}

export async function getUrlFromBlob(pdfSrc: string): Promise<string | void> {
    return fetch(pdfSrc)
        .then((response) => response.blob()) // Convert response to Blob
        .then((blob) => {
            return URL.createObjectURL(blob);
        })
        .catch((error) => console.error("Error loading blob:", error));
}
