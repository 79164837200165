﻿import {
    DataModel,
    DataOperation,
    DataOperationMaxIds,
    DataOperationTarget,
    DataOperationType,
    FrameworkErrorDataModelNeedsSync,
    MAX_TASK_DAYS,
} from "../DataModel";
import { ComplexProp } from "./complexProp";
import { TradesGetter } from "./tradesGetter";
import { assert, ParticleCore } from "particlecore";

export interface IDocumentTransaction {
    createProcess(ppid: number): number;

    setProcessProp<T>(
        pid: number,
        name: string | ComplexProp,
        value: T,
        opt?: { unit?: number; i?: number; tz?: string; type?: number },
    );

    get length(): number;

    createComment(): number;

    createCard(): number;
}

export class DocumentTransaction implements IDocumentTransaction {
    private readonly _model: DataModel;
    private _ops: DataOperation[] = [];
    private _maxIds: DataOperationMaxIds;
    protected readonly _newTrades: DataOperation[];

    constructor(
        core: ParticleCore,
        t?: ComplexDocumentTransaction,
        internal?: { model: DataModel; maxIds: DataOperationMaxIds },
    ) {
        const _model = internal?.model || core._model;
        this._model = _model;
        this._newTrades = (t as any)?._newTrades || [];
        this._maxIds = internal?.maxIds || ((t ? (t as any)._maxIds : _model.maxIds.slice()) as DataOperationMaxIds);
    }

    public get length() {
        return this._ops.length;
    }

    public reset(_maxIds?: DataOperationMaxIds) {
        this._maxIds = (_maxIds || this._model.maxIds.slice()) as DataOperationMaxIds;
        this._ops = [];
    }

    public commit(opt?: { ignoreReadonly?: boolean; forceSync?: (success: boolean) => void }) {
        if (this._ops.length > 0) {
            const commited0 = this._model.commitTS();
            assert(true === this._ops[this._ops.length - 1].group);
            delete this._ops[this._ops.length - 1].group;
            if (opt?.forceSync) {
                if (commited0 === this._model.syncCommitedTS().ofs) {
                    const _ops = this._ops;
                    const n_ops = _ops.length;
                    const _d = Math.floor(Date.now() / 1000 / 60);
                    for (let i_op = 0; i_op < n_ops; i_op++) {
                        const op = _ops[i_op];
                        if (!op._d) {
                            op._d = _d;
                        }
                    }
                    const safeSync = this._model._getSafeSyncState(0, _ops, commited0);
                    if (true && safeSync) {
                        const _safeSync = JSON.stringify(safeSync);
                        DataModel.pushStreamOps(this._model.storageName, safeSync, {
                            commitSandbox: undefined,
                            meta: { forcedSync: true },
                        })
                            .then((ret) => {
                                if (
                                    Array.isArray(ret?.ops) &&
                                    0 === ret.ops.length &&
                                    commited0 + _ops.length === ret.ofs
                                ) {
                                    this._model.pushOperation(_ops, opt);
                                    this._model.updateModel();
                                    const syncRet = this._model.getSync(0);
                                    assert(_safeSync === JSON.stringify(syncRet.sync)); // MUST BE THE SAME!!!!!!
                                    assert(1 === this._model.commitSync(ret, syncRet.token));
                                    opt.forceSync(true);
                                } else {
                                    opt.forceSync(false);
                                }
                            })
                            .catch((e) => {
                                opt.forceSync(false);
                            });
                    } else {
                        opt.forceSync(false);
                    }
                } else {
                    // pending commits...
                    opt.forceSync(false);
                }
            } else {
                this._model.pushOperation(this._ops, opt);
                this._model.updateModel();
            }
            this.reset();
        } else {
            if (opt?.forceSync) {
                opt?.forceSync(true);
            }
        }
    }

    public createProcess(ppid: number, opt?: { i: number; c?: 1 }): number {
        if ("number" === typeof ppid && ppid >= 0) {
            const taskId = ++this._maxIds[DataOperationTarget.TASKS];
            this._ops.push({
                op: DataOperationType.CREATE,
                target: DataOperationTarget.TASKS,
                id: taskId,
                name: "p",
                value: ppid,
                z: 0,
                _u: this._model.userName,
                group: true,
                i: 0,
                ...(opt || {}),
            });
            return taskId;
        } else {
            return null;
        }
    }

    public createTrade(name: string | TradesGetter) {
        if ("string" === typeof name) {
            const tradeId = ++this._maxIds[DataOperationTarget.TRADE];
            const op: DataOperation = {
                op: DataOperationType.CREATE,
                target: DataOperationTarget.TRADE,
                id: tradeId,
                name: "name",
                value: name,
                z: 0,
                _u: this._model.userName,
                group: true,
            };
            this._ops.push(op);
            this._newTrades.push(op);
            return tradeId;
        } else if (name instanceof TradesGetter) {
            const id = name.id;
            const core = name._core;
            const storage = core._model;
            assert(storage && storage.storageName && (storage.trades[id] ? true : false));
            if (!(storage.trades[id].__ < storage.syncCommited.ofs)) {
                // id must be synced!
                throw new FrameworkErrorDataModelNeedsSync();
            }
            if (this._model.storageName && storage.ops[storage.trades[id].__].r_sid in this._model._storageNames) {
                const r_id = storage.ops[storage.trades[id].__].r_id;
                return r_id;
            }
            const r_id = storage._transformId(DataOperationTarget.TRADE, id, true);
            for (let i_created = 0; i_created < this._newTrades.length; i_created++) {
                const op = this._newTrades[i_created];
                if (
                    DataOperationType.CREATE === op.op &&
                    DataOperationTarget.TRADE === op.target &&
                    op.r_sid in storage._storageNames &&
                    op.r_id === r_id
                ) {
                    return op.id;
                }
            }
            const tradeIds = Object.getOwnPropertyNames(this._model.trades);
            const i_ref = tradeIds.findIndex((trid) => {
                const trade = this._model.trades[trid];
                const op = this._model.ops[trade.__];
                assert(DataOperationTarget.TRADE === op.target && DataOperationType.CREATE === op.op);
                return op.r_sid in storage._storageNames && op.r_id === r_id;
            });
            let trid;
            if (i_ref < 0) {
                trid = ++this._maxIds[DataOperationTarget.TRADE];
                const op: DataOperation = {
                    op: DataOperationType.CREATE,
                    target: DataOperationTarget.TRADE,
                    id: trid,
                    name: "name",
                    value: name.value<string>("name", ""),
                    z: 0,
                    _u: this._model.userName,
                    r_sid: storage.storageName,
                    r_id: r_id,
                    r_ts: storage.commitTS(),
                    group: true,
                };
                this._ops.push(op);
                this._newTrades.push(op);
            } else {
                trid = Number.parseInt(tradeIds[i_ref]);
                assert(this._model.trades[trid]);
            }
            return trid;
        } else {
            return null;
        }
    }

    public isNewTradeId(id: number) {
        return id > this._model.maxIds[DataOperationTarget.TRADE];
    }

    public createComment(): number {
        const commentId = ++this._maxIds[DataOperationTarget.COMMENT];
        this._ops.push({
            op: DataOperationType.CREATE,
            target: DataOperationTarget.COMMENT,
            id: commentId,
            name: undefined,
            value: undefined,
            z: 0,
            _u: this._model.userName,
            group: true,
        });
        return commentId;
    }

    public createCard() {
        const cid = Math.max(this._maxIds[DataOperationTarget.ACTIVITY] + 1, MAX_TASK_DAYS);
        this._ops.push({
            op: DataOperationType.CREATE,
            target: DataOperationTarget.ACTIVITY,
            id: cid,
            name: undefined,
            value: undefined,
            z: 0,
            _u: this._model.userName,
            group: true,
        });
        this._maxIds[DataOperationTarget.ACTIVITY] = cid;
        return cid;
    }

    public setProcessProp<T>(
        pid: number,
        name: string | ComplexProp,
        value: T,
        opt?: { unit?: number; i?: number; tz?: string; type?: number; createdAt?: number },
    ) {
        const _name = "string" === typeof name ? name : name._value;
        let _value: any = value;
        if ("start" === _name && 0 < _value && _value < MAX_TASK_DAYS && 1 !== this._model.compat.gpaFix) {
            _value = (_value - 1) / this._model.compat.gpaFix + 1;
        }

        const opToAdd = {
            ...(opt || {}),
            op: DataOperationType.UPDATE,
            target: DataOperationTarget.TASKS,
            id: pid,
            name: _name,
            value: _value,
            z: 0,
            _u: this._model.userName,
            group: true,
        };
        this._ops.push(opToAdd);
    }

    public setTradeProp<T>(trId: number, name: string | ComplexProp, value: T) {
        this._ops.push({
            op: DataOperationType.UPDATE,
            target: DataOperationTarget.TRADE,
            id: trId,
            name: "string" === typeof name ? name : name._value,
            value: value,
            z: 0,
            _u: this._model.userName,
            group: true,
        });
    }

    public setHiveProp<T>(
        hid: number | undefined,
        name: string | ComplexProp | ((hid) => string | ComplexProp),
        value: T,
    ): number {
        const _hasHid = "number" === typeof hid && hid >= 0;
        const _hid = _hasHid ? hid : ++this._maxIds[DataOperationTarget.HIVE];
        if ("function" === typeof name) {
            name = name(_hid);
        }
        this._ops.push({
            op: _hasHid ? DataOperationType.UPDATE : DataOperationType.CREATE,
            target: DataOperationTarget.HIVE,
            id: _hid,
            name: "string" === typeof name ? name : name._value,
            value: value,
            z: 0,
            _u: this._model.userName,
            group: true,
        });
        return _hid;
    }
}

export class ComplexDocumentTransaction {
    private _model: DataModel;
    private _ops: DataOperation[][] = [];
    private _maxIds: DataOperationMaxIds;
    protected readonly _newTrades: DataOperation[] = [];

    constructor(core: ParticleCore) {
        this._model = core._model;
        this._maxIds = core._model.maxIds.slice() as DataOperationMaxIds;
    }

    public commit(t?: DocumentTransaction | { calcYIndex: number[] }): boolean {
        if (t && t instanceof DocumentTransaction) {
            assert((t as any)._maxIds === this._maxIds);
            const ops = (t as any)._ops;
            if (Array.isArray(ops) && ops.length > 0) {
                this._ops.push(ops);
            }
            t.reset(this._maxIds);
            assert((t as any)._maxIds === this._maxIds);
            return true;
        } else {
            const ret = this._ops.length > 0;
            if (ret) {
                const _ops = this._model.reduceAndFinalizeOps(this._ops);
                this._model.pushOperation(_ops);
                this._model.updateModel();
                if (Array.isArray((t as any)?.calcYIndex)) {
                    const calcYIndex = (t as any)?.calcYIndex;
                    const n_calcYIndex = calcYIndex.length;
                    for (let i = 0; i < n_calcYIndex; i++) {
                        const c = this._model.tasks[calcYIndex[i]]?._c;
                        assert(Array.isArray(c));
                        this._model._adjustYIndex(c, 0, c.length, true);
                    }
                }
                this._ops = [];
                this._maxIds = this._model.maxIds.slice() as DataOperationMaxIds;
            }
            return ret;
        }
    }
}
