import * as React from "react";
import { useEffect, useState } from "react";
import { Calendar, ChevronDown, ChevronRight, Save, Search, Trash, X } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { cn } from "@/lib/utils";
import { LcmdModal2, ModalSizeTypes } from "@/components/common/LcmModal2";
import { assert, ProcessStatuses } from "@/model/GlobalHelper";
import { Checkbox } from "@/components/ui/checkbox";
import { Label } from "@/components/ui/label";
import { DataModelFilter, DataModelFilterSavedAs, DigitalPlanningBoardTradeDetails, intl } from "lcmd2framework";
import { copyAndSort } from "@/components/Utils";
import { useLCMD } from "@/app/LCMContext";
import { CheckedState } from "@radix-ui/react-checkbox";
import { DialogTitle } from "@/components/ui/dialog";
import { SaveFilter } from "@/components/Filter/FilterV2/SaveFilter";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useProcessPlanTimestamp } from "@/app/store/canvasStore";
import { _onFormatDate } from "@/legacy/GlobalHelperFluentUI";
import { TimeFrameSelectionModal } from "@/components/Filter/FilterV2/modals/TimeFrameSelectionModal";
import { EGlobeState } from "@/components/common/globeField";
import { DeleteConfirmModal } from "@/components/Filter/FilterV2/modals/DeleteConfirmModal";
import { InputWithGlobeField } from "@/components/Filter/FilterV2/InputWithGlobeField";
import { getDateAndTime } from "@/components/utils/date/dateAndTime";
import { LCMDTooltip } from "@/components/common/Tooltip/LCMDTooltip";
import { useFilter } from "@/app/store/userJourneyStore";
import { WebAppTelemetryFactory } from "@/app/services/WebAppTelemetry.service";
import { MyFilterTabView } from "@/components/Filter/FilterV2/MyFilterTabView";
import { AreaCard } from "@/components/Filter/FilterV2/AreaCard";
import { TradeModal } from "@/components/Filter/FilterV2/modals/TradeModal";
import { TradeCard } from "@/components/Filter/FilterV2/modals/TradeCard";
import { StatusCard } from "@/components/Filter/FilterV2/StatusCard";

function FilterHeadline({ children }: { children: string }) {
    return <div className="mb-4 text-base font-semibold">{children}</div>;
}

const ProcessStatusesEnumValues: any = Object.values(ProcessStatuses).filter((value) => typeof value === "number");

const timeframes = ["every", "4weeks", "6weeks", "4months", "custom"] as const;
const today = getDateAndTime(new Date().getTime())?.toString() || "";

function getAllChildren(parent, allItems) {
    const ret = [];
    const parentIndex = allItems.findIndex((element) => element.tid === parent.tid);
    for (let i = parentIndex + 1; i < allItems.length; i++) {
        if (parent.l >= allItems[i].l) {
            break;
        }
        ret.push(allItems[i]);
    }

    return ret;
}

export type Duration = {
    start?: Date;
    end?: Date;
};

// todo: mit willi reden ob wir types in ne extra datei packen
export type FilterDuration = {
    start?: number;
    end?: number;
    formattedStart: Date;
    formattedEnd: Date;
    selection: (typeof timeframes)[number];
};

type Filter = {
    localAreas: any[];
    localTrades: any[];
    filterDuration: FilterDuration;
    status: ProcessStatuses[];
};

export function FilterModal({
    isOpen,
    onDismiss,
    tab,
    webtrackingPrefix,
}: {
    isOpen: boolean;
    onDismiss: () => void;
    tab?: "filter" | "myFilters";
    webtrackingPrefix?: string;
}) {
    const [emptyFilter, setEmptyFilter] = useState<Filter>({
        localAreas: [],
        localTrades: [],
        filterDuration: {
            start: undefined,
            end: undefined,
            formattedStart: undefined,
            formattedEnd: undefined,
            selection: "every",
        },
        status: [],
    });
    const LCMD = useLCMD();
    const processPlanTimestamp = useProcessPlanTimestamp();

    const [isSaveFilterOpen, setIsSaveFilterOpen] = useState(false);
    const [currentTab, setCurrentTab] = useState<"filter" | "myFilters">(tab || "filter");
    const [currentlySelectedFilters, setCurrentlySelectedFilters] = useState<Filter>(emptyFilter);
    const [isAnySubModalOpen, setIsAnySubModalOpen] = useState(false);
    const [currentEditFilter, setCurrentEditFilter] = useState(null);
    const [allAreas, setAllAreas] = useState([]);
    const [allTrades, setAllTrades] = useState([]);
    const [editName, setEditName] = useState("");
    const [editGlobal, setEditGlobal] = useState<EGlobeState>(EGlobeState.Private);
    const [deleteFilter, setDeleteFilter] = useState<DataModelFilterSavedAs>(null);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const filterState = useFilter();
    const [startFilter, setStartFilter] = useState(emptyFilter);

    const [projectDuration, setProjectDuration] = useState<Duration>({
        start: undefined,
        end: undefined,
    });

    useEffect(() => {
        setCurrentTab(tab);
    }, [tab]);

    useEffect(() => {
        const openDeleteDialog = deleteFilter !== null;
        setShowDeleteDialog(openDeleteDialog);
        setTimeout(() => {
            handleOpenSubModalChanged(openDeleteDialog);
        });
    }, [deleteFilter]);

    useEffect(() => {
        return LCMD.loadFilterData((data) => {
            const wbs: any[] = data?.wbs || [];

            const filterEntity = data?.filterCollection.find(
                (filter) =>
                    currentEditFilter &&
                    filter._hid === currentEditFilter?._hid &&
                    filter.global === currentEditFilter?.global,
            );

            const filter: DataModelFilterSavedAs = filterEntity ? filterEntity._filter : data?.filter;

            const filteredAreas = filter?.tz || [];
            const selectedAreas = filteredAreas.map((selected) => wbs.find((area) => area.tid == selected)) || [];
            setAllAreas(wbs);
            const trades: DigitalPlanningBoardTradeDetails[] = data?.trades || [];
            const sortedTrades = copyAndSort(trades, "label");
            const filteredTrades = filter?.trade || [];
            const selectedTrades = filteredTrades.map((selected) => sortedTrades.find((trade) => trade.id == selected));
            const projectStartDate = new Date(data.startDate);
            const projectEndDate = new Date(data.endDate);

            setProjectDuration({
                start: projectStartDate,
                end: projectEndDate,
            });

            setEmptyFilter({
                ...emptyFilter,
                filterDuration: {
                    start: undefined,
                    end: undefined,
                    formattedStart: projectStartDate,
                    formattedEnd: projectEndDate,
                    selection: "every",
                },
            });

            const startDate = filter?.d?.at(0) !== undefined ? filter?.d[0] : undefined;

            let endDate: number = undefined;
            let selection: FilterDuration["selection"] = "every";
            let formattedEndTimestamp: number = projectEndDate.getTime();
            if (filter?.d?.at(1)) {
                endDate = filter?.d?.at(1);
                const startDateAsDate = startDate === 0 ? new Date() : new Date(startDate);
                if (endDate == 4) {
                    formattedEndTimestamp = startDateAsDate.setDate(startDateAsDate.getDate() + 28);
                    selection = "4weeks";
                } else if (filter.d[1] == 6) {
                    formattedEndTimestamp = startDateAsDate.setDate(startDateAsDate.getDate() + 42);
                    selection = "6weeks";
                } else if (filter.d[1] == 260) {
                    formattedEndTimestamp = startDateAsDate.setMonth(startDateAsDate.getMonth() + 4);
                    selection = "4months";
                } else {
                    formattedEndTimestamp = filter.d[1];
                    selection = "custom";
                }
            }

            let formattedStart: Date;
            if (startDate === undefined) {
                formattedStart = projectStartDate;
            } else if (startDate === 0) {
                formattedStart = new Date();
            } else {
                formattedStart = new Date(startDate);
            }

            const formattedEnd = new Date(formattedEndTimestamp);

            const filterDuration = {
                start: startDate,
                end: endDate,
                selection,
                formattedStart,
                formattedEnd,
            };

            setAllTrades(sortedTrades);
            setCurrentlySelectedFilters({
                localAreas: selectedAreas,
                localTrades: selectedTrades,
                status: filter?.status ? [...filter.status.values()] : [],
                filterDuration: filterDuration,
            });
            setStartFilter({
                localAreas: selectedAreas,
                localTrades: selectedTrades,
                status: filter?.status ? [...filter.status.values()] : [],
                filterDuration: filterDuration,
            });
        });
    }, [
        LCMD,
        setCurrentlySelectedFilters,
        setAllAreas,
        setAllTrades,
        processPlanTimestamp,
        currentEditFilter,
        filterState,
    ]);

    function toLCMDFilter(tzFilter, tradesFilter, dateFilter, statusFilter): DataModelFilter {
        return {
            tz: tzFilter.length > 0 ? tzFilter.map((tz) => Number.parseInt(tz.tid)) : undefined,
            trade: tradesFilter.length > 0 ? tradesFilter.map((trade) => Number.parseInt(trade.id)) : undefined,
            d: dateFilter,
            status: new Set<ProcessStatuses>(statusFilter),
        };
    }

    function onFilterChanged(newFilter) {
        setCurrentlySelectedFilters(newFilter);
    }

    const compareArrays = (a: number[], b: number[]): boolean => {
        return (a?.length === b?.length && a?.every((e, i) => e === b?.at(i))) ?? true;
    };

    const compareSets = (a: Set<number>, b: Set<number>): boolean => {
        return (a?.size === b?.size && [...a]?.every((x) => b?.has(x))) ?? true;
    };

    const handleSetFilter = (saveFilterData: { name?: string; global?: boolean; pos?: number; hid?: number }) => {
        const { localAreas, localTrades, filterDuration, status } = currentlySelectedFilters;

        if (
            localAreas.length == 0 &&
            localTrades.length == 0 &&
            status.length == 0 &&
            filterDuration?.start === undefined &&
            filterDuration?.end === undefined
        ) {
            LCMD.setFilter(null);
            return;
        }

        const dateFilter =
            (filterDuration?.start === 0 || filterDuration?.start) && filterDuration?.end
                ? [filterDuration.start, filterDuration.end]
                : null;
        const saveAs = saveFilterData && {
            name: saveFilterData.name,
            global: saveFilterData.global,
            pos: saveFilterData.pos,
            hid: saveFilterData.hid,
        };
        let filter: DataModelFilterSavedAs = {
            ...toLCMDFilter(localAreas, localTrades, dateFilter, status),
        };
        if (saveAs) {
            filter = { ...filter, saveAs: saveAs };
        }

        const payload = {
            name: currentEditFilter?.name !== filter?.saveAs?.name,
            timeframe: !compareArrays(currentEditFilter?._filter?.d, filter?.d),
            status: !compareSets(currentEditFilter?._filter?.status, filter?.status),
            areas: !compareArrays(currentEditFilter?._filter?.tz, filter?.tz),
            trades: !compareArrays(currentEditFilter?._filter?.trade, filter?.trade),
        };
        WebAppTelemetryFactory.trackEvent(`${webtrackingPrefix}-filter-updated`, payload);
        LCMD.setFilter(filter);
    };

    function handleFilterEdit(filterItem) {
        setCurrentTab("filter");
        setEditName(filterItem?.name !== undefined && filterItem.name !== "" ? filterItem.name : today);
        setEditGlobal(Number(filterItem.global));
        setCurrentEditFilter(filterItem);
    }

    function handleFilterDelete(filterItem: DataModelFilterSavedAs) {
        WebAppTelemetryFactory.trackEvent(`${webtrackingPrefix}-delete-filters`);
        setCurrentTab("myFilters");
        LCMD.setFilter(filterItem);
        setDeleteFilter(null);
    }

    function handleCloseModal() {
        if (isAnySubModalOpen) {
            return;
        }
        setCurrentlySelectedFilters(startFilter);
        setCurrentEditFilter(null);
        onDismiss();
    }

    function isFilterSavable() {
        if (currentEditFilter !== null) {
            return false;
        }
        const { localAreas, localTrades, filterDuration, status } = currentlySelectedFilters;
        const isStatusInitial = status.length == 0;
        const isTradeInitial = localTrades.length == 0;
        const isAreasInitial = localAreas.length == 0;
        const isTimeframeInitial =
            filterDuration?.formattedEnd?.getTime() === projectDuration?.end?.getTime() &&
            filterDuration?.formattedStart?.getTime() === projectDuration?.start?.getTime();
        return !(isStatusInitial && isAreasInitial && isTradeInitial && isTimeframeInitial);
    }

    const modalHeader = (
        <div className={"flex h-full items-center justify-between"}>
            <DialogTitle className="overflow-hidden overflow-ellipsis whitespace-nowrap pb-2">
                <TabsList className="grid w-full grid-cols-2">
                    <TabsTrigger value="filter">{intl.get("canvas.cmd.filter.text")}</TabsTrigger>
                    <TabsTrigger value="myFilters">{intl.get("filter.myFilters")}</TabsTrigger>
                </TabsList>
            </DialogTitle>
            <div className={"right-32 flex items-center space-x-4"}>
                {currentTab === "filter" && (
                    <div className={"flex items-center border-r-2 border-slate-200 p-2 pr-4"}>
                        {currentEditFilter === null && (
                            <LCMDTooltip text={intl.get("filter.main.save.title")}>
                                <Save
                                    size={16}
                                    className={isFilterSavable() ? "font-black" : "text-slate-200"}
                                    onClick={() => {
                                        if (!isFilterSavable()) {
                                            return;
                                        }
                                        saveFilter();
                                    }}
                                ></Save>
                            </LCMDTooltip>
                        )}
                        {currentEditFilter !== null && (
                            <LCMDTooltip text={intl.get("filter.manage.cmd.delete.text")}>
                                <Trash
                                    size={16}
                                    onClick={() => {
                                        setDeleteFilter({
                                            saveAs: {
                                                hid: currentEditFilter._hid,
                                                name: currentEditFilter.name,
                                                global: currentEditFilter.global,
                                                pos: currentEditFilter.pos,
                                            },
                                        });
                                        setCurrentEditFilter(null);
                                    }}
                                ></Trash>
                            </LCMDTooltip>
                        )}
                    </div>
                )}
                <X
                    className={"cursor-pointer"}
                    size={20}
                    onClick={() => {
                        handleCloseModal();
                    }}
                ></X>
            </div>
        </div>
    );

    function handleOpenSubModalChanged(isAnySubModalOpen: boolean) {
        setIsAnySubModalOpen(isAnySubModalOpen);
    }

    function saveFilter() {
        WebAppTelemetryFactory.trackEvent(`${webtrackingPrefix}-filter-saved`);
        handleOpenSubModalChanged(true);
        setIsSaveFilterOpen(true);
    }

    const modalButtons =
        currentTab === "filter"
            ? [
                  currentEditFilter === null && (
                      <Button
                          key={"resetFilter"}
                          variant="outline"
                          onClick={() => {
                              WebAppTelemetryFactory.trackEvent(`${webtrackingPrefix}-clear-filter-clicked`);
                              if (filterState === null) {
                                  setCurrentlySelectedFilters(emptyFilter);
                              }
                              LCMD.setFilter(null);
                          }}
                      >
                          {intl.get("filter.main.clear.text")}
                      </Button>
                  ),
                  currentEditFilter !== null && (
                      <Button
                          key="cancel"
                          variant="outline"
                          onClick={() => {
                              setCurrentEditFilter(null);
                              setCurrentTab("myFilters");
                          }}
                      >
                          {intl.get("fw.cancel")}
                      </Button>
                  ),
                  <Button
                      key={"setFilter"}
                      onClick={() => {
                          if (currentEditFilter === null) {
                              handleSetFilter({});
                              onDismiss();
                          } else {
                              handleSetFilter({
                                  name: editName !== "" ? editName : today,
                                  global: Boolean(editGlobal),
                                  pos: currentEditFilter._pos,
                                  hid: currentEditFilter._hid,
                              });
                              setCurrentEditFilter(null);
                              setCurrentTab("myFilters");
                          }
                      }}
                  >
                      {intl.get("filter.manage.cmd.apply.text")}
                  </Button>,
              ]
            : [];

    return (
        <>
            {showDeleteDialog && (
                <DeleteConfirmModal
                    onOk={() => handleFilterDelete({ saveAs: { ...deleteFilter.saveAs, name: null } })}
                    onCancel={() => setDeleteFilter(null)}
                    filterName={deleteFilter?.saveAs?.name}
                />
            )}
            {isSaveFilterOpen && (
                <SaveFilter
                    isOpen={isSaveFilterOpen}
                    onClose={() => {
                        setIsSaveFilterOpen(false);
                        handleOpenSubModalChanged(false);
                    }}
                    onSave={(saveData) => {
                        WebAppTelemetryFactory.trackEvent(`${webtrackingPrefix}-filter-share-with-everyone`, {
                            value: saveData.global,
                        });
                        handleSetFilter(saveData);
                        handleOpenSubModalChanged(false);
                        setIsSaveFilterOpen(false);
                    }}
                ></SaveFilter>
            )}
            <Tabs
                className={cn(isAnySubModalOpen && "cursor-default")}
                value={currentTab}
                onValueChange={(value: "filter" | "myFilters") => {
                    if (isAnySubModalOpen) {
                        return;
                    }
                    setCurrentTab(value);
                }}
            >
                <LcmdModal2
                    open={isOpen}
                    size={ModalSizeTypes.m}
                    header={modalHeader}
                    onOpenChange={() => {
                        handleCloseModal();
                    }}
                    buttons={modalButtons}
                    closable={false}
                >
                    <div
                        className={cn("overflow-y-auto", isAnySubModalOpen && "pointer-events-none")}
                        onClick={(e) => {
                            if (isAnySubModalOpen) {
                                e.preventDefault();
                            }
                        }}
                    >
                        <TabsContent value="filter">
                            {currentEditFilter !== null && (
                                <div className={"py-4 pb-8"}>
                                    <FilterHeadline>{intl.get("filter.filterName")}</FilterHeadline>
                                    <InputWithGlobeField
                                        placeholder={today}
                                        initialGlobeState={editGlobal}
                                        onGlobalStateChange={(globalState) => {
                                            setEditGlobal(globalState);
                                        }}
                                        value={editName}
                                        onInputValueChange={(value) => setEditName(value)}
                                    ></InputWithGlobeField>
                                </div>
                            )}
                            <FilterTabView
                                onFilterChanged={onFilterChanged}
                                currentFilter={currentlySelectedFilters}
                                onOpenSubModalChanged={handleOpenSubModalChanged}
                                allAreas={allAreas}
                                allTrades={allTrades}
                                projectDuration={projectDuration}
                                emptyFilter={emptyFilter}
                            />
                        </TabsContent>
                        <TabsContent value={"myFilters"}>
                            <MyFilterTabView
                                onFilterSelect={onDismiss}
                                onFilterEdit={handleFilterEdit}
                                onFilterDelete={setDeleteFilter}
                                webtrackingPrefix={webtrackingPrefix}
                            />
                        </TabsContent>
                    </div>
                </LcmdModal2>
            </Tabs>
        </>
    );
}

function FilterTabView({
    onFilterChanged,
    onOpenSubModalChanged,
    currentFilter: defaultFilter,
    allTrades,
    allAreas,
    projectDuration,
    emptyFilter,
}: {
    onFilterChanged: (filter) => void;
    onOpenSubModalChanged: (isAnySubModalOpen: boolean) => void;
    currentFilter: any;
    allTrades: any[];
    allAreas: any[];
    projectDuration: Duration;
    emptyFilter: Filter;
}) {
    const LCMD = useLCMD();
    const [isAreasExpanded, setIsAreasExpanded] = useState(false);
    const [isTradesExpanded, setIsTradesExpanded] = useState(false);
    const [isAreasDialogOpen, setIsAreasDialogOpen] = useState(false);
    const [isTradesDialogOpen, setIsTradesDialogOpen] = useState(false);
    const [isTimeframeDialogOpen, setIsTimeframeDialogOpen] = useState(false);
    const [currentFilter, setCurrentFilter] = useState<Filter>(defaultFilter ?? emptyFilter);

    const [filterDateString, setFilterDateString] = useState(intl.get("filter.main.date.add.text"));

    useEffect(() => {
        if (!defaultFilter) {
            return;
        }
        setCurrentFilter(defaultFilter);
    }, [defaultFilter]);

    useEffect(() => {
        onOpenSubModalChanged(isTimeframeDialogOpen);
    }, [isTimeframeDialogOpen]);

    useEffect(() => {
        if (!currentFilter?.filterDuration?.formattedStart || !currentFilter?.filterDuration?.formattedEnd) {
            setFilterDateString(intl.get("filter.main.date.add.text"));
            return;
        }

        setFilterDateString(
            currentFilter?.filterDuration?.formattedEnd?.getTime() !== projectDuration?.end?.getTime() ||
                currentFilter?.filterDuration?.formattedStart?.getTime() !== projectDuration?.start?.getTime()
                ? `${_onFormatDate(currentFilter.filterDuration?.formattedStart)} - ${_onFormatDate(currentFilter.filterDuration.formattedEnd)}`
                : intl.get("filter.main.date.add.text"),
        );
    }, [currentFilter]);

    useEffect(() => {
        onFilterChanged(currentFilter);
    }, [currentFilter]);

    const handleStatusToggle = (statusItem: ProcessStatuses) => {
        let newStatus;
        if (currentFilter.status.includes(statusItem)) {
            currentFilter.status.splice(currentFilter.status.indexOf(statusItem), 1);
            newStatus = [...currentFilter.status];
        } else {
            newStatus = [...currentFilter.status, statusItem];
        }
        setCurrentFilter({ ...currentFilter, status: newStatus });
    };

    return (
        <div className={"relative flex max-h-96 w-full flex-col gap-8"}>
            <div className={"pt-4"}>
                <FilterHeadline>{intl.get("filter.main.date.label")}</FilterHeadline>
                <Button
                    variant="outline"
                    className="w-full justify-start"
                    onClick={() => {
                        setIsTimeframeDialogOpen(true);
                    }}
                >
                    <Calendar className="mr-2 h-4 w-4" />
                    {filterDateString}
                </Button>
            </div>
            <TimeFrameSelectionModal
                isOpen={isTimeframeDialogOpen}
                filterDuration={currentFilter.filterDuration}
                projectDuration={projectDuration}
                onClose={() => setIsTimeframeDialogOpen(false)}
                onTimeFrameSave={(filterDuration) => {
                    setCurrentFilter({ ...currentFilter, filterDuration });
                    setIsTimeframeDialogOpen(false);
                }}
            />
            <div>
                <FilterHeadline>{intl.get("filter.main.status.label")}</FilterHeadline>
                <div className="flex flex-wrap gap-2 pb-8">
                    {ProcessStatusesEnumValues.map((statusItem) => (
                        <StatusCard
                            key={"statuscard-" + statusItem}
                            onClick={(selectStatus) => handleStatusToggle(selectStatus)}
                            status={statusItem}
                            isSelected={currentFilter.status.includes(statusItem)}
                        ></StatusCard>
                    ))}
                </div>
                <div className="my-2 h-[1px] w-full bg-gray-200"></div>
            </div>
            <FilterSection
                title={intl.get("filter.main.tz.label")}
                selectedItems={currentFilter.localAreas}
                onSelectedItemsChanged={(localAreas) => setCurrentFilter({ ...currentFilter, localAreas })}
                isExpanded={isAreasExpanded}
                onShowList={() => {
                    setIsAreasDialogOpen(true);
                }}
                setIsExpanded={setIsAreasExpanded}
            />
            <FilterSection
                title={intl.get("filter.main.trades.label")}
                selectedItems={currentFilter.localTrades}
                onSelectedItemsChanged={(localTrades) => {
                    setCurrentFilter({ ...currentFilter, localTrades });
                }}
                isExpanded={isTradesExpanded}
                setIsExpanded={setIsTradesExpanded}
                onShowList={() => {
                    setIsTradesDialogOpen(true);
                }}
                isTrade
            />
            <TradeModal
                isDialogOpen={isTradesDialogOpen}
                onOpenChange={() => {
                    setIsTradesDialogOpen(false);
                }}
                title={intl.get("filter.main.trades.label")}
                selectedItems={currentFilter.localTrades}
                allItems={allTrades}
                onItemSelectionChanged={(localTrades) => {
                    setCurrentFilter({ ...currentFilter, localTrades });
                }}
            ></TradeModal>

            <AreaModal
                isDialogOpen={isAreasDialogOpen}
                onOpenChange={() => {
                    setIsAreasDialogOpen(false);
                }}
                title={intl.get("filter.main.tz.label")}
                selectedItems={currentFilter.localAreas}
                allItems={allAreas}
                onItemSelectionChanged={(localAreas) => {
                    setCurrentFilter({ ...currentFilter, localAreas });
                }}
            ></AreaModal>
        </div>
    );
}

function FilterSection({
    title,
    selectedItems,
    onSelectedItemsChanged,
    isExpanded,
    setIsExpanded,
    isTrade = false,
    onShowList,
}: {
    title: string;
    selectedItems: any[];
    onSelectedItemsChanged: (items: any[]) => void;
    isExpanded: boolean;
    setIsExpanded: (isExpanded: boolean) => void;
    onShowList: () => void;
    isTrade?: boolean;
}) {
    const visibleItems = isExpanded ? selectedItems : selectedItems.slice(0, 4);
    const searchVocabulary = isTrade ? "filter.main.trades.add.search" : "filter.main.tz.add.search";

    return (
        <div
            className={`space-y-2 rounded-2xl p-4 ${!isTrade && "bg-slate-100"} ${isTrade && "border border-slate-100"}`}
        >
            <div className="mb-4 flex items-center justify-between">
                <div>
                    <div className={"text-2xl font-semibold"}>{title}</div>
                    <div>
                        <span className="text-sm text-gray-500">
                            {selectedItems.length} {intl.get("filter.filterSection.selected")}
                        </span>
                    </div>
                </div>
                <div>
                    <Button variant="link" className={"flex items-center"} size="sm" onClick={() => onShowList()}>
                        {intl.get(searchVocabulary)}
                        <Search className="ml-2 h-4 w-4" />
                    </Button>
                </div>
            </div>
            <div className="flex flex-wrap gap-2">
                {visibleItems.map((item, index) => {
                    return (
                        <div key={index} className="flex h-[30px] items-center gap-1 space-x-1 rounded-full p-1">
                            {!isTrade && item && (
                                <AreaCard
                                    name={item?.n || ""}
                                    index={index}
                                    onDelete={(name) => {
                                        const newItems = selectedItems.filter((i) => i.n !== name);
                                        onSelectedItemsChanged(newItems);
                                    }}
                                />
                            )}

                            {isTrade && item && (
                                <TradeCard
                                    id={item.id}
                                    name={item.name}
                                    color={item.color}
                                    index={index}
                                    icon={item.icon}
                                    onClick={(selectedItem) => {
                                        const newItems = selectedItems.filter((item) => item.id !== selectedItem);
                                        onSelectedItemsChanged(newItems);
                                    }}
                                ></TradeCard>
                            )}
                        </div>
                    );
                })}
            </div>
            {selectedItems.length > 4 && (
                <div className={"flex justify-center"}>
                    <Button variant="ghost" size="sm" onClick={() => setIsExpanded(!isExpanded)}>
                        <ChevronDown className={cn("h-4 w-4 transition-transform", isExpanded && "rotate-180")} />
                    </Button>
                </div>
            )}
        </div>
    );
}

function searchWBS(wbs: any[], search: string) {
    const terms = [search.trim().toLocaleLowerCase()];
    const n_terms = terms.length;
    const path = [];
    const n = wbs.length;
    for (let i = 0; i < n; i++) {
        const item = wbs[i];
        while (item.l < path.length) {
            path.pop();
        }
        assert(item.l === path.length);
        const name: string = (item.n || "").trim().toLocaleLowerCase();
        let hit = false;
        for (let i_term = 0; i_term < n_terms; i_term++) {
            hit = hit || name.indexOf(terms[i_term]) >= 0;
        }
        if (hit) {
            // mark path
            const n_path = path.length;
            for (let i_path = 0; i_path < n_path; i_path++) {
                path[i_path]._hit |= 0x1;
            }
            item._hit = 0x3;
        } else {
            item._hit = 0; // clear flag...
        }
        path.push(item);
    }
}

function filterWBS(allItems, collapsed: any, selected: any, search: string) {
    const ret = [];

    if (search) {
        searchWBS(allItems, search);
    }

    const n = allItems.length;
    for (let i = 0; i < n; ) {
        const sel = allItems[i].tid in selected;
        if (allItems[i].tid in collapsed) {
            ret.push({ ...allItems[i], s: 2, sel: sel });
            let j = i + 1;
            while (j < n && allItems[j].l > allItems[i].l) j++;
            i = j;
        } else if (!search || allItems[i]._hit & 0x1) {
            const _hit = search ? allItems[i]._hit : 0;
            if (sel) {
                ret.push({ ...allItems[i++], sel: sel, hit: _hit });
            } else if (_hit) {
                ret.push({ ...allItems[i++], hit: _hit });
            } else {
                ret.push(allItems[i++]);
            }
        } else {
            i++; // skip...
        }
    }
    return ret;
}

function AreaModal({
    isDialogOpen,
    onOpenChange,
    title,
    allItems = [],
    onItemSelectionChanged,
    selectedItems = [],
}: any) {
    const [searchTerm, setSearchTerm] = useState("");
    const [collapsed, setCollapsed] = useState({});
    const [selected, setSelected] = useState(selectedItems);

    function handleSelectChange(item) {
        let newItems, newSelection;

        if (selected.find((select) => select.tid === item.tid)) {
            newItems = selected.filter((i) => i.tid !== item.tid);
            newSelection = unselectChildren(item, newItems);
            setSelected(newSelection);
        } else {
            newItems = [...selected, item];
            const itemChildren = getAllChildren(item, allItems);
            newSelection = newItems.concat(itemChildren);
            setSelected(newSelection);
        }
    }

    function unselectChildren(parent, currentSelection) {
        const children = getAllChildren(parent, allItems);
        if (children.length === 0) {
            return currentSelection;
        }
        const childrenIds = new Set(children.map((child) => child.tid));
        return currentSelection.filter((item) => !childrenIds.has(item.tid));
    }

    useEffect(() => {
        if (!selectedItems) {
            return;
        }
        setSelected(selectedItems);
    }, [selectedItems]);

    const filteredItems = filterWBS(allItems, collapsed, selected, searchTerm);

    const isAllSelected: CheckedState =
        selected.length == filteredItems.length ? true : selected.length >= 1 ? "indeterminate" : false;

    function onCollapseClick(item: any) {
        if (2 === item.s || 1 === item.s) {
            const collapse = 1 === item.s;
            setCollapsed((collapsed) => {
                const ret = Object.assign({}, collapsed);
                if (collapse) {
                    ret[item.tid] = true;
                } else {
                    delete ret[item.tid];
                }
                return ret;
            });
        }
    }

    function handleOkClick() {
        onItemSelectionChanged(selected);
        onOpenChange();
    }
    function handleCancelClick() {
        setSelected([...selectedItems]);
        onOpenChange();
    }

    return (
        <LcmdModal2
            open={isDialogOpen}
            onOpenChange={handleCancelClick}
            header={{ title: title }}
            size={ModalSizeTypes.m}
            buttons={[
                <Button key="cancel" variant="outline" onClick={handleCancelClick}>
                    {intl.get("fw.cancel")}
                </Button>,
                <Button key="ok" onClick={handleOkClick}>
                    {intl.get("fw.ok")}
                </Button>,
            ]}
        >
            <div className="space-y-4">
                <Input
                    placeholder={intl.get("filter.main.tz.add.search")}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                <div className="h-[300px] space-y-2 overflow-y-auto">
                    <Checkbox
                        checked={isAllSelected}
                        onClick={() => {
                            const newCheckedState: CheckedState = isAllSelected === "indeterminate" || !isAllSelected;
                            setSelected(newCheckedState ? filteredItems : []);
                        }}
                    />
                    {filteredItems.map((item, index) => (
                        <div
                            key={index}
                            className="flex items-center space-x-2"
                            style={{ paddingLeft: 16 * item.l + "px" }}
                        >
                            {collapsed[item.tid] ? (
                                <ChevronDown onClick={() => onCollapseClick(item)} />
                            ) : (
                                <ChevronRight onClick={() => onCollapseClick(item)} />
                            )}

                            <Checkbox
                                id={`${title}-${index}`}
                                key={`${title}-${index}`}
                                checked={!!selected.find((select) => select.tid === item.tid)}
                                onCheckedChange={() => {
                                    handleSelectChange(item);
                                }}
                            />
                            <Label htmlFor={`${title}-${index}`}>{item.n}</Label>
                        </div>
                    ))}
                </div>
            </div>
        </LcmdModal2>
    );
}
