import { useMemo } from "react";
import { useActiveBaseline } from "@/app/store/baselineStore";
import { useUserView } from "@/app/store/userJourneyStore";
import { ViewTypes } from "@/components/view/constants";
import { getCurrentLocale } from "@/components/utils/date/locale";
import { getLocaleDateAndTime } from "@/components/utils/date/dateAndTime";
import { LCMDTooltip } from "@/components/common/Tooltip/LCMDTooltip";

const DATE_MULTIPLIER = 1000 * 60; // Converts minutes to milliseconds
const MAX_LENGTH = 26; // Maximum length of the baseline text to be displayed

export function ActiveBaselineIndicator() {
    const viewType = useUserView();
    const activeBaseline = useActiveBaseline();

    const baselineText = useMemo(() => {
        if (activeBaseline?.name) {
            return activeBaseline.name;
        }

        if (activeBaseline?.date) {
            const locale = getCurrentLocale().code;
            const formattedDate = getLocaleDateAndTime(activeBaseline.date * DATE_MULTIPLIER, locale);
            return formattedDate?.toString() || null;
        }

        return null;
    }, [activeBaseline]);

    // Early return if not in baseline view or no baseline text
    if (viewType !== ViewTypes.BASELINE || !baselineText) {
        return null;
    }

    return (
        <div
            data-testid="active-baseline-indicator"
            className="font-inter mr-1 h-5 min-w-fit rounded-sm bg-violet-100 px-2 py-px text-sm leading-4 text-[#4F3AB1]"
        >
            {baselineText.length > MAX_LENGTH ? (
                <LCMDTooltip text={baselineText} asChild side="bottom">
                    <span data-testid="active-baseline-indicator-text" className="font-semibold">
                        {baselineText.slice(0, MAX_LENGTH)}...
                    </span>
                </LCMDTooltip>
            ) : (
                <span data-testid="active-baseline-indicator-text" className="font-semibold">
                    {baselineText}
                </span>
            )}
        </div>
    );
}
