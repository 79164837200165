import * as React from "react";
import { intl } from "@/legacy/GlobalHelperReact";
import { DefaultButton, Stack, TooltipHost } from "@fluentui/react";
import { CountableSection, DependencyOverview, DependencyType } from "../../DetailsView";
import { EditNameField } from "../../DetailsView/EditNameField";
import { Icons } from "../../../RegisteredIcons";
import { useContext, useReducer } from "react";
import { styleSets } from "../styleSets";
import { TrainInfo } from "../interface";
import "../../DetailsView/styles.detailsview.scss";
import { useLCMD } from "@/app/LCMContext";
import { TaktDependenciesTextField } from "./AreaSidebarView";
import { SidebarContext } from "@/app/components/SidebarHost";
import { LCMDContextTaskDetailsResult, LCMDContextTaskDetailsResultDependencyDetails } from "@/app/LCMDContextTypes";
import { WebAppTelemetryFactory } from "@/app/services/WebAppTelemetry.service";
import { useDebouncedCallback } from "use-debounce";

type ProcessSidebarViewProps = {
    data: LCMDContextTaskDetailsResult;
    trainInfos: { [trainId: string]: TrainInfo };
    onTrainInfos: (any) => void;
    trainId: number;
    onOrderByNameSuffix: (any) => void;
    orderByNameSuffix: boolean;
    webtrackingPrefix?: string;
};

const stateReducer = (prevState, updatedProperty) => ({
    ...prevState,
    ...updatedProperty,
});

const initialDependencies = {
    successors: null,
    predecessors: null,
};

export function ProcessSidebarView({
    data,
    trainInfos,
    onTrainInfos,
    trainId,
    webtrackingPrefix,
    onOrderByNameSuffix,
    orderByNameSuffix,
}: ProcessSidebarViewProps) {
    const LCMD = useLCMD();
    const propsContext = useContext(SidebarContext);

    const [dependencies, setDependencies] = useReducer(stateReducer, initialDependencies);

    const train = (data?.train?.value || "").toString();

    const trainInfo = trainInfos[trainId] || { id: trainId, start: undefined, dep: undefined };
    const setTrainInfo = (state) => {
        onTrainInfos({ ...trainInfos, [trainId]: { ...trainInfo, ...state } });
    };
    propsContext.sidebarCtx.useSelectionEffect((ev) => {}, []);

    LCMD.useProcessDetailsEffect(
        data?.pid?.value,
        true,
        (error, data) => {
            console.log({ error });
            console.log({ data });

            if (!data) {
                return;
            }

            setDependencies({ successors: data.successors?.value, predecessors: data.predecessors?.value });
        },
        [],
        propsContext.wbTS,
    );

    function getDependencyTargets(
        dependency: LCMDContextTaskDetailsResultDependencyDetails,
        dependencyType: DependencyType,
    ) {
        let src = dependency.pid;
        let dest = dependency.targetId;
        if (dependencyType == DependencyType.SUCCESSOR) {
            src = dependency.targetId;
            dest = dependency.pid;
        }
        return { src, dest };
    }

    function handleSaveChangedDependency(
        changedDependency: LCMDContextTaskDetailsResultDependencyDetails,
        dependencyType: DependencyType,
    ) {
        const { src, dest } = getDependencyTargets(changedDependency, dependencyType);

        LCMD.setDependencyDetails(
            src,
            dest,
            {
                lag: changedDependency.lag,
                type: changedDependency.type,
                unit: changedDependency.unit,
            },
            true,
        );
    }

    const debouncedWebtracking = useDebouncedCallback((trackingEvent: string) => {
        WebAppTelemetryFactory.trackEvent(trackingEvent);
    }, 1000);

    function handleDeleteDependency(
        dependencyToDelete: LCMDContextTaskDetailsResultDependencyDetails,
        dependencyType: DependencyType,
    ) {
        const { src, dest } = getDependencyTargets(dependencyToDelete, dependencyType);
        LCMD.setDependencyDetails(src, dest, null, true);
    }

    function getGeneratePlanButton() {
        return (
            <DefaultButton
                iconProps={{ iconName: Icons.Lcmd_Settings }}
                style={{ marginRight: "20px" }}
                text={intl.get("WhiteboardDetailsView.button_text")}
                disabled={!trainInfos || !data.project.gpaPreview}
                onClick={() => {
                    WebAppTelemetryFactory.trackEvent("whiteboard-planning-generate-plan-clicked");
                    LCMD.executeCommand({
                        cmd: "takting",
                        value: true,
                        preview: false,
                    });
                    onTrainInfos({}); // stop preview
                }}
            />
        );
    }

    return (
        <>
            <Stack className={`${styleSets.content} ${styleSets.borderBottom} `}>
                <CountableSection
                    classNames={{ header: "header" }}
                    headerText={intl.get("WhiteboardDetailsView.start")}
                    onValueChange={(value: number) => {
                        debouncedWebtracking("whiteboard-planning-duration-updated");
                        if (value > 0) {
                            LCMD.setProcessDetails(
                                data.pid.value,
                                {
                                    start: {
                                        value: value,
                                    },
                                },
                                true,
                            );
                        }
                    }}
                    unit={intl.get("UnitTypes.d", { value: data?.start?.day || 0 })}
                    value={data?.start?.day || 0}
                />
                <CountableSection
                    classNames={{ header: "header" }}
                    headerText={intl.get("WhiteboardDetailsView.duration")}
                    onValueChange={(value: number) => {
                        debouncedWebtracking("whiteboard-planning-start-date-updated");
                        if (value >= 0) {
                            LCMD.setProcessDetails(
                                data.pid.value,
                                {
                                    duration: {
                                        value: value,
                                        unit: 3, // days
                                    },
                                },
                                true,
                            );
                        }
                    }}
                    unit={intl.get("UnitTypes.d", { value: data?.duration?.value || 0 })}
                    value={data?.duration?.value || 0}
                />
            </Stack>

            <Stack styles={{ root: { overflowY: "scroll", overflowX: "hidden" } }}>
                <DependencyOverview
                    className="predecessor"
                    dependencies={dependencies.predecessors}
                    dependencyType={DependencyType.PREDECESSOR}
                    onDependencyDetailsChanged={handleSaveChangedDependency}
                    onDeleteDependency={handleDeleteDependency}
                    onClick={(data) => {
                        console.log({ data });
                    }}
                    webtrackingPrefix={webtrackingPrefix}
                />
                <DependencyOverview
                    className="successor"
                    dependencies={dependencies.successors}
                    dependencyType={DependencyType.SUCCESSOR}
                    onDependencyDetailsChanged={handleSaveChangedDependency}
                    onDeleteDependency={handleDeleteDependency}
                    onClick={(data) => {
                        console.log({ data });
                    }}
                    webtrackingPrefix={webtrackingPrefix}
                />
            </Stack>

            <Stack className={`${styleSets.content}`}>
                <Stack horizontal>
                    <Stack.Item>
                        <CountableSection
                            classNames={{ header: "header" }}
                            headerText={intl.get("WhiteboardDetailsView.teams")}
                            onValueChange={(value) => {
                                debouncedWebtracking("whiteboard-planning-teams-updated");
                                if (value > 0) {
                                    LCMD.setProcessDetails(
                                        data.pid.value,
                                        {
                                            teams: {
                                                value,
                                            },
                                        },
                                        true,
                                    );
                                }
                            }}
                            unit={""}
                            footerText={`${(data?.workforce?.value || 0) * (data?.teams?.value || 1)} ${intl.get(
                                "WhiteboardDetailsView.peopleInTotal",
                            )}`}
                            value={data?.teams?.value || 1}
                        />
                        <CountableSection
                            classNames={{ header: "header" }}
                            headerText={intl.get("WhiteboardDetailsView.workforce")}
                            onValueChange={(value) => {
                                LCMD.setProcessDetails(
                                    data.pid.value,
                                    {
                                        workforce: {
                                            value: value,
                                        },
                                    },
                                    true,
                                );
                            }}
                            unit={""}
                            value={data?.workforce?.value || 0}
                        />
                    </Stack.Item>
                </Stack>

                <div className={`${styleSets.field}`}>
                    <EditNameField
                        label={intl.get("WhiteboardDetailsView.train")}
                        placeholder={intl.get("WhiteboardDetailsView.train")}
                        value={train}
                        onChange={(ev, value) => {
                            if ("string" === typeof value || "number" === typeof value) {
                                LCMD.setProcessDetails(
                                    data.pid.value,
                                    {
                                        train: {
                                            value,
                                        },
                                    },
                                    true,
                                );
                            }
                        }}
                    />
                </div>
                <div className={`${styleSets.borderBottom}`} />
                <br />
                {data?.train?.value ? (
                    <CountableSection
                        classNames={{ header: "header" }}
                        headerText={intl.get("WhiteboardDetailsView.takt")}
                        onValueChange={(value) => {
                            const ppid = data.ppid?.value;
                            if ("number" === typeof ppid) {
                                LCMD.setProcessDetails(
                                    ppid,
                                    {
                                        trains: {
                                            [data.train.value]: {
                                                takt: {
                                                    value,
                                                },
                                            },
                                        },
                                    },
                                    true,
                                );
                            }
                        }}
                        unit={""}
                        value={data.train.takt || 0}
                    />
                ) : null}

                <div className={`${styleSets.field}`}>
                    <TaktDependenciesTextField
                        value={trainInfo.dep || undefined}
                        onChange={(value) => {
                            setTrainInfo({
                                dep: value,
                            });
                            LCMD.executeCommand({
                                cmd: "takting",
                                value: {
                                    id: trainId,
                                    dep: value,
                                },
                                preview: true,
                            });
                        }}
                    />
                </div>
            </Stack>
            <Stack className={styleSets.footer}>
                <Stack horizontal horizontalAlign={"end"}>
                    {
                        //TODO: add gpaPreview.enabled to Interface and check if data.project.gpaPreview.enabled
                        data.project.gpaPreview ? (
                            getGeneratePlanButton()
                        ) : (
                            <TooltipHost
                                calloutProps={{ gapSpace: 0 }}
                                content={intl.get("WhiteboardDetailsView.disabledPlanButtonTooltip")}
                            >
                                {getGeneratePlanButton()}
                            </TooltipHost>
                        )
                    }
                </Stack>
            </Stack>
        </>
    );
}
