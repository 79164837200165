import { Pencil, Trash2 } from "lucide-react";
import { intl } from "lcmd2framework";
import { MoreButtonDropdown } from "@/components/MoreButtonDropdown/MoreButtonDropdown";

export function DropdownEditDelete({
    onEditClicked,
    onDeleteClicked,
}: {
    onEditClicked: () => void;
    onDeleteClicked: () => void;
}) {
    const dropDownData = [
        {
            key: "edit",
            icon: <Pencil size={16} />,
            text: intl.get("ReasonCodesSettings.list.edit"),
            onClick: onEditClicked,
        },
        {
            key: "delete",
            icon: <Trash2 size={16} />,
            text: intl.get("ReasonCodesSettings.list.delete"),
            onClick: onDeleteClicked,
        },
    ];

    return <MoreButtonDropdown sideOffset={-25} alignOffset={20} align={"end"} menuItems={dropDownData} />;
}
