import { FilterItem } from "@/components/Filter/data-access-hooks/use-filter-data";
import { DataModelFilterSavedAs } from "@/model/DataModelTypes";
import { useLCMD } from "@/app/LCMContext";
import { Card } from "@/components/ui/card";
import { LCMDTooltip } from "@/components/common/Tooltip/LCMDTooltip";
import { intl } from "@/legacy/GlobalHelperReact";
import { CheckCircle, Globe, Pencil, Trash } from "lucide-react";
import { IconButton } from "@/components/common/IconButton/IconButton";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { WebAppTelemetryFactory } from "@/app/services/WebAppTelemetry.service";
import * as React from "react";

export function FilterCard({
    item,
    selected,
    currentUser,
    onFilterSelect,
    onFilterEdit,
    onFilterDelete,
    webtrackingPrefix,
}: {
    item: FilterItem;
    selected: boolean;
    currentUser: string;
    onFilterSelect: () => void;
    onFilterEdit: (item: FilterItem) => void;
    onFilterDelete: (item: DataModelFilterSavedAs) => void;
    webtrackingPrefix;
}) {
    const LCMD = useLCMD();
    return (
        <Card className={"h-[100px] rounded-2xl border-2 border-gray-200 bg-slate-50 p-4"}>
            <div>{item.name}</div>
            <div className={"flex items-center justify-end gap-2"}>
                {item.global && (
                    <div className={"p-2"}>
                        <LCMDTooltip text={intl.get("filter.manage.cmd.global.text")}>
                            <Globe
                                size={16}
                                className="outline-violet-600] rounded-full bg-violet-600 stroke-white outline-1"
                            />
                        </LCMDTooltip>
                    </div>
                )}
                {currentUser === item.owner ? (
                    <>
                        <LCMDTooltip text={intl.get("filter.manage.cmd.edit.text")}>
                            <IconButton
                                icon={Pencil}
                                iconProps={{ size: 16 }}
                                className={"text-zinc-500"}
                                onClick={() => onFilterEdit(item)}
                            ></IconButton>
                        </LCMDTooltip>
                        <LCMDTooltip text={intl.get("filter.manage.cmd.delete.text")}>
                            <IconButton
                                icon={Trash}
                                className={"text-zinc-500"}
                                iconProps={{ size: 16 }}
                                onClick={() => {
                                    onFilterDelete({
                                        saveAs: {
                                            hid: parseInt(item._hid),
                                            pos: item._pos,
                                            name: item.name,
                                            global: item.global,
                                        },
                                    });
                                }}
                            ></IconButton>
                        </LCMDTooltip>
                    </>
                ) : (
                    <LCMDTooltip text={intl.get("filter.manage.cmd.notOwner.text")}>
                        <div className={"flex items-center justify-end gap-2"}>
                            <div className={"p-2"}>
                                <Pencil className={"text-zinc-300"} size={16}></Pencil>
                            </div>
                            <div className={"p-2"}>
                                <Trash className={"text-zinc-300"} size={16}></Trash>
                            </div>
                        </div>
                    </LCMDTooltip>
                )}
                <div>
                    {selected && (
                        <Badge className="flex h-10 items-center rounded bg-green-600 px-3 hover:bg-green-600">
                            <CheckCircle size={16} className="mr-2" />
                            <div className="text-sm font-medium">{intl.get("baselines.active")}</div>
                        </Badge>
                    )}
                    {!selected && (
                        <Button
                            variant={"secondary"}
                            onClick={() => {
                                WebAppTelemetryFactory.trackEvent(`${webtrackingPrefix}-apply-filter-clicked`);
                                LCMD.setFilter({ filterId: item._hid, global: item.global });
                                onFilterSelect();
                            }}
                        >
                            {intl.get("filter.manage.cmd.apply.text")}
                        </Button>
                    )}
                </div>
            </div>
        </Card>
    );
}
