import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { X } from "lucide-react";
import * as React from "react";

export function AreaCard({ name, index, onDelete }: { name: string; index: number; onDelete: (element) => void }) {
    return (
        <Button key={index} variant="outline" size="sm" className={cn("flex cursor-default items-center space-x-1")}>
            <span>{name}</span>

            <X
                className="h-3 w-3 cursor-pointer"
                onClick={() => {
                    onDelete(name);
                }}
            />
        </Button>
    );
}
