import { useRef } from "react";
import { useLCMD } from "@/app/LCMContext";
import { useUserView } from "@/app/store/userJourneyStore";
import { ViewTypes } from "@/components/view/constants";
import { CanvasHostRenderNode, CanvasTaskData } from "lcmd2framework";
import { ProcessStatuses } from "@/model/GlobalHelper";
import { getCurrentLanguage } from "@/components/utils/date/locale";
import { ProcessTooltip } from "./ProcessTooltip";
import { MilestoneTooltip } from "./MilestoneTooltip";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { TooltipShimmer } from "./TooltipShimmer";
type ProcessTooltipWrapperProps = {
    canvasData: CanvasTaskData & CanvasHostRenderNode;
    x: number;
    y: number;
};

export function ProcessTooltipWrapper({ canvasData, x, y }: ProcessTooltipWrapperProps) {
    const view = useUserView();
    const LCMD = useLCMD();
    const { isLoading, data: processDetails } = LCMD.useProcessDetails(canvasData.id);
    const { isLoading: isBaselineLoading, data: baselineDetails } = LCMD.useProcessBaselineDetails(canvasData.id);
    const processStatus = ProcessStatuses[canvasData.status];
    const isMilestone = processDetails?.isMilestone?.value;
    const isVirtual = processDetails?.isVirtual?.value;
    const canvasTaskId = canvasData.id;
    const tooltipContentRef = useRef<HTMLDivElement>(null);
    const isTooltipDetailLoading = isLoading || (view === ViewTypes.BASELINE && isBaselineLoading);

    if (isVirtual) {
        return null;
    } else {
        return (
            <TooltipProvider>
                <Tooltip open>
                    <TooltipTrigger className="leading-none" />
                    <div
                        style={{
                            left: x - 130 + "px",
                            top: y - 23 + "px",
                        }}
                        className="absolute z-[9999] flex h-[25px] w-[260px] items-center justify-center p-2"
                    >
                        <svg
                            width="22px"
                            height="20px"
                            viewBox="0 0 18 18"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="#030712"
                            className="absolute top-[5px] text-black animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2"
                        >
                            <g id="SVGRepo_iconCarrier">
                                <g id="out" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <path d="M4,7 L9,13 L14,7 L4,7 L4,7 Z" id="path" fill="#000000"></path>
                                </g>
                            </g>
                        </svg>
                    </div>
                    <TooltipContent
                        ref={tooltipContentRef}
                        className="absolute z-[9999] w-max min-w-[264px] max-w-[500px] bg-[#030712]"
                        side="top"
                        style={{
                            left: x - 130 + "px",
                            top:
                                y -
                                124 -
                                (view === ViewTypes.BASELINE && baselineDetails?.start && !isTooltipDetailLoading
                                    ? 50
                                    : 0) +
                                "px",
                        }}
                        data-userflow-id="pp-tooltip-fortschritt"
                    >
                        {isTooltipDetailLoading ? (
                            <TooltipShimmer />
                        ) : isMilestone ? (
                            <MilestoneTooltip {...{ processDetails, processStatus, canvasTaskId }} />
                        ) : (
                            <ProcessTooltip
                                {...{
                                    processDetails,
                                    baselineDetails: view === ViewTypes.BASELINE ? baselineDetails : undefined,
                                    canvasTaskId,
                                }}
                            />
                        )}
                    </TooltipContent>
                </Tooltip>
            </TooltipProvider>
        );
    }
}

export function formatDate(date: Date | number): string {
    const formattedDate = new Date(date);
    const locale = getCurrentLanguage();
    return formattedDate
        .toLocaleDateString(locale, {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
        })
        .split(" ")
        .join(".");
}
