import React, { useEffect } from "react";
import { ReactElement, useContext, useState } from "react";
import { DigitalPlanningBoardLibraryDetails, DigitalPlanningBoardLibraryEvent, intl } from "lcmd2framework";
import { AreaWrapper } from "./AreaWrapper/AreaWrapper";
import { useBoolean } from "@fluentui/react-hooks";
import { copyAndSort } from "../../Utils";
import { useLCMD } from "@/app/LCMContext";
import { SidebarContext } from "@/app/components/SidebarHost";
import { LcmdModal2 } from "@/components/common/LcmModal2";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { AlertDialog } from "@/components/common/AlertDialog/AlertDialogV2";
import { SearchBar } from "@/components/common/SearchBar/SearchBar";
import { ArrowDownUp, Plus } from "lucide-react";
import { IconButton } from "@/components/common/IconButton/IconButton";
import { WebAppTelemetryFactory } from "@/app/services/WebAppTelemetry.service";

export function AreaLibrary({ view }: { view: string }): ReactElement {
    const LCMD = useLCMD();

    const [showDialog, { toggle: toggleHideDialog }] = useBoolean(false);
    const [isSortedDescending, { toggle: toggleSortedDescending }] = useBoolean(true);
    const [showNewEditDialog, { toggle: toggleShowNewEditDialog }] = useBoolean(false);

    const [savedlibraries, setSavedLibraries] = useState<DigitalPlanningBoardLibraryDetails[]>([]);
    const [libraries, setLibraries] = useState<DigitalPlanningBoardLibraryDetails[]>([]);
    const [selectedLibrary, setSelectedLibrary] = useState<DigitalPlanningBoardLibraryDetails>(null);
    const [libraryId, setLibraryId] = useState(null);
    const [editMode, setEditMode] = useState<boolean>(false);
    const [searchText, setSearchText] = useState("");

    const propsContext = useContext(SidebarContext);
    const modalTitle = intl.get(`AreaLibrary.newAreaDialog.${editMode ? "editTitle" : "title"}`);

    propsContext.sidebarCtx.useLibrariesEffect(
        (ev: DigitalPlanningBoardLibraryEvent) => {
            if (!propsContext.view.libraries.active) {
                return;
            }
            setLibraryId(propsContext.view.libraries.active);
            setLibraries(ev[propsContext.view.libraries.active]);
            setSavedLibraries(ev[propsContext.view.libraries.active]);
        },
        [propsContext.view.libraries.active],
    );

    const headerHeight: number = 90;
    const footerHeight: number = 65;

    function toggleSort() {
        toggleSortedDescending();
        setLibraries(copyAndSort(libraries, "label", isSortedDescending));
    }

    useEffect(() => {
        if (searchText && searchText.length && savedlibraries?.length) {
            setLibraries(
                savedlibraries.filter((trade) => {
                    return trade.label.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
                }),
            );
        } else {
            setLibraries([...savedlibraries]);
        }
    }, [searchText, savedlibraries]);

    function deleteArea() {
        LCMD.setOrCreateLibraryItem(libraryId, { id: selectedLibrary.t, p: -1 });
        toggleHideDialog();
    }

    function saveLibrary(lib: DigitalPlanningBoardLibraryDetails) {
        const saveItem: { id?: number; name: string } = {
            id: typeof lib.t === "number" ? lib.t : null,
            name: lib.label,
        };
        LCMD.setOrCreateLibraryItem(libraryId, saveItem);
        setSelectedLibrary(null);
    }

    return (
        <div className="h-full">
            <div className="h-[90px] px-2.5 py-1.5 text-lg font-semibold">
                <div className="mb-2.5">{intl.get("AreaLibrary.headline")}</div>
                <div className="text-red mb-2.5">{!libraryId ? intl.get("AreaLibrary.fail.selectWhiteboard") : ""}</div>
                <div className="mb-2.5 flex items-center justify-between gap-1">
                    <SearchBar
                        value={searchText}
                        onChange={setSearchText}
                        placeholder={intl.get("AreaLibrary.searchField.label")}
                    />
                    <IconButton icon={ArrowDownUp} className="h-10 w-10 rounded-sm" onClick={toggleSort} />
                </div>
            </div>
            <div
                className="relative overflow-y-auto overflow-x-hidden pt-2"
                style={{ height: `calc(100% - ${headerHeight + footerHeight}px)` }}
            >
                {libraries?.map((lib) => {
                    return (
                        <AreaWrapper
                            key={lib.t}
                            mode={view}
                            id={["_L", (lib.t + 1).toString(16)].join("")}
                            name={lib.label}
                            onDelete={() => {
                                setSelectedLibrary(lib);
                                toggleHideDialog();
                            }}
                            // onClone={() => {
                            //     setSelectedLibrary({label: 'clone_' + lib.label, t: null});
                            //     toggleShowNewEditDialog();
                            // }}
                            onEdit={() => {
                                setEditMode(true);
                                setSelectedLibrary(lib);
                                toggleShowNewEditDialog();
                            }}
                        />
                    );
                })}
            </div>
            <div className="flex justify-end border-t border-gray-300 px-2 py-3">
                <Button
                    variant="outline"
                    className="border-[#8a8886]"
                    disabled={!propsContext.view.libraries.active}
                    onClick={() => {
                        setEditMode(false);
                        setSelectedLibrary(null);
                        toggleShowNewEditDialog();
                    }}
                >
                    <Plus size={16} className="mr-2" />
                    {intl.get("AreaLibrary.newAreaButton.label")}
                </Button>
            </div>
            {showDialog && (
                <AlertDialog
                    open={showDialog}
                    onOpenChange={toggleHideDialog}
                    title={intl.get("AreaLibrary.dialogDelete.title")}
                    subText={intl.get("AreaLibrary.dialogDelete.subText", selectedLibrary?.label)}
                    onOk={deleteArea}
                    okButtonText={intl.get("AreaLibrary.dialogDelete.okButton")}
                    cancelButtonText={intl.get("AreaLibrary.dialogDelete.cancelButton")}
                />
            )}
            {showNewEditDialog && (
                <LcmdModal2
                    onOpenChange={toggleShowNewEditDialog}
                    buttons={[
                        <Button key="default" size="sm" variant="outline" onClick={toggleShowNewEditDialog}>
                            {intl.get("AreaLibrary.newAreaDialog.cancelButton")}
                        </Button>,
                        <Button
                            key="primary"
                            size="sm"
                            disabled={!selectedLibrary?.label}
                            onClick={() => {
                                WebAppTelemetryFactory.trackEvent("whiteboard-standard-library-added");
                                toggleShowNewEditDialog();
                                if (!selectedLibrary?.label) {
                                    return;
                                }
                                saveLibrary(selectedLibrary);
                            }}
                        >
                            {intl.get("AreaLibrary.newAreaDialog.okButton")}
                        </Button>,
                    ]}
                    open={showNewEditDialog}
                    header={{ title: modalTitle }}
                >
                    <div>
                        <Input
                            placeholder={intl.get("AreaLibrary.newAreaDialog.textFieldName.placeholder")}
                            value={selectedLibrary ? selectedLibrary.label : ""}
                            onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                setSelectedLibrary({
                                    label: (event.target as HTMLInputElement).value,
                                    t: selectedLibrary?.t,
                                });
                            }}
                        />
                    </div>
                </LcmdModal2>
            )}
        </div>
    );
}
