import { CanvasTaskData, CanvasStripeData, MAX_TASK_DAYS, FrameworkErrorDataModelNeedsSync } from "./DataModel";
import { CONST } from "../legacy/settings";
import { LCM } from "./CSV";
import {
    DataModel,
    DataOperationType,
    DataOperationTarget,
    DataOperation,
    WorkerSession,
    DataModelProcessAsJson,
} from "./DataModel";
import type { CanvasDataView } from "./DataModelTypes";
import { RT, RTSessionsData } from "./RT";
import {
    applyPatch,
    assert,
    assert_dev,
    errorToJSON,
    FrameworkError,
    FrameworkHttpError,
    unsafeParseJWT,
    FrameworkUpdateNeededError,
    generateId,
} from "./GlobalHelper";
import { UUID5 } from "./UUID5";
import {
    create_sandbox,
    registerSub,
    updateDB,
    procore_fetch_schedule,
    uploadArrayBuffer,
    setProjectProps,
    createProject,
    create_master,
    set_master_sandbox,
    fetch_ts,
    procore_fetch_tasks,
    getLog,
} from "./ApiHelper";
import { initServices, SERVICES } from "./services";
import { workerHandleAttachments } from "./WorkerAttachments";
import { workerHandleProcess } from "./WorkerProcesses";
import { Buffer } from "buffer";
import lcmd2renderer from "@lcmd/lcmd2renderer";
import { LCMDContextLibraryItemTarget, LCMDContextTaskDetailsResultDependencyDetails } from "../app/LCMDContextTypes";
import {
    ParticleCore,
    OnImportOptions,
    CanvasMeta,
    OnParticleCoreUpdateEvent,
    OnParticleWorkerMsgEvent,
} from "particlecore";
import { DataStorage } from "./DataStorage";
import { Core, HiveGetter } from "../core/lcmd2core";
import { CoreEvents } from "../app/types/CoreEvents";
import { ProcessGetter } from "./api/processGetter";
import { TradesGetter } from "./api/tradesGetter";
import { ParticleGetter } from "./api/particleGetter";

import { WebAppTelemetryFactory } from "../app/services/WebAppTelemetry.service";
import { ERROR_CODES } from "../utils/GlobalErrorEnum";
import { checkDependencies } from "../core/Deps";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { ViewTypeValueI } from "@/components/view/constants";

type OnWorkerMsgEvent = any;

type dialogPropsType = {
    tradeId: number;
    trade: string;
    used: boolean | { processes?: object; unknown?: boolean };
};

type RelatedProcesses = {
    _: number;
    value: Array<LCMDContextTaskDetailsResultDependencyDetails>;
    indeterminate: boolean;
    readonly: boolean;
};
type CopiedProcesses = Array<{
    id: number;
    name: string;
    predecessors: RelatedProcesses;
    successors: RelatedProcesses;
    startDate: number;
}>;
type PasteObject = {
    x: number;
    y: number;
    p: {
        pid: number;
        i_pid: number;
        tz_pid: string;
    };
    clipboardContent: DataModelProcessAsJson[];
    copiedTrades: Array<{ color: number; name: string; trade: object; trid: number }>;
    sourceProjectId: string;
    copiedTaskIds: CopiedProcesses;
    action: "cut" | "copy";
};

export type OldToNewProcessIdMap = Map<number, number>;

assert(null === DataModel.cache); // already set? why?
DataModel.cache = new DataStorage();
const rt_pp = new RT();
const canvas = {
    warmupId: undefined as string,
    userMeta: undefined as { [name: string]: any },
    error: null,
    worker: ("undefined" !== typeof self ? (self as any) : null) as ServiceWorker | Worker,
    _loaded: 0,
    _force: false,
    master_token: null,
    sandbox_token: null,
    sandbox_sub: null,
    sandbox_role: null,
    merge_token: null as null | {
        sandbox: string;
        sandbox_ofs: number;
    },
    meta: null as {
        dirty: boolean;
        name: string;
        ts: number;
        session: WorkerSession;
        role: string;
        readonly: boolean;
        procore?: {
            project_id?: string | number;
            company_id?: string | number;
        };
        pid?: string;
        revId?: number;
    } | null,
    resource: null,
    clientReady: false,
    //    cols: 0,
    //    rows: 0,
    //    stripes: [],
    //    tasks: [],
    model: null as DataModel,
    onUpdateCache: {
        worker: undefined,
        core: undefined,
        baseline: undefined,
        whiteboard: undefined,
        ts: -1,
        wb_ts: -1,
        trGt: undefined,
        pGt: undefined,
        _Gt: undefined,
        btrGt: undefined,
        bpGt: undefined,
        b_Gt: undefined,
        wbPGt: undefined,
        wbTrGt: undefined,
        wb_Gt: undefined,
    } as OnParticleCoreUpdateEvent,
    _clipboard: {
        action: null as "cut" | "copy",
        origin: null as string,
        content: [] as DataModelProcessAsJson[],
    },
    login: null as {
        name: string;
    } | null,
    tradesTS: 0,
    qa: null as {
        view: {
            width: number;
            height: number;
        };
    } | null,
    procore: null as any,
    stats: {},
    activeSync: 0 as number,
};
const viewStateHack: { [storageName: string]: { mode: string; view: { left: number; top: number; scale: number } } } =
    {};

function initDataView({ name }) {
    canvas.stats[name] = {
        state: {
            commit_ts: 0,
            grid_ts: 0,
            baseline_ts: -1,
            gpa_ts: -1,
            filter: null,
            view: null,
        } as CanvasDataView,
    };
    const dataViewReducer = canvas.model.callbacks._dataViewReducer;
    dataViewReducer.views[name].init(canvas.onUpdateCache, canvas.stats[name]);
}

/*
function invalidateDataViewIfNeeded({commit_ts, currentFilter}, name:string) {
    const kappa=canvas.stats[name] as {state:CanvasDataView};
    if (kappa.state.view.grid_ts!==kappa.state.grid_ts || kappa.state.commit_ts!==commit_ts || currentFilter!==kappa.state.filter) {
        kappa.state.grid_ts=kappa.state.view.grid_ts;
        kappa.state.commit_ts=commit_ts;
        kappa.state.filter=currentFilter;
        invalidateKappaDataView(kappa as any);
    }
}
*/

function updateDataViewIfNeeded({ commit_ts, baseline_ts, transferable, _stats }, name: string) {
    const dataViewReducer = canvas.model.callbacks._dataViewReducer;
    const kappa = canvas.stats[name] as { state: CanvasDataView };
    assert(
        kappa.state.view.grid_ts === canvas.stats[name].state.grid_ts &&
            canvas.stats[name].state.commit_ts === commit_ts &&
            canvas.stats[name].state.baseline_ts === baseline_ts,
    );
    _stats[name] = _stats[name] || {};
    _stats[name].view = kappa.state.view;
    dataViewReducer.views[name].update(canvas.onUpdateCache, { transferable }, _stats[name], kappa as any);
}

function postCanvasMessage(force?: boolean) {
    // console.time("postCanvasMessage");
    // calculating deps  on every canvas message. Not good, can cause longer render cycles!
    if (canvas.onUpdateCache.core) {
        checkDependencies(canvas.onUpdateCache.core);
    }

    if (canvas.worker && canvas._loaded && canvas.clientReady && canvas.model) {
        if (1 === canvas._loaded) {
            canvas.model.updateModel();
            const onInitCanvas = canvas.model.callbacks.onInitCanvas;
            onInitCanvas && onInitCanvas(canvas.model);
        }
        if (canvas._force) {
            force = true;
            canvas._force = false;
        }
        canvas.model.updateModel();
        if (canvas.model.canvasWhiteboards.model) {
            canvas.model.canvasWhiteboards.model.updateModel();
            canvas.model.updateModel();
        }
        const commited = canvas.model.commitTS();
        const patch = canvas.model.updateCanvas(force);
        const syncCommitedTS = canvas.model.syncCommitedTS();
        const commitedTS = canvas.model.commitTS();
        let _meta = undefined;
        let _goto = undefined;
        if (canvas?.meta?.dirty) {
            canvas.meta.dirty = false;
            _meta = Object.assign({}, canvas.meta);
            delete _meta.dirty;
        }
        if (1 === canvas._loaded) {
            canvas._loaded = 2;
            _goto = {
                left: 0,
                top: 0,
                right: patch.cols,
                bottom: patch.rows,
                option: "start",
            };
        }

        const statsNames = Object.getOwnPropertyNames(canvas.stats || {}).filter(
            (name) => canvas.stats[name]?.state?.view,
        );
        const commit_ts = canvas.model.commitTS();
        const baseline_ts = canvas.model.baseline ? canvas.model.baseline.commitTS() : -1;
        const gpa_ts = canvas.model.canvasWhiteboards?.model ? canvas.model.canvasWhiteboards.model.commitTS() : -1;
        const invalidStats = statsNames.reduce(
            (ret, name) => {
                const dataView = canvas.stats[name] as { state: CanvasDataView };
                const currentFilter = patch.filterPatch || dataView.state.filter;
                const options = dataView.state.view.options || dataView.state.options || null;
                if (
                    dataView.state.view.grid_ts !== dataView.state.grid_ts ||
                    dataView.state.commit_ts !== commit_ts ||
                    dataView.state.baseline_ts !== baseline_ts ||
                    currentFilter !== dataView.state.filter ||
                    dataView.state.options !== options ||
                    gpa_ts !== dataView.state.gpa_ts
                ) {
                    if (
                        0 < dataView.state.commit_ts &&
                        dataView.state.commit_ts < commit_ts &&
                        name.startsWith("dashboard.")
                    ) {
                        // do not automatically recalc
                    } else {
                        dataView.state.grid_ts = dataView.state.view.grid_ts;
                        dataView.state.commit_ts = commit_ts;
                        dataView.state.baseline_ts = baseline_ts;
                        dataView.state.gpa_ts = gpa_ts;
                        dataView.state.filter = currentFilter;
                        dataView.state.options = options;
                        ret.cards.push(name);
                    }
                }
                return ret;
            },
            {
                cards: [],
            },
        );
        if (invalidStats.cards.length > 0) {
            const dataViewReducer = canvas.model.callbacks._dataViewReducer;
            const n_stats = invalidStats.cards.length;
            for (let i_stats = 0; i_stats < n_stats; i_stats++) {
                const name = invalidStats.cards[i_stats];
                if (canvas.stats[name].state.view.grid_ts === canvas.model.grid?.ts) {
                    const ret = canvas.stats[name] as any;
                    assert(
                        ret.state.view.grid_ts === canvas.model.grid.ts &&
                            0 <= ret.state.view.col0 &&
                            ret.state.view.col0 <= ret.state.view.col1 &&
                            ret.state.view.col1 <= canvas.model.grid.view.cols,
                    );
                    dataViewReducer.views[name].invalidate(canvas.onUpdateCache, ret);
                }
            }
            const taskIds = Object.getOwnPropertyNames(canvas.model.tasks).filter((t) => {
                return undefined !== canvas.model.tasks[t]._stripe1;
            });
            const n_tasks = taskIds.length;
            /*
            const ctx={
                core: canvas.onUpdateCache.core,
                tid: null,
                pGt: canvas.onUpdateCache.pGt,
                trades: null,
                cards: null,
                baseline: canvas.model.baseline?{
                    task: null,
                    cards: null
                }:null
            }
            */
            const pctx = {};
            for (let i_task = 0; i_task < n_tasks; i_task++) {
                const tid = Number.parseInt(taskIds[i_task]);
                dataViewReducer.preprocessReduceCtx(canvas.onUpdateCache, pctx, tid);
                for (let i_stats = 0; i_stats < n_stats; i_stats++) {
                    const name = invalidStats.cards[i_stats];
                    if (canvas.stats[name].state.view.grid_ts === canvas.model.grid?.ts) {
                        dataViewReducer.views[name].reduce(canvas.onUpdateCache, canvas.stats[name] as any, pctx);
                    }
                }
                dataViewReducer.postprocessReduceCtx(canvas.onUpdateCache, pctx, tid);
            }
            for (let i_stats = 0; i_stats < n_stats; i_stats++) {
                const name = invalidStats.cards[i_stats];
                if (canvas.stats[name].state.view.grid_ts === canvas.model.grid?.ts) {
                    dataViewReducer.views[name].postprocess(canvas.onUpdateCache, canvas.stats[name] as any);
                }
            }
        }
        const stats = statsNames.reduce(
            (ret, name) => {
                ret._stats = ret._stats || {};
                const dataView = canvas.stats[name] as { state: CanvasDataView };
                const currentFilter = dataView.state.filter;
                const ctx = {
                    commit_ts: canvas.model.commitTS(),
                    baseline_ts: canvas.model.baseline ? canvas.model.baseline.commitTS() : -1,
                    currentFilter: currentFilter,
                    transferable: ret.transferable,
                    _stats: ret._stats,
                };
                if (canvas.stats[name].state.view.grid_ts === canvas.model.grid?.ts) {
                    if (canvas.stats[name].state.commit_ts < ctx.commit_ts) {
                        ctx._stats[name] = {
                            // invalid
                            view: "outdated",
                        };
                    } else {
                        updateDataViewIfNeeded(ctx, name);
                    }
                } else {
                    // oudated
                    ctx._stats[name] = {
                        view: null,
                    };
                }
                return ret;
            },
            {
                transferable: [] as Transferable[],
                _stats: undefined as any,
            },
        );
        let tradesPatch = undefined;
        if (canvas.model.tradesDirty) {
            canvas.model.tradesDirty = false;
            if (canvas.model.callbacks._coreGetTrades) {
                tradesPatch = canvas.model.callbacks._coreGetTrades();
            }
            if (canvas.model.canvasWhiteboards.model && canvas.model.callbacks.onWhiteboardSync) {
                canvas.model.callbacks.onWhiteboardSync(canvas.model, canvas.model.canvasWhiteboards.model); // can change canvas.model.canvasWhiteboards.model will be updated below, see (WBUPDATE)
            }
        }
        let gpaPreview = undefined; // canvas.model?.canvasWhiteboards?.model?.gpaPreview;
        if (gpaPreview !== canvas.model.legacyFLux.gpaPreview) {
            gpaPreview = canvas.model.legacyFLux.gpaPreview;
            canvas.model.legacyFLux.gpaPreview = undefined;
        }
        // console.log("postCanvasMessage", patch);
        canvas.worker.postMessage(
            [
                "canvas",
                {
                    start: patch.start,
                    rows: patch.rows,
                    cols: patch.cols,
                    l_min: patch.l_min,
                    l_max: patch.l_max,
                    viewConstPatch: patch.viewConstPatch,
                    viewMetaPatch: patch.viewMetaPatch,
                    stripesPatch: patch.stripesPatch,
                    tasksPatch: patch.tasksPatch,
                    gridPatch: patch.gridPatch,
                    filterPatch: patch.filterPatch,
                    wbPatch: patch.wbPatch,
                    tradesPatch: tradesPatch,
                    sync: {
                        storageName: canvas.model.storageName,
                        syncCommited: canvas.activeSync > 0 ? 0 : syncCommitedTS,
                        commited: commitedTS,
                        date: rt_pp.syncDate,
                        wb: canvas.model.canvasWhiteboards.model
                            ? {
                                  storageName: canvas.model.canvasWhiteboards.model.storageName,
                                  syncCommited:
                                      canvas.activeSync > 0 ? 0 : canvas.model.canvasWhiteboards.model.syncCommitedTS(),
                                  commited: canvas.model.canvasWhiteboards.model.commitTS(),
                                  date: canvas.model.canvasWhiteboards.rt.syncDate,
                              }
                            : undefined,
                    },
                    goto: _goto,
                    meta: _meta,
                    stats: stats._stats,
                    gpaDirty: patch.gpaDirty,
                    gpaPreview,
                    // TODO: remove after new design implementation
                    fs: canvas.model.legacyFLux?.fs,
                    rejectedOps: patch.rejectedOps,
                    errorPatch: patch.errorPatch,
                    renderProjectWeeks: canvas.model.viewConst.showProjectWeeks,
                    collapsedAreaList: canvas.model.getCollapsedAreaList(),
                },
            ],
            stats.transferable,
        );
        //const history=canvas.model.updateHistory_REMOVE_ME();
        //canvas.worker.postMessage(["history", history]);
        canvas.model.updateNotifications();
        const notifications = canvas.model.getPendingNotifications(true);
        if (notifications && notifications.length > 0) {
            canvas.worker.postMessage([
                "notifications",
                {
                    updates: notifications,
                },
            ]);
        }
        if (canvas.model.canvasWBS) {
            const patch = canvas.model.updateWBS(canvas.model.canvasWBS.id);
            canvas.worker.postMessage(["processview", "update", patch]);
        }
        if (canvas.model.canvasWhiteboards.model) {
            assert(canvas.model.canvasWhiteboards.activeId && canvas.model.canvasWhiteboards.active);
            //const wb=canvas.model.canvasWhiteboards.active;
            const m = canvas.model.canvasWhiteboards.model;
            assert(canvas.model.canvasWhiteboards.activeId === m.storageName);
            m.updateModel(); // (WBUPDATE)
            const mcommited = m.commitTS();
            const patch = m.updateCanvas(force);
            const syncCommitedTS = m.syncCommitedTS();
            const commitedTS = m.commitTS();
            canvas.worker.postMessage([
                "wb",
                {
                    id: canvas.model.canvasWhiteboards.activeId,
                    start: patch.start,
                    rows: patch.rows,
                    cols: patch.cols,
                    l_min: patch.l_min,
                    l_max: patch.l_max,
                    viewConstPatch: patch.viewConstPatch,
                    stripesPatch: patch.stripesPatch,
                    tasksPatch: patch.tasksPatch,
                    gridPatch: patch.gridPatch,
                    filterPatch: patch.filterPatch,
                    wbPatch: patch.wbPatch,
                    sync: {
                        storageName: m.storageName,
                        syncCommited: syncCommitedTS,
                        commited: commitedTS,
                    },
                    rejectedOps: patch.rejectedOps,
                    //goto: _goto,
                    //meta: _meta,
                    errorPatch: patch.errorPatch,
                },
            ]);
            assert(m.commitTS() === m.ops.length, "whiteboard concurrent update");
        }
        assert(canvas.model.commitTS() === canvas.model.ops.length, "concurrent update"); // concurrent update, not allowed!!!!

        // console.timeEnd("postCanvasMessage");
        return patch;
    } else {
        return null;
    }
}

function _throwFrameworkUpdateNeededErrorIfNeeded(e) {
    if ("error.406" === e?.message) {
        throw new FrameworkUpdateNeededError();
    } else {
        return false;
    }
}

function _setConnectionState(state: { mode: string; error?: Error | null; rt?: Error | null }) {
    const model =
        "canvas" === state.mode ? canvas.model : "wb" === state.mode ? canvas.model?.canvasWhiteboards?.model : null;
    if (model) {
        model.setErrorState(state);
    }
    postCanvasMessage();
}

function syncStream(ctx: { queue: (() => Promise<void>)[]; queueBusy: boolean }): Promise<any> {
    const model = canvas.model;
    const helper = [
        new Promise((resolve: (ret: boolean) => void, reject: (e: Error) => void) => {
            if (model) {
                RT.syncStream(
                    model,
                    canvas.master_token,
                    (e) => {
                        if (e) {
                            if (!_throwFrameworkUpdateNeededErrorIfNeeded(e)) {
                                _setConnectionState({ error: e, mode: "canvas" }); // signal connection error
                            }
                        } else {
                            ctx.queue.push(
                                _handleMessage.bind(ctx, {
                                    data: ["strmsync", {}],
                                }),
                            );
                            handleQueueIfNeeded(ctx);
                            //postCanvasMessage();
                        }
                        resolve(true);
                    },
                    rt_pp,
                );
            } else {
                throw new Error("syncStream has no model");
            }
        }),
    ];
    if (model?.canvasWhiteboards?.model) {
        helper.push(
            new Promise((resolve: (ret: boolean) => void, reject: (e: Error) => void) => {
                RT.syncStream(
                    model.canvasWhiteboards.model,
                    canvas.master_token,
                    (e) => {
                        if (e) {
                            if (!_throwFrameworkUpdateNeededErrorIfNeeded(e)) {
                                _setConnectionState({ error: e, mode: "wb" }); // signal connection error
                            }
                        } else {
                            ctx.queue.push(
                                _handleMessage.bind(ctx, {
                                    data: ["strmsync", {}],
                                }),
                            );
                            handleQueueIfNeeded(ctx);
                            //postCanvasMessage();
                        }
                        resolve(true);
                    },
                    model.canvasWhiteboards.rt,
                );
            }),
        );
    }
    if (helper.length > 1) {
        return Promise.all(helper);
    } else {
        return helper[0];
    }

    /*
    if (canvas.sandbox_token) {
        RT.syncStream(canvas.model, canvas.master_token, (e)=>{
            if (e) {
                if (!_throwFrameworkUpdateNeededErrorIfNeeded(e)) {
                    _setConnectionState({error: e, mode:"canvas"}); // signal connection error
                }
            } else {
                postCanvasMessage();
            }
        }, rt_pp);
        if (true && canvas.model.canvasWhiteboards.model) {
            RT.syncStream(canvas.model.canvasWhiteboards.model, canvas.master_token, (e)=>{
                if (e) {
                    if (!_throwFrameworkUpdateNeededErrorIfNeeded(e)) {
                        _setConnectionState({error: e, mode:"wb"}); // signal connection error
                    }
                } else {
                    postCanvasMessage();
                }
            }, canvas.model.canvasWhiteboards.rt);
        }
        return true;
    } else {
        return false;
    }
*/
}

function setGlobalError(e) {
    console.error(e);
    if (null === canvas.error) {
        canvas.error = e;
        canvas.worker.postMessage(["error", e]);
    }
}

function onCmdCommitSandbox(op: {
    op: DataOperationType.NOOP;
    cmd: "commit_sandbox" | "delete_sandbox";
    sid: string;
    ts: number;
    ops: number;
}) {
    // this sandbox has been commited => notification
    canvas.worker.postMessage(["commit", "stream", op]);
}

async function fetchResource(
    opt: {
        onImport?: (opt: OnImportOptions) => Promise<ParticleCore>;
    },
    event: any,
) {
    let model: DataModel = null;
    const todos = null;
    const resource = event.data[1].key.resource;
    let type = event.data[1]?.key?.resource?.type;
    {
        const name = event.data[1]?.key?.resource?.name;
        if ((name || "").endsWith(".lcmd.json")) {
            type = "application/vnd.lcmd.import.raw+zip";
        }
    }
    if (opt?.onImport) {
        const core = await opt.onImport({ resource, type });
        model = core._model;
    } else {
        assert(false); //move to lcmd2core
    }
    return {
        model,
        todos,
        resource,
    };
}

type CoreEventKeys = keyof CoreEvents;

// type EnsureAllKeys<T extends any[]> = {
//     [K in keyof T]: T[K] extends CoreEventKeys ? T[K] : never;
// } & (Exclude<CoreEventKeys, T[number]> extends never ? T : never);
//
// type CoreEventsToForward = EnsureAllKeys<CoreEventKeys[]>;
//
// type EnsureAllKeys<T extends any[]> = T extends [...infer Keys]
//     ? Exclude<CoreEventKeys, Keys[number]> extends never
//         ? T
//         : never
//     : never;

const coreEventsToForward: CoreEventKeys[] = [
    "processes::durationChanged",
    "processes::durationChanged::positiveEffect",
    "processes::durationChanged::negativeEffect",
    "processes::startDateChanged",
    "processes::startDateChanged::negativeEffect",
    "process::dependencyCreated::circularDependency",
];

function _setCanvasMasterToken(
    master_token: string,
    auth_ret: {
        sub: string;
        role: string;
        pid_name: string;
        pid: string;
        token: string;
        sid: string;
        ts: number;
        ofs: number;
    },
    aux?: { sub: string; role: string },
    key_meta?: any,
    rev?: number,
) {
    canvas.master_token = master_token;
    let readonly = !canvas.sandbox_token || rev > 0; // no SYNC or maxCommit => readonly
    if (auth_ret) {
        canvas.sandbox_sub = auth_ret.sub;
        canvas.sandbox_role = auth_ret.role;
        let _auth_ret: any = null;
        try {
            _auth_ret = unsafeParseJWT(auth_ret?.token);
        } catch (e) {
            _auth_ret = null;
        }
        canvas.meta = {
            dirty: true,
            name: auth_ret?.pid_name || null,
            ts: auth_ret?.ts || 0,
            session: {
                pid: auth_ret.pid,
                master_token: auth_ret.token,
                sandbox: auth_ret.sid,
                ofs: auth_ret.ofs,
            } as WorkerSession,
            role: auth_ret.role,
            readonly: readonly,
            procore: key_meta?.resource?.customFields?.procore || undefined,
            pid: _auth_ret?.pid,
        };
    } else if (aux?.role) {
        canvas.sandbox_sub = aux.sub;
        canvas.sandbox_role = aux.role;
    }
    readonly = readonly || !("admin" === canvas.sandbox_role || "user" === canvas.sandbox_role);
    if (canvas.meta.readonly !== readonly) {
        canvas.meta.dirty = true;
        canvas.meta.readonly = readonly;
    }
    canvas.model.viewConst = { ...canvas.model.viewConst, readonly: readonly };
    canvas.onUpdateCache.core = _ensureCoreFor(canvas.model);

    coreEventsToForward.forEach((coreEventName) => {
        forwardEventToMainThread(coreEventName, canvas.onUpdateCache.core as Core);
    });

    canvas.onUpdateCache.trGt = new TradesGetter(canvas.onUpdateCache.core);
    canvas.onUpdateCache.pGt = new ProcessGetter(canvas.onUpdateCache.core);
    canvas.onUpdateCache._Gt = new ParticleGetter(canvas.onUpdateCache.core);
    canvas.onUpdateCache.worker = canvas.worker;
    assert(undefined === canvas.onUpdateCache.wb);
    {
        const onMetaCanvas = canvas.model?.callbacks?.onMetaCanvas;
        onMetaCanvas && onMetaCanvas(canvas.model);
    }
}

function forwardEventToMainThread(eventName: keyof CoreEvents, core: Core) {
    core.on(eventName, (data, triggeredEvent) => {
        canvas.worker.postMessage(["core::event", data, triggeredEvent]);
    });
}

let _coreCache: Core = null;
let _baselineCache: Core = null;
let _wbCoreCache: Core = null;

export function _ensureCoreFor(model: DataModel) {
    let ret: Core = null;
    if (!model) {
        return ret;
    }

    if (model === canvas.model) {
        if (!_coreCache) {
            _coreCache = Core.newInstance(
                {
                    model: model,
                    auth_token: canvas.master_token,
                },
                canvas,
            );
        }
        assert((_coreCache as any)._model === canvas.model);
        ret = _coreCache;
    } else if (model === canvas.model.baseline) {
        if (!_baselineCache || model !== (_baselineCache as any)._model) {
            _baselineCache = Core.newInstance(
                {
                    model: canvas.model.baseline,
                    auth_token: null,
                },
                canvas,
            );
        }
        assert((_baselineCache as any)._model === canvas.model.baseline);
        ret = _baselineCache;
    } else {
        assert(model.whiteboard);
        if (!_wbCoreCache || model !== (_wbCoreCache as any)._model) {
            _wbCoreCache = Core.newInstance(
                {
                    model: model,
                    auth_token: canvas.master_token,
                },
                canvas,
            );
        }
        assert((_wbCoreCache as any)._model === model);
        ret = _wbCoreCache;
    }

    return ret;
}

export function getCachedCore(): Core {
    return _coreCache;
}

function _onUpdate(
    this: {
        _evt: OnParticleWorkerMsgEvent;
        onCoreUpdate?: (evt: OnParticleCoreUpdateEvent) => void;
    },
    _model: DataModel,
    syncWB?: DataModel,
) {
    const model = _model?.hostModel || _model;
    const whiteboard = _model?.canvasWhiteboards?.model || syncWB || undefined;
    assert(model === (canvas.onUpdateCache.core as any)?._model);
    if (whiteboard !== (canvas.onUpdateCache.wb as any)?._model) {
        canvas.onUpdateCache.wb = whiteboard ? _ensureCoreFor(whiteboard) : undefined;
        canvas.onUpdateCache.wbPGt = canvas.onUpdateCache.wb ? new ProcessGetter(canvas.onUpdateCache.wb) : undefined;
        canvas.onUpdateCache.wbTrGt = canvas.onUpdateCache.wb ? new TradesGetter(canvas.onUpdateCache.wb) : undefined;
        canvas.onUpdateCache.wb_Gt = canvas.onUpdateCache.wb ? new ParticleGetter(canvas.onUpdateCache.wb) : undefined;
        canvas.onUpdateCache.wb_ts = -1;
    }
    assert(whiteboard === (canvas.onUpdateCache.wb as any)?._model);
    const ts = canvas.onUpdateCache.core.getUncommitedTS();
    const wb_ts = canvas.onUpdateCache.wb ? canvas.onUpdateCache.wb.getUncommitedTS() : -1;
    if (syncWB) {
        assert(whiteboard === syncWB);
        model.callbacks.onWhiteboardSync && model.callbacks.onWhiteboardSync(model, whiteboard, true);
    } else {
        if (this.onCoreUpdate) {
            this.onCoreUpdate(canvas.onUpdateCache);
        }
        canvas.onUpdateCache.ts = ts;
        canvas.onUpdateCache.wb_ts = wb_ts;
    }
}

type CommentSubCommands = "get" | "new" | "edit" | "delete";
type CommentCommand = ["comment", CommentSubCommands, any];

function enableBaseline(revId: number) {
    if (canvas?.model && "number" === typeof revId && revId >= 0) {
        canvas.model.setBaseline(revId);
        canvas.onUpdateCache.baseline = _ensureCoreFor(canvas.model.baseline);
        canvas.onUpdateCache.bpGt = canvas.onUpdateCache.baseline
            ? new ProcessGetter(canvas.onUpdateCache.baseline)
            : undefined;
        canvas.onUpdateCache.btrGt = canvas.onUpdateCache.baseline
            ? new TradesGetter(canvas.onUpdateCache.baseline)
            : undefined;
        canvas.onUpdateCache.b_Gt = canvas.onUpdateCache.baseline
            ? new ParticleGetter(canvas.onUpdateCache.baseline)
            : undefined;
    }
}

function getActiveBaseline(core: Core) {
    const hGt = new HiveGetter(core, "lcmd.settings.global");
    return hGt.value<number | undefined>("activebaseline");
}

async function _handleMessage(
    this: {
        queue: (() => Promise<void>)[];
        queueBusy: boolean;
        _evt: OnWorkerMsgEventImpl;
        onWorkerMsg?: (evt: OnParticleWorkerMsgEvent) => Promise<boolean>;
        onCoreUpdate?: (evt: OnParticleCoreUpdateEvent) => void;
        onImport?: (opt: OnImportOptions) => Promise<ParticleCore>;
    },
    event: { data: ["init", any, { view: ViewTypeValueI; options: {} }] | CommentCommand } | any,
) {
    //console.debug("MESSAGE "+JSON.stringify(event.data));
    if (null === canvas.error)
        try {
            /*
        if (null===canvas.renderer) {
            canvas.renderer=false;
            canvas.renderer=await ssfwasm();
            console.log("WASM renderer initialized");
        }
        */
            if (Array.isArray(event.data) && event.data.length >= 2) {
                /*if ("ping"!==event.data[0]) {
                console.info(event.data);
            }*/
                const date0 = Date.now();
                assert(canvas.model === ((_coreCache as any)?._model || null));
                const whiteboard = canvas.model?.canvasWhiteboards?.model || undefined;
                if (whiteboard !== (_wbCoreCache as any)?._model) {
                    if (whiteboard) {
                        _ensureCoreFor(whiteboard);
                    } else {
                        _wbCoreCache = null;
                    }
                }
                assert(whiteboard === (_wbCoreCache as any)?._model);
                this._evt._preventUpdate = false;
                this._evt.core = _coreCache;
                this._evt.wbCore = _wbCoreCache;
                this._evt.msg = event.data;
                if (
                    this?.onWorkerMsg &&
                    "ping" !== event.data[0] &&
                    ("rt" !== event.data[0] || "canvas" === event.data[1]?.mode) &&
                    (await this.onWorkerMsg(this._evt))
                ) {
                    // handled
                    if (!this._evt._preventUpdate) {
                        postCanvasMessage();
                        syncStream(this);
                    }
                } else
                    switch (event.data[0]) {
                        case "import":
                            {
                                /*if (event.data[1]?.projectFile) {
                    const projectFile=event.data[1]?.projectFile;
                    const fileName=event.data[1]?.fileName||"Project";
                    const fileSize=event.data[1]?.fileSize||0;
                    canvas.model=await DataModel.compressAndUploadProjectFile(projectFile, {
                        onupload: function(ev) {
                            canvas.worker.postMessage(["http", "Uploading...", ev.lengthComputable?(ev.loaded*100/ev.total).toFixed(0)+"%":(ev.loaded/1024/1024).toFixed(2)+" MB"]);
                        },
                        onuploaded: function(ev) {
                            canvas.worker.postMessage(["http", "Processing "+fileName+"... ", (fileSize>0?(fileSize/1024/1024).toFixed(2)+" MB":"")]);
                        },
                        onprogress: function(ev) {
                            canvas.worker.postMessage(["http", "Fetching...", ev.lengthComputable?(ev.loaded*100/ev.total).toFixed(0)+"%":(ev.loaded/1024/1024).toFixed(2)+" MB"]);
                        }
                    });
                    const todos=canvas.model.getFontSizeTodos();
                    console.log("ready...");
                    canvas.worker.postMessage(["import", todos]);
                } else*/
                                if (event.data[1]?.jsonFile) {
                                    const jsonFile = event.data[1]?.jsonFile;
                                    const fileName = event.data[1]?.fileName || "Project";
                                    const fileSize = event.data[1]?.fileSize || 0;
                                    canvas.model = LCM.importProject(Buffer.from(jsonFile), false);
                                    const todos = canvas.model.getFontSizeTodos();
                                    console.log("ready...");
                                    canvas.worker.postMessage(["import", todos]);
                                } else if (event.data[1]?.todos) {
                                    const todos = event.data[1]?.todos;
                                    canvas.model.setFontSizes(todos);
                                    // hack => upload stream...
                                    canvas._loaded = 1;
                                    canvas.model.userName = canvas.login?.name || "nn";
                                    canvas.model.updateModel(true);
                                    postCanvasMessage();
                                }
                            }
                            break;
                        case "init":
                            try {
                                if (
                                    !WebAppTelemetryFactory.isIdentified() &&
                                    (event.data[1]?.auth?.sub || event.data[1]?.auth?.auth_result?.sub)
                                ) {
                                    const email =
                                        event.data[1]?.auth?.auth_result?.email || event.data[1]?.auth?.params?.email;
                                    const sub = event.data[1]?.auth?.auth_result?.sub || event.data[1]?.auth?.sub;
                                    if (WebAppTelemetryFactory.isInternalEmail(email)) {
                                        WebAppTelemetryFactory.identify({
                                            userId: sub,
                                            internal: true,
                                            projectId: event.data[1].puid,
                                            domain: WebAppTelemetryFactory.domainEmail(email) || "notDefined",
                                        });
                                    } else {
                                        WebAppTelemetryFactory.identify({
                                            userId: sub,
                                            projectId: event.data[1].puid,
                                            domain: WebAppTelemetryFactory.domainEmail(email) || "notDefined",
                                        });
                                    }

                                    WebAppTelemetryFactory.setRole(event.data[1].key.role.role);
                                }
                                DataModel.renderer = await lcmd2renderer();
                            } catch (err) {
                                console.error("Failed to load renderer");
                                WebAppTelemetryFactory.trackException(
                                    {
                                        id: generateId(),
                                        severityLevel: SeverityLevel.Critical,
                                        exception: err,
                                    },
                                    ERROR_CODES.NETWORK_ERROR_WASM_FILE,
                                );
                                throw err;
                            }
                            console.log("WASM renderer initialized");
                            assert(event.data[1]?.SERVICES);
                            initServices(null, event.data[1].SERVICES);

                            assert("string" === typeof event.data[1]?.warmupId);
                            canvas.warmupId = event.data[1]?.warmupId;
                            canvas.userMeta = event.data[1]?.userMeta || {};

                            if (false && "development" === process.env.NODE_ENV) {
                                const data = await (await fetch("out.json")).json();
                                canvas.model = DataModel.loadOps(data.ops);
                                /*} else if (true && "development"===process.env.NODE_ENV && event.data[1]?.projectFile) {
                            const projectFile=event.data[1]?.projectFile;
                            canvas.model=await DataModel.compressAndUploadProjectFile(projectFile);
                            console.log("ready...");*/
                            } else if (
                                true &&
                                "development" === process.env.NODE_ENV &&
                                "DEV" === event.data[1]?.key?.master_token
                            ) {
                                const data = await (await fetch("procore.json")).json();
                                canvas.model = LCM.importProCore(data);
                                const todos = canvas.model.getFontSizeTodos();
                                console.log("ready...");
                                canvas.worker.postMessage(["import", todos]);
                            } else if (event.data[1]?.key?.procore) {
                                const procore = event.data[1].key.procore;
                                if (procore.auth) {
                                    try {
                                        const tasks = await new Promise(
                                            (resolve: (ret: any) => void, reject: (reason: any) => void) => {
                                                procore_fetch_tasks(
                                                    procore.auth.access_token,
                                                    procore.project_id,
                                                    procore.company_id,
                                                    (error, result) => {
                                                        if (error) {
                                                            //console.log(error);
                                                            reject(error);
                                                        } else {
                                                            resolve(result);
                                                        }
                                                    },
                                                );
                                            },
                                        );
                                        canvas.model = LCM.importProCore(tasks);
                                        canvas.model.updateModel(true);
                                        canvas.procore = procore;
                                    } catch (e) {
                                        console.error(e);
                                        canvas.worker.postMessage([
                                            "import",
                                            {
                                                error: {
                                                    message: "Import Error",
                                                    meta: null,
                                                },
                                            },
                                        ]);
                                    }
                                } else {
                                    canvas.worker.postMessage([
                                        "import",
                                        {
                                            procore: event.data,
                                        },
                                    ]);
                                }
                            } else if ("number" === typeof event.data[1]?.key?.sandbox && event.data[1]?.puid) {
                                // bloody hack: give sandbox a "number" for the "one-sandbox-only" policy
                                try {
                                    if (!event.data[1]?.key?.master_token && event.data[1]?.key?.project_token) {
                                        const data = event.data[1]?.key;
                                        const project_token = data?.project_token;
                                        // check first whether a master was created in the meantime...
                                        const ret = await new Promise(
                                            (resolve: (ret: any) => void, reject: (reason: any) => void) => {
                                                getLog(project_token, data.pid, data.pid_ts, (error, log) => {
                                                    if (error) {
                                                        reject(error);
                                                    } else {
                                                        assert(data.pid_ts === log.id);
                                                        resolve(log);
                                                    }
                                                });
                                            },
                                        );

                                        if (ret.master) {
                                            event.data[1].key.master_token = ret.master;
                                        } else {
                                            const { model, resource } = await fetchResource(this, event);

                                            model.userName = canvas.login?.name || "nn";
                                            model.updateModel(true);
                                            const ret = await model.uploadModel(resource?.token);
                                            assert(ret.pid === event.data[1].key.pid);
                                            event.data[1].key.master_token = ret.token;
                                        }
                                    }
                                    if (event.data[1]?.key?.master_token) {
                                        const project_token = event.data[1].key.project_token;
                                        const master_token = event.data[1].key.master_token;
                                        const _master_token = JSON.parse(atob(master_token.split(".")[1]));
                                        const master_sandbox = _master_token.sid;
                                        let auth_ret = await DataModel.authenticateMasterUser(
                                            project_token,
                                            master_sandbox,
                                        );
                                        if (!auth_ret.token) {
                                            const ret = await new Promise(
                                                (resolve: (ret: any) => void, reject: (reason: any) => void) => {
                                                    create_sandbox(
                                                        master_token,
                                                        (error, result) => {
                                                            if (error) {
                                                                reject(error);
                                                            } else {
                                                                resolve(result);
                                                            }
                                                        },
                                                        "lcmd.db",
                                                        "pid",
                                                        0,
                                                        {
                                                            db: true,
                                                        },
                                                    );
                                                },
                                            );
                                            //@TODO create_sandbox shoud return info needed, authenticateMasterUser call is redundant...
                                            auth_ret = await DataModel.authenticateMasterUser(
                                                project_token,
                                                master_sandbox,
                                            );
                                        }
                                        if (auth_ret.token) {
                                            const sandbox = auth_ret.sid;
                                            if (auth_ret.ts > 0) {
                                                assert(false); //@TODO
                                            } else {
                                                canvas.model = await DataModel.loadStream(
                                                    sandbox,
                                                    master_token,
                                                    0,
                                                    auth_ret.ofs,
                                                    true,
                                                ); //@TODO: use master token from openSandbox...
                                                canvas.model.setStorageName(sandbox);
                                                canvas.sandbox_token = "AUTH"; //@TODO
                                            }
                                            _setCanvasMasterToken(
                                                master_token,
                                                auth_ret,
                                                undefined,
                                                event.data[1]?.key,
                                                event.data[1]?.rev,
                                            );
                                        } else {
                                            throw new Error();
                                        }
                                    } else {
                                        throw new Error();
                                    }
                                } catch (e) {
                                    console.error("Worker init error", e);
                                    let project_token = null;
                                    try {
                                        project_token = JSON.parse(
                                            atob((event.data[1]?.key?.project_token || "").split(".")[1]),
                                        );
                                    } catch (e) {
                                        console.error(e);
                                    }
                                    const _meta: CanvasMeta = {
                                        name: "Error loading project!",
                                        ts: -1,
                                        session: event.data[1]?.key,
                                        role: null,
                                        error: "Import Error",
                                    };
                                    canvas.worker.postMessage([
                                        "import",
                                        {
                                            error: {
                                                message: _meta.error,
                                                meta: _meta,
                                            },
                                        },
                                    ]);
                                }
                            } else if (
                                true /* && "development"===process.env.NODE_ENV*/ &&
                                event.data[1]?.puid &&
                                event.data[1]?.key?.master_token &&
                                event.data[1]?.key?.sandbox
                            ) {
                                try {
                                    const master_token = event.data[1].key.master_token;
                                    const _master_token = JSON.parse(atob(master_token.split(".")[1]));
                                    const sandbox = event.data[1]?.key?.sandbox;
                                    //canvas.model=await DataModel.loadStream(event.data[1]?.puid, event.data[1]?.key?.master_token);
                                    const ret = await DataModel.openSandbox(master_token, sandbox);
                                    if (ret.ts > 0) {
                                        // if (false) {
                                        //     const master_model = await DataModel.loadStream(
                                        //         ret.master,
                                        //         master_token,
                                        //         0,
                                        //         ret.ofs,
                                        //         true,
                                        //     ); //@TODO: use master token from openSandbox...
                                        //     {
                                        //         // get sandbox
                                        //         const syncRet = master_model.getSync(0);
                                        //         assert(ret.ofs === syncRet.sync.ofs);
                                        //         const _ret = await DataModel.pushStreamOps(sandbox, {
                                        //             ofs: syncRet.sync.ofs,
                                        //             ops: [], // only get!!
                                        //         });
                                        //         const commitRet = master_model.commitSync(_ret, syncRet.token);
                                        //         assert(0 === commitRet); // merged upstream changes
                                        //         assert(master_model.commitTS() >= ret.ts);
                                        //         if (master_model.commitTS() > ret.ts) {
                                        //             // sandbox has been modified after commit... just update it...
                                        //             ret.ts = master_model.commitTS();
                                        //         }
                                        //         assert(master_model.commitTS() === ret.ts);
                                        //         canvas.merge_token = {
                                        //             sandbox: sandbox,
                                        //             sandbox_ofs: _ret.ofs + _ret.ops.length,
                                        //         };
                                        //     }
                                        //     const master_model_snap = master_model._snap();
                                        //     const importedTS = master_model_snap.imported;
                                        //     const commitedTS = master_model_snap.commited;
                                        //     assert(
                                        //         commitedTS === master_model.ops.length &&
                                        //             ret.ts === master_model.ops.length &&
                                        //             ret.ofs === importedTS,
                                        //     );
                                        //     canvas.model = new DataModel();
                                        //     {
                                        //         // apply local....
                                        //         canvas.model._pushOperation(master_model.cloneOps(0, importedTS));
                                        //         canvas.model.updateModel(true);
                                        //         canvas.model._pushOperation(
                                        //             master_model.cloneOps(importedTS, commitedTS),
                                        //         );
                                        //         canvas.model.updateModel();
                                        //     }
                                        //     {
                                        //         // sync upstream
                                        //         const syncRet = canvas.model.getSync(0);
                                        //         assert(importedTS === syncRet.sync.ofs);
                                        //         const ret = await DataModel.pushStreamOps(_master_token?.sid, {
                                        //             ofs: syncRet.sync.ofs,
                                        //             ops: [], // only get!!
                                        //         });
                                        //         const commitRet = canvas.model.commitSync(ret, syncRet.token);
                                        //         assert(0 === commitRet); // merged upstream changes
                                        //     }
                                        // }
                                        // merge request
                                        canvas.merge_token = {
                                            sandbox: null,
                                            sandbox_ofs: 0,
                                        };
                                        canvas.model = await DataModel.createMergeRequest(
                                            master_token,
                                            ret,
                                            sandbox,
                                            canvas.merge_token,
                                        );
                                        assert(canvas.merge_token.sandbox && canvas.merge_token.sandbox_ofs > 0);
                                        assert(!canvas.sandbox_token); // no sync...
                                    } else {
                                        canvas.model = await DataModel.loadStream(
                                            ret.master,
                                            master_token,
                                            0,
                                            ret.ofs,
                                            true,
                                        ); //@TODO: use master token from openSandbox...
                                        canvas.model.setStorageName(sandbox);
                                        canvas.sandbox_token = "AUTH"; //@TODO
                                    }
                                    _setCanvasMasterToken(master_token, null, ret, undefined, event.data[1]?.rev);
                                } catch (e) {
                                    canvas.worker.postMessage([
                                        "import",
                                        {
                                            error: {
                                                message: "Import Error",
                                            },
                                        },
                                    ]);
                                }
                            } else if (
                                true /* && "development"===process.env.NODE_ENV*/ &&
                                event.data[1]?.puid &&
                                event.data[1]?.key?.master_token
                            ) {
                                try {
                                    const master_token = event.data[1].key.master_token;
                                    canvas.model = await DataModel.loadStream(
                                        event.data[1]?.puid,
                                        event.data[1]?.key?.master_token,
                                        undefined,
                                        undefined,
                                        true,
                                    );
                                    _setCanvasMasterToken(master_token, null, null, undefined, event.data[1]?.rev);
                                } catch (e) {
                                    canvas.worker.postMessage([
                                        "import",
                                        {
                                            error: {
                                                message: "Import Error",
                                            },
                                        },
                                    ]);
                                }
                            } else if (
                                true /* && "development"===process.env.NODE_ENV*/ &&
                                event.data[1]?.puid &&
                                event.data[1]?.key?.resource?.token
                            ) {
                                try {
                                    let { model, todos, resource } = await fetchResource(this, event);
                                    canvas.model = model;
                                    canvas.resource = resource;
                                    if (null === todos) {
                                        todos = canvas.model.getFontSizeTodos();
                                    }
                                    console.log("ready...");
                                    canvas.worker.postMessage(["import", todos]);
                                } catch (e) {
                                    canvas.worker.postMessage([
                                        "import",
                                        {
                                            error: {
                                                message: "Import Error",
                                            },
                                        },
                                    ]);
                                }
                            } else if (false /* && "development"===process.env.NODE_ENV*/ && event.data[1]?.puid) {
                                /*
                            const data=await(await (await fetch(event.data[1]?.puid+".lcm")).arrayBuffer());
                            canvas.model=DataModel.loadCompressedOps(data);
                            {
                                const storageName=event.data[1]?.puid;
                                const key=event.data[1]?.key;
                                if (key) {
                                    const ret=await canvas.model.openSandbox(storageName, key);
                                    console.log(ret);
                                } else {
                                    const ret=await canvas.model.createSandbox(storageName);
                                    console.log(ret);
                                }
                            }
                            if (false && "development"===process.env.NODE_ENV) {
                                canvas.worker.postMessage(["tasklist", {
                                    tasks: canvas.model.getTaskList(47156)
                                }]);
                            }
                            */
                            } else {
                                canvas.worker.postMessage([
                                    "import",
                                    {
                                        error: {
                                            message: "Import Error",
                                        },
                                    },
                                ]);
                            }
                            if (canvas.model) {
                                canvas._loaded = 1;
                                canvas.model.userName = canvas.model.userName || null;
                                const maxCommit = canvas.model.setMaxCommit(event.data[1]?.rev);
                                if (maxCommit > 0) {
                                    canvas.meta.revId = maxCommit;
                                    canvas.meta.dirty = true;
                                    canvas.model.syncStreamPending = -1; // disable sync...
                                }
                                try {
                                    await canvas.model.doInitialSyncFromCache(
                                        DataModel.cache,
                                        canvas.master_token,
                                        maxCommit || undefined,
                                    );
                                } catch (e) {
                                    canvas.worker.postMessage([
                                        "import",
                                        {
                                            error: {
                                                message: "Import Error",
                                            },
                                        },
                                    ]);
                                }

                                try {
                                    if (!(await syncStream(this))) {
                                        postCanvasMessage();
                                    }
                                } catch (e) {
                                    postCanvasMessage();
                                }
                                console.log("LOADED:" + JSON.stringify(canvas.model._snap()));
                                console.warn(event.data[2]);

                                const restoredSessionData = event.data[2];
                                const view = restoredSessionData.view;
                                const scaleSettings =
                                    restoredSessionData.options?.scaleSettings >= 0
                                        ? restoredSessionData.options?.scaleSettings
                                        : 1;

                                canvas.model.selectView(scaleSettings, view === "standard" ? null : view);
                                if (view === "baseline") {
                                    const activeBaseline = getActiveBaseline(canvas.onUpdateCache.core);
                                    if (activeBaseline) {
                                        enableBaseline(activeBaseline);
                                        canvas.model.showBaseline(true);
                                    }
                                }

                                canvas.model.selectView(undefined, undefined, {
                                    sidebarColImage: restoredSessionData.options?.taktZoneImages
                                        ? CONST.sidebarColImage
                                        : 0,
                                    showProjectWeeks: restoredSessionData.options?.showProjectWeeks,
                                    showTaktzones: restoredSessionData.options?.showTaktzones,
                                    showStatusBar: restoredSessionData.options?.showStatusBar,
                                });

                                canvas.model.updateCollapsedAreaList(restoredSessionData.collapsedAreaList);

                                if (restoredSessionData.filter) {
                                    try {
                                        const core = canvas.onUpdateCache.core;

                                        if (
                                            typeof restoredSessionData.filter !== "string" &&
                                            typeof restoredSessionData.filter !== "number"
                                        ) {
                                            if (restoredSessionData.filter.trades?.length === 0) {
                                                restoredSessionData.filter.trades = null;
                                            }

                                            restoredSessionData.filter.status =
                                                restoredSessionData.filter?.status instanceof Set
                                                    ? restoredSessionData.filter.staus
                                                    : new Set(restoredSessionData.filter.status);
                                        }

                                        await core.setAndSaveFilterIfNeeded(restoredSessionData.filter, null);
                                        const filter = core.getFilter();
                                        core.selectView({
                                            stackProcesses: Array.isArray(filter?.trade) && filter?.trade.length > 0,
                                        });
                                    } catch (error) {
                                        console.error(error);
                                    }
                                }

                                if (typeof event.data[2].options?.showNonWorkingDays === "boolean") {
                                    canvas.model.updateGridBasedOnWorkDays(event.data[2].options?.showNonWorkingDays);
                                }

                                canvas.model.callbacks.cmdCommitSandbox = onCmdCommitSandbox;
                                canvas.model.callbacks.onUpdate = _onUpdate.bind(this);
                                canvas.model.callbacks.onCanvasProcessUnchanged = (
                                    model: DataModel,
                                    ct: CanvasTaskData,
                                    pid,
                                    userCtx,
                                ) => {
                                    const isPP = model === canvas.model;
                                    const pGt = isPP ? canvas.onUpdateCache.pGt : canvas.onUpdateCache.wbPGt;
                                    assert(model === (pGt.core as any)._model);
                                    const cb = isPP
                                        ? canvas.model.callbacks._PPPUnchanged
                                        : canvas.model.callbacks._WBPUnchanged;
                                    return cb && cb(canvas.onUpdateCache, ct, pGt.reset(pid), userCtx);
                                };
                                canvas.model.callbacks.onCanvasProcessChanged = (
                                    model: DataModel,
                                    ct: CanvasTaskData,
                                    pid,
                                    userCtx,
                                ) => {
                                    const isPP = model === canvas.model;
                                    const pGt = isPP ? canvas.onUpdateCache.pGt : canvas.onUpdateCache.wbPGt;
                                    assert(model === (pGt.core as any)._model);
                                    const cb = isPP
                                        ? canvas.model.callbacks._PPPChanged
                                        : canvas.model.callbacks._WBPChanged;
                                    cb && cb(canvas.onUpdateCache, ct, pGt.reset(pid), userCtx);
                                };
                                canvas.model.callbacks.onCanvasProcessUpdateCtx = (model: DataModel, pid, userCtx) => {
                                    const isPP = model === canvas.model;
                                    const pGt = isPP ? canvas.onUpdateCache.pGt : canvas.onUpdateCache.wbPGt;
                                    assert(model === (pGt.core as any)._model);
                                    const cb = isPP
                                        ? canvas.model.callbacks._PPPUpdateCtx
                                        : canvas.model.callbacks._WBPUpdateCtx;
                                    cb && cb(canvas.onUpdateCache, pGt.reset(pid), userCtx);
                                };

                                const _coreResolveExtValue = canvas.model.callbacks._coreResolveExtValue;
                                canvas.model.callbacks.onResolveExtValue = _coreResolveExtValue
                                    ? (ext: number | number[]) => {
                                          return _coreResolveExtValue(ext, canvas.onUpdateCache._Gt);
                                      }
                                    : null;
                                canvas.model.callbacks.onWhiteboardSync = canvas.model.callbacks._onWBSync
                                    ? (model: DataModel, wb: DataModel, init?: boolean) => {
                                          assert(model === (canvas.onUpdateCache.core as any)._model);
                                          assert(wb === (canvas.onUpdateCache.wb as any)._model);
                                          canvas.model.callbacks._onWBSync(canvas.onUpdateCache, init);
                                      }
                                    : null;
                                /*canvas.model.callbacks._coreResolveExtValue?(ext: number | number[]){
                                canvas.onUpdateCache._Gt
                            }:null;*/
                                canvas.model._rejectedOps = []; // enabled tracking of rejected after loading...
                                if (canvas.model.userName) {
                                    connectRT(
                                        this,
                                        "canvas",
                                        canvas.model,
                                        rt_pp,
                                        canvas.model.storageName,
                                        canvas.model.userName,
                                        canvas.sandbox_token,
                                        canvas.warmupId,
                                    );
                                }
                                //this.setInterval(onTimer, hackms);
                                // if (false) {
                                //     const todos = canvas.model.getFontSizeTodos(false);
                                //     canvas.worker.postMessage(["import", todos]);
                                // }
                            }
                            break;
                        case "commit":
                            {
                                try {
                                    if ("submit" === event.data[1]) {
                                        canvas.worker.postMessage(["commit", "submitting", {}]);
                                        const ret = await canvas.model.submitChanges("commit_sandbox");
                                        if (ret) {
                                            const _snap = canvas.model._snap();
                                            canvas.worker.postMessage([
                                                "commit",
                                                "submit",
                                                {
                                                    snap: _snap,
                                                },
                                            ]);
                                        } else {
                                            canvas.worker.postMessage(["commit", "conflict", {}]);
                                        }
                                    } else if ("commit2" === event.data[1]) {
                                        const commit2 = event.data[2];
                                        assert(canvas.master_token);
                                        const master_token = JSON.parse(atob(canvas.master_token.split(".")[1]));
                                        if (!canvas.merge_token) {
                                            canvas.worker.postMessage(["commit", "submitting", {}]);
                                            // should not happen...
                                            /*
                                canvas.worker.postMessage(["commit", "submitting", {}]);
                                const ret=await canvas.model.submitChanges();
                                if (ret) {
                                    // merge request
                                    const master_ret=await DataModel.openSandbox(canvas.master_token, canvas.model.storageName);
                                    const merge_token={
                                        sandbox: null,
                                        sandbox_ofs: 0
                                    };
                                    const merge_model=await DataModel.createMergeRequest(canvas.master_token, master_ret, canvas.model.storageName, merge_token);
                                    assert(merge_token.sandbox && merge_token.sandbox_ofs>0);
                                    const ret=await merge_model.commitChanges(master_token.sid, merge_token);
                                    if (ret) {
                                        canvas.worker.postMessage(["commit", "commit2", {
                                        }]);
                                    } else {
                                        canvas.worker.postMessage(["commit", "conflict", {
                                        }]);
                                    }
                                } else {
                                    canvas.worker.postMessage(["commit", "conflict", {
                                    }]);
                                }
                                */
                                        } else {
                                            // sync upstream
                                            const ret = await canvas.model.commitChanges(
                                                master_token.sid,
                                                canvas.merge_token,
                                            );
                                            if (ret) {
                                                canvas.worker.postMessage(["commit", "commit2", {}]);
                                            } else {
                                                canvas.worker.postMessage(["commit", "conflict", {}]);
                                            }
                                        }
                                    } else if ("reopen" === event.data[1]) {
                                        const reopen = event.data[2];
                                        canvas.worker.postMessage(["commit", "reopening", {}]);
                                        assert(canvas?.master_token);
                                        const _snap = canvas.model._snap();
                                        const importedTS = _snap.imported;
                                        const commitedTS = _snap.commited;
                                        const ret = await new Promise(
                                            (resolve: (ret: any) => void, reject: (reason: any) => void) => {
                                                create_sandbox(
                                                    canvas?.master_token,
                                                    async (error, result) => {
                                                        if (!error && result?.meta_ts >= 0) {
                                                            // have sandbox, push stuff
                                                            assert(
                                                                result.storageName &&
                                                                    result.meta_ts > 0 &&
                                                                    importedTS === result.meta_ts,
                                                            );
                                                            assert(
                                                                importedTS < commitedTS &&
                                                                    DataOperationType.NOOP ===
                                                                        canvas.model.ops[importedTS].op &&
                                                                    canvas.merge_token?.sandbox ===
                                                                        (canvas.model.ops[importedTS] as any).sid,
                                                            );
                                                            const submit_model = new DataModel();
                                                            {
                                                                // apply local....
                                                                submit_model._pushOperation(
                                                                    canvas.model.cloneOps(0, importedTS),
                                                                );
                                                                submit_model.updateModel(true);
                                                                submit_model._pushOperation(
                                                                    canvas.model.cloneOps(importedTS, commitedTS),
                                                                );
                                                                submit_model.updateModel();
                                                            }
                                                            {
                                                                // sync upstream
                                                                const syncRet = submit_model.getSync(0);
                                                                assert(importedTS === syncRet.sync.ofs);
                                                                const ret = await DataModel.pushStreamOps(
                                                                    result?.storageName,
                                                                    syncRet.sync,
                                                                );
                                                                assert(commitedTS === ret.ofs);
                                                                const commitRet = submit_model.commitSync(
                                                                    ret,
                                                                    syncRet.token,
                                                                );
                                                                assert(1 === commitRet); // pushed ops..
                                                            }
                                                            resolve(result);
                                                        } else {
                                                            resolve(null);
                                                        }
                                                    },
                                                    reopen.sandbox_name,
                                                    null,
                                                    importedTS,
                                                    {
                                                        reopen: canvas.merge_token.sandbox,
                                                        overrideTS: importedTS,
                                                    },
                                                );
                                            },
                                        );
                                        if (ret) {
                                            canvas.worker.postMessage(["commit", "reopen", {}]);
                                        } else {
                                            canvas.worker.postMessage(["commit", "conflict", {}]);
                                        }
                                    } else if ("delete" === event.data[1]) {
                                        //canvas.worker.postMessage(["commit", "submitting", {}]);
                                        const ret = await canvas.model.submitChanges("delete_sandbox");
                                        if (ret) {
                                            const _snap = canvas.model._snap();
                                            canvas.worker.postMessage([
                                                "commit",
                                                "delete",
                                                {
                                                    snap: _snap,
                                                },
                                            ]);
                                        } else {
                                            canvas.worker.postMessage(["commit", "conflict", {}]);
                                        }
                                    }
                                } catch (e) {
                                    console.error(e);
                                    canvas.worker.postMessage(["commit", "error", errorToJSON(e)]);
                                }
                                RT.signalReload(canvas.model, rt_pp);
                            }
                            break;
                        case "uploadModel":
                            if (canvas.model && canvas.resource?.token) {
                                try {
                                    const ret = await canvas.model.uploadModel(canvas.resource?.token);
                                    //canvas.worker.postMessage(["framework", "sandboxPopup", {
                                    //    session: {
                                    //        pid: ret.pid,
                                    //        project_token: ret.pid?ret.token:null,
                                    //        resource: null, /*ret.rid?{
                                    //            token: ret.token
                                    //        }:null*/
                                    //        master_token: ret.token,
                                    //        sandbox: null
                                    //    } as WorkerSession,
                                    //    view: 0
                                    //} as SandboxPopupOptions]);
                                } catch (e) {
                                    canvas.worker.postMessage([
                                        "framework",
                                        "sandboxPopup",
                                        {
                                            session: null,
                                            error: "Upload error " + e.toString(),
                                        },
                                    ]);
                                }
                            }
                            break;
                        case "canvas":
                            if ("reload" === event.data[1]) {
                                canvas._force = true;
                                postCanvasMessage();
                            } else if ("user-meta" === event.data[1]) {
                                const cmd = event.data[1];
                                const opt = event.data[2];
                                switch (cmd) {
                                    case "user-meta":
                                        if (opt) {
                                            canvas.userMeta = opt;
                                            const onMetaCanvas = canvas.model?.callbacks?.onMetaCanvas;
                                            onMetaCanvas && onMetaCanvas(canvas.model);
                                        }
                                        break;
                                }
                                postCanvasMessage();
                            } else {
                                canvas.clientReady = true;
                                postCanvasMessage();
                            }
                            break;
                        case "v":
                            assert_dev(false); // RETIRED
                            break;
                        case "login":
                            assert_dev(false); // RETIRED
                            break;
                        case "sz":
                            {
                                const sz = event.data[1];
                                if (DataModel.renderer) {
                                    const text = sz.text.split("\n").join("");
                                    const p = 2; // padding ? @todo: check if padding or what is p
                                    const maxTextWidth = 24; // @todo: what is 24 ? px ? chars ?
                                    const m = DataModel.renderer.measureTextEx(
                                        text,
                                        maxTextWidth,
                                        sz.w - p - p,
                                        sz.h - p - p,
                                        0,
                                        1,
                                    );
                                    const lines = m.lines
                                        .map((l, i) => text.substring(i > 0 ? m.lines[i - 1].end : 0, l.end))
                                        .join("\n");
                                    canvas.worker.postMessage([
                                        "sz",
                                        {
                                            ...sz,
                                            _fs: m.fs,
                                            _w: m.width,
                                            _h: Math.max(m.lineHeight, m.height),
                                            lines,
                                            lh: m.lineHeight,
                                        },
                                    ]);
                                }
                            }
                            break;
                        case "rename":
                            {
                                const rename = event.data[1];
                                canvas.model.pushOperation([
                                    {
                                        op: DataOperationType.UPDATE,
                                        target: DataOperationTarget.TASKS,
                                        id: rename.taskId,
                                        name: "name",
                                        value: rename.text,
                                        z: 0,
                                        _u: canvas.model.userName,
                                        group: false,
                                    } /*,{
                        op: DataOperationType.UPDATE,
                        target: DataOperationTarget.TASKS,
                        id: rename.taskId,
                        name: "fs",
                        value: rename.fs,
                        z: 0,
                        _u: canvas.model.userName,
                        group: false
                    }*/,
                                ]);
                                postCanvasMessage();
                                syncStream(this);
                            }
                            break;
                        case "add":
                            {
                                const add = event.data[1];
                                const taskId = canvas.model.maxIds[DataOperationTarget.TASKS] + 1;
                                const x1 = DataModel.adjustToWorkingDay(
                                    canvas.model.taskCalendarHack,
                                    canvas.model.grid.gridToDate(add.x1),
                                    +1,
                                );
                                assert_dev(add.trade >= 0, "LCM2-725");
                                const days = add.x2 - add.x1;
                                canvas.model.pushOperation(
                                    [
                                        {
                                            op: DataOperationType.CREATE,
                                            target: DataOperationTarget.TASKS,
                                            id: taskId,
                                            name: "p",
                                            i: add.stripej,
                                            value: add.stripet,
                                            z: 0,
                                            _u: canvas.model.userName,
                                            group: true,
                                        } as any,
                                        add.trade >= 0
                                            ? {
                                                  op: DataOperationType.UPDATE,
                                                  target: DataOperationTarget.TASKS,
                                                  id: taskId,
                                                  name: "#RW" + add.trade.toString(16),
                                                  value: 0, // completed..
                                                  z: 0,
                                                  _u: canvas.model.userName,
                                                  group: true,
                                              }
                                            : null,
                                        {
                                            op: DataOperationType.UPDATE,
                                            target: DataOperationTarget.TASKS,
                                            id: taskId,
                                            name: "name",
                                            value: add.text,
                                            z: 0,
                                            _u: canvas.model.userName,
                                            group: true,
                                        },
                                        /*{
                    op: DataOperationType.UPDATE,
                    target: DataOperationTarget.TASKS,
                    id: taskId,
                    name: "fs",
                    value: add.fs,
                    z: 0,
                    _u: canvas.model.userName,
                    group: true
                },*/ {
                                            op: DataOperationType.UPDATE,
                                            target: DataOperationTarget.TASKS,
                                            id: taskId,
                                            name: "start",
                                            value: x1,
                                            z: 0,
                                            _u: canvas.model.userName,
                                            group: true,
                                        },
                                        {
                                            op: DataOperationType.UPDATE,
                                            target: DataOperationTarget.TASKS,
                                            id: taskId,
                                            name: "days",
                                            value: days,
                                            z: 0,
                                            _u: canvas.model.userName,
                                            group: true,
                                        },
                                        {
                                            op: DataOperationType.UPDATE,
                                            target: DataOperationTarget.TASKS,
                                            id: taskId,
                                            name: "stripey",
                                            value: add.y,
                                            z: 0,
                                            _u: canvas.model.userName,
                                            group: false,
                                        },
                                    ].filter((op) => op),
                                );
                                canvas.model.updateModel();
                                postCanvasMessage();
                                syncStream(this);
                            }
                            break;
                        case "rt":
                            {
                                const msg = event.data[1] as any;
                                //const model=("wb"===msg?.mode?canvas.model.canvasWhiteboards?.model:canvas.model);
                                if (msg?.ts > 0) {
                                    const rt = "wb" === msg?.mode ? canvas.model?.canvasWhiteboards?.rt : rt_pp;
                                    if (rt) {
                                        rt.pointerMoved(msg.x, msg.y, msg.n);
                                    }
                                }
                            }
                            break;
                        case "rtsync":
                            {
                                const msg = event.data[1] as any;
                                assert(
                                    msg.model === canvas.model || msg.model === canvas.model?.canvasWhiteboards?.model,
                                );
                                if (msg.sts > msg.model.syncSentTS()) {
                                    syncStream(this);
                                }
                            }
                            break;
                        case "strmsync":
                            {
                                const msg = event.data[1] as any;
                                postCanvasMessage();
                            }
                            break;
                        case "undo":
                            {
                                if ("workshop" === event.data[1]?.target) {
                                    const wb = event.data[1].wb;
                                    if (canvas.model.canvasWhiteboards.model?.storageName === wb) {
                                        canvas.model.canvasWhiteboards.model.undo();
                                        postCanvasMessage();
                                        syncStream(this);
                                    }
                                } else {
                                    if (canvas.model) {
                                        canvas.model.undo();
                                        postCanvasMessage();
                                        syncStream(this);
                                    }
                                }
                            }
                            break;
                        case "redo":
                            {
                                if ("workshop" === event.data[1]?.target) {
                                    const wb = event.data[1].wb;
                                    if (canvas.model.canvasWhiteboards.model?.storageName === wb) {
                                        canvas.model.canvasWhiteboards.model.redo();
                                        postCanvasMessage();
                                        syncStream(this);
                                    }
                                } else {
                                    if (canvas.model) {
                                        canvas.model.redo();
                                        postCanvasMessage();
                                        syncStream(this);
                                    }
                                }
                            }
                            break;
                        case "refresh":
                            {
                                if (canvas.model) {
                                    syncStream(this);
                                }
                            }
                            break;
                        case "reconnect":
                            {
                                if (canvas.model) {
                                    syncStream(this);
                                }
                                if (rt_pp) {
                                    if (canvas.model) {
                                        canvas.model.setErrorState(null);
                                    }
                                    rt_pp.reconnect();
                                }
                                if (canvas.model.canvasWhiteboards.rt) {
                                    if (canvas.model.canvasWhiteboards.model) {
                                        canvas.model.canvasWhiteboards.model.setErrorState(null);
                                    }
                                    canvas.model.canvasWhiteboards.rt.reconnect();
                                }
                                postCanvasMessage();
                            }
                            break;
                        case "details":
                            {
                                assert(false); // REMOVE ME
                            }
                            break;
                        case "progress":
                            {
                                assert(false); // REMOVE ME!
                            }
                            break;
                        case "setFilter":
                            {
                                assert(false); // REMOVE ME!
                            }
                            break;
                        case "comment":
                            {
                                const data = event.data as CommentCommand;
                                const commentId =
                                    typeof data.at(2).commentId === "string"
                                        ? parseInt((<string>data.at(2).commentId).replace("#C", "0x"))
                                        : undefined;
                                let skipSync = false;
                                const core = canvas.onUpdateCache.core;
                                const processId = event.data[2].processId;

                                switch (data.at(1)) {
                                    case "get":
                                        {
                                            const callback = event.data.at(2).callback;
                                            const comments = await core.comments.get(processId);
                                            canvas.worker.postMessage(["cb", callback, comments]);
                                            skipSync = true;
                                        }
                                        break;
                                    case "new":
                                        {
                                            const comment = event.data[2].text;
                                            core.comments.create(processId, comment);
                                        }
                                        break;
                                    case "edit":
                                        {
                                            const comment = event.data[2].text;
                                            core.comments.update(processId, commentId, comment);
                                        }
                                        break;
                                    case "delete":
                                        {
                                            core.comments.delete(processId, commentId);
                                        }
                                        break;
                                    default:
                                        skipSync = true;
                                        break;
                                }

                                if (skipSync) {
                                    break;
                                }

                                postCanvasMessage();
                                syncStream(this);

                                if (event.data[2]?.notificationWrapperData) {
                                    const { fromUserId, users, notification, auth_token } =
                                        event.data[2].notificationWrapperData;

                                    if (!fromUserId || !users || !notification) {
                                        console.error("Core Worker: Missing require notification parameters");
                                        return false;
                                    }

                                    canvas.onUpdateCache.core.notificationService.sendNotification(
                                        fromUserId,
                                        users,
                                        notification,
                                        auth_token,
                                    );
                                }
                            }
                            break;
                        case "export":
                            {
                                if (canvas.model) {
                                    const options = event.data[1];
                                    if ("string" === typeof options) {
                                        assert_dev(false); // move to lcmd
                                    } else if ("pdf" === options?.target) {
                                        assert_dev(false); // moved to lcmd2core
                                    } else if ("xls" === options?.target) {
                                        assert_dev(false); // no longer supported
                                    } else if ("raw" === options?.target) {
                                        //const buffer=canvas.model.getCompressedOps();
                                        const model =
                                            "wb" === options?.mode
                                                ? canvas.model.canvasWhiteboards?.model
                                                : canvas.model;
                                        if (model) {
                                            const data = {
                                                "lcmd.raw.ops": model.getRawOps(),
                                                "lcmd.raw.cache":
                                                    "function" === typeof (DataModel.cache as any)?._exportCache
                                                        ? await (DataModel.cache as any)._exportCache()
                                                        : null,
                                            };
                                            const buffer = Buffer.from(JSON.stringify(data)).buffer;
                                            canvas.worker.postMessage(
                                                [
                                                    "export",
                                                    {
                                                        data: buffer,
                                                        type: "lcmd",
                                                        mime: "application/vnd.lcmdigital.raw",
                                                        name: "export.lcmd.json",
                                                    },
                                                ],
                                                [buffer],
                                            );
                                        }
                                    } else if ("mpp.xml" === options?.target) {
                                        assert_dev(false); // moved to lcmd2core
                                    } else if ("changes.csv" === options?.target) {
                                        const buffer = canvas.model.exportAsChangesCSV(options?.opt);
                                        canvas.worker.postMessage(
                                            [
                                                "export",
                                                {
                                                    data: buffer,
                                                    type: "csv",
                                                    mime: "text/csv",
                                                    name: "changes.csv",
                                                },
                                            ],
                                            [buffer.buffer],
                                        );
                                    } else if ("processes.csv" === options?.target) {
                                        const buffer = canvas.model.exportAsProcessesCSV(options?.opt);
                                        canvas.worker.postMessage(
                                            [
                                                "export",
                                                {
                                                    data: buffer,
                                                    type: "csv",
                                                    mime: "text/csv",
                                                    name: "processes.csv",
                                                },
                                            ],
                                            [buffer.buffer],
                                        );
                                    } else if ("csv" === options?.target) {
                                        assert_dev(false); // moved to lcmd2core
                                    } else if ("msproject" === options?.target) {
                                        const patch = await canvas.model.generatePatch(options?.opt, canvas.meta);
                                        const buffer = Buffer.from(JSON.stringify(patch), "utf-8");
                                        canvas.worker.postMessage(
                                            [
                                                "export",
                                                {
                                                    data: buffer,
                                                    type: "json",
                                                    mime: "text/json",
                                                    name: "patch.json",
                                                },
                                            ],
                                            [buffer.buffer],
                                        );
                                    } else if ("clone" === options?.target) {
                                        assert_dev(false); // moved to lcmd2core
                                    } else if ("procore" === options?.target) {
                                        assert(false); // no longer supported
                                    }
                                }
                            }
                            break;
                        case "activity":
                            {
                                assert(false); // no longer supported
                            }
                            break;
                        case "async":
                            {
                                const props = event.data[2];
                                switch (event.data[1]) {
                                    case "drag":
                                        {
                                            // if (false && "canvas" === props.mode) {
                                            /*
                                        const helper={};
                                        const tids=props.tids;
                                        const n_tids=tids.length;
                                        for(let i_tid=0;i_tid<n_tids;i_tid++) {
                                            const tid=tids[i_tid];
                                            const t=canvas.model.tasks[tid];
                                            if (t) {
                                                const deps=canvas.model._REMOVE_ME_getDependencyChain(tid, t);
                                                const rels=Object.getOwnPropertyNames(deps.rel);
                                                const n_rels=rels.length;
                                                for(let i_rel=0;i_rel<n_rels;i_rel++) {
                                                    const _id=rels[i_rel];
                                                    helper[_id]=true;  //@TODO "max-resolution-date"
                                                }
                                            }
                                        }
                                        const selTids=Object.getOwnPropertyNames(helper).map((_id)=>Number.parseInt(_id));
                                        canvas.worker.postMessage(["async", "drag", {
                                            ts: props.ts,
                                            mode: props.mode,
                                            tids: selTids
                                        }]);
                                        */
                                            // }
                                        }
                                        break;
                                }
                            }
                            break;
                        case "trade":
                            {
                                switch (event.data[1]) {
                                    case "create":
                                        {
                                            assert(false); // no longer supported
                                        }
                                        break;
                                    case "update":
                                        {
                                            const msg = event.data[2];
                                            const subs = Object.getOwnPropertyNames(msg?.subs || {}).reduce(
                                                (ret, sub) => {
                                                    ret[sub] = msg.subs[sub] ? 1 : 0;
                                                    return ret;
                                                },
                                                {},
                                            );
                                            let success = true;
                                            if (subs && Object.getOwnPropertyNames(subs).length > 0) {
                                                success = await new Promise(
                                                    (
                                                        resolve: (ret: boolean) => void,
                                                        reject: (reason: any) => void,
                                                    ) => {
                                                        updateDB(canvas.master_token, subs, (error, result) => {
                                                            if (error) {
                                                                resolve(false);
                                                            } else {
                                                                resolve(true);
                                                            }
                                                        });
                                                    },
                                                );
                                            }
                                            if (success) {
                                                if (msg.name === null) {
                                                    const core = canvas.onUpdateCache.core;
                                                    const rbac = core.getProjectInfo().rbac;
                                                    for (const sub in rbac) {
                                                        if (
                                                            rbac[sub].filter?.trades?.includes(msg.id) &&
                                                            rbac[sub].filter?.trades?.length == 1
                                                        ) {
                                                            const projectId = canvas.meta.pid;
                                                            const userId = sub;
                                                            const accessToken = canvas.master_token;
                                                            fetch(
                                                                `${SERVICES.REST_API}/projects/${projectId}/subs/${userId}/role`,
                                                                {
                                                                    method: "POST",
                                                                    headers: {
                                                                        Authorization: `Bearer ${accessToken}`,
                                                                    },
                                                                    body: JSON.stringify({
                                                                        role: "readonly",
                                                                    }),
                                                                },
                                                            );
                                                        }
                                                    }
                                                }
                                                canvas.model.updateTrade(msg);
                                            }
                                            canvas.model.updateModel();
                                            postCanvasMessage();
                                            syncStream(this);
                                        }
                                        break;
                                    case "delete.dialog":
                                        {
                                            const req = event.data[2];
                                            const projectId = req.projectId || canvas.model.storageName;
                                            const m =
                                                canvas.model.canvasWhiteboards.model &&
                                                projectId === canvas.model.canvasWhiteboards.model.storageName
                                                    ? canvas.model.canvasWhiteboards.model
                                                    : projectId === canvas.model.storageName
                                                      ? canvas.model
                                                      : null;
                                            if (m) {
                                                const core = canvas.onUpdateCache.core;
                                                const _Gt = new ParticleGetter(core);
                                                const dialogProps: dialogPropsType = {
                                                    tradeId: req.id,
                                                    trade: null,
                                                    used: false,
                                                };
                                                const trades = core.getTrades();
                                                const trade = trades.find((trade) => req.id === trade.id);
                                                if (trade) {
                                                    // no deleted
                                                    const usedIds = core.gatherUsedTradeParticles();
                                                    dialogProps.trade = trade;
                                                    dialogProps.used = usedIds[req.id].reduce(
                                                        (ret: { processes?: object; unknown?: boolean }, _: number) => {
                                                            ret = ret || {};
                                                            if (_Gt.reset(_)._ >= 0 && _Gt.isProcessTarget) {
                                                                ret.processes = ret.processes || {};
                                                                if (!(_Gt.id in ret.processes)) {
                                                                    ret.processes[_Gt.id] = core.getProcessDetails(
                                                                        _Gt.id,
                                                                    );
                                                                }
                                                            } else {
                                                                ret.unknown = true;
                                                            }
                                                            return ret;
                                                        },
                                                        null,
                                                    );
                                                }
                                                if (req.dialog) {
                                                    canvas.worker.postMessage(["toggle", req.dialog, dialogProps]); // open exception dialog...
                                                }
                                            }
                                        }
                                        break;
                                }
                            }
                            break;
                        case "deleteTasks":
                            {
                                const msg = event.data[1];
                                canvas.model.deleteTasks(msg.tasks);
                                canvas.model.updateModel();
                                postCanvasMessage();
                                syncStream(this);
                            }
                            break;
                        case "cut":
                        case "copy":
                            {
                                const taskIds = event.data[1];
                                const isCut = "cut" === event.data[0];

                                const tasks = taskIds
                                    .map((tid) => canvas.model.processToJSON(tid))
                                    .filter((task) => !!task)
                                    .sort((a, b) => {
                                        if (a.start === b.start) {
                                            return a.y - b.y;
                                        }
                                        return a.start - b.start;
                                    });

                                canvas._clipboard.origin = canvas.model.storageName;
                                canvas._clipboard.content = tasks;

                                postMessage(["clipboardContent", tasks]);

                                if (isCut) {
                                    canvas.model.deleteTasks(taskIds);
                                }

                                canvas.model.updateModel();
                                postCanvasMessage();
                                syncStream(this);
                            }
                            break;
                        case "pastev2":
                            {
                                const paste = event.data[1] as PasteObject;
                                const isDifferentProject = paste.sourceProjectId !== canvas.model.storageName;

                                if (isDifferentProject) {
                                    const existingTrades = canvas.onUpdateCache.core.getTrades();
                                    const allTradeNames: string[] = existingTrades.map((item) => item.name);

                                    createTradesIfRequired(paste, allTradeNames);
                                }

                                if (paste.clipboardContent && paste.clipboardContent.length > 0) {
                                    canvas.model.stackTasksIfNeeded();
                                    let content = paste.clipboardContent;
                                    const targetProjectInfo = {
                                        startDate: canvas.onUpdateCache.core.getLegacyFluxState("startDate"),
                                        endDate: canvas.onUpdateCache.core.getLegacyFluxState("endDate"),
                                    };
                                    if (isDifferentProject) {
                                        content = modifyPastedTasksIfRequired(content, targetProjectInfo);
                                    }
                                    const sentinelPos = {
                                        x1: content.reduce(
                                            (ret, t) => (t.start < ret ? t.start : ret),
                                            content[0].start,
                                        ),
                                        y: content.reduce((ret, t) => (t.y < ret ? t.y : ret), content[0].y),
                                    };

                                    const dx = paste.x - canvas.model.grid.dateToGrid(sentinelPos.x1);
                                    const dy = paste.y - sentinelPos.y;
                                    const oldToNewProcessIdMapCollection: OldToNewProcessIdMap[] = [];

                                    //insert Processes
                                    const { ops, oldToNewProcessIdMap: oldToNew } = canvas.model.insertTasksFromJSON(
                                        paste.clipboardContent,
                                        canvas.model.grid,
                                        dx,
                                        dy,
                                        paste.p,
                                        //cut always triggers process update
                                        paste.action === "copy" || isDifferentProject,
                                    );
                                    oldToNewProcessIdMapCollection.push(oldToNew);

                                    addDependenciesToInsertedProcesses({
                                        ops,
                                        workerContext: this,
                                        pasteToDifferentProject: isDifferentProject,
                                        copiedProcesses: paste.copiedTaskIds,
                                        oldToNewProcessIdMapCollection,
                                    });

                                    if (!isDifferentProject) {
                                        // write the updated clipboard content back to the clipboard (task ids have been removed after cut->paste to copy instead of cut after the first paste)
                                        postMessage(["clipboardContent", paste.clipboardContent]);
                                    }
                                }
                            }
                            break;
                        case "paste":
                            {
                                const paste = event.data[1] as {
                                    x: number;
                                    y: number;
                                    p: {
                                        pid: number;
                                        i_pid: number;
                                        tz_pid: string;
                                    };
                                    clipboardContent: any; // Adding clipboardContent to the paste object
                                };
                                if (paste.clipboardContent && paste.clipboardContent.length > 0) {
                                    canvas.model.stackTasksIfNeeded();
                                    const content = paste.clipboardContent;
                                    const sentinelPos = {
                                        x1: content.reduce(
                                            (ret, t) => (t.start < ret ? t.start : ret),
                                            content[0].start,
                                        ),
                                        y: content.reduce((ret, t) => (t.y < ret ? t.y : ret), content[0].y),
                                    };
                                    const dx = paste.x - canvas.model.grid.dateToGrid(sentinelPos.x1);
                                    const dy = paste.y - sentinelPos.y;
                                    const { ops } = canvas.model.insertTasksFromJSON(
                                        paste.clipboardContent,
                                        canvas.model.grid,
                                        dx,
                                        dy,
                                        paste.p,
                                    );
                                    if (ops.length > 0) {
                                        const _ops = canvas.model.reduceAndFinalizeOps(ops);
                                        canvas.model.pushOperation(_ops);
                                        postCanvasMessage();
                                        syncStream(this);
                                    } else {
                                        postCanvasMessage(); // make sure to send the "forced" updates...
                                    }
                                }
                            }
                            break;
                        case "processview":
                            {
                                const cmd = event.data[1];
                                const opt = event.data[2];
                                switch (cmd) {
                                    case "init":
                                        {
                                            canvas.model.updateModel();
                                            const id = canvas.model.initWBS(opt);
                                            const patch = canvas.model.updateWBS(id, true);
                                            canvas.worker.postMessage(["processview", "init", patch]);
                                        }
                                        break;
                                    case "cleanup":
                                        {
                                            canvas.model.cleanupWBS(opt?.id);
                                        }
                                        break;
                                    case "insert":
                                        {
                                            canvas.model.insertWBSRows(opt?.id, opt.ts, opt.tid, opt.count);
                                            postCanvasMessage();
                                            syncStream(this);
                                        }
                                        break;
                                    case "delete":
                                        {
                                            const ops = [];
                                            canvas.model.deleteWBSRow(ops, opt.id, opt.ts, opt.tids);
                                            if (ops.length > 0) {
                                                ops[ops.length - 1].group = false;
                                                canvas.model.pushOperation(ops);
                                            }
                                            postCanvasMessage();
                                            syncStream(this);
                                        }
                                        break;
                                    case "indent":
                                        {
                                            const ops = [];
                                            canvas.model.indentWBSRow(ops, opt.id, opt.ts, opt.tids);
                                            if (ops.length > 0) {
                                                ops[ops.length - 1].group = false;
                                                canvas.model.pushOperation(ops);
                                            }
                                            postCanvasMessage();
                                            syncStream(this);
                                        }
                                        break;
                                    case "outdent":
                                        {
                                            const ops = [];
                                            canvas.model.outdentWBSRow(ops, opt.id, opt.ts, opt.tids);
                                            if (ops.length > 0) {
                                                ops[ops.length - 1].group = false;
                                                canvas.model.pushOperation(ops);
                                            }
                                            postCanvasMessage();
                                            syncStream(this);
                                        }
                                        break;
                                    case "rename":
                                        {
                                            canvas.model.renameWBSRow(opt?.id, opt.ts, opt.tid, opt.v);
                                            postCanvasMessage();
                                            syncStream(this);
                                        }
                                        break;
                                    case "append":
                                        {
                                            canvas.model.appendWBSRow(opt?.id, opt.ts, opt.v);
                                            postCanvasMessage();
                                            syncStream(this);
                                        }
                                        break;
                                    case "collapse":
                                        {
                                            canvas.model.collapse(opt?.id, opt?.ts, opt.tid, opt.v);
                                            const patch = canvas.model.updateWBS(opt?.id);
                                            canvas.worker.postMessage(["processview", "update", patch]);
                                        }
                                        break;
                                    case "selchg":
                                        if (opt) {
                                            canvas.model.selectWBSTIDs(opt.id, opt.ts, opt.tids);
                                            const patch = canvas.model.updateWBS(opt?.id);
                                            canvas.worker.postMessage(["processview", "update", patch]);
                                        }
                                        break;
                                    case "copy":
                                        if (opt) {
                                            const ops = [];
                                            canvas.model.copyWBS(ops, opt.id, opt.ts, opt.tids, opt.cut);
                                            if (ops.length > 0) {
                                                ops[ops.length - 1].group = false;
                                                canvas.model.pushOperation(ops);
                                            }
                                            canvas._clipboard.action = opt.cut ? "cut" : "copy";
                                            postCanvasMessage();
                                            syncStream(this);
                                        }
                                        break;
                                    case "paste":
                                        if (opt) {
                                            const { ops, oldToNewProcessIdMapCollection } = canvas.model.pasteWBS(
                                                opt.id,
                                                opt.ts,
                                                opt.tid,
                                                canvas._clipboard.action === "copy",
                                            );
                                            if (ops.length > 0) {
                                                addDependenciesToInsertedProcesses({
                                                    ops,
                                                    oldToNewProcessIdMapCollection,
                                                    workerContext: this,
                                                    pasteToDifferentProject: false,
                                                });
                                            }
                                        }
                                        break;
                                    case "move":
                                        if (opt) {
                                            const ops: DataOperation[] = [];
                                            canvas.model.moveWBS(ops, opt.id, opt.ts, opt.tid, opt.moveToId);
                                            if (ops.length > 0) {
                                                assert(
                                                    "development" !== process.env.NODE_ENV ||
                                                        ops.reduce((ret, op) => ret && true === op.group, true),
                                                );
                                                ops[ops.length - 1].group = false;
                                                canvas.model.pushOperation(ops);
                                            }
                                            postCanvasMessage();
                                            syncStream(this);
                                        }
                                        break;
                                    case "clone":
                                        if (opt) {
                                            const { ops, oldToNewProcessIdMapCollection } = canvas.model.cloneWBS(
                                                opt.id,
                                                opt.ts,
                                                opt.taktZoneId,
                                                opt.opt,
                                            );

                                            if (ops.length > 0) {
                                                addDependenciesToInsertedProcesses({
                                                    ops,
                                                    oldToNewProcessIdMapCollection,
                                                    workerContext: this,
                                                    pasteToDifferentProject: false,
                                                });
                                            }
                                        }
                                        break;
                                    case "drag":
                                        if (opt) {
                                            const _ops: DataOperation[] = [];
                                            canvas.model.copyWBS(_ops, opt.id, opt.ts, opt.tids, true);
                                            if (null === opt.tid) {
                                                if (_ops.length > 0) {
                                                    const n = _ops.length;
                                                    for (let i = 0; i < n; i++) {
                                                        assert("p" === _ops[i].name && -1 === _ops[i].value);
                                                        _ops[i].p_scratch = { x: opt.x, y: opt.y };
                                                    }
                                                    _ops[_ops.length - 1].group = false;
                                                    canvas.model.pushOperation(_ops);
                                                }
                                            } else {
                                                assert(opt.ts === canvas.model.canvasWBS.ts);
                                                canvas.model.canvasWBS.ts--; // HACK
                                                const { ops } = canvas.model.pasteWBS(opt.id, opt.ts, opt.tid, false);
                                                if (ops.length > 0) {
                                                    const _ops = canvas.model.reduceAndFinalizeOps(ops);
                                                    if (_ops.length > 0) {
                                                        canvas.model.pushOperation(_ops);
                                                    }
                                                }
                                            }
                                            postCanvasMessage();
                                            syncStream(this);
                                        }
                                }
                            }
                            break;
                        case "tasksview":
                            {
                                //workerHandleTaskView(canvas.worker, event);
                            }
                            break;
                        case "process":
                            {
                                if (workerHandleProcess(canvas, event)) {
                                    postCanvasMessage();
                                    syncStream(this);
                                }
                            }
                            break;
                        case "view":
                            {
                                const cmd = event.data[1];
                                const opt = event.data[2];
                                switch (cmd) {
                                    case "select":
                                        if (
                                            canvas.model &&
                                            "number" == typeof opt &&
                                            0 <= opt &&
                                            opt < CONST.views.length
                                        ) {
                                            canvas.model.selectView(opt);
                                            postCanvasMessage();
                                        }
                                        break;
                                    case "status":
                                        if (canvas.model) {
                                            const view = opt.view;

                                            if (!canvas.onUpdateCache.baseline && view === "baseline") {
                                                const activeBaseline = getActiveBaseline(canvas.onUpdateCache.core);
                                                if (activeBaseline) {
                                                    enableBaseline(activeBaseline);
                                                }
                                            }

                                            if (view) {
                                                canvas.model.selectView(undefined, "standard" === view ? null : view);
                                                canvas.model.showBaseline(view === "baseline");
                                            }

                                            if (
                                                true === opt.options?.taktZoneImages ||
                                                false === opt.options?.taktZoneImages
                                            ) {
                                                canvas.model.selectView(undefined, undefined, {
                                                    sidebarColImage: opt.options.taktZoneImages
                                                        ? CONST.sidebarColImage
                                                        : 0,
                                                });
                                            }
                                            if (
                                                true === opt.options?.showProjectWeeks ||
                                                false === opt.options?.showProjectWeeks
                                            ) {
                                                canvas.model.selectView(undefined, undefined, {
                                                    showProjectWeeks: opt.options.showProjectWeeks,
                                                });
                                            }
                                            if (
                                                true === opt.options?.showTaktzones ||
                                                false === opt.options?.showTaktzones
                                            ) {
                                                canvas.model.selectView(undefined, undefined, {
                                                    showTaktzones: opt.options.showTaktzones,
                                                });
                                            }
                                            if (
                                                true === opt.options?.stackProcesses ||
                                                false === opt.options?.stackProcesses
                                            ) {
                                                canvas.model.selectView(undefined, undefined, {
                                                    stackProcesses: opt.options.stackProcesses,
                                                });
                                            }
                                            if (
                                                true === opt.options?.showStatusBar ||
                                                false === opt.options?.showStatusBar
                                            ) {
                                                canvas.model.selectView(undefined, undefined, {
                                                    showStatusBar: opt.options.showStatusBar,
                                                });
                                            }
                                            postCanvasMessage();
                                        }
                                        break;
                                    case "grid":
                                        if (
                                            canvas.model &&
                                            "number" == typeof opt &&
                                            opt >= 1 &&
                                            canvas.model?.canvasWhiteboards?.model
                                        ) {
                                            const weekDaysNumber =
                                                this._evt.core
                                                    .getLegacyFluxState("calendar.meta")
                                                    .wd.filter((day) => day).length || 5;
                                            canvas.model.canvasWhiteboards.model.selectView(undefined, undefined, {
                                                grid: opt,
                                                weekDaysNumber,
                                            });
                                            postCanvasMessage();
                                        }
                                        break;
                                }
                            }
                            break;
                        case "filter":
                            {
                                // moved to worker
                                assert_dev(false); // moved to lcmd2ssf
                            }
                            break;
                        case "calendar":
                            {
                                const numberOfDays: number = event.data[1];
                                canvas.model.updateGridBasedOnWorkDays(7 === numberOfDays);
                                postCanvasMessage();
                            }
                            break;
                        case "sub":
                            {
                                const cmd = event.data[1];
                                const opt = event.data[2];
                                switch (cmd) {
                                    case "add":
                                        {
                                            const ret = await new Promise(
                                                (resolve: (ret: any) => void, reject: (reason: any) => void) => {
                                                    registerSub(
                                                        opt.auth_token,
                                                        (error, result) => {
                                                            if (!error && result?.sub) {
                                                                updateDB(
                                                                    canvas.master_token,
                                                                    { [result.sub]: 1 },
                                                                    (error, result2) => {
                                                                        if (
                                                                            !error &&
                                                                            (result2?.subs || {})[result.sub]
                                                                        ) {
                                                                            canvas.worker.postMessage([
                                                                                "cb",
                                                                                opt.cb,
                                                                                result,
                                                                            ]);
                                                                        } else {
                                                                            canvas.worker.postMessage([
                                                                                "cb",
                                                                                opt.cb,
                                                                                null,
                                                                            ]);
                                                                        }
                                                                    },
                                                                );
                                                            } else {
                                                                canvas.worker.postMessage(["cb", opt.cb, null]);
                                                            }
                                                        },
                                                        opt.email,
                                                    );
                                                },
                                            );
                                        }
                                        break;
                                    case "remove":
                                        {
                                            if (opt?.sub) {
                                                await new Promise(
                                                    (resolve: (ret: any) => void, reject: (reason: any) => void) => {
                                                        updateDB(
                                                            canvas.master_token,
                                                            { [opt.sub]: 0 },
                                                            (error, result2) => {
                                                                if (!error && result2?.subs) {
                                                                    canvas.worker.postMessage(["cb", opt.cb, result2]);
                                                                } else {
                                                                    canvas.worker.postMessage(["cb", opt.cb, null]);
                                                                }
                                                            },
                                                        );
                                                    },
                                                );
                                            }
                                        }
                                        break;
                                    case "filter":
                                        {
                                            if (opt?.sub) {
                                                if (Array.isArray(opt?.trades) && opt.trades.length > 0) {
                                                    canvas.model.pushOperation({
                                                        op: DataOperationType.UPDATE,
                                                        target: DataOperationTarget.HIVE,
                                                        id: -1,
                                                        z: 0,
                                                        _u: canvas.model.userName,
                                                        group: false,
                                                        name: ["#lcmd#ext#com.lcmdigital.rbac"].join(""),
                                                        value: {
                                                            t3: true,
                                                            opt,
                                                        },
                                                    });
                                                } else {
                                                    // remove filter
                                                    canvas.model.pushOperation({
                                                        op: DataOperationType.UPDATE,
                                                        target: DataOperationTarget.HIVE,
                                                        id: -1,
                                                        z: 0,
                                                        _u: canvas.model.userName,
                                                        group: false,
                                                        name: ["#lcmd#ext#com.lcmdigital.rbac"].join(""),
                                                        value: {
                                                            t3: true,
                                                            opt: {
                                                                sub: opt.sub,
                                                            },
                                                        },
                                                    });
                                                }
                                                postCanvasMessage();
                                                syncStream(this);
                                            }
                                        }
                                        break;
                                }
                            }
                            break;
                        case "attachment":
                            {
                                const ops = [];
                                workerHandleAttachments(
                                    ops,
                                    canvas.model,
                                    {
                                        postMessage: canvas.worker.postMessage,
                                        master_token: canvas.master_token,
                                        onUpload: (ops, blobId) => {
                                            if (Array.isArray(ops) && ops.length > 0) {
                                                ops[ops.length - 1].group = false;
                                                canvas.model.pushOperation(ops);
                                                postCanvasMessage();
                                            }
                                            if ("number" === typeof event.data[2]?.cb) {
                                                canvas.worker.postMessage(["cb", event.data[2].cb, { blobId }]);
                                            }
                                            if (Array.isArray(ops) && ops.length > 0) {
                                                syncStream(this);
                                            }
                                        },
                                    },
                                    event,
                                );
                                if (ops.length > 0) {
                                    ops[ops.length - 1].group = false;
                                    canvas.model.pushOperation(ops);
                                    postCanvasMessage();
                                    syncStream(this);
                                }
                            }
                            break;
                        case "lcmdx":
                            {
                                const ext = event.data[1];
                                if (canvas.model) {
                                    canvas.model.pushLCMX(ext);
                                    postCanvasMessage();
                                    syncStream(this);
                                }
                            }
                            break;
                        case "project":
                            {
                                const cmd = event.data[1];
                                const opt = event.data[2];
                                switch (cmd) {
                                    case "info":
                                        {
                                            const master_token = unsafeParseJWT(canvas.master_token);
                                            const data = canvas?.model ? canvas.model.getProjectInfo() : null;
                                            if (opt.cb) {
                                                canvas.worker.postMessage([
                                                    "cb",
                                                    opt.cb,
                                                    { ...data, meta: canvas.meta, pid: master_token?.pid },
                                                ]);
                                            }
                                        }
                                        break;
                                    case "props":
                                        {
                                            const master_token = unsafeParseJWT(canvas.master_token);
                                            if ("string" === typeof opt?.name && opt.name !== canvas.meta.name) {
                                                const ret = await new Promise(
                                                    (
                                                        resolve: (ret: {
                                                            error: FrameworkHttpError;
                                                            result: any;
                                                        }) => void,
                                                        reject: (reason: any) => void,
                                                    ) => {
                                                        setProjectProps(
                                                            canvas.master_token,
                                                            master_token?.pid,
                                                            {
                                                                name: opt.name,
                                                            },
                                                            (error, result) => {
                                                                resolve({ error, result });
                                                            },
                                                        );
                                                    },
                                                );
                                                if (ret.error) {
                                                    console.error(ret.error);
                                                } else {
                                                    canvas.meta.dirty = true;
                                                    canvas.meta.name = ret.result.project.name;
                                                }
                                                postCanvasMessage();
                                            }
                                            const _x1 = opt?.startDate || 0;
                                            const _x2 = opt?.endDate || 0;
                                            if (
                                                _x1 > MAX_TASK_DAYS &&
                                                _x1 < _x2 &&
                                                canvas?.model &&
                                                (_x1 !== canvas.model.tasks[0]._x1 || _x2 !== canvas.model.tasks[0]._x2)
                                            ) {
                                                const cd = DataModel.CalcEllapsedDays(_x1, _x2);
                                                if (cd > 0) {
                                                    const taskId = 0;
                                                    const _ops: DataOperation[] = [];
                                                    _ops.push({
                                                        op: DataOperationType.UPDATE,
                                                        target: DataOperationTarget.TASKS,
                                                        id: taskId,
                                                        name: "start",
                                                        value: _x1,
                                                        z: 0,
                                                        _u: canvas.model.userName,
                                                        group: true,
                                                    });
                                                    _ops.push({
                                                        op: DataOperationType.UPDATE,
                                                        target: DataOperationTarget.TASKS,
                                                        id: taskId,
                                                        name: "days",
                                                        value: cd,
                                                        unit: 10, // ellaped days
                                                        z: 0,
                                                        _u: canvas.model.userName,
                                                        group: false,
                                                    });
                                                    canvas.model.pushOperation(_ops);
                                                    postCanvasMessage();
                                                    syncStream(this);
                                                }
                                            }
                                            const data = canvas?.model ? canvas.model.getProjectInfo() : null;
                                            if (opt.cb) {
                                                canvas.worker.postMessage([
                                                    "cb",
                                                    opt.cb,
                                                    { ...data, meta: canvas.meta, pid: master_token?.pid },
                                                ]);
                                            }
                                        }
                                        break;
                                    case "safeIDs":
                                        {
                                            if (canvas.model && opt.cb && opt.ids) {
                                                let ret = null;
                                                let err = null;
                                                try {
                                                    ret = canvas.model.getSafeIDs(opt.ids);
                                                } catch (e) {
                                                    err = errorToJSON(e);
                                                }
                                                canvas.worker.postMessage(["cb", opt.cb, { ret, err }]);
                                            }
                                        }
                                        break;
                                    case "unsafeIDs":
                                        {
                                            if (canvas.model && opt.cb && opt.safeIDs) {
                                                let ret = null;
                                                let err = null;
                                                try {
                                                    ret = canvas.model.getUnsafeIDs(opt.safeIDs);
                                                } catch (e) {
                                                    err = errorToJSON(e);
                                                }
                                                canvas.worker.postMessage(["cb", opt.cb, { ret, err }]);
                                            }
                                        }
                                        break;
                                    case "clone_pre":
                                        {
                                            try {
                                                const ret = await new Promise(
                                                    (resolve: (ret: any) => void, reject: (reason: any) => void) => {
                                                        assert(opt.sid === canvas.model.storageName);
                                                        const _auth_token = unsafeParseJWT(opt.auth_token);
                                                        const sub = _auth_token.sub;
                                                        assert(sub);
                                                        const ofs = canvas.model.syncCommitedTS().ofs;
                                                        const activeSyncOfs = canvas.model.canvasWhiteboards.model
                                                            ? canvas.model.canvasWhiteboards.model.syncCommitedTS().ofs
                                                            : -1;
                                                        const activeOfs = canvas.model.canvasWhiteboards.model
                                                            ? canvas.model.canvasWhiteboards.model.commitTS()
                                                            : -1;
                                                        if (
                                                            canvas.model.commitTS() === ofs &&
                                                            activeSyncOfs === activeOfs
                                                        ) {
                                                            const clone = {
                                                                sid: canvas.model.storageName,
                                                                ofs,
                                                            };
                                                            const wbIds = [];
                                                            {
                                                                // mark whiteboards
                                                                const wbs = canvas.model.hive?.lcmd?.wb || {};
                                                                const wbIds5 = Object.getOwnPropertyNames(wbs);
                                                                const n_wbIds5 = wbIds5.length;
                                                                for (let i_wbId = 0; i_wbId < n_wbIds5; i_wbId++) {
                                                                    const wbId5 = wbIds5[i_wbId];
                                                                    if (
                                                                        27 === wbId5.length &&
                                                                        "U" === wbId5[0] &&
                                                                        "string" ===
                                                                            typeof canvas.model.VALUE<string>(
                                                                                wbs[wbId5].name,
                                                                                null,
                                                                            )
                                                                    ) {
                                                                        //const wb=wbs[wbId5];
                                                                        const id = UUID5.fromUUID5(
                                                                            wbId5.substring(1).toUpperCase(),
                                                                        ).toUUID();
                                                                        wbIds.push(id);
                                                                    }
                                                                }
                                                            }
                                                            fetch_ts(
                                                                opt.auth_token,
                                                                wbIds,
                                                                (error, fetch_ts_result) => {
                                                                    if (error) {
                                                                        resolve({
                                                                            error: errorToJSON(error),
                                                                        });
                                                                    } else {
                                                                        const master_ops: DataOperation[] = [
                                                                            {
                                                                                op: 0 /*DataOperationType.NOOP*/,
                                                                                cmd: "clone",
                                                                                clone: clone,
                                                                                _u: sub,
                                                                                group: true,
                                                                            } as any,
                                                                        ];
                                                                        for (
                                                                            let i_wbId = 0;
                                                                            i_wbId < wbIds.length;
                                                                            i_wbId++
                                                                        ) {
                                                                            const wbId = wbIds[i_wbId];
                                                                            const ts = fetch_ts_result[wbId];
                                                                            assert("number" === typeof ts && ts >= 0);
                                                                            const _id =
                                                                                "U" +
                                                                                UUID5.fromUUID(wbId)
                                                                                    .toUUID5()
                                                                                    .toLowerCase();
                                                                            master_ops.push({
                                                                                op: DataOperationType.UPDATE,
                                                                                target: DataOperationTarget.HIVE,
                                                                                id: -1,
                                                                                name: [
                                                                                    "#lcmd",
                                                                                    "wb",
                                                                                    _id,
                                                                                    "clone",
                                                                                ].join("#"),
                                                                                value: ts,
                                                                                z: 0,
                                                                                _u: sub,
                                                                                group: true,
                                                                            });
                                                                        }
                                                                        master_ops[master_ops.length - 1].group = false;
                                                                        resolve({
                                                                            result: {
                                                                                auth_token: opt.auth_token,
                                                                                clone,
                                                                                ops: master_ops,
                                                                            },
                                                                        });
                                                                    }
                                                                },
                                                            );
                                                        } else {
                                                            resolve({
                                                                error: errorToJSON(new FrameworkError("fw.needsSync")),
                                                            });
                                                        }
                                                    },
                                                );
                                                canvas.worker.postMessage(["cb", opt.cb, ret]);
                                            } catch (e) {
                                                canvas.worker.postMessage([
                                                    "cb",
                                                    opt.cb,
                                                    {
                                                        error: errorToJSON(e),
                                                    },
                                                ]);
                                            }
                                        }
                                        break;
                                    case "clone":
                                        {
                                            try {
                                                const ret = await new Promise(
                                                    (resolve: (ret: any) => void, reject: (reason: any) => void) => {
                                                        const clone = opt.clone;
                                                        assert(clone.sid === canvas.model.storageName);
                                                        const _auth_token = unsafeParseJWT(opt.auth_token);
                                                        const sub = _auth_token.sub;
                                                        assert(sub);
                                                        const master_ops = opt.ops;
                                                        assert(Array.isArray(master_ops));
                                                        if (canvas.model.commitTS() === clone.ofs) {
                                                            const _file = {
                                                                id: "00000000-0000-0000-0000-000000000000",
                                                                type: "application/vnd.lcmdigital.clone",
                                                                clone: clone,
                                                            };
                                                            const _commit = {
                                                                name: opt?.details?.name || undefined,
                                                                message: opt?.details?.message || undefined,
                                                            };
                                                            createProject(
                                                                opt.auth_token,
                                                                (error, create_project_result) => {
                                                                    if (error) {
                                                                        resolve({
                                                                            error: errorToJSON(error),
                                                                        });
                                                                    } else {
                                                                        assert(create_project_result?.project_token);

                                                                        create_master(
                                                                            master_ops,
                                                                            (error, create_master_result) => {
                                                                                if (error) {
                                                                                    resolve({
                                                                                        error: errorToJSON(error),
                                                                                    });
                                                                                } else {
                                                                                    assert(create_master_result?.token);
                                                                                    set_master_sandbox(
                                                                                        create_master_result.token,
                                                                                        (error, result) => {
                                                                                            if (error) {
                                                                                                resolve({
                                                                                                    error: errorToJSON(
                                                                                                        error,
                                                                                                    ),
                                                                                                });
                                                                                            } else {
                                                                                                resolve({
                                                                                                    result: create_project_result,
                                                                                                });
                                                                                            }
                                                                                        },
                                                                                    );
                                                                                }
                                                                            },
                                                                            {
                                                                                resource_token:
                                                                                    create_project_result.project_token,
                                                                                clone: clone,
                                                                            },
                                                                        );
                                                                    }
                                                                },
                                                                _file,
                                                                _commit,
                                                            );
                                                        } else {
                                                            resolve({
                                                                error: errorToJSON(new FrameworkError("fw.needsSync")),
                                                            });
                                                        }
                                                    },
                                                );
                                                canvas.worker.postMessage(["cb", opt.cb, ret]);
                                            } catch (e) {
                                                canvas.worker.postMessage([
                                                    "cb",
                                                    opt.cb,
                                                    {
                                                        error: errorToJSON(e),
                                                    },
                                                ]);
                                            }
                                        }
                                        break;
                                }
                            }
                            break;
                        case "procore":
                            {
                                const cmd = event.data[1];
                                const opt = event.data[2];
                                switch (cmd) {
                                    case "edit":
                                        {
                                            if (canvas.procore) {
                                                canvas.worker.postMessage([
                                                    "worker",
                                                    "progress",
                                                    {
                                                        show: 1,
                                                    },
                                                ]);
                                                const ret = await new Promise(
                                                    (resolve: (ret: any) => void, reject: (reason: any) => void) => {
                                                        procore_fetch_schedule(
                                                            canvas.procore.auth.access_token,
                                                            canvas.procore.project_id,
                                                            canvas.procore.company_id,
                                                            (error, result, contentType) => {
                                                                if (error) {
                                                                    resolve(null);
                                                                } else {
                                                                    uploadArrayBuffer(
                                                                        result,
                                                                        (error, done) => {
                                                                            if (error) {
                                                                                resolve(false);
                                                                            } else {
                                                                                resolve(done);
                                                                            }
                                                                        },
                                                                        null,
                                                                        {
                                                                            procore: {
                                                                                project_id: canvas.procore.project_id,
                                                                                company_id: canvas.procore.company_id,
                                                                            },
                                                                        },
                                                                    );
                                                                }
                                                            },
                                                        );
                                                    },
                                                );
                                                if (ret?.resource_token) {
                                                    canvas.worker.postMessage([
                                                        "worker",
                                                        "progress",
                                                        {
                                                            show: 0,
                                                        },
                                                    ]);
                                                    canvas.worker.postMessage([
                                                        "export",
                                                        {
                                                            procore: {
                                                                ...canvas.procore,
                                                                resource_token: ret.resource_token,
                                                                href:
                                                                    "/?resource_token=" +
                                                                    encodeURIComponent(ret.resource_token),
                                                                target: "_blank",
                                                            },
                                                        },
                                                    ]);
                                                } else {
                                                    // error
                                                    canvas.worker.postMessage([
                                                        "worker",
                                                        "progress",
                                                        {
                                                            show: 0,
                                                            error: {
                                                                message: "Error",
                                                                meta: {},
                                                            },
                                                        },
                                                    ]);
                                                }
                                            }
                                        }
                                        break;
                                }
                            }
                            break;
                        case "stats":
                            {
                                const msg = event.data;
                                switch (msg[1]) {
                                    case "view":
                                        {
                                            //console.log(JSON.stringify(msg));
                                            const name = msg[2]?.name;
                                            if (name && canvas.stats) {
                                                if (canvas.stats[name]) {
                                                    if (msg[2].scale) {
                                                        canvas.stats[name].state.view = msg[2];
                                                    } else {
                                                        canvas.stats[name] = null;
                                                    }
                                                } else if (msg[2].scale) {
                                                    initDataView(msg[2]);
                                                    canvas.stats[name].state.view = msg[2];
                                                }
                                                postCanvasMessage();
                                            }
                                        }
                                        break;
                                    case "update":
                                        {
                                            const opt = msg[2];
                                            const stats = canvas?.stats || {};
                                            Object.getOwnPropertyNames(stats).forEach((name) => {
                                                const stat = stats[name];
                                                if (stat?.state) {
                                                    stat.state.commit_ts = 0;
                                                }
                                            });

                                            if (opt?.cb) {
                                                canvas.worker.postMessage(["cb", opt.cb]);
                                            }
                                            postCanvasMessage();
                                        }
                                        break;
                                    default:
                                        break;
                                }
                            }
                            break;
                        case "lib":
                            {
                                const msg = event.data;
                                switch (msg[1]) {
                                    case "add":
                                        {
                                            const items = msg[2] as LCMDContextLibraryItemTarget[];
                                            if (canvas.model.canvasWhiteboards.model) {
                                                const m = canvas.model.canvasWhiteboards.model;
                                                const ctx = {
                                                    maxIds: m.maxIdsCopy(),
                                                };
                                                const ops: DataOperation[] = [];
                                                const n_items = items.length;
                                                for (let i_item = 0; i_item < n_items; i_item++) {
                                                    const item = items[i_item];
                                                    let tid = item.id;
                                                    if (!("number" === typeof tid && tid >= 0)) {
                                                        tid = ++ctx.maxIds[DataOperationTarget.TASKS];
                                                        const c = m.tasks[0]._c || [];
                                                        ops.push({
                                                            op: DataOperationType.CREATE,
                                                            target: DataOperationTarget.TASKS,
                                                            id: tid,
                                                            name: "p",
                                                            value: 0,
                                                            i: c.length,
                                                            c: 1,
                                                            z: 0,
                                                            _u: m.userName,
                                                            group: true,
                                                        });
                                                    }
                                                    if (-1 === item.p) {
                                                        ops.push({
                                                            op: DataOperationType.UPDATE,
                                                            target: DataOperationTarget.TASKS,
                                                            id: tid,
                                                            name: "p",
                                                            value: item.p,
                                                            z: 0,
                                                            _u: m.userName,
                                                            group: true,
                                                        });
                                                    }
                                                    if (item.name) {
                                                        ops.push({
                                                            op: DataOperationType.UPDATE,
                                                            target: DataOperationTarget.TASKS,
                                                            id: tid,
                                                            name: "name",
                                                            value: item.name,
                                                            z: 0,
                                                            _u: m.userName,
                                                            group: true,
                                                        });
                                                    }
                                                }
                                                if (ops.length > 0) {
                                                    ops[ops.length - 1].group = false;
                                                    m.pushOperation(ops);
                                                }
                                            }
                                            postCanvasMessage();
                                            syncStream(this);
                                        }
                                        break;
                                }
                            }
                            break;
                        case "baseline":
                            {
                                const msg = event.data;
                                switch (msg[1]) {
                                    case "ids":
                                        {
                                            const opt = msg[2];
                                            const data = canvas?.model ? canvas.model.gatherBaselines() : [];
                                            if (opt.cb) {
                                                canvas.worker.postMessage([
                                                    "cb",
                                                    opt.cb,
                                                    {
                                                        revId: canvas.model.baseline
                                                            ? canvas.model.baseline.commitTS()
                                                            : -1,
                                                        data,
                                                    },
                                                ]);
                                            }
                                        }
                                        break;
                                    case "set":
                                        {
                                            const opt = msg[2];
                                            enableBaseline(opt?.revId);
                                            postCanvasMessage();
                                        }
                                        break;
                                    case "toggle":
                                        canvas.model.showBaseline(msg[2]);
                                        postCanvasMessage();
                                        break;
                                    case "details": {
                                        const opt = msg[2];
                                        const data = this._evt.core.getBaseline()?.getProcessDetails(opt.taskId);

                                        if (opt.cb) {
                                            canvas.worker.postMessage(["cb", opt.cb, data]);
                                        }
                                        break;
                                    }
                                }
                            }
                            break;
                        case "wb":
                            {
                                try {
                                    const cmd = event.data[1];
                                    const opt = event.data[2];
                                    switch (cmd) {
                                        case "canvas":
                                            {
                                                postCanvasMessage(true);
                                                const viewState = viewStateHack[opt.storageName] || {
                                                    storageName: opt.storageName,
                                                };
                                                canvas.worker.postMessage(["framework", "loaded", viewState]);
                                            }
                                            break;
                                        case "unmount":
                                            {
                                                viewStateHack[opt.storageName] = opt;
                                            }
                                            break;
                                        case "create":
                                            {
                                                if (canvas?.model) {
                                                    const ops0 = canvas.model.ops.length;
                                                    const ret = await canvas.model.createWhiteboard(
                                                        canvas.master_token,
                                                        {},
                                                    );
                                                    if (ops0 < canvas.model.ops.length && ret?.id) {
                                                        const ops: DataOperation[] = [];
                                                        canvas.model._setWhiteboardState(ops, ret.id, opt);
                                                        if (ops.length > 0) {
                                                            assert(
                                                                true !==
                                                                    canvas.model.ops[canvas.model.ops.length - 1].group,
                                                            );
                                                            canvas.model.ops[canvas.model.ops.length - 1].group = true;
                                                            ops[ops.length - 1].group = false;
                                                            canvas.model._pushOperation(ops);
                                                        }
                                                    }
                                                    if (ret?.id && opt?.open) {
                                                        await canvas.model._setSubStorageActive(
                                                            "wb",
                                                            canvas.model.canvasWhiteboards,
                                                            ret.id,
                                                            canvas.master_token,
                                                            canvas.meta.readonly,
                                                        );
                                                    }
                                                    if (
                                                        canvas.model.canvasWhiteboards.rt &&
                                                        canvas.model.canvasWhiteboards.model?.userName
                                                    ) {
                                                        connectRT(
                                                            this,
                                                            "wb",
                                                            canvas.model.canvasWhiteboards.model,
                                                            canvas.model.canvasWhiteboards.rt,
                                                            canvas.model.canvasWhiteboards.model.storageName,
                                                            canvas.model.canvasWhiteboards.model.userName,
                                                            canvas.sandbox_token,
                                                            canvas.warmupId,
                                                        );
                                                    }
                                                    postCanvasMessage();
                                                    syncStream(this);
                                                }
                                            }
                                            break;
                                        case "open":
                                            {
                                                if (
                                                    canvas?.model &&
                                                    canvas.model.canvasWhiteboards.activeId !== opt.id
                                                ) {
                                                    const id5 = "U" + UUID5.fromUUID(opt.id).toUUID5().toLowerCase();
                                                    const wb = (canvas.model.hive?.lcmd?.wb || {})[id5];
                                                    assert(wb);
                                                    const cloneTS = canvas.model.VALUE<number>(wb.clone, -1);
                                                    if (cloneTS >= 0) {
                                                        // force clone
                                                        await canvas.model._cloneAndSetSubstorageActive(
                                                            "wb",
                                                            {
                                                                sid: opt.id,
                                                                ofs: cloneTS,
                                                            },
                                                            canvas.model.canvasWhiteboards,
                                                            canvas.master_token,
                                                            canvas.meta.readonly,
                                                        );
                                                    } else {
                                                        const ret = await canvas.model._setSubStorageActive(
                                                            "wb",
                                                            canvas.model.canvasWhiteboards,
                                                            opt.id,
                                                            canvas.master_token,
                                                            canvas.meta.readonly,
                                                        );
                                                        if (ret instanceof FrameworkHttpError) {
                                                            if (
                                                                "number" ===
                                                                    typeof (ret as FrameworkHttpError)?.details
                                                                        ?.invalidPID &&
                                                                ret.details.invalidPID >= 0
                                                            ) {
                                                                // fix a legacy problem => clone
                                                                await canvas.model._cloneAndSetSubstorageActive(
                                                                    "wb",
                                                                    {
                                                                        sid: opt.id,
                                                                        ofs: ret.details.invalidPID,
                                                                    },
                                                                    canvas.model.canvasWhiteboards,
                                                                    canvas.master_token,
                                                                    canvas.meta.readonly,
                                                                );
                                                            } else {
                                                                console.error(ret);
                                                                const closedMsg = {
                                                                    mode: "wb",
                                                                    storageName: opt.id,
                                                                    error: true,
                                                                };
                                                                canvas.worker.postMessage([
                                                                    "framework",
                                                                    "closed",
                                                                    closedMsg,
                                                                ]);
                                                            }
                                                        }
                                                    }
                                                    if (
                                                        canvas.model.canvasWhiteboards.rt &&
                                                        canvas.model.canvasWhiteboards.model?.userName
                                                    ) {
                                                        connectRT(
                                                            this,
                                                            "wb",
                                                            canvas.model.canvasWhiteboards.model,
                                                            canvas.model.canvasWhiteboards.rt,
                                                            canvas.model.canvasWhiteboards.model.storageName,
                                                            canvas.model.canvasWhiteboards.model.userName,
                                                            canvas.sandbox_token,
                                                            canvas.warmupId,
                                                        );
                                                    }
                                                    postCanvasMessage();
                                                    syncStream(this);
                                                }
                                            }
                                            break;
                                        case "import":
                                            {
                                                if (
                                                    canvas.model &&
                                                    !canvas.model?.canvasWhiteboards?.model &&
                                                    Array.isArray(opt?.files)
                                                ) {
                                                    for (let i_file = 0; i_file < opt.files.length; i_file++) {
                                                        const file = opt.files[i_file];
                                                        try {
                                                            let _ops = JSON.parse(
                                                                Buffer.from(file.buffer).toString("utf-8"),
                                                            );
                                                            if (_ops && Array.isArray(_ops["lcmd.raw.ops"])) {
                                                                _ops = _ops["lcmd.raw.ops"];
                                                            }
                                                            assert(Array.isArray(_ops));
                                                            const m = DataModel.loadOps(_ops, false, {
                                                                whiteboard: true,
                                                            });
                                                            _ops = null; // allow Gc
                                                            //@TODO fix m
                                                            m.updateModel();
                                                            const ops = m.getRawOps();
                                                            const ret = await canvas.model.createWhiteboard(
                                                                canvas.master_token,
                                                                {
                                                                    name: file.name || "Unnamed Import",
                                                                },
                                                                undefined,
                                                                ops,
                                                            );
                                                            console.log("TODO " + opt.files.length);
                                                        } catch (e) {
                                                            console.error(e);
                                                        }
                                                    }
                                                    postCanvasMessage();
                                                    syncStream(this);
                                                }
                                            }
                                            break;
                                        case "close":
                                            {
                                                if (
                                                    canvas?.model &&
                                                    canvas.model.canvasWhiteboards.activeId === opt.active
                                                ) {
                                                    if (true === opt.delete) {
                                                        const type = "wb";
                                                        const wbId = [
                                                            "U",
                                                            UUID5.fromUUID(opt.active).toUUID5().toLowerCase(),
                                                        ].join("");
                                                        canvas.model.pushOperation({
                                                            op: DataOperationType.UPDATE,
                                                            target: DataOperationTarget.HIVE,
                                                            id: -1,
                                                            z: 0,
                                                            _u: canvas.model.userName,
                                                            group: false,
                                                            name: ["#lcmd#", type, "#", wbId, "#name"].join(""),
                                                            value: null, // delete
                                                        });
                                                    }
                                                    syncStream(this); // flush pending
                                                    const commitTS = canvas.model.canvasWhiteboards?.model
                                                        ? canvas.model.canvasWhiteboards.model.commitTS()
                                                        : 0;
                                                    const syncCommitedTS = canvas.model.canvasWhiteboards?.model
                                                        ? canvas.model.canvasWhiteboards.model.syncCommitedTS().ofs
                                                        : 0;
                                                    if (commitTS === syncCommitedTS) {
                                                        // no outstanding ops...
                                                        const closedMsg = {
                                                            mode: "wb",
                                                            storageName: canvas.model.canvasWhiteboards.activeId,
                                                        };
                                                        canvas.model._setSubStorageActive(
                                                            "wb",
                                                            canvas.model.canvasWhiteboards,
                                                            null,
                                                            null,
                                                            null,
                                                        );
                                                        postCanvasMessage();
                                                        canvas.worker.postMessage(["framework", "closed", closedMsg]);
                                                    } else {
                                                        console.warn("Could not close => non synced ops...");
                                                    }
                                                }
                                            }
                                            break;
                                        case "set":
                                            {
                                                if (canvas?.model && opt.id) {
                                                    const ops: DataOperation[] = [];
                                                    canvas.model._setWhiteboardState(ops, opt.id, opt.state);
                                                    if (ops.length > 0) {
                                                        ops[ops.length - 1].group = false;
                                                        canvas.model.pushOperation(ops);
                                                    }
                                                    postCanvasMessage();
                                                    syncStream(this);
                                                }
                                            }
                                            break;
                                        case "drop":
                                            {
                                                assert_dev(false); // moved to lcmd2core
                                            }
                                            break;
                                        case "update":
                                            {
                                                if (Array.isArray(opt)) {
                                                    const m = canvas.model.canvasWhiteboards.model;
                                                    m.updateModel();
                                                    m.stackTasksIfNeeded();
                                                    const ops: DataOperation[][] = [];
                                                    for (let i = 0; i < opt.length; i++) {
                                                        const change_ops: DataOperation[] = [];
                                                        const change = opt[i];
                                                        Object.getOwnPropertyNames(change).forEach((name) => {
                                                            let value = change[name];
                                                            switch (name) {
                                                                case "start":
                                                                case "days":
                                                                    if ("start" === name) {
                                                                        value =
                                                                            0 < value && value < MAX_TASK_DAYS
                                                                                ? (value - 1) / m.compat.gpaFix + 1
                                                                                : value;
                                                                    } else if ("days" === name) {
                                                                        value = Math.min(
                                                                            Math.max(0, value),
                                                                            MAX_TASK_DAYS - 1,
                                                                        );
                                                                        if (!(0 <= value && value < MAX_TASK_DAYS)) {
                                                                            value = undefined;
                                                                        }
                                                                    }
                                                                // no break
                                                                case "stripey": // no break
                                                                case "x": // no break
                                                                case "y": // no break
                                                                case "w": // no break
                                                                case "h": // no break
                                                                    if ("number" === typeof value) {
                                                                        let apply = false;
                                                                        if (
                                                                            DataOperationTarget.TASKS === change.target
                                                                        ) {
                                                                            const t = m.tasks[change.id];
                                                                            if (t) {
                                                                                m.setCanvasDirtyFlag(change.id);
                                                                                if ("stripey" === name) {
                                                                                    apply = t._y !== value;
                                                                                } else {
                                                                                    apply =
                                                                                        m.VALUE<number>(
                                                                                            t[name],
                                                                                            undefined,
                                                                                        ) !== value;
                                                                                }
                                                                            }
                                                                        }
                                                                        if (apply) {
                                                                            change_ops.push({
                                                                                op: DataOperationType.UPDATE,
                                                                                target: change.target,
                                                                                id: change.id,
                                                                                name: name,
                                                                                value: value,
                                                                                z: 0,
                                                                                _u: m.userName,
                                                                                group: true,
                                                                            });
                                                                        }
                                                                    }
                                                                    break;
                                                            }
                                                        });
                                                        if (change_ops.length > 0) {
                                                            ops.push(change_ops);
                                                        }
                                                    }
                                                    if (ops.length > 0) {
                                                        const _ops = m.reduceAndFinalizeOps(ops);
                                                        m.pushOperation(_ops);
                                                        postCanvasMessage();
                                                        syncStream(this);
                                                    } else {
                                                        postCanvasMessage(); // make sure to send the "forced" updates...
                                                    }
                                                }
                                            }
                                            break;
                                        case "cut": // no break
                                        case "copy":
                                            {
                                                const m = canvas.model.canvasWhiteboards.model;
                                                const msg = event.data[2];
                                                const isCut = "cut" === event.data[1];
                                                canvas._clipboard.action = event.data[1];
                                                canvas._clipboard.origin = m.storageName;
                                                canvas._clipboard.content = msg
                                                    .map((tid) => m.processToJSON(tid))
                                                    .filter((task) => !!task);
                                                if (isCut) {
                                                    m.deleteTasks(msg);
                                                    postCanvasMessage();
                                                }
                                            }
                                            break;
                                        case "paste":
                                            {
                                                const m = canvas.model.canvasWhiteboards.model;
                                                const paste = event.data[2] as {
                                                    x: number;
                                                    y: number;
                                                    p: {
                                                        pid: number;
                                                        i_pid: number;
                                                        tz_pid: string;
                                                    };
                                                };
                                                if (
                                                    m &&
                                                    canvas._clipboard.content.length > 0 &&
                                                    canvas._clipboard.origin === m.storageName
                                                ) {
                                                    m.stackTasksIfNeeded();
                                                    const content = canvas._clipboard.content;
                                                    const sentinelPos = {
                                                        x1: content.reduce(
                                                            (ret, t) => (t.start < ret ? t.start : ret),
                                                            content[0].start,
                                                        ),
                                                        y: content.reduce(
                                                            (ret, t) => (t.y < ret ? t.y : ret),
                                                            content[0].y,
                                                        ),
                                                    };
                                                    const dx = paste.x - m.grid.dateToGrid(sentinelPos.x1);
                                                    const dy = paste.y - sentinelPos.y;

                                                    const { ops } = m.insertTasksFromJSON(
                                                        canvas._clipboard.content as any,
                                                        m.grid,
                                                        dx,
                                                        dy,
                                                        paste.p,
                                                        canvas._clipboard.action === "copy",
                                                    );

                                                    //copy => copy; cut => rewrite to copy for next paste
                                                    canvas._clipboard.action = "copy";
                                                    if (ops.length > 0) {
                                                        const _ops = m.reduceAndFinalizeOps(ops);
                                                        m.pushOperation(_ops);
                                                        postCanvasMessage();
                                                        syncStream(this);
                                                    } else {
                                                        postCanvasMessage(); // make sure to send the "forced" updates...
                                                    }
                                                }
                                            }
                                            break;
                                        case "pastepp":
                                            {
                                                assert_dev(false); // moved to lcmd2core
                                            }
                                            break;
                                        case "setPPGpa":
                                            {
                                                const m = canvas.model.canvasWhiteboards.model;
                                                const props = event.data[2] as {
                                                    lib: number;
                                                    stripei: number;
                                                    stripej: number;
                                                    stripet: number;
                                                };
                                                const gpa = m ? m.tasks[props.lib] : null;
                                                const p = canvas.model.tasks[props.stripet];
                                                if (p && gpa) {
                                                    const _ops: DataOperation[] = [];
                                                    const opsCtx = {
                                                        maxIds: canvas.model.maxIdsCopy(),
                                                        createdOps: [] as DataOperation[],
                                                    };
                                                    const gpa_color = undefined;
                                                    _ops.push({
                                                        op: DataOperationType.UPDATE,
                                                        target: DataOperationTarget.TASKS,
                                                        id: props.stripet,
                                                        name: "gpa",
                                                        value: {
                                                            name: m.VALUE<string>(gpa.name),
                                                            color: gpa_color,
                                                        },
                                                        r_sid: m.storageName,
                                                        r_id: props.lib,
                                                        r_ts: m.commitTS(),
                                                        z: 0,
                                                        _u: canvas.model.userName,
                                                        group: true,
                                                    });
                                                    if (_ops.length > 0) {
                                                        _ops[_ops.length - 1].group = false;
                                                        canvas.model.pushOperation(_ops);
                                                        postCanvasMessage();
                                                        syncStream(this);
                                                    } else {
                                                        postCanvasMessage(); // make sure to send the "forced" updates...
                                                    }
                                                }
                                            }
                                            break;
                                        case "libAdd":
                                            {
                                                assert(false); // moved to lcmd2core
                                            }
                                            break;
                                        case "sync":
                                            {
                                                assert(canvas.activeSync >= 0);
                                                canvas.activeSync++;
                                                const sync = event.data[2];
                                                const cb = sync?.cb;
                                                const syncDate0 = Math.max(
                                                    rt_pp?.syncDate || 0,
                                                    canvas.model?.canvasWhiteboards?.rt?.syncDate || 0,
                                                );
                                                syncStream(this);
                                                if (cb) {
                                                    postCanvasMessage();
                                                    const timeout = Math.max(
                                                        "number" === typeof sync?.timeout ? sync.timeout : 0,
                                                        1000,
                                                    );
                                                    const ctx = { id: undefined as any };
                                                    ctx.id = setInterval(() => {
                                                        let syncDate = rt_pp?.syncDate || 0;
                                                        if (canvas.model?.canvasWhiteboards?.rt?.syncDate > 0) {
                                                            syncDate = Math.min(
                                                                syncDate,
                                                                canvas.model.canvasWhiteboards.rt.syncDate,
                                                            );
                                                        }
                                                        if (syncDate0 < syncDate) {
                                                            clearInterval(ctx.id);
                                                            assert(canvas.activeSync > 0);
                                                            canvas.activeSync--;
                                                            postCanvasMessage();
                                                            canvas.worker.postMessage(["cb", cb, {}]);
                                                        }
                                                    }, timeout);
                                                } else {
                                                    assert(canvas.activeSync > 0);
                                                    canvas.activeSync--;
                                                    postCanvasMessage();
                                                }
                                            }
                                            break;
                                        case "rename":
                                            {
                                                const m = canvas.model.canvasWhiteboards.model;
                                                const msg = event.data[2];
                                                if (m && DataOperationTarget.TASKS === msg.target) {
                                                    const t = m.tasks[msg.id];
                                                    if (t) {
                                                        m.pushOperation({
                                                            op: DataOperationType.UPDATE,
                                                            target: DataOperationTarget.TASKS,
                                                            id: msg.id,
                                                            name: "name",
                                                            value: msg.value,
                                                            z: 0,
                                                            _u: m.userName,
                                                            group: false,
                                                        });
                                                        postCanvasMessage();
                                                        syncStream(this);
                                                    }
                                                }
                                            }
                                            break;
                                        case "takting":
                                            {
                                                const m = canvas.model.canvasWhiteboards.model;
                                                const op = event.data[2];
                                                if (op?.preview) {
                                                    const gpaPreview: any = m.gpaPreview || {};
                                                    let trainId;
                                                    if ("number" === typeof (trainId = op.value?.id)) {
                                                        // start preview
                                                        const trainInfos = gpaPreview?.trainInfos || {};
                                                        const trainInfo = trainInfos[trainId] || {};
                                                        m.gpaPreview = {
                                                            ...gpaPreview,
                                                            trainInfos: {
                                                                ...trainInfos,
                                                                [trainId]: { ...trainInfo, ...op.value },
                                                            },
                                                            ts: 0,
                                                            hostTS: 0,
                                                            enabled: true,
                                                        };
                                                    }
                                                    if (
                                                        true === op?.orderByNameSuffix ||
                                                        false === op?.orderByNameSuffix
                                                    ) {
                                                        m.gpaPreview = {
                                                            ...gpaPreview,
                                                            orderByNameSuffix: op.orderByNameSuffix,
                                                            ts: 0,
                                                            hostTS: 0,
                                                            enabled: true,
                                                        };
                                                    }
                                                } else if (op.value) {
                                                    // generate plan
                                                    const trainInfos =
                                                        true === op.value ? m.gpaPreview.trainInfos : op.value;
                                                    if (trainInfos) {
                                                        m.gpaPreview = {
                                                            trainInfos: trainInfos,
                                                            orderByNameSuffix:
                                                                true === op.value
                                                                    ? m.gpaPreview.orderByNameSuffix
                                                                    : op.orderByNameSuffix,
                                                            ts: 0,
                                                            hostTS: 0,
                                                            enabled: true,
                                                        };
                                                        const ops: DataOperation[] = [];
                                                        canvas.model._updateGPAPreview(m, {
                                                            maxIds: canvas.model.maxIdsCopy(),
                                                            ops,
                                                        });
                                                        if (ops.length > 0) {
                                                            ops[ops.length - 1].group = false;
                                                            canvas.model.pushOperation(ops);
                                                            canvas.model.updateModel();
                                                        }
                                                    }
                                                    m.gpaPreview = null; // end preview
                                                } else {
                                                    // end preview
                                                    m.gpaPreview = null;
                                                }
                                                postCanvasMessage();
                                                syncStream(this);
                                            }
                                            break;
                                    }
                                } catch (e) {
                                    if (e instanceof FrameworkErrorDataModelNeedsSync) {
                                        canvas.worker.postMessage(["toggle", "fw.exception", errorToJSON(e)]); // open exception dialog...
                                        /*
                        xx
                        errorToJSON
                        LCMD.showDialog("fw.alert", {
                            dialogContentProps: {
                                type: DialogType.normal,
                                title: intl.get("overview.cmd.unlink.alert.title"),
                                subText: intl.get("overview.cmd.unlink.alert.subText")
                            },
                            onOK: ()=>{
                                LCMD.showDialog("fw.alert", null);
                                LCMD.showDialog("fw.project.unlink"); // fix me...
                            },
                            onCancel: ()=>{
                                LCMD.showDialog("fw.alert", null);
                            }
                        });

                        XXXX
                        //canvas.worker.postMessage(["toggle", "lib.props", {id: pid}]); // open rename dialog...
                        */
                                    } else {
                                        throw e; // rethrow
                                    }
                                }
                            }
                            break;
                        case "todo":
                            {
                                const cmd = event.data[1];
                                const opt = event.data[2];
                                switch (cmd) {
                                    case "fetch":
                                        if (canvas.onUpdateCache?.core && opt?.cb) {
                                            const todos = canvas.onUpdateCache.core.getApiTodos(opt);
                                            canvas.worker.postMessage(["cb", opt.cb, todos]);
                                        }
                                        break;
                                    case "set":
                                        if (canvas.onUpdateCache?.core && opt?.cb) {
                                            canvas.onUpdateCache.core.setOrCreateApiTodoItemForProcess(
                                                opt?.target,
                                                opt.state,
                                                opt.meta,
                                            );
                                            postCanvasMessage();
                                            syncStream(this);
                                            canvas.worker.postMessage(["cb", opt.cb, {}]);
                                        }
                                        break;
                                }
                            }
                            break;
                        case "db":
                            {
                                const msg = event.data;
                                switch (msg[1]) {
                                    case "sync":
                                        {
                                            const dbSyncTS = msg[2].syncTS;
                                            if (canvas.model && "number" === typeof dbSyncTS) {
                                                const syncTS = canvas.model.syncCommitedTS().ofs;
                                                if (dbSyncTS > syncTS) {
                                                    syncStream(this);
                                                }
                                            }
                                        }
                                        break;
                                }
                            }
                            break;
                        case "qa":
                            {
                                const msg = event.data;
                                console.log("qa " + JSON.stringify(msg));
                                console.log(JSON.stringify(canvas.model._snap));
                                switch (msg[1]) {
                                    case "rev":
                                        {
                                            canvas.model.setMaxCommit(msg[2]);
                                            canvas.model.updateModel();
                                            console.log(JSON.stringify(canvas.model._snap));
                                            postCanvasMessage();
                                        }
                                        break;
                                }
                            }
                            break;
                        case "QA":
                            {
                                const msg = event.data[1];
                                if (null === canvas.qa) {
                                    // need to initialze QA
                                }
                                canvas.model.updateModel();
                                postCanvasMessage();
                                syncStream(this);
                                console.log("QA Snapshot");
                            }
                            break;
                        case "shutdown":
                            {
                                const msg = event.data[1];
                                canvas.worker.postMessage(["shutdown", {}]);
                            }
                            break;
                        case "ping":
                            {
                                const msg = event.data[1];
                                canvas.worker.postMessage(["pong", {}]);
                            }
                            break;
                        case "area": {
                            const cmd = event.data[1];
                            switch (cmd) {
                                case "toggleCollapse": {
                                    const stripeId = event.data[2];
                                    canvas.model.collapseStripe(stripeId);
                                    postCanvasMessage();
                                    break;
                                }
                                case "expandAll": {
                                    canvas.model.expandAllStripes();
                                    postCanvasMessage();
                                    break;
                                }
                                case "collapseAll": {
                                    canvas.model.collapseAllStripes();
                                    postCanvasMessage();
                                    break;
                                }
                            }
                            break;
                        }
                        default:
                            console.warn("Unhandled Message " + JSON.stringify(event.data));
                            break;
                    }
                this._evt.core = null;
                this._evt.msg = null;
                const date1 = Date.now();
                const delta = date1 - date0;
                if (delta > 1000) {
                    console.warn("Long Op " + event.data[0] + " " + delta + "ms");
                }
            } else {
                console.warn("Invalid Message " + JSON.stringify(event.data));
            }
        } catch (e) {
            setGlobalError(e);
        }
}

function connectRT(
    ctx: { queue: (() => Promise<void>)[]; queueBusy: boolean },
    mode: "canvas" | "wb",
    model: DataModel,
    rt: RT,
    streamId: string,
    sub: string,
    sandbox_token: string,
    warmupId: string,
) {
    //const hubName5="P"+UUID5.fromUUID(projectId).toUUID5();
    //const user=Math.floor(Math.random()*0xFFFFFFFF).toString(16).padStart(8, '0');
    //console.log("MAIN WORKER "+user);
    const sdkInst = "pp-api"; //@TODO make configurable
    rt.connect(
        canvas.meta?.pid,
        streamId,
        sub,
        canvas.master_token,
        sdkInst,
        warmupId,
        (error: Error, sts: number, sessions: string[], data: RTSessionsData) => {
            if (null === canvas.error)
                try {
                    if (canvas.worker && canvas._loaded && canvas.clientReady) {
                        if (error) {
                            if (!_throwFrameworkUpdateNeededErrorIfNeeded(error)) {
                                _setConnectionState({ rt: error, mode }); // signal connection error
                            }
                        } else {
                            if (sessions && data) {
                                const patch = model.updateCanvasSessions(sessions, data);
                                if (patch) {
                                    canvas.worker.postMessage([
                                        "collaborators",
                                        {
                                            mode,
                                            sessions: patch,
                                        },
                                    ]);
                                }
                            }
                            ctx.queue.push(
                                _handleMessage.bind(ctx, {
                                    data: [
                                        "rtsync",
                                        {
                                            model,
                                            sts,
                                        },
                                    ],
                                }),
                            );
                            handleQueueIfNeeded(ctx);
                        }
                    }
                } catch (e) {
                    setGlobalError(e);
                }
        },
        "wb" === mode
            ? (session: string, data: RTSessionsData) => {
                  canvas.worker.postMessage([
                      "rt",
                      {
                          mode,
                          session,
                          x: data.x,
                          y: data.y,
                          n: data.n,
                          ts: data._ts,
                      },
                  ]);
              }
            : undefined,
    );
}

function createTradesIfRequired(paste: PasteObject, allTradeNames: any[]) {
    // Filter out the trades from the copiedTrades array that already exist in the current project.
    const createTrades = (paste.copiedTrades || []).filter((item) => !allTradeNames.includes(item.name));

    // For each trade that needs to be created, update the canvas model with the new trade.
    createTrades.forEach((_trade) => {
        canvas.model.updateTrade({
            id: -1,
            color: _trade.color,
            name: _trade.name,
            trade: undefined,
            subs: undefined,
        });
        canvas.model.updateModel();
    });

    // Get the updated list of trades after creating new ones.
    const updatedTrades = canvas.onUpdateCache.core.getTrades();

    // Create an array to hold mappings of new trade IDs to their original IDs from the copied content.
    const mergedArray = [];
    updatedTrades.forEach((obj1) => {
        const obj2 = paste.copiedTrades.find((obj) => obj.name === obj1.name);
        if (obj2) {
            mergedArray.push({ new_id: obj1.id, original_id: obj2.trid });
        }
    });

    // Create a mapping object from the merged array.
    const idMapping = {};
    mergedArray.forEach((item) => {
        idMapping[item.original_id] = item.new_id;
    });

    // Update the IDs in the clipboard content to match the new IDs in the current project.
    // This remapping ensures that tasks associated with a specific trade in the copied content
    // are now associated with the corresponding trade in the new project.
    paste.clipboardContent.forEach((item) => {
        const oldRw = item.rw;
        item.rw = {};

        Object.keys(oldRw)
            .filter((original_id) => idMapping[original_id] !== undefined)
            .forEach((original_id) => {
                item.rw[idMapping[original_id]] = 0;
            });
    });
}

function modifyPastedTasksIfRequired(content: any[], targetProjectInfo: any) {
    // Step 1: Find difference of each task's start and end
    content.forEach((task) => {
        task.diffBetweenStartAndEnd = task.end - task.start;
    });

    // Step 2: Find difference of each task's start from the start of the first task
    content.forEach((task) => {
        task.diffFromFirstStart = task.start - content[0].start;
    });

    // Step 3: Set the value of the first task's start equal to targetProjectInfo.startDate
    content[0].start = targetProjectInfo.startDate;

    // Step 4: For the rest of the tasks, update their start value
    for (let i = 1; i < content.length; i++) {
        content[i].start = targetProjectInfo.startDate + content[i].diffFromFirstStart;
    }

    // Step 5: For all the tasks, update their end value
    content.forEach((task) => {
        task.end = task.start + task.diffBetweenStartAndEnd;
    });

    // delete temporary properties
    content.forEach((task) => {
        delete task.diffBetweenStartAndEnd;
        delete task.diffFromFirstStart;
    });
    return content;
}

function getNewPredecessorsArray(originalTasks: PasteObject["copiedTaskIds"], createdTasksWithDetails) {
    // Create a mapping based on index
    const idMapping = {};
    originalTasks.forEach((task, index) => {
        idMapping[task.id] = createdTasksWithDetails[index].id;
    });
    // Update the relationships based on the mapping
    const updatedTasks = createdTasksWithDetails.map((task) => {
        // Clone the task to avoid mutating the original
        const updatedTask = { ...task };

        // Find the original task from paste_copiedTaskIds (created tasks and copied tasks are sorted arrays and are matched by index)
        const originalTask = originalTasks.find((t) => t.id in idMapping && idMapping[t.id] === task.id);

        // If there are predecessors
        if (originalTask && originalTask.predecessors) {
            updatedTask.predecessors = originalTask.predecessors.value.map((rel) => {
                return {
                    ...rel,
                    targetId: idMapping[rel.targetId],
                };
            });
        } else {
            updatedTask.predecessors = [];
        }

        // If there are successors
        if (originalTask && originalTask.successors) {
            updatedTask.successors = originalTask.successors.value.map((rel) => {
                return {
                    ...rel,
                    targetId: idMapping[rel.targetId],
                };
            });
        } else {
            updatedTask.successors = [];
        }

        return updatedTask;
    });

    return updatedTasks;
}

type pasteProcessWithDependencyArgs = {
    workerContext: any;
    ops: DataOperation[][];
    oldToNewProcessIdMapCollection: OldToNewProcessIdMap[];
    pasteToDifferentProject: boolean;
    copiedProcesses?: CopiedProcesses;
};

function addDependenciesToInsertedProcesses({
    workerContext,
    ops,
    oldToNewProcessIdMapCollection,
    pasteToDifferentProject,
    copiedProcesses,
}: pasteProcessWithDependencyArgs) {
    assert(
        !pasteToDifferentProject || typeof copiedProcesses !== "undefined",
        "when pasting from different project copied processes are needed",
    );

    //createDependencies
    if (ops.length > 0) {
        let originalProcesses = [];

        const _ops = canvas.model.reduceAndFinalizeOps(ops);
        canvas.model.pushOperation(_ops);
        postCanvasMessage();
        syncStream(workerContext);

        if (oldToNewProcessIdMapCollection.length > 0) {
            if (pasteToDifferentProject) {
                originalProcesses = copiedProcesses.map(({ id, predecessors }) => ({
                    id,
                    predecessors,
                }));
            } else {
                originalProcesses = [...new Set(oldToNewProcessIdMapCollection[0].keys())]
                    .map((processID) => canvas.onUpdateCache?.core.getProcessDetails(processID))
                    .filter((item) => !item.children)
                    .map((item) => ({
                        id: item.pid.value,
                        predecessors: item.predecessors,
                    }));
            }

            for (const oldToNewProcessIdMapElement of oldToNewProcessIdMapCollection) {
                createDependencyForProcessCopies({
                    oldToNewProcessIdMap: oldToNewProcessIdMapElement,
                    originalProcesses,
                });
            }
        }

        postCanvasMessage();
        syncStream(workerContext);
    } else {
        postCanvasMessage(); // make sure to send the "forced" updates...
    }
}

function createDependencyForProcessCopies({
    oldToNewProcessIdMap,
    originalProcesses,
}: {
    oldToNewProcessIdMap: OldToNewProcessIdMap;
    originalProcesses: Array<{ id: number; predecessors: RelatedProcesses }>;
}) {
    //if the original tasks had predecessors get their new ids for the newly created tasks after paste
    const processesWithPredecessors = adoptDependenciesToProcessCopies(oldToNewProcessIdMap, originalProcesses);
    // set predecessor for each newly created task
    processesWithPredecessors.forEach((processWithPredecessors) => {
        for (const predecessor of processWithPredecessors.predecessors) {
            if (processWithPredecessors.processId && predecessor.targetId) {
                canvas.onUpdateCache.core.setDependencyDetails(
                    processWithPredecessors.processId,
                    predecessor.targetId,
                    {
                        lag: predecessor.lag,
                        unit: predecessor.unit,
                        type: predecessor.type,
                    },
                );
            } else {
                console.log("task or predecessor id missing");
            }
        }
    });
}

function adoptDependenciesToProcessCopies(
    oldToNewProcessIdMap: OldToNewProcessIdMap,
    originalProcesses: Array<{ id: number; predecessors: RelatedProcesses }>,
): Array<{ processId: number; predecessors: Array<{ lag: number; type: number; unit: number; targetId: number }> }> {
    const originalProcessCache = new Map();
    const dependencyCopies = [];
    originalProcesses.forEach((originalProcesses) => {
        originalProcessCache.set(originalProcesses.id, originalProcesses);
    });

    oldToNewProcessIdMap.forEach((newProcessId, oldProcessId) => {
        if (!originalProcessCache.has(oldProcessId)) {
            return;
        }
        const originalProcess = originalProcessCache.get(oldProcessId);
        if (!(originalProcess && originalProcess.predecessors)) {
            return;
        }

        dependencyCopies.push({
            processId: newProcessId,
            predecessors: originalProcess.predecessors.value.map((dependency) => {
                return {
                    ...dependency,
                    targetId: oldToNewProcessIdMap.get(dependency.targetId),
                };
            }),
        });
    });

    return dependencyCopies;
}

function handleQueueIfNeeded(ctx: { queue: (() => Promise<void>)[]; queueBusy: boolean }) {
    if (ctx.queue.length > 0 && !ctx.queueBusy) {
        const msg = ctx.queue.shift();
        assert(false === ctx.queueBusy);
        ctx.queueBusy = true;
        msg().then(() => {
            assert(true === ctx.queueBusy);
            ctx.queueBusy = false;
            handleQueueIfNeeded(ctx);
        });
    }
}

function handleMessage(
    this: {
        queue: (() => Promise<void>)[];
        queueBusy: boolean;
        _evt: OnWorkerMsgEvent;
        onWorkerMsg?: (evt: OnWorkerMsgEvent) => Promise<boolean>;
    },
    event,
) {
    if (event.data?.source?.includes("react-devtools-")) {
        return;
    }
    const queued_msg = _handleMessage.bind(this, event);
    this.queue.push(queued_msg);
    handleQueueIfNeeded(this);
}

type OnWorkerMsgEventImpl = OnWorkerMsgEvent & { _preventUpdate: boolean };

export function startWorkerAsync(opt?: {
    onWorkerMsg?: (evt: OnParticleWorkerMsgEvent) => Promise<boolean>;
    onCoreUpdate?: (evt: OnParticleCoreUpdateEvent) => void;
    onImport?: (opt: OnImportOptions) => Promise<ParticleCore>;
}) {
    if (undefined === (globalThis as any).lcmdigital) {
        /*
        self.addEventListener('install', function(event) {
            // Perform install steps
        });

        self.addEventListener('activate', function(event) {
        });
        */

        addEventListener(
            "error",
            function (event) {
                if (event.error) {
                    setGlobalError(event.error);
                } else {
                    console.warn(event);
                }
            },
            false,
        );

        addEventListener(
            "unhandledrejection",
            function (event) {
                if (event.reason) {
                    setGlobalError(event.reason);
                } else {
                    console.warn(event);
                }
            },
            false,
        );

        const _evt: OnWorkerMsgEventImpl = {
            _preventUpdate: false,
            preventUpdate: null,
            core: null,
            msg: null,
            worker: canvas.worker,
        };
        _evt.preventUpdate = () => {
            _evt._preventUpdate = true;
        };
        _evt.updateCanvas = (opt?) => {
            const ret: any = postCanvasMessage(opt?.force);

            if (opt?.returnJSON) {
                const m = canvas.model;
                const C = m.viewConst;
                const grid = m.grid;
                const stripes = applyPatch<CanvasStripeData>([], ret.stripesPatch);
                const tasks = applyPatch<CanvasTaskData>([], ret.tasksPatch);
                const header = DataModel.generateCalendarHeader(
                    C,
                    grid,
                    ret.start,
                    ret.cols,
                    m._isWorkingDayMeta,
                    opt.returnJSON.calendarIntl,
                    canvas.model.viewConst.showProjectWeeks,
                );
                const trades = m.callbacks._coreGetTrades ? m.callbacks._coreGetTrades() : [];
                delete ret.stripesPatch;
                delete ret.tasksPatch;
                ret.stripes = stripes;
                ret.tasks = tasks;
                ret.trades = trades;
                ret.header = header;
                ret.sidebarColWidth = CONST.sidebarColWidth;
                ret.sidebarColExtra = CONST.sidebarColExtra;
                ret.sidebarColImage = C.sidebarColImage;
                ret.colPx = C.colPx;
                ret.rowPx = C.rowPx;
                ret.colHeaderHeight1 = C.colHeaderHeight1;
                ret.colHeaderHeight2 = C.colHeaderHeight2;
                ret.gridView = grid.view;
                ret.bottomStripeHeight = 10;
                return ret;
            } else {
                return null;
            }
        };
        _evt.canvasMeta = () => {
            return canvas.meta;
        };
        const _handleMessage = handleMessage.bind({ ...(opt || {}), _evt, queue: [], queueBusy: false });
        addEventListener("message", _handleMessage);

        //set qa context
        (globalThis as any).lcmdigital = {
            canvas: null,
            pipe: canvas.worker,
            services: null,
            qa: {},
        };
    }
}
