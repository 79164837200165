import * as React from "react";
import { useEffect, useReducer, useState } from "react";
import { useLCMD } from "../../app/LCMContext";

import { ColumnDef } from "@tanstack/react-table";

import { AttachedChecklist } from "@/core/services/Checklist.service";
import { TodoDataTable } from "@/components/ui/todo-data-table";
import { getCurrentLanguage } from "@/components/utils/date/locale";
import { ArrowUpDown, Minus } from "lucide-react";
import { StatusBadge } from "@/components/common/StatusBadge/StatusBadge";
import { Badge } from "@/components/ui/badge";
import { copyAndSort, dateToEpochDay, epochDayToDate } from "@/components/Utils";
import { getTimeDifferenceInDays } from "@/utils/DateUtils";
import { Button } from "../ui/button";
import { ScrollArea } from "@/components/ui/scroll-area";
import { intl } from "lcmd2framework";
import { ChecklistListsItem, nameColumn } from "@/app/types/Todo";
import { defaultTradeImage, tradeImages } from "@/components/Sidebar/TradeImages";
import { decimalToRgb } from "@/components/utils/color/decimal-to-rgb";
const sortStatus = ["", "open", "inProgress", "done"];

const bg = "bg-gray-100";
function NotSolvedIcon({ whiteBg }: { whiteBg: boolean }) {
    const styles = {
        outerDiv: {
            width: "20px",
            height: "20px",
            borderRadius: "50%",
        },
        normal: {
            icon: {
                color: "red",
            },
            background: {},
        },
        inverted: {
            icon: {
                color: "white",
            },
            background: {
                backgroundColor: "red",
            },
        },
    };
    const outerDivStyle = {
        ...styles.outerDiv,
    };
    return (
        <div
            style={outerDivStyle}
            className={(whiteBg ? "bg-white" : "bg-red-200") + " flex items-center justify-center"}
        >
            <Minus className={"text-red-600"}></Minus>
        </div>
    );
}

export function DueTodayBadge() {
    return (
        <Badge variant={"secondary"} className={"bg-yellow-200"}>
            {intl.get("checklistView.today")}
        </Badge>
    );
}

export function OverdueBadge({ days }: { days: number }) {
    return <Badge variant={"destructive"}>{days} T. Überfällig</Badge>;
}

const today = epochDayToDate(dateToEpochDay(new Date()));
export function ToDo() {
    const LCMD = useLCMD();

    const columns: ColumnDef<ChecklistListsItem, any>[] = [
        {
            id: "nameOutput",
            accessorKey: "nameOutput",
            header: intl.get("Checklist.Overview.Checklist"),
            enableSorting: true,
            enableHiding: true,
            size: 350,
            cell: ({ row }) => {
                const { tradeIcon, tradeColor, checklistName, tradeName }: nameColumn = row.getValue("nameOutput");
                const image = tradeImages.find((img) => img.title == tradeIcon) || defaultTradeImage;
                return (
                    <div className={"flex items-center gap-x-4"}>
                        <div
                            className={"flex h-8 w-8 items-center justify-center rounded-full"}
                            style={{ backgroundColor: decimalToRgb(tradeColor) }}
                        >
                            <img src={image.img} className="h-[16px] w-[16px]" />
                        </div>
                        <div>
                            <div className={"text-zinc-500"}>{checklistName}</div>
                            <div className={"text-zinc-950"}>{tradeName}</div>
                        </div>
                    </div>
                );
            },
        },
        {
            id: "endDate",
            accessorKey: "endDate",
            header: ({ column }) => {
                return (
                    <div className={"md:pl-12 lg:pl-0"}>
                        <Button
                            variant="ghost"
                            className={"p-0 text-sm font-semibold text-black"}
                            onClick={handleEndDateSort}
                        >
                            {intl.get("Checklist.Overview.DueDate")}
                            <ArrowUpDown className="ml-2 h-4 w-4" />
                        </Button>
                    </div>
                );
            },
            size: 300,
            cell: ({ row }) => {
                const endDate = new Date(row.getValue("endDate"));
                const locale = getCurrentLanguage();
                const endDateView = endDate.toLocaleDateString(locale, {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                });
                let endDateAddition = null;
                if (row.getValue("status") == "done") {
                    endDateAddition = null;
                } else if (endDate.getTime() == today.getTime()) {
                    endDateAddition = <DueTodayBadge />;
                } else if (endDate.getTime() < today.getTime()) {
                    const days = getTimeDifferenceInDays(endDate, today);
                    endDateAddition = <OverdueBadge days={days} />;
                }

                return (
                    <div className={"flex gap-4"}>
                        <div>{endDateView}</div>
                        <div>{endDateAddition}</div>
                    </div>
                );
            },
        },
        {
            id: "notSolved",
            accessorKey: "notSolved",
            size: 100,
            header: ({ column }) => {
                return (
                    <Button
                        variant="ghost"
                        className={"p-0 text-sm font-semibold text-black"}
                        onClick={handleNotSolvedSort}
                    >
                        {intl.get("Checklist.Overview.NegativeCheck")}
                        <ArrowUpDown className="ml-2 h-4 w-4" />
                    </Button>
                );
            },
            cell: ({ row }) => {
                let isOverdue = false;
                if (row.getValue("status") !== "done") {
                    const endDate = new Date(row.getValue("endDate"));
                    isOverdue = endDate.getTime() < today.getTime();
                }
                return row.getValue("notSolved") ? (
                    <div className={"flex w-16"}>
                        <NotSolvedIcon whiteBg={isOverdue} />
                    </div>
                ) : null;
            },
        },
        {
            id: "status",
            accessorKey: "status",
            size: 150,
            header: ({ column }) => {
                return (
                    <Button
                        variant="ghost"
                        className={"p-0 text-sm font-semibold text-black"}
                        onClick={handleStatusSort}
                    >
                        {intl.get("Checklist.Overview.Status")}

                        {currentStatusSorting.status !== "" && (
                            <div className={"ml-2"}>
                                <StatusBadge status={currentStatusSorting.status} />
                            </div>
                        )}
                        <ArrowUpDown className="ml-2 h-4 w-4" />
                    </Button>
                );
            },
            cell: ({ row }) => <StatusBadge status={row.getValue("status")} />,
        },
    ];

    const handleNotSolvedSort = () => {
        const sortedOtherChecklists = sortNotSolved([...state.otherChecklistsView], sortNotSolvedAscending);
        const sortedOverdueChecklists = sortNotSolved([...state.overdueChecklistsView], sortNotSolvedAscending);

        setState({
            overdueChecklistsView: sortedOverdueChecklists,
            otherChecklistsView: sortedOtherChecklists,
        });

        setSortNotSolvedAscending(!sortNotSolvedAscending);
    };

    const handleStatusSort = () => {
        if (currentStatusSorting.index === 0) {
            setState({
                overdueChecklistsView: state.overdueChecklistsAll,
                otherChecklistsView: state.otherChecklistsAll,
                doneChecklistsView: state.doneChecklistsAll,
            });
        } else {
            const sortedDoneChecklists = sortForStatus([...state.doneChecklistsAll]);
            const sortedOtherChecklists = sortForStatus([...state.otherChecklistsAll]);
            const sortedOverdueChecklists = sortForStatus([...state.overdueChecklistsAll]);

            setState({
                overdueChecklistsView: sortedOverdueChecklists,
                otherChecklistsView: sortedOtherChecklists,
                doneChecklistsView: sortedDoneChecklists,
            });
        }

        const newIndex = (currentStatusSorting.index + 1) % 4;
        const newStatus = sortStatus[currentStatusSorting.index];

        setCurrentStatusSorting({
            index: newIndex,
            status: newStatus,
        });
    };

    const handleEndDateSort = () => {
        const sortedOverdueChecklists = copyAndSort(
            [...state.overdueChecklistsView],
            "endDateTimestamp",
            sortEndDateDescending,
        );
        const sortedOtherChecklists = copyAndSort(
            [...state.otherChecklistsView],
            "endDateTimestamp",
            sortEndDateDescending,
        );
        const sortedDoneChecklists = copyAndSort(
            [...state.doneChecklistsView],
            "endDateTimestamp",
            sortEndDateDescending,
        );

        setState({
            overdueChecklistsView: sortedOverdueChecklists,
            otherChecklistsView: sortedOtherChecklists,
            doneChecklistsView: sortedDoneChecklists,
        });

        setSortEndDateDescending(!sortEndDateDescending);
    };

    const [state, setState] = useReducer(
        (current, update) => ({
            ...current,
            ...update,
        }),
        {
            overdueChecklistsView: [],
            otherChecklistsView: [],
            doneChecklistsView: [],
            overdueChecklistsAll: [],
            otherChecklistsAll: [],
            doneChecklistsAll: [],
        },
    );

    const [sortNotSolvedAscending, setSortNotSolvedAscending] = useState(true);
    const [sortEndDateDescending, setSortEndDateDescending] = useState(false);
    const [currentStatusSorting, setCurrentStatusSorting] = useState({ index: 1, status: "" });
    const [checklists, setChecklists] = useState<ChecklistListsItem[]>([]);
    const [triggerRefresh, setTriggerRefresh] = useState<boolean>(false);

    useEffect(() => {
        async function getChecklists() {
            const response = await LCMD.getAllAttachedChecklists();
            const dataMap: ChecklistListsItem[] = response.map((item: AttachedChecklist) => {
                let isOverdue = false;
                if (item.status !== "done") {
                    const endDate = new Date(item.endDate);
                    isOverdue = endDate.getTime() < today.getTime();
                }
                return {
                    checklistObject: item,
                    nameOutput: {
                        checklistName: item.name,
                        tradeName: item.trade.name || item.trade.trade,
                        tradeIcon: item.trade.icon,
                        tradeColor: item.trade.color,
                    },
                    notSolved: item.data.some((check) => check === 1),
                    overdue: isOverdue,
                    status: item.status,
                    endDate: item.endDate,
                    endDateTimestamp: item.endDate.getTime(),
                };
            });

            setChecklists(dataMap);
        }

        getChecklists();
    }, [triggerRefresh]);

    useEffect(() => {
        if (checklists.length == 0) {
            return;
        }
        const filteredOverdueChecklists = checklists.filter((checklist) => checklist.overdue);
        const filteredDoneChecklists = checklists.filter((checklist) => checklist.status === "done");
        const filteredOtherChecklists = checklists.filter(
            (checklist) => !checklist.overdue && checklist.status !== "done",
        );

        const sortedOverdueChecklists = copyAndSort(
            filteredOverdueChecklists,
            "endDateTimestamp",
            sortEndDateDescending,
        );
        const sortedOtherChecklists = copyAndSort(filteredOtherChecklists, "endDateTimestamp", sortEndDateDescending);
        const sortedDoneChecklists = copyAndSort(filteredDoneChecklists, "endDateTimestamp", sortEndDateDescending);

        setState({
            overdueChecklistsView: sortedOverdueChecklists,
            otherChecklistsView: sortedOtherChecklists,
            doneChecklistsView: sortedDoneChecklists,
            overdueChecklistsAll: sortedOverdueChecklists,
            otherChecklistsAll: sortedOtherChecklists,
            doneChecklistsAll: sortedDoneChecklists,
        });
        setSortEndDateDescending(!sortEndDateDescending);
    }, [checklists]);

    function sortForStatus(checklists: ChecklistListsItem[]) {
        const filteredChecklist =
            checklists.filter((item) => {
                return item.status === sortStatus[currentStatusSorting.index];
            }) || [];
        return filteredChecklist;
    }

    function sortNotSolved(checklists: ChecklistListsItem[], ascending?) {
        // Define the order of statuses
        const statusOrder = [true, false];
        if (!ascending) {
            statusOrder.reverse();
        }

        // Sort function
        return checklists.sort((a, b) => {
            // Compare statuses first
            const statusComparison = statusOrder.indexOf(a.notSolved) - statusOrder.indexOf(b.notSolved);
            if (statusComparison !== 0) return statusComparison;

            // If statuses are the same, compare endDates (latest endDate first)
            return a.endDate.getTime() - b.endDate.getTime();
        });
    }

    return (
        <div className={"flex h-full  w-full justify-center " + bg}>
            <div
                style={{
                    width: "100%",
                    maxWidth: "1200px",
                }}
            >
                <ScrollArea className="h-full w-full p-8">
                    <TodoDataTable
                        triggerRefresh={() => {
                            setTriggerRefresh(!triggerRefresh);
                        }}
                        columns={columns}
                        data={state.overdueChecklistsView || []}
                        tdClassNames={"bg-red-100 border-b border-t border-red-600 first:border-l last:border-r"}
                    />
                    <div className="relative flex h-12 w-full items-center justify-center p-4">
                        <div className="w-full border-t-2 border-dashed border-slate-300"></div>
                        <div className="absolute left-1/2 -translate-x-1/2 transform">
                            <div className={"p-2 " + bg}>
                                <Badge className={"bg-blue-100 text-blue-600"}>Running</Badge>
                            </div>
                        </div>
                    </div>
                    <TodoDataTable
                        triggerRefresh={() => {
                            setTriggerRefresh(!triggerRefresh);
                        }}
                        columns={columns}
                        showColumns={false}
                        data={state.otherChecklistsView || []}
                    />
                    <div className="relative flex h-12 w-full items-center justify-center p-4">
                        <div
                            className="w-full border-t-2 border-dashed border-slate-300"
                            style={{
                                borderTopStyle: "dashed",
                                borderTopWidth: "2px",
                                borderTopColor: "#cbd5e1",
                            }}
                        ></div>
                        <div className="absolute left-1/2 -translate-x-1/2 transform">
                            <div className={"p-2 " + bg}>
                                <Badge className={"bg-green-100 text-green-600"}>Done</Badge>
                            </div>
                        </div>
                    </div>
                    <TodoDataTable
                        triggerRefresh={() => {
                            setTriggerRefresh(!triggerRefresh);
                        }}
                        columns={columns}
                        showColumns={false}
                        data={state.doneChecklistsView || []}
                    />
                </ScrollArea>
            </div>
        </div>
    );
}
