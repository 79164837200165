import {
    HashRouter as Router,
    Routes,
    Route,
    createHashRouter,
    createRoutesFromElements,
    RouterProvider,
    redirect,
} from "react-router-dom";

import { Paths } from "./types/routes";
import { HomeView } from "./components/home/Home";
import { Login } from "./components/login/Login";
import { Register } from "./components/register/Register";
import { ForgotPassword } from "./components/forgotPassword/ForgotPassword";
import { MsalProvider } from "@azure/msal-react";
import { BrowserCacheLocation, Configuration, PublicClientApplication } from "@azure/msal-browser";
import { ConfirmEmail } from "./components/confirmEmail/ConfirmEmail";
import { MainLayout } from "@/lcmd2loginV2/src/components/common/MainLayout/MainLayout";
import { AuthClient } from "@/app/services/AuthClient.service";
import { useMemo } from "react";
import { useLoginStore } from "@/lcmd2loginV2/src/store/loginStore";
import { BookAppointment } from "@/lcmd2loginV2/src/components/bookAppointment/BookAppointment";

export type LcmdLoginProps = {
    onLogin: (sub: string, authToken: string) => void;
};

// MSAL configuration
const configuration: Configuration = {
    auth: {
        // TODO: can this stay here or should it be a config value?
        clientId: "0ac8729c-5a18-4395-b4e9-a85d09e80daa",
        redirectUri: window.location.origin, // hack for issue https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/4921
    },
    cache: {
        cacheLocation: BrowserCacheLocation.SessionStorage,
    },
};
// needs to be outside of a react component, so it won't re init
const pca = new PublicClientApplication(configuration);

async function registerLoader({ params }) {
    if (!params.email) {
        return redirect(Paths.HOME);
    }

    if (await AuthClient.isUserRegistered(params.email)) {
        useLoginStore.setState({ email: params.email });
        if (params.project) {
            useLoginStore.setState({ projectId: params.project });
        }
        return redirect(Paths.LOGIN);
    }

    return null;
}

export function LcmdLogin({ onLogin }: LcmdLoginProps) {
    // using memo to make tjhe routes static inside a component. Routes should be created outside of react see:
    // https://reactrouter.com/6.28.2/routers/create-browser-router
    const router = useMemo(() => {
        return createHashRouter(
            createRoutesFromElements(
                <Route>
                    <Route path={Paths.HOME} element={<HomeView onLogin={onLogin} />} />
                    <Route path={Paths.LOGIN} element={<Login onLogin={onLogin} />} />
                    <Route path={Paths.REGISTER} element={<Register onLogin={onLogin} />} loader={registerLoader} />
                    <Route path={Paths.FORGOT_PASSWORD} element={<ForgotPassword onLogin={onLogin} />} />
                    <Route path={Paths.CONFIRM_EMAIL} element={<ConfirmEmail onLogin={onLogin} />} />
                    <Route path={Paths.BOOK_APPOINTMENT} element={<BookAppointment />} />
                </Route>,
            ),
        );
    }, [onLogin]);
    return (
        <MsalProvider instance={pca}>
            <MainLayout>
                <RouterProvider router={router} />
            </MainLayout>
        </MsalProvider>
    );
}
