import { CanvasTaskData } from "../../../model/DataModel";
import type { CanvasStripeData } from "../../../model/DataModel";
import {
    CanvasHostHitResult,
    CanvasHostRenderer,
    CanvasHostRendererCtx,
    CanvasHostRendererDragInfo,
    CanvasHostRendererDropInfo,
    CanvasHostRenderNode,
    CanvasHostStripeRenderNode,
    ICanvasHostRenderer,
} from "./Shared/CanvasHostRenderer";
import { CanvasHostBase, CanvasHostBasePointerEvent, CanvasHostBaseProps } from "./Shared/CanvasHostBase";
import { needsWhiteColor } from "../../../model/GlobalHelper";

export class CanvasTaktzoneHost extends CanvasHostBase implements ICanvasHostRenderer {
    constructor(props: CanvasHostBaseProps) {
        super(props, {
            zOrderStripes: true,
            xGroupTasks: true,
            eventHitTest: true,
        });
        this._renderer.setRenderer(this);
    }

    public renderOverlay(rc: CanvasHostRendererCtx, ctx: CanvasRenderingContext2D) {}

    public renderHell(rc: CanvasHostRendererCtx, ctx: CanvasRenderingContext2D) {}

    public renderHeaven(rc: CanvasHostRendererCtx, ctx: CanvasRenderingContext2D) {}

    public renderEnd(rc: CanvasHostRendererCtx, ctx: CanvasRenderingContext2D) {}

    public onAddDependency(srcPid: number, tgtPid: number) {}
    public renderSelectedBaselineHighlight(
        rc: CanvasHostRendererCtx,
        ctx: CanvasRenderingContext2D,
        task: CanvasTaskData & CanvasHostStripeRenderNode,
        baselineTask: CanvasTaskData & CanvasHostStripeRenderNode,
    ) {}

    private renderItem(
        rc: CanvasHostRendererCtx,
        ctx: CanvasRenderingContext2D,
        pos: { __x: number; __y: number; __w: number; __h: number; color?: number },
        dx: number,
        dy: number,
        sel: boolean,
        state: number,
        title: any,
    ) {
        const color = pos.color || 0xffffff;
        ctx.fillStyle = "#" + color.toString(16).padStart(6, "0");
        if (!sel) {
            ctx.strokeStyle = "gray";
            ctx.lineWidth = 1;
        } else {
            ctx.strokeStyle = rc.C.gpa.selected.primary.style;
            ctx.lineWidth = rc.C.gpa.selected.primary.width;
        }
        ctx.fillRect(pos.__x + dx, pos.__y + dy, pos.__w, pos.__h);
        ctx.strokeRect(pos.__x + dx, pos.__y + dy, pos.__w, pos.__h);
        if (title) {
            // render text
            const p = 0; // rc.C.gpa.task.label.padding;
            const x = pos.__x + p + dx;
            const y = pos.__y + p + dy;
            const w = pos.__w - p - p;
            const h = pos.__h - p - p;
            const _w = needsWhiteColor(color);
            ctx.fillStyle = _w ? rc.C.gpa.task.label.white : rc.C.gpa.task.label.black;
            CanvasHostRenderer.renderSegments(ctx, title, x, y, w, h, 1, false, false, 0, 0, false);
        }
    }

    public renderStripeHell(
        rc: CanvasHostRendererCtx,
        ctx: CanvasRenderingContext2D,
        stripe: CanvasStripeData & CanvasHostStripeRenderNode,
        i_stripe: number,
    ) {}

    public renderStripeBegin(
        rc: CanvasHostRendererCtx,
        ctx: CanvasRenderingContext2D,
        stripe: CanvasStripeData & CanvasHostStripeRenderNode,
    ) {
        /*
        ctx.fillStyle="red";
        ctx.fillRect(stripe.__x, stripe.__y, stripe.__w, stripe.__h);
        if (-1===stripe.__z || rc.taskICount>0) {
            ctx.strokeStyle="gray";
            ctx.lineWidth=1;
        } else {
            ctx.strokeStyle=rc.C.gpa.selected.primary.style;
            ctx.lineWidth=rc.C.gpa.selected.primary.width;
        }
        ctx.strokeRect(stripe.__x, stripe.__y, stripe.__w, stripe.__h);
        */
        const dx = 0;
        const dy = 0;
        this.renderItem(rc, ctx, stripe, dx, dy, !(-1 === stripe.__z || rc.taskICount > 0), 0, stripe.segs);
    }

    public renderStripeEnd(
        rc: CanvasHostRendererCtx,
        ctx: CanvasRenderingContext2D,
        stripe: CanvasStripeData & CanvasHostStripeRenderNode,
    ) {}

    public renderTask(
        rc: CanvasHostRendererCtx,
        ctx: CanvasRenderingContext2D,
        task: CanvasTaskData & CanvasHostStripeRenderNode,
    ) {
        const dx =
            (-1 === task.__z ? 0 : rc.selStripeDx) +
            (rc.selXGroup ? (-1 === rc.selXGroup.__z ? 0 : rc.selStripeDx) : 0);
        const dy =
            (-1 === task.__z ? 0 : rc.selStripeDy) +
            (rc.selXGroup ? (-1 === rc.selXGroup.__z ? 0 : rc.selStripeDy) : 0);
        /*
        if (15===task.id) {
            console.log(JSON.stringify({__y: task.__y, __h: task.__h, __z: task.__z, selXGroup: rc.selXGroup, dx, dy}));
        }
        */
        this.renderItem(rc, ctx, task, dx, dy, -1 !== task.__z, 0, task.segs);
    }

    public selChanged() {
        /*
        const sel=this._renderer.getSel();
        const tids=sel.map((s)=>s.id);
        this.props.worker.postMessage(["processview", "selchg", {
            id: this.wbs?.id,
            ts: ++this.wbs.ts,
            tids: tids,
            tid: this._renderer.hit.hitTask?this._renderer.hit.hitTask.id:null
        }]);
        */
    }

    protected onDragStart(rc: CanvasHostRendererCtx, hit: CanvasHostHitResult) {
        //console.log("onDragStart");
        this._renderer.setMeta({});
    }

    protected onDragging(rc: CanvasHostRendererCtx, hit: CanvasHostHitResult, hitStart: CanvasHostHitResult) {
        //console.log("onDragging "+JSON.stringify([hit.hitStripeX, hit.hitStripeY]));
        const y = hit.hitStripe ? Math.floor(hit.hitStripeY / 20) * 20 : undefined;
        const h = 20;
        this._renderer.setMeta((meta) => {
            if (y !== meta.y || h !== meta.h) {
                return { ...meta, y, h };
            } else {
                return null;
            }
        });
    }

    protected override onDragEnd(rc: CanvasHostRendererCtx, dragInfo: CanvasHostRendererDragInfo | null) {
        this._renderer.setMeta({});
        const sel = this._renderer.getSel();
        const tids = sel.map((s) => s.id);
        this.props.worker.postMessage([
            "processview",
            "drag",
            {
                id: this.wbs?.id,
                ts: ++this.wbs.ts,
                tids: [], // tids,
                tid: this._renderer.hit.hitTask
                    ? this._renderer.hit.hitTask.id
                    : this._renderer.hit.hitStripe
                      ? this._renderer.hit.hitStripe.t
                      : null,
                x: this._renderer.hit.hitCanvasX,
                y: this._renderer.hit.hitCanvasY,
            },
        ]);
    }

    protected onDropEnd(
        rc: CanvasHostRendererCtx,
        pointer: CanvasHostBasePointerEvent,
        dropInfo: CanvasHostRendererDropInfo | null,
        draggedRef: HTMLElement,
    ) {}

    protected override onClick(mouse: { x: number; y: number }) {}

    protected override onTextEdited(target: number, id: number, value: string) {}

    protected override onCopyCut(cmd: "copy" | "cut") {}

    protected override onPaste(ev: CanvasHostBasePointerEvent) {}

    protected override onDeleteKey() {}

    protected override onTranslate(dx: number, dy: number) {}

    public override onHoverProcess(
        task: CanvasTaskData & CanvasHostRenderNode,
        points: { mouse: { x: number; y: number }; task: { x: number; y: number } },
    ) {}

    public override onLeaveProcess() {}
}
