import { FilterItem } from "@/components/Filter/data-access-hooks/use-filter-data";
import { DataModelFilterSavedAs } from "@/model/DataModelTypes";
import { useFilterCollection } from "@/components/Filter/data-access-hooks/use-filter-collection";
import { useLCMD } from "@/app/LCMContext";
import { Spinner } from "@fluentui/react";
import { Skeleton } from "@/components/ui/skeleton";
import { FilterCard } from "@/components/Filter/FilterV2/FilterCard";
import * as React from "react";

export function MyFilterTabView({
    onFilterSelect,
    onFilterEdit,
    onFilterDelete,
    webtrackingPrefix,
}: {
    onFilterSelect: () => void;
    onFilterEdit: (item: FilterItem) => void;
    onFilterDelete: (item: DataModelFilterSavedAs) => void;
    webtrackingPrefix?: string;
}) {
    const {
        status: { loading, loaded },
        refresh,
        selectedFilterId,
        items: filterItems,
    } = useFilterCollection();
    const LCMD = useLCMD();
    const { isLoading: isUserLoading, data: currentUserData } = LCMD.useCurrentUserId();

    if (isUserLoading) {
        return <Spinner></Spinner>;
    }

    return (
        <div className="relative flex h-[450px] w-full flex-col gap-8">
            {loading &&
                Array.from({ length: 3 }, (_, index) => (
                    <Skeleton key={`Skeleton-${index}`} className={"mb-2 h-24 w-full"}></Skeleton>
                ))}
            {loaded &&
                filterItems.map((item) => {
                    return (
                        <FilterCard
                            key={`${item.global}-${item._hid}`}
                            item={item}
                            currentUser={currentUserData.sub}
                            selected={selectedFilterId && selectedFilterId == item._hid}
                            onFilterSelect={onFilterSelect}
                            onFilterEdit={onFilterEdit}
                            onFilterDelete={onFilterDelete}
                            webtrackingPrefix={webtrackingPrefix}
                        />
                    );
                })}
        </div>
    );
}
