import React from "react";
import { intl } from "lcmd2framework";
import { Button } from "../../ui/button";
import { ArrowUpRightSquare, CheckCircle, Eye } from "lucide-react";
import { Card, CardContent, CardHeader } from "../../ui/card";
import { useUser } from "@/components/hooks/userMap.hook";
import { LcmFacepile } from "@/components/common/LcmFacepile";
import { Badge } from "@/components/ui/badge";
import { EditNameInput } from "@/components/common/EditNameInput";
import { LCMDContextBaselineDetails } from "@/app/LCMDContextTypes";
import { useLCMD } from "@/app/LCMContext";
import { getDateAndTime } from "@/components/utils/date/dateAndTime";
import { WebAppTelemetryFactory } from "@/app/services/WebAppTelemetry.service";

type BaselinesDialogCardType = {
    item: LCMDContextBaselineDetails;
    isActive?: boolean;
    onNameChange: (name: string, item: LCMDContextBaselineDetails) => void;
    onSetAsBaseline?: (item: LCMDContextBaselineDetails) => void;
    onActivateBaseline?: (item: LCMDContextBaselineDetails) => void;
    showSetAsBaselineButton?: boolean;
};

export function BaselinesDialogCard({
    item,
    isActive = false,
    onNameChange,
    onSetAsBaseline,
    onActivateBaseline,
    showSetAsBaselineButton,
}: BaselinesDialogCardType) {
    const canOpenBaseline = false;
    const currentUser = useUser(item.u);
    const LCMD = useLCMD();
    const date = getDateAndTime(item?.d * 1000 * 60)?.toString() || "";

    return (
        <Card className="h-[100px] rounded-2xl border-2 border-gray-200 bg-slate-50 p-3">
            <CardHeader className="mb-1">
                {item?.name ? (
                    <div className="flex flex-row items-center justify-between">
                        <EditNameInput
                            name={item.name}
                            saveName={
                                showSetAsBaselineButton
                                    ? (value) => {
                                          onNameChange(value, item);
                                      }
                                    : undefined
                            }
                            onResetText={() => {
                                onNameChange(item.name, item);
                            }}
                            inputClassName="h-6"
                            className="max-w-[700px]"
                        />

                        <div className="text-sm font-normal text-zinc-500">{date}</div>
                    </div>
                ) : (
                    <EditNameInput
                        triggerValue={date}
                        name={item.name}
                        saveName={
                            showSetAsBaselineButton
                                ? (value) => {
                                      onNameChange(value, item);
                                  }
                                : undefined
                        }
                        onResetText={() => {
                            onNameChange("", item);
                        }}
                        inputClassName="h-6"
                    />
                )}
            </CardHeader>
            <CardContent className="flex items-center justify-between">
                <div className="my-2 flex items-center gap-3">
                    {currentUser && <LcmFacepile persona={[currentUser]} />}
                    <div>
                        <p className="text-sm font-normal leading-4 text-zinc-950">
                            {currentUser?.meta.firstName} {currentUser?.meta.lastName}
                        </p>
                        <p className="text-[10px] font-normal leading-3 text-gray-600">
                            {"import" === item.u ? intl.get("baseline.import") : item.u ? currentUser?.email : null}
                        </p>
                    </div>
                </div>
                {isActive ? (
                    <Badge
                        className="flex h-10 items-center rounded bg-green-600 px-3 hover:bg-green-600"
                        onClick={() => {
                            onActivateBaseline(item);
                        }}
                    >
                        <CheckCircle size={16} className="mr-2" />
                        <div className="text-sm font-medium">{intl.get("baselines.active")}</div>
                    </Badge>
                ) : (
                    <div className="flex">
                        {canOpenBaseline && (
                            <Button
                                variant="ghost"
                                size="icon"
                                onClick={() => {
                                    LCMD.getProjectDetails((error, data) => {
                                        const revUrl = new URL(window.location.href);
                                        revUrl.searchParams.append("project", data.pid.toString());
                                        revUrl.searchParams.append("revId", item.i.toString(16));
                                        window.location.href = revUrl.toString();
                                    });
                                }}
                            >
                                <Eye size={16} className="text-zinc-400" />
                            </Button>
                        )}
                        {showSetAsBaselineButton && (
                            <Button
                                variant="secondary"
                                className="hover:bg-slate-150 flex h-10 items-center rounded bg-slate-100 px-3"
                                onClick={() => {
                                    WebAppTelemetryFactory.trackEvent("baseline-selected");
                                    onSetAsBaseline(item);
                                }}
                            >
                                <ArrowUpRightSquare size={16} className="mr-2" />
                                <div className="text-sm font-medium text-black">{intl.get("baselines.schedule")}</div>
                            </Button>
                        )}
                    </div>
                )}
            </CardContent>
        </Card>
    );
}
