﻿import { assert, CanvasStripeData, CanvasTaskData, DigitalPlanningBoardConfig, jsonToError } from "lcmd2framework";
import {
    CalendarGrid,
    CanvasNotificationData,
    CanvasViewConst,
    CanvasViewMeta,
    DataModel,
    VCONST0,
    WorkerSession,
} from "../model/DataModel";
import { generateCanvasCalendarIntl } from "./GlobalHelperFluentUI";
import { applyPatch } from "../model/GlobalHelper";
import { getParticleContext } from "./api/ParticleContext";
import { initWorkerLink } from "../app/worker/workerLink";
import { SERVICES } from "../model/services";
import { CanvasMeta } from "./components/Canvas/Canvas";
import { AuthTokenHandler } from "./AuthTokenHandler";
import { showScreenOfDeath } from "./ScreenOfDeath";
import { useCanvasStore, usePresentingMode } from "@/app/store/canvasStore";
import { renameUserJourneyStore, useUserJourneyStore } from "@/app/store/userJourneyStore";
import { useConflictStore } from "@/app/store/conflictsStore";
import { useBaselineStore } from "@/app/store/baselineStore";

interface CanvasCache {
    meta: CanvasMeta;
    stripes: CanvasStripeData[];
    tasks: CanvasTaskData[];
    grid: CalendarGrid;
    filter: any;
    wb: any; // whiteboards
    viewConst: CanvasViewConst;
    viewMeta: CanvasViewMeta;
    header: any;
    notifications: CanvasNotificationData[];
    trades: any[];
    errorState: { error?: Error; rt?: Error } | null;
}

const CANVASCACHE0 = (viewConst: CanvasViewConst) => ({
    meta: null,
    stripes: [],
    tasks: [],
    grid: null,
    filter: null,
    wb: null, // whiteboards
    viewConst,
    viewMeta: null,
    header: null,
    notifications: [],
    trades: [],
    errorState: null,
});

export type MainWorkerMessage = [string, any] | [string, any, any] | [string, any, any, any];
export type MainWorkerMessageHandler = (msg: MainWorkerMessage) => void | "unregister";

export class MainWorkerPipe {
    config: DigitalPlanningBoardConfig<any> = {};
    worker: ServiceWorker | Worker = null;
    workerTimer: any = null;
    handlers: MainWorkerMessageHandler[] = [];
    inDispatch: boolean | any[] = false;
    cbsId = 0;
    cbs: { [x: number]: (data) => void } = {};
    dbRef: HTMLIFrameElement = undefined;
    ppSyncTS: number = 0;
    ppSyncDate: number = 0;
    wbSyncTS: number = 0;
    wbSyncDate: number = 0;

    canvas: CanvasCache = CANVASCACHE0(VCONST0);
    wb: CanvasCache = CANVASCACHE0(null);

    auth: {
        auth_token?: string;
        params?: {
            sub?: string;
            lic?: {
                edit?: 0 | 1;
            };
            email?: string;
        };
        auth_result?: {
            email?: string;
            ret_auth?: number;
            lang?: string;
            meta?: any;
            req?: string;
            sub?: string;
            warmupId?: string;
        };
        details?: {
            sub: string;
            email: string;
            lang: string;
            lic: unknown;
            meta: {
                firstName: string;
                lastName: string;
            };
            projects: string[];
        };
    } | null = null;
    warmupId: string = undefined;
    userMeta: { [name: string]: any } = undefined;
    nav: any;
    warmup_result: {
        sub?: string;
        req?: string;
        promo?: {
            name?: string;
            edit?: number;
            start: number;
            end: number;
        };
    } | null = null;

    constructor(config: DigitalPlanningBoardConfig<any>) {
        this.config = config;
    }

    public isLicTrial() {
        //const edit = this.auth?.params?.lic?.edit;
        return !this.isLicEditing();
    }

    public isLicEditing() {
        //const edit=gfu4(SERVICES.LICENSE_OVERRIDE as any, this.auth?.params?.lic?.edit, this.warmup_result?.promo?.edit, 0);
        //return (edit>=1);
        return true;
    }

    public forceSync(target?: string) {
        if (!target || "dailyboard" !== target) {
            this.postMessage([
                "wb",
                "sync",
                {
                    cb: this.registerCallback(() => {}),
                },
            ]); // maybe with UI?
        }
        if (this.dbRef?.contentWindow && (!target || "dailyboard" === target)) {
            this.dbRef.contentWindow.postMessage(["lcmd", "sync"], "*");
        }
    }

    public static replaceHash(props) {
        let href = window.location.href;
        const i_hash = href.indexOf("#");
        if (i_hash >= 0) {
            href = href.substring(0, i_hash);
        }
        const hash = Object.getOwnPropertyNames(props)
            .filter((n) => null !== props[n] && undefined !== props[n])
            .map((n) => {
                return [n, props[n]].join("=");
            })
            .join("&");
        href = [href, "#", hash].join("");
        window.history.replaceState({}, null, href);
    }

    public static findTaskIndex(tasks: CanvasTaskData[], tid: number) {
        if (!VCONST0.forceCanvas) {
            let i = 0;
            let j = tasks.length;
            while (i < j) {
                const k = i + Math.floor((j - i) / 2);
                const cmp = tid - tasks[k].id;
                if (cmp < 0) {
                    j = k;
                } else if (cmp > 0) {
                    i = k + 1;
                } else {
                    return k;
                }
            }
            return -1;
        } else {
            // tasks are sorted by stripe
            return tasks.findIndex((t) => t.id === tid);
        }
    }

    public static findTask(tasks: CanvasTaskData[], tid: number) {
        const i = MainWorkerPipe.findTaskIndex(tasks, tid);
        return i < 0 ? null : tasks[i];
    }

    public gotoPos(
        pos: {
            left: number;
            right: number;
            top: number;
            bottom: number;
            option?: "center" | "end" | "nearest" | "start";
        },
        view: {
            colHeaderHeight: number;
            rowHeaderWidth: number;
            scale: number;
            inf2Rows: number;
            inf2ColsBefore: number;
        },
        divRefs: {
            //TODO: add SH
        },
        option?: "center" | "end" | "nearest" | "start",
    ) {
        const C = this.canvas.viewConst;
        const SH = document.getElementById("SH") as HTMLDivElement;
        if (true && SH && C) {
            const s = view.scale;
            SH.style.visibility = null; // default from style
            SH.style.left = (pos.left + view.inf2ColsBefore) * C.colPx * s + "px";
            SH.style.top = (pos.top + view.inf2Rows) * C.rowPx * s + "px";
            SH.style.width = view.rowHeaderWidth + (pos.right - pos.left) * C.colPx * s + "px";
            SH.style.height = view.colHeaderHeight + (pos.bottom - pos.top) * C.rowPx * s + "px";
            option = option || pos.option || "nearest";
            SH.scrollIntoView({
                block: option,
                inline: option,
            });
            SH.style.left = "0px";
            SH.style.top = "0px";
            SH.style.width = "0px";
            SH.style.height = "0px";
        }
    }

    public gotoTask(
        tid: number,
        view: {
            colHeaderHeight: number;
            rowHeaderWidth: number;
            scale: number;
            inf2Rows: number;
            inf2ColsBefore: number;
        },
        divRefs: {
            //TODO: add SH
        },
    ) {
        const C = this.canvas.viewConst;
        const SH = document.getElementById("SH") as HTMLDivElement;
        if (true && SH && C) {
            const task = MainWorkerPipe.findTask(this.canvas.tasks, tid);
            if (task) {
                const s = view.scale;
                const dx = view.rowHeaderWidth;
                const dy = view.colHeaderHeight;
                SH.style.visibility = null; // default from style
                SH.style.left = (task.left + view.inf2ColsBefore * C.colPx) * s + "px";
                SH.style.top = (task.top + view.inf2Rows * C.rowPx) * s + "px";
                SH.style.width = view.rowHeaderWidth + "px";
                SH.style.height = view.colHeaderHeight + "px";
                SH.scrollIntoView({
                    block: "nearest",
                    inline: "nearest",
                });
                SH.style.left = "0px";
                SH.style.top = "0px";
                SH.style.width = "0px";
                SH.style.height = "0px";
                this.dispatchMessage([
                    "didGotoTask",
                    {
                        tid: tid,
                    },
                ]);
            }
        }
    }

    private _handleCanvasMessage(cache: CanvasCache, canvas: any) {
        let event = null;
        if (canvas.viewConstPatch) {
            event = Object.assign(event || {}, {
                view0: cache.viewConst,
                view: canvas.viewConstPatch,
            });
            cache.viewConst = canvas.viewConstPatch;
        }
        if (canvas.viewMetaPatch) {
            cache.viewMeta = canvas.viewMetaPatch;
        }
        canvas.viewConst = cache.viewConst;
        canvas.viewMeta = cache.viewMeta;
        const stripes = cache.stripes;
        const tasks = cache.tasks;
        cache.stripes = applyPatch<CanvasStripeData>(stripes, canvas?.stripesPatch);
        cache.tasks = applyPatch<CanvasTaskData>(cache.tasks, canvas?.tasksPatch);
        canvas.stripes = cache.stripes || null;
        canvas.tasks = cache.tasks || null;
        if (canvas?.gridPatch) {
            const grid = CalendarGrid.createGridFromPatch(canvas.gridPatch);
            const header = DataModel.generateCalendarHeader(
                cache.viewConst,
                grid,
                canvas.start,
                canvas.cols,
                canvas.gridPatch.meta,
                generateCanvasCalendarIntl(),
                canvas?.renderProjectWeeks,
            );
            event = Object.assign(event || {}, {
                grid0: cache.grid,
                grid: grid,
                header0: cache.header,
                header: header,
            });
            cache.grid = grid;
            cache.header = header;
        }
        if (undefined !== canvas?.filterPatch) {
            event = Object.assign(event || {}, {
                filter0: cache.filter,
                filter: canvas.filterPatch,
            });
            cache.filter = canvas.filterPatch;
        }
        if (undefined !== canvas?.wbPatch) {
            cache.wb = canvas.wbPatch;
        }
        if (undefined !== canvas?.meta) {
            cache.meta = canvas.meta;
        }
        if (undefined !== canvas?.tradesPatch) {
            cache.trades = canvas.tradesPatch;
        }
        if (undefined !== canvas?.errorPatch) {
            const errorStateError = canvas.errorPatch?.error
                ? jsonToError(canvas.errorPatch?.error)
                : null === canvas.errorPatch || null === canvas.errorPatch?.error
                  ? undefined
                  : cache.errorState?.error;
            const errorStateRT = canvas.errorPatch?.rt
                ? jsonToError(canvas.errorPatch?.rt)
                : null === canvas.errorPatch || null === canvas.errorPatch?.rt
                  ? undefined
                  : cache.errorState?.rt;
            const errorState =
                errorStateError !== cache.errorState?.error || errorStateRT !== cache.errorState?.rt
                    ? errorStateError || errorStateRT
                        ? {
                              error: errorStateError,
                              rt: errorStateRT,
                          }
                        : null
                    : cache.errorState;
            cache.errorState = errorState;
            console.error(cache.errorState);
        }
        canvas.errorState = cache.errorState;
        canvas.filter = cache.filter;
        canvas.wb = cache.wb;
        canvas.grid = cache.grid;
        canvas.header = cache.header;
        canvas.trades = cache.trades;
        //@TODO cache gpaPreview
        if (event) {
            canvas.event = event;
        }
        if (undefined !== canvas.rejectedOps && "function" === typeof this.config?.onRejectedOps) {
            this.config.onRejectedOps(getParticleContext(), canvas.rejectedOps);
        }

        useUserJourneyStore.getState().actions.setCollapsedAreaList(canvas.collapsedAreaList);
    }

    private _handleNotificationMessage(cache: CanvasCache, event: any) {
        const updates = event.updates;
        const notifications = cache.notifications.slice();
        notifications.sort((a, b) => a.nid.localeCompare(b.nid));
        // merge updates
        let i_notifications = 0;
        const n_updates = updates.length;
        for (let i_update = 0; i_update < n_updates; i_update++) {
            const n_notifications = notifications.length;
            while (i_notifications < n_notifications && notifications[i_notifications].nid < updates[i_update].nid)
                i_notifications++;
            if (i_notifications < n_notifications && notifications[i_notifications].nid === updates[i_update].nid) {
                // in-place
                notifications[i_notifications++] = updates[i_update];
            } else {
                // new
                assert(i_notifications === n_notifications || notifications[i_notifications].nid > updates[i_update]);
                notifications.splice(i_notifications++, 0, updates[i_update]);
            }
        }
        notifications.sort((a, b) => a._ - b._);
        event.notifications = notifications;
    }

    private onMessage = function (this: MainWorkerPipe, event: MessageEvent) {
        //console.debug(JSON.stringify(event.data[0]));
        if (Array.isArray(event.data) && event.data.length >= 2) {
            if ("error" === event.data[0]) {
                const e = event.data[1];
                showScreenOfDeath(e, { message: "(empty)" });
            } else if ("shutdown" === event.data[0]) {
                event.target.removeEventListener("message", this.onMessage);
                (event.target as Worker).terminate();
            } else if ("pong" === event.data[0]) {
                if (this.workerBusy) {
                    this.workerBusy = false;
                    this.dispatchMessage(["worker", "busy", this.workerBusy]);
                }
                this.pingSent = 0;
            } else {
                if ("canvas" === event.data[0]) {
                    const msg = event.data[1] as {
                        sync?: {
                            syncCommited: { ofs: number };
                            wb?: { syncCommited: { ofs: number }; date: number };
                            date: number;
                        };
                    };
                    if (msg.sync && this.worker) {
                        const canvasStore = useCanvasStore.getState();

                        this.ppSyncTS = msg.sync.syncCommited.ofs;
                        this.ppSyncDate = msg.sync.date;
                        canvasStore.setProcessPlanTimestamp(msg.sync.syncCommited.ofs);

                        if (msg.sync.wb) {
                            this.wbSyncTS = msg.sync.wb.syncCommited.ofs;
                            this.wbSyncDate = msg.sync.wb.date;
                            canvasStore.setWhiteboardTimestamp(msg.sync.wb.syncCommited.ofs);
                        }
                    }
                    this._handleCanvasMessage(this.canvas, msg);
                } else if ("wb" === event.data[0]) {
                    this._handleCanvasMessage(this.wb, event.data[1] as any);
                } else if ("notifications" === event.data[0]) {
                    this._handleNotificationMessage(this.canvas, event.data[1]);
                } else if ("framework" === event.data[0] && "closed" === event.data[1]) {
                    this.wb = CANVASCACHE0(null);
                    this.wbSyncTS = 0;
                    this.wbSyncDate = 0;
                }
                this.dispatchMessage(event.data as MainWorkerMessage);
            }
        } else {
            console.warn("Invalid Message " + JSON.stringify(event.data));
        }
    }.bind(this);

    dispatchMessage(this: MainWorkerPipe, msg: MainWorkerMessage) {
        if ("auth_token" === msg[0]) {
            this.auth = msg[1];
            this.auth.params = {};
            try {
                this.auth.params =
                    AuthTokenHandler.parseNotValidateAuthToken(this.auth?.auth_token) || this.auth.params;
            } catch (e) {
                console.warn("bad token");
            }
            const userMeta = msg[1].auth_result?.meta || msg[1].details?.meta; // warmup response // login response
            if (userMeta) {
                // warmup response
                this.userMeta = userMeta;
                if (this.worker) {
                    assert(false); //@TODO update userMeta in worker
                }
            }
            if (false && "development" === process.env.NODE_ENV && this.auth?.auth_token && this.auth?.params) {
                this.auth.params.lic = {
                    edit: 1,
                };
            }
        } else if ("framework" === msg[0] && "nav" === msg[1]) {
            this.nav = msg[2];
            //@TODO MainWorkerPipe.replaceHash(msg[2]);
        } else if ("cb" === msg[0]) {
            const cb = this.cbs[msg[1]];
            if (cb) {
                delete this.cbs[msg[1]];
                cb(msg[2]);
            }
            return; // do not deliver the message
        }
        //assert(false===this.inDispatch);
        const doCleanup = false === this.inDispatch;
        this.inDispatch = this.inDispatch || true;
        let n = this.handlers.length;
        for (let i = 0; i < n; ) {
            if ("unregister" === this.handlers[i](msg)) {
                this.handlers.splice(i, 1);
                n--;
            } else {
                i++;
            }
        }
        if (doCleanup) {
            do {
                const inDispatch = this.inDispatch;
                this.inDispatch = false;
                if (Array.isArray(inDispatch)) {
                    // perform pending ops...
                    for (let i = 0; i < inDispatch.length; i++) {
                        inDispatch[i](this.handlers);
                    }
                }
            } while (Array.isArray(this.inDispatch));
        }
    }

    postMessage(this: MainWorkerPipe, msg: MainWorkerMessage, transfer?: Transferable[]) {
        if (this.worker) {
            this.worker.postMessage(msg, transfer);
        }
    }

    registerHandler(this: MainWorkerPipe, handler: MainWorkerMessageHandler) {
        if (this.inDispatch) {
            this.inDispatch = Array.isArray(this.inDispatch) ? this.inDispatch : [];
            this.inDispatch.push(() => this.handlers.push(handler));
        } else {
            this.handlers.push(handler);
        }
    }

    unregisterHandler(this: MainWorkerPipe, handler: MainWorkerMessageHandler) {
        if (this.inDispatch) {
            this.inDispatch = Array.isArray(this.inDispatch) ? this.inDispatch : [];
            this.inDispatch.push(() => {
                const ret = this.handlers.indexOf(handler);
                if (ret >= 0) {
                    this.handlers.splice(ret, 1);
                }
            });
        } else {
            const ret = this.handlers.indexOf(handler);
            if (ret >= 0) {
                this.handlers.splice(ret, 1);
            }
        }
    }

    registerCallback(cb: (data: any) => void): number {
        this.cbsId++;
        this.cbs[this.cbsId] = cb;
        return this.cbsId;
    }

    shutdownWorker(this: MainWorkerPipe) {
        if (this.worker) {
            this.worker.postMessage(["shutdown", {}]);
            clearInterval(this.workerTimer);
        }
        this.worker = null;
        this.workerTimer = null;
    }

    private pingSent: number = 0;
    private lastTrigger: number = 0;
    private workerBusy: boolean = false;
    private onTimer = function (this: MainWorkerPipe) {
        const now = Date.now();
        if (!this.pingSent) {
            this.pingSent = now;
            this.worker.postMessage(["ping", {}]);
        } else if (now >= this.pingSent + 1000 * 5) {
            if (!this.workerBusy) {
                console.log("WORKER BUSY...");
                this.workerBusy = true;
                this.dispatchMessage(["worker", "busy", this.workerBusy]);
            }
        }
        /*if (this.ppSyncDate>0 && !document.hidden && this.ppSyncDate+60*1000<now) {
            console.log("Out of sync for "+(now-this.ppSyncDate)+" ms");
            this.forceSync();

        }*/
        if (this.lastTrigger > 0 && this.lastTrigger + 5 * 60 * 1000 < now) {
            console.log("TAB sleeping?");
            this.forceSync();
        }
        this.lastTrigger = now;
    }.bind(this);

    async loadProject(this: MainWorkerPipe, puid: string, sandbox: string | WorkerSession, rev?: number) {
        this.shutdownWorker();
        this.worker = new Worker(new URL("../app/worker/worker.ts", import.meta.url), {
            type: "module",
        });

        initWorkerLink(this.worker);
        this.canvas = CANVASCACHE0(VCONST0);
        this.wb = CANVASCACHE0(null);
        this.worker.addEventListener("message", this.onMessage);
        this.worker.addEventListener("error", (event) => {
            console.error("worker error");
            console.error(event);
            showScreenOfDeath(event.error, event);
        });
        if ("string" !== typeof this.warmupId) {
            //@TODO: remove me: fix for old backend
            this.warmupId = "";
        }
        assert("string" === typeof this.warmupId);
        await renameUserJourneyStore(this.auth.params.sub, puid);

        useCanvasStore.getState().reset();
        useConflictStore.getState().actions.reset();
        useBaselineStore.getState().reset();

        if (this.nav?.processId) {
            useCanvasStore.getState().setProcessId(parseInt(this.nav?.processId));
        }

        if (this.nav?.initialSidebarView) {
            useCanvasStore.getState().setInitialSidebarView(this.nav?.initialSidebarView);
        }

        if (!rev && this.nav?.revId) {
            rev = this.nav.revId;
        }

        this.worker.postMessage([
            "init",
            {
                warmupId: this.warmupId,
                userMeta: this.userMeta,
                SERVICES: SERVICES,
                puid: puid,
                key: sandbox,
                rev: rev,
                auth: this.auth,
            },
            {
                view: useUserJourneyStore?.getState()?.userView,
                filter: useUserJourneyStore?.getState()?.filter,
                collapsedAreaList: useUserJourneyStore?.getState().collapsedAreaList,
                options: {
                    showProjectWeeks: useUserJourneyStore?.getState()?.showProjectWeeks,
                    taktZoneImages: useUserJourneyStore?.getState()?.areaAttachments,
                    showTaktzones: useUserJourneyStore?.getState()?.showTaktZones,
                    showNonWorkingDays: useUserJourneyStore?.getState()?.showNonWorkingDays,
                    scaleSettings: useUserJourneyStore?.getState()?.scaleSettings,
                    showStatusBar: useUserJourneyStore?.getState()?.showStatusBar,
                },
            },
        ]);
        this.workerTimer = setInterval(this.onTimer, 1 * 1000);
    }
}
