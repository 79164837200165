import { ScrollablePane, Spinner, SpinnerSize, Stack, StackItem, Sticky, StickyPositionType } from "@fluentui/react";
import { intl } from "@/legacy/GlobalHelperReact";
import { NewChangesView } from "../../NewChangesView";
import { HistoryList, Source } from "./HistoryList";
import React, { useContext, useEffect, useState } from "react";
import { HistoryMeta } from "../../../../app/interface";
import { HistoryHeaderButtons } from "../../Shared/Shared";
import { changesPanelClassNames } from "../../Shared/styles";
import { ChangesHeader } from "../../Shared/ChangesHeader";
import { ChangesPanelActionTypes, CPContext } from "../../Shared/CPContext";
import { useLCMD } from "../../../../app/LCMContext";
import { PPFilterWarning } from "../../../History/PPFilterWarning";
import { SelectionMode } from "@fluentui/utilities";
import { LoadMoreButton } from "../../../common/LoadMoreButton/LoadMoreButton";

// function TaskEvent(props: { event: LCMDChangesPanelChangesTaskEvent }) {
//     switch (props.event.type) {
//         case 0:
//             return <div>&nbsp;&nbsp;{props.event.name}:{props.event.c}</div>;
//
//         case 1:
//             return <div>&nbsp;&nbsp;Comment:{props.event.c.toString()}</div>;
//         default:
//             return <div></div>;
//     }
// }

// function Task(props: { task: LCMDChangesPanelChangesTask, ctx, conflictMode: boolean }) {
//     const LCMD = useLCMD();
//
//     const commentsContent = props.task.events.map((event) => <TaskEvent key={event._} event={event}/>);
//
//     return <Stack
//         horizontal
//         tokens={{
//             childrenGap: 25,
//             padding: "0px 15px"
//         }}
//     >
//         <Stack>
//             <div
//                 onClick={() => {
//                     LCMD.executeCommand({
//                         cmd: "goto",
//                         pid: [props.task.tid]
//                     })
//                 }}
//             >
//                 <ActivityItem
//                     styles={changesPanelStyles.ActivityItem}
//                     activityDescription={<>
//                         <span>Task </span>
//                         <span
//                             className={changesPanelClassNames.taskText}
//                         >
//                             {props.task.name || ""} (#{props.task.tid})
//                         </span>
//                         <span>:</span>
//                     </>}
//                     comments={commentsContent}
//                 />
//             </div>
//         </Stack>
//     </Stack>;
// }

export function HistoryView() {
    const LCMD = useLCMD();
    // const parentRef = useRef()
    const { state, dispatch } = useContext(CPContext);
    const { changes, onDismiss, historyFilter, showConflicts } = state;
    const changesCounter = changes.counter;
    const [conflictCount, setConflictCount] = useState(0);
    const [historyChanges, setHistoryChanges] = useState<HistoryMeta[]>([]);
    const [loaded, setLoaded] = useState(false);
    // loader for load more button
    const [loading, setLoading] = useState(false);
    const [isFirstLoad, setIsFirstLoad] = useState(false);
    // const [displayedLoadMoreButton, setDisplayedLoadMoreButton ] = useState<boolean>(false);

    useEffect(() => {
        // load history for first time
        if (state.changes.history.length === 0) {
            loadHistory(1);
        }

        //WebAppTelemetryFactory.trackPageView("history-view");
    }, []);

    useEffect(() => {
        if (!changes.tasks) {
            setConflictCount(0);
            return;
        }
        const tasksWithConflicts = changes.tasks.filter((task) => task.conflict != undefined);
        setConflictCount(tasksWithConflicts.length);
    }, [changes.tasks]);

    useEffect(() => {
        if (changes?.history) {
            if (historyFilter?.size > 0) {
                const selectedFilters = Array.from(historyFilter.values()).flat(2);
                const filteredChanges = changes.history.filter((hC) => selectedFilters.includes(hC.action));
                sortChangesByDate(filteredChanges);
                setHistoryChanges(filteredChanges);
            } else {
                const filteredChanges = JSON.parse(JSON.stringify(changes.history));
                sortChangesByDate(filteredChanges);
                setHistoryChanges(filteredChanges);
            }
            setLoaded(true);
        }
    }, [changes.history, historyFilter]);

    useEffect(() => {
        if (changesCounter == 1 && changes.tasks.length < 1) {
            setIsFirstLoad(true);
        }
    }, [changes.counter]);

    const sortChangesByDate = (changes: HistoryMeta[]) => {
        changes.sort((a, b) => {
            return b.events[0].date - a.events[0].date;
        });
    };
    // useEffect(() => {
    //     rowVirtualizer.scrollToIndex(0);
    //     rowVirtualizer.measure();
    // }, [tasks])

    // const ctx = useMemo(() => {
    //     const ret = {
    //         LCMD,
    //         changes: props.changes,
    //         onConflictButtonClick: null
    //     };
    //     ret.onConflictButtonClick = onConflictButtonClick.bind(ret);
    //     return ret;
    // }, [LCMD, props.changes]);

    function handleRefresh() {
        dispatch({
            type: ChangesPanelActionTypes.SET_HISTORY,
            history: [],
            currentPage: 0,
            nextPage: 1,
            _: state.changes._,
        });
        loadHistory(1);
        setIsFirstLoad(false);
    }

    function loadHistory(page: number) {
        setLoading(true);
        LCMD.getHistory(page).then((histPage) => {
            if (histPage.data.length > 0 && histPage.meta.current <= state.page.current) {
                // overwrite hist because page returned was smaller than pages loaded in view
                dispatch({
                    type: ChangesPanelActionTypes.SET_HISTORY,
                    history: histPage.data || [],
                    currentPage: histPage.data?.length ? histPage.meta.current : 0,
                    nextPage: histPage.meta.next,
                    _: state.changes._,
                });
            } else if (histPage.data.length > 0) {
                dispatch({
                    type: ChangesPanelActionTypes.SET_HISTORY,
                    history: state.changes.history.concat(histPage.data) || [],
                    currentPage: state.changes.history.length || histPage.data?.length ? histPage.meta.current : 0,
                    nextPage: histPage.meta.next,
                    _: state.changes._,
                });
            }

            setLoading(!histPage.meta.next);
        });
    }
    return (
        <>
            {loaded ? (
                <Stack verticalAlign={"space-between"}>
                    <StackItem>
                        <ScrollablePane
                            className={changesPanelClassNames.container}
                            styles={{ contentContainer: { paddingBottom: 50 } }}
                        >
                            <Sticky stickyPosition={StickyPositionType.Header}>
                                <ChangesHeader
                                    title={<>{intl.get("ChangesPanel.headers.changes")}</>}
                                    onCloseButtonChange={onDismiss}
                                />

                                <HistoryHeaderButtons
                                    showConflictsOnly={false}
                                    conflictCount={conflictCount}
                                    showConflictsButton={showConflicts}
                                />
                                <NewChangesView
                                    onRefresh={handleRefresh}
                                    show={changesCounter != 0 && changes.tasks.length > 0}
                                    changesCounter={isFirstLoad ? changesCounter - 1 : changesCounter}
                                />
                            </Sticky>
                            <Stack styles={{ root: { paddingBottom: "110px" } }}>
                                <HistoryList
                                    historyChanges={historyChanges}
                                    selectionMode={SelectionMode.none}
                                    source={Source.ALL_PROCESSES}
                                    showFilter={true}
                                    onSelect={() => {}}
                                />
                            </Stack>
                            <Stack horizontalAlign={"center"}>
                                {Boolean(state.page.next) && (
                                    <LoadMoreButton
                                        loading={loading}
                                        onClick={() => {
                                            loadHistory(state.page.current + 1);
                                        }}
                                    />
                                )}
                            </Stack>

                            {/*<div ref={parentRef} style={{height: "calc(100% - 42px - 24px - 50px)", overflow: 'auto'}}>*/}
                            {/*    <div className="ListInner" style={{height: `${rowVirtualizer.totalSize}px`, width: '100%', position: 'relative',}}>*/}
                            {/*        {rowVirtualizer.virtualItems.map(virtualRow => {*/}
                            {/*            const task = tasks[virtualRow.index]*/}
                            {/*            return (<div*/}
                            {/*                key={virtualRow.index}*/}
                            {/*                ref={virtualRow.measureRef}*/}
                            {/*                style={{position: 'absolute', top: 0, left: 0, width: '100%', transform: `translateY(${virtualRow.start}px)`}}>*/}

                            {/*                <Task key={task.tid} ctx={ctx} task={task} conflictMode={showConflictsOnly}/>*/}
                            {/*            </div>)*/}
                            {/*        })}*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </ScrollablePane>
                    </StackItem>
                    <StackItem className={changesPanelClassNames.ppWarningFooter}>
                        <PPFilterWarning />
                    </StackItem>
                </Stack>
            ) : (
                <Spinner
                    style={{ marginTop: 100 }}
                    size={SpinnerSize.large}
                    label={intl.get("ChangesPanel.calculatingChanges")}
                />
            )}
        </>
    );
}
