import {
    assert,
    FrameworkError,
    FrameworkHttpError,
    generateId as _generateId,
    jsonToError,
} from "../../model/GlobalHelper";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { _deleteAttachment, _uploadAttachment } from "./DailyBoardAPI";
import * as ApiHelper from "../../model/ApiHelper";
import { DataModelFilter, DataModelFilterCollection, DataModelFilterSavedAs } from "../../model/DataModelTypes";
import { Persona } from "../SubTypes";
import { IFacepilePersona } from "@fluentui/react";
import { _handleProcessViewMsg } from "../components/Canvas/Shared/CanvasHostBase";
import { useUserMap } from "../../components/hooks/userMap.hook";
import { LCMDHookResult } from "../../app/types/LCMDHookResult";
import { SubId } from "../../app/types/SubId";
import { MainWorkerMessage, MainWorkerPipe } from "../MainWorkerPipe";
import { getWorkerLink } from "../../app/worker/workerLink";
import { isValidHttpUrl } from "@/components/Utils";
import { SubCache } from "../SubCache";
import { Sub } from "../Sub";

export type LCMDChangesPanelChanges = any; //@TODO define type
export type LCMDContextWhiteboardState = any; //@TODO define type
export type LCMDContextLibraryItemTarget = any; //@TODO define type
export type CanvasChartCallbacks = any; //@TODO define type

/**
 * @public
 */
export type LCMDContextAuthUser = any & {
    sub: string;
    email: string;
};

/**
 * @public
 */
export type LCMDContextLicenseDetails = any & {};

/**
 * @public
 */
export type LCMDContextLibraries = {
    id: number;
    name: string;
};

export interface User {
    email: string;
    ts: number;
    lic: Lic;
    lang: string;
    projects: string[];
    meta: Meta;
    sub: string;
}

export interface Lic {}

export interface Meta {
    firstName: string;
    lastName: string;
    prefs: Prefs;
}

export interface Prefs {
    showNWD: any;
    fs: any;
}

type ReasonCodeModalSettings = {
    user: {
        isreasoncodeobligatory: string;
        forcedreasoncodeusers: undefined;
        autoaddnewusers: undefined;
        reasoncodes: undefined;
    };
    global: {
        isreasoncodeobligatory: undefined;
        forcedreasoncodeusers: string;
        autoaddnewusers: string;
        reasoncodes: string;
    };
};

type Baseline = {
    name: string;
    id: string;
    date: string;
    user: string;
};

function _cardEffectmessageHandler(
    this: null,
    initialCard: { tid: number; aid: number; i: number },
    setData: any,
    setTS: any,
    setStatus: any,
    msg: MainWorkerMessage,
) {
    switch (msg[0]) {
        case "canvas":
            {
                const canvas = msg[1];
                const _ = canvas.sync.commited;
                setTS(_);
            }
            break;
        case "patch":
            {
                const patch = msg[1];
                const _ = patch.sync.commited;
                setTS(_);
            }
            break;
        case "details": {
            const td = msg[1];
            if (td?.id === initialCard.tid) {
                const i_card = td._cards.findIndex(
                    (card) => card.tid === initialCard.tid && card.aid === initialCard.aid && card.i === initialCard.i,
                );
                const card = i_card >= 0 ? td._cards[i_card] : null;
                setTS(td._);
                setData({
                    card: card,
                    i_card: i_card,
                    td: td,
                });
            }
        }
        case "framework":
            {
                switch (msg[1]) {
                    case "uploadStatus":
                        {
                            console.log("uploadStatus");
                            console.log(msg[2]);
                            const helper = msg[2].filter((s) => "attachments" === s.userKey);
                            console.log(helper);
                            setStatus(helper.length > 0 ? helper : null);
                        }
                        break;
                    case "uploadFailed":
                        {
                            console.log("uploadFailed");
                            if ("attachments" === msg[2].userKey) {
                                assert(!Array.isArray(msg[2]));
                                setStatus(msg[2]);
                            }
                        }
                        break;
                }
            }
            break;
    }
}

/**
 * @public
 */
export abstract class ParticleContext {
    public worker: any = null;
    public config: any = {};
    public wbs: any = null;
    //tasksView=null;

    private _dataViews = null;
    public registerDataView(name: string, cb: ((data) => void) | null) {
        if (cb) {
            // register
            if (null === this._dataViews) {
                this._dataViews = {
                    n: 1,
                    onMsg: function (name: string, msg: MainWorkerMessage) {
                        switch (msg[0]) {
                            case "canvas": {
                                const canvas = msg[1];
                                if (canvas.stats) {
                                    const stats = Object.getOwnPropertyNames(canvas.stats);
                                    console.log("stats", stats);
                                    for (let i = 0; i < stats.length; i++) {
                                        const name = stats[i];
                                        const stat = canvas.stats[name];
                                        const cb = this._dataViews ? this._dataViews.cbs[name] : null;
                                        if ("outdated" === stat?.view) {
                                            if (cb) {
                                                cb(stat);
                                            }
                                        } else if ("number" === typeof stat?.view?.grid_ts) {
                                            if (stat.view.grid_ts === canvas?.grid?.ts) {
                                                stat.grid = canvas?.grid;
                                                if (cb) {
                                                    cb(stat);
                                                }
                                            } else {
                                                //out-of-bound
                                            }
                                        } else {
                                            // no grid needed
                                            if (cb) {
                                                cb(stat);
                                            }
                                        }
                                    }
                                    if (
                                        this._dataViews &&
                                        this._dataViews.cbs &&
                                        this._dataViews.cbs["dashboard.reason-codes"]
                                    ) {
                                        this._dataViews.cbs["dashboard.reason-codes"]();
                                    }
                                }
                                break;
                            }
                        }
                    }.bind(this, name),
                    cbs: {
                        [name]: cb,
                    },
                };
                this.worker.registerHandler(this._dataViews.onMsg);
            } else {
                assert(!this._dataViews.cbs[name]); // registered twice?
                this._dataViews.n++;
                this._dataViews.cbs[name] = cb;
            }
        } else {
            // unregister
            assert(this._dataViews?.n > 0 && this._dataViews.cbs[name]); // not registered?
            this.worker.postMessage([
                "stats",
                "view",
                {
                    name: name,
                    scale: null, // unregister
                },
            ]);
            delete this._dataViews.cbs[name];
            this._dataViews.n--;
            if (0 === this._dataViews.n) {
                this.worker.unregisterHandler(this._dataViews.onMsg);
                this._dataViews = null;
            }
        }
    }

    public uploadAttachments(
        card: { tid: number; aid: number; i: number } | {},
        files: FileList | File[],
        userKey?: string | number,
        cb?: number,
    ) {
        _uploadAttachment(this.worker, card, files, userKey, cb);
    }

    public deleteAttachments(card: { tid: number; aid: number; i: number } | {}, blobIds: string[]) {
        _deleteAttachment(this.worker, card, blobIds);
    }

    public getAttachmentUrl(attachment: { blobId: string; contentType?: string }): string {
        return ApiHelper.getAttachmentUrl(attachment);
    }

    private _authUserCache: any = undefined;
    public getAuthUser(
        cb: (error: FrameworkError, user: LCMDContextAuthUser) => void,
        opt?: { forceSync?: boolean; sync_lic?: boolean },
    ) {
        if (this.worker.auth?.auth_token && this.worker.auth?.params?.sub) {
            if (!opt?.forceSync && this._authUserCache) {
                cb(null, this._authUserCache);
            } else {
                ApiHelper.getSub(
                    this.worker.auth.auth_token,
                    this.worker.auth.params.sub,
                    (error, result) => {
                        if (error) {
                            cb(error, null);
                        } else {
                            // console.log(result);
                            if (this.worker && result.result?.meta) {
                                this.worker.postMessage(["canvas", "user-meta", result.result?.meta]);
                            }
                            this._authUserCache = { ...result.result, sub: result.sub };
                            cb(null, this._authUserCache);
                        }
                    },
                    {
                        sync_lic: opt?.sync_lic,
                    },
                );
            }
        } else {
            cb(new FrameworkError("error.fatal"), null);
        }
    }

    public useCurrentUserId() {
        const [hookState, setHookState] = useState<LCMDHookResult<{ sub: SubId }>>({
            isLoading: true,
            isError: false,
            error: undefined,
            data: undefined,
        });

        useEffect(() => {
            this.getAuthUser((error, user: User) => {
                if (error) {
                    setHookState({ ...hookState, isError: true, error });
                    return;
                }
                setHookState({ ...hookState, isLoading: false, data: { sub: user.sub as SubId } });
            });
        }, []);

        return hookState;
    }

    public getUser(sub: string, cb: (result: any) => void) {
        SubCache.getSub(this.worker.auth?.auth_token, sub, cb);
    }

    public getPersonas(sub: string[], cb: (personas: IFacepilePersona[]) => void) {
        SubCache.getPersonas(this.worker.auth?.auth_token, sub, cb);
    }

    public updateUserMeta(
        meta: any,
        cb: (error: FrameworkError, result: { email?: string; meta?: { [name: string]: any } }) => void,
    ) {
        if (this.worker.auth?.auth_token) {
            ApiHelper.updateMeta(this.worker.auth.auth_token, meta, (error, result) => {
                SubCache.invalidate(this.worker.auth?.auth_result?.sub); // invalidate cache
                if (error) {
                    cb(error, null);
                } else {
                    this.worker.userMeta = result?.meta;
                    this._authUserCache = { ...this._authUserCache, meta: result?.meta };
                    this.worker.postMessage(["canvas", "user-meta", result?.meta]);
                    cb(null, result);
                }
            });
        } else {
            cb(new FrameworkError("error.fatal"), null);
        }
    }

    // @todo: refactor typings to be more clear
    setSettingsProps(props: { [prop: string]: any }, cb: () => void) {
        this.worker.postMessage([
            "settings",
            "set",
            {
                props,
                cb: this.worker.registerCallback(() => {
                    if (cb) {
                        cb();
                    }
                }),
            },
        ]);
    }

    useSettingsEffect(props: string[], cb: (props: any) => void, deps: React.DependencyList) {
        const _cb = useCallback(cb, deps);
        useEffect(() => {
            if (this.worker) {
                const wrapper = {
                    cb,
                };
                this.worker.postMessage([
                    "settings",
                    "get",
                    {
                        props,
                        cb: this.worker.registerCallback((props) => {
                            if (wrapper.cb) {
                                wrapper.cb(props);
                            }
                        }),
                    },
                ]);
                return () => {
                    wrapper.cb = null;
                };
            }
        }, [_cb]);
    }

    /**
     * Hook to get and set ReasonCode Settings
     */
    useReasonCodeSettings() {
        const userMap = useUserMap();
        const [updateRef, setUpdateRef] = useState(Symbol());
        const [hookState, setHookState] = useState<
            LCMDHookResult<{
                forceNewUsersToSetReasonCodes: boolean;
                userList: SubId[];
                isReasonCodeObligatory: boolean;
                reasonCodes: any[];
            }>
        >({
            isLoading: true,
            isError: false,
            error: undefined,
            data: undefined,
        });

        useEffect(() => {
            setUpdateRef(Symbol());
        }, [userMap]);
        const saveReasonCodeSettings = async ({
            forceNewUsersToSetReasonCodes,
            userList,
            isReasonCodeObligatory,
        }: {
            forceNewUsersToSetReasonCodes?: boolean;
            userList?: SubId[];
            isReasonCodeObligatory?: boolean;
        }) => {
            return new Promise<void>((resolve, reject) => {
                if (!userMap) {
                    reject("UserMap not loaded");
                }

                let newSettings = {};

                if (typeof isReasonCodeObligatory !== "undefined") {
                    newSettings = Object.assign(newSettings, {
                        ["user"]: {
                            isreasoncodeobligatory: JSON.stringify(isReasonCodeObligatory),
                        },
                    });
                }

                if (typeof forceNewUsersToSetReasonCodes !== "undefined" && Array.isArray(userList)) {
                    if (forceNewUsersToSetReasonCodes) {
                        userList = (Object.keys(userMap) as SubId[]).filter((sub) => !userList.includes(sub));
                    }

                    newSettings = Object.assign(newSettings, {
                        ["global"]: {
                            forcedreasoncodeusers: JSON.stringify(userList),
                            autoaddnewusers: JSON.stringify(forceNewUsersToSetReasonCodes),
                        },
                    });
                }

                this.setSettingsProps(newSettings, () => {
                    setHookState({ ...hookState, isLoading: true });
                    setUpdateRef(Symbol());
                    resolve();
                });
            });
        };

        const saveObligatoryReasonCodeSetting = (isReasonCodeObligatory: boolean) => {
            const newSettings = Object.assign(
                {},
                {
                    ["user"]: {
                        isreasoncodeobligatory: JSON.stringify(isReasonCodeObligatory),
                    },
                },
            );
            this.setSettingsProps(newSettings, () => {
                setUpdateRef(Symbol());
            });
        };

        const saveReasonCodes = (reasonCodes: any[]) => {
            if (!reasonCodes || !Array.isArray(reasonCodes)) {
                throw new Error("Please provide ReasonCodes to store in Settings");
            }

            const newSettings = {
                ["global"]: {
                    reasoncodes: JSON.stringify(reasonCodes),
                },
            };
            this.setSettingsProps(newSettings, () => {
                setUpdateRef(Symbol());
            });
        };

        this.useSettingsEffect(
            ["isreasoncodeobligatory", "forcedreasoncodeusers", "autoaddnewusers", "reasoncodes"],
            ({ user, global }: ReasonCodeModalSettings) => {
                if (Object.keys(userMap).length == 0) {
                    return;
                }

                let isReasonCodeObligatory = true;
                let reasonCodes = [];
                let userList = [];
                let forceNewUsersToSetReasonCodes = false;

                if (user?.isreasoncodeobligatory) {
                    isReasonCodeObligatory = JSON.parse(user.isreasoncodeobligatory);
                }

                if (global?.reasoncodes) {
                    reasonCodes = JSON.parse(global.reasoncodes);
                    reasonCodes = reasonCodes ? reasonCodes.filter((reasonCode) => !reasonCode.deleted) : [];
                }

                if (global?.forcedreasoncodeusers) {
                    userList = JSON.parse(global.forcedreasoncodeusers);
                    assert(Array.isArray(userList), "error loading obligatory users");
                }

                if (global?.autoaddnewusers) {
                    const currentUserKeys = Object.keys(userMap);

                    try {
                        forceNewUsersToSetReasonCodes = JSON.parse(global.autoaddnewusers);
                    } catch (e) {
                        forceNewUsersToSetReasonCodes = true;
                    }
                    if (forceNewUsersToSetReasonCodes) {
                        userList = currentUserKeys.filter((userId) => !userList.includes(userId));
                    }
                }

                setHookState({
                    ...hookState,
                    isLoading: false,
                    data: { forceNewUsersToSetReasonCodes, userList, isReasonCodeObligatory, reasonCodes },
                });
            },
            [updateRef],
        );

        return { ...hookState, saveReasonCodeSettings, saveObligatoryReasonCodeSetting, saveReasonCodes } as const;
    }

    /**
     * Hook to get and set named Baselines
     */
    useBaselineSettings() {
        const [updateRef, setUpdateRef] = useState(Symbol());
        const [hookState, setHookState] = useState<
            LCMDHookResult<{
                baseline: Baseline[];
            }>
        >({
            isLoading: true,
            isError: false,
            error: undefined,
            data: undefined,
        });

        const editBaselineName = (newEntry: any) => {
            const currentBaseline = hookState.data.baseline;
            const oldEntryIndex = currentBaseline.findIndex((entry) => entry.id == newEntry.id);
            if (oldEntryIndex === -1 && newEntry.name == "") {
                return;
            }

            currentBaseline[oldEntryIndex].name = newEntry.name;

            const newSettings = {
                ["global"]: {
                    baseline: [JSON.stringify(currentBaseline)],
                },
            };

            this.setSettingsProps(newSettings, () => {
                setHookState({ ...hookState, isLoading: true });
                setUpdateRef(Symbol());
            });
        };

        const deleteBaselineName = (id: string) => {
            const currentBaseline = hookState.data.baseline;
            const oldEntryIndex = currentBaseline.findIndex((entry) => entry.id == id);
            if (oldEntryIndex === -1) {
                return;
            }

            const newBaseline = currentBaseline.splice(oldEntryIndex, 1);

            const newSettings = {
                ["global"]: {
                    baseline: [JSON.stringify(currentBaseline)],
                },
            };

            this.setSettingsProps(newSettings, () => {
                setHookState({ ...hookState, isLoading: true });
                setUpdateRef(Symbol());
            });
        };

        const saveNamedBaseline = (newEntry: any) => {
            const currentBaseline = hookState.data.baseline;
            const oldEntryIndex = currentBaseline.findIndex((entry) => entry.id == newEntry.id);

            if (oldEntryIndex === -1 && newEntry.name == "") {
                return;
            }

            const newBaseline = [...currentBaseline, newEntry];

            const newSettings = {
                ["global"]: {
                    baseline: [JSON.stringify(newBaseline)],
                },
            };

            this.setSettingsProps(newSettings, () => {
                setHookState({ ...hookState, isLoading: true });
                setUpdateRef(Symbol());
            });
        };

        this.useSettingsEffect(
            ["baseline"],
            ({ user, global }) => {
                let baseline: Baseline[] = [];

                if (global?.baseline) {
                    baseline = JSON.parse(global.baseline);
                }

                setHookState({
                    ...hookState,
                    isLoading: false,
                    data: { baseline },
                });
            },
            [updateRef],
        );

        return { ...hookState, saveNamedBaseline, editBaselineName, deleteBaselineName } as const;
    }

    /**
     * Hook to get and set Data Security Link
     */
    useDataSecurityLink() {
        const [hookState, setHookState] = useState<LCMDHookResult<{ link: string }>>({
            isLoading: true,
            isError: false,
            error: undefined,
            data: { link: "" },
        });

        this.useSettingsEffect(
            ["datasecuritylink"],
            (settings) => {
                if (settings.global.datasecuritylink) {
                    setHookState({ ...hookState, data: { link: settings.global.datasecuritylink }, isLoading: false });
                } else {
                    setHookState({ ...hookState, data: { link: "" }, isLoading: false });
                }
            },
            [],
        );

        const setDataSecurityLink = (link: string) => {
            const isValidLink = isValidHttpUrl(link) || link === "";
            if (!isValidLink) {
                setHookState({
                    ...hookState,
                    isError: true,
                    isLoading: false,
                    data: { link: null },
                    error: new Error("Data security link provided is not a valid URL"),
                });
            } else {
                const newLink = {
                    ["global"]: {
                        datasecuritylink: link,
                    },
                };

                this.setSettingsProps(newLink, () => {
                    setHookState({ ...hookState, isLoading: false, isError: false, data: { link } });
                });
            }
        };

        return { ...hookState, setDataSecurityLink };
    }

    public forceCanvasReload() {
        if (this.worker) {
            this.worker.postMessage(["canvas", "reload"]);
        }
    }

    getCanvasMeta() {
        return this.worker?.canvas?.meta || {};
    }

    getLicense(licid: string, cb: (error: FrameworkError, lic: LCMDContextLicenseDetails) => void) {
        if (this.worker.auth?.auth_token && this.worker.auth?.params?.sub && licid) {
            ApiHelper.getLic(this.worker.auth.auth_token, licid, (error, result) => {
                if (error) {
                    cb(error, null);
                } else {
                    // console.log(result);
                    cb(null, { ...result.result, sub: this.worker.auth?.params?.sub });
                }
            });
        } else {
            cb(new FrameworkError("error.fatal"), null);
        }
    }

    public authLogin(
        email: string,
        password: string | { accessToken: string } | null,
        cb: (error: FrameworkHttpError, result: any) => void,
        recaptcha: string,
        keepMeLoggedIn: boolean,
        options?: {
            sso?: {
                domain: string;
            };
        },
    ) {
        if (this.worker) {
            if (options?.sso) {
                email = [encodeURIComponent(email), options.sso.domain].join("@");
            }
            ApiHelper.authLogin(
                email,
                password,
                (error, result) => {
                    if (error) {
                        cb(error as any, null);
                    } else {
                        cb(null, result);
                    }
                },
                this.worker.auth?.auth_token || null,
                this.worker.warmup_result?.req ? (this.worker.warmup_result as { req: string }) : null,
                true,
                null,
                recaptcha,
            );
        }
    }

    public authCreate(email: string, cb: (error: FrameworkHttpError, result: any) => void, recaptcha: string) {
        if (this.worker) {
            ApiHelper.authCreate(
                email,
                (error: FrameworkError, result) => {
                    if (error) {
                        cb(error, null);
                    } else {
                        ApiHelper.authLogin(
                            email,
                            null,
                            (error: FrameworkError, result) => {
                                if (error) {
                                    cb(error, null);
                                } else {
                                    cb(null, result);
                                }
                            },
                            result.recaptcha,
                            this.worker.warmup_result?.req ? (this.worker.warmup_result as { req: string }) : null,
                            true,
                            undefined,
                            undefined,
                        );
                    }
                },
                this.worker.auth?.auth_token || null,
                this.worker.warmup_result?.req ? (this.worker.warmup_result as { req: string }) : null,
                recaptcha,
            );
        }
    }

    public authVerify(
        email: string,
        pin: string,
        pin_secret: string,
        password: string | null,
        cb: (error: FrameworkHttpError, result: any) => void,
        meta?: any,
    ) {
        if (this.worker) {
            ApiHelper.authVerify(
                pin,
                pin_secret,
                password,
                (error, result) => {
                    if (error) {
                        cb(error as any, null);
                    } else {
                        if (false && result?.auth_token) {
                            this.worker.dispatchMessage(["auth_token", result]);
                        }
                        cb(null, result);
                    }
                },
                undefined,
                email,
                meta,
            );
        }
    }

    public getConfig() {
        if (this.worker) {
            return {
                sso: this.worker.warmup_result?.sso,
                auth: {
                    details: this.worker.auth?.details,
                    extra: this.worker.auth?.extra,
                },
            };
        } else {
            return null;
        }
    }

    public static Sub(props: { sub: string }): JSX.Element {
        const ctx = useParticleContext();
        return <Sub {...props} worker={ctx.worker} />;
    }

    public generateId() {
        return _generateId();
    }

    public useProjectPersonasEffect(
        subs: string[],
        cb: (error: FrameworkError, personas: IFacepilePersona[]) => void,
        deps: React.DependencyList,
    ) {
        if (this.worker?.auth?.auth_token && this.worker?.nav?.session?.pid) {
            const wrapper = {
                cb,
            };
            SubCache.getPersonas(this.worker.auth.auth_token, subs, (personas) => {
                if (wrapper.cb) {
                    wrapper.cb(null, personas);
                }
            });
            return () => {
                wrapper.cb = null;
            };
        }
    }

    public useProjectCollaboratorsEffect(
        projectId: string | null,
        cb: (error: FrameworkError, personas: Persona[], project: any) => void,
        deps: React.DependencyList,
    ) {
        const _cb = React.useCallback(cb, deps);
        React.useEffect(() => {
            if (this.worker?.auth?.auth_token && this.worker?.nav?.session?.pid) {
                const wrapper = {
                    cb: _cb,
                };
                ApiHelper.getProject(this.worker.auth.auth_token, this.worker.nav.session.pid, (error, result) => {
                    if (wrapper.cb) {
                        if (error) {
                            wrapper.cb(error as FrameworkError, null, null);
                        } else {
                            const subs = Object.getOwnPropertyNames(result.project.shared);
                            SubCache.getPersonas(this.worker.auth.auth_token, subs, (personas) => {
                                if (wrapper.cb) {
                                    wrapper.cb(null, personas, result);
                                }
                            });
                        }
                    }
                });
                return () => {
                    wrapper.cb = null;
                };
            }
        }, [_cb]);
    }

    public getSafeIDs(
        ids: { pids?: number[]; trids?: number[] },
        cb: (error: FrameworkError, safeIds: { pids: string[]; trids: string[] }) => void,
    ) {
        if (this.worker) {
            this.worker.postMessage([
                "project",
                "safeIDs",
                {
                    ids,
                    cb: this.worker.registerCallback((data) => {
                        const err = data.err ? (jsonToError(data.err) as FrameworkError) : null;
                        cb(err, data.ret);
                    }),
                },
            ]);
        } else {
            cb(null, null);
        }
    }

    public getUnsafeIDs(
        safeIDs: { pids: string[]; trids: string[] },
        cb: (error: FrameworkError, ids: { pids: number[]; trids: number[] }) => void,
    ) {
        if (this.worker) {
            this.worker.postMessage([
                "project",
                "unsafeIDs",
                {
                    safeIDs,
                    cb: this.worker.registerCallback((data) => {
                        const err = data.err ? (jsonToError(data.err) as FrameworkError) : null;
                        cb(err, data.ret);
                    }),
                },
            ]);
        } else {
            cb(null, null);
        }
    }

    public setOrCreateLibraryItem(
        projectId: string | null,
        batch: LCMDContextLibraryItemTarget | LCMDContextLibraryItemTarget[],
    ) {
        if (this.worker) {
            this.worker.postMessage(["lib", "add", Array.isArray(batch) ? batch : [batch]]);
        }
    }

    public getActiveLibrariesSync(): LCMDContextLibraries[] {
        if (Array.isArray(this.worker?.wb?.stripes)) {
            return this.worker.wb.stripes.map((stripe) => {
                return {
                    id: stripe.t,
                    name: stripe.label,
                };
            });
        } else {
            return null;
        }
    }

    public createWhiteboard(
        params: {
            open?: boolean;
        } & LCMDContextWhiteboardState,
    ) {
        if (this.worker) {
            this.worker.postMessage(["wb", "create", params]);
        }
    }

    public openWhiteboard(params: { id: string }) {
        if (this.worker) {
            this.worker.postMessage(["wb", "open", params]);
        }
    }

    public setWhiteboardState(id: string, state: LCMDContextWhiteboardState) {
        if (this.worker) {
            this.worker.postMessage([
                "wb",
                "set",
                {
                    id,
                    state,
                },
            ]);
        }
    }

    public closeWhiteboard(
        id: string,
        opt?: {
            delete?: boolean;
        },
    ) {
        if (this.worker) {
            this.worker.postMessage(["wb", "close", { ...opt, active: id }]);
        }
    }

    public natigateToView(
        view: "files",
        options?,
        auth?: {
            keepMeLoggedIn: boolean;
            result: { auth_token: string; sub: string };
        },
    ) {
        if (this.worker) {
            if (auth) {
                if (auth.result?.auth_token) {
                    if (auth.keepMeLoggedIn) {
                        try {
                            localStorage.setItem("lcm.auth_token", auth.result.auth_token);
                        } catch (e) {
                            console.warn(e);
                        }
                    }
                    this.worker.dispatchMessage(["auth_token", auth.result]);
                }
            }
            this.worker.dispatchMessage(["framework", "nav", { ...(options || {}), view: view }]);
        }
    }

    public unlinkProject(projectId: string | null, sub: string | string[], cb: (error, result) => void) {
        if (this.worker?.auth?.auth_token) {
            const pid = projectId || this.worker.nav?.project || null;
            if (pid) {
                ApiHelper.unlink_project(this.worker.auth.auth_token, cb, pid, sub);
            } else {
                cb(new FrameworkHttpError(400), null);
            }
        } else {
            cb(new FrameworkHttpError(403), null);
        }
    }

    public addCollaborator(
        projectId: string | null,
        sub: string,
        emails: string[],
        role: string,
        cb: (error, result) => void,
    ) {
        if (this.worker?.auth?.auth_token) {
            const pid = projectId || this.worker.nav?.project || null;
            if (pid) {
                ApiHelper.addCollaborator(this.worker?.auth?.auth_token, cb, pid, sub, emails as any, role);
            } else {
                cb(new FrameworkHttpError(400), null);
            }
        } else {
            cb(new FrameworkHttpError(403), null);
        }
    }

    setCollaboratorState(sub: string, { trades, extendedRights, tradesAdmin }: { trades?: number[]; extendedRights?: boolean, tradesAdmin?: boolean }) {
        if (sub && Array.isArray(trades)) {
            this.worker.postMessage([
                "sub",
                "filter",
                {
                    sub,
                    trades,
                    extendedRights,
                    tradesAdmin,
                },
            ]);
        }
    }

    public loadFilterData(
        cb: (data: {
            wbs: any;
            trades: any;
            filter: DataModelFilter | null;
            filterCollection?: DataModelFilterCollection;
            startDate?: number;
            endDate?: number;
        }) => void,
    ) {
        const ctx = {
            cancel: false,
        };
        this.worker.registerHandler((msg: MainWorkerMessage) => {
            switch (msg[0]) {
                case "filter":
                    {
                        switch (msg[1]) {
                            case "data":
                                {
                                    if (!ctx.cancel) {
                                        cb(msg[2]);
                                    }
                                    return "unregister";
                                }
                                break;
                        }
                    }
                    break;
            }
        });
        this.worker.postMessage(["filter", "data", {}]);
        return () => {
            ctx.cancel = true;
        };
    }

    public abstract setFilter(
        filter: DataModelFilterSavedAs | string,
        opt?: { modeDailyBoard?: boolean },
        cb?: (error?: FrameworkError) => void,
    );

    public useCardEffect(props: { initialCard }, ts, data, status, setTS, setData, setStatus) {
        //_cardEffectmessageHandler
        const handler = React.useMemo(
            () => _cardEffectmessageHandler.bind(null, props.initialCard, setData, setTS, setStatus),
            [setData, props.initialCard, setTS, setStatus],
        );
        React.useEffect(() => {
            this.worker.registerHandler(handler);
            this.worker.postMessage([
                "details",
                {
                    taskId: props.initialCard.tid,
                    isWhiteboard: props.initialCard.isWhiteboard,
                },
            ]);
            return () => {
                this.worker.unregisterHandler(handler);
            };
        }, [this, handler, props.initialCard.tid]);
        React.useEffect(() => {
            if (data.card && data.td._ < ts) {
                this.worker.postMessage([
                    "details",
                    {
                        taskId: props.initialCard.tid,
                        isWhiteboard: props.initialCard.isWhiteboard,
                    },
                ]);
            }
        }, [ts, data, props.initialCard.tid]);
    }

    useAreaPath(processId: number, refTimestamp?: number, isTaktzone?: boolean) {
        const [hookState, setHookState] = useState<LCMDHookResult<{ areaPath: { id: number; name: string }[] }>>({
            isLoading: true,
            isError: false,
            error: undefined,
            data: { areaPath: [] },
        });

        useEffect(() => {
            let isMounted = true;
            (async () => {
                if (isMounted) {
                    const cb = this.worker.registerCallback((areaPathes: { id: number; name: string }[][]) => {
                        setHookState({ ...hookState, isLoading: false, data: { areaPath: areaPathes[0] } });
                    });
                    this.worker.postMessage([
                        "utils",
                        "areaPath",
                        { processIds: [processId], refTimestamp, isTaktzone, cb },
                    ]);
                }
            })();
            return () => {
                isMounted = false;
            };
        }, [processId]);

        return hookState;
    }
}

let _particleContext: { instance: ParticleContext; ctx: React.Context<ParticleContext> } = null;
export function _setParticleContext(
    ctx: { instance: ParticleContext; ctx: React.Context<ParticleContext> },
    worker: MainWorkerPipe | Worker,
) {
    _particleContext = ctx;
    if (_particleContext.instance) {
        _particleContext.instance.worker = worker;
    }
}

export function ParticleContextProvider(props: { children: any }) {
    assert(_particleContext?.ctx);
    return (
        <_particleContext.ctx.Provider value={_particleContext.instance}>
            {props.children}
        </_particleContext.ctx.Provider>
    );
}

export function useParticleContext(): ParticleContext {
    assert(_particleContext?.ctx);
    return React.useContext(_particleContext.ctx);
}

export function getParticleContext(): ParticleContext {
    assert(_particleContext);
    return _particleContext.instance;
}

export function useProcessView(props: {
    onOpen?: () => void;
    onClose?: () => void;
    onUpdate?: (resp: { data: any[]; readonly: boolean }) => void;
}) {
    const LCMD = useParticleContext();
    React.useEffect(() => {
        const handler = _handleProcessViewMsg.bind(props, LCMD);
        LCMD.worker.registerHandler(handler);
        LCMD.worker.postMessage(["processview", "init"]);
        if (props?.onOpen) {
            props.onOpen();
        }
        return () => {
            LCMD.worker.postMessage([
                "processview",
                "cleanup",
                {
                    id: LCMD.wbs?.id,
                },
            ]);
            LCMD.wbs = null;
            if (props?.onClose) {
                props.onClose();
            }
            LCMD.worker.unregisterHandler(handler);
        };
    }, []);
}
