import { IColumn, mergeStyleSets, Spinner, Stack, StackItem, Text } from "@fluentui/react";
import * as React from "react";
import { useEffect, useState } from "react";
import { intl } from "@/legacy/GlobalHelperReact";
import { copyAndSort } from "../../Utils";
import { ReasonCodeDeleteDialog } from "./reason-code-delete";
import { ReasonCodesModal } from "./reason-codes-modal";
import { useLCMD } from "../../../app/LCMContext";
import { ReasonCodesRolesModal } from "./ReasonCodesRolesModal";
import { useUserMap } from "../../hooks/userMap.hook";
import { User, UserMap } from "../../hooks/UserMapTypes";
import { LcmFacepile } from "../../common/LcmFacepile";
import { useUserRole } from "../../hooks/useUserRole.hook";
import { Switch } from "@/components/ui/switch";
import { Button } from "@/components/ui/button";
import { DataTable } from "@/components/ui/data-table";
import { ArrowUpDown, MoreVertical, Pencil, Plus, Trash2 } from "lucide-react";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { WebAppTelemetryFactory } from "@/app/services/WebAppTelemetry.service";

type SortState = {
    sortByColumn: IColumn;
};
export type ReasonCodeItem = {
    id?: string;
    name: string;
    deleted: boolean;
};

export function ReasonCodes() {
    const LCMD = useLCMD();
    const {
        isLoading: areReasonCodeSettingsLoading,
        data: reasonCodeSettings,
        saveObligatoryReasonCodeSetting,
        saveReasonCodes,
        saveReasonCodeSettings,
    } = LCMD.useReasonCodeSettings();

    const [items, setItems] = useState<ReasonCodeItem[]>([]);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showReasonCodesRolesModal, setShowReasonCodesRolesModal] = useState(false);
    const [showReasonCodeDeleteDialog, setShowReasonCodeDeleteDialog] = useState(false);
    const [reasonCodeToChange, setReasonCodeToChange] = useState<ReasonCodeItem>(null);
    const [facepileUsers, setFacepileUsers] = useState<User[]>([]);
    const [sortState, setSortState] = useState<SortState>({ sortByColumn: null });
    const [sortAscending, setSortAscending] = useState(false);

    const isAllowed = useUserRole() === "admin";
    const userMap: UserMap = useUserMap();
    function saveSettings(items) {
        saveReasonCodes(items);
    }

    const saveShowModal = (isReasonCodeObligatory) => {
        WebAppTelemetryFactory.trackEvent("settings-quick-mode", { enabled: isReasonCodeObligatory });
        saveObligatoryReasonCodeSetting(isReasonCodeObligatory);
    };

    useEffect(() => {
        if (!reasonCodeSettings || Object.keys(userMap).length == 0) {
            setFacepileUsers([]);
            return;
        }
        const usersFromIds = reasonCodeSettings.userList.map((userId) => userMap[userId]); // Convert user IDs to users
        const validUsers = usersFromIds.filter((user) => user !== undefined); // Filter out any undefined users
        const usersForFacepile: User[] = validUsers.map((user) => ({
            email: user.email,
            meta: user.meta,
        }));
        setFacepileUsers(usersForFacepile);
    }, [userMap, reasonCodeSettings]);

    function createEntries(reasonCodesToSave: ReasonCodeItem[]) {
        reasonCodesToSave.map((reasonCode) => (reasonCode.id = LCMD.generateId()));
        const entries = reasonCodeSettings?.reasonCodes.concat(reasonCodesToSave);
        saveSettings(entries);
    }

    function editEntry(ret: ReasonCodeItem) {
        reasonCodeSettings?.reasonCodes?.map((item) => {
            if (item.id == ret.id) {
                item.name = ret.name;
            }
        });
        saveSettings(reasonCodeSettings?.reasonCodes);
    }

    function deleteEntry(ret: ReasonCodeItem) {
        reasonCodeSettings.reasonCodes.some((entry, index) => {
            if (entry.id == ret.id) {
                entry.deleted = true;
                return true;
            }
        });

        saveSettings(reasonCodeSettings?.reasonCodes);
    }

    useEffect(() => {
        if (!reasonCodeSettings || !reasonCodeSettings?.reasonCodes) {
            return;
        }

        if (sortState.sortByColumn) {
            let res = [...reasonCodeSettings.reasonCodes];
            if (sortState.sortByColumn) {
                res = copyAndSort(res, sortState.sortByColumn.key, sortState.sortByColumn.isSortedDescending);
            }
            setItems(res);
        } else {
            setItems([...reasonCodeSettings.reasonCodes]);
        }
    }, [reasonCodeSettings?.reasonCodes]);

    const classNames = mergeStyleSets({
        menuPropFont: {
            fontSize: "14px",
            lineHeight: "20px",
            letterSpacing: "-1,5%",
            fontWeight: 400,
        },
        settingsTitle: {
            fontSize: 16,
            fontWeight: 600,
        },
        settingsSubtitle: {
            fontSize: 14,
            color: "#565C60",
        },
    });

    async function saveRoles(users, autoAddNewUsers) {
        try {
            await saveReasonCodeSettings({
                forceNewUsersToSetReasonCodes: autoAddNewUsers,
                userList: users,
            });
        } catch (e) {}
    }

    function sortByColumn(columnKey: string) {
        setItems(copyAndSort([...items], columnKey, sortAscending));
        setSortAscending(!sortAscending);
    }

    const reasonCodeColumns = [
        {
            accessorKey: "name",
            size: 200,
            header: ({ column }) => {
                return (
                    <Button variant="ghost" className="p-0" onClick={() => sortByColumn(column.id)}>
                        {intl.get("StabilityCriteria.SettingsList.NameColumn")}
                        <ArrowUpDown size={16} className={"mx-2"}></ArrowUpDown>
                    </Button>
                );
            },
            cell: ({ row }) => (
                <div className="relative flex max-w-max items-center gap-2">
                    <p>{row.getValue("name")}</p>
                </div>
            ),
        },
        {
            accessorKey: "dropdown",
            size: 200,
            header: () => <div className="text-right"></div>,
            cell: ({ row }) => (
                <div className="relative flex justify-end gap-2">
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button className="ml-auto h-5 w-5 bg-transparent p-0" variant="secondary">
                                <MoreVertical stroke-width="0.5" />
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent sideOffset={-25} alignOffset={20} align={"end"}>
                            <DropdownMenuItem
                                disabled={!isAllowed}
                                onClick={() => {
                                    setReasonCodeToChange(row.original);
                                    setShowEditModal(true);
                                }}
                                key="editMessage"
                            >
                                <div className="flex justify-between gap-2">
                                    <Pencil strokeWidth={1} size={18} />
                                    <p>{intl.get("ReasonCodesSettings.list.edit")}</p>
                                </div>
                            </DropdownMenuItem>
                            <DropdownMenuItem
                                disabled={!isAllowed}
                                onClick={() => {
                                    setReasonCodeToChange(row.original);
                                    setShowReasonCodeDeleteDialog(true);
                                }}
                                key="deleteMessage"
                            >
                                <div className="flex justify-between gap-2">
                                    <Trash2 strokeWidth={1} size={18} />
                                    <p>{intl.get("ReasonCodesSettings.list.delete")}</p>
                                </div>
                            </DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                </div>
            ),
        },
    ];

    if (areReasonCodeSettingsLoading && !reasonCodeSettings) {
        return <Spinner />;
    }

    return (
        <Stack>
            <Stack
                styles={{ root: { position: "absolute", top: 16, right: 24 } }}
                horizontal
                horizontalAlign={"center"}
                verticalAlign={"center"}
                tokens={{ childrenGap: 16 }}
            >
                <StackItem>
                    <LcmFacepile
                        persona={facepileUsers}
                        tooltipQuestionMark={intl.get("ReasonCodesSettings.questionMarkTooltip")}
                        onClickPersona={() => setShowReasonCodesRolesModal(true)}
                        onClickQuestionMark={() => setShowReasonCodesRolesModal(true)}
                    />
                </StackItem>
                <StackItem>
                    <Button
                        variant="secondary"
                        disabled={!isAllowed}
                        onClick={() => {
                            setShowReasonCodesRolesModal(true);
                        }}
                    >
                        {intl.get("ReasonCodesSettings.defineRolesButton")}
                    </Button>
                </StackItem>
            </Stack>
            <Stack horizontal horizontalAlign={"space-between"}>
                <StackItem>
                    <Stack tokens={{ childrenGap: 6 }}>
                        <StackItem>
                            <Text className={classNames.settingsTitle}>
                                {intl.get("ReasonCodesSettings.list.title")}
                            </Text>
                        </StackItem>
                        <StackItem>
                            <Text className={classNames.settingsSubtitle}>
                                {intl.get("ReasonCodesSettings.list.subtitle")}
                            </Text>
                        </StackItem>
                    </Stack>
                </StackItem>
                <StackItem>
                    <Button
                        disabled={!isAllowed}
                        onClick={() => {
                            setShowCreateModal(true);
                        }}
                    >
                        <Plus className={"mr-2"} />
                        {intl.get("ReasonCodesSettings.newButton")}
                    </Button>
                </StackItem>
            </Stack>
            {items.length > 0 && (
                <StackItem
                    className={"max-h-[600px] overflow-y-auto"}
                    style={{ margin: "20px 0" }}>
                    <DataTable data={items.filter((item) => !item.deleted)} columns={reasonCodeColumns} />
                </StackItem>
            )}
            <Stack className={"mt-8"}>
                <StackItem >
                    <Text className={classNames.settingsTitle}>{intl.get("ReasonCodesSettings.quickMode.title")}</Text>
                </StackItem>
                <Stack horizontal horizontalAlign={"space-between"}>
                    <StackItem align="center">
                        <Text className={classNames.settingsSubtitle}>
                            {intl.get("ReasonCodesSettings.quickMode.subtitle")}
                        </Text>
                    </StackItem>
                    <StackItem align="center">
                        <Switch
                            checked={reasonCodeSettings?.isReasonCodeObligatory}
                            onCheckedChange={(checked) => {
                                saveShowModal(checked);
                            }}
                        />
                    </StackItem>
                </Stack>
            </Stack>
            {showReasonCodeDeleteDialog && (
                <ReasonCodeDeleteDialog
                    reasonCode={reasonCodeToChange}
                    onClose={(itemToDelete) => {
                        deleteEntry(itemToDelete);
                        setShowReasonCodeDeleteDialog(false);
                    }}
                    onCancel={() => setShowReasonCodeDeleteDialog(false)}
                />
            )}
            <ReasonCodesModal
                onCancel={() => setShowCreateModal(false)}
                onClose={(itemsToSave: ReasonCodeItem[]) => {
                    createEntries(itemsToSave);
                    setShowCreateModal(false);
                }}
                showDialog={showCreateModal}
            />
            <ReasonCodesModal
                onCancel={() => {
                    setShowEditModal(false);
                }}
                onClose={(itemsToSave: ReasonCodeItem) => {
                    editEntry(itemsToSave);
                    setShowEditModal(false);
                }}
                showDialog={showEditModal}
                reasonCodeToEdit={reasonCodeToChange}
            />
            {showReasonCodesRolesModal && (
                <ReasonCodesRolesModal
                    open={showReasonCodesRolesModal}
                    initiallySelected={reasonCodeSettings.userList}
                    autoAddNewUsers={reasonCodeSettings.forceNewUsersToSetReasonCodes}
                    users={userMap}
                    onDismiss={() => setShowReasonCodesRolesModal(false)}
                    onOk={async ({ selectedUsers, autoAddNewUsers }) => {
                        await saveRoles(selectedUsers, autoAddNewUsers);
                        setShowReasonCodesRolesModal(false);
                    }}
                />
            )}
        </Stack>
    );
}
