import * as React from "react";
import { useCallback, useState } from "react";

import { ViewButton as BaseViewButton } from "./ViewButtonV2";
import { Sizes, ContentTypeValueI, ViewTypeValueI, SizeValueI } from "../constants";
import { useLCMD } from "../../../app/LCMContext";
import { WebAppTelemetryFactory } from "@/app/services/WebAppTelemetry.service";
import { useCanvasStore } from "@/app/store/canvasStore";
import {
    useContentType,
    useSetAreaAttachments,
    useSetContentType,
    useSetShowNonWorkingDays,
    useSetShowProjectWeeks,
    useSetShowStatusBar,
    useSetShowTaktZones,
    useSetSplitView,
    useSetUserView,
    useShowTaktZones,
    useSplitView,
    useUserView,
} from "@/app/store/userJourneyStore";

const contentTypeMap: { [key: string]: string } = {
    RESOURCE_CHART: "canvas.kappa",
    MILESTONES: "canvas.milestones",
};

function ExtendedViewButton() {
    const LCMD = useLCMD();

    const { setShowDependencies } = useCanvasStore();
    const userView = useUserView();
    const setUserView = useSetUserView();
    const setAreaAttachment = useSetAreaAttachments();
    const setShowNonWorkingDays = useSetShowNonWorkingDays();
    const showTaktzones = useShowTaktZones();
    const setShowTaktZones = useSetShowTaktZones();
    const setShowProjectWeeks = useSetShowProjectWeeks();
    const splitView = useSplitView();
    const setSplitView = useSetSplitView();
    const contentType = useContentType();
    const setContentType = useSetContentType();
    const setShowStatusBar = useSetShowStatusBar();

    const [size, setSize] = useState<SizeValueI>(Sizes.MEDIUM);

    const onSplitViewChange = (value: boolean) => {
        WebAppTelemetryFactory.trackEvent("show-bottom-split-view-toggle", { value });
        if (value) {
            LCMD.showDialog("footer", { name: contentTypeMap[contentType] });
        } else {
            LCMD.showDialog("footer", null);
        }

        setSplitView(value);
    };

    const onTaktZoneImageChange = useCallback((value: boolean) => {
        WebAppTelemetryFactory.trackEvent("show-area-attachments-toggle", { value });
        LCMD.setStatusView(null, {
            taktZoneImages: value,
        });
        setAreaAttachment(value);
    }, []);

    const onContentSelect = (value: ContentTypeValueI) => {
        WebAppTelemetryFactory.trackEvent("show-content", { value: value.toLowerCase() });
        if (splitView) {
            LCMD.showDialog("footer", { name: contentTypeMap[value] });
        }
        setContentType(value);
    };

    const onSizeSelect = useCallback((value: SizeValueI) => {
        console.warn("TODO: Should implement");
        setSize(value);
    }, []);

    const onTypeSelect = useCallback(
        (value: ViewTypeValueI) => {
            LCMD.setStatusView(value);
            WebAppTelemetryFactory.trackEvent("viewmode-change", { selectedType: value });
            setUserView(value);
        },
        [LCMD],
    );

    const onShowStatusBarChange = (value: boolean) => {
        LCMD.setStatusView(null, {
            showStatusBar: value,
        });
        setShowStatusBar(value);
        WebAppTelemetryFactory.trackEvent("show-progress-toggle", {
            value: value,
        });
    };

    const onShowNonWorkingDaysChange = (value: boolean) => {
        WebAppTelemetryFactory.trackEvent("show-non-working-days-toggle", { value });
        LCMD.setCalendarView(value);
        setShowNonWorkingDays(value);
    };

    const onShowTaktZonesChange = (value: boolean) => {
        WebAppTelemetryFactory.trackEvent("show-standard-areas-toggle", { value });
        LCMD.setStatusView(null, {
            showTaktzones: value,
        });
        setShowTaktZones(value);
    };

    const onShowProjectWeeksChange = (value: boolean) => {
        WebAppTelemetryFactory.trackEvent("show-project-weeks-toggle", { value });
        LCMD.setStatusView(null, {
            showProjectWeeks: value,
        });
        setShowProjectWeeks(value);
    };
    const onShowDependenciesChange = (showDeps: boolean) => {
        setShowDependencies(showDeps);
        WebAppTelemetryFactory.trackEvent("show-dependecy-toggle", { value: showDeps });
    };

    return (
        <BaseViewButton
            content={contentType}
            size={size}
            onTypeSelect={onTypeSelect}
            onSplitViewChange={onSplitViewChange}
            onContentSelect={onContentSelect}
            onSizeSelect={onSizeSelect}
            onTaktZoneImageChange={onTaktZoneImageChange}
            onShowStatusBarChange={onShowStatusBarChange}
            onShowNonWorkingDaysChange={onShowNonWorkingDaysChange}
            onShowTaktZonesChange={onShowTaktZonesChange}
            onshowProjectWeeksChange={onShowProjectWeeksChange}
            onshowDependenciesChange={onShowDependenciesChange}
        />
    );
}

export { ExtendedViewButton as ViewButton };
