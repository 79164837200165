import { ICheckedValues } from "./Sidebar/ToDoCheckbox/ICheckedValues";
import { getCurrentLocale } from "./utils/date/locale";
import { User } from "./hooks/UserMapTypes";
import { ItemState, ToDoProps } from "./ToDo/interface";
import { LCMDContextCardStatus, StabilityOverallStatus } from "../model/GlobalHelper";
import { DataModel, isWorkingDayHack } from "../model/DataModel";
import { LCMDContextTaskDetailsResult, LCMDContextTodoResult } from "@/app/LCMDContextTypes";
import { generateTodosFromRawDate } from "./ToDo/ActionItem.factory";
import { StabilityViewStateProps } from "./hooks/useStabilityViewData.hook";
import { z } from "zod";
import { getUserDisplayName } from "@/utils/GeneralUtils";

export function epochDayToDate(epochs: number) {
    return new Date(epochs * 60 * 60 * 24 * 1000);
}

export function dateToEpochDay(date: Date | number) {
    if (date instanceof Date) {
        return Math.trunc(date?.getTime() / 24 / 60 / 60 / 1000);
    } else if (typeof date === "number") {
        return Math.trunc(date / 24 / 60 / 60 / 1000);
    } else {
        return 0;
    }
}

export function copyAndSort<T>(items: T[], field: string, isSortedDescending?: boolean): T[] {
    const key = field as keyof T;
    return items.slice(0).sort((a: T, b: T) => {
        const aTemp = a[key].toString();
        const bTemp = b[key].toString();

        if (isSortedDescending) {
            return bTemp.localeCompare(aTemp, getCurrentLocale().code, { sensitivity: "base", numeric: true });
        } else {
            return aTemp.localeCompare(bTemp, getCurrentLocale().code, { sensitivity: "base", numeric: true });
        }
    });
}

export function toUTCDate(d: Date): Date {
    return new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
}

export function isOverdue(done: boolean, dueDate: number, today: number) {
    return !done && dueDate < today;
}

export function isToDoOverdue(todo: ToDoProps, today: number) {
    if (todo.status === ItemState.DONE) {
        return false;
    }

    return todo.status === ItemState.OPEN && todo.deadline < today;
}

export function getToDoState(done: boolean, dueDate: number, today: number): ICheckedValues {
    if (done) {
        return ICheckedValues.CHECKED;
    } else if (dueDate >= today) {
        return ICheckedValues.UNCHECKED;
    } else if (dueDate < today) {
        return ICheckedValues.LATE;
    }
}

export function getCardStateText(state: LCMDContextCardStatus, intl): string {
    return (
        [
            intl.get("TasksView.StateOpen"),
            intl.get("TasksView.StateInProgress"),
            intl.get("TasksView.StateDone"),
            intl.get("TasksView.StateInApproval"),
            intl.get("TasksView.StateInApproval"),
        ][state] || ""
    );
}

export function createUserName(user: User): string {
    return getUserDisplayName(user);
}

/**
 * Returns a hash code from a string
 * @param {String} str The string to hash.
 * @return {Number}
 **/
export function getHashCode(str: string): number {
    let hash = 0;
    for (let i = 0, len = str.length; i < len; i++) {
        const chr = str.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
}
// @refactor: add typing for meta !!
// @todo: add an hook that uses this method
export function calcWorkingEndDateInMs(startDateInTimestamp: number, durationDay: number, meta: any): number {
    const isWorkingDay = isWorkingDayHack.bind(DataModel.createIsWorkingDayHackMeta(meta));
    return DataModel.CalcEpochEndDayMS(isWorkingDay, startDateInTimestamp, durationDay, 3);
}

export function calcWorkingStartDateInMs(startDateInTimestamp: number, durationDay: number, meta: any): number {
    const isWorkingDay = isWorkingDayHack.bind(DataModel.createIsWorkingDayHackMeta(meta));
    return DataModel.CalcEpochStartDayMS(isWorkingDay, startDateInTimestamp, durationDay, 3);
}

export const getStabilityViewState = (data: LCMDContextTaskDetailsResult) => {
    if (!data) {
        const defaultState: {
            todos: LCMDContextTodoResult;
            stabilityItems: ToDoProps[];
            groupedItems?: Map<string, ToDoProps[]>;
            customStabilityItems: ToDoProps[];
            stableItemsCount: number;
            stabilityTotalCount: number;
        } = {
            todos: null,
            groupedItems: null,
            stabilityItems: [],
            customStabilityItems: [],
            stableItemsCount: 0,
            stabilityTotalCount: 0,
        };

        return defaultState;
    }

    const todos = generateTodosFromRawDate(data.todos);
    const stabilityItems = todos.filter((todoItem) => {
        return Boolean(todoItem.stability) && typeof todoItem.id === "string";
    });

    const customStabilityItems = todos.filter((todoItem) => {
        return Boolean(todoItem.stability) && typeof todoItem.id === "number";
    });

    const groupedItemsMap: Map<string, ToDoProps[]> = new Map();
    stabilityItems.forEach((t) => {
        if (groupedItemsMap.get(t.stability)) {
            const value = groupedItemsMap.get(t.stability);
            value.push(t);
            groupedItemsMap.set(t.stability, value);
        } else {
            groupedItemsMap.set(t.stability, [t]);
        }
    });

    return {
        todos: data.todos,
        groupedItems: groupedItemsMap,
        stabilityItems: stabilityItems,
        customStabilityItems: customStabilityItems,
        stableItemsCount:
            stabilityItems.filter((stabItem) => stabItem.status === ItemState.DONE).length +
            customStabilityItems.filter((stabItem) => stabItem.status === ItemState.DONE).length,
        stabilityTotalCount: stabilityItems.length + customStabilityItems.length,
    };
};

export const getOverallStabilityState = (
    localToday: number,
    stabilityViewState: StabilityViewStateProps,
): StabilityOverallStatus => {
    if (stabilityViewState.stabilityTotalCount === stabilityViewState.stableItemsCount) {
        return StabilityOverallStatus.DONE;
    }

    const generalIsOverdue = stabilityViewState.stabilityItems.some((stabi) => {
        return isOverdue(stabi.status === ItemState.DONE, stabi.deadline, localToday);
    });

    if (generalIsOverdue) {
        return StabilityOverallStatus.LATE;
    }

    const customIsOverdue = stabilityViewState.customStabilityItems.some((stabi) => {
        return isOverdue(stabi.status === ItemState.DONE, stabi.deadline, localToday);
    });
    return customIsOverdue ? StabilityOverallStatus.LATE : StabilityOverallStatus.ON_TIME;
};

export const isValidHttpUrl = (link: string) => {
    let url;

    try {
        url = new URL(link);
    } catch (_) {
        return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
};

export const getUrlLink = (url) => {
    return isValidHttpUrl(url) ? url : "https://" + url;
};

export const isValidUrl = (link: string) => {
    return z
        .string()
        .regex(/^(https?):\/\/(?=.*\.[a-z]{2,})[^\s$.?#].[^\s]*$/i)
        .safeParse(link).success;
};
