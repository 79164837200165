import { LabelToggleType } from "@/legacy/components/Canvas/CanvasHostContainer";
import { Tabs, TabsList, TabsTrigger } from "../../ui/tabs";
import { intl } from "@/legacy/GlobalHelperReact";
import { WebAppTelemetryFactory } from "@/app/services/WebAppTelemetry.service";
import { LabelToggleValues } from "@/legacy/components/Canvas/CanvasHostContainer";

type LabelToggle = {
    value: LabelToggleType;
    onChange: (value: string) => void;
};

export function LabelToggle({ value, onChange: handleChange }: LabelToggle) {
    const trackEvent = (tabName) => {
        WebAppTelemetryFactory.trackEvent("whiteboard-tab-switch", { property: tabName });
    };

    return (
        <Tabs value={value} defaultValue={LabelToggleValues.WORKSHOP}>
            <TabsList>
                <TabsTrigger
                    value="workshop"
                    onClick={() => {
                        handleChange(LabelToggleValues.WORKSHOP);
                        trackEvent(LabelToggleValues.WORKSHOP);
                    }}
                >
                    {intl.get("whiteboard.toggle.workshop")}
                </TabsTrigger>
                <TabsTrigger
                    value="plan"
                    onClick={() => {
                        handleChange(LabelToggleValues.PLAN);
                        trackEvent(LabelToggleValues.PLAN);
                    }}
                >
                    {intl.get("whiteboard.toggle.plan")}
                </TabsTrigger>
            </TabsList>
        </Tabs>
    );
}
