import * as React from "react";
import { AlertDialog } from "@/components/common/AlertDialog/AlertDialogV2"
import { intl } from "@/legacy/GlobalHelperReact";

export function CalendarExceptionDeleteDialog({
    exceptionTitle,
    onAccept,
    onDismiss,
}: {
    exceptionTitle: string;
    onDismiss: () => void;
    onAccept: () => void;
}) {
    return (
        <AlertDialog
            open={true}
            onOpenChange={onDismiss}
            title={intl.get("CalendarExceptionMainScreen.DeleteDialog.DeleteTitle")}
            subText={intl.get("CalendarExceptionMainScreen.DeleteDialog.DeleteText") + exceptionTitle}
            onOk={onAccept}
        />
    );
}
