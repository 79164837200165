import { useEffect, useState } from "react";
import {
    SidebarProvider,
    SidebarContent,
    SidebarMenu,
    SidebarMenuItem,
    SidebarMenuButton,
} from "@/components/ui/sidebar";
import * as React from "react";
import { CalendarSettingsView } from "./settings/calendar-settings/CalendarSettings.view.component";
import { StabilityCriteriaSettingsView } from "./settings/stability-criteria";
import { PdfSettingsView } from "./settings/pdf-settings/PdfSettings.view.component";
import { intl } from "lcmd2framework";
import { ICustomizedNavLink, ICustomizedNavLinkGroup } from "./interface";
import { ChangesSettings } from "./settings/changes-settings/changes-settings";
import { useCalendarStore } from "@/app/store/saveCalendarSettings";
import { saveSettings } from "@/utils/SettingsUtils";
import { cn } from "@/lib/utils";
import { WebAppTelemetryFactory } from "@/app/services/WebAppTelemetry.service";

export function Settings({
    navigationOptions,
    subView = "lcmd.settings.pdf",
}: {
    navigationOptions?: ICustomizedNavLinkGroup[] | undefined;
    subView?: string;
}) {
    const calendarSettingsStore = useCalendarStore();
    if (navigationOptions === undefined) {
        navigationOptions = [
            {
                links: [
                    {
                        name: intl.get("Settings.PDFExport"),
                        key: "lcmd.settings.pdf",
                        webtrackKey: "pdf",
                        url: "#",
                        view: <PdfSettingsView />,
                    },
                    {
                        name: intl.get("Settings.Calender"),
                        key: "lcmd.settings.calendar",
                        webtrackKey: "calendar",
                        //TODO: make static text variable
                        url: "#",
                        view: <CalendarSettingsView />,
                    },
                    {
                        name: intl.get("Settings.StabilityCriteria"),
                        key: "lcmd.settings.stability-criteria",
                        webtrackKey: "prerequisites",
                        url: "#",
                        view: <StabilityCriteriaSettingsView />,
                    },
                    {
                        name: intl.get("ReasonCodesSettings.headline"),
                        key: "lcmd.settings.changes-settings",
                        webtrackKey: "changes",
                        url: "#",
                        view: <ChangesSettings />,
                    },
                ],
            },
        ];
    }
    const selectedIndex = (naviOptions: ICustomizedNavLinkGroup[], subViewName: string): ICustomizedNavLink => {
        const view = naviOptions[0].links.find((l) => l.key === subViewName);
        if (!view) {
            console.error(`subView doesn't exist redirect to '${subView}'!`);
        }
        return view ? view : naviOptions[0].links[0];
    };

    const [activeSetting, setActiveSetting] = useState(selectedIndex(navigationOptions, subView));
    useEffect(() => {
        setActiveSetting(selectedIndex(navigationOptions, subView));
    }, [subView]);

    const navItemSelected = (item) => {
        WebAppTelemetryFactory.trackEvent(`settings-tab-${item.webtrackKey}`);
        if (activeSetting.key == "lcmd.settings.calendar") {
            const calendarSettings = calendarSettingsStore.calendarSettings;
            saveSettings(calendarSettings);
        }
        setActiveSetting(item);
    };

    return (
        <>
            <div className="flex w-full flex-row overflow-hidden">
                <div>
                    <SidebarProvider>
                        <SidebarContent className="box-border h-screen min-w-[240px] overflow-y-auto border-r border-solid border-gray-200 bg-white p-2.5">
                            <SidebarMenu>
                                {navigationOptions[0].links.map((item) => (
                                    <SidebarMenuItem key={item.key}>
                                        <SidebarMenuButton
                                            asChild
                                            className="rounded-[6px]"
                                            isActive={activeSetting.key === item.key}
                                        >
                                            <a role="button" onClick={() => navItemSelected(item)}>
                                                <span>{item.name}</span>
                                            </a>
                                        </SidebarMenuButton>
                                    </SidebarMenuItem>
                                ))}
                            </SidebarMenu>
                        </SidebarContent>
                    </SidebarProvider>
                </div>

                <div className={cn("relative", "w-full", activeSetting.description?.length > 0 && "my-2.5")}>
                    <div className="flex min-h-16 items-center border-b border-solid border-gray-200 px-5">
                        <div className="flex flex-col align-middle">
                            <p className="text-xl font-semibold">{activeSetting.name}</p>
                            {activeSetting.description?.length > 0 && (
                                <p className="max-w-[580px] pb-5">{activeSetting.description}</p>
                            )}
                        </div>
                    </div>
                    {activeSetting.view}
                </div>
            </div>
        </>
    );
}
