import { useState } from "react";
import { intl } from "@/legacy/GlobalHelperReact";
import { LcmdModal2 } from "@/components/common/LcmModal2";
import { Input } from "@/components/ui/input";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import {
    CalendarException,
    CalendarExceptionData,
    CalendarExceptionRepeatability,
} from "./calendarSettings.interfaces";
import { UTCDatePickerV2 } from "@/legacy/GlobalHelperFluentUI";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";

export function CalendarExceptionDialog({
    onDismiss,
    onAccept,
    calendarException,
}: {
    onDismiss: () => void;
    onAccept: (data: CalendarExceptionData) => void;
    calendarException?: CalendarException | any;
}) {
    const initialDialogValues = getInitialDialogValues(calendarException);
    const [name, setName] = useState(initialDialogValues.name);
    const [startDate, setStartDate] = useState(initialDialogValues.startDate);
    const [endDate, setEndDate] = useState(initialDialogValues.endDate);
    const [repeat, setRepeat] = useState<CalendarExceptionRepeatability>(
        initialDialogValues.repeat as CalendarExceptionRepeatability,
    );

    const dialogTitle = !calendarException
        ? intl.get("CalendarExceptionMainScreen.title.create")
        : intl.get("CalendarExceptionMainScreen.title.edit");

    const startDateSelected = (date: Date) => {
        setStartDate(date);
        setEndDate(date);
    };

    function getInitialDialogValues(calendarException?: CalendarException) {
        if (!calendarException) {
            // set to zero hours, min, sec, ms
            const d = new Date().setUTCHours(0, 0, 0, 0);
            return {
                name: "",
                startDate: new Date(d),
                endDate: new Date(d),
                repeat: "none",
            };
        }

        const startDate =
            typeof calendarException.value === "string"
                ? new Date(calendarException.value)
                : new Date(calendarException.value[0]);
        const endDate =
            typeof calendarException.value === "string"
                ? new Date(calendarException.value)
                : new Date(calendarException.value[1]);

        return {
            name: calendarException.name ?? "",
            startDate,
            endDate,
            repeat: calendarException.repeat || "none",
        };
    }
    return (
        <LcmdModal2
            open={true}
            size="m"
            onOpenChange={onDismiss}
            header={{ title: dialogTitle }}
            buttons={[
                <Button key="cancel" variant="secondary" onClick={onDismiss}>
                    {intl.get("fw.cancel")}
                </Button>,
                <Button key="ok" onClick={() => onAccept({ name, startDate, endDate, repeat })} disabled={!name}>
                    {intl.get("fw.ok")}
                </Button>,
            ]}
        >
            <Label> {intl.get("CalendarExceptionMainScreen.textFieldName.label")} </Label>
            <Input value={name} className="mt-2 w-[530px]" onChange={(ev) => setName(ev.currentTarget.value)} />
            <div className="flex max-w-[530px] items-center justify-between px-0 py-4">
                <UTCDatePickerV2
                    value={startDate}
                    className="w-64"
                    label={intl.get("CalendarExceptionMainScreen.startDate.label")}
                    onSelectDate={startDateSelected}
                />
                <UTCDatePickerV2
                    value={endDate}
                    className="w-64"
                    label={intl.get("CalendarExceptionMainScreen.endDate.label")}
                    onSelectDate={setEndDate}
                />
            </div>
            <Label> {intl.get("CalendarExceptionMainScreen.choiceGroupRepeat.label")} </Label>
            <RadioGroup
                className="mt-2 flex"
                defaultValue={repeat}
                onValueChange={(value: CalendarExceptionRepeatability) => setRepeat(value)}
            >
                <RadioGroupItem id="none" value="none" />
                <Label className="cursor-pointer" htmlFor="none">
                    {intl.get("CalendarExceptionMainScreen.choiceGroupRepeat.options.none")}
                </Label>
                <RadioGroupItem id="yearly" value="yearly" />
                <Label className="cursor-pointer" htmlFor="yearly">
                    {intl.get("CalendarExceptionMainScreen.choiceGroupRepeat.options.yearly")}
                </Label>
            </RadioGroup>
        </LcmdModal2>
    );
}
