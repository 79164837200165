import { useState } from "react";
import { LcmdModal2, ModalSizeTypes } from "@/components/common/LcmModal2";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { intl } from "@/legacy/GlobalHelperReact";
import { Label } from "../ui/label";
import { WebAppTelemetryFactory } from "@/app/services/WebAppTelemetry.service";

type WhiteboardNameModalProps = {
    isOpen: boolean;
    onOk: (name: string) => void;
    onCancel: () => void;
    name?: string;
};

export function WhiteboardNameModal({ isOpen, onOk, onCancel, name }: WhiteboardNameModalProps) {
    const [_name, setName] = useState(name || "");
    const handleOnOk = (name: string) => {
        WebAppTelemetryFactory.trackEvent("whiteboard-new-created");
        onOk(name);
    };
    const buttons = [
        <Button key="cancel" variant="outline" onClick={onCancel}>
            {intl.get("fw.cancel")}
        </Button>,
        <Button key="createWhiteboard" onClick={() => handleOnOk(_name)}>
            {intl.get("whiteboard.properties.createWhiteboard")}
        </Button>,
    ];

    return (
        <LcmdModal2
            buttons={buttons}
            header={{ title: intl.get("whiteboard.properties.newWhiteboard") }}
            size={ModalSizeTypes.s}
            open={isOpen}
            onOpenChange={onCancel}
        >
            <div className="flex flex-shrink flex-grow flex-col gap-2">
                <Label htmlFor="whiteboardName">{intl.get("whiteboard.properties.nameLabel")}</Label>
                <Input
                    id="whiteboardName"
                    placeholder={intl.get("whiteboard.properties.namePlaceholder")}
                    value={_name || ""}
                    onChange={(event) => setName(event.target.value)}
                />
            </div>
        </LcmdModal2>
    );
}
