import { Pencil, Trash } from "lucide-react";
import { decimalToHex } from "@/components/utils/color/decimal-to-hex";
import { needsWhiteColor } from "@/model/GlobalHelper";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { tradeImagesMap } from "@/components/Sidebar/TradeImages";
import { VCONST0 } from "@/model/DataModel";
import { intl } from "@/legacy/GlobalHelperReact";
import { cn } from "@/lib/utils";

type TradeListItemProps = {
    tradeId: number;
    createDays: number;
    title: string;
    iconName: string;
    backgroundColor: number;
    onTradeEdit?: () => void;
    onTradeDelete?: () => void;
};

const TradeListItem = ({
    tradeId,
    createDays,
    title,
    iconName,
    backgroundColor,
    onTradeEdit,
    onTradeDelete,
}: TradeListItemProps) => {
    const bGColorHex = decimalToHex(backgroundColor);
    const textColor = needsWhiteColor(backgroundColor) ? "text-white" : "text-black";
    const iconOutlineColor = textColor === "text-white" ? "#fff" : "#000";

    const svgIcon = tradeImagesMap.get(iconName || VCONST0.tradeDefaultIcon);

    return (
        <div className="mb-1 inline-flex w-full items-center justify-start gap-5">
            <div
                className="flex h-[54px] w-[85%] touch-none items-center justify-between gap-4 rounded-lg p-2"
                style={{ backgroundColor: `${bGColorHex}` }}
                data-drop-id={"_A" + (tradeId + 1).toString(16)}
                data-drop-days={createDays}
            >
                <div
                    className="flex touch-none items-center justify-center gap-2.5"
                    data-drop-id={"_A" + (tradeId + 1).toString(16)}
                    data-drop-days={createDays}
                >
                    <div className="flex h-7 w-7 items-center justify-center gap-2.5 rounded-[100px] bg-black bg-opacity-20 backdrop-blur-sm">
                        <img src={svgIcon.img} title={svgIcon.title} className="pointer-events-none h-4 w-4" />
                    </div>
                </div>
                <div
                    className="inline-flex h-8 w-[50%] touch-none items-center justify-start"
                    data-drop-id={"_A" + (tradeId + 1).toString(16)}
                    data-drop-days={createDays}
                >
                    <TooltipProvider>
                        <Tooltip>
                            <TooltipTrigger>
                                <div
                                    className={cn(
                                        "w-32 cursor-default touch-none truncate text-left font-['Inter'] text-sm font-semibold leading-tight",
                                        textColor,
                                    )}
                                    data-drop-id={"_A" + (tradeId + 1).toString(16)}
                                    data-drop-days={createDays}
                                >
                                    {title}
                                </div>
                            </TooltipTrigger>
                            <TooltipContent sideOffset={10}>
                                <span>{title}</span>
                            </TooltipContent>
                        </Tooltip>
                    </TooltipProvider>
                </div>
                <div className="flex items-center justify-start">
                    <TooltipProvider>
                        <Tooltip>
                            <TooltipTrigger>
                                <div className="flex cursor-pointer items-center justify-center gap-2 rounded-md p-2 opacity-60">
                                    <Pencil
                                        size={"16px"}
                                        color={iconOutlineColor}
                                        onClick={() => onTradeEdit && onTradeEdit()}
                                    />
                                </div>
                            </TooltipTrigger>
                            <TooltipContent>
                                <span>{intl.get("TradesView.TradeTeaser.MenuTextEdit")}</span>
                            </TooltipContent>
                        </Tooltip>
                    </TooltipProvider>
                    <TooltipProvider>
                        <Tooltip>
                            <TooltipTrigger>
                                <div className="flex items-center justify-center gap-2 rounded-md p-2 opacity-60">
                                    <div className="flex h-4 w-4 cursor-pointer items-center justify-center">
                                        <Trash
                                            size={"16px"}
                                            color={iconOutlineColor}
                                            onClick={() => onTradeDelete && onTradeDelete()}
                                        />
                                    </div>
                                </div>
                            </TooltipTrigger>
                            <TooltipContent>
                                <span>{intl.get("TradesView.TradeTeaser.MenuTextDelete")}</span>
                            </TooltipContent>
                        </Tooltip>
                    </TooltipProvider>
                </div>
            </div>
            <div
                className="flex h-7 w-7 origin-center rotate-45 touch-none rounded"
                data-drop-id={"_A" + (tradeId + 1).toString(16)}
                data-drop-days={0}
                style={{ backgroundColor: `${bGColorHex}` }}
            />
        </div>
    );
};

export { TradeListItem };
