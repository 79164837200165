import * as React from "react";
import { useCanvasTooltipStore } from "../store/tooltipStore";
import "react-toastify/dist/ReactToastify.css";
import { ProcessTooltipWrapper } from "@/components/common/ProcessTooltip/Wrapper";
import { Toaster } from "@/components/ui/sonner";

// this is a component container for components we want to inject on very high DOM level. U can use this to inject modals or tooltips if you need a high dom lvl
export function UtilsContainer() {
    const { task, visible, x, y } = useCanvasTooltipStore();

    return (
        <div className={"invisibleUtilsComponent"}>
            {/* redesigned shadcn Process Tooltip  */}
            {visible ? <ProcessTooltipWrapper canvasData={task} x={x} y={y} /> : null}
            <Toaster />
        </div>
    );
}
