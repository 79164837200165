import { LCMDContextType } from "../../app/LCMContext";
import * as React from "react";
import { useMemo } from "react";
import { CARD_STATUS_TEXT, intl } from "../../legacy/GlobalHelperReact";
import { DefaultButton } from "@fluentui/react";
import { WebAppTelemetryFactory } from "@/app/services/WebAppTelemetry.service";

type StatusButtonProps = {
    LCMD: LCMDContextType;
    data;
};

export function StatusButton({ LCMD, data }: StatusButtonProps) {
    const statusText = useMemo(() => CARD_STATUS_TEXT(intl), [intl]);
    const options = useMemo(
        () => ({
            items: statusText.map((n, i) => ({
                key: i.toString(10),
                text: n,
                onClick: () => {
                    WebAppTelemetryFactory.trackEvent("task-edit-status-updated");
                    LCMD.updateSingleCard(data.card, {
                        s: i,
                    });
                },
            })),
        }),
        [LCMD, data.card, statusText],
    );
    const text = statusText[data.card.s] || "";

    return (
        <DefaultButton
            menuProps={options}
            text={text}
            styles={{
                root: {
                    textAlign: "left",
                },
            }}
        />
    );
}
