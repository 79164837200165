import React, { FormEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { assert, errorToMessage, intl, jsonToError } from "lcmd2framework";
import { StartView } from "./components/start/Start";
import { getLCMD } from "@/app/LCMContext";
import { DetailsView } from "./components/details/Details";
import { PrimaveraView } from "./components/primavera/Primavera";
import { createMemoryRouter, RouterProvider } from "react-router-dom";
import { Paths } from "./types/routes";
import { FileUploadWrapper } from "./components/fileUploadWrapper/FileUploadWrapper";
import { FileUploadPopupViews } from "./types/views";
import { useFileUploadHandlers } from "./components/hooks/useFileUploadHandlers";
import { useFileUploadState } from "./components/hooks/useFileUploadState";
import { DEFAULT_WORKING_WEEK } from "@/lib/constants";

const initialState = {
    view: null,
    data: {
        sub: null,
        auth_token: null,
        upload_result: null,
        file: null,
        details: null,
        clone: false,
        ops: [],
    },
    timer0: 0,
    timer: 0,
};

type FileUploadProps = {
    view: FileUploadPopupViews;
    viewOptions?: {
        files?: FileList | File[];
        clone?: any;
    };
    auth_token: string | null;
    auth_warmup: {
        req: string;
    } | null;
    project: string;
    onDone: (data: any) => void;
    resource_token?: string;
};

export const FileUploadPopup = ({
    onDone,
    project,
    auth_token,
    resource_token,
    viewOptions,
    view: popupView,
}: FileUploadProps) => {
    const LCMD = getLCMD();
    const activeFormRef = useRef(null);

    const { view, data, timer, timer0, setView, setData, ensureTimer } = useFileUploadState(initialState);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date(new Date().setMonth(new Date().getMonth() + 1)));
    const [workingWeekDays, setWorkingWeekDays] = useState(DEFAULT_WORKING_WEEK);
    const { onFileUploadAsync, onCreateProject } = useFileUploadHandlers({
        auth_token,
        project,
        viewOptions,
        resource_token,
        setView,
        LCMD,
    });

    useEffect(() => {
        if (!view) {
            let newViewState = null;
            let newDataState = null;
            switch (popupView) {
                case "welcome":
                case "upload":
                    newViewState = {
                        name: "start",
                        title: intl.get(["legacyProjectDialog", popupView, "title"].join(".")),
                        canSkip: "welcome" === popupView,
                    };
                    break;
                case "login":
                    newViewState = {
                        name: "login",
                        title: intl.get(["legacyProjectDialog", "login", "title"].join(".")),
                        login: LCMD.worker.nav?.login,
                        license: LCMD.worker.nav?.license,
                    };
                    break;
                case "details":
                    newViewState = {
                        name: "details",
                        title: intl.get(["legacyProjectDialog", "details", "title"].join(".")),
                        canSkip: false,
                    };
                    if (resource_token) {
                        let token = null;
                        try {
                            token = JSON.parse(atob(resource_token.split(".")[1]));
                        } catch (e) {
                            token = null;
                        }
                        if (token) {
                            newDataState = {
                                ...(data || {}),
                                upload_result: {
                                    token: token,
                                    resource_token,
                                },
                            };
                        }
                    }
                    break;
            }
            setView((prevState) => ({
                ...prevState,
                ...initialState.view,
                ...newViewState,
            }));
            setData((prevState) => ({
                ...prevState,
                ...initialState.data,
                ...newDataState,
            }));
        }
    }, [popupView, resource_token, data, view]);

    useEffect(() => {
        if (view?.name === "start" && (viewOptions?.files || viewOptions?.clone)) {
            onNext();
        }
    }, [view]);

    const onHide = () => {
        ensureTimer(false);
        setView(initialState.view);
        setData(initialState.data);
        onDone(null);
    };

    const handleOnDone = (data: any) => {
        ensureTimer(false);
        setView(initialState.view);
        setData(initialState.data);
        onDone(data);
    };

    const handleDatesChange = (start: Date, end: Date) => {
        setStartDate(start);
        setEndDate(end);
    };

    const handleWeekDayChange = (workingWeek) => {
        setWorkingWeekDays(workingWeek);
    };

    useEffect(() => {
        const isUploadStatus = view?.nonVisualProps?.upload_status;
        const isUploadResult = view?.nonVisualProps?.upload_result;
        const isError = view?.nonVisualProps?.error;
        const isDone = view?.nonVisualProps?.done;

        if (isUploadStatus || isUploadResult || isError || isDone) {
            onNext();
        }
    }, [view]);

    const uploadFile = (file) => {
        onFileUploadAsync(
            file,
            (error, result) => {
                if (error) {
                    setView((prevView) => ({
                        ...prevView,
                        nonVisualProps: { error },
                    }));
                } else {
                    setView((prevView) => ({
                        ...prevView,
                        nonVisualProps: { upload_result: result },
                    }));
                }
            },
            (status) => {
                setView((prevView) => ({
                    ...prevView,
                    nonVisualProps: { upload_status: status },
                }));
            },
        );
    };

    const cloneProject = (clone) => {
        LCMD.worker.postMessage([
            "project",
            "clone_pre",
            {
                ...viewOptions.clone,
                auth_token,
                cb: LCMD.worker.registerCallback((data) => {
                    if (!data?.result || data.error) {
                        const error = errorToMessage(jsonToError(data?.error || undefined));
                        setView((prevView) => ({
                            ...prevView,
                            nonVisualProps: { error },
                            busy: false,
                            name: "done",
                            title: intl.get("legacyProjectDialog.clone.title"),
                        }));
                        setData(null);
                    } else {
                        assert(data.result);
                        setView((prevView) => ({
                            ...prevView,
                            busy: false,
                            name: "details",
                            title: clone
                                ? intl.get("legacyProjectDialog.clone.title")
                                : intl.get("legacyProjectDialog.empty.title"),
                        }));
                        setData(data.result);
                    }
                }),
            },
        ]);
        setView((prevView) => ({
            ...prevView,
            nonVisualProps: {
                upload_status: { clone, parsing: true, ofs: 0, length: 0 },
            },
        }));
    };

    const onNext = async () => {
        let next = null;
        let nextData = undefined;
        let done = null;
        let callback = null;

        switch (view?.name) {
            case "start":
                if (view.nonVisualProps?.error) {
                    ensureTimer(false);
                    next = {
                        ...view,
                        error: view.nonVisualProps.error,
                        busy: false,
                        nonVisualProps: null,
                    };
                } else if (view.nonVisualProps?.upload_result) {
                    if (
                        view.nonVisualProps?.upload_result.application === "Primavera" ||
                        view.nonVisualProps?.upload_result.application === "Asta"
                    ) {
                        ensureTimer(false);
                        next = {
                            name: "primavera",
                            title: intl.get("legacyProjectDialog.primavera.import"),
                        };
                        nextData = { upload_result: view.nonVisualProps.upload_result };
                    } else {
                        ensureTimer(false);
                        next = {
                            name: "details",
                            title: viewOptions?.clone
                                ? intl.get("legacyProjectDialog.clone.successful")
                                : intl.get("legacyProjectDialog.upload.successful"),
                        };
                        nextData = { upload_result: view.nonVisualProps.upload_result };
                    }
                } else if (view.nonVisualProps?.upload_status) {
                    if (view.busy) {
                        ensureTimer(true);
                    }
                    next = {
                        ...view,
                        status: view.nonVisualProps.upload_status,
                        nonVisualProps: null,
                    };
                } else if (!view.busy) {
                    const input = activeFormRef?.current;
                    const files = viewOptions?.files || input?.files;

                    if (files && files.length > 0) {
                        const file = files[0];
                        next = { ...view, busy: true };
                        nextData = { file };
                        callback = () => uploadFile(file);
                    } else {
                        ensureTimer(false);
                        if (view.canSkip) {
                            if (auth_token) {
                                next = {
                                    name: "done",
                                    title: intl.get("legacyProjectDialog.done.title"),
                                    busy: true,
                                };
                                callback = onCreateProject;
                                nextData = { auth_token };
                            } else {
                                nextData = {};
                                next = {
                                    name: "login",
                                    title: intl.get("legacyProjectDialog.login.title"),
                                    login: LCMD.worker.nav?.login,
                                };
                            }
                        } else {
                            if (auth_token) {
                                const clone = !!viewOptions?.clone;
                                if (clone) {
                                    next = {
                                        name: view.name,
                                        title: intl.get("legacyProjectDialog.clone.title"),
                                        busy: true,
                                    };
                                    callback = () => cloneProject(clone);
                                    nextData = { auth_token: auth_token, clone };
                                } else {
                                    next = {
                                        name: "details",
                                        title: intl.get("legacyProjectDialog.empty.title"),
                                    };
                                    nextData = { auth_token: auth_token };
                                }
                            }
                        }
                    }
                }
                break;
            case "details": {
                const input = activeFormRef.current?.elements?.["formProjectName"];
                const projectName = input?.value || "";
                const details = {
                    name: projectName,
                    customFields: {
                        taktZone: -1,
                        calendar: workingWeekDays,
                    },
                    startDate,
                    endDate,
                };

                if (viewOptions?.clone) {
                    if (data?.clone) {
                        if (view.busy) {
                            next = { ...view, busy: false };
                        } else {
                            next = {
                                name: "done",
                                title: intl.get("legacyProjectDialog.clone.title"),
                                busy: true,
                            };
                            nextData = { details };
                            callback = onCreateProject;
                        }
                    } else {
                        nextData = { auth_token, details };
                        next = {
                            name: "done",
                            title: intl.get("legacyProjectDialog.clone.title"),
                            busy: true,
                        };
                        callback = onCreateProject;
                    }
                } else {
                    if (auth_token) {
                        next = {
                            name: "done",
                            title: data.upload_result
                                ? intl.get("legacyProjectDialog.upload.successful")
                                : intl.get("legacyProjectDialog.details.title"),
                            busy: true,
                        };
                        callback = onCreateProject;
                        nextData = { auth_token, details };
                    } else {
                        nextData = { details };
                        next = {
                            name: "login",
                            title: intl.get("legacyProjectDialog.login.title"),
                            login: LCMD.worker.nav?.login,
                        };
                    }
                }
                break;
            }
            case "primavera": {
                const formActivityCode = activeFormRef.current?.elements?.["formActivityCode"];
                const projectName = data?.upload_result?.props?.name || data?.file?.name;
                const details = {
                    name: projectName,
                    message: "",
                    activityCode: formActivityCode?.value,
                    customFields: {
                        taktZone: -1,
                        calendar: 5,
                    },
                };

                next = {
                    name: "done",
                    title: intl.get("legacyProjectDialog.primavera.import"),
                    busy: true,
                };
                callback = onCreateProject;
                nextData = { auth_token, details };
                break;
            }
            case "done":
                if (view.nonVisualProps?.error) {
                    next = {
                        ...view,
                        error: view.nonVisualProps.error,
                        busy: false,
                        nonVisualProps: null,
                    };
                } else if (view.nonVisualProps?.done) {
                    next = {};
                    done = view.nonVisualProps?.done;
                }
                break;
            default:
                break;
        }
        if (next) {
            if (nextData) {
                setView(next);
                setData((prevData) => ({ ...prevData, ...nextData }));
                if (callback) callback({ ...data, ...nextData });
            } else {
                setView(next);
                if (callback) callback();
            }
        }
        if (done) {
            handleOnDone(done);
        }
    };

    const onSubmit = useCallback(
        (e: FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            onNext();
            return false;
        },
        [onNext],
    );

    const routes = [
        {
            path: Paths.START,
            element: (
                <StartView
                    ref={activeFormRef}
                    view={view}
                    onNext={onNext}
                    onSubmit={onSubmit}
                    timer={timer}
                    timer0={timer0}
                />
            ),
        },
        {
            path: Paths.DETAILS,
            element: (
                <DetailsView
                    ref={activeFormRef}
                    data={data}
                    onSubmit={onSubmit}
                    onDatesChange={(start, end) => handleDatesChange(start, end)}
                    onWorkingWeekChange={(workingWeekDays) => handleWeekDayChange(workingWeekDays)}
                />
            ),
        },
        {
            path: Paths.PRIMAVERA,
            element: (
                <PrimaveraView ref={activeFormRef} projects={data?.upload_result?.activityCodes} onSubmit={onSubmit} />
            ),
        },
    ];

    const router = useMemo(() => {
        if (view) {
            return createMemoryRouter(routes, {
                initialEntries: [`/${view.name}`],
                initialIndex: 1,
            });
        }
    }, [view]);

    useEffect(() => {
        if (view?.name === "done") {
            return;
        }
        if (view?.name) {
            router.navigate(`/${view.name}`);
        }
        if (viewOptions?.clone && router) {
            router.navigate("/details");
        }
    }, [view, router]);

    return (
        <FileUploadWrapper view={view} viewOptions={viewOptions} onDone={onDone} onNext={onNext} onHide={onHide}>
            <RouterProvider router={router} />
        </FileUploadWrapper>
    );
};
