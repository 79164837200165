import { Icon, PrimaryButton, Stack, StackItem } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { Icons } from "../RegisteredIcons";
import { useLCMD } from "../../app/LCMContext";
import { intl } from "@/legacy/GlobalHelperReact";

function PPFilterDialogTitle() {
    return (
        <Stack horizontal styles={{ root: { position: "relative" } }}>
            <StackItem>
                <Icon
                    iconName={Icons.Lcmd_Warning}
                    styles={{ root: { color: "#D83B01", position: "absolute", top: -3, left: -26, fontSize: "20px" } }}
                />
            </StackItem>
            <StackItem styles={{ root: { fontSize: "14px" } }}>
                <b>{intl.get("changesPanel.PPFilterActive.Dialog.Headline")}</b>
            </StackItem>
        </Stack>
    );
}

function PPFilterWarningFooter({ isVisible, onUp }) {
    return (
        isVisible && (
            <Stack
                horizontal
                styles={{
                    root: { height: "40px", backgroundColor: "#F1F3F3", paddingLeft: "16px", paddingTop: "8px" },
                }}
                tokens={{ childrenGap: 16 }}
                verticalAlign={"start"}
            >
                <StackItem>
                    <Icon iconName={Icons.Lcmd_Warning} styles={{ root: { color: "#D83B01", fontSize: "20px" } }} />
                </StackItem>
                <StackItem onClick={onUp} styles={{ root: { paddingLeft: "10px" } }}>
                    <Icon iconName={"ChevronUpMed"} styles={{ root: { color: "#grey" } }} />
                </StackItem>
                <StackItem styles={{ root: { fontSize: "14px", fontWeight: "600" } }}>
                    {intl.get("changesPanel.PPFilterActive.Footer.Text")}
                </StackItem>
            </Stack>
        )
    );
}

export function PPFilterWarningPopUp({ isOpen, onOk, onOpenFilter }) {
    return (
        isOpen && (
            <Stack
                tokens={{ childrenGap: "16" }}
                verticalAlign={"end"}
                styles={{ root: { padding: "10px 30px" } }}
            >
                <StackItem>
                    <PPFilterDialogTitle />
                </StackItem>
                <StackItem styles={{ root: { color: "#565C60" } }}>
                    {intl.get("changesPanel.PPFilterActive.Dialog.Text")}
                </StackItem>
                <StackItem>
                    <Stack horizontal horizontalAlign={"end"} tokens={{ childrenGap: "8" }}>
                        <PrimaryButton
                            text={intl.get("changesPanel.PPFilterActive.Dialog.Buttons.ok")}
                            onClick={onOk}
                        />
                    </Stack>
                </StackItem>
            </Stack>
        )
    );
}

export function PPFilterWarning() {
    const [isFilterActive, setIsFilterActive] = useState(false);
    const [showPopUp, setShowPopUp] = useState(true);

    const LCMD = useLCMD();
    useEffect(() => {
        LCMD.isFilterActive((data: boolean, error) => {
            if (error) {
                throw new Error(error.details.toString());
            }
            setIsFilterActive(data);
        });
    }, []);

    return (
        isFilterActive && (
            <>
                <PPFilterWarningPopUp
                    onOk={() => setShowPopUp(false)}
                    isOpen={showPopUp}
                    onOpenFilter={() => {
                        setShowPopUp(false);
                        LCMD.showDialog("filter", {});
                    }}
                />
                <PPFilterWarningFooter isVisible={!showPopUp} onUp={() => setShowPopUp(true)} />
            </>
        )
    );
}
