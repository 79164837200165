import { useMemo } from "react";
import { intl } from "@/legacy/GlobalHelperReact";

import { ViewTypes } from "../../../constants";

import { Item } from "./ItemV2";
import { Asterisk, CircleDashed, FlipVertical, ListChecks, UserCircle2, LucideIcon } from "lucide-react";
import posthog from "posthog-js";

type ViewTypeOptionProps = {
    selectedType: string;
    onSelect: (type: string) => void;
};

type ViewTypeOption = {
    type: string;
    iconName: LucideIcon;
    label: string;
};

function ViewTypeOption({ selectedType, onSelect }: ViewTypeOptionProps) {
    const viewTypesOptions: ViewTypeOption[] = [
        {
            type: ViewTypes.TRADE,
            iconName: UserCircle2,
            label: intl.get("canvas.cmd.view.types.trade.label"),
        },
        {
            type: ViewTypes.STATUS,
            iconName: CircleDashed,
            label: intl.get("canvas.cmd.view.types.status.label"),
        },
        {
            type: ViewTypes.STABILITY,
            iconName: ListChecks,
            label:
                // eslint-disable-next-line no-constant-condition
                false && posthog.getFeatureFlag("ab-checklist") === "test"
                    ? intl.get("canvas.cmd.view.types.checklist.label")
                    : intl.get("canvas.cmd.view.types.stability.label"),
        },
        {
            type: ViewTypes.CONFLICT,
            iconName: Asterisk,
            label: intl.get("canvas.cmd.view.types.conflict.label"),
        },
        {
            type: ViewTypes.BASELINE,
            iconName: FlipVertical,
            label: intl.get("canvas.cmd.view.types.baseline.label"),
        },
        // Uncomment and add more items if needed
        // {
        //   type: "criticalpath",
        //   iconName: 'AsteriskSolid',
        //   label: intl.get('canvas.cmd.view.types.criticalpath.label'),
        // },
    ];

    const colSpans = useMemo(() => {
        const ITEMS_PER_ROW = 3;
        const totalItems = viewTypesOptions.length;
        const itemsInLastRow = totalItems % ITEMS_PER_ROW || ITEMS_PER_ROW;

        const COL_SPANS = {
            2: "col-span-2",
            3: "col-span-3",
            6: "col-span-6",
        };

        return viewTypesOptions.map((_, index) => {
            const isItemOnLastRow = Math.floor(index / ITEMS_PER_ROW) + 1 === Math.ceil(totalItems / ITEMS_PER_ROW);

            let colSpan = COL_SPANS[2];

            if (isItemOnLastRow) {
                if (itemsInLastRow === 1) {
                    colSpan = COL_SPANS[6];
                } else if (itemsInLastRow === 2) {
                    colSpan = COL_SPANS[3];
                }
            }

            return colSpan;
        });
    }, [viewTypesOptions]);

    return (
        <div className="grid grid-cols-6 gap-1">
            {viewTypesOptions.map((option: ViewTypeOption, i: number) => {
                const { type, iconName, label } = option;
                const selected = selectedType === type;

                return (
                    <Item
                        key={type}
                        type={type}
                        label={label}
                        iconName={iconName}
                        onSelect={onSelect}
                        selected={selected}
                        userflowId={`pp-view-${label}`}
                        colSpan={colSpans[i]}
                    />
                );
            })}
        </div>
    );
}

export { ViewTypeOption };
