﻿import { useCallback } from "react";
import { useLCMD } from "../../app/LCMContext";
import { ChoiceWithCommit, DropdownWithCommit, TextFieldWithCommit } from "../../legacy/GlobalHelperFluentUI";
import { intl } from "@/legacy/GlobalHelperReact";
import { AttachmentFieldWithCommit } from "./AttachmentFieldWithCommit";
import { CardDialogLink } from "./CardDialogLink";
import { Checkbox } from "@/components/ui/checkbox";
import * as React from "react";

type ExtensionFieldProps = {
    field: {
        label: string;
        type: string;
        width: number;
        id: string;
        key: string;
    } & any;
    _;
    value;
    onChange;
    values;
    disabled?;
    atm;
    card: { tid: number; aid: number; i: number };
};

export function ExtensionField(props: ExtensionFieldProps) {
    const LCMD = useLCMD();

    const onChange = useCallback(
        (value) => {
            props.onChange(props.field, value);
        },
        [props.onChange, props.field],
    );

    if (Array.isArray(props.field.dropdown) || "attachment" === props.field.dropdown) {
        return (
            <DropdownWithCommit
                ts={props._}
                key={props.field.key}
                label={props.field.label}
                disabled={props.disabled}
                value={props.value || props.field.default || ""}
                styles={{
                    root: {
                        marginTop: 10,
                        marginBottom: 10,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                    },
                    label: {
                        marginRight: 10,
                    },
                    dropdown: {
                        marginLeft: "auto",
                        flexGrow: "1",
                        flexShrink: "1",
                        maxWidth: props.field.width || undefined,
                    },
                }}
                onChange={(value) => {
                    if ("add" === value?.value) {
                        const button = document.getElementById("fw.attachment.add");
                        if (button) {
                            const cb = LCMD.worker.registerCallback((data) => {
                                if (data?.blobId) {
                                    props.onChange(props.field, {
                                        value: data.blobId,
                                    });
                                }
                            });
                            button.dataset.fwAttachmentCb = cb.toString();
                            button.click();
                        }
                    } else {
                        props.onChange(props.field, value);
                    }
                }}
                dropdown={
                    "attachment" === props.field.dropdown
                        ? [
                              {
                                  blobId: "add",
                                  fileName: intl.get("fw.attachment.dropdown"),
                              },
                          ]
                              .concat(props.atm || [])
                              .map((atm) => ({
                                  key: atm.blobId,
                                  text: atm.fileName || atm.blobId,
                              }))
                        : props.field.dropdown
                }
            />
        );
    }

    if (Array.isArray(props.field.choice)) {
        return (
            <ChoiceWithCommit
                ts={props._}
                key={props.field.key}
                label={props.field.label}
                styles={{
                    root: {
                        marginTop: 10,
                        marginBottom: 10,
                        padding: 0,
                    },
                }}
                value={props.value || props.field.default || ""}
                choices={props.field.choice}
                onRenderField={(choice, _props) => (
                    <ExtensionField
                        card={props.card}
                        field={choice}
                        _={props._}
                        value={(props?.values || {})[choice.key]}
                        onChange={props.onChange}
                        values={props.values}
                        disabled={_props.value !== choice.id}
                        atm={props.atm}
                    />
                )}
                onChange={onChange}
            />
        );
    }

    switch (props.field.type) {
        case "attachment":
            return (
                <AttachmentFieldWithCommit
                    key={props.field.key}
                    label={props.field.label}
                    initialCard={props.card}
                    onChange={onChange}
                />
            );
        case "link":
            return <CardDialogLink props={props} onChange={onChange} />;
        case "checkbox": {
            return (
                <div className="mb-4 flex items-center space-x-2">
                    <Checkbox
                        id={`extension-${props.field.key}`}
                        checked={props.value?.value}
                        onCheckedChange={(checked) => {
                            props.onChange(props.field, { value: checked });
                        }}
                    />
                    <label
                        htmlFor={`extension-${props.field.key}`}
                        className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                    >
                        {props.field.label}
                    </label>
                </div>
            );
        }
        default:
            return (
                <TextFieldWithCommit
                    ts={props._}
                    key={props.field.key}
                    label={props.field.label}
                    type={props.field.type}
                    suffix={props.field.suffix}
                    styles={{
                        wrapper: {
                            marginTop: 10,
                            marginBottom: 10,
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            columnGap: "10px",
                        },
                        fieldGroup: {
                            flexGrow: "1",
                            flexShrink: "1",
                            maxWidth: props.field.width,
                        },
                    }}
                    value={props.value || props.field.default || ""}
                    onChange={onChange}
                    disabled={props.disabled}
                />
            );
    }
}
