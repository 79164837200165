import { intl } from "@/legacy/GlobalHelperReact";
import { AlertDialog } from "@/components/common/AlertDialog/AlertDialogV2";

export function CommentDeleteDialog({ onOk, onCancel }) {
    return (
        <AlertDialog
            open={true}
            onOpenChange={onCancel}
            onOk={() => {
                onOk();
            }}
            title={intl.get("ReasonCodesSettings.delete.secondaryTitle")}
            subText={intl.getHTML("ReasonCodesSettings.delete.confirmCommentQuestion")}
        ></AlertDialog>
    );
}
