import { AlertDialog } from "@/components/common/AlertDialog/AlertDialogV2"
import * as React from "react";
import { intl } from "@/legacy/GlobalHelperReact";

export function StabilityCriteriaDeleteDialog({
    stabilityTitle,
    onDelete,
    onCancel,
}: {
    stabilityTitle: string;
    onCancel: () => void;
    onDelete: () => void;
}) {
    return (
        <AlertDialog
            open={true}
            onOpenChange={onCancel}
            title={intl.get("StabilityCriteria.DeleteDialog.DeleteTitle")}
            subText={intl.getHTML("StabilityCriteria.DeleteDialog.DeleteText", { name: stabilityTitle })}
            onOk={onDelete}
        />
    );
}
