import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { DialogProps } from "@radix-ui/react-dialog";
import { cn } from "@/lib/utils";
import { intl } from "@/legacy/GlobalHelperReact";
import { CalendarIcon } from "lucide-react";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Calendar } from "@/components/ui/calendar";
import { format, isBefore } from "date-fns";
import { Button } from "@/components/ui/button";
import { DateRange } from "react-day-picker";
import { getCurrentLocale } from "@/components/utils/date/locale";

export interface DatePickerProps extends DialogProps {
    // set 'any' type to dates because the range and single date pickers have different types,
    // and shadcn doesn't support combining them.
    date: any;
    onChange: Dispatch<SetStateAction<any>>;
    isSingleDate?: boolean;
    className?: string;
}

export function DatePicker({ date, onChange, isSingleDate, className }: DatePickerProps) {
    const [datePickerDate, setDatePickerDate] = useState<any>(date);
    const [isCalendarOpen, setIsCalendarOpen] = useState(false);
    const defaultMonth = isSingleDate ? datePickerDate : datePickerDate?.from;
    const numberOfMonths = isSingleDate ? 1 : 2;
    const dateFormat = "dd. LLLL y";
    const locale = getCurrentLocale();

    useEffect(() => {
        if (isSingleDate && (date || datePickerDate)) {
            onChange(datePickerDate);
            setIsCalendarOpen(false);
        } else if (datePickerDate?.from && datePickerDate?.to) {
            if (isBefore(datePickerDate.to, datePickerDate.from)) {
                onChange({ from: datePickerDate.to, to: datePickerDate.from });
            } else {
                onChange(datePickerDate);
            }
            setIsCalendarOpen(false);
        }
    }, [datePickerDate, isSingleDate]);

    const handleDayClick = (day: Date) => {
        setDatePickerDate((prev) => {
            if (isSingleDate) {
                return day;
            }
            if (prev?.from && !prev?.to) {
                if (isBefore(day, prev.from)) {
                    return { from: day, to: prev.from };
                }
                return { from: prev.from, to: day };
            }
            return { from: day, to: null };
        });
    };

    const inputValue = useMemo(() => {
        if (isSingleDate && date) {
            return format(date, dateFormat);
        }
        const inputRangeValue = (dateValue) =>
            `${format(dateValue.from, dateFormat, { locale })} - ${format(dateValue.to, dateFormat, { locale })}`;

        if (date?.from && date?.to) {
            if (datePickerDate?.from && !datePickerDate?.to) {
                return intl.get("ToDo.datePickerPlaceholder");
            }
            return inputRangeValue(date);
        }
        return intl.get("ToDo.datePickerPlaceholder");
    }, [isSingleDate, date, datePickerDate]);

    return (
        <div className={cn("grid gap-2", className)}>
            <Popover open={isCalendarOpen} onOpenChange={() => setIsCalendarOpen(!isCalendarOpen)}>
                <PopoverTrigger asChild>
                    <Button
                        id="date"
                        variant={"outline"}
                        className={cn("w-full justify-start text-left font-normal", !date && "text-muted-foreground")}
                    >
                        <CalendarIcon className="mr-2 h-4 w-4" />
                        <span>{inputValue}</span>
                    </Button>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0" align="start">
                    <Calendar
                        initialFocus
                        mode={isSingleDate ? "single" : "range"}
                        defaultMonth={defaultMonth as DateRange & Date}
                        selected={datePickerDate as DateRange & Date}
                        onDayClick={handleDayClick}
                        numberOfMonths={numberOfMonths}
                    />
                </PopoverContent>
            </Popover>
        </div>
    );
}
