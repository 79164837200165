import { MouseEvent, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { intl } from "lcmd2framework";
import { DEFAULT_WORKING_WEEK, workingWeekType } from "@/lib/constants";

const WeekPicker = ({
    onChange,
    value = DEFAULT_WORKING_WEEK,
}: {
    onChange: (weekDays: workingWeekType) => void;
    value: workingWeekType;
}) => {
    const setDay = (ev: MouseEvent<HTMLButtonElement>, day: number) => {
        ev.preventDefault();
        if (value.filter((day) => day).length < 2 && value[day]) return;
        value[day] = !value[day];
        onChange([...value]);
    };

    useEffect(() => {
        if (value.filter((day) => day).length < 1) {
            value = DEFAULT_WORKING_WEEK;
            onChange([...DEFAULT_WORKING_WEEK]);
        }
    }, []);

    const daysTranslations = [
        "calendar.strings.days.sun",
        "calendar.strings.days.mon",
        "calendar.strings.days.tue",
        "calendar.strings.days.wed",
        "calendar.strings.days.thu",
        "calendar.strings.days.fri",
        "calendar.strings.days.sat",
    ];

    return (
        <div className="grid grid-cols-7 gap-2">
            {Array(daysTranslations.length)
                .fill(0)
                .map((_, i) => {
                    const index = (i + 1) % daysTranslations.length;
                    return (
                        <Button
                            key={index}
                            variant={value[index] ? "secondaryDarker" : "outline"}
                            onClick={(ev) => setDay(ev, index)}
                            className="focus:ring-0 focus:ring-offset-0"
                        >
                            {intl.get(daysTranslations[index])}
                        </Button>
                    );
                })}
        </div>
    );
};

export { WeekPicker };
