import { HistoryActionName, HistoryMeta } from "../../../app/interface";
import { decimalToRgb } from "../../utils/color/decimal-to-rgb";
import { calcWorkingEndDateInMs, createUserName, getCardStateText } from "../../Utils";
import { intl } from "../../../legacy/GlobalHelperReact";
import { useEffect, useState } from "react";
import { mergeStyleSets, Shimmer } from "@fluentui/react";
import { useLCMD } from "../../../app/LCMContext";
import { AreaList } from "../../common/areaList/areaList";
import { useCalendarSettings } from "../../hooks/useCalendarSettings.hook";
import { getCurrentLanguage } from "../../utils/date/locale";
import { useUserMap } from "@/components/hooks/userMap.hook";
import { UserMap } from "@/components/hooks/UserMapTypes";

//todo: Props: If there are multiple input values, oldVal,oldSecondValue should be refactored to an array.
export function HistoryTextBlock({
    changeEvent,
    userName,
    oldVal,
    oldSecondValue,
}: {
    changeEvent: HistoryMeta;
    userName: string;
    oldVal?: string | number;
    oldSecondValue?: any;
}): JSX.Element {
    const styles = mergeStyleSets({
        historyText: {
            fontSize: "14px",
            lineHeight: "20px",
        },
        black: {
            color: "#000000",
        },
    });

    const LCMD = useLCMD();
    const userMap: UserMap = useUserMap();
    const users = Object.entries(userMap).map((user) => Object.assign(user[1], { key: user[0] }));
    const hookResult = LCMD.useActionItemName(changeEvent.events[0].id, parseInt(changeEvent.events[0].actionItemId));
    const [actionItemName, setActionItemName] = useState<string>("");
    const [uniquePath, setUniquePath] = useState<boolean>(false);
    const { isLoading: isLoadingCalendarSettings, isError, data: calendarMeta } = useCalendarSettings();

    useEffect(() => {
        setActionItemName(hookResult?.data?.actionItemName || "");
    }, [hookResult]);

    const areaPathes = LCMD.useAreaPaths(changeEvent.events.map((event) => event.id));

    useEffect(() => {
        if (changeEvent.action === HistoryActionName.MULTI_DELETED) {
            const pathes = [];
            if (areaPathes[0].path.length > 0) {
                areaPathes.forEach((areaPath) => {
                    pathes.push(
                        areaPath.path
                            .map((path) => {
                                return path.name;
                            })
                            .join(" > "),
                    );
                });
                const unique = [...new Set(pathes)];
                if (unique.length > 1) {
                    setUniquePath(false);
                }
                if (unique.length === 1) {
                    setUniquePath(true);
                }
            }
        }
    }, [areaPathes]);

    function HistoryEntryValue({
        isLoading,
        value,
        oldValue,
    }: {
        isLoading?: boolean;
        value: string;
        oldValue?: string;
    }) {
        return isLoading === false || isLoading == null ? (
            <p style={{ margin: "8px 0" }}>
                <span hidden={!oldValue}>{oldValue}</span>
                <span>-&gt; {value}</span>
            </p>
        ) : (
            <Shimmer />
        );
    }

    function toUTCFormatString(date: number | string | Date) {
        return new Date(date).toUTCFormatString(getCurrentLanguage(), {
            day: "2-digit",
            month: "long",
            year: "numeric",
        });
    }

    function AreaMovedListEntry({ changeEvent, userName }: { changeEvent: HistoryMeta; userName: string }) {
        const currentAreaName = changeEvent.events[0].processName;
        const LCMD = useLCMD();

        const { isLoading, data: processDetails } = LCMD.useProcessDetails(changeEvent.events.at(0).value);
        const parentAreaName = processDetails?.name?.value;

        return (
            <span className={styles.historyText}>
                <span>
                    {intl.getHTML("HistoryView.DetailsView.AreaMoved", {
                        userName: userName,
                        areaName: currentAreaName,
                    })}
                </span>
                <br />
                <HistoryEntryValue value={parentAreaName} isLoading={isLoading}></HistoryEntryValue>
            </span>
        );
    }

    function CardStatusEdit({
        cardData,
        newStatus,
    }: {
        cardData: { processId: number; cardId: { aid: number; cid: number } };
        newStatus: string;
    }) {
        const LCMD = useLCMD();
        const cardNameData = LCMD.useCardName(cardData);
        const cardName = cardNameData?.data?.cardName || changeEvent.events[0].processName;

        return (
            <span className={styles.historyText}>
                {intl.getHTML("HistoryView.DetailsView.CardStatusChanged", { userName: userName, cardName: cardName })}{" "}
                -&#62; <strong>{newStatus}</strong>
            </span>
        );
    }

    function CardDelete({ cardData }: { cardData: { processId: number; cardId: { aid: number; cid: number } } }) {
        const LCMD = useLCMD();
        const cardNameData = LCMD.useCardName(cardData);
        const cardName = cardNameData?.data?.cardName || changeEvent.events[0].processName;

        return (
            <span className={styles.historyText}>
                {intl.getHTML("HistoryView.DetailsView.CardDeleted", { userName: userName, cardName: cardName })}
            </span>
        );
    }

    function CardAdded({ cardData }: { cardData: { processId: number; cardId: { aid: number; cid: number } } }) {
        const LCMD = useLCMD();
        const cardNameData = LCMD.useCardName(cardData);
        const cardName = cardNameData?.data?.cardName || changeEvent.events[0].processName;

        return (
            <span className={styles.historyText}>
                {intl.getHTML("HistoryView.DetailsView.CardAdded", { userName: userName, cardName: cardName })}
            </span>
        );
    }

    if (isLoadingCalendarSettings) {
        return <Shimmer />;
    }

    if (changeEvent.action === HistoryActionName.PROCESS_DELETED) {
        return (
            <span className={styles.historyText}>
                {intl.getHTML("HistoryView.DetailsView.ProcessDeleted", {
                    userName: userName,
                    processName: changeEvent.events[0].processName,
                })}
                <br />
                -&#62; <AreaList processId={changeEvent.events[0].id} />
            </span>
        );
    }

    if (changeEvent.action === HistoryActionName.PROCESS_CUT) {
        return (
            <span className={styles.historyText}>
                {intl.getHTML("HistoryView.DetailsView.ProcessCut", {
                    userName: userName,
                    processName: changeEvent.events[0].processName,
                })}
                <br />
                -&#62; <AreaList processId={changeEvent.events[0].id} refTimestamp={changeEvent.events[0]._} />
            </span>
        );
    }

    if (changeEvent.action === HistoryActionName.MULTI_DELETED) {
        return (
            <div style={{ display: "flex", flexDirection: "column", rowGap: "15px" }}>
                <span className={styles.historyText}>
                    {intl.getHTML("HistoryView.DetailsView.MultiDeleted", {
                        userName: userName,
                        processes: changeEvent.events.length,
                    })}
                </span>
                {uniquePath ? <AreaList processId={changeEvent.events[0].id} /> : null}
            </div>
        );
    }

    if (changeEvent.action === HistoryActionName.MULTI_PROCESS_CUT) {
        return (
            <div style={{ display: "flex", flexDirection: "column", rowGap: "15px" }}>
                <span className={styles.historyText}>
                    {intl.getHTML("HistoryView.DetailsView.MultiProcessCut", {
                        userName: userName,
                        processes: changeEvent.events.length,
                    })}
                </span>
                {uniquePath ? <AreaList processId={changeEvent.events[0].id} /> : null}
            </div>
        );
    }

    if (changeEvent.action === HistoryActionName.MULTI_PROCESS_CREATED) {
        return (
            <span className={styles.historyText}>
                {intl.getHTML("HistoryView.DetailsView.MultiProcessCreated", {
                    userName: userName,
                    processesNumber: changeEvent.events.length,
                })}
            </span>
        );
    }

    if (changeEvent.action === HistoryActionName.PROCESS_CREATED) {
        const dateString: string = changeEvent?.events[1].value ? toUTCFormatString(changeEvent.events[1].value) : "";
        return (
            <span className={styles.historyText}>
                <span>
                    {intl.getHTML("HistoryView.DetailsView.ProcessCreated", {
                        userName: userName,
                        processName: changeEvent.events[0].processName,
                    })}
                </span>
                <br />
                <p style={{ margin: "8px 0" }}>-&#62; {dateString}</p>
            </span>
        );
    }

    if (changeEvent.action === HistoryActionName.AREA_CREATED) {
        return (
            <span className={styles.historyText}>
                <span>
                    {intl.getHTML("HistoryView.DetailsView.AreaCreated", {
                        userName: userName,
                        areaName: changeEvent.events[0].processName,
                    })}
                </span>
                <br />
            </span>
        );
    }

    if (changeEvent.action === HistoryActionName.AREA_MOVED) {
        return <AreaMovedListEntry changeEvent={changeEvent} userName={userName} />;
    }

    if (changeEvent.action === HistoryActionName.AREA_MULTI_DELETED) {
        return (
            <span className={styles.historyText}>
                {intl.getHTML("HistoryView.DetailsView.AreaMultiDeleted", {
                    userName: userName,
                    processes: changeEvent.events.length,
                })}
            </span>
        );
    }

    if (changeEvent.action === HistoryActionName.AREA_DELETED) {
        const dateString: string = changeEvent?.events[0].date ? toUTCFormatString(changeEvent.events[0].date) : "";
        return (
            <span className={styles.historyText}>
                <span>
                    {intl.getHTML("HistoryView.DetailsView.AreaDeleted", {
                        userName: userName,
                        areaName: changeEvent.events[0].processName,
                    })}
                </span>
                <br />
                <p style={{ margin: "8px 0" }}>
                    -&#62;
                    {dateString}
                </p>
            </span>
        );
    }

    if (changeEvent.action === HistoryActionName.AREA_RENAMED) {
        return (
            <span className={styles.historyText}>
                <span>
                    {intl.getHTML("HistoryView.DetailsView.AreaRenamed", {
                        userName: userName,
                        areaName: changeEvent.events[0].processName,
                    })}
                </span>
                <br />
                <p style={{ margin: "8px 0" }}>
                    <span hidden={!oldVal}>{oldVal} </span>
                    <span style={{ margin: "8px 0" }}>-&#62; {changeEvent.events[0].value}</span>
                </p>
            </span>
        );
    }

    if (changeEvent.action === HistoryActionName.TRADE_CREATED) {
        return (
            <span className={styles.historyText}>
                <span>
                    {intl.getHTML("HistoryView.DetailsView.TradeCreated", {
                        userName: userName,
                        tradeName: changeEvent.events[0].processName,
                    })}
                </span>
                <br />
            </span>
        );
    }

    if (changeEvent.action === HistoryActionName.TRADE_NAME_EDIT) {
        return (
            <span className={styles.historyText}>
                <span>
                    {intl.getHTML("HistoryView.DetailsView.TradeNameChanged", {
                        userName: userName,
                        tradeName: changeEvent.events[0].processName,
                    })}
                </span>
                <br />
                <p style={{ margin: "8px 0" }}>
                    <span hidden={!oldVal}>{oldVal} -&gt; </span>
                    <span>{changeEvent.events[0].value}</span>
                </p>
            </span>
        );
    }

    if (changeEvent.action === HistoryActionName.TRADE_COLOR_EDIT) {
        return (
            <span className={styles.historyText}>
                <span>
                    {intl.getHTML("HistoryView.DetailsView.TradeColorChanged", {
                        userName: userName,
                        tradeName: changeEvent.events[0].processName,
                    })}
                </span>
                <br />
                <div style={{ display: "flex", alignItems: "center", marginTop: 8 }}>
                    <div
                        style={{ height: 15, width: 15, backgroundColor: decimalToRgb(changeEvent.events[0].value) }}
                    ></div>
                </div>
            </span>
        );
    }

    if (
        changeEvent.action === HistoryActionName.PROCESS_DAYS_EDIT &&
        changeEvent.events[0].prop === "days" &&
        changeEvent.events[1].prop === "start"
    ) {
        const oneDay = 24 * 60 * 60 * 1000;
        const currentEndTimestamp = calcWorkingEndDateInMs(
            changeEvent?.events[1]?.value,
            changeEvent.events[0].value,
            calendarMeta,
        );
        const endDate = toUTCFormatString(currentEndTimestamp - oneDay);
        let currentOldEndTimestamp = undefined;
        let oldEndDate = undefined;
        if (oldVal && oldSecondValue && typeof oldVal === "number") {
            currentOldEndTimestamp = calcWorkingEndDateInMs(oldSecondValue, oldVal, calendarMeta);
            oldEndDate = oldSecondValue ? toUTCFormatString(currentOldEndTimestamp - oneDay) : null;
        }

        return (
            <span className={styles.historyText}>
                <span>
                    {intl.getHTML("HistoryView.DetailsView.ProcessDurationChanged", {
                        userName: userName,
                        processName: changeEvent.events[0].processName,
                    })}
                </span>
                <br />
                <p style={{ margin: "8px 0" }}>
                    <span hidden={!oldVal}>
                        {oldVal} {intl.get(`UnitTypes.d`, { value: oldVal || 0 })}{" "}
                    </span>
                    <span>
                        -&gt; {changeEvent.events[0].value}{" "}
                        {intl.get(`UnitTypes.d`, { value: changeEvent.events[0].value })}
                    </span>
                    <br />
                    <span hidden={!oldSecondValue}>{oldEndDate} </span>
                    <span>-&gt; {endDate}</span>
                </p>
            </span>
        );
    }

    if (changeEvent.action === HistoryActionName.MULTI_DAYS_EDIT) {
        const eventTempMap = new Map();
        changeEvent.events.forEach((event) => eventTempMap.set(event.id, [event]));
        return (
            <span className={styles.historyText}>
                <span>
                    {intl.getHTML("HistoryView.DetailsView.MultiDurationChanged", {
                        userName: userName,
                        processes: eventTempMap.size,
                    })}
                </span>
                <br />
            </span>
        );
    }

    if (changeEvent.action === HistoryActionName.PROCESS_TRADE_EDIT) {
        oldVal = oldVal || changeEvent.events[1]?.tradeName;

        return (
            <span className={styles.historyText}>
                <span>
                    {intl.getHTML("HistoryView.DetailsView.ProcessTradeChanged", {
                        userName: userName,
                        processName: changeEvent.events[0].processName,
                    })}
                </span>
                <br />
                {/*@ts-ignore*/}
                <p style={{ margin: "8px 0" }}>
                    <span hidden={!oldVal}>{oldVal} -&gt; </span>
                    <span>{changeEvent.events[0].tradeName}</span>
                </p>
            </span>
        );
    }

    if (changeEvent.action === HistoryActionName.PROCESS_NAME_EDIT) {
        return (
            <span className={styles.historyText}>
                <span>
                    {intl.getHTML("HistoryView.DetailsView.ProcessNameChanged", {
                        userName: userName,
                        processName: changeEvent.events[0].processName,
                    })}
                </span>
                <br />
                <p style={{ margin: "8px 0" }}>
                    <span hidden={!oldVal}>{oldVal} -&gt; </span>
                    <span>{changeEvent.events[0].value}</span>
                </p>
            </span>
        );
    }

    if (changeEvent.action === HistoryActionName.PROCESS_WORKFORCE_EDIT) {
        return (
            <span className={styles.historyText}>
                <span>
                    {intl.getHTML("HistoryView.DetailsView.ProcessWorkforceChanged", {
                        userName: userName,
                        processName: changeEvent.events[0].processName,
                    })}
                </span>
                <br />
                <p style={{ margin: "8px 0" }}>
                    <span hidden={!oldVal}>{oldVal} </span>
                    <span>-&gt; {changeEvent.events[0].value}</span>
                </p>
            </span>
        );
    }

    if (changeEvent.action === HistoryActionName.MULTI_PROCESS_WORKFORCE_EDIT) {
        return (
            <span className={styles.historyText}>
                <span>
                    {intl.getHTML("HistoryView.DetailsView.ProcessMultiWorkforce", {
                        userName: userName,
                        processes: changeEvent.events.length,
                    })}
                </span>
                <br />
                <p style={{ margin: "8px 0" }}>
                    <span hidden={!oldVal}>{oldVal} </span>
                    <span>-&gt; {changeEvent.events[0].value}</span>
                </p>
            </span>
        );
    }

    if (changeEvent.action === HistoryActionName.MULTI_START_EDIT) {
        const eventTempMap = new Map();
        changeEvent.events.forEach((event) => eventTempMap.set(event.id, [event]));
        return (
            <span className={styles.historyText}>
                <span>
                    {intl.getHTML("HistoryView.DetailsView.MultiStartEdit", {
                        userName: userName,
                        processes: eventTempMap.size,
                    })}
                </span>
                <br />
                {/*<p style={{marginTop: 10}}>*/}
                {/*    <span hidden={!oldVal}>{oldVal} -&gt; </span>*/}
                {/*    <span>{changeEvent.events[0].value}</span>*/}
                {/*</p>*/}
            </span>
        );
    }

    if (changeEvent.action === HistoryActionName.MULTI_NAME_EDIT) {
        return (
            <span className={styles.historyText}>
                <span>
                    {intl.getHTML("HistoryView.DetailsView.MultiNameEdit", {
                        userName: userName,
                        processes: changeEvent.events.length,
                    })}
                </span>
                <br />
            </span>
        );
    }
    if (changeEvent.action === HistoryActionName.MULTI_TRADE_EDIT) {
        return (
            <span className={styles.historyText}>
                <span>
                    {intl.getHTML("HistoryView.DetailsView.MultiTradeEdit", {
                        userName: userName,
                        processes: changeEvent.events.length / 2,
                    })}
                    {/*half of the events contain last value of event*/}
                </span>
                <br />
            </span>
        );
    }

    if (changeEvent.action === HistoryActionName.ACTION_ITEM_CREATED) {
        return (
            <span className={styles.historyText}>
                <span>
                    {intl.getHTML("HistoryView.DetailsView.ActionItemCreated", {
                        userName: userName,
                        processName: changeEvent.events[0].processName,
                        actionItemName: actionItemName || "no id found",
                    })}
                </span>
            </span>
        );
    }

    if (changeEvent.action === HistoryActionName.ACTION_ITEM_EDIT) {
        return (
            <span className={styles.historyText}>
                <span>
                    {intl.getHTML("HistoryView.DetailsView.ActionItemEdited", {
                        userName: userName,
                        processName: changeEvent.events[0].processName,
                        actionItemName: actionItemName,
                    })}
                </span>
            </span>
        );
    }

    if (changeEvent.action === HistoryActionName.ACTION_ITEM_DELETED) {
        return (
            <span className={styles.historyText}>
                <span>
                    {intl.getHTML("HistoryView.DetailsView.ActionItemDeleted", {
                        userName: userName,
                        processName: changeEvent.events[0].processName,
                        actionItemName: actionItemName || "no id found",
                    })}
                </span>
                <br />
                <p style={{ margin: "8px 0" }}>
                    <span hidden={!oldVal}>{oldVal}</span>
                    <span> -&gt; {actionItemName}</span>
                </p>
            </span>
        );
    }

    if (changeEvent.action === HistoryActionName.STABILITY_STATUS_EDIT) {
        return (
            <span className={styles.historyText}>
                <span>
                    {intl.getHTML("HistoryView.DetailsView.StabilityStatusEdit", {
                        userName: userName,
                        processName: changeEvent.events[0].processName,
                    })}
                </span>
            </span>
        );
    }

    if (
        changeEvent.action === HistoryActionName.PROCESS_START_AND_DAYS_EDIT ||
        changeEvent.action === HistoryActionName.STRIPEY_MOVE
    ) {
        const d = changeEvent?.events[0].value ? toUTCFormatString(changeEvent?.events[0].value) : "";
        const oldDate = oldVal ? toUTCFormatString(oldVal) : "";
        return (
            <span className={styles.historyText}>
                <span>
                    {intl.getHTML("HistoryView.DetailsView.ProcessStartDateChanged", {
                        userName: userName,
                        processName: changeEvent.events[0].processName,
                    })}
                </span>
                <br />
                <div style={{ margin: "8px 0", display: "flex" }}>
                    <span hidden={!oldSecondValue}>
                        {oldSecondValue} {intl.get(`UnitTypes.d`, { value: oldSecondValue || 0 })}{" "}
                    </span>
                    <span>
                        -&gt; {changeEvent.events[1].value}{" "}
                        {intl.get(`UnitTypes.d`, { value: changeEvent.events[1].value })}
                    </span>
                </div>
                <div style={{ margin: "8px 0", display: "flex" }}>
                    <span hidden={!oldVal} style={{ marginRight: 5 }}>
                        {oldDate}{" "}
                    </span>
                    <span>-&#62; {d}</span>
                </div>
            </span>
        );
    }

    if (changeEvent.action === HistoryActionName.MULTI_START_AND_DAYS_EDIT) {
        const eventTempMap = new Map();
        changeEvent.events.forEach((event) => eventTempMap.set(event.id, [event]));
        return (
            <span className={styles.historyText}>
                <span>
                    {intl.getHTML("HistoryView.DetailsView.MultiStartDateChanged", {
                        userName: userName,
                        processes: eventTempMap.size,
                    })}
                </span>
                <br />
            </span>
        );
    }

    if (
        changeEvent.action === HistoryActionName.PROCESS_STARTDATE_EDIT ||
        (changeEvent.events[1]?.prop === "days" && changeEvent.events[0]?.prop === "start")
    ) {
        const currentEndTimestamp = calcWorkingEndDateInMs(
            changeEvent?.events[0]?.value,
            changeEvent.events[1].value,
            calendarMeta,
        );

        const oneDay = 24 * 60 * 60 * 1_000;
        const endDate = toUTCFormatString(currentEndTimestamp - oneDay);
        let oldEndDate: string | null = null;
        if (oldVal && typeof oldVal === "number") {
            const oldValDate = calcWorkingEndDateInMs(oldVal, changeEvent.events[1].value, calendarMeta);
            oldEndDate = toUTCFormatString(oldValDate - oneDay);
        }

        return (
            <span className={styles.historyText}>
                <span>
                    {intl.getHTML("HistoryView.DetailsView.ProcessDateChanged", {
                        userName: userName,
                        processName: changeEvent.events[0].processName,
                    })}
                </span>
                <br />
                <p>{intl.getHTML("HistoryView.DetailsView.Enddate")}:</p>
                <div style={{ margin: "8px 0", display: "flex" }}>
                    <span hidden={!oldVal} style={{ marginRight: 5 }}>
                        {oldEndDate}{" "}
                    </span>
                    <span>-&#62; {endDate}</span>
                </div>
            </span>
        );
    }

    if (
        changeEvent.action === HistoryActionName.MULTI_STARTDATE_EDIT ||
        (changeEvent.events[1]?.prop === "days" && changeEvent.events[0]?.prop === "start")
    ) {
        return (
            <span className={styles.historyText}>
                <span>
                    {intl.getHTML("HistoryView.DetailsView.ProcessDateChanged", {
                        userName: userName,
                        processName: changeEvent.events[0].processName,
                    })}
                </span>
                <br />
            </span>
        );
    }

    function formatHistoryTextToHandleMentions(historyText: string) {
        if (!historyText) return;

        const regex = /@\{"([^}]+)\"\}/g;
        const match = regex.exec(historyText);

        const username = match ? match[1] : null;

        if (username) {
            historyText = historyText.replace(
                regex,
                `<span class="text-nowrap rounded-full bg-[#ede9fe] px-1 py-0.5 text-xs font-medium leading-5 text-black">@${username}</span>`,
            );
        }

        historyText = historyText.replace(/&lt;br&gt;/gm, "\n");
        historyText = historyText.replace(/<br\s*\/?>/gm, "\n");
        return historyText;
    }

    if (changeEvent.action === HistoryActionName.PROCESS_COMMENT_CREATED) {
        return (
            <span
                className={styles.historyText}
                dangerouslySetInnerHTML={{
                    __html: formatHistoryTextToHandleMentions(
                        intl.get("HistoryView.DetailsView.ProcessAddComment", {
                            userName: userName,
                            comment: changeEvent.events[0].value,
                        }),
                    ),
                }}
            />
        );
    }

    if (changeEvent.action === HistoryActionName.PROCESS_COMMENT_DELETED) {
        return (
            <span className={styles.historyText}>
                {intl.getHTML("HistoryView.DetailsView.ProcessDeletedComment", {
                    userName: userName,
                })}
            </span>
        );
    }

    if (changeEvent.action === HistoryActionName.PROCESS_COMMENT_EDIT) {
        return (
            <span
                className={styles.historyText}
                dangerouslySetInnerHTML={{
                    __html: formatHistoryTextToHandleMentions(
                        intl.get("HistoryView.DetailsView.ProcessEditedComment", {
                            userName: userName,
                            commentNew: changeEvent.events[0].value,
                            commentOld: changeEvent.events[1].value,
                        }),
                    ),
                }}
            />
        );
    }

    if (changeEvent.action === HistoryActionName.PROCESS_PASTE) {
        return (
            <span className={styles.historyText}>
                <span>
                    {intl.getHTML("HistoryView.DetailsView.ProcessPaste", {
                        userName: userName,
                        processName: changeEvent.events[0].processName,
                    })}
                </span>
                <br />
                <p style={{ margin: "8px 0" }}>
                    <span hidden={!oldVal}>{oldVal}</span>
                    <span>
                        {" "}
                        -&gt; <AreaList processId={changeEvent.events[0].id} refTimestamp={changeEvent.events[0]._} />
                    </span>
                </p>
            </span>
        );
    }

    if (changeEvent.action === HistoryActionName.MULTI_PROCESS_PASTE) {
        return (
            <span className={styles.historyText}>
                <span>
                    {intl.getHTML("HistoryView.DetailsView.MultiProcessPaste", {
                        userName: userName,
                        processes: changeEvent.events.length,
                    })}
                </span>
            </span>
        );
    }

    // Card changes
    if (changeEvent.target === "card") {
        const cardData = {
            processId: changeEvent.events[0].id,
            cardId: {
                aid: changeEvent.events[0].card.aid,
                cid: changeEvent.events[0].card.cid,
            },
        };

        if (changeEvent.action === HistoryActionName.CARD_DELETED) {
            return <CardDelete cardData={cardData} />;
        }

        if (changeEvent.action === HistoryActionName.CARD_ADDED) {
            return <CardAdded cardData={cardData} />;
        }

        if (changeEvent.action === HistoryActionName.CARD_STATUS_EDIT) {
            return (
                <CardStatusEdit cardData={cardData} newStatus={getCardStateText(changeEvent.events[0].value, intl)} />
            );
        }
    }
    return null;
}
