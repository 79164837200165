import { intl } from "../../../legacy/GlobalHelperReact";
import * as React from "react";
import { ReasonCodeItem } from "./reason-codes";
import { LcmdModal2, ModalSizeTypes } from "../../common/LcmModal2";
import { Button } from "@/components/ui/button";

export function ReasonCodeDeleteDialog({
    reasonCode,
    onClose,
    onCancel,
}: {
    reasonCode: ReasonCodeItem;
    onCancel: () => void;
    onClose: (itemToDelete: ReasonCodeItem) => void;
}) {
    return (
        <LcmdModal2
            size={ModalSizeTypes.s}
            header={{
                title: intl.get("ReasonCodesSettings.delete.title"),
            }}
            buttons={[
                <Button key="cancel" variant="outline" onClick={onCancel}>
                    {intl.get("ReasonCodesSettings.delete.cancelButton")}
                </Button>,
                <Button key="ok" onClick={() => onClose(reasonCode)}>
                    {intl.get("ReasonCodesSettings.delete.deleteButton")}
                </Button>,
            ]}
            open={true}
            onOpenChange={onCancel}
        >
            <div className={"px-4 py-6"}>
                <div>{intl.getHTML("ReasonCodesSettings.delete.confirmQuestion", { name: reasonCode.name })}</div>
                <div>{intl.get("ReasonCodesSettings.delete.confirmText")}</div>
            </div>
        </LcmdModal2>
    );
}
