import { LucideProps } from "lucide-react";
import React from "react";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";

type IconButton = {
    icon: React.FC<LucideProps>;
    onClick?: () => void;
    className?: string;
    disabled?: boolean;
    userflowId?: string;
    iconColor?: string;
    iconSize?: number;
    iconProps?: {
        size?: number;
        color?: string;
        strokeWidth?: number;
    };
    style?: any;
};

export function IconButton({
    icon: Icon,
    onClick: handleClick,
    className,
    disabled,
    userflowId,
    iconProps,
    style,
}: IconButton) {
    return (
        <Button
            variant={"ghost"}
            size={"icon"}
            className={cn("h-8 w-8 rounded-full", className)}
            onClick={() => handleClick && handleClick()}
            disabled={disabled}
            data-userflow-id={userflowId}
            style={style}
        >
            <Icon size={iconProps?.size || 20} {...iconProps}></Icon>
        </Button>
    );
}
