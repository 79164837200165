import { useLCMD } from "../../app/LCMContext";
import { useEffect, useState } from "react";
import { LCMDHookResult } from "../../app/types/LCMDHookResult";
import { useProcessPlanTimestamp } from "@/app/store/canvasStore";

export function useAllProcessIds() {
    const ppTimestamp = useProcessPlanTimestamp();
    const { worker } = useLCMD();
    const [hookState, setHookState] = useState<LCMDHookResult<number[]>>({
        isLoading: true,
        isError: false,
        error: undefined,
        data: undefined,
    });

    useEffect(() => {
        setHookState({ isLoading: true, isError: false, error: undefined, data: undefined });
        if (!worker) {
            setHookState({ isLoading: false, isError: true, error: new Error("No worker found"), data: undefined });
            return;
        }
        worker.postMessage([
            "getAllProcessIds",
            {
                cb: worker.registerCallback((data) => {
                    setHookState({ isLoading: false, isError: false, error: undefined, data });
                }),
            },
        ]);
    }, [ppTimestamp, worker]);

    return { ...hookState } as const;
}
