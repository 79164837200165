﻿import React, { useEffect, useReducer } from "react";
import * as fluentui from "@fluentui/react";
import { IContextualMenuProps } from "@fluentui/react";
import { CalendarGrid, CanvasRTSession, CanvasViewConst, WorkerSession } from "@/model/DataModel";
import { CanvasCommonProps, CanvasMeta, CanvasViewPort, CanvasViewProps } from "./Canvas";
import { CONST } from "../../settings";
import { intl } from "../../GlobalHelperReact";
import { FrameworkHeader } from "../FrameworkHeader/FrameworkHeader";
import { MainWorkerMessage } from "../../MainWorkerPipe";
import { assert, gfu } from "@/model/GlobalHelper";
import { Bell, Network, Pencil, PencilOff, Redo, Undo } from "lucide-react";
import { Button } from "@/components/ui/button";
import { IconButton } from "@/components/common/IconButton/IconButton";
import { LCMDTooltip } from "@/components/common/Tooltip/LCMDTooltip";
import { EditNameInput } from "@/components/common/EditNameInput";
import { FrameworkRightHelpAlertContract } from "../FrameworkHeader/FrameworkRightHelpAlertContract";
import { CanvasStatusDropdown } from "./CanvasStatusDropdown";
import { useLCMD } from "@/app/LCMContext";
import { DigitalPlanningBoardViewKey } from "lcmd2framework";
import { ConflictsButton } from "@/components/common/ConflictsButton/ConflictsButton";
import { useConflictCount } from "@/app/store/conflictsStore";
import { ActiveBaselineIndicator } from "@/components/common/ActiveBaselineIndicator/ActiveBaselineIndicator";
import { FilterButton } from "@/components/common/FilterButton/FilterButton";
import { PreviewButton } from "@/components/whiteboard/Whiteboard";
import { ViewButton } from "@/components/view/ViewButton";
import { useConflictMode, usePresentingMode, useSetPresentingMode } from "@/app/store/canvasStore";
import { IconSwitch } from "@/components/common/IconSwitch/IconSwitch";
import { WebAppTelemetryFactory } from "@/app/services/WebAppTelemetry.service";

type CanvasStatusHeaderProps = CanvasCommonProps &
    CanvasViewProps & {
        sidebar?: { selected?: DigitalPlanningBoardViewKey };
        filesOverlay: boolean;
        onEditing: () => void;
        hidden: boolean;
    };

type CanvasStatusHeaderState = {
    const: CanvasViewConst;
    meta: CanvasMeta;
    mobile: boolean;
    hidden: boolean;
    message: string | null;
    color: string;
    notifications: any[];
    filter: boolean;
    stats: any[];
    grid: CalendarGrid;
    gpaPreview: boolean;
    ppSynced: boolean;
    wbSynced: boolean;
    dbSynced: boolean;
    ppSessions: CanvasRTSession[];
    wbSessions: CanvasRTSession[];
};

export function CanvasStatusHeader({
    worker,
    hidden,
    meta,
    filesOverlay,
    view,
    projectId,
    projectSandbox,
    onEditing,
    sidebar,
}: CanvasStatusHeaderProps) {
    const LCMD = useLCMD();
    const presentingMode = usePresentingMode();
    const setPresentingMode = useSetPresentingMode();
    const [state, setState] = useReducer(
        (
            current: Partial<CanvasStatusHeaderState>,
            update: { canvas: any; view: CanvasViewPort | null; stateUpdate?: Partial<CanvasStatusHeaderState> },
        ) => {
            return {
                ...(updateState(update.canvas, update.view, current) || current),
                ...update.stateUpdate,
            };
        },
        {
            const: worker.canvas.viewConst,
            meta: null,
            mobile: false,
            hidden: true,
            message: "",
            color: null,
            notifications: [],
            filter: false,
            stats: null,
            grid: null,
            gpaPreview: false,
            ppSynced: false,
            wbSynced: false,
            dbSynced: false,
            ppSessions: [],
            wbSessions: [],
        },
    );

    const conflictCount = useConflictCount();
    const isConflictModeEnabled = useConflictMode();

    const viewMenuPropsTemplate: IContextualMenuProps = {
        items: [
            {
                key: "standard",
                //text: "Standard",
                onClick: () => setStatus("standard"),
            },
            {
                key: "status",
                //text: "Status",
                onClick: () => setStatus("status"),
            },
            {
                key: "stability",
                //text: "Stability",
                onClick: () => setStatus("stability"),
            },
        ],
        isBeakVisible: false,
    };

    let viewMenuProps: IContextualMenuProps = null;

    useEffect(() => {
        worker.registerHandler(handleWorkerMsg);
        if (projectId) {
            setState({
                canvas: null,
                view: null,
                stateUpdate: {
                    hidden: false,
                    message: intl.get("fw.loading"),
                },
            });
        }
        return () => {
            worker.unregisterHandler(handleWorkerMsg);
        };
    }, []);

    function handleWorkerMsg(msg: MainWorkerMessage) {
        switch (msg[0]) {
            case "canvas":
                {
                    const canvas = msg[1];
                    setState({ canvas, view: null });
                }
                break;
            case "view":
                {
                    const view = msg[1];
                    setState({ canvas: null, view: view });
                }
                break;
            case "import":
                {
                    if (msg[1]?.error) {
                        setState({ canvas: null, view: null, stateUpdate: { message: null } });
                    }
                }
                break;
            case "notifications":
                {
                    setState({ canvas: null, view: null, stateUpdate: { notifications: msg[1].notifications } });
                }
                break;
            case "toggle":
                {
                    switch (msg[1]) {
                        case "forceShiftKey":
                            {
                                view.forceShiftKey = msg[2] as boolean;
                            }
                            break;
                    }
                }
                break;
            case "collaborators":
                {
                    if ("canvas" === msg[1].mode) {
                        setState({ canvas: null, view: null, stateUpdate: { ppSessions: msg[1].sessions } });
                    } else if ("wb" === msg[1].mode) {
                        setState({ canvas: null, view: null, stateUpdate: { wbSessions: msg[1].sessions } });
                    }
                }
                break;
            case "framework":
                {
                    switch (msg[1]) {
                        case "closed":
                            {
                                if ("wb" === msg[2]?.mode) {
                                    setState({ canvas: null, view: null, stateUpdate: { wbSessions: [] } });
                                } else {
                                    assert(false); // handle me!
                                }
                            }
                            break;
                    }
                }
                break;
            case "db":
                {
                    switch (msg[1]) {
                        case "sync":
                            {
                                setState({
                                    canvas: null,
                                    view: null,
                                    stateUpdate: { dbSynced: true === msg[2].synced },
                                });
                            }
                            break;
                    }
                }
                break;
        }
    }

    function setStatus(status: string) {
        worker.postMessage(["view", "status", status]);
    }

    function onReopen() {
        const sandbox_name = (projectSandbox as WorkerSession)?.sandbox_name;
        //const sandbox_name=_sandbox_name+" (Reopened "+Date.now().toString()+")";
        worker.postMessage([
            "commit",
            "reopen",
            {
                sandbox_name: sandbox_name,
            },
        ]);
    }

    function onProCoreEdit(r) {
        worker.postMessage(["procore", "edit", {}]);
    }

    function updateState(
        canvas: any,
        view: CanvasViewPort | null,
        state: Partial<CanvasStatusHeaderState>,
    ): Partial<CanvasStatusHeaderState> | undefined {
        const mobile = gfu(view?.mobile, state.mobile, false);
        const meta = canvas?.meta || state.meta;
        const filter = gfu(canvas?.filter, state.filter, false);
        const C = canvas?.viewConst || state.const;
        const stats = canvas ? canvas.stats : state.stats;
        const grid = gfu(canvas?.grid, state.grid, null);
        const gpaPreview =
            undefined !== canvas?.gpaPreview ? true === canvas?.gpaPreview?.enabled : state.gpaPreview || false;
        const ppSynced = canvas?.sync ? canvas.sync.commited === canvas.sync.syncCommited.ofs : state.ppSynced;
        const wbSynced = canvas?.sync
            ? canvas?.sync.wb
                ? canvas.sync.wb.commited === canvas.sync.wb.syncCommited.ofs
                : true
            : state.wbSynced;

        const hasChangedOrHasMessage =
            mobile !== state.mobile ||
            (!mobile && state.message) ||
            C !== state.const ||
            meta !== state.meta ||
            filter !== state.filter ||
            stats !== state.stats ||
            grid !== state.grid ||
            gpaPreview !== state.gpaPreview ||
            ppSynced !== state.ppSynced ||
            wbSynced !== state.wbSynced;

        if (!hasChangedOrHasMessage) {
            return;
        }

        if (mobile) {
            return {
                const: C,
                meta: meta,
                mobile: mobile,
                message: "Mobile View Only!",
                color: CONST.mobileWarningColor,
                hidden: false,
                filter: filter,
                stats: stats,
                grid: grid,
                gpaPreview: gpaPreview,
                ppSynced,
                wbSynced,
            };
        }
        return {
            const: C,
            meta: meta,
            mobile: mobile,
            message: null,
            color: undefined,
            hidden: false,
            filter: filter,
            stats: stats,
            grid: grid,
            gpaPreview: gpaPreview,
            ppSynced,
            wbSynced,
        };
    }

    // ab hier return
    if (state.message) {
        return (
            <div
                className="SH"
                style={{
                    display: state.hidden ? "none" : null,
                    height: CONST.titleHeight,
                    lineHeight: CONST.titleHeight + "px",
                    backgroundColor: state.color || undefined,
                }}
            >
                {state.message}
            </div>
        );
    }

    if (null === viewMenuProps) {
        viewMenuProps = {
            ...viewMenuPropsTemplate,
            items: viewMenuPropsTemplate.items.map((e) => ({
                ...e,
                text: intl.get(["canvas.cmd.view.menu", e.key, "text"].join(".")),
                title: intl.get(["canvas.cmd.view.menu", e.key, "title"].join(".")),
            })),
        };
    }

    const _sandbox = projectSandbox as WorkerSession;
    const procore = (_sandbox as any)?.procore;
    const _meta = meta;
    const sandbox_name = _meta?.name || _sandbox?.sandbox_name;
    const sandbox_ts = _meta?.ts || _sandbox?.sandbox_ts || 0;
    const sandbox_color =
        "number" === typeof _sandbox?.sandbox
            ? state.const.readonly
                ? "#F1F3F3"
                : "admin" === _meta?.role
                  ? "purple"
                  : "yellow"
            : 0 === sandbox_ts && sandbox_name
              ? "yellow" // sandbox
              : sandbox_ts > 0 && sandbox_name
                ? "purple" // merge
                : "#F1F3F3";

    const metaRevId = (_meta as any)?.revId;
    const isSandbox = typeof _sandbox?.sandbox === "number";
    const sandboxCondition =
        sandbox_ts === 0 && sandbox_name
            ? "sandbox"
            : sandbox_ts > 0 && sandbox_name
              ? "merge"
              : procore
                ? intl.get("canvas.roles.readonly")
                : "master";

    const sandbox_text =
        metaRevId > 0
            ? metaRevId.toString(16)
            : isSandbox
              ? _meta?.role
                  ? intl.get(`canvas.roles.${_meta.role}`)
                  : "?"
              : sandboxCondition;

    const editButton =
        0 !== sandbox_ts ? (
            <fluentui.DefaultButton key="reopen" text="Reopen" onClick={onReopen} />
        ) : 0 !== sandbox_ts && state.const.readonly ? (
            <fluentui.DefaultButton key="edit" text="Edit" onClick={onEditing} />
        ) : procore ? (
            <fluentui.DefaultButton key="procore" text="Edit in LCMDigital" onClick={onProCoreEdit} />
        ) : null;
    const selected = sidebar.selected || "project";
    const filesOveray = filesOverlay || false;
    const showNonWorkingDays = !(state.grid?.grid?.length > 1);
    // (state.stats && state.stats["canvas.kappa"]?.view)?true:false
    const splitView: any = state.stats
        ? Object.getOwnPropertyNames(state.stats).reduce((ret, id) => {
              if (id.startsWith("canvas.") && state.stats[id]?.view) {
                  return id;
              } else {
                  return ret;
              }
          }, undefined)
        : undefined;
    const synced = "dailyboard" === selected ? state.dbSynced : state.ppSynced && state.wbSynced;

    const renameProcessPlan = (newName: string) => {
        LCMD.setProjectDetails(
            {
                name: newName,
            },
            (error, data) => {
                if (error) {
                    console.warn(error);
                } else {
                    console.log("successfully renamed process plan: ", data);
                }
            },
        );
    };

    return (
        <>
            {0 === CONST.titleHeight ? null : (
                <FrameworkHeader
                    key={7}
                    activeView={selected}
                    filesOverlay={filesOveray}
                    worker={worker}
                    hidden={state.hidden}
                    metaData={_meta}
                    left={[]}
                    center={[
                        <div key="center" className="flex items-center">
                            <div className={"max-w-80"}>
                                <EditNameInput
                                    selectOnFocus
                                    name={sandbox_name}
                                    saveName={renameProcessPlan}
                                    darkBg
                                    userflowId="pp-name"
                                    trackingEvent="processplan-renamed"
                                    inputClassName="focus:outline-none focus-visible:ring-0 border-0 focus-visible:ring-offset-0"
                                />
                            </div>

                            <CanvasStatusDropdown
                                const={state.const}
                                worker={worker}
                                meta={meta}
                                selected={selected}
                                userflowId="pp-dropdown"
                            />
                        </div>,
                    ]}
                    right={[
                        <FrameworkRightHelpAlertContract
                            key="frahc"
                            worker={worker}
                            sessions={"workshop" === selected ? state.wbSessions : state.ppSessions}
                            synced={synced}
                            selected={selected}
                        />,
                    ]}
                />
            )}
            {hidden ? null : (
                <div
                    key="TH2"
                    className="TH2"
                    style={{
                        display: state.hidden ? "none" : undefined,
                        left: CONST.menuCondensedWidth,
                        top: CONST.titleHeight,
                        height: CONST.subtitleHeight,
                        lineHeight: CONST.subtitleHeight + "px",
                    }}
                >
                    <div className="TH2L">
                        <LCMDTooltip text={intl.get("CommonUse.Undo")} side={"bottom"}>
                            <IconButton key="undo" icon={Undo} onClick={() => worker.postMessage(["undo", {}])} />
                        </LCMDTooltip>
                        <LCMDTooltip text={intl.get("CommonUse.Redo")} side={"bottom"}>
                            <IconButton key="redo" icon={Redo} onClick={() => worker.postMessage(["redo", {}])} />
                        </LCMDTooltip>
                        {state.gpaPreview && <PreviewButton key="preview" />}
                        <div style={{ height: "32px" }}>
                            <LCMDTooltip
                                text={
                                    presentingMode
                                        ? intl.get("fw.tooltip.EnableEditing")
                                        : intl.get("fw.tooltip.DisableEditing")
                                }
                                side="bottom"
                            >
                                <IconSwitch
                                    checked={presentingMode}
                                    onCheckedChange={(val) => {
                                        setPresentingMode(val);
                                        WebAppTelemetryFactory.trackEvent("presentation-mode", {
                                            toggle: val,
                                        });
                                    }}
                                    icons={{ checked: Pencil, unchecked: PencilOff }}
                                    data-userflow-id="PresentationMode"
                                />
                            </LCMDTooltip>
                        </div>
                    </div>
                    {(conflictCount > 0 || isConflictModeEnabled) && <ConflictsButton />}
                    <div className="TH2R">
                        <div className="flex h-8 min-w-fit items-center leading-none" data-userflow-id="pp-ansicht">
                            <ActiveBaselineIndicator />
                            <ViewButton key="view" />
                        </div>
                        <LCMDTooltip
                            text={intl.get("canvas.cmd.tz.text")}
                            className={presentingMode ? "hidden" : ""}
                            key={"taktzonesTooltip"}
                            userflowId="pp-bereiche"
                            side="bottom"
                        >
                            <IconButton
                                key={"taktzonesBtn"}
                                icon={Network}
                                onClick={() => {
                                    WebAppTelemetryFactory.trackEvent("area-menu-opened");
                                    worker.dispatchMessage(["toggle", "taktzones", {}]);
                                }}
                            />
                        </LCMDTooltip>
                        <LCMDTooltip
                            text={intl.get("canvas.cmd.changes.text")}
                            key={"changesTooltip"}
                            userflowId="pp-konflikt-icon"
                            side="bottom"
                        >
                            <IconButton
                                key={"changesBtn"}
                                icon={Bell}
                                onClick={() => {
                                    worker.dispatchMessage(["toggle", "changes", {}]);
                                }}
                            />
                        </LCMDTooltip>
                        <FilterButton
                            checked={state.filter}
                            key="filter"
                            userflowId="pp-drop-down-filter"
                            userflowId2="pp-filter-button"
                            webtrackingPrefix="pp"
                        />
                        <Button
                            className="h-8"
                            variant="darkHover"
                            onClick={() => {
                                WebAppTelemetryFactory.trackEvent("share-project", { where: "canvas" });
                                LCMD.showDialog("fw.project.share");
                            }}
                            userflowId="pp-projekt-teilen"
                        >
                            {intl.get("canvas.cmd.share.title")}
                        </Button>
                    </div>
                </div>
            )}
        </>
    );
}
