import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { NonProgressBar, intl } from "lcmd2framework";
import { UploadCloud } from "lucide-react";
import { Button } from "@/components/ui/button";
import { FileUploadPopupState } from "../../types/initialState";

type StartViewProps = {
    view: FileUploadPopupState["view"];
    onNext: () => void | Promise<void>;
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => boolean;
    timer: number;
    timer0: number;
};

export const StartView = forwardRef<HTMLInputElement, StartViewProps>(
    ({ view, onNext, onSubmit, timer, timer0 }, ref) => {
        const inputRef = useRef<HTMLInputElement>(null);

        useImperativeHandle(ref, () => inputRef.current);

        const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            e.preventDefault();
            inputRef.current?.click();
        };

        return (
            <div className="flex min-h-[400px] w-full flex-col justify-center rounded-lg bg-slate-100">
                <form
                    onSubmit={onSubmit}
                    className="relative flex min-h-[400px] w-full flex-col items-center justify-center rounded-lg bg-slate-100 text-center"
                >
                    {view.status?.parsing ? (
                        <span>
                            {view.status?.clone
                                ? "TODO CLONE"
                                : intl.get("legacyProjectDialog.upload.processing", {
                                      totalMB: Math.ceil(view.status.length / 1024 / 1024),
                                  })}
                        </span>
                    ) : view.status ? (
                        view.status.ofs > 0 ? (
                            <span>
                                {intl.get("legacyProjectDialog.upload.uploading", {
                                    ofs: view.status.ofs,
                                    length: view.status.length,
                                    percent: Math.ceil((view.status.ofs * 100) / view.status.length),
                                })}
                            </span>
                        ) : (
                            <span>
                                {intl.get("legacyProjectDialog.upload.uploading2", {
                                    totalMB: Math.ceil(view.status.length / 1024 / 1024),
                                })}
                            </span>
                        )
                    ) : (
                        <div className="flex w-96 flex-col items-center justify-center gap-4 text-center">
                            <input
                                type="file"
                                className="absolute h-full w-full cursor-pointer opacity-0"
                                ref={inputRef}
                                onChange={onNext}
                                name="file"
                            />
                            <img
                                alt="upload"
                                className="mx-auto"
                                src="/img/3d-folder-icon.png"
                                width={160}
                                height={160}
                            />
                            <div className="mx-auto text-sm font-normal leading-6 text-slate-500">
                                {intl.get("legacyProjectDialog.upload.content")}
                            </div>
                            <div className="cursor-pointer">
                                <Button className="border-[#8a8886]" onClick={handleButtonClick}>
                                    <UploadCloud size={16} className="mr-2" />
                                    {intl.get("legacyProjectDialog.upload.uploadFile")}
                                </Button>
                            </div>
                        </div>
                    )}
                    {view.status?.parsing ||
                        (view.status && (
                            <NonProgressBar
                                timer={timer}
                                timer0={timer0}
                                timerLabel={view.status.clone && "TODO CLONE TIME"}
                            />
                        ))}
                </form>
            </div>
        );
    },
);

StartView.displayName = "StartView";
