import {
    CalendarGrid,
    CanvasAppConst,
    CanvasCalendarHeader,
    CanvasStripePatchData,
    CanvasTaskData,
    CanvasViewConst,
    CanvasViewMeta,
    CANVAS_FONT,
    DataModel,
    DataOperationTarget,
} from "@/model/DataModel";
import type { CanvasStripeData } from "@/model/DataModel";
import { CONST } from "../../../settings";
import { assert, EpochDaystoEpochMS } from "@/model/GlobalHelper";
import { lerp } from "./CanvasUtils";
import { LCMDContextDependencyType } from "./CanvasHostBase";
import { useCanvasStore } from "@/app/store/canvasStore";
import { useUserJourneyStore } from "@/app/store/userJourneyStore";
import { ViewTypes } from "@/components/view/constants";
// import { LCMDContextDependencyType } from "../../../../app/LCMContext";

export type CanvasHostRenderNode = {
    level?: number; // for TaktzoneHost
    __x: number;
    __y: number;
    __w: number;
    __h: number;
    __f: number; // flag
    __z: number; // z index helper
};
export type CanvasHostStripeRenderNode = CanvasHostRenderNode & {
    __t: number; // task index
    segs?: (number | string)[]; // for TaktZones
};
type CanvasMessageData = {};

const TARGET_SHIFT = 32 - 3;
const TARGET_MASK = 0x7;
const TARGET_MASK_ID = ~(TARGET_MASK << TARGET_SHIFT) >>> 0;
const STRIPE_FLAG = 0x80000000 >>> 0;
const STRIPE_MASK = 0x7fffffff >>> 0;
const INVALID_VALUE = 0xffffffff >>> 0;

export type ICanvasHostRenderer = {
    getImage(image: string, w: number, h: number): CanvasImageSource;
    renderOverlay(rc: CanvasHostRendererCtx, ctx: CanvasRenderingContext2D);
    renderHell(rc: CanvasHostRendererCtx, ctx: CanvasRenderingContext2D);
    renderHeaven(rc: CanvasHostRendererCtx, ctx: CanvasRenderingContext2D, isVisible: boolean);
    renderEnd(rc: CanvasHostRendererCtx, ctx: CanvasRenderingContext2D);
    renderStripeHell(
        rc: CanvasHostRendererCtx,
        ctx: CanvasRenderingContext2D,
        stripe: CanvasStripeData & CanvasHostStripeRenderNode,
        i_stripe: number,
    );
    renderStripeBegin(
        rc: CanvasHostRendererCtx,
        ctx: CanvasRenderingContext2D,
        stripe: CanvasStripeData & CanvasHostStripeRenderNode,
        i_stripe: number,
    );
    renderStripeEnd(
        rc: CanvasHostRendererCtx,
        ctx: CanvasRenderingContext2D,
        stripe: CanvasStripeData & CanvasHostStripeRenderNode,
    );
    renderTask(
        rc: CanvasHostRendererCtx,
        ctx: CanvasRenderingContext2D,
        task: CanvasTaskData & CanvasHostStripeRenderNode,
        drawOutline?: boolean,
        tradeName?: string,
        isBaseline?: boolean,
        baselineColor?: string,
    );
    renderSelectedBaselineHighlight(
        rc: CanvasHostRendererCtx,
        ctx: CanvasRenderingContext2D,
        task: CanvasTaskData & CanvasHostStripeRenderNode,
        baselineTask: CanvasTaskData & CanvasHostStripeRenderNode,
    );
    renderDependency(
        rc: CanvasHostRendererCtx,
        ctx: CanvasRenderingContext2D,
        task: CanvasTaskData & CanvasHostRenderNode,
    );
    selChanged(ev: PointerEvent);

    onAddDependency(srcPid: number, tgtPid: number, dependencyType: LCMDContextDependencyType);

    drawTaskSelectedSide(ctx: CanvasRenderingContext2D, task: CanvasTaskData & CanvasHostRenderNode);

    onHoverProcess(
        task: CanvasTaskData & CanvasHostStripeRenderNode,
        points: { mouse: { x: number; y: number }; task: { x: number; y: number } },
        scale: number,
        dynamicFontSize: boolean,
    );

    onLeaveProcess();

    renderCollapsedStripe?(rc: CanvasHostRendererCtx, ctx: CanvasRenderingContext2D, stripe: CanvasStripeData): void;
};

export type CanvasHostRendererDragInfo = {
    dx: number;
    dy: number;
    tx: number;
    ty: number;
    sx: number;
    sy: number;
    task?: CanvasTaskData;
    stripe?: CanvasStripeData;
};

export type CanvasHostRendererDropInfo = {
    trade: {
        ref: string; // project id
        target: DataOperationTarget;
        id: number;
        name: string;
        color: number;
    };
    lib: {
        ref: string; // project id
        target: DataOperationTarget;
        id: number;
    };
    x: number;
    y: number;
    w: number;
    h: number;
    pid?: number; // stripe process id
};

export type CanvasHostRendererSidebarItem = {
    stripe: CanvasStripeData & CanvasHostStripeRenderNode;
    j: number;
    top: number;
    left: number;
    width: number;
    height: number;
    color: string;
    segs: (number | string)[];
    rotated: boolean;
};

export type CanvasHostRendererSidebarLevelInfo = {
    n: number;
    m: number; // min
    t: number; // total
};

export type CanvasHostRendererCtx = {
    view: {
        scale: number;
        x1: number;
        y1: number;
        x2: number;
        y2: number;
        insetTop: number;
        insetLeft: number;
        insetRight: number;
        insetBottom: number;
    };
    A: CanvasAppConst;
    C: CanvasViewConst;
    M: CanvasViewMeta;
    grid: CalendarGrid;
    today: number | undefined; // today in grid
    header: CanvasCalendarHeader;
    stripes: (CanvasStripeData & CanvasHostStripeRenderNode)[];
    l_min: number;
    l_max: number;
    sidebar: CanvasHostRendererSidebarItem[];
    sidebarL: CanvasHostRendererSidebarLevelInfo[];
    taskICount: number; // what is that and why is that needed (wrote this 2 day ago ... AHHHH)
    selStripeDx: number; // -------------------------------- " ---------------------------------
    selStripeDy: number; // -------------------------------- " ---------------------------------
    selXGroup: (CanvasTaskData & CanvasHostRenderNode) | null;
    tasks: (CanvasTaskData & CanvasHostRenderNode)[];
    /*
    hitStripe:CanvasStripeData&CanvasHostStripeRenderNode;
    hitTask:CanvasTaskData&CanvasHostRenderNode;
    hitCanvasX:number;
    hitCanvasY:number;
    hitStripeX:number;
    hitStripeY:number;
    hitStripeLeft:number;
    hitStripeTop:number;
    hitStripeGridX:number;
    hitStripeGridY:number;
    hitStripeGridDate:number;
    */
    hit: CanvasHostHitResult | null;
};

export type CanvasHostHitResult = {
    hitStripe: CanvasStripeData & CanvasHostStripeRenderNode;
    hitTask: CanvasTaskData & CanvasHostRenderNode;
    hitBaselineTask: boolean;
    hitSrcTaskId: null | number;
    hitSrcTaskLeft: null | boolean;
    hitTaskLeft: null | boolean;
    hitCanvasX: number;
    hitCanvasY: number;
    hitStripeX: number;
    hitStripeY: number;
    hitStripeLeft: number;
    hitStripeTop: number;
    hitStripeGridX: number;
    hitStripeGridY: number;
    hitStripeGridDate: number;
    hitStripeHeader: boolean;
    hitSidebar: CanvasHostRendererSidebarItem;
    hitSidebarX: number;
    hitSidebarY: number;
    hitSidebarImage: boolean;
    hitDep: [number, number];
};

export type CanvasHostRendererUserMeta = { [key: string]: any };

export class CanvasHostRenderer {
    public C: CanvasViewConst = null;
    public M: CanvasViewMeta = null;
    public grid: CalendarGrid = null;
    private today: number | undefined = undefined; // today in grid
    private stripes: (CanvasStripePatchData & CanvasHostStripeRenderNode)[] = [];
    private l_min: number = 0;
    private l_max: number = 0;
    public tasks: (CanvasTaskData & CanvasHostRenderNode)[] = [];
    private messages: (CanvasMessageData & CanvasHostRenderNode)[] = [];
    private header: CanvasCalendarHeader = null;
    private sel: Uint32Array = null;
    public selCount: number = 0;
    private selIndex: Uint32Array = null;
    private selIndexCount: number = 0;
    private selZ: Uint32Array = null;
    private stripeI: Uint32Array = null;
    private stripeICount: number = 0;
    private taskI: Uint32Array = null;
    private taskICount: number = 0;
    private commentI: Uint32Array = null;
    private commentICount: number = 0;
    private sidebar: CanvasHostRendererSidebarItem[] = [];
    private sidebarL: CanvasHostRendererSidebarLevelInfo[] = [];
    private bboxX1: number = null;
    private bboxY1: number = null;
    private bboxX2: number = null;
    private bboxY2: number = null;
    private bboxHit: {
        stripe?: any;
        task?: any;
    } = null;
    private selDx: number = 0;
    private selDy: number = 0;
    private selSx: number = 1;
    private selSy: number = 1;
    private selStripeDx: number = 0;
    private selStripeDy: number = 0;
    private selStripeTx: number = 0;
    private selStripeTy: number = 0;
    private selXGroup: (CanvasTaskData & CanvasHostRenderNode) | null = null;
    public selStripeSx: number = 1;
    private selStripeSy: number = 1;
    private selHit: CanvasHostHitResult = null;
    private readonly zOrderStripes;
    private readonly xGroupTasks;
    public readonly eventHitTest;
    private renderer: ICanvasHostRenderer = null;
    private userMeta: CanvasHostRendererUserMeta = {};

    public lineVisibilityThreshold: number;
    public roundedCornersThreshold: number;
    public iconVisibilityThreshold: number;
    public textVisibilityThreshold: number;

    constructor(opt: { zOrderStripes: boolean; xGroupTasks: boolean; eventHitTest: boolean }) {
        this.zOrderStripes = opt.zOrderStripes;
        this.xGroupTasks = opt.xGroupTasks;
        this.eventHitTest = opt.eventHitTest;

        this.lineVisibilityThreshold = 0.4;
        this.roundedCornersThreshold = 0.1;
        this.iconVisibilityThreshold = 0.1;
        this.textVisibilityThreshold = 0.1;

        // window.onkeydown = (e: KeyboardEvent) => {
        //     switch (e.code) {
        //         case "KeyC":
        //             if (e.shiftKey) {
        //                 this.roundedCornersThreshold -= 0.1;
        //             } else {
        //                 this.roundedCornersThreshold += 0.1;
        //             }
        //             console.log(
        //                 `Current Scale = ${this.view.scale}\nRounded Corners Threshold = ${this.roundedCornersThreshold}\n===================`,
        //             );
        //             break;
        //         case "KeyI":
        //             if (e.shiftKey) {
        //                 this.iconVisibilityThreshold -= 0.1;
        //             } else {
        //                 this.iconVisibilityThreshold += 0.1;
        //             }
        //             console.log(
        //                 `Current Scale = ${this.view.scale}\nIcons Threshold = ${this.iconVisibilityThreshold}\n===================`,
        //             );
        //             break;
        //         case "KeyL":
        //             if (e.shiftKey) {
        //                 this.lineVisibilityThreshold -= 0.1;
        //             } else {
        //                 this.lineVisibilityThreshold += 0.1;
        //             }
        //             console.log(
        //                 `Current Scale = ${this.view.scale}\nLine Threshold = ${this.lineVisibilityThreshold}\n===================`,
        //             );
        //             break;
        //         case "KeyT":
        //             if (e.shiftKey) {
        //                 this.textVisibilityThreshold -= 0.1;
        //             } else {
        //                 this.textVisibilityThreshold += 0.1;
        //             }
        //             console.log(
        //                 `Current Scale = ${this.view.scale}\nText Threshold = ${this.textVisibilityThreshold}\n===================`,
        //             );
        //             break;
        //     }
        // };
    }

    public getSelStripeSx() {
        return this.selStripeSx;
    }

    public setRenderer(renderer: ICanvasHostRenderer) {
        this.renderer = renderer;
    }

    public getSuccessor(
        task: CanvasTaskData,
    ): Array<[CanvasTaskData & CanvasHostRenderNode, LCMDContextDependencyType]> {
        if (!task?.deps?.rs) {
            return [];
        }

        const result = [];
        for (const id in task.deps.rs) {
            const foundTask = this.tasks.find((t) => t.id === +id);
            if (foundTask) {
                result.push([foundTask, task.deps.rs[id].type]);
            }
        }
        return result;
    }
    public getPredecessor(
        task: CanvasTaskData,
    ): Array<[CanvasTaskData & CanvasHostRenderNode, LCMDContextDependencyType]> {
        if (!task?.deps?.rp) {
            return [];
        }
        const result = [];
        for (const id in task.deps.rp) {
            const foundTask = this.tasks.find((t) => t.id === +id);
            if (foundTask) {
                result.push([foundTask, task.deps.rp[id].type]);
            }
        }
        return result;
    }

    private static _ensureSelCapacity(sel: Uint32Array, count: number, capacity: number) {
        if (null === sel || capacity >= sel.length) {
            let c = 100;
            while (c < capacity) c = c * 2;
            const _sel = new Uint32Array(c);
            if (null !== sel && count > 0) {
                _sel.set(sel); //@TODO only use a view 0...count
            }
            /*{ //DEBUG: remove me!
                for(let i=0;i<count;i++) {
                    assert(sel[i]===_sel[i]);
                }
            }*/
            sel = _sel;
        }
        return sel;
    }

    private _findSelIndex(target: DataOperationTarget, id: number) {
        const sel = this.sel;
        const index = this.selIndex;
        const n_index = this.selIndexCount;
        assert((id & TARGET_MASK_ID) === id);
        const v = (id | (target << TARGET_SHIFT)) >>> 0;
        let i = 0;
        let j = n_index;
        while (i < j) {
            const k = i + Math.floor((j - i) / 2);
            const _k = index[k];
            const _v = sel[_k];
            if (v < _v) {
                j = k;
            } else if (v > _v) {
                i = k + 1;
            } else {
                //assert(sel.slice(0, this.selCount).indexOf(id)===_k); // DEBUG REMVOE ME!
                return _k;
            }
        }
        //assert(0===this.selCount || sel.slice(0, this.selCount).indexOf(id)===-1); // DEBUG REMVOE ME!
        return -1;
    }

    private _updateSelIndex(updateIfNeeded?: boolean, ev?: PointerEvent) {
        const sel = this.sel;
        const n_sel = this.selCount;
        assert(0 <= n_sel && n_sel <= (sel?.length || 0));
        this.selIndex = new Uint32Array(n_sel); //@TODO fix me CanvasHostRenderer._ensureSelCapacity(this.selIndex, 0, n_sel);
        const index = this.selIndex;
        const n_index = n_sel;
        for (let i = 0; i < n_index; i++) index[i] = i;
        index.sort((i, j) => {
            const a = this.sel[index[i]];
            const b = this.sel[index[j]];
            return a < b ? -1 : a > b ? +1 : 0;
        });
        this.selIndexCount = n_index;
        if (false !== updateIfNeeded) {
            this.updateIfNeeded(
                this.C,
                this.M,
                this.grid,
                this.stripes,
                this.l_min,
                this.l_max,
                this.tasks,
                this.messages,
                this.header,
                true,
            );
        }
        this.renderer.selChanged(ev);
    }

    public select(target: DataOperationTarget, id: number, ev?: PointerEvent, group?: boolean) {
        //console.log("SELECT "+id);
        this.selIndexCount = 0; // invalidate
        this.sel = CanvasHostRenderer._ensureSelCapacity(this.sel, this.selCount, this.selCount + 1);
        assert((id & TARGET_MASK_ID) === id);
        const v = (id | (target << TARGET_SHIFT)) >>> 0;
        const a = this.sel;
        const n = this.selCount;
        let i = 0;
        while (i < n && a[i] !== v) i++;
        if (n === i) {
            a[i] = v;
            this.selCount++;
        } else {
            assert(i < n && a[i] === v); // already there
        }
        if (!group) {
            this._updateSelIndex(undefined, ev);
        }
    }

    public unselect(target: DataOperationTarget, id: number, group?: boolean) {
        //console.log("UNSELECT "+id);
        this.selIndexCount = 0; // invalidate
        assert((id & TARGET_MASK_ID) === id);
        const v = (id | (target << TARGET_SHIFT)) >>> 0;
        const a = this.sel;
        const n = this.selCount;
        let i = 0;
        while (i < n && a[i] !== v) i++;
        if (i < n) {
            // found
            for (; i + 1 < n; i++) a[i] = a[i + 1];
            this.selCount--;
            if (!group) {
                this._updateSelIndex();
            }
        }
    }

    public clearSelect(group?: boolean) {
        //console.log("CLEARSELECT");
        if (this.selCount > 0) {
            this.selIndexCount = 0; // invalidate
            this.selCount = 0;
            if (!group) {
                this._updateSelIndex();
            }
        }
    }

    private _updateBBox({ __x, __y, __w, __h }, target: { stripe?: any; task?: any }) {
        const x2 = __x + __w;
        const y2 = __y + __h;
        // bbox will be only applied to one task, because the big box is not needed anymore in the new design
        if (null === this.bboxX1) this.bboxX1 = __x;
        if (null === this.bboxY1) this.bboxY1 = __y;
        if (null === this.bboxX2) this.bboxX2 = x2;
        if (null === this.bboxY2) this.bboxY2 = y2;
        if (target) {
            this.bboxHit = target;
        }
    }

    private _updateSidebar() {
        const { C, l_min, l_max, stripes } = this;
        // const tasks = this.tasks;
        const n_stripes = stripes.length;
        // const n_tasks = tasks.length;

        this.sidebar = [];
        this.sidebarL = new Array(l_max - l_min);

        if (this.zOrderStripes /*@TODO add new param, like generateHeader :-) */) {
            return;
        }

        // generade "sidebar". @TODO only generate when stripe changes...
        const scale = 1;
        let top = 0;
        let deepestSidebarItem: number = 0;

        for (let i_stripe = 0; i_stripe < n_stripes; i_stripe++) {
            const s = i_stripe > 0 ? stripes[i_stripe - 1].e : 0;
            const e = stripes[i_stripe].e;
            // const override = stripes[i_stripe]?.override;
            // const overrideProps = override ? stripes[i_stripe]?.overrideProps : undefined;
            let marginLeft = CONST.menuCondensedWidth;
            const headerWidth = CONST.sidebarColWidth;
            // const headerFSMin = 6;
            // const headerFSMax = 20;
            const n_j = stripes[i_stripe]._h.length;
            // const imageLink = stripes[i_stripe]._image ? "url(" + stripes[i_stripe]._image + ")" : undefined;
            const whiteColor = "#FFFFFF";
            const greyColor = "#F1F1F1";

            for (let j = l_min; j < n_j; j++) {
                const s = stripes[i_stripe]._s[j];

                if (-1 === s) {
                    marginLeft += headerWidth;
                    continue;
                }

                // const h = stripes[i_stripe]._h[j];
                // const color=stripes[i_stripe]._c[j].toString(16).padStart(6, '0');
                const e0 = i_stripe > 0 ? stripes[i_stripe - 1].e : 0;
                const e1 = stripes[i_stripe + s].e;

                assert(i_stripe + s < n_stripes);

                let hSpan = 0;

                while (
                    Math.abs(stripes[i_stripe].l) >= 0 &&
                    j + hSpan + 1 < n_j &&
                    null === stripes[i_stripe]._h[j + hSpan + 1]
                ) {
                    hSpan++;
                }

                const leaf = j + hSpan + 1 === n_j;
                const width = headerWidth + hSpan * headerWidth + (leaf ? CONST.sidebarColExtra : 0);
                const totalWidth = width + (leaf ? C.sidebarColImage : 0);
                const height = (e1 - e0) * C.rowPx * scale;
                // const borderTop = "thin solid gray";
                // const borderLeft = null !== h ? "thin solid gray" : undefined;
                // const borderRight = j + hspan + 1 === n_j ? "thin solid gray" : undefined;

                let color = (l_max - j) % 2 === 1 ? whiteColor : greyColor;
                if (leaf) {
                    color = deepestSidebarItem % 2 === 1 ? "rgb(249, 250, 251)" : "white";
                    deepestSidebarItem++;
                }

                this.sidebar.push({
                    stripe:
                        useUserJourneyStore.getState().userView === ViewTypes.BASELINE && stripes[i_stripe + 1]
                            ? stripes[i_stripe + 1]
                            : stripes[i_stripe],
                    j,
                    top,
                    left: marginLeft,
                    width: totalWidth,
                    height,
                    color,
                    segs: stripes[i_stripe]._segs[j],
                    //image: stripes[i_stripe]._image,
                    rotated: !leaf,
                });

                let info = this.sidebarL[j];

                if (!info) {
                    info = {
                        n: 1,
                        t: height,
                        m: height,
                    };
                    this.sidebarL[j] = info;
                } else {
                    this.sidebarL[j].n++;
                    this.sidebarL[j].t += height;
                    this.sidebarL[j].m = Math.min(this.sidebarL[j].m, height);
                }

                j += hSpan;
                marginLeft += headerWidth;
            }

            const stripeHeight = (e - s) * C.rowPx * scale;

            top += stripeHeight;
        }
    }

    public setMeta(
        meta: ((meta: CanvasHostRendererUserMeta) => CanvasHostRendererUserMeta | null) | CanvasHostRendererUserMeta,
    ) {
        if ("function" === typeof meta) {
            const ret = meta(this.userMeta);
            if (null !== ret) {
                this.userMeta = ret;
                //console.log("setMeta "+JSON.stringify(this.userMeta));
                this._updateRenderStripeTree();
            }
        } else {
            this.userMeta = meta;
            this._updateRenderStripeTree();
        }
    }

    public addAddDependency(srcPid: number, tgtPid: number, dependencyType: LCMDContextDependencyType) {
        this.renderer.onAddDependency(srcPid, tgtPid, dependencyType);
    }

    private _updateRenderStripeTree() {
        const C = this.C;
        if (C) {
            //console.log("_updateRenderStripeTree");
            const canvasW = this.grid.view.cols * this.C.colPx;
            const l_min = this.l_min;
            const l_max = this.l_max;
            const stripes = this.stripes;
            const tasks = this.tasks;
            const n_stripes = stripes.length;
            const n_tasks = tasks.length;
            const sel = this.sel;
            const n_selZ = this.selIndexCount;
            this.selZ = CanvasHostRenderer._ensureSelCapacity(this.selZ, 0, this.selIndexCount);
            const selZ = this.selZ;
            for (let i = 0; i < n_selZ; i++) {
                selZ[i] = INVALID_VALUE;
            }
            let i_stripe = 0;
            let i_task = 0;
            for (; i_stripe < n_stripes && i_task <= n_tasks; i_stripe++) {
                const stripe = stripes[i_stripe];
                const e0 = i_stripe > 0 ? stripes[i_stripe - 1].e : 0;
                stripe.__x = stripe.x;
                stripe.__y = stripe.y;
                stripe.__w = null === stripe.w ? canvasW - stripe.x : stripe.w;
                stripe.__h = stripe.h;
                stripe.__f = 0;
                stripe.__t = i_task;
                const z_stripe = this._findSelIndex(DataOperationTarget.TASKS, stripe.t);
                stripe.__z = z_stripe;
                if (this.zOrderStripes && z_stripe >= 0) {
                    //console.log("STRIPE["+i_stripe+"] #"+stripe.t+"="+z_stripe);
                    assert(sel[z_stripe] === stripes[i_stripe].t && INVALID_VALUE === selZ[z_stripe]);
                    selZ[z_stripe] = (i_stripe | STRIPE_FLAG) >>> 0;
                }
                if (this.xGroupTasks) {
                    // do layout
                    const isDrag = this.selHit && (0 !== this.selStripeDx || 0 !== this.selStripeDy);
                    //console.log("isDrag "+isDrag+"  "+JSON.stringify([new Boolean(this.selHit), this.selDx, this.selDy, this.selStripeDx, this.selStripeDy, this.userMeta]));
                    const helper_r: { __z: number; __y: number; __h: number }[] = [
                        {
                            __z: -1,
                            __y: 20,
                            __h: 0,
                        },
                    ];
                    let _helper_y = helper_r[0].__y;
                    let helper_y_sel: number = null;
                    let helper_l = 0;
                    let helper_sel = 0;
                    let helper_meta = null;
                    let task;
                    const pop = () => {
                        assert(helper_l > 0);
                        if (isDrag && helper_r[helper_l - 1].__z >= 0) {
                            assert(helper_sel > 0);
                            helper_sel--;
                        }
                        /*
                        if(isDrag && helper_r[helper_l].__z>=0) {
                            helper_y=helper_r[helper_l].__y-stripe.__y;
                        }
                        */
                        helper_r[helper_l - 1].__h =
                            helper_r[helper_l].__y + helper_r[helper_l].__h - helper_r[helper_l - 1].__y;
                        helper_r[helper_l] = null;
                        helper_l--;
                    };
                    for (; i_task < n_tasks && i_stripe === (task = tasks[i_task]).stripe; i_task++) {
                        const z_task = this._findSelIndex(DataOperationTarget.TASKS, task.id);
                        task.__x = stripe.__x + task.left;
                        task.__w = task.right - task.left;
                        //task.__h=task.h;
                        task.__f = 0;
                        task.__z = z_task;
                        if (z_task >= 0) {
                            assert(sel[z_task] === tasks[i_task].id && INVALID_VALUE === selZ[z_task]);
                            selZ[z_task] = i_task;
                        }
                        if (task.level > helper_l) {
                            // push
                            if (isDrag && helper_r[helper_l].__z >= 0) {
                                helper_sel++;
                            }
                            helper_r[++helper_l] = null;
                        } else {
                            while (helper_l > task.level) pop();
                        }
                        assert(helper_l === task.level);
                        /*
                        if (isDrag && 0===helper_sel && null!==helper_r[helper_l] && helper_r[helper_l].__z>=0) {
                            helper_y=helper_r[helper_l].__y-stripe.__y;
                        }
                        */
                        const h = 20;
                        task.__h = h;
                        if (isDrag && (z_task >= 0 || helper_sel > 0)) {
                            if (null === helper_y_sel) {
                                helper_y_sel = _helper_y - (null !== helper_meta ? this.userMeta.h : 0);
                            }
                            task.__y = stripe.__y + helper_y_sel;
                            helper_y_sel += task.__h;
                        } else {
                            if (
                                null === helper_meta &&
                                "number" === typeof this.userMeta.y &&
                                z_task < 0 &&
                                0 === helper_sel &&
                                _helper_y <= this.userMeta.y &&
                                this.userMeta.y < _helper_y + h
                            ) {
                                /*
                                console.log("TASK "+JSON.stringify({
                                    _helper_y,
                                    userMeta_y: this.userMeta.y,
                                    task: task.segs.filter((s)=>"string"===typeof(s)).join(''),
                                    id: task.id
                                }));
                                */
                                helper_meta = task;
                                _helper_y += this.userMeta.h;
                            }
                            task.__y = stripe.__y + _helper_y;
                            _helper_y += task.__h;
                        }
                        helper_r[helper_l] = task;
                    }
                    while (helper_l > 0) pop();
                    assert(0 === helper_l);
                } else {
                    let task;
                    for (; i_task < n_tasks && i_stripe === (task = tasks[i_task]).stripe; i_task++) {
                        const z_task = this._findSelIndex(DataOperationTarget.TASKS, task.id);
                        const isMilestone = this.isMilestone(task);
                        task.__x = stripe.__x + task.left + this.C.gpa.padding - (isMilestone ? this.C.rowPx / 2 : 0);
                        task.__y = stripe.__y + task.top - e0 * C.rowPx + this.C.gpa.padding + this.C.gpa.header.height;
                        task.__w =
                            (isMilestone ? this.C.rowPx : task.right - task.left) -
                            this.C.gpa.padding -
                            this.C.gpa.padding;
                        task.__h = C.rowPx - this.C.gpa.padding - this.C.gpa.padding;
                        task.__f = 0;
                        task.__z = z_task;
                        if (z_task >= 0) {
                            assert(sel[z_task] === tasks[i_task].id && INVALID_VALUE === selZ[z_task]);
                            selZ[z_task] = i_task;
                        }
                    }
                }
                /* TODO
                let comment;
                for(i_comment; ...)
                */
            }
            assert(n_stripes === i_stripe && n_tasks === i_task); // incorrect order?
            /*{ //DEBUG!! REMOVE ME!
                for(let i=0;i<n_selZ;i++) {
                    const v=selZ[i];
                    assert(INVALID_VALUE!==v);
                    const t:DataOperationTarget=((sel[i]>>>TARGET_SHIFT)&TARGET_MASK);
                    if (DataOperationTarget.TASKS===t) {
                        if (((v&STRIPE_FLAG)>>>0)===STRIPE_FLAG) {
                            const i_stripe=(v&STRIPE_MASK);
                            const stripe=stripes[i_stripe];
                            assert(i===stripe.__z && sel[i]===stripe.t);
                        } else {
                            const i_task=v;
                            const t=tasks[i_task];
                            assert(i===t.__z && sel[i]===t.id);
                        }
                    } else {
                        assert(false); //@TODO
                    }

                }
            }*/
            this.stripeICount = 0;
            this.taskICount = 0;
            this.commentICount = 0;
            this.bboxX1 = null;
            this.bboxY1 = null;
            this.bboxX2 = null;
            this.bboxY2 = null;
            this.bboxHit = null;
            let selsDirty = 0;
            for (let i = 0; i < n_selZ; i++) {
                const v = selZ[i];
                if (INVALID_VALUE === v) {
                    // no longer valid... common case: task was selected and deleted. then task is gone but selection still has the id
                    if ("development" === process.env.NODE_ENV) {
                        const missingId = this.sel[i];
                        assert(!this.tasks.find((t) => missingId === t.id));
                    }
                    sel[i] = INVALID_VALUE; /// mark as deleted
                    selsDirty++;
                } else {
                    const t: DataOperationTarget = (sel[i] >>> TARGET_SHIFT) & TARGET_MASK;
                    if (DataOperationTarget.TASKS === t) {
                        if ((v & STRIPE_FLAG) >>> 0 === STRIPE_FLAG) {
                            const i_stripe = v & STRIPE_MASK;
                            const stripe = stripes[i_stripe];
                            assert(0 <= stripe.__z && stripe.__z <= i); // if stripe.__z<i then the stripe has been added implicitly before by a task/comment
                            if (i === stripe.__z) {
                                this.stripeI = CanvasHostRenderer._ensureSelCapacity(
                                    this.stripeI,
                                    this.stripeICount,
                                    this.stripeICount + 1,
                                );
                                this.stripeI[this.stripeICount++] = i_stripe;
                            }
                            this._updateBBox(stripe, { stripe });
                        } else {
                            const i_task = v;
                            const t = tasks[i_task];
                            if (-1 === stripes[t.stripe].__z || stripes[t.stripe].__z > i) {
                                // we need to insert the stripe NOW
                                this.stripeI = CanvasHostRenderer._ensureSelCapacity(
                                    this.stripeI,
                                    this.stripeICount,
                                    this.stripeICount + 1,
                                );
                                this.stripeI[this.stripeICount++] = t.stripe;
                                stripes[t.stripe].__z = i; // adjust z ref
                            } /*else { //DEBUG: remove me
                                if (this.stripeI.slice(0, this.stripeICount).indexOf(t.stripe)<0) {
                                    assert(false);
                                }
                            }*/
                            this.taskI = CanvasHostRenderer._ensureSelCapacity(
                                this.taskI,
                                this.taskICount,
                                this.taskICount + 1,
                            );
                            let i_taskI = this.taskICount++;
                            while (
                                i_taskI > 0 &&
                                stripes[t.stripe].__z < stripes[tasks[this.taskI[i_taskI - 1]].stripe].__z
                            ) {
                                this.taskI[i_taskI] = this.taskI[i_taskI - 1];
                                i_taskI--;
                            }
                            this.taskI[i_taskI] = i_task;
                            this._updateBBox(t, { task: t });
                        }
                    } else {
                        assert(false); // handle me!!
                    }
                }
            }
            if (selsDirty > 0) {
                const n = this.selCount;
                this.selCount = 0;
                for (let i = 0; i < n; i++) {
                    assert(this.selCount <= i);
                    if (INVALID_VALUE !== sel[i]) {
                        sel[this.selCount++] = sel[i];
                    }
                }
                this._updateSelIndex(false);
            }
            this._updateSidebar();
        }
    }

    private view = {
        scale: 1,
        x1: 0,
        y1: 0,
        x2: 0,
        y2: 0,
        insetTop: 0,
        insetLeft: 0,
        insetRight: 0,
        insetBottom: 0,
    }; // helper
    private _viewBeforeFocus = null;
    private A: CanvasAppConst = CONST;

    private renderMilestones(
        milestones: { t: CanvasTaskData & CanvasHostStripeRenderNode; i_task: number }[],
        ctx: CanvasRenderingContext2D,
        n_tasks: number,
    ) {
        for (let milestoneIndex = 0; milestoneIndex < milestones.length; milestoneIndex++) {
            this.renderer &&
                this.renderer.renderTask(
                    this as any as CanvasHostRendererCtx,
                    ctx,
                    milestones[milestoneIndex].t,
                    milestones[milestoneIndex].i_task < n_tasks * 0.33,
                ); // render task t
        }
    }

    private isMilestone(task: CanvasTaskData & CanvasHostRenderNode) {
        return task && task.right === task.left;
    }

    public render(
        ctx: CanvasRenderingContext2D,
        scale: number,
        left: number,
        top: number,
        width: number,
        height: number,
    ) {
        const C = this.C;
        if (!C || !this.renderer) {
            return;
        }
        const isWhiteboard = C.gpa.gpa; // gpa = Gesamt Prozess Analyse => Whiteboard
        const { showConflictMode, dependencyChain, showDependencies, initiallyRendered, setInitiallyRendered } =
            useCanvasStore.getState();
        const showBaseline = useUserJourneyStore.getState().userView === ViewTypes.BASELINE;

        this.view.scale = scale;
        this.view.x1 = left;
        this.view.y1 = top;
        this.view.x2 = left + width;
        this.view.y2 = top + height;
        this.renderer.renderHell(this as any as CanvasHostRendererCtx, ctx);

        const stripes = this.stripes;
        const n_stripes = stripes.length;
        const tasks = this.tasks;
        const n_tasks = tasks.length;

        for (let i_stripe = 0; i_stripe < n_stripes; i_stripe++) {
            this.renderer.renderStripeHell(this as any as CanvasHostRendererCtx, ctx, stripes[i_stripe], i_stripe);
        }

        this.renderer.renderHeaven(
            this as any as CanvasHostRendererCtx,
            ctx,
            this.view.scale >= this.lineVisibilityThreshold,
        );

        // whiteboard only: here the selection of a process or multiple will be drawn
        // if (this.C?.gpa?.gpa && null !== this.bboxX1 && null !== this.bboxY1 && null !== this.bboxX2 && null !== this.bboxY2) {
        //     ctx.strokeStyle = this.C.gpa.selected.primary.style;
        //     ctx.lineWidth = 1;
        //     const dx = this.selStripeDx + this.selStripeTx;
        //     const dy = this.selStripeDy + this.selStripeTy;
        //     const sx = this.selSx * this.selStripeSx;
        //     const sy = this.selSy * this.selStripeSy;
        //     ctx.strokeRect(
        //         this.bboxX1 + dx,
        //         this.bboxY1 + dy,
        //         (this.bboxX2 - this.bboxX1) * sx,
        //         (this.bboxY2 - this.bboxY1) * sy,
        //     );
        // }
        for (let i_stripe = 0; i_stripe < n_stripes; i_stripe++) {
            const stripe = stripes[i_stripe];

            if (-1 !== stripe.__z) {
                continue;
            }
            // skip selected
            this.renderer.renderStripeBegin(this as any as CanvasHostRendererCtx, ctx, stripe, i_stripe);
            if (this.renderer.renderCollapsedStripe && stripe.collapsed) {
                this.renderer.renderCollapsedStripe(this as any as CanvasHostRendererCtx, ctx, {
                    ...stripe,
                } as any);
                this.renderer.renderStripeEnd(this as any as CanvasHostRendererCtx, ctx, stripe);
                continue;
            }

            if (showBaseline && stripe.baseline) {
                for (let i_task = stripe.__t; i_task < n_tasks && i_stripe === tasks[i_task].stripe - 1; i_task++) {
                    const t = tasks[i_task] as any;

                    if (!t.baseline) {
                        // Skip if there's no baseline property
                        continue;
                    }

                    const { _x1, _x2, _color } = t.baseline;

                    this.renderer.renderTask(
                        this as any as CanvasHostRendererCtx,
                        ctx,
                        {
                            ...t,
                            __x: _x1,
                            __w: _x2 - _x1,
                            __y: t.baseline._y - stripe.__h,
                        },
                        null,
                        null,
                        true,
                        _color,
                    );
                }
            } else {
                const milestones = [];

                for (let i_task = stripe.__t; i_task < n_tasks && i_stripe === tasks[i_task].stripe; i_task++) {
                    const t = tasks[i_task] as any;
                    // The processes must be painted before milestones, to keep the milestones always on top
                    // see https://lcmexecute.atlassian.net/browse/LCM2-5497
                    // The milestones will be stored and skipped, to be rendered after the processes
                    if (this.isMilestone(tasks[i_task])) {
                        milestones.push({ t, i_task });
                        continue;
                    }
                    this.renderer.renderTask(this as any as CanvasHostRendererCtx, ctx, t);
                }

                this.renderMilestones(milestones, ctx, n_tasks);
            }
            this.renderer.renderStripeEnd(this as any as CanvasHostRendererCtx, ctx, stripe);
        }

        // draw selected
        ctx.save();
        ctx.translate(this.selDx, this.selDy);

        const n_stripeI = this.stripeICount;
        const n_taskI = this.taskICount;
        let i_taskI = 0;

        for (let i_stripeI = 0; i_stripeI < n_stripeI; i_stripeI++) {
            const i_stripe = this.stripeI[i_stripeI];
            const stripe = stripes[i_stripe];

            assert(stripe.__z >= 0);

            this.renderer.renderStripeBegin(this as any as CanvasHostRendererCtx, ctx, stripe, i_stripe);

            if (this.renderer.renderCollapsedStripe && stripe.collapsed) {
                this.renderer.renderCollapsedStripe(this as any as CanvasHostRendererCtx, ctx, stripe);
                this.renderer.renderStripeEnd(this as any as CanvasHostRendererCtx, ctx, stripe);
                i_taskI++;
                continue;
            }

            //@TODO draw comments <0
            let i_task = stripe.__t;

            let milestones = [];
            while (i_task < n_tasks && i_stripe === tasks[i_task].stripe) {
                const t = tasks[i_task] as any;

                if (-1 === t.__z) {
                    // skip selected
                    if (this.isMilestone(tasks[i_task]) && this.taskI) {
                        milestones.push({ t, i_task: this.taskI[i_taskI] });
                        i_task++;
                        continue;
                    }
                    this.renderer.renderTask(this as any as CanvasHostRendererCtx, ctx, t); // render task t
                    i_task++;
                } else if (this.xGroupTasks) {
                    assert(t.__z >= 0); // selected
                    i_task++;

                    while (i_task < n_tasks && i_stripe === tasks[i_task].stripe && tasks[i_task].level > t.level) {
                        // skip selected group
                        i_task++;
                    }
                } else {
                    i_task++;
                }
            }
            this.renderMilestones(milestones, ctx, n_tasks);

            // renders the selected
            milestones = [];
            while (i_taskI < n_taskI && i_stripe === tasks[this.taskI[i_taskI]].stripe) {
                const t = tasks[this.taskI[i_taskI]] as any;

                assert(t.__z >= 0);
                if (this.isMilestone(tasks[this.taskI[i_taskI]]) && this.taskI) {
                    milestones.push({ t, i_task: this.taskI[i_taskI] });
                    i_taskI++; // Make sure to increment the loop counter
                    continue;
                }
                if (showBaseline && t.baseline) {
                    this.renderer.renderSelectedBaselineHighlight(this as any as CanvasHostRendererCtx, ctx, t, {
                        ...t,
                        __x: t.baseline._x1,
                        __w: t.baseline._x2 - t.baseline._x1,
                        __y: t.baseline._y - stripe.__h,
                    });
                    // HACK: only for demo. After need get this info by calculating difference in days
                    const { _x1, _x2 } = t.baseline;
                    let color = "AFAFAF";

                    if (_x2 < t.__x + t.__w) {
                        color = "DC2626";
                    } else if (_x2 > t.__x + t.__w) {
                        color = "22C55E";
                    }

                    this.renderer.renderTask(
                        this as any as CanvasHostRendererCtx,
                        ctx,
                        {
                            ...t,
                            __x: _x1,
                            __w: _x2 - _x1,
                            __y: t.baseline._y - stripe.__h,
                        },
                        null,
                        null,
                        true,
                        color,
                    );
                }
                this.renderer.renderTask(this as any as CanvasHostRendererCtx, ctx, t); // render task t

                if (!this.xGroupTasks) {
                    i_taskI++;
                    continue;
                }

                assert(null === this.selXGroup); // why is this set?
                this.selXGroup = t;

                for (
                    let i_task = this.taskI[i_taskI] + 1;
                    i_task < n_tasks && i_stripe === tasks[i_task].stripe && tasks[i_task].level > t.level;
                    i_task++
                ) {
                    this.renderer.renderTask(
                        this as any as CanvasHostRendererCtx,
                        ctx,
                        tasks[i_task] as any,
                        i_task < n_tasks,
                    ); // render task t
                }

                this.selXGroup = null;
                i_taskI++;
            }

            this.renderMilestones(milestones, ctx, n_tasks);
            //@TODO draw comments >0
            this.renderer.renderStripeEnd(this as any as CanvasHostRendererCtx, ctx, stripe);
        }

        assert(n_taskI === i_taskI); // wrong order?

        if (this.stripes.length > 0 && this.grid?.view?.f) {
            const h = this.stripes[this.stripes.length - 1].e * C.rowPx;

            ctx.save();
            ctx.fillStyle = "#55555588";
            ctx.fillRect(0, 0, this.grid.view.f.g0 * C.colPx, h);
            ctx.fillRect(
                this.grid.view.f.g1 * C.colPx,
                0,
                this.grid.view.cols * C.colPx - this.grid.view.f.g1 * C.colPx,
                h,
            );
            ctx.restore();
        }

        // whiteboard only: drawing of the bbox handle
        if (
            this.C?.gpa?.gpa &&
            null !== this.bboxX1 &&
            null !== this.bboxY1 &&
            null !== this.bboxX2 &&
            null !== this.bboxY2
        ) {
            ctx.strokeStyle = this.C.gpa.selected.primary.style;
            ctx.lineWidth = 1;

            const dx = this.selStripeDx + this.selStripeTx;
            const dy = this.selStripeDy + this.selStripeTy;
            const sx = this.selSx * this.selStripeSx;
            const sy = this.selSy * this.selStripeSy;
            const bboxX2 = this.bboxX1 + (this.bboxX2 - this.bboxX1) * sx + dx;
            const bboxY2 = this.bboxY1 + (this.bboxY2 - this.bboxY1) * sy + dy;
            const { width } = C.gpa.selected.handle;
            const w = Math.max(width, width / this.view.scale);

            ctx.fillStyle = C.gpa.selected.handle.style;
            ctx.fillRect(bboxX2 - w, bboxY2 - w, w + w, w + w);
        }
        ctx.restore();

        if ((showConflictMode || showDependencies || isWhiteboard) && this.view.scale > 0.1) {
            for (let i_stripe = 0; i_stripe < n_stripes; i_stripe++) {
                const stripe = stripes[i_stripe];

                for (let i_task = stripe.__t; i_task < n_tasks && i_stripe === tasks[i_task].stripe; i_task++) {
                    const t = tasks[i_task];

                    if (
                        !stripe.collapsed &&
                        (!showConflictMode || isWhiteboard || (showConflictMode && dependencyChain.indexOf(t.id) >= 0))
                    ) {
                        this.renderer.renderDependency(this as any as CanvasHostRendererCtx, ctx, t);
                        this.renderer.drawTaskSelectedSide(ctx, t);
                    }
                }
            }
        }

        this.renderer.renderEnd(this as any as CanvasHostRendererCtx, ctx);

        if (!initiallyRendered) {
            setInitiallyRendered();
        }
    }

    public renderOverlay(
        ctx: CanvasRenderingContext2D,
        scale: number,
        left: number,
        top: number,
        width: number,
        height: number,
    ) {
        const C = this.C;
        if (C) {
            // why the fuck change the view constants for the rendering cycle?? Where is this used with mutated values
            this.view.scale = scale;
            this.view.x1 = left;
            this.view.y1 = top;
            this.view.x2 = left + width;
            this.view.y2 = top + height;
            this.view.insetTop = CONST.titleHeight + CONST.subtitleHeight + C.colHeaderHeight;
            this.view.insetLeft = 0;
            this.view.insetRight = CONST.newSidebarWidth;
            this.view.insetBottom = 0;
            this.renderer && this.renderer.renderOverlay(this as any as CanvasHostRendererCtx, ctx);
        }
    }

    public updateIfNeeded(
        C: CanvasViewConst,
        M: CanvasViewMeta,
        grid: CalendarGrid,
        stripes: CanvasStripeData[],
        l_min: number,
        l_max: number,
        tasks: CanvasTaskData[],
        messages: CanvasMessageData[],
        header: CanvasCalendarHeader,
        force?: boolean,
    ) {
        if (C && grid) {
            if (
                this.C !== C ||
                this.M !== M ||
                this.grid !== grid ||
                this.stripes !== stripes ||
                this.l_min !== l_min ||
                this.l_max !== l_max ||
                this.tasks !== tasks /* || this.messages!==messages */ ||
                this.header !== header ||
                force
            ) {
                this.C = C;
                this.M = M;
                this.grid = grid;
                this.stripes = (stripes || []) as any;
                this.l_min = l_min;
                this.l_max = l_max;
                this.tasks = (tasks || []) as any;
                this.messages = (messages || []) as any;
                this.header = header;
                this.today = undefined;
                if (this.C.today) {
                    if (
                        this.grid &&
                        this.grid.grid.length > 0 &&
                        this.grid.grid[0].d0 <= this.C.today &&
                        this.C.today < this.grid.grid[this.grid.grid.length - 1].d1
                    ) {
                        const x = DataModel.EpochDateToProjectGrid(this.grid, -1, EpochDaystoEpochMS(this.C.today));
                        this.today = x;
                    }
                }
                this._updateRenderStripeTree();
            }
        }
    }

    private _hitRect(m: { x; y }, { __x, __y, __w, __h }): boolean {
        return __x <= m.x && m.x < __x + __w && __y <= m.y && m.y <= __y + __h;
    }
    public hit: CanvasHostHitResult = {
        hitStripe: null,
        hitSrcTaskId: null,
        hitSrcTaskLeft: null,
        hitTaskLeft: null,
        hitTask: null,
        hitBaselineTask: false,
        hitCanvasX: null,
        hitCanvasY: null,
        hitStripeX: null,
        hitStripeY: null,
        hitStripeLeft: null,
        hitStripeTop: null,
        hitStripeGridX: null,
        hitStripeGridY: null,
        hitStripeGridDate: null,
        hitStripeHeader: null,
        hitSidebar: null,
        hitSidebarX: null,
        hitSidebarY: null,
        hitSidebarImage: false,
        hitDep: [null, null],
    };

    private _hitTestTasks(
        m: { x; y },
        stripe: CanvasStripeData & CanvasHostStripeRenderNode,
        i_stripe: number,
        handle: number,
        options?: { ignoreSrcLeft: boolean },
    ) {
        const { showDependencies } = useCanvasStore.getState();
        const showBaseline = useUserJourneyStore.getState().userView === ViewTypes.BASELINE;
        const isWhiteboard = this.C.gpa.gpa; // gpa = Gesamt Prozess Analyse => Whiteboard
        const tasks = this.tasks;
        const n_tasks = tasks.length;
        const taskI = this.taskI;
        const n_taskI = this.taskICount;
        let hitTask = null;
        let t: CanvasTaskData & CanvasHostRenderNode;

        for (let i_taskI = 0; i_taskI < n_taskI; i_taskI++) {
            t = tasks[taskI[i_taskI]];

            if (i_stripe !== t.stripe) {
                continue;
            }

            if (this._hitRect(m, t)) {
                hitTask = t;

                if (hitTask.isVirtual) {
                    this.hit.hitTaskLeft = null;
                    continue;
                }

                if ((showDependencies || isWhiteboard) && lerp(t.__x, t.__x + t.__w, 0.25) > m.x) {
                    this.hit.hitTaskLeft = true;
                    if (options && !options.ignoreSrcLeft && this.hit.hitSrcTaskLeft === null) {
                        this.hit.hitSrcTaskLeft = true;
                        this.hit.hitSrcTaskId = t.id;
                    }
                } else if ((showDependencies || isWhiteboard) && lerp(t.__x, t.__x + t.__w, 0.75) <= m.x) {
                    this.hit.hitTaskLeft = false;
                    if (options && !options.ignoreSrcLeft && this.hit.hitSrcTaskLeft === null) {
                        this.hit.hitSrcTaskLeft = false;
                        this.hit.hitSrcTaskId = t.id;
                    }
                } else {
                    this.hit.hitTaskLeft = null;
                }
            }
        }
        let ret = false;
        if (hitTask) {
            this.hit.hitTask = hitTask;
            ret = true;
        } else {
            for (let i_task = stripe.__t; i_task < n_tasks && i_stripe === (t = tasks[i_task]).stripe; i_task++) {
                if (showBaseline && t.baseline) {
                    const baselineTask = {
                        ...t,
                        __x: t.baseline._x1,
                        __w: t.baseline._x2 - t.baseline._x1,
                        __y: t.baseline._y - stripe.__h,
                    };
                    if (this._hitRect(m, baselineTask)) {
                        hitTask = t;
                        this.hit.hitBaselineTask = true;
                    }
                }

                if (-1 !== t.__z || !this._hitRect(m, t)) {
                    continue;
                }

                hitTask = t;

                if (hitTask.isVirtual) {
                    this.hit.hitTaskLeft = null;
                    continue;
                }

                if ((showDependencies || isWhiteboard) && lerp(t.__x, t.__x + t.__w, 0.25) > m.x) {
                    this.hit.hitTaskLeft = true;
                    if (options && !options.ignoreSrcLeft && this.hit.hitSrcTaskLeft === null) {
                        this.hit.hitSrcTaskLeft = true;
                        this.hit.hitSrcTaskId = t.id;
                    }

                    continue;
                }

                if ((showDependencies || isWhiteboard) && lerp(t.__x, t.__x + t.__w, 0.75) <= m.x) {
                    this.hit.hitTaskLeft = false;

                    if (options && !options.ignoreSrcLeft && this.hit.hitSrcTaskLeft === null) {
                        this.hit.hitSrcTaskLeft = false;
                        this.hit.hitSrcTaskId = t.id;
                    }

                    continue;
                }

                this.hit.hitTaskLeft = null;
            }
            if (hitTask) {
                this.hit.hitTask = hitTask;
                ret = true;
            } else {
                this.hit.hitTaskLeft = null;
                if (!options || options.ignoreSrcLeft === false) {
                    this.hit.hitSrcTaskLeft = null;
                    this.hit.hitSrcTaskId = null;
                }
            }
        }
        // that's the hit test if the bbox handle was hit. Don't know if we need that in the new design. Maybe for the Whiteboard
        if (handle && this.bboxHit && null === this.hit.hitTask) {
            this.hit.hitTask = this.bboxHit.task;
        }
    }

    private _hitTestStripe(m: { x; y }, stripe: CanvasStripeData & CanvasHostStripeRenderNode, i_stripe: number) {
        const isPresentingMode: boolean = useCanvasStore.getState().isPresentingMode;
        if (this._hitRect(m, stripe) && !isPresentingMode && !stripe.collapsed) {
            this.hit.hitStripe = stripe;
            this.hit.hitStripeX = m.x - stripe.__x;
            this.hit.hitStripeY = m.y - stripe.__y;
            this.hit.hitStripeLeft = this.hit.hitStripeX;
            this.hit.hitStripeTop = this.hit.hitStripeY - this.C.gpa.header.height;
            this.hit.hitStripeGridX = Math.max(0, Math.floor(this.hit.hitStripeLeft / this.C.colPx));
            this.hit.hitStripeGridY = Math.max(0, Math.floor(this.hit.hitStripeTop / this.C.rowPx));
            this.hit.hitStripeGridDate = this.grid.gridToDay(this.hit.hitStripeGridX);
            this.hit.hitStripeHeader = this.hit.hitStripeY < this.C.gpa.header.height;
            return true;
        } else {
            return false;
        }
    }

    /*
    private _hitRectLRWH(m:{screenX, screenY}, {left, top, width, height}, offsetX, offsetY) {
        const screenX=m.screenX+offsetX;
        const screenY=m.screenY+offsetY;
        return left<=screenX && screenX<(left+width) && top<=screenY && screenY<=(top+height);
    }
    */

    private _hitTestSidebar(m: { x; y; screenX; screenY; offsetX; offsetY; scale }) {
        //console.log("_hitTestSidebar="+JSON.stringify(m));
        if (!this.sidebar) {
            return false;
        }

        const width =
            (this.l_max - this.l_min) * CONST.sidebarColWidth + CONST.sidebarColExtra + this.C.sidebarColImage;
        const { screenX, screenY, scale, y, offsetY } = m;

        if (screenX >= width) {
            return false;
        }

        for (const s of this.sidebar) {
            if (!(s.left <= screenX && screenX < s.left + s.width && s.top <= y && y <= s.top + s.height)) {
                continue;
            }

            this.hit.hitSidebar = s;
            this.hit.hitSidebarX = screenX - s.left;
            this.hit.hitSidebarY = screenY / scale - offsetY - s.top;

            if (
                !s.stripe.collapsed &&
                width - Math.min(this.C.sidebarColImage, this.C.sidebarColImage * scale) <= screenX &&
                this.hit.hitSidebarY * scale <= Math.min(this.C.sidebarColImage * scale, this.C.sidebarColImage)
            ) {
                this.hit.hitSidebarImage = true;
                return true;
            }
        }

        return false;
    }

    private _isMouseInsideUi(m: { x; y; screenX; screenY; offsetX; offsetY; scale } | null): boolean {
        if (!m) {
            return false;
        }

        const headerHeight = CONST.titleHeight + CONST.subtitleHeight + this.C.colHeaderHeight;
        const sidebarWidth =
            (this.l_max - this.l_min) * this.A.sidebarColWidth + this.A.sidebarColExtra + this.C.sidebarColImage;

        return m.screenY <= headerHeight || m.screenX <= sidebarWidth;
    }

    public hitTest(
        m: { x; y; screenX; screenY; offsetX; offsetY; scale } | null,
        opt?: { ignoreSelected?: boolean; handle?: number; keepDepSelection?: boolean; ignoreSrcLeft?: boolean },
    ) {
        this.hit.hitTask = null;
        this.hit.hitBaselineTask = false;
        this.hit.hitTaskLeft = null;
        this.hit.hitStripe = null;
        this.hit.hitCanvasX = m.x;
        this.hit.hitCanvasY = m.y;
        this.hit.hitStripeX = null;
        this.hit.hitStripeY = null;
        this.hit.hitStripeLeft = null;
        this.hit.hitStripeTop = null;
        this.hit.hitStripeGridX = null;
        this.hit.hitStripeGridY = null;
        this.hit.hitStripeGridDate = null;
        this.hit.hitStripeHeader = false;
        this.hit.hitSidebar = null;
        this.hit.hitSidebarX = null;
        this.hit.hitSidebarY = null;
        this.hit.hitSidebarImage = false;

        if (!opt?.keepDepSelection) {
            this.hit.hitDep = [null, null];
        }

        if (!m) {
            return;
        }
        if (this._hitTestSidebar(m)) {
            return;
        }

        if (!this.C.gpa?.gpa && this._isMouseInsideUi(m)) {
            return;
        }

        const stripes = this.stripes;
        const n_stripes = stripes.length;
        if (!opt?.ignoreSelected) {
            const _m = {
                x: m.x - this.selDx,
                y: m.y - this.selDy,
            };
            const stripesI = this.stripeI;
            const n_stripesI = this.stripeICount;
            for (let i_stripeI = n_stripesI; i_stripeI > 0; ) {
                i_stripeI--;
                const i_stripe = stripesI[i_stripeI];
                if (this._hitTestStripe(_m, stripes[i_stripe], i_stripe)) {
                    this._hitTestTasks(m, stripes[i_stripe], i_stripe, opt?.handle, {
                        ignoreSrcLeft: opt?.ignoreSrcLeft || false,
                    });
                    return;
                }
            }
        }
        for (let i_stripe = n_stripes; i_stripe > 0; ) {
            i_stripe--;
            const stripe = stripes[i_stripe];
            if (-1 === stripe.__z) {
                if (this._hitTestStripe(m, stripe, i_stripe)) {
                    if (stripe.baseline) {
                        this._hitTestTasks(m, stripes[i_stripe + 1], i_stripe + 1, opt?.handle, {
                            ignoreSrcLeft: opt?.ignoreSrcLeft || false,
                        });
                        return;
                    }

                    this._hitTestTasks(m, stripe, i_stripe, opt?.handle, {
                        ignoreSrcLeft: opt?.ignoreSrcLeft || false,
                    });
                    return;
                }
            }
        }
    }

    public dragSel(handle: number, dx: number, dy: number, hit: CanvasHostHitResult, forceUpdate: boolean) {
        if (this.hit.hitBaselineTask) {
            return;
        }

        this.selHit = hit;
        if (0 === handle) {
            if (this.taskICount > 0 || this.commentICount) {
                this.selStripeDx = dx;
                this.selStripeDy = dy;
                this.selStripeTx = 0;
                this.selStripeTy = 0;
                this.selStripeSx = 1;
                this.selStripeSy = 1;
            } else if (this.stripeICount > 0) {
                this.selDx = dx;
                this.selDy = dy;
                this.selSx = 1;
                this.selSy = 1;
            }
        } else {
            let x1, x2, y1, y2;
            if (this.selHit?.hitTask) {
                x1 = this.selHit.hitTask?.left;
                y1 = this.selHit.hitTask?.top;
                x2 = this.selHit.hitTask?.right;
                y2 = this.selHit.hitTask?.top + this.selHit.hitTask?.__h;
            } else {
                x1 = this.bboxX1;
                y1 = this.bboxY1;
                x2 = this.bboxX2;
                y2 = this.bboxY2;
            }
            const oldX1 = x1;
            const oldX2 = x2;
            const oldY1 = y1;
            const oldY2 = y2;
            let _tx = 0;
            const _ty = 0;
            if (4 === handle /*&& this.selHit.hitStripe*/) {
                x2 += dx;
                y2 += dy;
            } else if (5 === handle && this.selHit.hitTask /* right */) {
                x2 += dx;
            } else if (6 === handle && this.selHit.hitTask /* left */) {
                _tx += dx;
            }
            const _dx = 0;
            const _dy = 0;
            const _sx = (x2 - x1 - _tx) / (oldX2 - oldX1);
            const _sy = (y2 - y1 - _ty) / (oldY2 - oldY1);
            if (this.taskICount > 0 || this.commentICount) {
                this.selStripeDx = _dx;
                this.selStripeDy = _dy;
                this.selStripeTx = _tx;
                this.selStripeTy = _ty;
                this.selStripeSx = _sx;
                this.selStripeSy = _sy;
            } else if (this.stripeICount > 0) {
                this.selDx = _dx;
                this.selDy = _dy;
                this.selSx = _sx;
                this.selSy = _sy;
            }
        }
        if (forceUpdate) {
            this._updateRenderStripeTree();
        }
    }

    public dragEnd(commit: boolean): CanvasHostRendererDragInfo | null {
        let ret: CanvasHostRendererDragInfo | null = null;
        let update = false;
        if (
            0 !== this.selStripeDx ||
            0 !== this.selStripeDy ||
            0 !== this.selStripeTx ||
            0 !== this.selStripeTy ||
            1 !== this.selStripeSx ||
            1 !== this.selStripeSy
        ) {
            let task;
            if (commit) {
                const n_taskI = this.taskICount;
                for (let i_taskI = 0; i_taskI < n_taskI; i_taskI++) {
                    const i_task = this.taskI[i_taskI];
                    task = this.tasks[i_task];
                    task.__x += this.selStripeDx;
                    task.__y += this.selStripeDy;
                    task.__w *= this.selStripeSx;
                    task.__h *= this.selStripeSy;
                    task.left += this.selStripeDx;
                    task.right += this.selStripeDx;
                    task.top += this.selStripeDy;
                    if (this.xGroupTasks) {
                        const i_stripe = task.stripe;
                        const tasks = this.tasks;
                        const n_tasks = tasks.length;
                        for (
                            let i_task = this.taskI[i_taskI] + 1;
                            i_task < n_tasks && i_stripe === tasks[i_task].stripe && tasks[i_task].level > task.level;
                            i_task++
                        ) {
                            const groupTask = this.tasks[i_task];
                            groupTask.__x += this.selStripeDx;
                            groupTask.__y += this.selStripeDy;
                            groupTask.__w *= this.selStripeSx;
                            groupTask.__h *= this.selStripeSy;
                            groupTask.left += this.selStripeDx;
                            groupTask.right += this.selStripeDx;
                            groupTask.top += this.selStripeDy;
                        }
                    }
                }
            }
            ret = {
                dx: this.selStripeDx,
                dy: this.selStripeDy,
                tx: this.selStripeTx,
                ty: this.selStripeTy,
                sx: this.selStripeSx,
                sy: this.selStripeSy,
                task: this.hit.hitTask || task || null,
            };
            update = true;
        } else if (0 !== this.selDx || 0 !== this.selDy || 1 !== this.selSx || 1 !== this.selSy) {
            assert(0 === this.taskICount && 0 === this.commentICount);
            if (commit) {
                const n_stripeI = this.stripeICount;
                for (let i_stripeI = 0; i_stripeI < n_stripeI; i_stripeI++) {
                    const i_stripe = this.stripeI[i_stripeI];
                    const stripe = this.stripes[i_stripe];
                    stripe.__x += this.selDx;
                    stripe.__y += this.selDy;
                    stripe.__w *= this.selSx;
                    stripe.__h *= this.selSy;
                    stripe.x = stripe.__x;
                    stripe.y = stripe.__y;
                    stripe.w = stripe.__w;
                    stripe.h = stripe.__h;
                }
            }
            ret = {
                dx: this.selStripeDx,
                dy: this.selStripeDy,
                tx: 0,
                ty: 0,
                sx: this.selStripeSx,
                sy: this.selStripeSy,
                stripe: this.selHit?.hitStripe || null,
            };
            update = true;
        }
        if (update) {
            this.selHit = null;
            this.selStripeDx = 0;
            this.selStripeDy = 0;
            this.selStripeTx = 0;
            this.selStripeTy = 0;
            this.selStripeSx = 1;
            this.selStripeSy = 1;
            this.selDx = 0;
            this.selDy = 0;
            this.selSx = 1;
            this.selSy = 1;
            this.updateIfNeeded(
                this.C,
                this.M,
                this.grid,
                this.stripes,
                this.l_min,
                this.l_max,
                this.tasks,
                this.messages,
                this.header,
                true,
            );
        }
        return ret;
    }

    private isBorder(m: { x: number; y: number }, d: number, side: "right" | "left") {
        if (this.isMilestone(this.hit?.hitTask)) {
            return false;
        }
        const selectedList = this.getSel();
        for (let i = 0; i < selectedList.length; i++) {
            const selectedTask = selectedList[i];
            if (selectedTask && this.hit && selectedTask?.id === this.hit.hitTask?.id) {
                const isEdge = this._hitRect(m, {
                    __x: this.hit.hitTask[side] - d,
                    __y: this.hit.hitTask.top - d,
                    __w: d + d,
                    __h: this.hit.hitTask.__h + d + d,
                });
                if (isEdge) {
                    return true;
                }
            }
        }
        return false;
    }

    public isBBox(m: { x: number; y: number }, scale: number) {
        // this function needs complete refactoring because the bbox is not needed anymore this way
        // selection should be focused on if a selected process was hit and not the bbox anymore
        if (this.C) {
            const _d = this.C.gpa.selected.handle.width;
            const d = Math.max(_d, _d / scale) * 2;
            if (this.isBorder(m, d, "right")) {
                return 5;
            } else if (this.isBorder(m, d, "left")) {
                return 6;
            } else if (this._hitRect(m, { __x: this.bboxX2 - d, __y: this.bboxY2 - d, __w: d + d, __h: d + d })) {
                // right/bottom
                return 4;
            } else if (this._hitRect(m, { __x: this.bboxX2 - d, __y: this.bboxY1 - d, __w: d + d, __h: d + d })) {
                // right/top
                return 2;
            } else if (this._hitRect(m, { __x: this.bboxX1 - d, __y: this.bboxY2 - d, __w: d + d, __h: d + d })) {
                // left/bottom
                return 3;
            } else if (this._hitRect(m, { __x: this.bboxX1 - d, __y: this.bboxY1 - d, __w: d + d, __h: d + d })) {
                // left/top
                return 1;
            } else if (
                // this._hitRect(m, {
                //     __x: this.bboxX1,
                //     __y: this.bboxY1,
                //     __w: this.bboxX2 - this.bboxX1,
                //     __h: this.bboxY2 - this.bboxY1,
                // })
                this.hit.hitTask &&
                this.hit.hitTask.__z >= 0
            ) {
                return 0;
            }
        }
        return null;
    }

    public getBBox(screen?: { scale: number; left: number; top: number }) {
        if (this.C && null !== this.bboxX1 && null !== this.bboxY1 && null !== this.bboxX2 && null !== this.bboxY2) {
            const target = 1 === this.selCount ? (this.sel[0] >>> TARGET_SHIFT) & TARGET_MASK : null;
            const id = 1 === this.selCount ? this.sel[0] & TARGET_MASK_ID : null;
            const stripeFlag = 1 === this.selIndexCount ? (this.selZ[0] & STRIPE_FLAG) >>> 0 === STRIPE_FLAG : null;
            return {
                canvas: {
                    x: this.bboxX1,
                    y: this.bboxY1,
                    w: this.bboxX2 - this.bboxX1,
                    h: this.bboxY2 - this.bboxY1,
                },
                screen: screen
                    ? {
                          x: (this.bboxX1 + screen.left) * screen.scale,
                          y: (this.bboxY1 + screen.top) * screen.scale,
                          w: (this.bboxX2 + screen.left) * screen.scale - (this.bboxX1 + screen.left) * screen.scale,
                          h: (this.bboxY2 + screen.top) * screen.scale - (this.bboxY1 + screen.top) * screen.scale,
                      }
                    : null,
                textArea: {
                    x: this.bboxX1,
                    y: this.bboxY1,
                    w: this.bboxX2 - this.bboxX1,
                    h: stripeFlag ? this.C.gpa.header.height : this.bboxY2 - this.bboxY1,
                },
                dx: this.selStripeDx,
                dy: this.selStripeDy,
                tx: this.selStripeTx,
                ty: this.selStripeTy,
                sx: this.selStripeSx,
                sy: this.selStripeSy,
                target: target,
                id: id,
            };
        } else {
            return null;
        }
    }

    public getDependencySel(): [number, number] {
        return this.hit.hitDep;
    }

    public getSel() {
        if (!this.C || !this.grid) {
            return [];
        }

        const selectedTasks = [];

        for (let i = 0; i < this.selIndexCount; i++) {
            const selectedItemId = this.sel[i];
            const selectedItemIndex = this.selZ[i];

            if (INVALID_VALUE === selectedItemIndex) {
                continue;
            }

            if (DataOperationTarget.TASKS !== this.getTarget(selectedItemId)) {
                throw new Error("Invalid target");
            }

            if (this.isStripe(selectedItemIndex)) {
                const selectedStripe = this.createSelectedStripeData(selectedItemIndex, selectedItemId);
                selectedTasks.push(selectedStripe);
            } else {
                const selectedTask = this.createSelectedTaskData(selectedItemIndex, selectedItemId);
                selectedTasks.push(selectedTask);
            }
        }

        return selectedTasks;
    }

    public isTaskSelected(task: CanvasTaskData & CanvasHostRenderNode): boolean {
        if (!task || !Number.isInteger(task.__z) || task.id < 0) {
            return false;
        }
        return task.__z >= 0;
    }

    private createSelectedTaskData(taskIndex: number, taskId: number) {
        const task = this.tasks[taskIndex];
        assert(taskId === task.id);

        const stripeIndex = task.stripe;
        const e0 = stripeIndex > 0 ? this.stripes[stripeIndex - 1].e : 0;
        const top = task.top - e0 * this.C.rowPx;
        const gridLeft = Math.max(0, Math.round(task.left / this.C.colPx));
        const gridRight = Math.max(0, Math.round(task.right / this.C.colPx));
        const gridTop = Math.max(0, Math.round(top / this.C.rowPx));
        const gridValid =
            this.grid?.grid &&
            this.grid.grid.length > 0 &&
            0 <= gridLeft &&
            gridLeft <= gridRight &&
            gridRight < this.grid.grid[this.grid.grid.length - 1].g1;
        const gridDate0 = gridValid ? this.grid.gridToDay(gridLeft) : undefined;
        const gridDate1 = gridValid ? this.grid.gridToDay(gridRight) : undefined;

        return {
            target: DataOperationTarget.TASKS,
            id: task.id,
            start: gridDate0,
            end: gridDate1,
            unit: 3,
            stripey: gridTop,
            dep: task.dep,
        };
    }

    private createSelectedStripeData(maskedIndex: number, selectedStripeId: number) {
        const stripeIndex = maskedIndex & STRIPE_MASK;
        const stripe = this.stripes[stripeIndex];
        assert(selectedStripeId === stripe.t);

        const grid = 10;
        return {
            target: DataOperationTarget.TASKS,
            id: stripe.t,
            x: Math.round(stripe.x / grid) * grid,
            y: Math.round(stripe.y / grid) * grid,
            w: Math.round(stripe.w / grid) * grid,
            h: Math.round(stripe.h / grid) * grid,
        };
    }

    private getTarget(selection: number) {
        return (selection >>> TARGET_SHIFT) & TARGET_MASK;
    }

    private isStripe(v: number) {
        return (v & STRIPE_FLAG) >>> 0 === STRIPE_FLAG;
    }

    private _marqueeSelectTasks(
        m: { x1; y1; x2; y2; sel },
        stripe: CanvasStripeData & CanvasHostStripeRenderNode,
        i_stripe: number,
    ) {
        const tasks = this.tasks;
        const n_tasks = tasks.length;
        const taskI = this.taskI;
        const n_taskI = this.taskICount;
        let t;
        for (let i_taskI = 0; i_taskI < n_taskI && i_stripe === (t = tasks[taskI[i_taskI]]).stripe; i_taskI++) {
            if (
                Math.max(m.y1, t.__y) < Math.min(m.y2, t.__y + t.__h) &&
                Math.max(m.x1, t.__x) <= Math.min(m.x2, t.__x + t.__w)
            ) {
                m.sel.push(t.id);
            }
        }
        for (let i_task = stripe.__t; i_task < n_tasks && i_stripe === (t = tasks[i_task]).stripe; i_task++) {
            if (
                -1 === t.__z &&
                Math.max(m.y1, t.__y) < Math.min(m.y2, t.__y + t.__h) &&
                Math.max(m.x1, t.__x) <= Math.min(m.x2, t.__x + t.__w)
            ) {
                m.sel.push(t.id);
            }
        }
    }

    public marqueeSelect(marquee: { x; y; w; h }) {
        const m = {
            sel: [],
            x1: Math.min(marquee.x, marquee.x + marquee.w),
            y1: Math.min(marquee.y, marquee.y + marquee.h),
            x2: Math.max(marquee.x, marquee.x + marquee.w),
            y2: Math.max(marquee.y, marquee.y + marquee.h),
        };
        const stripes = this.stripes;
        const n_stripes = stripes.length;
        const stripesI = this.stripeI;
        const n_stripesI = this.stripeICount;
        for (let i_stripeI = n_stripesI; i_stripeI > 0; ) {
            i_stripeI--;
            const i_stripe = stripesI[i_stripeI];
            const stripe = stripes[i_stripe];
            if (
                !stripe.collapsed &&
                Math.max(m.y1, stripe.__y) < Math.min(m.y2, stripe.__y + stripe.__h) &&
                Math.max(m.x1, stripe.__x) <= Math.min(m.x2, stripe.__x + stripe.__w)
            ) {
                this._marqueeSelectTasks(m, stripes[i_stripe], i_stripe);
            }
        }
        for (let i_stripe = n_stripes; i_stripe > 0; ) {
            i_stripe--;
            const stripe = stripes[i_stripe];
            if (-1 === stripe.__z) {
                if (
                    !stripe.collapsed &&
                    Math.max(m.y1, stripe.__y) < Math.min(m.y2, stripe.__y + stripe.__h) &&
                    Math.max(m.x1, stripe.__x) <= Math.min(m.x2, stripe.__x + stripe.__w)
                ) {
                    this._marqueeSelectTasks(m, stripes[i_stripe], i_stripe);
                }
            }
        }
        this.selCount = 0;
        this.selIndexCount = 0; // invalidate
        for (let i_sel = m.sel.length; i_sel > 0; ) {
            i_sel--;
            this.sel = CanvasHostRenderer._ensureSelCapacity(this.sel, this.selCount, this.selCount + 1);
            this.sel[this.selCount++] = m.sel[i_sel];
        }
        this._updateSelIndex();
        assert(this.selIndexCount === this.selCount);
    }

    static shrinkText(ctx: CanvasRenderingContext2D, text: string, width: number) {
        let low = 0;
        let high = text.length;

        while (low <= high) {
            const mid = Math.floor((low + high) / 2);
            const textWidth = ctx.measureText(text.slice(0, mid).trim()).width;

            if (Math.round(textWidth) === width) {
                return mid;
            } else if (textWidth > width) {
                high = mid - 1;
            } else {
                low = mid + 1;
            }
        }

        return high;
    }

    static renderSegments(
        ctx: CanvasRenderingContext2D,
        segs: (number | string)[],
        x: number,
        y: number,
        w: number,
        h: number,
        s: number,
        rotate: boolean,
        clip: boolean,
        halign: number,
        valign: number,
        outline: false | string,
        sticky?: {
            // y1: number; y2: number;
            insetTop: number;
            // insetBottom: number
        },
        text?: string,
        fs?: number,
    ) {
        let finalY = 0;
        let totalH = 0;
        let totalW = 0;
        /*if (rotate) {
            ctx.strokeStyle="red";
            ctx.strokeRect(x, y, h, w);
        }*/
        if (!Array.isArray(segs)) {
        } else if (0 === segs[0]) {
            if (sticky) {
                const y1 = sticky.insetTop;
                //const y2=sticky.insetTop+sticky.y2-sticky.y1-sticky.insetBottom;
                const _y1 = Math.max(y, y1);
                const _y2 = Math.max(y + h, _y1);
                y = _y1;
                h = _y2 - _y1;
            }
            const fs2 = (2 * 12 * 72) / 96; // 12pt:
            ctx.font = CANVAS_FONT.font.replace("{sz}", (fs2 / 2).toString());
            const ascentEmu = Math.floor((CANVAS_FONT.emascent * fs2 * 6350) / CANVAS_FONT.emsquare);
            const descentEmu = Math.floor((CANVAS_FONT.emdescent * fs2 * 6350) / CANVAS_FONT.emsquare);
            let wEmu = 0;
            const n_segs = segs ? segs.length : 0;
            for (let i = 1; i < n_segs; i += 2) {
                wEmu += Math.floor(((segs[i] as number) * fs2 * 6350) / CANVAS_FONT.emsquare);
            }
            const lineW = wEmu / 9525;
            const lineH = (ascentEmu + descentEmu) / 9525;
            const dx = (w - lineW) / 2;
            const dy = (h - lineH) / 2;
            let _x = x + (1 === halign ? Math.max(0, dx) : 0);
            const _y = y + (1 === valign ? Math.max(0, dy) : 0);
            let _a = _y + ascentEmu / 9525;
            if (clip || dx < 0 || dy < 0 || rotate) {
                ctx.save();
                if (rotate) {
                    ctx.beginPath();
                    ctx.rect(x, y, w, h);
                    ctx.clip();
                    ctx.translate(x, y);
                    ctx.rotate(Math.PI / 2);
                    _x = 0;
                    _a = -(w - lineH) / 2; // _a-_y;
                } else {
                    ctx.beginPath();
                    ctx.rect(x, y, w, h);
                    ctx.clip();
                }
            }
            let wPx = 0;
            for (let i = 1; i < n_segs; i += 2) {
                const _w = Math.floor(((segs[i] as number) * fs2 * 6350) / CANVAS_FONT.emsquare) / 9525;
                if (0 === halign && 0 === valign && wPx > 0 && wPx + _w > w && !rotate) {
                    wPx = 0;
                    _a += lineH;
                    totalH += lineH;
                }

                // if (false && outline) {
                //     ctx.save();
                //     ctx.lineWidth = 2;
                //     ctx.miterLimit = 2;
                //     ctx.strokeText(segs[i + 1] as string, _x + wPx, _a, _w || undefined);
                //     ctx.restore();
                // }
                ctx.fillText(segs[i + 1] as string, _x + wPx, _a, _w || undefined);
                wPx += _w;
                totalH = Math.max(totalH, lineH);
                totalW = Math.max(totalW, wPx);
            }
            if (clip || dx < 0 || dy < 0 || rotate) {
                ctx.restore();
            }
            /*{
                ctx.save();
                ctx.strokeStyle="red";
                ctx.strokeRect(x, y, w, h);
                ctx.restore();
            }*/
            finalY = _a;
        } else {
            //const fs2=2*(segs[0] as number)*72/96;
            const ascent = segs[1] as number;
            const descent = segs[2] as number;
            ctx.font = CANVAS_FONT.font.replace("{sz}", ((segs[0] as number) * s).toString());
            //const ascentEmu=Math.floor((CANVAS_FONT.emascent*fs2*6350)/CANVAS_FONT.emsquare);
            //const descentEmu=Math.floor((CANVAS_FONT.emdescent*fs2*6350)/CANVAS_FONT.emsquare);
            let lineW = 0;
            let lines = 0;
            const n_segs = segs ? segs.length : 0;
            for (let i = 3; i < n_segs; i += 2) {
                lineW = Math.max(lineW, Math.abs(segs[i] as number));
                lines++;
            }
            const lineH = ascent + descent;
            const linesH = lines * lineH;
            const dx = (w - lineW) / 2;
            const dy = (h - linesH) / 2;
            let _x = x + (1 === halign ? Math.max(0, dx) : 0);
            const _y = y + (1 === valign ? Math.max(0, dy) : 0);
            let _a = _y + ascent;
            if (clip || dx < 0 || dy < 0 || rotate) {
                ctx.save();
                if (rotate) {
                    ctx.beginPath();
                    ctx.rect(x * s, y * s, w * s, h * s);
                    ctx.clip();
                    ctx.translate(x * s, y * s);
                    ctx.rotate(Math.PI / 2);
                    _x = 0;
                    _a = -(w - linesH) / 2; // _a-_y;
                } else {
                    /*
                    ctx.beginPath();
                    ctx.rect(x, y, w, h);
                    ctx.clip();
                    */
                }
            }
            /*if (true) {
                ctx.save();
                ctx.lineWidth=0;
                ctx.strokeStyle="red";
                ctx.strokeRect(_x*s, _y*s, lineW*s, linesH*s);
                ctx.restore();
            }*/
            let wPx = 0;
            if (fs === 12) {
                const finalText = text.slice(0, this.shrinkText(ctx, text, w)).trim();
                const finalTextWidth = ctx.measureText(finalText).width;
                const finalX = x + (w - finalTextWidth) / 2;

                if (outline) {
                    ctx.save();
                    ctx.strokeStyle = outline;
                    ctx.lineWidth = 2 * s;
                    ctx.miterLimit = 2 * s;
                    ctx.strokeText(finalText, finalX, y + (h + lineH / 2) / 2);
                    ctx.restore();
                }

                ctx.fillText(finalText, finalX, y + (h + lineH / 2) / 2);
                wPx += finalTextWidth;
                totalH = Math.max(totalH, lineH);
                totalW = Math.max(totalW, wPx);
            } else {
                for (let i = 3; i < n_segs; i += 2) {
                    const _w = segs[i] as number;
                    if (wPx > 0 && _w > 0) {
                        wPx = 0;
                        _a += lineH;
                        totalH += lineH;
                    }
                    const __x = (_x + wPx + (1 === halign ? (lineW - _w) / 2 : 0)) * s;
                    const __y = _a * s;
                    const __w = _w ? _w * s : undefined;

                    if (outline) {
                        ctx.save();
                        ctx.strokeStyle = outline;
                        ctx.lineWidth = 2 * s;
                        ctx.miterLimit = 2 * s;
                        ctx.strokeText(segs[i + 1] as string, __x, __y, __w);
                        ctx.restore();
                    }
                    ctx.fillText(segs[i + 1] as string, __x, __y, __w);
                    wPx += _w;
                    totalH = Math.max(totalH, lineH);
                    totalW = Math.max(totalW, wPx);
                }
            }
            if (clip || dx < 0 || dy < 0 || rotate) {
                ctx.restore();
            }

            finalY = _a;
        }
        return { totalH, totalW, finalY };
    }
}
