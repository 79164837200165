import { useCallback, useEffect, useState } from "react";
import { LCMDHookResult } from "@/app/types/LCMDHookResult";
import { useLCMD } from "@/app/LCMContext";
import { useProcessPlanTimestamp } from "@/app/store/canvasStore";
import { Notification } from "@/core/Notification/NotificationService";
import { SubId } from "@/app/types/SubId";

export type ProcessComment = {
    date: number;
    text: string;
    userId: SubId;
};

export function useProcessComments(processId: number | number[]) {
    const { worker } = useLCMD();
    const ppTimestamp = useProcessPlanTimestamp();
    const [hookResult, setHookResult] = useState<LCMDHookResult<Map<string, ProcessComment>>>({
        isLoading: true,
        isError: false,
        error: undefined,
        data: null,
    });

    useEffect(() => {
        if ((typeof processId != "number" && Array.isArray(processId) && processId.length == 0) || processId < 0) {
            return;
        }
        setHookResult({ ...hookResult, isLoading: true });

        worker.postMessage([
            "comment",
            "get",
            {
                processId,
                callback: worker.registerCallback((data) => {
                    setHookResult({ ...hookResult, isLoading: false, data });
                }),
            },
        ]);
    }, [processId, ppTimestamp]);

    const createComment = useCallback(
        async ({
            processId,
            text,
            notificationWrapperData,
        }: {
            processId: number;
            text: string;
            notificationWrapperData?: {
                fromUserId: string;
                users: string[];
                notification: Notification;
                auth_token?: string;
            };
        }) => {
            if ("string" === typeof text && worker) {
                notificationWrapperData = { ...notificationWrapperData, auth_token: worker.auth.auth_token };
                worker.postMessage([
                    "comment",
                    "new",
                    {
                        processId,
                        text,
                        notificationWrapperData: notificationWrapperData ? notificationWrapperData : null,
                    },
                ]);
            }
        },
        [],
    );

    const editComment = useCallback(
        async ({
            processId,
            commentId,
            text,
            notificationWrapperData,
        }: {
            processId: number;
            commentId: string;
            text: string;
            notificationWrapperData?: {
                fromUserId: string;
                users: string[];
                notification: Notification;
                auth_token?: string;
            };
        }) => {
            if ("string" === typeof text && worker) {
                notificationWrapperData = { ...notificationWrapperData, auth_token: worker.auth.auth_token };
                worker.postMessage([
                    "comment",
                    "edit",
                    {
                        processId,
                        text,
                        commentId,
                        notificationWrapperData: notificationWrapperData ? notificationWrapperData : null,
                    },
                ]);
            }
        },
        [],
    );

    const deleteComment = useCallback(async ({ processId, commentId }: { processId: number; commentId: string }) => {
        if ("string" === typeof commentId && worker) {
            worker.postMessage([
                "comment",
                "delete",
                {
                    processId,
                    commentId,
                },
            ]);
        }
    }, []);

    return { ...hookResult, createComment, deleteComment, editComment };
}
