import * as React from "react";
import { Fragment, useContext, useState } from "react";
import { Label, mergeStyleSets, PrimaryButton, Stack } from "@fluentui/react";
import { StateSelector } from "./StateSelector";
import { DigitalPlanningBoardSelectionEvent, intl, LCMDContextCardStatus } from "lcmd2framework";
import { LCMDContextCardStatus as DailyBoardAPICardStatus } from "lcmd2framework";
import { useLCMD } from "../../../app/LCMContext";
import { SidebarContext } from "../../../app/components/SidebarHost";
import { LCMDContextTaskDetailsResult, LCMDContextTaskDetailsResultCardDetails } from "../../../app/LCMDContextTypes";
import { Check, Plus, RotateCcw } from "lucide-react";
import { Button } from "@/components/ui/button";
import { ScrollArea } from "@/components/ui/scroll-area";
import { WebAppTelemetryFactory } from "@/app/services/WebAppTelemetry.service";

const styles = mergeStyleSets({
    label: {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "18px",
        lineHeight: "24px",
        /* identical to box height, or 133% */
        letterSpacing: "-0.02em",
    },

    smallText: {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "12px",
        lineHeight: "16px",
        textAlign: "center",
        letterSpacing: "-0.01em",
        color: "#565C60",
    },

    doneText: {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "-0.015em",
        color: "#565C60",
    },

    percentText: {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "14px",
        lineHeight: "20px",

        textAlign: "right",
        letterSpacing: "-0.015em",
        color: "#2C3032",
    },

    topContent: {
        borderBottom: "1px solid #E1E4E5",
    },
    contentMainViewProgression: {
        width: "100%",
        padding: "4px 8px 0 8px",
    },
    contentFooter: {
        padding: "12px 8px",
        bottom: "0",
        position: "absolute",
        width: "100%",
        height: "56px",
        borderTop: "1px solid #E1E4E5",
    },

    contentEmptyMainView: {
        padding: "90px 0 0 0",
    },

    contentEmptyMainViewImgContainer: {
        width: "200px",
        height: "200px",
        backgroundColor: "yellow",
    },
});

// todo: clear use-case of emptyView
export function TasksView(props: { wbMode: boolean }) {
    const propsContext = useContext(SidebarContext);
    const LCMD = useLCMD();
    const [tasks, setTasks] = useState<any[]>([]);
    const [milestoneState, setMilestoneState] = useState<LCMDContextCardStatus>(null);
    // const [progress, setProgress] = useState<number>(0);
    // const [editMode, setEditMode] = useState<{ oldVal: number } | null>(null);
    const [isWorkshopMode, setWorkshopMode] = useState<boolean>(false);
    const [indeterminated, setIndeterminate] = useState(false);
    const [selectedProcessIds, setSelectedProcessIds] = useState<DigitalPlanningBoardSelectionEvent>(null);
    const [selectedProcesses, setSelectedProcesses] = useState<LCMDContextTaskDetailsResult>(null);

    function handleCardStateChange(
        cardDetails: LCMDContextTaskDetailsResultCardDetails,
        newState: DailyBoardAPICardStatus,
    ) {
        LCMD.setCardsDetails(cardDetails.tid, {
            s: newState,
        });
    }

    function handleCardDeleteChange(cardDetails: LCMDContextTaskDetailsResultCardDetails) {
        LCMD.setCardsDetails(cardDetails.tid, null);
    }

    function handleCardEditChange(cardDetails: LCMDContextTaskDetailsResultCardDetails) {
        LCMD.showDialog("fw.task.edit", { ...cardDetails, isWhiteboard: selectedProcessIds?.isWhiteboard });
    }

    function handleCreateNewTaskClick() {
        WebAppTelemetryFactory.trackEvent("new-task-added");
        if (indeterminated) {
            console.warn("Button shouldn´t be enabled");
            return;
        }
        LCMD.setProcessDetails(
            selectedProcesses.pid.value,
            {
                duration: {
                    value: selectedProcesses.duration.value + 1,
                    unit: 3,
                },
            },
            selectedProcessIds?.isWhiteboard,
        );
    }

    function handleRestartClick() {
        changeStatusForAllCards(DailyBoardAPICardStatus.OPEN);
        WebAppTelemetryFactory.trackEvent("processUpdate", {
            where: "sidebar-allOpen",
            status: "Open"
        });
    }

    function handleAllCardsDoneClick() {
        changeStatusForAllCards(DailyBoardAPICardStatus.DONE);
        WebAppTelemetryFactory.trackEvent("processUpdate", {
            where: "sidebar-allDone",
            status: "Done"
        });
    }

    function changeStatusForAllCards(status: DailyBoardAPICardStatus) {
        if (!selectedProcesses || !selectedProcesses?.pid?.value) {
            return;
        }
        LCMD.setProcessDetails(
            selectedProcesses.pid.value,
            {
                cards: {
                    s: status,
                },
            },
            selectedProcessIds?.isWhiteboard,
        );
    }

    // function handleSaveSlider(){
    //     tasks.forEach((t,i) => {
    //         if(i < progress){
    //             handleCardStateChange(tasks[i], DailyBoardAPICardStatus.DONE);
    //         }else{
    //             handleCardStateChange(tasks[i], DailyBoardAPICardStatus.OPEN);
    //         }
    //     });
    //
    //     setEditMode(null);
    // }

    // function handleCancelSlider(){
    //     setProgress(editMode?.oldVal);
    //     setEditMode(null)
    // }
    //
    // function handleSliderChange(num: number){
    //     if(!editMode){
    //         setEditMode({oldVal: progress});
    //     }
    //
    //     setProgress(num);
    // }

    function getProcessProgress() {
        if (milestoneState != null && milestoneState >= 0) {
            return milestoneState === 2 ? 100 : 0;
        }

        const progressCount = tasks.filter((task) => task.s === LCMDContextCardStatus.DONE).length;

        return tasks?.length ? Math.floor((100 / tasks?.length) * progressCount) : 0;
    }

    propsContext.sidebarCtx.useSelectionEffect(
        (ev: DigitalPlanningBoardSelectionEvent) => {
            setSelectedProcessIds(ev);
            propsContext.view.key === "workshop" ? setWorkshopMode(true) : setWorkshopMode(false);
        },
        [setSelectedProcessIds],
    );

    LCMD.useProcessDetailsEffect(
        selectedProcessIds?.pid,
        selectedProcessIds?.isWhiteboard,
        (error, data) => {
            if (data) {
                setSelectedProcesses(data?.pid ? data : null);
                if (!data) {
                    setTasks([]);
                    setIndeterminate(false);
                    return;
                }
                if (!data.cards && data.isMilestone?.value === true) {
                    setMilestoneState(data.milestoneState.value);
                    setTasks([]);
                    setIndeterminate(data.milestoneState.indeterminate);
                    return;
                }

                if (data && data.cards) {
                    setMilestoneState(null);
                    setIndeterminate(data.cards.indeterminate);
                    setTasks(data.cards.value as LCMDContextTaskDetailsResultCardDetails[]);
                }
            }
        },
        [setSelectedProcesses, setTasks, setIndeterminate],
        props.wbMode ? propsContext.wbTS : propsContext.ppTS,
    );

    // useEffect(() => {
    //     let progressCount = 0;
    //
    //     tasks?.forEach((task) => {
    //         if(task.s === DailyBoardAPICardStatus.DONE){
    //             progressCount++
    //         }
    //     });
    //
    //     setProgress(progressCount);
    // }, [tasks])

    const emptyView = () => {
        return (
            <div>
                <Stack className={styles.contentEmptyMainView}>
                    <Stack.Item align={"center"} style={{ maxWidth: "300px" }}>
                        <div className={styles.contentEmptyMainViewImgContainer}>
                            {intl.get("TasksView.ImageContainerShortText")}
                        </div>
                    </Stack.Item>
                    <Stack.Item align={"center"} style={{ maxWidth: "300px" }}>
                        <Label className={styles.label} style={{ fontSize: "16px" }}>
                            {intl.get("TasksView.ContentEmptyHeadline")}
                        </Label>
                    </Stack.Item>
                    <Stack.Item align={"center"} style={{ maxWidth: "300px" }}>
                        <p className={styles.smallText}>{intl.get("TasksView.ContentEmptyText")}</p>
                    </Stack.Item>
                    <Stack.Item align={"center"} style={{ maxWidth: "300px" }}>
                        <PrimaryButton
                            text={intl.get("TasksView.NewTaskButtonText")}
                            onClick={handleCreateNewTaskClick}
                        />
                    </Stack.Item>
                </Stack>
            </div>
        );
    };
    const normalView = () => {
        return (
            <>
                <div>
                    {!isWorkshopMode ? (
                        <div className="m-2">
                            {!indeterminated && tasks?.length >= 0 && (
                                <div className="flex w-full justify-between px-2 pb-0 pt-1">
                                    <p className="text-base text-sm font-normal leading-5 tracking-tighter text-[#565C60]">
                                        {intl.get("TasksView.ProgressBarText")}
                                    </p>
                                    <span className="text-right text-base text-sm font-semibold leading-5 tracking-tighter text-[#2C3032]">
                                        {getProcessProgress()}%
                                    </span>
                                </div>
                            )}
                            <div className="mt-4">
                                {/*{!indeterminated &&*/}
                                {/*    <Fragment>*/}
                                {/*        <Slider*/}
                                {/*            style={{padding: 0}}*/}
                                {/*            showValue={false}*/}
                                {/*            max={tasks?.length}*/}
                                {/*            value={progress}*/}
                                {/*            step={1}*/}
                                {/*            snapToStep={true}*/}
                                {/*            disabled={true}*/}
                                {/*            onChange={handleSliderChange}*/}
                                {/*        />*/}
                                {/*        {editMode && <Stack horizontal horizontalAlign={"space-between"} tokens={{childrenGap: "15px"}}>*/}
                                {/*            <DefaultButton*/}
                                {/*                iconProps={{iconName: "Cancel"}}*/}
                                {/*                styles={{flexContainer: {flexDirection: "row-reverse"}}}*/}
                                {/*                onClick={handleCancelSlider}*/}
                                {/*            >*/}
                                {/*                {intl.get('TasksView.CancelButtonText')}*/}
                                {/*            </DefaultButton>*/}
                                {/*            <PrimaryButton*/}
                                {/*                iconProps={{iconName: "Save"}}*/}
                                {/*                styles={{flexContainer: {flexDirection: "row-reverse"}}}*/}
                                {/*                onClick={handleSaveSlider}*/}
                                {/*            >*/}
                                {/*                {intl.get('TasksView.SaveButtonText')}*/}
                                {/*            </PrimaryButton>*/}
                                {/*        </Stack>}*/}
                                {/*    </Fragment>*/}
                                {/*}*/}
                            </div>
                            <div className="mx-0 my-0 flex justify-between px-2 pb-0 pb-3.5 pt-5">
                                <Button variant="outline" onClick={handleRestartClick} className="border-[#8a8886]">
                                    <RotateCcw size={16} className="mr-2" />
                                    {intl.get("TasksView.RestartButtonText")}
                                </Button>
                                <Button
                                    variant="outline"
                                    onClick={handleAllCardsDoneClick}
                                    className="border-[#8a8886]"
                                >
                                    <Check size={16} className="mr-2" />
                                    {intl.get("TasksView.DoneButtonText")}
                                </Button>
                            </div>
                        </div>
                    ) : null}
                    {!isWorkshopMode ? <div className="border-b border-solid border-gray-300" /> : null}
                    <ScrollArea
                        style={{
                            width: "100%",
                            height: "calc(100% - 280px)",
                            position: "absolute",
                        }}
                    >
                        {!indeterminated &&
                            tasks?.map((task, index) => {
                                return (
                                    <StateSelector
                                        key={index}
                                        state={task.s}
                                        isHaveAttachment={task.atm}
                                        taskName={task.name}
                                        hiddenStateMenu={isWorkshopMode}
                                        onCardStateChange={(newState) => {
                                            handleCardStateChange(task, newState);
                                        }}
                                        onCardEdit={() => {
                                            handleCardEditChange(task);
                                        }}
                                        onCardDelete={() => {
                                            handleCardDeleteChange(task);
                                        }}
                                        date={task.date}
                                    />
                                );
                            })}
                    </ScrollArea>
                </div>
                <div className="absolute bottom-0 flex h-16 w-full justify-end border-t border-gray-300 px-2 py-3">
                    <Button
                        variant="outline"
                        onClick={handleCreateNewTaskClick}
                        disabled={indeterminated}
                        className="border-[#8a8886]"
                    >
                        <Plus size={16} className="mr-2" />
                        {intl.get("TasksView.NewTaskButtonText")}
                    </Button>
                </div>
            </>
        );
    };
    return (
        <>
            <div className="border-b border-solid border-[#E1E4E5] pb-4 pl-5 pr-0 pt-0 text-lg font-semibold leading-6 tracking-tighter">
                {intl.get("TasksView.TopContentLabel")}
            </div>
            {normalView()}
            {/*{!tasks?.length && !indeterminated ? normalView() : normalView()}*/}
        </>
    );
}
