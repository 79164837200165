import { useEffect } from "react";
import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import { Focus, X } from "lucide-react";
import { IconButton } from "../IconButton/IconButton";
import { intl } from "@/legacy/GlobalHelperReact";
import { useCanvasStore, useConflictMode, useDependencyChain } from "@/app/store/canvasStore";
import { useFocusMode } from "@/app/hooks/useFocusMode";
import { useLCMD } from "@/app/LCMContext";
import { LCMDTooltip } from "../Tooltip/LCMDTooltip";
import Adopt from "../../ui/icons/adopt-icon";
import { WebAppTelemetryFactory } from "@/app/services/WebAppTelemetry.service";

type ProcessPlanToolbar = {
    // ProcessPlanToolbar props
};

export function ProcessPlanToolbar(props: ProcessPlanToolbar) {
    const LCMD = useLCMD();
    const { setShowConflictMode, selectedProcessIds } = useCanvasStore();
    const { data: processDetails } = LCMD.useProcessDetails(selectedProcessIds?.at(0) || null);

    function handleAdoptDependency() {
        WebAppTelemetryFactory.trackEvent("resolve-conflict-adopt-dependecy");
        if (processDetails.successors) {
            const successors = processDetails.successors.value;
            successors.map((successor) => {
                if (successor.valid) {
                    return;
                }
                LCMD.setDependencyDetails(successor.targetId, selectedProcessIds?.at(0), {
                    type: successor.type,
                    lag: successor.lag - successor.conflictOffset,
                    unit: successor.unit,
                });
            });
        }
        if (processDetails.predecessors) {
            const predecessors = processDetails.predecessors.value;
            predecessors.map((predecessor) => {
                if (predecessor.valid) {
                    return;
                }
                LCMD.setDependencyDetails(selectedProcessIds?.at(0), predecessor.targetId, {
                    type: predecessor.type,
                    lag: predecessor.lag - predecessor.conflictOffset,
                    unit: predecessor.unit,
                });
            });
        }
    }

    const showConflictMode = useConflictMode();
    const dependencyChain = useDependencyChain();
    const { isFocusModeEnabled, enableFocusMode, disableFocusMode } = useFocusMode();

    useEffect(() => {
        const keyDownHandler = (event) => {
            if (event.key === "Escape") {
                event.preventDefault();
                disableFocusMode();
                setShowConflictMode(false);
            }
        };

        document.addEventListener("keydown", keyDownHandler);

        return () => {
            document.removeEventListener("keydown", keyDownHandler);
        };
    }, []);

    if (!showConflictMode) {
        return null;
    }

    function handleClose() {
        WebAppTelemetryFactory.trackEvent("resolve-conflict-closed");
        setShowConflictMode(false);
        // todo: control this over the StateManagement and not explicit in component
        disableFocusMode();
    }

    async function onCompressedView() {
        WebAppTelemetryFactory.trackEvent("resolve-conflict-compressed-view");
        if (isFocusModeEnabled) {
            await disableFocusMode();
        } else {
            await enableFocusMode(dependencyChain);
        }
    }

    return (
        <div className="absolute bottom-3 left-1/2 z-[9999999] flex h-12 -translate-x-1/2 transform items-center gap-x-1 rounded-lg bg-zinc-950 p-2">
            <div className="flex h-full items-center justify-center gap-x-1 text-white">
                <LCMDTooltip key="closeTooltip" text={intl.get("canvas.processPlanToolbar.close")}>
                    <IconButton
                        key="closeIcon"
                        icon={X}
                        onClick={handleClose}
                        className="h-8 rounded-md"
                        userflowId="pp-konflikt-kreuz"
                    />
                </LCMDTooltip>
                <LCMDTooltip key="focusTooltip" text={intl.get("canvas.processPlanToolbar.focus")}>
                    <IconButton
                        key="focusIcon"
                        icon={Focus}
                        className={
                            isFocusModeEnabled
                                ? "h-8 rounded-md bg-secondary text-secondary-foreground"
                                : "h-8 rounded-md"
                        }
                        onClick={onCompressedView}
                        userflowId="pp-konflikt-filterung"
                    />
                </LCMDTooltip>
                <Separator orientation="vertical" className="m-1 bg-[#35393B]" />
                <LCMDTooltip key="viewsTooltip" text={intl.get("canvas.processPlanToolbar.views")}>
                    <IconButton
                        key="viewsIcon"
                        icon={Adopt}
                        className="m-1 h-8 rounded-md"
                        onClick={handleAdoptDependency}
                        disabled={selectedProcessIds.length !== 1}
                        userflowId="pp-konflikt-verknuepfung-aendern"
                    />
                </LCMDTooltip>
            </div>
            <Button
                variant="outline"
                className="text-nowrap"
                disabled={selectedProcessIds.length !== 1}
                onClick={() => {
                    LCMD.resolveConflictByProcessId(selectedProcessIds?.at(0));
                    WebAppTelemetryFactory.trackEvent("resolve-conflict-button-clicked");
                }}
                userflowId="pp-konflikt-aufloesen"
            >
                <p className="text-medium text-sm leading-6 text-zinc-950">
                    {intl.get("ChangesPanel.resolveConflictButton.content")}
                </p>
            </Button>
        </div>
    );
}
