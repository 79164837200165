import * as React from "react";
import { useNavigate } from "react-router-dom";
import { ModalAssetHeader } from "../../ModalAsset";
import {
    ChoiceGroup,
    DayOfWeek,
    DefaultButton,
    defaultDatePickerStrings,
    IChoiceGroupOption,
    IStackItemStyles,
    PrimaryButton,
    Stack,
} from "@fluentui/react";
import { ModalAssetFooter } from "../../ModalAsset/ModalAssetFooter";
import { Icons } from "../../../RegisteredIcons";
import { useEffect, useState } from "react";
import { weekNumber } from "weeknumber";
import { RouteNames } from "../filter.interface";
import { intl, LocalizedDatePicker } from "lcmd2framework";
import { useToDoModalFilterStore } from "../../../../app/store/toDoModalFilterStore";
import { styles } from "../filterModalStyles";
import { WebAppTelemetryFactory } from "@/app/services/WebAppTelemetry.service";

export function DateFilter() {
    const navigate = useNavigate();
    const { filterData, setFilterData, projectDateRange } = useToDoModalFilterStore();
    const [selectedMode, setSelectedMode] = useState("everything");
    const [date, setDate] = useState({ startDate: new Date().getTime(), endDate: new Date().getTime() });
    const DATE_FILTER_MODES = {
        EVERYTHING: "everything",
        ONE_WEEK: "1week",
        FOUR_WEEKS: "4weeks",
        CUSTOM: "custom",
    };

    const options: IChoiceGroupOption[] = [
        {
            key: DATE_FILTER_MODES.EVERYTHING,
            text: intl.get("ModalToDoFilter.DeadlineFilter.everything"),
            iconProps: { iconName: "CalendarDay" },
            imageSize: { width: 145, height: 96 },
        },
        {
            key: DATE_FILTER_MODES.ONE_WEEK,
            text: intl.get("ModalToDoFilter.DeadlineFilter.1week"),
            iconProps: { iconName: "CalendarWeek" },
            imageSize: { width: 145, height: 96 },
        },
        {
            key: DATE_FILTER_MODES.FOUR_WEEKS,
            text: intl.get("ModalToDoFilter.DeadlineFilter.4weeks"),
            iconProps: { iconName: "CalendarWeek" },
            imageSize: { width: 145, height: 96 },
        },
        {
            key: DATE_FILTER_MODES.CUSTOM,
            text: intl.get("ModalToDoFilter.DeadlineFilter.custom"),
            iconProps: { iconName: Icons.Lcmd_Autofit },
            imageSize: { width: 145, height: 96 },
        },
    ];

    useEffect(() => {
        if (filterData?.dateFilterData?.length && filterData.dateFilterData[0]?.startDate) {
            setDate(filterData.dateFilterData[0]);
            setSelectedMode(filterData.dateFilterData[0]?.selectedMode);
        }
    }, [filterData]);

    const stackItemStyles: IStackItemStyles = {
        root: {
            display: "inline-block",
            padding: 5,
        },
    };

    function headerTitle() {
        return (
            <>
                {intl.get("ModalToDoFilter.DeadlineFilter.filter")} /{" "}
                {intl.get("ModalToDoFilter.DeadlineFilter.deadline")}
            </>
        );
    }

    function footerLeftContent() {
        return (
            <DefaultButton
                styles={{ root: { border: "none" } }}
                text={intl.get("ModalToDoFilter.DeadlineFilter.clearFilters")}
                iconProps={{ iconName: "Delete" }}
                onClick={() => {
                    setFilterData(null);
                }}
            />
        );
    }

    function footerRightContent() {
        return (
            <>
                <PrimaryButton
                    text={intl.get("ModalToDoFilter.DeadlineFilter.done")}
                    onClick={() => {
                        setFilterData({ ...filterData, dateFilterData: [{ ...date, selectedMode }] });
                        navigate(RouteNames.HOME);
                            WebAppTelemetryFactory.trackEvent('todo-deadline-filter-applied',{
                                deadlineOption: selectedMode
                            } );
                    }}
                />
            </>
        );
    }

    function fillDate(mode: string, startDate: number) {
        const start = new Date(startDate);
        switch (mode) {
            case DATE_FILTER_MODES.EVERYTHING:
                setDate(projectDateRange);
                break;
            case DATE_FILTER_MODES.ONE_WEEK:
                const end = new Date(startDate).setDate(start.getDate() + 7);
                setDate({
                    ...date,
                    startDate: startDate,
                    endDate: end,
                });
                break;
            case DATE_FILTER_MODES.FOUR_WEEKS:
                const end1 = new Date(startDate).setDate(start.getDate() + 4 * 7);
                setDate({
                    ...date,
                    startDate: startDate,
                    endDate: end1,
                });
                break;
            case DATE_FILTER_MODES.CUSTOM:
            default:
                break;
        }
    }

    function isStartDateDisabled() {
        return selectedMode === DATE_FILTER_MODES.EVERYTHING;
    }
    function isEndDateDisabled() {
        return selectedMode === DATE_FILTER_MODES.EVERYTHING || selectedMode === DATE_FILTER_MODES.ONE_WEEK || selectedMode === DATE_FILTER_MODES.FOUR_WEEKS;
    }

    return (
        <>
            <ModalAssetHeader
                onBackButtonChange={() => {
                    navigate(RouteNames.HOME);
                }}
                title={headerTitle()}
            />

            <div className={styles.body + " " + styles.bodyPadding}>
                <ChoiceGroup
                    options={options}
                    styles={{
                        root: {
                            marginBottom: 10,
                        },
                    }}
                    onChange={(event, option) => {
                        setSelectedMode(option.key);
                        fillDate(option.key, date.startDate);
                    }}
                    selectedKey={selectedMode}
                />

                <Stack horizontal styles={{ root: { background: "#F1F3F3", padding: "16px 20px" } }}>
                    <Stack.Item grow={3} styles={stackItemStyles}>
                        <div style={{ marginBottom: 6 }}>
                            <strong>{intl.get("ModalToDoFilter.DeadlineFilter.start")}</strong>
                        </div>
                        <div style={{ marginBottom: 10 }}>
                            <LocalizedDatePicker
                                disabled={isStartDateDisabled()}
                                styles={{ root: { width: "100%" } }}
                                firstDayOfWeek={DayOfWeek.Monday}
                                placeholder={intl.get("ModalToDoFilter.DeadlineFilter.start")}
                                value={new Date(date?.startDate)}
                                onSelectDate={(d: Date | null | undefined) => {
                                    setDate({ ...date, startDate: d.getTime() });
                                    fillDate(selectedMode, d.getTime());
                                }}
                                strings={defaultDatePickerStrings}
                                toDate={selectedMode === "custom" ? new Date(date?.endDate) : null}
                            />
                        </div>
                        <div>
                            {intl.get("ModalToDoFilter.DeadlineFilter.cw")}#{weekNumber(new Date(date.startDate))}
                        </div>
                    </Stack.Item>
                    <Stack.Item grow={3} align="auto" styles={stackItemStyles}>
                        <div style={{ marginBottom: 6 }}>
                            <strong>{intl.get("ModalToDoFilter.DeadlineFilter.end")}</strong>
                        </div>
                        <div style={{ marginBottom: 10 }}>
                            <LocalizedDatePicker
                                disabled={isEndDateDisabled()}
                                styles={{ root: { width: "100%" } }}
                                firstDayOfWeek={DayOfWeek.Monday}
                                placeholder={intl.get("ModalToDoFilter.DeadlineFilter.end")}
                                value={new Date(date?.endDate)}
                                onSelectDate={(d: Date | null | undefined) => {
                                    setDate({ ...date, endDate: d.getTime() });
                                }}
                                strings={defaultDatePickerStrings}
                                fromDate={selectedMode === "custom" ? new Date(date?.startDate) : null}
                            />
                        </div>
                        <div>
                            {intl.get("ModalToDoFilter.DeadlineFilter.cw")}#{weekNumber(new Date(date.endDate))}
                        </div>
                    </Stack.Item>
                </Stack>
            </div>

            <ModalAssetFooter leftContent={footerLeftContent()} rightContent={footerRightContent()} />
        </>
    );
}
