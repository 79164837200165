import { useLCMD } from "@/app/LCMContext";
import { intl } from "lcmd2framework";
import { useCallback, useState } from "react";
import { LCMDHookResult } from "../types/LCMDHookResult";


function useShowTradeSelection(){
    const [hookResult, setHookResult] = useState<LCMDHookResult<number[]>>({
        isLoading: false,
        isError: false,
        error: undefined,
        data: undefined
    });
    const LCMD = useLCMD();
    const showTradeSelection = useCallback((trades:number[]=[]) => {
        LCMD.showDialog("fw.trade.select", {
            title: intl.get("filter.trades.share.title"),
            trades,
            cb: (selectedTrades:number[]) => {
                setHookResult({...hookResult, data: selectedTrades})
            },
            multiselect: true,
        });
    }, []);
    return {...hookResult, showTradeSelection};
};

export { useShowTradeSelection };
