import * as React from "react";
import { MenuItem } from "./MenuItem";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { SidebarMenu, SidebarMenuItem } from "./interface";
import { getProcessSelectedMenus } from "./Sidebar.config";
import { DigitalPlanningBoardSelectionEvent, DigitalPlanningBoardViewKey, intl } from "lcmd2framework";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useBoolean } from "@fluentui/react-hooks";
import { LcmdModal2 } from "@/components/common/LcmModal2";
import { useLCMD } from "@/app/LCMContext";
import { ExtendedSearchBox } from "../common/extendedSearchBox";
import { SidebarContext } from "@/app/components/SidebarHost";
import { TradePanels } from "./TradePanels";
import { useCanvasNavigation } from "@/components/hooks/useCanvasNavigation.hook";
import { useCanvasIsInitiallyRendered, useCanvasStore, useInitialSidebarView } from "@/app/store/canvasStore";
import { WebAppTelemetryFactory } from "@/app/services/WebAppTelemetry.service";

export type SidebarProps = {
    menus?: SidebarMenu[];
    view: DigitalPlanningBoardViewKey;
};

export function Sidebar({ menus, view }: SidebarProps) {
    const LCMD = useLCMD();
    const canvasNavigation = useCanvasNavigation();
    const asyncMenus = useMemo(() => {
        return menus || getProcessSelectedMenus(intl, canvasNavigation, view);
    }, [intl, canvasNavigation, menus, view]);
    const [activePanelView, setActivePanelView] = useState<SidebarMenuItem>(null);
    const [activeMenuKey, setActiveMenuKey] = useState<string>(null);
    const [panelRootStyle, setPanelRootStyle] = useState<string>("mr-[60px] h-auto");
    const [showAddToLibraryModal, setShowAddToLibraryModal] = useState(false);
    const containerRef = useRef<HTMLDivElement>();
    const [selectedProcesses, setSelectedProcesses] = useState<DigitalPlanningBoardSelectionEvent>(null);
    const [newLibraryName, setNewLibraryName] = useState("");
    const [isSearchBarVisible, setSearchBarVisible] = useBoolean(false);
    const [findValue, setFindValue] = React.useState<string>("");
    const [searchResults, setSearchResults] = useState<any>([]);
    const [indexOfCurrentResult, setIndexOfCurrentResult] = useState<number>(-1);
    const propsContext = useContext(SidebarContext);
    const [selectedProcessesData, setSelectedProcessesData] = useState(null);
    const [disableLink, setDisableLink] = useState(false);

    const [hideAlertDialog, { toggle: toggleHideAlertDialog }] = useBoolean(true);
    const initialSidebarView = useInitialSidebarView();
    const canvasIsInitiallyRendered = useCanvasIsInitiallyRendered();

    LCMD.useProcessDetailsEffect(
        selectedProcesses?.pid,
        selectedProcesses?.isWhiteboard,
        (error, data) => {
            if (data) {
                setSelectedProcessesData(data);
            }
        },
        [selectedProcesses],
    );

    useEffect(() => {
        if (selectedProcessesData == null || selectedProcesses.pid.length < 2) {
            setDisableLink(true);
        } else if (selectedProcessesData.pid.value == null || selectedProcessesData.pid.value.includes(null)) {
            setDisableLink(true);
        } else {
            setDisableLink(false);
        }
    }, [selectedProcessesData]);
    const handleCreateNewLibraryItem = React.useCallback(() => {
        // todo: connect API
        // value is in newLibraryName

        //LCMD.setOrCreateLibraryItem(null, {name: newLibraryName});
        WebAppTelemetryFactory.trackEvent("process-chain-added-standard-library");
        LCMD.executeCommand({
            cmd: "libAdd",
            pid: selectedProcesses.pid,
            value: {
                name: newLibraryName,
            },
        });
        setNewLibraryName("");
        setShowAddToLibraryModal(false);
    }, [selectedProcesses, newLibraryName, setNewLibraryName, setShowAddToLibraryModal]);

    const createLibraryItemModalButtons = [
        <Button
            key="createLibraryCancel"
            variant="secondary"
            onClick={() => {
                setShowAddToLibraryModal(false);
            }}
        >
            {intl.get("Sidebar.CreateLibraryItemModal.CancelButtonText")}
        </Button>,
        <Button key="createLibraryAdd" disabled={!newLibraryName?.trim()} onClick={handleCreateNewLibraryItem}>
            {intl.get("Sidebar.CreateLibraryItemModal.AddButtonText")}
        </Button>,
    ];

    propsContext.sidebarCtx.useSelectionEffect(
        (ev: DigitalPlanningBoardSelectionEvent) => {
            setSelectedProcesses(ev);
        },
        [setSelectedProcesses],
    );

    function findMenuItem(menu: any, key: string) {
        for (const item of menu) {
            if (item.key === key) {
                return item;
            } else if (item.menuItems?.length > 0) {
                const found = findMenuItem(item.menuItems, key);
                if (found) {
                    return found;
                }
            }
        }
        return null;
    }

    useEffect(() => {
        if (canvasIsInitiallyRendered && initialSidebarView && asyncMenus) {
            const menuItem = findMenuItem(asyncMenus, initialSidebarView);
            if (menuItem) {
                setTimeout(() => {
                    menuOpen(menuItem);
                    useCanvasStore.getState().setInitialSidebarView(null);
                }, 0);
            }
        }
    }, [asyncMenus, initialSidebarView, canvasIsInitiallyRendered]);

    useEffect(() => {
        setActivePanelView(null);
        setActiveMenuKey(null);
    }, [asyncMenus, propsContext.view.key]);

    //Case findValue changes!
    LCMD.useTextSearchEffect(
        findValue,
        (data) => {
            if (!findValue || findValue == "") {
                setSearchResults([]);
                return;
            }
            setSearchResults(data.processes);
            if (data.processes.length == 0) {
                setIndexOfCurrentResult(-1);
                return;
            }

            setIndexOfCurrentResult(0);
        },
        [LCMD, setSearchResults],
    );

    useEffect(() => {
        if (indexOfCurrentResult == -1) {
            return;
        }
        canvasNavigation.goTo(searchResults[indexOfCurrentResult]);
    }, [indexOfCurrentResult, searchResults]);

    useEffect(() => {
        if (containerRef.current) {
            const rect = containerRef.current.getBoundingClientRect();
            setPanelRootStyle(`${panelRootStyle} mt-[${rect.top}] h-auto`);
        }
    }, [containerRef.current, propsContext.top]);

    useEffect(() => {
        document.addEventListener("spotlight-search", setSearchBarVisible.toggle);

        return () => {
            document.removeEventListener("spotlight-search", setSearchBarVisible.toggle);
        };
    });

    function handlePrevious() {
        if (searchResults.length == 0) {
            setIndexOfCurrentResult(-1);
            return;
        }

        if (indexOfCurrentResult == 0) {
            setIndexOfCurrentResult(searchResults.length - 1);
            return;
        }

        setIndexOfCurrentResult(indexOfCurrentResult - 1);
    }

    function handleNext() {
        if (searchResults.length == 0) {
            setIndexOfCurrentResult(-1);
            return;
        }
        if (indexOfCurrentResult + 1 > searchResults.length - 1) {
            setIndexOfCurrentResult(0);
            return;
        }

        setIndexOfCurrentResult(indexOfCurrentResult + 1);
    }

    function menuOpen(menuItem: SidebarMenuItem) {
        if (activePanelView === menuItem) {
            setActiveMenuKey(null);
            setActivePanelView(null);
            propsContext.sidebarCtx.setTradeData(null);
            return;
        }
        if (menuItem.name === "Library") {
            WebAppTelemetryFactory.trackEvent("whiteboard-standard-library-opened");
        }
        setActiveMenuKey(menuItem.key);
        setActivePanelView(menuItem);
    }

    return (
        <>
            {isSearchBarVisible && (
                <div
                    style={{
                        position: "absolute",
                        right: activeMenuKey === null ? "60px" : "401px",
                        top: "10px",
                    }}
                >
                    <div style={{ marginRight: "8px" }}>
                        <ExtendedSearchBox
                            currentResult={indexOfCurrentResult + 1}
                            allResults={searchResults.length}
                            onPrevious={handlePrevious}
                            onNext={handleNext}
                            searchText={findValue}
                            onSearch={(value) => {
                                setFindValue(value);
                            }}
                            onClose={() => {
                                setFindValue("");
                                setIndexOfCurrentResult(-1);
                                setSearchBarVisible.setFalse();
                            }}
                        />
                    </div>
                </div>
            )}
            <div className={"sidebar"} style={{ height: "100%", overflow: "hidden" }} ref={containerRef}>
                <div
                    style={{
                        width: 60,
                        padding: 10,
                        height: "100%",
                        zIndex: 9999999,
                        backgroundColor: "rgb(248, 250, 250)",
                        boxShadow: "rgb(0 0 0 / 22%) 0px 25.6px 57.6px 0px, rgb(0 0 0 / 18%) 0px 4.8px 14.4px 0px",
                    }}
                >
                    {asyncMenus.map((menu, index) => (
                        <div key={menu.key}>
                            <div
                                id={"sidebar-menu-" + index}
                                data-menu-name={menu.name}
                                style={{
                                    marginBottom: 10,
                                    borderBottom: "1px solid rgb(225, 228, 229)",
                                    paddingBottom: 10,
                                }}
                            >
                                <div style={{ padding: 0, margin: 0 }}>
                                    {menu.menuItems.map((menuItem) => (
                                        <div key={menuItem.key}>
                                            {menuItem.key === "com.lcmd.processes.link" ? (
                                                <MenuItem
                                                    menuItem={menuItem}
                                                    disabled={disableLink}
                                                    onClick={() => {
                                                        console.log("linked");
                                                        WebAppTelemetryFactory.trackEvent("link-process-clicked");
                                                        LCMD.executeCommand({
                                                            cmd: view !== "workshop" ? "link" : "whiteboard-link",
                                                            pid: selectedProcesses.pid,
                                                        });
                                                    }}
                                                />
                                            ) : (
                                                <MenuItem
                                                    menuItem={menuItem}
                                                    checked={
                                                        activeMenuKey == menuItem.key ||
                                                        ("com.lcmdigital.menu.select" === menuItem.key &&
                                                            propsContext.marquee)
                                                    }
                                                    onClick={() => {
                                                        // todo: remove hack
                                                        if ("com.lcmdigital.menu.select" === menuItem.key) {
                                                            WebAppTelemetryFactory.trackEvent("select-button");
                                                            LCMD.executeCommand({
                                                                cmd: "marquee",
                                                                value: !propsContext.marquee,
                                                            });
                                                            return;
                                                        }

                                                        if ("com.lcmdigital.menu.search" === menuItem.key) {
                                                            setSearchBarVisible.toggle();
                                                            return;
                                                        }

                                                        if ("com.lcmdigital.sidebar.addToLibrary" === menuItem.key) {
                                                            WebAppTelemetryFactory.trackEvent(
                                                                "sidebar-add-to-library-opened",
                                                            );
                                                            if (!propsContext.view.libraries.active) {
                                                                toggleHideAlertDialog();
                                                            } else {
                                                                setShowAddToLibraryModal(true);
                                                            }
                                                            return;
                                                        }

                                                        if (menuItem.detailsContent) {
                                                            menuOpen(menuItem);
                                                        }

                                                        if (menuItem.onClick) {
                                                            menuItem.onClick(menuItem);
                                                        }
                                                    }}
                                                />
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <LcmdModal2
                    open={showAddToLibraryModal}
                    buttons={createLibraryItemModalButtons}
                    header={{ title: intl.get("Sidebar.AddToLibraryModal.AddToLibraryHeadline") }}
                    onOpenChange={() => {
                        setShowAddToLibraryModal(false);
                        setNewLibraryName("");
                    }}
                >
                    <Label>{intl.get("Sidebar.AddToLibraryModal.NameOfTheProcessChainLabel")}</Label>
                    <Input
                        onChange={(ev) => {
                            setNewLibraryName(ev.target.value);
                        }}
                    />
                </LcmdModal2>
                <TradePanels
                    activePanelView={activePanelView}
                    activeMenuKey={activeMenuKey}
                    setActiveMenuKey={setActiveMenuKey}
                    setActivePanelView={setActivePanelView}
                />
                <LcmdModal2
                    header={{ title: intl.get("Sidebar.AlertDialog.firstSelectWhiteboardBoard.title") }}
                    open={!hideAlertDialog}
                    onOpenChange={toggleHideAlertDialog}
                    buttons={[
                        <Button key="ok" variant="default" onClick={toggleHideAlertDialog}>
                            {intl.get("fw.ok")}
                        </Button>,
                    ]}
                >
                    {intl.get("Sidebar.AlertDialog.firstSelectWhiteboardBoard.subText")}
                </LcmdModal2>
            </div>
        </>
    );
}
