import * as React from "react";
import { useContext, useState } from "react";
import { Label, Stack } from "@fluentui/react";
import {
    DigitalPlanningBoardSelectionEvent,
    intl,
    assert,
    HelperEpochMSToLocalDate,
    DigitalPlanningBoardLibraryEvent,
} from "lcmd2framework";
import { styleSets } from "./styleSets";
import { ProcessSidebarView } from "./Views/ProcessSidebarView";
import { AreaSidebarView } from "./Views/AreaSidebarView";
import "../DetailsView/styles.detailsview.scss";
import { useLCMD } from "../../../app/LCMContext";
import { SidebarContext } from "../../../app/components/SidebarHost";
import { TrainInfo } from "./interface";
import { LCMDContextTaskDetailsResult } from "../../../app/LCMDContextTypes";

export function WhiteboardDetailsView(props: {}) {
    const propsContext = useContext(SidebarContext);

    const LCMD = useLCMD();
    const [libraryId, setLibraryId] = useState(null);
    const [refreshToken, setRefreshToken] = useState(Symbol());
    const [orderByNameSuffix, setOrderByNameSuffix] = useState<boolean>(false);
    const [selectedProcesses, setSelectedProcesses] = React.useState<DigitalPlanningBoardSelectionEvent>(null);
    propsContext.sidebarCtx.useSelectionEffect(
        (ev: DigitalPlanningBoardSelectionEvent) => {
            setSelectedProcesses(ev);
            setLibraryId(propsContext.view.libraries.active);
        },
        [setSelectedProcesses],
    );

    const [data, setData] = useState<LCMDContextTaskDetailsResult>(null);
    const [trainInfos, setTrainInfos] = useState<{ [trainId: string]: TrainInfo }>({});

    LCMD.useProcessDetailsEffect(
        selectedProcesses?.pid || [],
        selectedProcesses?.isWhiteboard,
        (error, data) => {
            const _setTrainInfos = (error, data) => {
                if (error) {
                    setTrainInfos({});
                    setData(null);
                } else {
                    const gpaPreview = data.project.gpaPreview;
                    const gpaTrainInfos = gpaPreview?.trainInfos || {};
                    const _trainInfos = Object.getOwnPropertyNames(gpaTrainInfos).reduce((ret, _trainId) => {
                        const gpaTrainInfo = gpaTrainInfos[_trainId];
                        gpaTrainInfo.start =
                            gpaTrainInfo.start > 0 ? HelperEpochMSToLocalDate(gpaTrainInfo.start) : undefined;
                        ret[_trainId] = { ...(ret[_trainId] || { id: Number.parseInt(_trainId) }), ...gpaTrainInfo };
                        return ret;
                    }, trainInfos);
                    setTrainInfos(_trainInfos);
                    setOrderByNameSuffix(gpaPreview?.oderByNameSuffix ? true : false);
                    setData(data);
                }
            };
            if (data?.projectId === propsContext.view.libraries.active) {
                _setTrainInfos(error, data);
            } else if (data?.rsid === propsContext.view.libraries.active) {
                LCMD.getProcessDetails(data.rid, (error, data) => {
                    assert(data.projectId === propsContext.view.libraries.active);
                    _setTrainInfos(error, data);
                }, true);
                // setTrainInfos({});
                // setData(null);
            } else {
                setTrainInfos({});
                setData(null);
            }
        },
        [setData, setTrainInfos, setLibraryId, setOrderByNameSuffix, propsContext.view.libraries.active, refreshToken],
        propsContext.wbTS,
    );

    propsContext.sidebarCtx.useLibrariesEffect(
        (ev: DigitalPlanningBoardLibraryEvent) => {
            if (!propsContext.view.libraries.active) {
                return;
            }
            setLibraryId(propsContext.view.libraries.active);
        },
        [propsContext.view.libraries.active],
    );

    return data ? (
        <div style={{ overflow: "scroll", height: "calc(100% - 105px)" }} className="main">
            <Stack className={styleSets.borderBottom}>
                <Stack tokens={{ padding: "0 0 17px 20px" }}>
                    <Label className={styleSets.label}>{intl.get("WhiteboardDetailsView.title")}</Label>
                </Stack>
            </Stack>

            {(data.ppaths?.value || []).map((path) => {
                if (Array.isArray(path) && 1 <= path.length) {
                    const trainId = Array.isArray(data.ppid.value) ? data.ppid.value[0] : data.ppid.value;
                    return (
                        <ProcessSidebarView
                            key={trainId}
                            trainId={trainId}
                            data={data}
                            onTrainInfos={(data) => {
                                setTrainInfos(data);
                                setRefreshToken(Symbol());
                            }}
                            trainInfos={trainInfos}
                            orderByNameSuffix={orderByNameSuffix}
                            onOrderByNameSuffix={setOrderByNameSuffix}
                            webtrackingPrefix="whiteboard-planning"
                        />
                    );
                } else {
                    const trainId = Array.isArray(data.pid.value) ? data.pid.value[0] : data.pid.value;
                    return (
                        <AreaSidebarView
                            key={trainId}
                            trainId={trainId}
                            data={data}
                            onTrainInfos={(data) => {
                                setTrainInfos(data);
                                setRefreshToken(Symbol());
                            }}
                            trainInfos={trainInfos}
                            libraryId={libraryId}
                            orderByNameSuffix={orderByNameSuffix}
                            onOrderByNameSuffix={setOrderByNameSuffix}
                        />
                    );
                }
            })}
        </div>
    ) : null;
}
