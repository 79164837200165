import * as React from "react";
import { useMemo, useRef, useState, useEffect } from "react";
import { intl } from "@/legacy/GlobalHelperReact";
import { cn } from "@/lib/utils";
import loadingImage from "@/assets/img/loading/lcmd.loading.gif";
import { Upload, Trash, ExternalLink, Plus } from "lucide-react";
import { useLCMD } from "@/app/LCMContext";
import { LCMDContextTaskDetailsResultSingleItemWithDetails } from "@/app/LCMDContextTypes";
import { LcmdModal2 } from "@/components/common/LcmModal2";
import { LCMDTooltip } from "@/components/common/Tooltip/LCMDTooltip";
import { AlertDialog } from "@/components/common/AlertDialog/AlertDialogV2";
import { hasTouchSupport } from "@/utils/device";
import { IFrameViewer } from "@/components/ground-layout-modal/IFrameViewer";
import { WebAppTelemetryFactory } from "@/app/services/WebAppTelemetry.service";

export type GroundLayoutModalProps = {
    projectId: string;
    dialogId?: string;
    tid?: number;
};

export const GroundLayoutModal = ({ tid, dialogId }: GroundLayoutModalProps) => {
    const LCMD = useLCMD();
    const defaultModalState: {
        image: LCMDContextTaskDetailsResultSingleItemWithDetails<
            {
                blobId?: string;
                contentType?: string;
                fileName?: string;
            },
            {
                url?: string;
            }
        >;
        fileSrc: string;
    } = {
        image: null,
        fileSrc: "",
    };

    const fileInput = useRef<HTMLInputElement>();
    const [modalState, setModalState] = useState(defaultModalState);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [isDraggingOver, setIsDraggingOver] = useState(false);
    const [areaName, setAreaName] = useState("");
    const [hideFileUpload, setHideFileUpload] = useState(true);
    const [blobSrc, setBlobSrc] = useState("");

    useEffect(() => {
        // If the dialog appears on the same coordinates of the area attachment, the dialog to select file is triggered
        // This happens only on touchable devices
        // To prevent it, we have a workaround to hide the input momentarily
        setTimeout(() => setHideFileUpload(false), 100);
        LCMD.getProcessDetails(tid, (error, data) => {
            setAreaName(data?.name?.value || intl.get("GroundLayoutModal.unknownArea"));
            if (error) {
                console.warn(error);
                setModalState(defaultModalState);
            } else {
                setModalState({
                    image: data?.image,
                    fileSrc: data?.image?.url ? data.image.url : "",
                });
            }
        });
    }, []);

    const uploadHandler = useMemo(
        () => (ev) => {
            WebAppTelemetryFactory.trackEvent("area-attachment-added");
            const fileInput: HTMLInputElement = ev.target;
            setIsUploading(true);
            const cb = LCMD.worker.registerCallback((data) => {
                if (data?.blobId) {
                    LCMD.setProcess(tid, [
                        {
                            name: "image",
                            value: {
                                blobId: data.blobId,
                                fileName: fileInput.files[0].name,
                                contentType: fileInput.files[0].type,
                            },
                        },
                    ]);
                    handleCloseModal();
                    setIsUploading(false);
                }
            });
            LCMD.uploadAttachments({}, fileInput.files, undefined, cb);
        },
        [LCMD],
    );

    function handleDeleteAttachment() {
        if (!modalState.image?.value?.blobId) {
            return;
        }
        LCMD.setProcess(tid, [
            {
                name: "image",
                value: null,
            },
        ]);
        LCMD.deleteAttachments({}, [modalState.image.value.blobId]);
        handleCloseModal();
    }

    function handleCloseModal() {
        LCMD.showDialog(dialogId, false);
    }

    return (
        <div>
            <LcmdModal2
                open
                onOpenChange={handleCloseModal}
                size="l"
                header={{ title: intl.get("GroundLayoutModal.headline"), subtitle: areaName }}
            >
                {isUploading ? (
                    <div className="flex h-[500px] flex-col items-center justify-center">
                        <img alt="loading image" src={loadingImage} />
                        {intl.get("GroundLayoutModal.loading")}
                    </div>
                ) : modalState.fileSrc ? (
                    <div className="group flex flex-col items-center justify-center">
                        <input
                            type="file"
                            hidden
                            ref={fileInput}
                            onChange={uploadHandler}
                            name="file"
                            accept="image/*, .pdf"
                        />
                        {/*<img className="max-h-[500px]" alt="Area image" src={modalState.fileSrc} />*/}
                        <IFrameViewer path={modalState.fileSrc} onBlobChange={(blob: string) => setBlobSrc(blob)} />
                        {/* There is an ongoing discussion on shadcn community to add a ButtonGroup component */}
                        {/* https://github.com/shadcn-ui/ui/discussions/4283 */}
                        {/* When that component is available, we should replace it in here */}
                        <div
                            className={cn(
                                "absolute bottom-0 right-0 m-5 flex gap-3 rounded-lg bg-black p-2 text-white",
                                hasTouchSupport() ? "opacity-100" : "opacity-0 group-hover:opacity-100",
                            )}
                        >
                            <LCMDTooltip text={intl.get("GroundLayoutModal.deleteTooltip")}>
                                <Trash size="16" onClick={() => setShowDeleteDialog(true)} />
                            </LCMDTooltip>
                            <LCMDTooltip text={intl.get("GroundLayoutModal.replaceTooltip")}>
                                <Upload size="16" onClick={() => fileInput?.current.click()} />
                            </LCMDTooltip>
                            <LCMDTooltip text={intl.get("GroundLayoutModal.openTooltip")}>
                                <ExternalLink size="16" onClick={() => window.open(blobSrc, "_blank")} />
                            </LCMDTooltip>
                        </div>
                    </div>
                ) : (
                    <div
                        className={cn(
                            "relative flex h-[500px] flex-col items-center justify-center gap-2 rounded-lg border border-dashed border-slate-400 bg-slate-100",
                            isDraggingOver && "bg-violet-200",
                        )}
                    >
                        <input
                            type="file"
                            className="absolute h-full w-full cursor-pointer rounded-lg opacity-0"
                            hidden={hideFileUpload}
                            ref={fileInput}
                            onDragEnter={() => setIsDraggingOver(true)}
                            onDragLeave={() => setIsDraggingOver(false)}
                            onChange={uploadHandler}
                            name="file"
                            accept="image/*, .pdf"
                        />
                        {isDraggingOver ? (
                            <>
                                <Plus size="16"></Plus>
                                {intl.get("GroundLayoutModal.uploadAreaDragText")}
                            </>
                        ) : (
                            <>
                                <Upload size="16"></Upload>
                                {intl.get("GroundLayoutModal.uploadAreaText")}
                            </>
                        )}
                    </div>
                )}
                {showDeleteDialog && (
                    <AlertDialog
                        open
                        onOpenChange={() => setShowDeleteDialog(false)}
                        title={intl.get("GroundLayoutModal.deleteDialog.header")}
                        subText={intl.get("GroundLayoutModal.deleteDialog.description")}
                        okButtonText={intl.get("GroundLayoutModal.deleteDialog.buttonText")}
                        onOk={handleDeleteAttachment}
                    ></AlertDialog>
                )}
            </LcmdModal2>
        </div>
    );
};
