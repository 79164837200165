﻿import { FrameworkUpdateNeededError, generateWarmupId } from "../model/GlobalHelper";
import { notifyError } from "../model/ApiHelper";
import { createRoot } from "react-dom/client";
import { SERVICES } from "../model/services";
import * as React from "react";

export interface OnErrorProps {
    e: {
        message: string;
    };
    forcedUpdate: boolean;
    buildInfo: {
        build: string;
        hash: string;
    };
    projectId: string;
}

let _onErrorHandler: (props: OnErrorProps) => JSX.Element = null;
let _onErrorRoot: HTMLDivElement = null;
export function setOnErrorHandler(onError: (props: OnErrorProps) => JSX.Element) {
    _onErrorHandler = onError;
}

const allowedErrorMessages = new Set([
    `ResizeObserver loop completed with undelivered notifications.`,
    `Error: Could not load Userflow.js`,
]);

export function showScreenOfDeath(e?, event?) {
    const rootDiv = document.getElementById("root");
    if (!rootDiv) {
        return;
    }

    console.error(e);
    let stack = undefined;
    let warmupId = undefined;
    let projectId = undefined;

    try {
        stack = e?.stack || undefined;
        warmupId = (self as any)?.lcmdigital?.pipe?.warmupId;
        projectId = (self as any)?.lcmdigital?.pipe?.nav?.session?.pid;
    } catch (_e) {
        // nothing to do
    }

    if ("string" !== typeof warmupId || 0 === warmupId.length) {
        warmupId = generateWarmupId();
    }

    notifyError(e, stack);

    if (!e || allowedErrorMessages.has(e.toString())) {
        return;
    }

    if (!_onErrorHandler) {
        const div = document.createElement("div");
        div.id = "crash";
        div.innerHTML =
            "<div><p>Development Mode!</p>Please try to reproduce this and report to development!<p><p>Please reload page to continue...<p></div>";
        rootDiv.parentNode.replaceChild(div, rootDiv);
        return;
    }

    if (_onErrorRoot) {
        return;
    }

    _onErrorRoot = document.createElement("div");
    _onErrorRoot.style.position = "absolute";
    _onErrorRoot.style.left = "0px";
    _onErrorRoot.style.right = "0px";
    _onErrorRoot.style.top = "0px";
    _onErrorRoot.style.bottom = "0px";
    document.body.appendChild(_onErrorRoot);
    const root = createRoot(_onErrorRoot);
    root.render(
        <_onErrorHandler
            e={e || { message: event?.message || "?" }}
            forcedUpdate={e instanceof FrameworkUpdateNeededError}
            buildInfo={{
                build: SERVICES?.BUILD?.BUNDLE_HASH || null,
                hash: SERVICES?.BUILD?.WORKER_HASH || null,
            }}
            projectId={projectId}
        />,
    );
}
