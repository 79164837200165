import { DialogHeader } from "@/components/ui/dialog";
import { Globe } from "lucide-react";
import { intl } from "lcmd2framework";
import { useState } from "react";
import { cn } from "@/lib/utils";

export enum EGlobeState {
    Private = 0,
    Global = 1,
}

export function GlobeField({
    initialGlobeState = EGlobeState.Private,
    onGlobalStateClick,
}: {
    initialGlobeState: EGlobeState;
    onGlobalStateClick: (globalState: EGlobeState) => void;
}) {
    const [globalState, setGlobalState] = useState<EGlobeState>(initialGlobeState);

    function handleGlobalStateClicked() {
        toggleGlobeState();
        onGlobalStateClick(globalState === EGlobeState.Private ? EGlobeState.Global : EGlobeState.Private);
    }

    const toggleGlobeState = () => {
        setGlobalState((prevState) => (prevState === EGlobeState.Private ? EGlobeState.Global : EGlobeState.Private));
    };

    return (
        <div
            className={cn(
                "flex h-full min-w-max cursor-pointer items-center justify-center gap-2 rounded-e bg-white px-4",
                globalState === EGlobeState.Global && "bg-violet-100",
            )}
            onClick={handleGlobalStateClicked}
        >
            {globalState === EGlobeState.Private ? (
                <>
                    <DialogHeader className={"text-xs font-normal text-slate-500"}>
                        {intl.get("globe.makeGlobeTitle")}
                    </DialogHeader>
                    <Globe className="h-4 w-4" />
                </>
            ) : (
                <>
                    <DialogHeader className={"text-xs font-normal text-violet-600"}>
                        {intl.get("globe.globeTitle")}
                    </DialogHeader>
                    <Globe className="h-4 w-4 rounded-full bg-violet-600 stroke-white outline-1 outline-violet-600" />
                </>
            )}
        </div>
    );
}
