import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";
import { intl } from "lcmd2framework";
import { Paths } from "../../types/routes";
import rocket from "@/lcmd2loginV2/src/assets/rocket2.png";
import { IconButton } from "@/lcmd2loginV2/src/components/common/iconButton/IconButton";
import { ArrowLeft } from "lucide-react";

export function BookAppointment() {
    const navigate = useNavigate();

    //TODO: make hook

    function handleBack() {
        navigate(Paths.HOME);
    }

    return (
        <>
            <div className={"pb-8"}>
                <img width="200" src={rocket} className={"mx-auto"} />
            </div>
            <div className={"flex gap-1 text-xl font-semibold leading-7 text-zinc-900"}>
                <IconButton icon={ArrowLeft} onClick={handleBack} />
                {intl.get("LcmdLogin.BookAppointment.Headline")}
            </div>
            <div className={"mb-8 mt-4 text-sm font-medium text-zinc-400"}>
                {intl.get("LcmdLogin.BookAppointment.getStarted")}
            </div>
            <Button asChild>
                <a href={intl.get("LcmdLogin.BookAppointment.link")}>
                    {intl.get("LcmdLogin.BookAppointment.bookCall")}
                </a>
            </Button>
        </>
    );
}
