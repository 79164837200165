import * as React from "react";
import { FrameworkError, intl } from "lcmd2framework";

import { useCallback, useMemo, useState } from "react";
import { useBoolean } from "@fluentui/react-hooks";

import { useOnInputChange } from "../../../hooks/use-on-input-change";
import { useFilterCollection } from "../../data-access-hooks/use-filter-collection";

import { TextField } from "@fluentui/react";
import { Checkbox } from "@/components/ui/checkbox";

const FILTER_NAME_MAX_LENGTH = 40;

export function SaveScreen({
    dialog,
    route: {
        params: { filter, onSave, onCancel },
    },
}) {
    const { items: filterItems } = useFilterCollection();

    const currentFilterName: string = filter?.name || "";

    const [submitted, { setTrue: setSubmitted }] = useBoolean(false);
    const [nextFilterName, setNextFilterName] = React.useState(currentFilterName);
    const [savingState, setSavingState] = React.useState(false as boolean | FrameworkError);
    const [isFilterGlobal, setIsFilterGlobal] = useState(false);

    const validateName = useMemo(() => {
        const existNames = new Set(filterItems.map((filterItem) => filterItem.name));

        // Don't need to validate by exist name, in case when editing an existed filter
        existNames.delete(currentFilterName);

        const fieldName = intl.get("filter.fieldsNames.name");

        return (name: string) => {
            name = name.trim();

            if (name.length === 0) {
                return intl.get("validateErrors.empty", { fieldName });
            }

            if (existNames.has(name)) {
                return intl.get("validateErrors.notUnique", { fieldName });
            }

            return undefined;
        };
    }, [intl, filterItems, currentFilterName]);

    const filterNameError = validateName(nextFilterName);

    const onInputChange = useOnInputChange(setNextFilterName);

    const onSubmit = useCallback(() => {
        setSubmitted();

        if (!filterNameError) {
            setSavingState(true);
            onSave(nextFilterName, isFilterGlobal, (error) => {
                console.log(error);
                setSavingState(error);
            });
        }
    }, [onSave, filterNameError, nextFilterName, isFilterGlobal, setSubmitted, setSavingState]);

    const visibleErrors = filterNameError && submitted;

    React.useLayoutEffect(() => {
        dialog.setOptions({
            title: intl.get("filter.save.title"),
            ok: intl.get("filter.save.ok"),
            onOK: true === savingState ? undefined : onSubmit,
            onCancel: true === savingState ? undefined : onCancel,
            onClose: true === savingState ? undefined : onCancel,
        });
    }, [dialog, onCancel, onSubmit, savingState]);
    const frameworkError = (savingState as FrameworkError)?.message
        ? intl.get((savingState as FrameworkError).message)
        : undefined;
    return (
        <div style={{ padding: 24 }}>
            <TextField
                label={intl.get("filter.save.name.label")}
                placeholder={intl.get("filter.save.name.placeholder")}
                description={intl.get("filter.save.name.description")}
                value={nextFilterName}
                errorMessage={visibleErrors ? filterNameError : frameworkError}
                onChange={onInputChange}
                maxLength={FILTER_NAME_MAX_LENGTH}
            />
            Global?
            <Checkbox
                defaultChecked={isFilterGlobal}
                checked={isFilterGlobal}
                onCheckedChange={(newState) => {
                    setIsFilterGlobal(!isFilterGlobal);
                }}
            ></Checkbox>
        </div>
    );
}
