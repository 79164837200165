import { useEffect, useState } from "react";
import { cn } from "@/lib/utils";
import { Label } from "@/components/ui/label";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Button } from "@/components/ui/button";
import { CalendarIcon } from "lucide-react";
import { Calendar, CalendarProps } from "@/components/ui/calendar";
import { SelectSingleEventHandler } from "react-day-picker";

export type LCMDDatepickerProps = CalendarProps & {
    label: string;
    disabled: boolean;
    value: Date;
    placeholder: string;
    onSelectDate: SelectSingleEventHandler;
    formatDate: (date: Date) => void;
    locale?: Locale;
    required?: boolean;
};

export function LCMDDatepicker({
    label,
    disabled,
    value,
    placeholder,
    onSelectDate,
    formatDate,
    locale,
    required = true,
    fromDate,
    toDate,
}: LCMDDatepickerProps) {
    const [isCalendarOpen, setCalendarOpen] = useState(false);

    function handleSelectDate(date, day, activeModifiers, e) {
        onSelectDate(date, day, activeModifiers, e);
        setCalendarOpen(false);
    }

    useEffect(() => {
        setCalendarOpen(false);
    }, [value]);

    return (
        <div className="flex flex-col">
            <Label className="block pb-1">{label}</Label>
            <Popover open={isCalendarOpen} modal={true}>
                <PopoverTrigger asChild>
                    <Button
                        onClick={() => {
                            setCalendarOpen(true);
                        }}
                        variant="outline"
                        disabled={disabled}
                        className={cn("justify-start text-left font-normal", !value && "text-muted-foreground")}
                    >
                        <>
                            <CalendarIcon className="mr-2 h-4 w-4" />
                            {value ? formatDate(value) : <span>{placeholder}</span>}
                        </>
                    </Button>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0" onPointerDownOutside={() => setCalendarOpen(false)}>
                    <Calendar
                        mode="single"
                        selected={value}
                        onSelect={(date, day, activeModifiers, e) => {
                            handleSelectDate(date, day, activeModifiers, e);
                        }}
                        locale={locale}
                        initialFocus
                        required={required}
                        fromDate={fromDate}
                        toDate={toDate}
                    />
                </PopoverContent>
            </Popover>
        </div>
    );
}
