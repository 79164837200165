import * as React from "react";
import { mergeStyleSets } from "@fluentui/react";
import { MilestonesChartCanvasPointerEvent, MilestonesChartCanvasProps, MilestonesChartCanvasState } from "./types";
import { MilestonesList } from "./LeftColumn";
import { getMilstoneStateColor, headerHeight, lineHeight } from "./Utils";
import { Overlay } from "./Overlay";

const classNames = mergeStyleSets({
    m: {
        height: lineHeight,
        lineHeight: lineHeight + "px",
    },
});

function onRenderCanvas(
    canvas: MilestonesChartCanvasState & { chart: HTMLCanvasElement; legend: HTMLCanvasElement },
    props: MilestonesChartCanvasProps,
) {
    if (canvas.chart) {
        const ctx = canvas.chart.getContext("2d");
        ctx.save();
        //console.log("canvas: ", canvas);
        ctx.translate(0, headerHeight);

        // console.log("Canvas2: ", props)
        if (ctx) {
            const width = canvas.chart.width;
            const height = canvas.chart.height;
            //NOT LONGER NEEDED: ctx.clearRect(0, 0, width, height);
            const C = props.const;
            const scale = props.scale;

            const col0 = props.data.view.col0;
            const col1 = props.data.view.col1;
            const milestones = props.data.milestones;
            const n_milestones = milestones?.length || 0;
            const w = Math.min(C.rowPx * scale, 12);
            const dx = (C.rowPx - w) / 2;
            const dy = (lineHeight - w) / 2;
            const paddingRight = (C.milestoneCol - w) / 2;

            for (let i_milestone = 0; i_milestone < n_milestones; i_milestone++) {
                const m = milestones[i_milestone];
                if (m.x === -1 && m.b >= 0) {
                    //@todo implement if needed: Logic for recognising if process was a milestone
                    // console.warn(`Milestone: ${m.n} was a milestone but is now a process`)
                    continue;
                }
                createVerticalSeparatorLine(ctx, props, i_milestone);
                const milestoneX = (m.x - col0) * C.colPx * scale;
                const milestoneY = i_milestone * lineHeight - props.canvasTop + dy;

                if (m.b >= 0 && m.x != m.b) {
                    const baseX = (m.b - col0) * C.colPx * scale;
                    const baseY = i_milestone * lineHeight - props.canvasTop + dy + w / 2;

                    // Draw line between baseline and milestone
                    ctx.beginPath();
                    ctx.moveTo(baseX, baseY);
                    ctx.lineTo(milestoneX + w / 2, milestoneY + w / 2);
                    ctx.strokeStyle = "#565C60";
                    ctx.lineWidth = 2;
                    ctx.stroke();

                    // Draw border of baseline
                    ctx.beginPath();
                    ctx.fillStyle = "rgba(0,0,0,0.08)";
                    ctx.arc(baseX, baseY, w / 2 + 1, 0, 2 * Math.PI);
                    ctx.fill();

                    // Draw baseline
                    ctx.beginPath();
                    ctx.fillStyle = "#999EA1";
                    ctx.arc(baseX, baseY, w / 2, 0, 2 * Math.PI);
                    ctx.fill();
                }

                if (m.x >= 0) {
                    ctx.fillStyle = getMilstoneStateColor(m);
                    ctx.fillRect(milestoneX - paddingRight, milestoneY, w, w);

                    // todo: rotated version, but setTransform is wrong, ask Flo for correct setTransform values
                    // const rotationAngel = (45 * Math.PI / 180);
                    // ctx.translate(milestoneX - paddingRight + w/2, milestoneY + w/2);
                    // ctx.rotate(rotationAngel);
                    // ctx.translate(-1 * (milestoneX - paddingRight + w/2), -1 * (milestoneY + w/2));
                    // ctx.fillStyle = getMilstoneStateColor(m);
                    // ctx.fillRect(milestoneX - paddingRight, milestoneY, w, w);
                    // ctx.setTransform(1, 0, 0, 1, 0, 0);
                }
            }
        }
        ctx.restore();
    }
}

function createVerticalSeparatorLine(ctx: CanvasRenderingContext2D, props: MilestonesChartCanvasProps, row: number) {
    const dy = lineHeight * row - props.canvasTop;
    ctx.beginPath();
    ctx.moveTo(0, dy);
    ctx.lineTo(ctx.canvas.width, dy);
    ctx.strokeStyle = "#E1E4E5";
    ctx.lineWidth = 1;
    ctx.stroke();
}

function onPointerDown(
    canvas: MilestonesChartCanvasState,
    props: MilestonesChartCanvasProps,
    ev: MilestonesChartCanvasPointerEvent,
) {}

function onPointerUp(
    canvas: MilestonesChartCanvasState,
    props: MilestonesChartCanvasProps,
    ev: MilestonesChartCanvasPointerEvent,
) {}

function onPointerMove(
    canvas: MilestonesChartCanvasState,
    props: MilestonesChartCanvasProps,
    ev: MilestonesChartCanvasPointerEvent,
) {
    canvas.setOverlayState({
        hover:
            0 === ev.buttons
                ? {
                      canvasX: ev.canvasX / props.scale,
                      canvasY: ev.canvasY,
                  }
                : undefined,
    });
}

function onPointerEnter(
    canvas: MilestonesChartCanvasState,
    props: MilestonesChartCanvasProps,
    ev: MilestonesChartCanvasPointerEvent,
) {
    // console.log("onPointerEnter "+JSON.stringify({canvasX:ev.canvasX, canvasY:ev.canvasY}));
}

function onPointerLeave(
    canvas: MilestonesChartCanvasState,
    props: MilestonesChartCanvasProps,
    ev: MilestonesChartCanvasPointerEvent,
) {
    // console.log("onPointerLeave "+JSON.stringify({canvasX:ev.canvasX, canvasY:ev.canvasY}));
    canvas.setOverlayState({
        hover: undefined,
    });
}

function onPointerCancel(
    canvas: MilestonesChartCanvasState,
    props: MilestonesChartCanvasProps,
    ev: MilestonesChartCanvasPointerEvent,
) {
    // console.log("onPointerLeave "+JSON.stringify({canvasX:ev.canvasX, canvasY:ev.canvasY}));
    canvas.setOverlayState({
        hover: undefined,
    });
}

function onClick(
    canvas: MilestonesChartCanvasState,
    props: MilestonesChartCanvasProps,
    ev: MilestonesChartCanvasPointerEvent,
) {
    // console.log("onClick "+JSON.stringify({canvasX:ev.canvasX, canvasY:ev.canvasY}));
    canvas.setOverlayState({
        hover:
            0 === ev.buttons
                ? {
                      canvasX: ev.canvasX / props.scale,
                      canvasY: ev.canvasY,
                  }
                : undefined,
    });
}

function Component(this: MilestonesChartCanvasState, props: MilestonesChartCanvasProps) {
    const milestones = props.data?.milestones || [];

    return <MilestonesList milestones={milestones} startTimestamp={Math.round(props.start)} />;
    // return <>{milestones.map((m)=><div key={m.tid} className={classNames.m}>{m.n}</div>)}</>;
}

export const MilestonesChart = {
    Component,
    Overlay,
    onRenderCanvas,
    onPointerDown,
    onPointerUp,
    onPointerMove,
    onPointerEnter,
    onPointerLeave,
    onPointerCancel,
    onClick,
};
