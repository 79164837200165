import * as React from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Minus, Plus } from "lucide-react";

type CounterProps = {
    onChange: (num: number) => void;
    min?: number;
    max?: number;
    value?: number;
    label?: string;
    step?: number;
    unit?: string;
};

const validatePattern = "^(d+(.d+)?)$";
const buttonClasses = "border border-solid border-gray-800 rounded-full text-gray-800 w-8 h-8 p-0";

export function Counter({
    onChange,
    min = 0,
    max = Infinity,
    value = min,
    label = "",
    step = 1,
    unit = "",
}: CounterProps) {
    const labelValue = unit && unit != "" ? `${label} (${unit})` : label;

    const getValidValue = (value: number) => {
        if (isNaN(value)) {
            return;
        }
        if (value < min) {
            return min;
        }
        if (value > max) {
            return max;
        }
        return value;
    };

    const changeNum = (newValue: number = 0) => {
        const validValue = getValidValue(newValue);
        if (validValue !== null) {
            onChange(validValue);
        }
    };

    if (typeof value !== "number") {
        throw new Error("value param needs to be of type number");
    }

    if (typeof min !== "number") {
        throw new Error("min param needs to be of type number");
    }

    if (typeof label !== "string") {
        throw new Error("label param needs to be of type string");
    }

    if (typeof step !== "number") {
        throw new Error("step param needs to be of type number");
    }

    if (typeof unit !== "string") {
        throw new Error("unit param needs to be of type string");
    }

    return (
        <div className="">
            <p className="mb-1 text-sm text-[#323130]">{labelValue}</p>
            <div className="relative flex items-center">
                <Button variant="ghost" onClick={() => changeNum(value - step)} className={buttonClasses}>
                    <Minus strokeWidth="1" />
                </Button>
                <Input
                    type="number"
                    min={min}
                    max={max}
                    value={value.toString()}
                    style={{ boxShadow: "none" }}
                    className="mx-1 h-8 w-16 rounded-none border-gray-800 text-center [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                    pattern={validatePattern}
                    onChange={(e) => {
                        const newValue = e.target.value;
                        changeNum(+newValue);
                    }}
                />
                <Button variant="ghost" onClick={() => changeNum(value + step)} className={buttonClasses}>
                    <Plus strokeWidth="1" />
                </Button>
            </div>
        </div>
    );
}
