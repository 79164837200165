import * as React from "react";
import { useEffect, useState } from "react";
import { intl } from "../../../legacy/GlobalHelperReact";
import { User, UserMap } from "../../hooks/UserMapTypes";
import { LcmdModal2 } from "@/components/common/LcmModal2";
import { Button } from "@/components/ui/button";
import { SearchBar } from "@/components/common/SearchBar/SearchBar";
import { Switch } from "@/components/ui/switch";
import { DetailsUser } from "./DetailsUser";
import { Checkbox } from "@/components/ui/checkbox";
import { Label } from "@/components/ui/label";
import { WebAppTelemetryFactory } from "@/app/services/WebAppTelemetry.service";

export type UserWithKey = User & {
    key: string;
};

export function ReasonCodesRolesModal({
    open,
    onDismiss: handleOnDismiss,
    onOk: handleOnOk,
    initiallySelected = [],
    autoAddNewUsers = false,
    users,
}: {
    open: boolean;
    onDismiss: () => void;
    onOk: (selectedUsers) => void;
    initiallySelected?: string[];
    autoAddNewUsers?: boolean;
    users: UserMap;
}) {
    const allUsers = Object.entries(users).map((user) => Object.assign(user[1], { key: user[0] }));
    const [userItems, setUserItems] = useState<UserWithKey[]>([]);
    const [selectedUsers, setSelectedUsers] = useState<string[]>(initiallySelected);
    const [searchText, setSearchText] = useState<string>("");
    const [checkAutoAdd, setAutoAdd] = useState<boolean>(autoAddNewUsers);

    const arrAllUsersChecked = userItems.every((user) => selectedUsers.includes(user.key));

    useEffect(() => {
        setUserItems(allUsers.filter((p) => p.email.toLowerCase().indexOf(searchText.toLowerCase()) > -1));
    }, [searchText]);

    const onSave = () => {
        if (JSON.stringify(initiallySelected.sort()) !== JSON.stringify(selectedUsers.sort())) {
            WebAppTelemetryFactory.trackEvent("settings-added-user-reasoncode");
        }
        handleOnOk({ selectedUsers, autoAddNewUsers: checkAutoAdd });
        cleanUpModal();
    };

    const cleanUpModal = () => {
        setSelectedUsers([]);
        setSearchText("");
        setAutoAdd(false);
    };

    const buttons = [
        <Button key="cancel" variant="outline" onClick={handleOnDismiss}>
            {intl.get("ProcessDependenciesModal.buttons.cancel")}
        </Button>,
        <Button key="apply" onClick={onSave}>
            {intl.get("ProcessDependenciesModal.buttons.apply")}
        </Button>,
    ];

    return (
        <LcmdModal2
            size="m"
            open={open}
            onOpenChange={handleOnDismiss}
            buttons={buttons}
            header={{
                subtitle: intl.get("ReasonCodesSettings.defineRolesModal.subtitle"),
                title: intl.get("ReasonCodesSettings.defineRolesModal.title"),
            }}
        >
            <SearchBar
                value={searchText}
                onSearch={(value) => setSearchText(value || "")}
                onChange={(newValue) => setSearchText(newValue)}
                placeholder={intl.get("ReasonCodesSettings.defineRolesModal.searchUsers")}
                className="w-full"
            />
            <div className="flex justify-between pt-5">
                <div className="font-semibold">
                    {intl.get("ReasonCodesSettings.defineRolesModal.invitedUsersToggleLabel")}
                </div>
                <Switch checked={checkAutoAdd} onCheckedChange={() => setAutoAdd(!checkAutoAdd)} />
            </div>
            <div>
                <div className="flex h-10 items-center gap-3">
                    <Checkbox
                        id="allUsers"
                        className="h-5 w-5"
                        checked={arrAllUsersChecked}
                        onCheckedChange={(checked) => {
                            const newSelectedUsers = checked ? userItems.map((user) => user.key) : [];
                            setSelectedUsers(newSelectedUsers);
                        }}
                    />
                    <Label className="text-sm font-semibold leading-5" htmlFor="allUsers">
                        {intl.get("ReasonCodesSettings.defineRolesModal.allUsersLabel")}
                    </Label>
                </div>
                {userItems.map((user) => (
                    <DetailsUser
                        key={user.key}
                        user={user}
                        selectedUsers={selectedUsers}
                        setSelectedUsers={setSelectedUsers}
                    />
                ))}
            </div>
        </LcmdModal2>
    );
}
