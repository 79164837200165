import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { intl, HelperDateToUTCEpochMS, HelperDateTo24TimeStr, UNIT_TYPES } from "lcmd2framework";
import { weekNumber } from "weeknumber";
import { getCurrentLanguage } from "../date/locale";
import { UTCDatePickerV2 } from "@/legacy/GlobalHelperFluentUI";
import { useEffect, useMemo, useState } from "react";
import { convertMillisecondsToDays } from "@/utils/DateUtils";

function _onFormatDate(date: Date) {
    if (typeof date !== "object" || date === null) {
        console.warn("Date needs to be type Date. " + typeof date + " given.");
        return "";
    }
    if (typeof date.getTime !== "function" || typeof date.toLocaleDateString !== "function") {
        console.warn("date.getTime and date.toLocaleDateString need to be valid functions");
        return "";
    }
    const locale = getCurrentLanguage();
    const options: Intl.DateTimeFormatOptions = { year: "numeric", month: "2-digit", day: "2-digit" };
    return date && date.getTime() > 0 ? date.toLocaleDateString(locale, options) : "";
}

export function onFormatDateUnitTest(date: Date) {
    return _onFormatDate(date);
}

export function StartEndDateControl(props: {
    overrideStartEnd?: {
        startDate?: Date;
        endDate?: Date;
    };
    defaultStart?: Date;
    defaultEnd?: Date;
    onChange?: ((startDate: Date, endDate: Date) => void) | null;
    disabledStart?: boolean;
    disabledEnd?: boolean;
    utc: boolean;
    duration?: number;
    durationUnit?: number;
    ellapsedUnit?: number;
}) {
    const [start, setStart] = useState(props.defaultStart);
    const [end, setEnd] = useState(props.defaultEnd);
    const cwStart = start ? weekNumber(start) : -1;
    const cwEnd = end ? weekNumber(end) : -1;
    const readOnly = null === props.onChange || props.overrideStartEnd;

    useEffect(() => {
        setStart(props.defaultStart);
        setEnd(props.defaultEnd);
    }, [props.defaultStart, props.defaultEnd]);

    const onChangeStart = useMemo(
        () => (date: Date) => {
            if (props.utc) {
                date = new Date(HelperDateToUTCEpochMS(date));
            }
            setStart(date);
            if (props.onChange) {
                props.onChange(props.disabledStart ? null : date, props.disabledEnd ? null : end);
            }
        },
        [props.utc, props.onChange, setStart, end, props.disabledStart, props.disabledEnd],
    );
    const onChangeEnd = useMemo(
        () => (date: Date) => {
            if (props.utc) {
                date = new Date(HelperDateToUTCEpochMS(date));
            }
            setEnd(date);
            if (props.onChange) {
                props.onChange(props.disabledStart ? null : start, props.disabledEnd ? null : date);
            }
        },
        [props.utc, props.onChange, start, setEnd, props.disabledStart, props.disabledEnd],
    );

    return (
        <div className="mt-2.5 box-border flex flex-row justify-between gap-5 rounded bg-gray-100 px-5 py-4">
            <div className="box-border flex shrink grow flex-col">
                {readOnly ? (
                    <>
                        <Label>{intl.get("fw.startEndDateControl.start.label")}</Label>
                        <Input
                            readOnly={true}
                            value={_onFormatDate(
                                props.overrideStartEnd?.startDate ||
                                    (props.disabledStart ? props.defaultStart : start) ||
                                    null,
                            )}
                        />
                    </>
                ) : (
                    <UTCDatePickerV2
                        label={intl.get("fw.startEndDateControl.start.text")}
                        placeholder={intl.get("fw.startEndDateControl.start.placeholder")}
                        value={(props.disabledStart ? props.defaultStart : start) || null}
                        onSelectDate={onChangeStart}
                        disabled={props.disabledStart}
                    />
                )}
                <div className="h-5 text-sm font-semibold">
                    {!props.disabledStart && cwStart >= 0
                        ? intl.get("fw.startEndDateControl.cw", { cw: cwStart.toString(10) })
                        : null}
                </div>
            </div>
            {props.disabledEnd || null !== props.defaultEnd ? (
                <div className="box-border flex shrink grow flex-col">
                    {readOnly ? (
                        <>
                            <Label>{intl.get("fw.startEndDateControl.end.label")}</Label>
                            <Input
                                readOnly={true}
                                value={_onFormatDate(
                                    props.overrideStartEnd?.endDate ||
                                        (props.disabledEnd ? props.defaultEnd : end) ||
                                        null,
                                )}
                            />
                        </>
                    ) : (
                        <UTCDatePickerV2
                            label={intl.get("fw.startEndDateControl.end.text")}
                            placeholder={intl.get("fw.startEndDateControl.end.placeholder")}
                            value={(props.disabledEnd ? props.defaultEnd : end) || null}
                            onSelectDate={onChangeEnd}
                            disabled={props.disabledEnd}
                        />
                    )}
                    <div className="h-5 text-sm font-semibold">
                        {!props.disabledEnd && cwEnd >= 0
                            ? intl.get("fw.startEndDateControl.cw", { cw: cwEnd.toString(10) })
                            : null}
                    </div>
                </div>
            ) : props.defaultStart && !props.defaultEnd && null === end ? (
                <div className="box-border flex shrink grow flex-col">
                    <Label>{intl.get("fw.startEndDateControl.end.time")}</Label>
                    <Input readOnly={true} type="time" value={HelperDateTo24TimeStr(props.defaultStart.getTime())} />
                </div>
            ) : null}
            {undefined !== props.duration && undefined !== props.durationUnit ? (
                <div className="box-border flex shrink grow flex-col">
                    <Label>{intl.get("fw.startEndDateControl.duration.label")}</Label>
                    <Input
                        readOnly={true}
                        type="number"
                        value={props.duration}
                        suffix={UNIT_TYPES[props.durationUnit]}
                    />
                    <div className="h-5 text-sm font-semibold">
                        {!props.disabledStart && start && !props.disabledEnd && end && 10 === props.ellapsedUnit
                            ? [
                                  (
                                      convertMillisecondsToDays(end.getTime()) -
                                      convertMillisecondsToDays(start.getTime())
                                  ).toString(10),
                                  UNIT_TYPES[props.ellapsedUnit],
                              ].join(" ")
                            : null}
                    </div>
                </div>
            ) : null}
        </div>
    );
}
