import { useState, useMemo, useEffect, useLayoutEffect } from "react";
import { Dialog, DialogScreen, intl } from "lcmd2framework";
import { Checkbox, Dropdown, IDropdownOption, mergeStyleSets, TextField } from "@fluentui/react";
import { useLCMD } from "@/app/LCMContext";
import { DialogRouteParams } from "@/app/LCMDContextTypes";
import { WebAppTelemetryFactory } from "@/app/services/WebAppTelemetry.service";

const classNames = mergeStyleSets({
    main: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        flexShrink: 1,
        paddingLeft: 20,
        paddingRight: 20,
    },
});

export type TaktzoneCloneMainProps = {
    selectedIndices: any;
    view: string;
};

type TaktzoneCloneMainScreenProperties = DialogRouteParams<TaktzoneCloneMainProps> & {
    dialog;
};

function MainScreen({
    dialog,
    route: {
        params: { selectedIndices, view },
    },
}: TaktzoneCloneMainScreenProperties) {
    const sel = selectedIndices;
    const LCMD = useLCMD();
    const [count, setCount] = useState("1");
    const libs = useMemo(() => {
        const ret = LCMD.getActiveLibrariesSync();
        if (Array.isArray(ret) && ret.length > 0) {
            return ret.map((item) => ({
                key: item.id,
                text: item.name,
            }));
        } else {
            return null;
        }
    }, [LCMD]);
    const [checked, setChecked] = useState(false);
    const [gpa, setGPA] = useState(-1);
    useLayoutEffect(() => {
        const onCancel = () => {
            LCMD.showDialog("dialog.taktzone.clone", false);
        };
        dialog.setOptions({
            title: intl.get(`TaktzoneCloneDialog.title.${view}`),
            onOK: undefined,
            onCancel,
            onClose: onCancel,
        });
    }, [LCMD]);
    useEffect(() => {
        const _count = Number.parseInt(count, 10);
        let onOK = undefined;
        if (
            "number" === typeof _count &&
            (_count > 0 || (_count > 0 && gpa >= 0)) &&
            Array.isArray(selectedIndices) &&
            1 === selectedIndices.length
        ) {
            onOK = () => {
                WebAppTelemetryFactory.trackEvent(`areas-change-${view}`);
                const newCountValue = "duplicate" === view ? _count + 1 : null;
                LCMD.duplicateRow(selectedIndices[0], { count: newCountValue, gpa: gpa >= 0 ? gpa : undefined });
                LCMD.showDialog("dialog.taktzone.clone", false);
            };
        }
        dialog.setOptions({ onOK });
    }, [LCMD, count, gpa]);
    return "duplicate" === view ? (
        <div className={classNames.main}>
            <TextField
                label={intl.get("TaktzoneCloneDialog.duplicate")}
                value={count}
                type="number"
                onChange={(ev, value) => {
                    setCount(value);
                }}
            />
            <Checkbox
                className="py-2"
                label={intl.get("TaktzoneCloneDialog.attachGpa")}
                checked={checked}
                onChange={(ev, checked) => {
                    setChecked(checked || false);
                }}
            />
            {checked ? (
                <Dropdown
                    disabled={null === libs}
                    options={libs || []}
                    onChange={(ev, item: IDropdownOption) => {
                        setGPA(item.key as number);
                    }}
                    errorMessage={null === libs ? intl.get("TaktzoneCloneDialog.errorMessage") : undefined}
                />
            ) : null}
        </div>
    ) : "attachGPA" === view ? (
        <div className={classNames.main}>
            <Dropdown
                disabled={null === libs}
                options={libs || []}
                onChange={(ev, item: IDropdownOption) => {
                    setGPA(item.key as number);
                }}
                errorMessage={null === libs ? intl.get("TaktzoneCloneDialog.errorMessage") : undefined}
            />
        </div>
    ) : null;
}

export function TaktzoneCloneDialog(props: {}) {
    return (
        <Dialog
            width={320}
            heigth={400}
            initialScreen={(dialog) => {
                dialog.pushScreen("main", props);
            }}
            isOpen={true}
        >
            <DialogScreen name="main" component={MainScreen} />
        </Dialog>
    );
}
