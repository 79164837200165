import { SidebarMenu } from "./interface";
import { Icons } from "../RegisteredIcons";
import { TasksView } from "./TasksView";
import { StabilityView } from "./StabilityView";
import * as React from "react";
import { DetailsView } from "./DetailsView";
import { ActionsView } from "./ActionsView";
import { HistoryView } from "./HistoryView";
import { TradesView } from "./TradesView";
import { AreaLibrary } from "./AreaLibrary";
import { WhiteboardDetailsView } from "./WhiteboardDetailsView";
import { CanvasNavigation } from "@/components/hooks/useCanvasNavigation.hook";
import { WebAppTelemetryFactory } from "@/app/services/WebAppTelemetry.service";
import { DigitalPlanningBoardViewKey } from "lcmd2framework";
import { ChecklistView } from "./ChecklistView";
import { CommentsView } from "./CommentsView/CommentsView";
import posthog from "posthog-js";

const getCommonMenus = (intl, { zoomIn, zoomOut }: CanvasNavigation): SidebarMenu[] => {
    const resize_icons: SidebarMenu = {
        key: "resize_icons",
        name: "Resize Icons",
        menuItems: [
            {
                key: "com.lcmdigital.sidebar.plus",
                style: {
                    root: {
                        padding: "20px",
                        paddingTop: "25px",
                    },
                },
                iconName: "ZoomIn",
                name: intl.get("Sidebar.Tooltips.Plus"),
                title: "Plus",
                detailsContent: null,
                onClick: () => {
                    zoomIn();
                },
            },
            {
                key: "com.lcmdigital.sidebar.minus",
                iconName: "ZoomOut",
                style: {
                    root: {
                        padding: "20px",
                        paddingTop: "25px",
                    },
                },
                name: intl.get("Sidebar.Tooltips.Subtract"),
                title: "Subtract",
                detailsContent: null,
                onClick: () => {
                    zoomOut();
                },
            },
        ],
    };

    return [resize_icons];
};

export const getNoProcessSelectedMenus = (
    intl,
    canvasNavigation: CanvasNavigation,
    view: DigitalPlanningBoardViewKey,
): SidebarMenu[] => {
    const whiteboardMenu1: SidebarMenu = {
        key: "com.lcmdigital.whiteboard.menu1",
        name: "menu1",
        menuItems: [
            ...getSearchMenuItemIfWhiteboard(intl, view),
            {
                key: "com.lcmdigital.menu.select",
                iconName: Icons.Lcmd_Fluent_Border,
                name: intl.get("Sidebar.Tooltips.Select"),
                title: "Select",
                disabled: false,
            },
        ],
    };

    const whiteboardMenu2: SidebarMenu = {
        key: "menu2",
        name: "menu2",
        menuItems: [
            {
                key: "com.lcmdigital.sidebar.trades",
                iconName: Icons.Lcmd_Trade,
                name: intl.get("Sidebar.Tooltips.Trade"),
                title: "Trades",
                detailsContent: <TradesView />,
            },
            {
                key: "com.lcmdigital.sidebar.areas",
                iconName: Icons.Lcmd_Areas,
                name: intl.get("Sidebar.Tooltips.Library"),
                title: "Library",
                detailsContent: <AreaLibrary view={view} />,
            },
        ],
    };

    return [whiteboardMenu1, whiteboardMenu2].concat(getCommonMenus(intl, canvasNavigation));
};

export const getProcessSelectedMenus = (
    intl,
    canvasNavigation: CanvasNavigation,
    view: DigitalPlanningBoardViewKey,
) => {
    //todo: keys ändern
    const menu1: SidebarMenu = {
        key: "menu1",
        name: "menu1",
        menuItems: [
            ...getSearchMenuItemIfWhiteboard(intl, view),
            {
                key: "com.lcmdigital.menu.select",
                iconName: Icons.Lcmd_Fluent_Border,
                name: intl.get("Sidebar.Tooltips.Select"),
                title: "Select",
                disabled: false,
            },
        ],
    };
    const menu2: SidebarMenu = {
        key: "menu2",
        name: "menu2",
        menuItems: [
            {
                key: "com.lcmd.processes.link",
                iconName: Icons.Lcmd_Link,
                name: intl.get("Sidebar.Tooltips.Link"),
                title: "Link",
                disabled: false,
            },
        ],
    };
    const menu3: SidebarMenu = {
        key: "menu3",
        name: "menu3",
        menuItems: [
            {
                key: "com.lcmdigital.sidebar.details",
                iconName: Icons.Lcmd_Document,
                name: intl.get("Sidebar.Tooltips.Detail"),
                title: "Details",
                detailsContent: <DetailsView webtrackingPrefix="sidebar-details" />,
            },
            {
                key: "com.lcmdigital.sidebar.tasks",
                iconName: Icons.Lcmd_Checkmark_Circle,
                name: intl.get("Sidebar.Tooltips.Task"),
                title: "Tasks",
                detailsContent: <TasksView wbMode={false} />,
            },
            {
                key: "com.lcmdigital.sidebar.comments",
                iconName: Icons.Lcmd_Comment,
                name: intl.get("Sidebar.Tooltips.Comments"),
                title: "Comments",
                onClick: () => {
                    WebAppTelemetryFactory.trackEvent("sidebar-comments-opened");
                },
                detailsContent: <CommentsView />,
            },
            {
                key: "com.lcmdigital.sidebar.stabilitys",
                iconName: Icons.Lcmd_Bullet_List,
                name: intl.get("Sidebar.Tooltips.Stability"),
                title: "Stability",
                onClick: () => {
                    WebAppTelemetryFactory.trackEvent("sidebar-stability-criteria-opened");
                },
                detailsContent: <StabilityView />,
            },
            {
                key: "com.lcmdigital.sidebar.actions",
                iconName: Icons.Lcmd_Warning,
                name: intl.get("Sidebar.Tooltips.Action"),
                title: "Actions",
                onClick: () => {
                    WebAppTelemetryFactory.trackEvent("sidebar-action-item-opened");
                },
                detailsContent: <ActionsView />,
            },
            {
                key: "com.lcmdigital.sidebar.history",
                iconName: Icons.Lcmd_History,
                name: intl.get("Sidebar.Tooltips.History"),
                title: "History",
                detailsContent: <HistoryView />,
            },
            {
                key: "com.lcmdigital.sidebar.wbdetails",
                iconName: Icons.Lcmd_Whitebaord_Details,
                name: intl.get("Sidebar.Tooltips.Planning"),
                title: "Planung",
                detailsContent: <WhiteboardDetailsView />,
            },
        ],
    };

    const addToLibrayMenu: SidebarMenu = {
        key: "addToLibraryMenu",
        name: "addToLibraryMenu",
        menuItems: [
            {
                key: "com.lcmdigital.sidebar.addToLibrary",
                iconName: Icons.Lcmd_Add_Library,
                name: intl.get("Sidebar.Tooltips.AddToLibrary"),
                title: "Add To Library",
            },
        ],
    };

    const menuWorkshop: SidebarMenu = {
        key: "menuWorkshop",
        name: "menuWorkshop",
        menuItems: [
            {
                key: "com.lcmdigital.sidebar.wbdetails",
                iconName: Icons.Lcmd_Whitebaord_Details,
                name: intl.get("Sidebar.Tooltips.Planning"),
                title: "Details",
                detailsContent: <WhiteboardDetailsView />,
            },
            {
                key: "com.lcmdigital.sidebar.tasks",
                iconName: Icons.Lcmd_Checkmark_Circle,
                name: intl.get("Sidebar.Tooltips.Task"),
                title: "Tasks",
                detailsContent: <TasksView wbMode={true} />,
            },
        ],
    };

    // eslint-disable-next-line no-constant-condition
    if (false && posthog.getFeatureFlag("ab-checklist") === "test") {
        // Do something differently for this user
        const commentMenuItem = {
            key: "com.lcmdigital.sidebar.checklists",
            iconName: Icons.Lcmd_Circle_Filled,
            name: intl.get("checklistView.title"),
            title: "Checklists",
            detailsContent: <ChecklistView />,
        };

        menu3.menuItems.splice(3, 0, commentMenuItem);
    }

    if ("workshop" === view) {
        return [menu1, menu2, menuWorkshop].concat(getCommonMenus(intl, canvasNavigation));
    } else {
        return [menu1, menu2, menu3, addToLibrayMenu].concat(getCommonMenus(intl, canvasNavigation));
    }
};

function getSearchMenuItemIfWhiteboard(intl, view: DigitalPlanningBoardViewKey) {
    const searchMenuItem =
        view !== "workshop"
            ? [
                  {
                      key: "com.lcmdigital.menu.search",
                      iconName: "Search",
                      name: intl.get("Sidebar.Tooltips.Search"),
                      title: "Search",
                      disabled: false,
                  },
              ]
            : [];
    return searchMenuItem;
}
