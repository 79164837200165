import * as React from "react";
import { useEffect, useState } from "react";
import { getUrlFromBlob } from "@/utils/GeneralUtils";

export const IFrameViewer = ({ path, onBlobChange }: { path: string; onBlobChange: (blob) => void }) => {
    const [blobSrc, setBlobSrc] = useState("");

    function handleFrameOnLoad(e) {
        const new_style_element = document.createElement("style");
        new_style_element.textContent = "body{display:flex;justify-content:center}img{height:100%;object-fit:cover}";
        e.target.contentDocument.head.appendChild(new_style_element);
    }

    useEffect(() => {
        if (path) {
            getUrlFromBlob(path).then((res) => {
                setBlobSrc(res as string);
                onBlobChange(res as string);
            });
        }
        return () => {
            URL.revokeObjectURL(blobSrc);
            onBlobChange("");
        };
    }, [path]);

    return (
        <iframe
            onLoad={(e) => handleFrameOnLoad(e)}
            id="iframeAttachment"
            className="h-[1000px] max-h-[500px] w-full"
            src={blobSrc}
        />
    );
};
