import { useEffect, useState } from "react";
import { CheckedState } from "@radix-ui/react-checkbox";
import { LcmdModal2, ModalSizeTypes } from "@/components/common/LcmModal2";
import { Button } from "@/components/ui/button";
import { intl } from "@/legacy/GlobalHelperReact";
import { Input } from "@/components/ui/input";
import { Checkbox } from "@/components/ui/checkbox";
import { Label } from "@/components/ui/label";
import * as React from "react";

//TODO: Refactor for standard component
export function TradeModal({
    isDialogOpen,
    onOpenChange,
    title,
    allItems = [],
    onItemSelectionChanged,
    selectedItems = [],
}: any) {
    const [searchTerm, setSearchTerm] = useState("");
    const [selected, setSelected] = useState(selectedItems);

    const filteredItems = allItems.filter((item) => item.name.toLowerCase().includes(searchTerm.toLowerCase()));

    const isAllSelected: CheckedState =
        selected.length == filteredItems.length ? true : selected.length >= 1 ? "indeterminate" : false;

    useEffect(() => {
        if (!selectedItems) {
            return;
        }
        setSelected(selectedItems);
    }, [selectedItems]);

    function handleOkClick() {
        onItemSelectionChanged(selected);
        onOpenChange();
    }

    function handleCancelClick() {
        setSelected([...selectedItems]);
        onOpenChange();
    }

    return (
        <LcmdModal2
            open={isDialogOpen}
            onOpenChange={() => {
                onOpenChange();
            }}
            header={{ title: title }}
            size={ModalSizeTypes.m}
            buttons={[
                <Button key="cancel" variant="outline" onClick={handleCancelClick}>
                    {intl.get("fw.cancel")}
                </Button>,
                <Button key="ok" onClick={handleOkClick}>
                    {intl.get("fw.ok")}
                </Button>,
            ]}
        >
            <div className="space-y-4">
                <Input
                    placeholder={intl.get("filter.main.trades.add.search")}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                <div className="h-[300px] space-y-2 overflow-y-auto">
                    <Checkbox
                        checked={isAllSelected}
                        onClick={() => {
                            const newCheckedState: CheckedState = isAllSelected == "indeterminate" || !isAllSelected;
                            setSelected(newCheckedState ? filteredItems : []);
                        }}
                    />
                    {filteredItems.map((item, index) => (
                        <div key={index} className="flex items-center space-x-2">
                            <Checkbox
                                id={`${title}-${index}`}
                                key={`${title}-${index}`}
                                checked={!!selected.find((select) => select.id === item.id)}
                                onCheckedChange={() => {
                                    const newItems = selected.includes(item)
                                        ? selected.filter((i) => i !== item)
                                        : [...selected, item];
                                    setSelected(newItems);
                                }}
                            />
                            <Label htmlFor={`${title}-${index}`}>{item.name}</Label>
                        </div>
                    ))}
                </div>
            </div>
        </LcmdModal2>
    );
}
