export function getDateAndTime(date: number | null) {
    if (date) {
        return new Intl.DateTimeFormat(undefined, {
            day: "numeric",
            month: "numeric",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
        }).format(new Date(date));
    }
    return date;
}

export function getLocaleDateAndTime(date: number | null, locale: string) {
    if (date) {
        return new Intl.DateTimeFormat(locale, {
            day: "numeric",
            month: "numeric",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
        }).format(new Date(date));
    }
    return date;
}
