import { Checkbox, PrimaryButton, ScrollablePane, SearchBox, Stack, StackItem } from "@fluentui/react";
import { useNavigate } from "react-router-dom";
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { intl } from "@/legacy/GlobalHelperReact";
import { HistoryActionName } from "../../../../app/interface";
import { CHANGES_PANEL_ROUTES } from "../../Shared/routers.enum";
import { ChangesHeader } from "../../Shared/ChangesHeader";
import { ChangesPanelActionTypes, CPContext } from "../../Shared/CPContext";
import { CounterBubble, StyleTheme } from "../../../common/counter/CounterBubble";
import { useLCMD } from "../../../../app/LCMContext";
import { DeleteFilterButton } from "../../../common/deleteFilter/DeleteFilterButton";

type HistoryFilterItem = {
    area: string;
    filters: HistoryFilterItemFilter[];
};

type HistoryFilterItemFilter = {
    key: string;
    transaction: HistoryActionName[];
};

export function HistoryFilter() {
    //TODO: implement commented out cases
    const LCMD = useLCMD();
    const allFilterItems: HistoryFilterItem[] = [
        {
            area: intl.get("HistoryView.HistoryFilter.Processes"),
            filters: [
                {
                    key: "HistoryView.HistoryFilter.ProcessAdded",
                    transaction: [HistoryActionName.PROCESS_CREATED, HistoryActionName.MULTI_PROCESS_CREATED],
                },
                {
                    key: "HistoryView.HistoryFilter.ProcessDeleted",
                    transaction: [HistoryActionName.PROCESS_DELETED, HistoryActionName.MULTI_DELETED],
                },
                {
                    key: "HistoryView.HistoryFilter.ProcessNameChanged",
                    transaction: [HistoryActionName.PROCESS_NAME_EDIT, HistoryActionName.MULTI_NAME_EDIT],
                },
                {
                    key: "HistoryView.HistoryFilter.StartDateChanged",
                    transaction: [
                        HistoryActionName.PROCESS_START_AND_DAYS_EDIT,
                        HistoryActionName.PROCESS_STARTDATE_EDIT,
                        HistoryActionName.MULTI_START_EDIT,
                        HistoryActionName.PROCESS_DAYS_EDIT,
                    ],
                },
                {
                    key: "HistoryView.HistoryFilter.ProcessDurationChanged",
                    transaction: [HistoryActionName.PROCESS_DAYS_EDIT],
                },
                {
                    key: "HistoryView.HistoryFilter.ProcessTradeChanged",
                    transaction: [HistoryActionName.PROCESS_TRADE_EDIT, HistoryActionName.MULTI_TRADE_EDIT],
                },
                {
                    key: "HistoryView.HistoryFilter.WorkforceChanged",
                    transaction: [
                        HistoryActionName.PROCESS_WORKFORCE_EDIT,
                        HistoryActionName.MULTI_PROCESS_WORKFORCE_EDIT,
                    ],
                },
                /*
                {
                    text: intl.get('HistoryView.HistoryFilter.ProcessStatusChanged'),
                    transaction: HistoryActionName.PROCESS_STATUS_CHANGED,
                },
                 */
            ],
        },
        {
            area: intl.get("HistoryView.HistoryFilter.Tasks"),
            filters: [
                {
                    key: "HistoryView.HistoryFilter.TaskDeleted",
                    transaction: [HistoryActionName.CARD_DELETED],
                },
                /* Not fully implemented yet
                TODO: implement history entry for setting all tasks to done
                {
                    text: intl.get('HistoryView.HistoryFilter.TaskStatusChanged'),
                    transaction: HistoryActionName.CARD_STATUS_EDIT,
                },
                */
            ],
        },
        {
            area: intl.get("HistoryView.HistoryFilter.Areas"),
            filters: [
                {
                    key: "HistoryView.HistoryFilter.AreaCreated",
                    transaction: [HistoryActionName.AREA_CREATED],
                },
                {
                    key: "HistoryView.HistoryFilter.AreaDeleted",
                    transaction: [HistoryActionName.AREA_DELETED, HistoryActionName.AREA_MULTI_DELETED],
                },
                {
                    key: "HistoryView.HistoryFilter.AreaMoved",
                    transaction: [HistoryActionName.AREA_MOVED],
                },
                {
                    key: "HistoryView.HistoryFilter.AreaNameChanged",
                    transaction: [HistoryActionName.AREA_RENAMED],
                },
            ],
        },
        {
            area: intl.get("HistoryView.HistoryFilter.StabilityCriteria"),
            filters: [
                {
                    key: "HistoryView.HistoryFilter.StabilityStatusChanged",
                    transaction: [HistoryActionName.STABILITY_STATUS_EDIT],
                },
            ],
        },
        {
            area: intl.get("HistoryView.HistoryFilter.ActionItems"),
            filters: [
                {
                    key: "HistoryView.HistoryFilter.ActionItemCreated",
                    transaction: [HistoryActionName.ACTION_ITEM_CREATED],
                },
                {
                    key: "HistoryView.HistoryFilter.ActionItemDeleted",
                    transaction: [HistoryActionName.ACTION_ITEM_DELETED],
                },
                {
                    key: "HistoryView.HistoryFilter.ActionItemStatusChanged",
                    transaction: [HistoryActionName.ACTION_ITEM_EDIT],
                },
            ],
        },
        /*
        {
            area: intl.get('HistoryView.HistoryFilter.Dependencies'),
            filters: [
                {
                    text: intl.get('HistoryView.HistoryFilter.ConflictResolved'),
                    transaction: HistoryActionName.CONFLICT_RESOLVED,
                },
            ],
        },
        */
        {
            area: intl.get("HistoryView.HistoryFilter.Comment"),
            filters: [
                {
                    key: "HistoryView.HistoryFilter.ProcessCommentAdded",
                    transaction: [HistoryActionName.PROCESS_COMMENT_CREATED],
                },
                {
                    key: "HistoryView.HistoryFilter.ProcessCommentDeleted",
                    transaction: [HistoryActionName.PROCESS_COMMENT_DELETED],
                },
                {
                    key: "HistoryView.HistoryFilter.ProcessCommentChanged",
                    transaction: [HistoryActionName.PROCESS_COMMENT_EDIT],
                },
            ],
        },
    ];
    const navigate = useNavigate();
    const { state, dispatch } = useContext(CPContext);
    const { onDismiss, historyFilter } = state;
    const [filterSet, setFilterSet] = useState(new Map<string, HistoryActionName[]>());
    const [filteredFilterItems, setFilteredFilterItems] = useState(allFilterItems);
    const [projectId, setProjectId] = useState<string>("");

    useEffect(() => {
        LCMD.getProjectDetails((error, data) => {
            if (error) {
                console.warn(error.message);
                setFilterSet(new Map<string, HistoryActionName[]>());
                setProjectId("");
                return;
            }
            setProjectId(data.pid.toString());
            const currentFilterSet =
                historyFilter.size > 0
                    ? historyFilter
                    : JSON.parse(sessionStorage.getItem(`lcmd.historyFilters_${data.pid.toString()}`)) || [];
            setFilterSet(new Map(currentFilterSet));
        });
    }, [state]);

    function onSearchChange(ev, value) {
        const foundItems = [];
        allFilterItems.forEach((area) => {
            const foundFilter = area.filters.filter((filter) => filter.key.toLowerCase().includes(value.toLowerCase()));
            if (foundFilter.length == 0) {
                return;
            }
            foundItems.push({ area: area.area, filters: foundFilter });
        });

        setFilteredFilterItems(foundItems);
    }

    function handleCheckboxChange(key, transaction, checked) {
        const map = new Map(filterSet);
        if (checked) {
            map.set(key, transaction);
        } else {
            map.delete(key);
        }

        setFilterSet(new Map(map));
    }

    function applyFilter() {
        dispatch({ type: ChangesPanelActionTypes.SET_HISTORY_FILTER, filter: filterSet });
        if (typeof projectId === "string" && projectId !== "") {
            sessionStorage.setItem(`lcmd.historyFilters_${projectId}`, JSON.stringify(Array.from(filterSet)));
        }
        navigate(CHANGES_PANEL_ROUTES.HISTORY_VIEW);
    }

    const header = (
        <Stack horizontal>
            <StackItem styles={{ root: { fontSize: "18px", fontWeight: 600 } }}>
                {intl.get("changesPanel.Changes")}
            </StackItem>
            <StackItem
                styles={{
                    root: {
                        fontSize: "18px",
                        height: "16px",
                        padding: "0 10px",
                        color: "rgb(225, 228, 229)",
                        fontWeight: 300,
                    },
                }}
            >
                /
            </StackItem>
            <StackItem>
                <Stack horizontal verticalAlign={"center"} tokens={{ childrenGap: 8 }}>
                    <StackItem styles={{ root: { fontSize: "18px", fontWeight: 600 } }}>
                        {intl.get("changesPanel.Filter")}
                    </StackItem>
                    {filterSet.size > 0 && (
                        <StackItem>
                            <CounterBubble value={filterSet.size} styleTheme={StyleTheme.BLUE} />
                        </StackItem>
                    )}
                </Stack>
            </StackItem>
        </Stack>
    );

    return (
        <Stack padding={"8px 16px"}>
            <ChangesHeader
                title={header}
                onBackButtonChange={() => {
                    navigate(-1);
                }}
                onCloseButtonChange={onDismiss}
            />
            <StackItem>
                <SearchBox
                    onChange={onSearchChange}
                    placeholder={intl.get("HistoryView.HistoryFilter.SearchBoxPlaceholderText")}
                />
            </StackItem>
            <StackItem>
                <ScrollablePane styles={{ root: { position: "relative", height: "calc(100vh - 170px)" } }}>
                    <Stack>
                        {filteredFilterItems.map((area, index) => {
                            return (
                                <Stack
                                    styles={{
                                        root: {
                                            paddingTop: "16px",
                                            paddingBottom: "16px",
                                            borderTop: index == 0 ? "" : "1px solid #E1E4E5",
                                        },
                                    }}
                                    tokens={{ childrenGap: 8 }}
                                    key={area.area}
                                >
                                    <StackItem styles={{ root: { fontWeight: 600 } }}>{area.area}</StackItem>
                                    <StackItem styles={{ root: { borderBottomColor: "#E1E4E5" } }}>
                                        <Stack tokens={{ childrenGap: 12 }}>
                                            {area.filters.map((filter) => {
                                                return (
                                                    <StackItem key={filter.key}>
                                                        <Checkbox
                                                            checked={filterSet.get(filter.key) !== undefined}
                                                            label={intl.get(filter.key)}
                                                            onChange={(ev, checked) =>
                                                                handleCheckboxChange(
                                                                    filter.key,
                                                                    filter.transaction,
                                                                    checked,
                                                                )
                                                            }
                                                        />
                                                    </StackItem>
                                                );
                                            })}
                                        </Stack>
                                    </StackItem>
                                </Stack>
                            );
                        })}
                    </Stack>
                </ScrollablePane>
            </StackItem>
            <StackItem styles={{ root: { height: "26px", paddingTop: "18px", borderTop: "1px solid #e6e6e6" } }}>
                <Stack horizontal horizontalAlign={"space-between"} verticalAlign={"center"}>
                    <StackItem>
                        <DeleteFilterButton
                            onClick={() => {
                                setFilterSet(new Map<string, HistoryActionName[]>());
                            }}
                        ></DeleteFilterButton>
                    </StackItem>
                    <StackItem>
                        <PrimaryButton onClick={applyFilter}>
                            {intl.get("HistoryView.HistoryFilter.Buttons.Save")}
                        </PrimaryButton>
                    </StackItem>
                </Stack>
            </StackItem>
        </Stack>
    );
}
