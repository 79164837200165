import { create } from "zustand";

export type ActiveBaselineData = {
    id: number;
    name: string;
    date: number;
};

export type BaselineStore = BaselineState & BaselineActions;

type BaselineState = {
    activeBaseline: ActiveBaselineData | null;
};

type BaselineActions = {
    setActiveBaseline: (baseline: ActiveBaselineData) => void;
    updateActiveBaselineName: (name: string) => void;
    reset: () => void;
};

const initalState: BaselineState = {
    activeBaseline: null,
};

export const useBaselineStore = create<BaselineStore>((set) => ({
    ...initalState,
    setActiveBaseline: (baseline) => set({ activeBaseline: baseline }),
    updateActiveBaselineName: (name) => set((state) => ({ activeBaseline: { ...state.activeBaseline, name } })),
    reset: () => set(initalState),
}));

export function useActiveBaseline() {
    return useBaselineStore((state) => state.activeBaseline);
}

export function useSetActiveBaseline() {
    return useBaselineStore((state) => state.setActiveBaseline);
}

export function useUpdateActiveBaselineName() {
    return useBaselineStore((state) => state.updateActiveBaselineName);
}
