import * as React from "react";
import { Check, Dot } from "lucide-react";
import { intl } from "@/legacy/GlobalHelperReact";

/**
 * Active Weekdays
 *
 * @description Starts like Javascript Weekdays (0 - Sunday, 1 - Monday ...)
 */
type ActiveWeekdays = [boolean, boolean, boolean, boolean, boolean, boolean, boolean];

type ActiveWeekdaySelectorProps = {
    activeWeekdays: ActiveWeekdays;
    onActiveWeekdaysChange: (newActiveWeekdays: ActiveWeekdays) => void;
    disabled?: boolean;
};

export function ActiveWeekdaySelector({
    activeWeekdays,
    onActiveWeekdaysChange,
    disabled,
}: ActiveWeekdaySelectorProps) {
    const weekdayIndexes: [number, number, number, number, number, number, number] = [7, 1, 2, 3, 4, 5, 6];
    const weekdayNames: string[] = intl.get("CalendarExceptionMainScreen.days") as string[];

    if (typeof activeWeekdays !== "object" || activeWeekdays === null) {
        throw new Error("activeWeekdays param needs to be type of ActiveWeekdays");
    }

    if (activeWeekdays.length !== 7) {
        throw new Error("activeWeekdays param needs to have 7 elements");
    }

    if (
        !activeWeekdays.every((weekday) => {
            return typeof weekday === "boolean";
        })
    ) {
        throw new Error("activeWeekdays param elements have to be type of boolean");
    }

    const currentWeekdayStatusChanged = (index: number) => {
        if (disabled) {
            return;
        }
        const newActiveWeekdays: ActiveWeekdays = [...activeWeekdays];
        newActiveWeekdays[index] = !newActiveWeekdays[index];
        onActiveWeekdaysChange(newActiveWeekdays);
    };

    return (
        <div
            className={
                "flex w-fit rounded border border-solid border-gray-200 " + (disabled ? "opacity-40" : "opacity-100")
            }
        >
            {activeWeekdays.map((currentWeekdayStatus, index) => {
                return (
                    <div
                        key={`active-weekday-${weekdayIndexes[index]}`}
                        style={{ order: weekdayIndexes[index] }}
                        className={currentWeekdayStatus ? "bg-white text-gray-600" : "bg-gray-100 text-slate-300"}
                    >
                        <label
                            key={"label-key-" + weekdayNames[index]}
                            className="mx-0 my-2.5 box-content flex w-5 flex-col items-center border-r border-solid border-r-gray-200 px-4 py-0"
                            style={7 === weekdayIndexes[index] ? { border: "none" } : {}}
                        >
                            {weekdayNames[index]}
                            <input
                                type="checkbox"
                                defaultChecked={currentWeekdayStatus}
                                style={{ display: "none" }}
                                onChange={() => currentWeekdayStatusChanged(index)}
                            />
                            {currentWeekdayStatus ? <Check size="16" className="mt-2" /> : <Dot size="30" />}
                        </label>
                    </div>
                );
            })}
        </div>
    );
}

export type { ActiveWeekdaySelectorProps, ActiveWeekdays };
