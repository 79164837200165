import * as React from "react";
import { LcmdModal } from "../../../common/LcmModal";
import { ComboBox, DefaultButton, IComboBoxOption, PrimaryButton, Stack, TextField } from "@fluentui/react";
import { Counter } from "../../../common/counter";
import { useState } from "react";
import { intl } from "@/legacy/GlobalHelperReact";
import { LCMDContextDependencyType, LCMDContextTaskDetailsResultDependencyDetails } from "@/app/LCMDContextTypes";
import { WebAppTelemetryFactory } from "@/app/services/WebAppTelemetry.service";
import { DependencyType } from "@/components/Sidebar/DetailsView";

export const ProcessDependenciesModal = ({
    show,
    dependencyType,
    processDependency,
    onCancel,
    onClose,
    webtrackingPrefix,
}: {
    show: boolean;
    dependencyType: DependencyType;
    processDependency: LCMDContextTaskDetailsResultDependencyDetails;
    onCancel: () => void;
    onClose: (processDependency: LCMDContextTaskDetailsResultDependencyDetails) => void;
    webtrackingPrefix?: string;
}) => {
    const dependencyOptions: (IComboBoxOption & { track: string })[] = [
        { key: 2, text: intl.get("ProcessDependenciesModal.dependenciesOptions.finishToStart"), track: "FS" },
        { key: 1, text: intl.get("ProcessDependenciesModal.dependenciesOptions.finishToFinish"), track: "FF" },
        { key: 4, text: intl.get("ProcessDependenciesModal.dependenciesOptions.startToStart"), track: "SS" },
        { key: 3, text: intl.get("ProcessDependenciesModal.dependenciesOptions.startToFinish"), track: "SF" },
    ];

    const [type, setType] = useState<LCMDContextDependencyType>(processDependency?.type ? processDependency.type : 0);
    const [offset, setOffset] = useState(processDependency?.lag ? processDependency.lag : 0);
    const processName = processDependency?.targetName ? processDependency.targetName : "";

    const getDependencyOption = (type: number) => {
        return dependencyOptions.find((option) => option.key === type).track;
    };

    const saveModal = () => {
        const webTrackingPayload: Record<string, string | number> = {
            dependency: dependencyType === 0 ? "predecessor" : "successor",
        };
        if (processDependency?.lag !== offset) {
            webTrackingPayload.dependency_option = "offset";
            webTrackingPayload.dependency_type_value = null;
            WebAppTelemetryFactory.trackEvent(`${webtrackingPrefix}-dependecy-updated`, { ...webTrackingPayload });
        }
        if (processDependency?.type !== type) {
            webTrackingPayload.dependency_option = "type";
            webTrackingPayload.dependency_type_value = getDependencyOption(type);
            WebAppTelemetryFactory.trackEvent(`${webtrackingPrefix}-dependecy-updated`, { ...webTrackingPayload });
        }
        const processDependencyToSave: LCMDContextTaskDetailsResultDependencyDetails = {
            ...processDependency,
            type,
            lag: offset,
        };
        onClose(processDependencyToSave);
    };

    const buttons = [
        <DefaultButton key="cancel" onClick={onCancel}>
            {intl.get("ProcessDependenciesModal.buttons.cancel")}
        </DefaultButton>,
        <PrimaryButton key="save" onClick={saveModal}>
            {intl.get("ProcessDependenciesModal.buttons.apply")}
        </PrimaryButton>,
    ];

    return (
        <LcmdModal
            buttons={buttons}
            header={{ title: intl.get("ProcessDependenciesModal.headline") }}
            styles={{ main: { width: "600px", height: "800px" } }}
            isOpen={show}
            onDismiss={onCancel}
        >
            <Stack styles={{ root: { height: "100%" } }} tokens={{ childrenGap: "10px" }}>
                <Stack tokens={{ childrenGap: "25px" }} styles={{ root: { padding: "20px 32px" } }}>
                    <Stack>
                        <Stack horizontal horizontalAlign={"space-between"}>
                            <ComboBox
                                label={intl.get("ProcessDependenciesModal.typeComboBox.label")}
                                defaultSelectedKey={type}
                                placeholder={intl.get("ProcessDependenciesModal.typeComboBox.placeholder")}
                                options={dependencyOptions}
                                styles={{ root: { width: "300px" } }}
                                onChange={(ev, newOption) => {
                                    setType(parseInt(`${newOption.key}`));
                                }}
                            />
                            <Stack horizontal>
                                <Counter
                                    label={intl.get("ProcessDependenciesModal.offsetCounter.label")}
                                    unit={intl.get("ProcessDependenciesModal.offsetCounter.unit")}
                                    value={offset}
                                    onChange={(newValue) => {
                                        setOffset(newValue);
                                    }}
                                    min={-100}
                                />
                                {/*<Text>working days</Text>*/}
                            </Stack>
                        </Stack>
                        <TextField
                            label={intl.get("ProcessDependenciesModal.processCounter.label")}
                            disabled
                            defaultValue={processName}
                        />
                    </Stack>
                </Stack>
            </Stack>
        </LcmdModal>
    );
};
