﻿import * as z from "zod";
import { intl } from "@/legacy/GlobalHelperReact";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { IconButton } from "../common/iconButton/IconButton";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowLeft, Loader2 } from "lucide-react";
import { Paths } from "../../types/routes";
import { useLoginStore } from "../../store/loginStore";
import { AuthClient } from "@/app/services/AuthClient.service";

export function ForgotPasswordEmailForm() {
    const { email } = useLoginStore();
    const [isLoading, setIsLoading] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (values: z.infer<typeof formSchema>) => {
        setIsLoading(true);
        try {
            await AuthClient.requestPasswordReset(values.email);
            setShowSuccessMessage(true);
        } catch (error) {
            form.setError("email", { message: intl.get("LcmdLogin.ForgotPassword.EmailSendError") });
        }
        setIsLoading(false);
    };

    const formSchema = z.object({
        email: z.string().email(intl.get("LcmdLogin.ForgotPassword.EmailInputErrorMessage")),
    });
    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            email,
        },
    });

    function handleBack() {
        navigate(Paths.HOME);
    }

    if (showSuccessMessage) {
        return (
            <div className="flex flex-col gap-1">
                <div className="text-xl font-semibold">{intl.get("LcmdLogin.ForgotPassword.EmailSentTitle")}</div>
                <div className="body-medium text-zinc-400">{intl.get("LcmdLogin.ForgotPassword.EmailSentMessage")}</div>
            </div>
        );
    }

    return (
        <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-1">
                <div className="flex gap-1">
                    <IconButton icon={ArrowLeft} onClick={handleBack} />
                    <div className="text-xl font-semibold">{intl.get("LcmdLogin.ForgotPassword.Title")}</div>
                </div>
                <div className="body-medium pl-9 text-zinc-400">{intl.get("LcmdLogin.ForgotPassword.Description")}</div>
            </div>
            <Form {...form}>
                <form onSubmit={form.handleSubmit(handleSubmit)} className="flex flex-col gap-8">
                    <FormField
                        control={form.control}
                        name="email"
                        disabled={isLoading}
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>{intl.get("LcmdLogin.ForgotPassword.EmailInputLabel")}</FormLabel>
                                <FormControl>
                                    <Input {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <Button type="submit" disabled={isLoading} size="lg">
                        {isLoading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
                        {intl.get("LcmdLogin.ForgotPassword.SubmitEmailButton")}
                    </Button>
                </form>
            </Form>
        </div>
    );
}
