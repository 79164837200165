import {
    ApplicationInsights,
    ICustomProperties,
    IDependencyInitializerHandler,
} from "@microsoft/applicationinsights-web";
import { IExceptionTelemetry } from "@microsoft/applicationinsights-common";
import posthog from "posthog-js";
import * as Sentry from "@sentry/browser";
import { Client } from "@sentry/types";

export class Telemetry {
    private applicationInsights: ApplicationInsights;
    private phog: typeof posthog;

    private readonly connectionString: string;
    private sub: string;
    private projectId: string;
    private internal: boolean = false;
    private readonly bundleHash: string;
    private readonly workerHash: string;
    private emailDomain: string;
    private userRole: string;
    private sentryClient: Client = null;
    private basicTelemetryInitializer: void | IDependencyInitializerHandler;
    private advancedTelemetryInitializer: void | IDependencyInitializerHandler;

    // todo: refactor urlObject should not be optional
    constructor(
        connectionString: string,
        bundleHash: string,
        workerHash: string,
        urlObject?: { origin: string; pathname: string },
        sentry?: { dsn: string; environment: string; release: string; tunnel?: string },
    ) {
        this.connectionString = connectionString;
        this.bundleHash = bundleHash;
        this.workerHash = workerHash;

        this.applicationInsights = new ApplicationInsights({
            config: {
                connectionString: this.connectionString,
                enableCorsCorrelation: true,
            },
        });

        // check if location available and if not a tracking of the path needs to be implemented
        urlObject = {
            origin: urlObject?.origin || globalThis?.location?.origin,
            pathname: urlObject?.pathname || globalThis?.location?.pathname,
        };

        if (!this.bundleHash || !this.workerHash || !urlObject?.pathname || !urlObject?.origin) {
            throw new Error("Missing initializer parameter please provide all default params!");
        }

        const telemetryInitializerFunction = (telemetryItem) => {
            telemetryItem.data["bundle-hash"] = this.bundleHash;
            telemetryItem.data["worker-hash"] = this.workerHash;
            telemetryItem.data["origin"] = urlObject.origin;
            telemetryItem.data["pathname"] = urlObject.pathname;
            return true;
        };

        this.basicTelemetryInitializer = this.applicationInsights.addTelemetryInitializer(telemetryInitializerFunction);

        this.applicationInsights.loadAppInsights();

        // Initialize PostHog
        this.phog = posthog;
        this.phog.init("phc_XPRW57wESs1UhiAgyNJYwpe2wGfyvnwzCla4mtwei1l", {
            api_host: "https://eu.i.posthog.com",
            autocapture: false,
            loaded: (posthog) => {
                if (process.env.NODE_ENV != "development") posthog.startSessionRecording();
            },
        }); // this token will be sent to posthog auth

        // sentry init
        if (sentry) {
            this.sentryClient = Sentry.init(sentry);
        } else {
            console.log("not starting sentry");
        }
    }

    public identify({
        projectId,
        userId,
        internal = false,
        domain,
    }: {
        projectId?: string;
        userId: string;
        internal?: boolean;
        domain?: string;
    }) {
        if (!this.applicationInsights) {
            throw new Error("Reinitialising TelemetryClient not possible");
        }

        if (!this.phog) {
            throw new Error("phog: Reinitialising TelemetryClient not possible");
        }

        // prevent reinit of user or project. Security feature
        if (this.sub || this.projectId) {
            throw new Error("cannot reinitialize user or project. Please clear the instance!");
        }

        this.sub = userId;
        this.projectId = projectId;
        this.internal = internal;
        this.emailDomain = domain;

        const telemetryInitializerFunction = (telemetryItem) => {
            telemetryItem.data["user-id"] = this.sub;
            telemetryItem.data["project-id"] = this.projectId;
            if (this.internal) {
                telemetryItem.data["internal"] = internal;
            }
            return true;
        };

        this.advancedTelemetryInitializer =
            this.applicationInsights.addTelemetryInitializer(telemetryInitializerFunction);
        this.applicationInsights.setAuthenticatedUserContext(this.sub, this.projectId || null, true);

        //For PostHog, manually add user properties
        if (!this.phog._isIdentified()) {
            this.phog.identify(this.sub, {
                "user-id": this.sub,
                ...(this.internal && { internal: this.internal }),
                ...(this.emailDomain && { domain: this.emailDomain }),
            });
        }

        if (this.projectId) {
            this.phog.register_for_session({
                "project-id": this.projectId,
            });
        }

        if (Sentry.isInitialized()) {
            Sentry.setUser({ id: userId });

            if (this.projectId) {
                Sentry.setExtra("projectId", projectId);
            }
        }
    }

    public setRole(role: string) {
        if (typeof role !== "string") {
            throw new Error("Role is not string");
        }
        this.userRole = role;
    }
    public setProject(projectId: string, role?: string) {
        // prevent reinit of project. Security feature
        if (this.projectId) {
            throw new Error("cannot reinitialize user or project. Please clear the instance!");
        }

        this.projectId = projectId;
        if (Sentry.isInitialized()) {
            Sentry.setExtra("projectId", projectId);
        }

        if (role) {
            this.setRole(role);
        }
    }

    public trackPageView(viewName: string, customProperties: ICustomProperties = {}) {
        if (!this.isInitialized()) {
            console.error("Telemetry service needs to be initialised first!");
            return;
        }

        this.applicationInsights.trackPageView({
            name: viewName,
            properties: {
                ...customProperties,
            },
        });
    }

    public trackEvent(name: string, custom: ICustomProperties = {}) {
        if (!this.isInitialized()) {
            console.error("Telemetry service needs to be initialised first!");
            return;
        }

        this.applicationInsights.trackEvent({ name }, { ...custom });

        //posthog event properties
        this.phog.capture(name, {
            ...custom,
            "user-id": this.sub,
            "project-id": this.projectId,
            "bundle-hash": this.bundleHash,
            "worker-hash": this.workerHash,
            internal: this.internal,
            domain: this.emailDomain,
            role: this.userRole,
        });
    }

    public trackException(exception: IExceptionTelemetry, groupIdentifier?: string, customProps?: ICustomProperties) {
        if (!this.isInitialized()) {
            console.error("Telemetry service needs to be initialised first!");
            return;
        }

        let cs = { ...customProps };
        if (typeof groupIdentifier === "string") {
            cs = { ...cs, ...{ group: groupIdentifier } };
        }

        this.applicationInsights.trackException(exception, cs);
        this.applicationInsights.flush();

        // track error on sentry
        this.sentryClient.captureException(exception?.exception || new Error("unknown exception"));
    }

    public isInitialized(): boolean {
        if (!this.applicationInsights) {
            return false;
        }

        if (!this.phog) {
            return false;
        }

        if (!Sentry.isInitialized()) {
            return false;
        }

        return true;
    }

    public isIdentified(): boolean {
        return Boolean(this.sub && this.isInitialized());
    }

    public getSub(): string {
        return this.sub;
    }

    public clearInstance() {
        this.sub = null;
        this.projectId = null;
        this.internal = false;
        this.userRole = null;

        if (this.advancedTelemetryInitializer) {
            this.advancedTelemetryInitializer.remove();
        }

        // Clear user context in PostHog
        if (this.phog) {
            this.phog.reset();
        }

        Sentry.flush();
    }

    public getProjectId(): string {
        return this.projectId;
    }

    public isInternalEmail(email: string): boolean {
        if (!email) {
            return false;
        }
        return email.endsWith("@lcmdigital.com") || email.endsWith("@lcmd.io");
    }

    public domainEmail(email: string) {
        if (!email) {
            return false;
        }
        return email.split("@")[1];
    }

    // private getEventProperties() {
    //     return {
    //         "project-id": this.projectId,
    //         "user-id": this.sub,
    //     };
    // }
}

export type TelemetryType = InstanceType<typeof Telemetry>;
