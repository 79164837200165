import React, { useState, useEffect } from "react";
import { CONST } from "@/legacy/settings";
import { intl } from "@/legacy/GlobalHelperReact";
import { LCMDTooltip } from "@/components/common/Tooltip/LCMDTooltip";
import { IconButton } from "@/components/common/IconButton/IconButton";
import { ArrowLeft, ChevronDown, Undo, Redo } from "lucide-react";
import { useParticleContext } from "@/legacy/api/ParticleContext";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { AlertDialog } from "@/components/common/AlertDialog/AlertDialogV2";
import { LabelToggleType } from "@/legacy/components/Canvas/CanvasHostContainer";
import { EditNameInput } from "../common/EditNameInput";
import { LabelToggle } from "../common/LabelToggle/LabelToggleV2";
import { WhiteboardEditNameModal } from "./WhiteboardEditNameModal";
import { WebAppTelemetryFactory } from "@/app/services/WebAppTelemetry.service";
import { LabelToggleValues } from "@/legacy/components/Canvas/CanvasHostContainer";

type WhiteboardSubHeaderProps = {
    active: boolean;
    gpaMode: LabelToggleType;
    wb?: { id: string; name?: string };
    ctx: {
        undo: () => void;
        redo: () => void;
        toggleGPAGrid: (value: string) => void;
        closeWhiteboard: () => void;
        showWhiteboardDialog: () => void;
    };
};

export function WhiteboardSubHeader(props: WhiteboardSubHeaderProps) {
    const LCMD = useParticleContext();
    const [showDeleteAlert, setShowDeleteAlert] = useState<boolean>(false);
    const [showEditDialog, setShowEditDialog] = useState<boolean>(false);
    const whiteboardName = props.wb?.name || intl.get("whiteboard.list.item.unnamed");
    function saveWbName(_name) {
        LCMD.setWhiteboardState(props.wb.id, {
            name: {
                value: _name,
            },
        });
    }
    function deleteWb() {
        WebAppTelemetryFactory.trackEvent("whiteboard-deleted");
        LCMD.closeWhiteboard(props.wb.id, { delete: true });
        setShowDeleteAlert(false);
    }

    useEffect(() => {
        if (props.gpaMode === LabelToggleValues.PLAN) {
            props.ctx.toggleGPAGrid(LabelToggleValues.PLAN);
        }
    }, []);
    return (
        <div
            className="TH2"
            style={{
                display: !props.active ? "none" : undefined,
                left: CONST.menuCondensedWidth,
                top: CONST.titleHeight,
                height: CONST.subtitleHeight,
                lineHeight: CONST.subtitleHeight + "px",
                position: "fixed",
                boxSizing: "border-box",
                justifyContent: "center",
            }}
        >
            <div className="TH2L" style={{ position: "absolute", left: 0 }}>
                <LCMDTooltip text={intl.get("whiteboard.header.backToOverview")} side="bottom">
                    <IconButton
                        icon={ArrowLeft}
                        onClick={props.ctx.closeWhiteboard}
                        userflowId="wb-zurueck-button"
                    ></IconButton>
                </LCMDTooltip>
                <LabelToggle value={props.gpaMode} onChange={(value) => props.ctx.toggleGPAGrid(value)} />
                <LCMDTooltip
                    text={intl.get("canvas.cmd.undo.title")}
                    side="bottom"
                    className={!props.active && "hidden"}
                >
                    <IconButton icon={Undo} onClick={props.ctx.undo}></IconButton>
                </LCMDTooltip>
                <LCMDTooltip
                    text={intl.get("canvas.cmd.redo.title")}
                    side="bottom"
                    className={!props.active && "hidden"}
                >
                    <IconButton icon={Redo} onClick={props.ctx.redo}></IconButton>
                </LCMDTooltip>
            </div>
            <>
                <EditNameInput
                    name={whiteboardName}
                    saveName={saveWbName}
                    userflowId="wb-name"
                    trackingEvent="whiteboard-renamed"
                />
                <DropdownMenu>
                    <DropdownMenuTrigger>
                        <div className={"ml-1"} style={{ height: "32px" }} data-userflow-id="wb-drop-down-button">
                            <IconButton icon={ChevronDown}></IconButton>
                        </div>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className="w-56 bg-neutral-800 text-white">
                        <DropdownMenuGroup>
                            <DropdownMenuItem
                                className={"focus:cursor-pointer focus:bg-stone-700 focus:text-white"}
                                onClick={() => {
                                    setShowEditDialog(true);
                                }}
                            >
                                {intl.get("whiteboard.properties.renameWhiteboard")}
                            </DropdownMenuItem>
                            <DropdownMenuItem
                                className={"focus:cursor-pointer focus:bg-stone-700 focus:text-white"}
                                onClick={() => {
                                    setShowDeleteAlert(true);
                                }}
                            >
                                {intl.get("whiteboard.properties.deleteButton")}
                            </DropdownMenuItem>
                        </DropdownMenuGroup>
                    </DropdownMenuContent>
                </DropdownMenu>
            </>
            {showEditDialog && (
                <WhiteboardEditNameModal
                    isOpen={showEditDialog}
                    name={whiteboardName}
                    onOk={(value) => {
                        saveWbName(value);
                        setShowEditDialog(false);
                        if (whiteboardName != value) {
                            WebAppTelemetryFactory.trackEvent("whiteboard-renamed", {
                                place: "dropdown",
                            });
                        }
                    }}
                    onCancel={() => {
                        setShowEditDialog(false);
                    }}
                />
            )}
            {showDeleteAlert && (
                <AlertDialog
                    open={showDeleteAlert}
                    title={intl.get("whiteboard.properties.deleteConfirm.headline")}
                    subText={intl.get("whiteboard.properties.deleteConfirm.deleteText")}
                    onOk={() => deleteWb()}
                    onOpenChange={() => setShowDeleteAlert(false)}
                />
            )}
        </div>
    );
}
