import { CircleHelp, Loader2, RefreshCcw } from "lucide-react";
import { Button, ButtonProps } from "@/components/ui/button";
import { IconButton } from "@/components/common/IconButton/IconButton";
import { LCMDTooltip } from "@/components/common/Tooltip/LCMDTooltip";
import { intl } from "lcmd2framework";

interface SpinnerButtonProps extends ButtonProps {
    isLoading: boolean;
    onClick: () => void;
}

export const SyncedSpinnerButton = ({ isLoading, onClick: handleClick }: SpinnerButtonProps) => {
    if (isLoading) {
        return (
            <LCMDTooltip text={intl.get("CommonUse.RefreshProject")}>
            <IconButton
                onClick={handleClick}
                className="mx-0.5 h-8 w-[36px] self-center rounded-md p-2 hover:bg-accent/20 hover:text-white"
                icon={RefreshCcw}/>
            </LCMDTooltip>
        );
    }
    return (
        <div>
            <IconButton
                onClick={handleClick}
                className="mx-0.5 flex h-8 w-[36px] items-center justify-center rounded-md bg-accent/20 p-2 hover:bg-accent/20 hover:text-white active:bg-accent/20"
                icon={() => (
                    <div className="animate-spin">
                        <RefreshCcw />
                    </div>
                )}
            />
        </div>
    );
};
